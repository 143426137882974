import { TableSettings } from 'api/api';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { allColumns, NotificationHistoryTableSettingsState } from './types';

export const initialState: NotificationHistoryTableSettingsState = {
	columnOrder: ['createdOn', 'notificationEvents', 'stateChangeDateTime', 'emails', 'editHistoryDto'],
	columnWidths: [
		{ columnName: 'createdOn', width: 155 },
		{ columnName: 'notificationEvents', width: 200 },
		{ columnName: 'emails', width: 300 },
		{ columnName: 'stateChangeDateTime', width: 155 },
		{ columnName: 'editHistoryDto', width: 80 },
	],
	sorting: [
		{
			columnName: 'createdOn',
			direction: 'desc',
		},
	],
	columns: allColumns,
	tableSettings: new TableSettings(),
	loaded: false,
};

export function notificationHistoryTableSettingsReducer(
	state: typeof initialState = initialState,
	action: Action
): typeof initialState {
	if (isType(action, actions.setColumnOrderAction)) {
		return {
			...state,
			columnOrder: action.payload,
		};
	}
	if (isType(action, actions.setColumnSizeAction)) {
		return {
			...state,
			columnWidths: action.payload,
		};
	}
	if (isType(action, actions.setColumnSortingAction)) {
		return {
			...state,
			sorting: action.payload,
		};
	}

	return state;
}
