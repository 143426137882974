import { IFileDirectoryDto } from 'api/api';
import React, { FC } from 'react';
import { PartnerAreaIcon } from './partner-area-icon';
import './partner-area.scss';

type Props = {
	obj: IFileDirectoryDto;
};

export const PartnerAreaRowName: FC<Props> = props => {
	return (
		<>
			<PartnerAreaIcon obj={props.obj} />
			<span className="margin-left-10px">{props.obj.name}</span>
		</>
	);
};
