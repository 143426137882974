import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
	'GET_RELEASE_NOTES',
	'READ_RELEASE_NOTES',
	'DELETE_RELEASE_NOTES',
	'ADD_RELEASE_NOTE',
	'EDIT_RELEASE_NOTE',
	'GET_IMAGE',
	'SAVE_IMAGE',
);
