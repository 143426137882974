import { Action } from 'redux';
import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';
import { isType } from 'typescript-fsa';
import { upsertValueInArrayCustomId, deleteValueInArrayCustomId } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { UnitTrackerState } from './types';

export const initialState: UnitTrackerState = {
	unitTrackers: [],
};

export function unitTrackerReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.CreateUnitTrackerAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			unitTrackers: upsertValueInArrayCustomId(
				state.unitTrackers,
				action.payload.result,
				unitTracker => unitTracker.unitId
			),
		};
	} else if (isType(action, actions.GetUnitTrackerAction.done)) {
		return {
			...state,
			unitTrackers: upsertValueInArrayCustomId(
				state.unitTrackers,
				action.payload.result,
				unitTracker => unitTracker.unitId
			),
		};
	} else if (isType(action, actions.UpdateUnitTrackerIdAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			unitTrackers: upsertValueInArrayCustomId(
				state.unitTrackers,
				action.payload.result,
				unitTracker => unitTracker.unitId
			),
		};
	} else if (isType(action, actions.DeleteUnitTrackerAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			unitTrackers: deleteValueInArrayCustomId(
				state.unitTrackers,
				action.payload.params.unitId,
				unitTracker => unitTracker.unitId
			),
		};
	} else if (isType(action, actions.GetUnitTrackersAction.done)) {
		return {
			...state,
			unitTrackers: action.payload.result,
		};
	}

	return state;
}
