import { UnitTrackerClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { unitTrackerClient } from '../api-clients';
import {
	CreateUnitTrackerAction,
	DeleteUnitTrackerAction,
	GetUnitTrackerAction,
	GetUnitTrackersAction,
	UpdateUnitTrackerIdAction,
} from './actions';

export function updateUnitTrackerId(unitId: number, trackerId: string) {
	return AsyncOperationBuilder(
		UpdateUnitTrackerIdAction,
		apiClient => (apiClient as UnitTrackerClient).updateUnitTrackerId(unitId, trackerId),
		{ unitId, trackerId },
		unitTrackerClient
	);
}

export function deleteUnitTracker(unitId: number) {
	return AsyncOperationBuilder(
		DeleteUnitTrackerAction,
		apiClient => (apiClient as UnitTrackerClient).deleteUnitTracker(unitId),
		{ unitId },
		unitTrackerClient
	);
}

export function getUnitTracker(serialNumber: string) {
	return AsyncOperationBuilder(
		GetUnitTrackerAction,
		apiClient => (apiClient as UnitTrackerClient).getUnitTracker(serialNumber),
		{ serialNumber },
		unitTrackerClient
	);
}

export function createUnitTracker(unitId: number, trackerId: string) {
	return AsyncOperationBuilder(
		CreateUnitTrackerAction,
		apiClient => (apiClient as UnitTrackerClient).createUnitTracker(unitId, trackerId),
		{ unitId: unitId, trackerId },
		unitTrackerClient
	);
}

export function getUnitTrackers() {
	return AsyncOperationBuilder(
		GetUnitTrackersAction,
		apiClient => (apiClient as UnitTrackerClient).getUnitTrackers(),
		undefined,
		unitTrackerClient
	);
}
