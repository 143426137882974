import { IKPIServiceTileDto } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import { images } from 'utilities/images';
import './kpi-service-tile.scss';

interface PropsFromParent {
	tile: IKPIServiceTileDto;
}

type Props = PropsFromParent;

export class KPIServiceTile extends React.PureComponent<Props> {

	public render() {
		return (
			<>
				<div className="border-bottom-grey margin-bottom-20px" />
				<div className="flex-direction-column flex-center-column">
					<img className="img-kpi-service-icon" src={images.cog_wrench} alt={localized('Service')} draggable={false} />
					<h3 className="text-bold margin-top-20px text-lowercase">
						{this.props.tile.unitWithValidService} % {localized('Units')}
					</h3>
					<span className="font-size-normal">{localized('ServicedWithinTheLast12Months')}</span>
				</div>
			</>
		);
	}
}
