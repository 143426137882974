import { IGPSHistoryDto, ITableSettings } from 'api/api';
import { localized } from 'state/i18n';
import { IsBrandBramidan } from 'utilities/brand-utils';
import { ColumnGeneric } from '../history-table-settings/types';
import { SortingGeneric, TableColumnWidthInfoGeneric } from '../table-settings/types';

export interface IGPSHistoryDtoCustom extends IGPSHistoryDto {
	homeLocation: string | undefined;
	currentLocation: string | undefined;
}
export type GPSHistoryDtoKeys = keyof IGPSHistoryDtoCustom;

// These are the columns that will be rendered.
export const allColumns: Array<ColumnGeneric<GPSHistoryDtoKeys>> = [
	{ name: 'updatedDate', title: localized('Timestamp') },
	{ name: 'homeLocation', title: localized('HomeLocation') },
	{ name: 'currentLocation', title: localized('currentLocation') },
	{ name: 'distance', title: localized('Distance') },
	{ name: 'source', title: localized('Source') },
];
export const withoutHomeLocationColumns: Array<ColumnGeneric<GPSHistoryDtoKeys>> = [
	{ name: 'updatedDate', title: localized('Timestamp') },
	{ name: 'currentLocation', title: localized('currentLocation') },
	{ name: 'source', title: localized('Source') },
];

if (IsBrandBramidan) {
	const triggeredByColumn: ColumnGeneric<GPSHistoryDtoKeys> = {
		name: 'triggeredBy',
		title: localized('TriggeredBy'),
	};
	allColumns.push(triggeredByColumn);
	withoutHomeLocationColumns.push(triggeredByColumn);
}

export interface GPSHistoryTableSettingsState {
	columnWidths: Array<TableColumnWidthInfoGeneric<GPSHistoryDtoKeys>>;
	columnOrder: GPSHistoryDtoKeys[];
	sorting: Array<SortingGeneric<GPSHistoryDtoKeys>>;
	allColumns: Array<ColumnGeneric<GPSHistoryDtoKeys>>;
	withoutHomeLocationColums: Array<ColumnGeneric<GPSHistoryDtoKeys>>;
	tableSettings: ITableSettings;
	loaded: boolean;
}
