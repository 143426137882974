import { IUnitManufacturerDto } from 'api/api';
import { InputText } from 'primereact/inputtext';
import { PageState, Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from 'react';
import { deleteUnitManufacturer, getUnitManufacturers } from 'state/ducks/unit-manufacturer/operations';
import { localized } from 'state/i18n';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import BiLabelContentItem from 'view/shared/components/bi-label-content/bi-label-content-item';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';
import './unit-manufacturer-editor.scss';
import { UnitManufacturerField } from './unit-manufacturer-field';

interface AppProps { }

const rowUnitManufacturer: IUnitManufacturerDto = { id: 0, isDeletable: false, name: '' };

export const UnitManufacturerEditor: React.FC<AppProps> = props => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		getUnitManufacturers()(dispatch);
	}, [dispatch]);

	const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
	const [paginatorRows, setPaginatorRows] = useState<number>(9);
	const [newlyCreatedUnitManufacturer, setNewlyCreatedUnitManufacturer] = useState<IUnitManufacturerDto>({
		...rowUnitManufacturer,
	});
	const [searchBarText, setSearchBarText] = useState<string>('');
	const [unitManufacturerToDelete, setUnitManufacturerToDelete] = useState<IUnitManufacturerDto | undefined>();

	const unitManufacturers = useAppSelector(state => state.unitManufacturerReducer.unitManufactures)
		.filter(um => isNullOrWhitespace(searchBarText) || um.name?.toLowerCase().includes(searchBarText.toLowerCase()))
		.sort((a, b) => ((a?.name?.toLowerCase() ?? '') > (b?.name?.toLowerCase() ?? '') ? 1 : -1));

	const closeDeleteDialog = () => {
		setUnitManufacturerToDelete(undefined);
	};

	const onSearchBarTextChanged = (e: React.FormEvent<HTMLInputElement>) => {
		setSearchBarText(e.currentTarget.value);
		setNewlyCreatedUnitManufacturer({ ...rowUnitManufacturer });
		setPaginatorFirst(0);
	};

	const onPageChangeHandler = (e: PageState) => {
		setPaginatorFirst(e.first);
		setPaginatorRows(e.rows);
		setNewlyCreatedUnitManufacturer({ ...rowUnitManufacturer });
	};

	const getPaginatedUnitManufacturerRows = (): JSX.Element[] => {
		const pageinatedUnitManufacturer: IUnitManufacturerDto[] = unitManufacturers.slice(
			paginatorFirst,
			paginatorFirst + paginatorRows
		);
		return getUnitManufacturerComponentRows(pageinatedUnitManufacturer);
	};

	const deleteUnitManufacturerButtonPushed = async (unitManufacturer: IUnitManufacturerDto) => {
		if (unitManufacturer.id === 0) {
			setUnitManufacturerToDelete(undefined);
			setNewlyCreatedUnitManufacturer({ ...rowUnitManufacturer });
		} else {
			setUnitManufacturerToDelete(unitManufacturer);
		}
	};

	const deleteManufacturer = async () => {
		if (unitManufacturerToDelete) await deleteUnitManufacturer(unitManufacturerToDelete)(dispatch);
		closeDeleteDialog();
	};

	const getUnitManufacturerComponentRows = (unitManufacturers: IUnitManufacturerDto[]): JSX.Element[] => {
		return unitManufacturers.map(unitManufacturer => {
			const content = (
				<UnitManufacturerField
					unitManufacturer={unitManufacturer}
					deleteUnitManufacturer={deleteUnitManufacturerButtonPushed}
					clearTempRow={() => setNewlyCreatedUnitManufacturer({ ...rowUnitManufacturer })}
				/>
			);
			return (
				<BiLabelContentItem
					defaultClassNames='bi-label-content-item-lg unit-manufacturerer-grid'
					containerClassName="margin-bottom-10px"
					key={`${unitManufacturer.name}${unitManufacturer.id}`}
					label={undefined}
					content={content}
				/>
			);
		});
	};

	const getNewlyCreatedField = (): JSX.Element => {
		const content = (
			<UnitManufacturerField
				unitManufacturer={newlyCreatedUnitManufacturer}
				deleteUnitManufacturer={deleteUnitManufacturerButtonPushed}
				clearTempRow={() => setNewlyCreatedUnitManufacturer({ ...rowUnitManufacturer })}
			/>
		);
		return <BiLabelContentItem defaultClassNames='bi-label-content-item-lg unit-manufacturerer-grid' containerClassName="margin-bottom-10px" label={undefined} content={content} />;
	};

	const UnitManufacturerLabels = () => (
		<div className="content-container-unit-manufacturers padding-left-20px padding-right-20px">
			<label>{localized('manufacturer')}</label>
			<label></label>
		</div>
	);

	return (
		<>
			<BiTextDialog
				visible={unitManufacturerToDelete !== undefined}
				onHide={closeDeleteDialog}
				title={localized('Delete')}
				subtitle={localized('DeleteUnitManufacturerConfirmMessage')}
			>
				<div className="flex-end-row margin-top-10px">
					<BiButton
						colorTheme="org-primary-grey"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={closeDeleteDialog}
					>
						{localized('Cancel')}
					</BiButton>
					<BiButton
						colorTheme="org-red"
						containerTheme="slim-with-rounded-corners"
						onClick={deleteManufacturer}
					>
						{localized('Delete')}
					</BiButton>
				</div>
			</BiTextDialog>

			<div>
				<h5 className="text-bold">{localized('FilterUnitManufacturersByName')}</h5>
				<div className="p-inputgroup margin-bottom-20px">
					<div className="bi-container flex-direction-column flex-fill-width">
						<div className="flex-start-row search-bar margin-bottom-5px">
							<InputText
								placeholder={localized('Search')}
								value={searchBarText}
								onChange={onSearchBarTextChanged}
							/>
						</div>
						<div className="flex-fill-width">
							<BiLabelContent containerClassName="flex-fill-width">
								{UnitManufacturerLabels()}
								{getPaginatedUnitManufacturerRows()}
								{getNewlyCreatedField()}
								<div className="flex-end-row">
									<Paginator
										first={paginatorFirst}
										rows={paginatorRows}
										totalRecords={unitManufacturers.length}
										onPageChange={onPageChangeHandler}
										template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
									></Paginator>
								</div>
							</BiLabelContent>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
