import React from 'react';
import MachineTable from '../bi-table/machine-table';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactAI } from 'services/telemetry-service';
import './machines.scss';
import { MachineTableId, FilterBarId, NavBarHeightPx, UnitsSelectedRowHeightPx } from 'utilities/constants';

type Props = {};

class Machines extends React.PureComponent<Props> {
	public componentDidMount() {
		this.setHeights();
	}

	public render() {
		return (
			<div id={MachineTableId} className="table-container bg-color-white">
				<MachineTable />
			</div>
		);
	}

	private setHeights = () => {
		// This sets the height of the table is because we don't know the height of the filterbar
		let filterBarElement = document.getElementById(FilterBarId);
		let machineTable = document.getElementById(MachineTableId);

		if (machineTable && filterBarElement) {
			let height = filterBarElement.clientHeight + NavBarHeightPx + UnitsSelectedRowHeightPx;
			machineTable.setAttribute('style', `height: calc(100vh - ${height}px)`);
		}
	}
}

export default withAITracking(reactAI.reactPlugin, Machines, 'Machines');
