import { constants } from './constants';
import { IServiceVideoDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('table-settings');

export const getServiceVideosAction = actionCreator.async<string, IServiceVideoDto[]>(constants.GET_SERVICE_VIDEOS);
export const getAllServiceVideosAction = actionCreator.async<undefined, IServiceVideoDto[]>(
	constants.GET_ALL_SERVICE_VIDEOS
);
export const createServiceVideoAction = actionCreator.async<IServiceVideoDto, IServiceVideoDto>(
	constants.CREATE_SERVICE_VIDEOS
);

export const deleteServiceVideosAction = actionCreator.async<IServiceVideoDto, void>(
	constants.DELETE_SERVICE_VIDEOS
);
