import { IUnitDetailsDto, UnitDetailsDto } from 'api/api';
import { SelectItem } from 'primereact/components/selectitem/SelectItem';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { UpsertMachineDetails, getMachineDetails } from 'state/ducks/machine-details/operations';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import { getEmailFromToken } from 'utilities/token-util';
import WhiteCard from 'view/shared/components/cards/white-card';
import { getDateToString } from 'view/shared/components/date-to-string';
import LabelValueCard, {
	LabelValueForCardMap,
	addTranslationLabelValueMapToList,
	getLabelValueOrDefaultToLocalizedNoDataString,
} from 'view/shared/components/label-value-card';
import { cardDetailsStyles, cardGeneralClassNames } from '../constants';
import { ConfirmDialog } from '../../../../shared/components/dialogs/bi-dialog-confirm/dialog-confirm';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	const currMachine = getMachineFromIdDataMapOrDefault(
		state.machineDetailsReducer.machineDetails,
		ownProps.machineDetailsId,
		UnitDetailsDto
	);

	const idDataTupleUserGroups = state.groupsReducer.groups.find(g => g.id === getEmailFromToken());
	let userGroups = idDataTupleUserGroups ? idDataTupleUserGroups.data : [];

	return {
		serviceContractTypes: state.machineDetailsReducer.options.serviceContractTypes,
		ownerships: state.machineDetailsReducer.options.ownerShips,
		ownershipId: currMachine.data.ownershipId,
		machineDetailsDto: currMachine.data,
		comment: currMachine.data.comment,
		customerUnitId: currMachine.data.customerUnitId,
		collector: currMachine.data.collector,
		distributor: currMachine.data.distributor,
		distributorCountry: currMachine.data.distributorCountry,
		serviceContractType: currMachine.data.serviceContractTypeId,
		warrantyExpires: currMachine.data.warrantyExpires,
		installationDate: currMachine.data.installationDate,
		lastService: currMachine.data.lastService,
		nextService: currMachine.data.nextService,
		siteUser: currMachine.data.siteUser,
		siteDescription: currMachine.data.siteDescription,
		city: currMachine.data.siteCity,
		postalCode: currMachine.data.sitePostalCode,
		siteAdress: currMachine.data.siteAddress,
		gpsLocation: currMachine.data.gpsLocation,
		gpsHomeLocation: currMachine.data.gpsHomeLocation,
		ownerGroupId: currMachine.data.ownerGroupId,
		userGroups,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	upsertMachineDetails: async (machineIds: IUnitDetailsDto, updateCoordinates: boolean) =>
		(await UpsertMachineDetails(machineIds, updateCoordinates))(dispatch),
	getMachineDetails: async (id: string) => (await getMachineDetails(id))(dispatch),
});

interface AppProps {
	machineDetailsId: string;
	isEditable: boolean;
	id: number;
}

interface SaveLocation {
	dialog: JSX.Element;
	key: keyof UnitDetailsDto;
	value: any;
}

type State = {
	selectedGroupNameId: string | undefined;
	saveLocation?: SaveLocation;
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

class MachineOwnerAndLocationCard extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		const groupName = this.getGroupNameById();

		this.state = {
			selectedGroupNameId: groupName,
		};
	}

	public render() {
		const machineOwnerPairValues = this.getMachineOwnerPairValues();
		const locationPairValues = this.getLocationPairValues();
		const containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer}`;

		return (
			<WhiteCard title={localized('MachineOwnerDetails')} classNameContainer={containerClassName}>
				<LabelValueCard
					labels={machineOwnerPairValues.labels}
					values={machineOwnerPairValues.valuesForLabels}
				/>

				<h3 className="text-bold margin-top-40">{localized('location')}</h3>
				<LabelValueCard labels={locationPairValues.labels} values={locationPairValues.valuesForLabels} />
				{this.state.saveLocation?.dialog}
			</WhiteCard>
		);
	}
	/*				<NavLink
					className={`${cardMapNoPadding} border-shadow-dark-all-corners border-radius-5`}
					to={{ pathname: Routes.Location, search: this.props.id }}
					onClick={this.setAsSelectedMachine}
				>
					{machineLocationData}
				</NavLink> */
	private getMachineOwnerPairValues(): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};
		const notEditable = false;
		const serviceContractTypeOptions: any[] = [];
		this.props.serviceContractTypes &&
			this.props.serviceContractTypes.forEach(element => {
				serviceContractTypeOptions.push({
					label: element.nameTranslationKey ? localizedDynamic(element.nameTranslationKey) : '',
					value: element.id,
				});
			});

		let serviceContractTypeSelected = serviceContractTypeOptions.find(
			a => a.value === this.props.serviceContractType
		);
		if (!serviceContractTypeSelected) {
			serviceContractTypeSelected = { label: ' ', value: ' ' };
		}
		const ownerShipOptions: any[] = [];
		this.props.ownerships &&
			this.props.ownerships.forEach(element => {
				ownerShipOptions.push({
					label: element.nameTranslationKey ? localizedDynamic(element.nameTranslationKey) : '',
					value: element.id,
				});
			});

		let ownerShipSelected = ownerShipOptions.find(a => a.value === this.props.ownershipId);
		if (!ownerShipSelected) {
			ownerShipSelected = { label: ' ', value: ' ' };
		}

		addTranslationLabelValueMapToList(
			'distributor',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.distributor),
			retMap,
			notEditable,
			(value: any) => this.saveItem('distributor', value)
		);
		addTranslationLabelValueMapToList(
			'distributorCountry',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.distributorCountry),
			retMap,
			notEditable,
			(value: any) => this.saveItem('distributorCountry', value)
		);

		addTranslationLabelValueMapToList(
			'groupName',
			getLabelValueOrDefaultToLocalizedNoDataString(this.getGroupNameById()),
			retMap,
			this.props.isEditable,
			this.setGroupName,
			'dropdown',
			this.state.selectedGroupNameId,
			this.props.userGroups.map(
				g =>
					({
						label: g.groupName,
						value: g.id,
						key: g.id,
					} as SelectItem)
			)
		);

		addTranslationLabelValueMapToList(
			'siteUser',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.siteUser),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('siteUser', value)
		);
		addTranslationLabelValueMapToList(
			'siteDescription',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.siteDescription),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('siteDescription', value)
		);
		addTranslationLabelValueMapToList(
			'ownership',
			getLabelValueOrDefaultToLocalizedNoDataString(ownerShipSelected.label),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('ownershipId', value),
			'dropdown',
			ownerShipSelected.value,
			ownerShipOptions // What can be picked?
		);
		addTranslationLabelValueMapToList(
			'customerUnitId',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.customerUnitId),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('customerUnitId', value)
		);
		addTranslationLabelValueMapToList(
			'collector',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.collector),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('collector', value)
		);
		addTranslationLabelValueMapToList(
			'installationDate',
			this.props.installationDate ? getDateToString(this.props.installationDate) : '',
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('installationDate', value),
			'datePicker',
			this.props.installationDate
		);
		addTranslationLabelValueMapToList(
			'warrantyExpires',
			this.props.warrantyExpires ? getDateToString(this.props.warrantyExpires) : '',
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('warrantyExpires', value),
			'datePicker',
			this.props.warrantyExpires
		);
		addTranslationLabelValueMapToList(
			'serviceContractType',
			getLabelValueOrDefaultToLocalizedNoDataString(serviceContractTypeSelected.label),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('serviceContractTypeId', value),
			'dropdown',
			serviceContractTypeSelected.value,
			serviceContractTypeOptions // What can be picked?
		);
		addTranslationLabelValueMapToList(
			'lastService',
			this.props.lastService ? getDateToString(this.props.lastService) : '',
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('lastService', value),
			'datePicker',
			this.props.lastService
		);
		addTranslationLabelValueMapToList(
			'nextService',
			this.props.nextService ? getDateToString(this.props.nextService) : '',
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('nextService', value),
			'datePicker',
			this.props.nextService
		);
		addTranslationLabelValueMapToList(
			'comments',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.comment),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItem('comment', value),
			'textArea'
		);
		return retMap;
	}

	private getGroupNameById = () => {
		const machineGroup = this.props.userGroups && this.props.userGroups.find(g => g.id === this.props.ownerGroupId);
		const groupName = machineGroup ? machineGroup.groupName : '';

		return groupName;
	};

	private async saveItem(key: keyof UnitDetailsDto, value: any, updateCoordinates = false) {
		let machineDetailsDto = { ...this.props.machineDetailsDto } as IUnitDetailsDto;
		if (key === 'gpsHomeLocation') {
			let latlongArray = value.split(',');
			machineDetailsDto['homeLatitude'] = latlongArray[0];
			machineDetailsDto['homeLongitude'] = latlongArray[1];
			machineDetailsDto[key] = value;
		} else {
			machineDetailsDto[key] = value;
		}

		await this.props.upsertMachineDetails(machineDetailsDto, updateCoordinates);
	}

	private async clearGpsLocation() {
		let machineDetailsDto = { ...this.props.machineDetailsDto } as IUnitDetailsDto;
		machineDetailsDto['homeLatitude'] = undefined;
		machineDetailsDto['homeLongitude'] = undefined;
		machineDetailsDto.gpsHomeLocation = null!;

		await this.props.upsertMachineDetails(machineDetailsDto, true);
	}

	private saveLocationWithCoordinates = () => {
		this.saveItem(this.state.saveLocation!.key, this.state.saveLocation!.value, true);
		this.setState({ saveLocation: undefined });
	};

	private saveLocationWithoutCoordinates = () => {
		this.saveItem(this.state.saveLocation!.key, this.state.saveLocation!.value);
		this.setState({ saveLocation: undefined });
	};

	private saveItemWithDialogOnLocation = (key: keyof UnitDetailsDto, value: any) => {
		let dialog: JSX.Element = (
			<ConfirmDialog
				header={localized('ChangeLocation')}
				visible={true}
				confirmColor="org-primary"
				dialogMessage={localized('LocationChangeConfirmMessage')}
				confirmMessage={localized('ChangeLocation')}
				cancelMessage={localized('No')}
				onConfirm={this.saveLocationWithCoordinates}
				onCancel={this.saveLocationWithoutCoordinates}
				onHide={this.saveLocationWithoutCoordinates}
			/>
		);

		this.setState({ saveLocation: { dialog: dialog, key: key, value: value } });
	};

	private getLocationPairValues(): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		addTranslationLabelValueMapToList(
			'siteCity',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.city),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItemWithDialogOnLocation('siteCity', value)
		);
		addTranslationLabelValueMapToList(
			'sitePostalCode',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.postalCode),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItemWithDialogOnLocation('sitePostalCode', value)
		);
		addTranslationLabelValueMapToList(
			'siteAddress',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.siteAdress),
			retMap,
			this.props.isEditable,
			(value: any) => this.saveItemWithDialogOnLocation('siteAddress', value)
		);
		addTranslationLabelValueMapToList(
			'gpsLocation',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.gpsLocation),
			retMap
		);
		addTranslationLabelValueMapToList(
			'homeLocation',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.gpsHomeLocation),
			retMap,
			this.props.isEditable,
			this.saveGpsHomeLocation
		);
		return retMap;
	}

	private setGroupName = async (e: any) => {
		if (e && e !== this.state.selectedGroupNameId) {
			let machineDetailsDto = { ...this.props.machineDetailsDto } as IUnitDetailsDto;
			machineDetailsDto['ownerGroupId'] = e;
			await this.props.upsertMachineDetails(machineDetailsDto, false);

			//We need to call this to get the new distributor
			this.props.getMachineDetails(this.props.machineDetailsId);
		}

		this.setState({ selectedGroupNameId: e || this.props.ownerGroupId });
	};

	private saveGpsHomeLocation = (value: string) => {
		let latlongArray = value.split(',');
		if (
			latlongArray.length === 2 &&
			Number(latlongArray[0]).toString() !== 'NaN' &&
			Number(latlongArray[1]).toString() !== 'NaN'
		) {
			this.saveItem('gpsHomeLocation', value);
		} else if (latlongArray.length === 1 && latlongArray[0] === '') {
			this.clearGpsLocation();
		} else {
			notificationService.showWarningMessage(localized('Error_HomeLocationNeedsToBeOfFormat'));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineOwnerAndLocationCard);
