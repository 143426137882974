import { Dispatch } from 'redux';
import { SortingGeneric, TableColumnWidthInfoGeneric } from '../history-table-settings/types';
import { setColumnOrderAction, setColumnSizeAction, setColumnSortingAction } from './actions';
import { NotificationHistoryDtoKeys } from './types';

export function setSorting(sorting: SortingGeneric<NotificationHistoryDtoKeys>[]) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setColumnSortingAction(sorting));
	};
}

export function setSize(columnWidths: TableColumnWidthInfoGeneric<NotificationHistoryDtoKeys>[]) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setColumnSizeAction(columnWidths));
	};
}

export function setOrder(columnOrder: NotificationHistoryDtoKeys[]) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setColumnOrderAction(columnOrder));
	};
}
