import { faBell, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGetReleaseNoteDto } from 'api/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { ReadReleaseNotes, getReleaseNotes } from 'state/ducks/release-notes/operations';
import { RouteBasePath, Routes } from 'state/ducks/routes';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { ShouldShowTutorials } from 'utilities/tutorial-constants';
import TablePagePicker from '../table/table-page-picker';
import './navigation-bar.scss';
import ReleaseNotes from './release-notes';
import { Tutorials } from './tutorial/tutorials';
import { authPermissionCheck } from '../auth/auth-helper';
import { administrationPageAuthRequirements, profilePageAuthRequirements } from '../auth/auth-requirements';
import { ReleaseNoteStepConfirmDialog } from './release-note-step-confirm-dialog/release-note-step-confirm-dialog';

const mapStateToProps = (state: AppState) => {
	const userGroupPermissions = state.userPermissionReducer.UserGroupPermissions;

	return {
		isAuthenticated: state.authReducer.isAuthenticated,
		userProfile: state.profileReducer.profile,
		userGroupPermissions,
		languageChanged: state.userSettingReducer.userSettings.selectedLanguage,
		isNewReleaseNotes: state.releaseNoteReducer.notes.some(note => note.isNew),
		releaseNotes: state.releaseNoteReducer.notes,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getReleaseNotes: async () => (await getReleaseNotes())(dispatch),
	ReadReleaseNotes: async (notes: IGetReleaseNoteDto[]) => (await ReadReleaseNotes(notes))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class NavigationBar extends React.PureComponent<Props> {
	private releaseNotesPanel: OverlayPanel | null = null;
	private tutorialsPanel: OverlayPanel | null = null;

	constructor(props: Props) {
		super(props);

		if (this.props.isAuthenticated === true) {
			this.props.getReleaseNotes();
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (
			(prevProps.isAuthenticated === undefined || prevProps.isAuthenticated === false) &&
			this.props.isAuthenticated === true
		) {
			this.props.getReleaseNotes();
		}
	}

	handleReleaseNoteToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		!this.props.isNewReleaseNotes && this.releaseNotesPanel?.toggle(e);
	};
	handleTutorialsToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		this.tutorialsPanel?.toggle(e);
	};

	handleReadReleaseNotes = () => {
		this.props.ReadReleaseNotes(this.props.releaseNotes);
	};

	public render() {
		const { userProfile } = this.props;
		const releaseNotesItem = (
			<li className="nav-item">
				<div className="nav-link nav-icon-container" onClick={this.handleReleaseNoteToggle}>
					<FontAwesomeIcon className="icon" icon={faBell} />
					{this.props.isNewReleaseNotes && (
						<>
							<div className="not-circle" />
							<ReleaseNoteStepConfirmDialog />
						</>
					)}
				</div>
				<OverlayPanel ref={el => (this.releaseNotesPanel = el)} onHide={this.handleReadReleaseNotes}>
					<ReleaseNotes />
				</OverlayPanel>
			</li>
		);
		const tutorialsItem = ShouldShowTutorials && (
			<li className="nav-item">
				<div className="nav-link nav-icon-container" onClick={this.handleTutorialsToggle}>
					<FontAwesomeIcon className="icon" icon={faQuestionCircle} />
				</div>
				<OverlayPanel ref={el => (this.tutorialsPanel = el)}>
					<Tutorials />
				</OverlayPanel>
			</li>
		);

		return (
			<nav className="navbar">
				<span className="first-section">
					<Link to={RouteBasePath} className="logo">
						<img className="brand-logo" alt="" />
					</Link>

					<TablePagePicker />
				</span>

				<ul className="nav-links">
					{userProfile.firstName && (
						<>
							<li className="nav-item user-name">
								<span>
									{userProfile.firstName + (userProfile.lastName ? ' ' + userProfile.lastName : '')}
								</span>
							</li>
							<li className="nav-item header-divider brand-link" />
						</>
					)}

					{/* {userProfile.firstName && (
						<>
							<li className="nav-item brand-link">
								<a href={this.getLink()} target="_blank" rel="noopener noreferrer">
									{IsBrandBramidan ? 'Bramidan' : 'Presto'}
								</a>
							</li>
							<li className="nav-item brand-link header-divider" />
						</>
					)} */}

					{this.props.isAuthenticated && (
						<>
							{releaseNotesItem}
							{tutorialsItem}
						</>
					)}

					{authPermissionCheck(this.props.userGroupPermissions, administrationPageAuthRequirements) && (
						<li className="nav-item">
							<NavLink activeClassName="current-page" to={Routes.Administration}>
								{localized('Administration')}
							</NavLink>
						</li>
					)}
					{authPermissionCheck(this.props.userGroupPermissions, profilePageAuthRequirements) && (
						<li className="nav-item">
							<NavLink activeClassName="current-page" to={Routes.Profile}>
								{localized('Profile')}
							</NavLink>
						</li>
					)}
					{/* <li className="nav-item">
						<NavLink activeClassName="current-page" to={Routes.Help}>
							{localized('Help')}
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink activeClassName="current-page" to={Routes.Settings}>
							{localized('Settings')}
						</NavLink>
					</li> */}
					{this.props.isAuthenticated ? (
						<li className="nav-item">
							<NavLink activeClassName="current-page" to={Routes.SignOutRedirect}>
								{localized('SignOut')}
							</NavLink>
						</li>
					) : (
						<li className="nav-item">
							<NavLink activeClassName="current-page" to={Routes.SignInRedirect}>
								{localized('SignIn')}
							</NavLink>
						</li>
					)}
				</ul>
			</nav>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
