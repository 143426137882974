import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
	'GetDashboardContent',
	'GetSingleDashboardTile',
	'CreateDashboardContent',
	'UpdateDashboardContent',
	'DeleteDashboardContent',
	'SetMenuId',
	'CREATE_REPORT_TILE',
	'CREATE_STATIC_MAP_LOCATION',
	'SET_PICKUPS_DATE_TYPE'
);
