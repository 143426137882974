import { StatusSeverity } from 'api/api';
import * as React from 'react';
import { ErrorIdKey, getLocalizedUnitStatusErrorByErrorString } from 'utilities/machine-error-util';
import BiTooltip from '../bi-tooltip/bi-tooltip';
import {
	ErrorOrReadonlyErrorArray, IconType, JsxIconAndType,
	unitStatusIconError,
	unitStatusIconErrorAndType, unitStatusIconMessage, unitStatusIconMessageAndType, unitStatusIconOkAndType, unitStatusIconOkAndTypeWithTooltip, unitStatusIconWarning,
	unitStatusIconWarningAndType
} from './constants';
import { getMostPrioritized } from './unit-status';

export function getUnitStatusIconForControllerWithErrors(
	errorIds: ErrorOrReadonlyErrorArray,
	enableTooltip: boolean = true
): JsxIconAndType {
	const defaultResult = enableTooltip ? unitStatusIconOkAndTypeWithTooltip : unitStatusIconOkAndType;

	const iconIfAny = getUnitStatusIconForFailureInformation(errorIds, enableTooltip);

	return iconIfAny || defaultResult;
}

function getUnitStatusIconForFailureInformation(
	errorIds: ErrorOrReadonlyErrorArray,
	enableTooltip: boolean
): JsxIconAndType | null {
	const errorIfAny = getMostPrioritized(errorIds, StatusSeverity.Error);
	if (errorIfAny !== undefined) {
		return getUnitStatusIcon(
			errorIfAny.readableError!,
			enableTooltip,
			'Error',
			unitStatusIconErrorAndType,
			unitStatusIconError
		);
	}

	const warningIfAny = getMostPrioritized(errorIds, StatusSeverity.Warning);
	if (warningIfAny !== undefined) {
		return getUnitStatusIcon(
			warningIfAny.readableError!,
			enableTooltip,
			'Warning',
			unitStatusIconWarningAndType,
			unitStatusIconWarning
		);
	}

	const messageIfAny = getMostPrioritized(errorIds, StatusSeverity.Message);
	if (messageIfAny !== undefined) {
		return getUnitStatusIcon(
			messageIfAny.readableError!,
			enableTooltip,
			'Message',
			unitStatusIconMessageAndType,
			unitStatusIconMessage
		);
	}

	// There are no valid error flags
	return null;
}

function getUnitStatusIcon(
	readableError: string,
	enableTooltip: boolean,
	iconType: IconType,
	statusIconAndType: JsxIconAndType,
	statusIcon: JSX.Element
): JsxIconAndType {
	return enableTooltip
		? {
				iconType: iconType,
				icon: <BiTooltip overlay={getLocalizedUnitStatusErrorByErrorString(readableError as ErrorIdKey)}>{statusIcon}</BiTooltip>,
		  }
		: statusIconAndType;
}
