import { MoveUnitDto, RolesType, IUnitSelectedDto } from 'api/api';
import { AppState } from 'state/store';
import { DialogProps } from 'primereact/dialog';
import { ScrollPanel } from 'primereact/scrollpanel';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { updateMachinesOwnerGroup } from 'state/ducks/machines/operations';
import { localized } from 'state/i18n';
import { mapTreeItemsToTreeSelectItems } from 'utilities/array-to-tree';
import { getEmailFromToken } from 'utilities/token-util';
import BiTreeSelect from 'view/components/bi-tree-select/bi-tree-select';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import MachineDetailsRow from './machine-details-row';
import './move-machine.scss';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';

const mapStateToProps = (state: AppState) => {
	const userGroups = state.groupsReducer.groups.find(g => g.id === getEmailFromToken());

	// Filter selected machineId's based on userrole.
	const selectedMachineIds: number[] = state.tableSettingsReducer.selectedMachines.filter(machineId => {
		const machine: IUnitSelectedDto | undefined = state.machineSelectedReducer.machinesSelected
			? state.machineSelectedReducer.machinesSelected.find(m => m.id === machineId)
			: undefined;
		return machine && userGroups
			? userGroups.data.some(ug => ug.id === machine.ownerGroupId && ug.roleType <= RolesType.Admin)
			: false;
	});

	return {
		userGroups: userGroups,
		selectedMachineIds: selectedMachineIds,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	updateMachinesOwnerGroup: async (moveMachineDto: MoveUnitDto, groupName: string) =>
		(await updateMachinesOwnerGroup(moveMachineDto, groupName))(dispatch),
});

export interface EditColumnProps extends DialogProps {}
interface State {
	selectedGroup: number[];
	selectedGroupLabel: string;
	isSavingData: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & EditColumnProps;

class MoveMachine extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedGroup: [],
			selectedGroupLabel: '',
			isSavingData: false,
		};
	}

	public render() {
		const groupHierarchy = this.props.userGroups ? mapTreeItemsToTreeSelectItems(this.props.userGroups.data) : [];
		const selectedMachines = this.getSelectedMachinesFormatted();
		const dialogDescription = this.getDialogDescription();
		const cancelBtn = this.getCancelBtn();
		const machinesSelected: boolean = this.props.selectedMachineIds.length ? true : false;
		const minHeight: string = machinesSelected ? 'height-800px' : '';
		return (
			<BiTextDialog
				dismissableMask={true}
				title={localized('MoveMachine')}
				subtitle={dialogDescription}
				{...this.props}
			>
				<div className="p-grid no-select">
					<ScrollPanel className={'scrollpanel-container-big flex-fill-width ' + minHeight}>
						<div className="margin-10px">
							{machinesSelected && (
								<div>
									<BiTreeSelect
										onCheckChanged={this.groupsChanged}
										nodes={groupHierarchy}
										selectedItems={this.state.selectedGroup}
										className={'margin-bottom-10px'}
									/>

									<div className="header-container">
										<p className="header-details">{localized('unitId')}</p>
										<p className="header-details">{localized('unitModel')}</p>
										<p className="header-details">{localized('wasteType')}</p>
										<p className="header-details">{localized('currentGroup')}</p>
										<span></span>
										<p className="header-details">{localized('chosenGroup')}</p>
									</div>

									{selectedMachines}
								</div>
							)}
						</div>
					</ScrollPanel>
				</div>

				{this.props.selectedMachineIds.length ? (
					<div className="flex-end-row margin-top-10px">
						<BiButton
							colorTheme="org-red"
							containerTheme="slim-with-rounded-corners"
							containerClassName="margin-right-24px"
							onClick={this.handleReset}
						>
							{localized('Reset')}
						</BiButton>
						{cancelBtn}
						<BiButton
							colorTheme="org-green"
							containerTheme="slim-with-rounded-corners"
							onClick={this.handleSaveColumns}
						>
							{localized('Save')}
						</BiButton>
					</div>
				) : (
					<div className="flex-end-row margin-top-10px">{cancelBtn}</div>
				)}
			</BiTextDialog>
		);
	}

	private getCancelBtn = (): JSX.Element => {
		return (
			<BiButton
				colorTheme="org-red"
				containerTheme="slim-with-rounded-corners"
				containerClassName="margin-right-24px"
				onClick={this.handleCancel}
			>
				{localized('Cancel')}
			</BiButton>
		);
	};

	private getDialogDescription = (): string => {
		let text: string = '';
		if (this.props.selectedMachineIds.length) {
			text = localized('moveMachineDescription');
		} else {
			text = localized('moveMachineNoMachinesSelected');
		}
		return text;
	};

	private handleReset = () => {
		this.groupsChanged([]);
	};

	private isSelectedGroupValid = (selectedGroup: number): boolean => {
		// Check if the user has permission to move machine to the selected destination group
		return this.props.userGroups
			? this.props.userGroups.data.some(ug => ug.id === selectedGroup && ug.roleType <= RolesType.Admin)
			: false;
	};

	private handleSaveColumns = async () => {
		if (!this.state.selectedGroup[0]) {
			notificationService.showInfoMessage(undefined, localized('SelectGroup'), false, 5000);
		} else {
			if (this.state.isSavingData) {
				return;
			}
			this.setState({ isSavingData: true });
			let moveMachineDto: MoveUnitDto = new MoveUnitDto();

			moveMachineDto.unitIds = this.props.selectedMachineIds;
			moveMachineDto.newGroupId = this.state.selectedGroup[0];

			// Get the groupname of the selected destination group
			const group = this.props.userGroups
				? this.props.userGroups.data.find(grp => grp.id === moveMachineDto.newGroupId)
				: undefined;
			const groupName = group ? group.groupName : '';

			try {
				// You need to send the groupName to the reducer, to update the groupName in other redux states
				await this.props.updateMachinesOwnerGroup(moveMachineDto, groupName || '');
				this.props.onHide();
			} finally {
				this.setState({ isSavingData: false });
			}
		}
	};
	private getSelectedMachinesFormatted = () => {
		let selectedMachines: JSX.Element[] = this.props.selectedMachineIds.map(id => (
			<MachineDetailsRow key={id} selectedGroup={this.state.selectedGroupLabel} machineId={id} />
		));
		return <div>{selectedMachines}</div>;
	};

	private groupsChanged = (items: number[], breadCrumb?: string) => {
		let destinationGroupBreadCrumb: string = '';
		if (items.length) {
			if (!this.isSelectedGroupValid(items[0])) {
				notificationService.showErrorMessage(undefined, localized('noPermissionError'));
				return;
			}
			destinationGroupBreadCrumb = breadCrumb ? breadCrumb : '';
		}

		this.setState({
			selectedGroup: items,
			selectedGroupLabel: destinationGroupBreadCrumb,
		});
	};

	private handleCancel = () => {
		this.props.onHide();
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveMachine);
