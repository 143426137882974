import { UnitModelCodeClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { unitModelCodeClient } from '../api-clients';
import { getUnitModelCodesAction } from './actions';

export function getUnitModelCodes() {
	return AsyncOperationBuilder(
		getUnitModelCodesAction,
		apiClient => (apiClient as UnitModelCodeClient).getUnitModelCodes(),
		undefined,
		unitModelCodeClient
	);
}
