import { DashboardTileType, IDashboardBaseDto } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import DashboardTile from 'view/components/dashboard/dashboard-tile';
import BiSimpleInputText from 'view/shared/components/bi-input-text/bi-simple-input-text';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import './create-tile.scss';
import { ReportTileDateTypePicker } from './report-tile-date-type-picker';

interface PropsFromParent {
	tileNamePlaceholder: string;
	tile: IDashboardBaseDto;
	tileType: DashboardTileType;
	tileName?: string;
	btnDisabled: boolean;
	onTileAdded(): void;
	onTileNameChanged(e: React.FormEvent<HTMLInputElement>): void
}

type Props = PropsFromParent;

class CreateTile extends React.PureComponent<Props> {

	public render() {

		return (
			<div>
				<p className="font-size-normal location-name">{localized("NameOfLocationTile")}</p>
				<BiSimpleInputText value={this.props.tileName ?? ''} onChange={this.props.onTileNameChanged} />

				{this.getExtraContent()}
				
				<div className="content-wrapper flex-center-content">
					<DashboardTile
						disableMenu={true}
						tileName={this.props.tileName ?? `${this.props.tileNamePlaceholder}`}
						tile={this.props.tile}
						clickable={false}
					/>
				</div>

				<div className="flex-center-content add-tile-btn">
					<BiButton
						colorTheme="org-primary-grey"
						containerTheme="slim-with-rounded-corners"
						onClick={this.props.onTileAdded}
						disabled={this.props.btnDisabled}
					>
						{localized('AddTile')}
					</BiButton>
				</div>
			</div>
		);
	}

	private getExtraContent = () => {
		switch (this.props.tileType) {
			case DashboardTileType.ReportPrintout:
				return <ReportTileDateTypePicker />
			default:
				return <></>
		}
	}
}

export default CreateTile;
