import './bi-text-preview.scss';
import React from 'react';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';

interface AppProps {
	fullText?: string;
}

type Props = AppProps;

const maxTextLength = 30;

export default class BiTextPreview extends React.PureComponent<Props> {
	public render() {
		let textToRender = this.props.fullText;

		if (textToRender && textToRender.length > maxTextLength) {
			textToRender = this.props.fullText!.slice(0, maxTextLength) + '...';
		}

		return (
			<BiTooltip overlay={this.props.fullText}>
				<span className="bi-text-preview">{textToRender}</span>
			</BiTooltip>
		);
	}
}
