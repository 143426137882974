import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { localized } from 'state/i18n';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';

export class CloseButtonDialog extends React.PureComponent<DialogProps> {
	public render() {
		return (
			<BiTextDialog {...this.props} title={this.props.header}>
				{this.props.children}
				<div className="flex-end-row margin-top-10px">
					<BiButton
						onClick={this.props.onHide}
						colorTheme="org-red"
						containerClassName="margin-right-24px"
						containerTheme="slim-with-rounded-corners"
					>
						{localized('Close')}
					</BiButton>
				</div>
			</BiTextDialog>
		);
	}
}
