import { IUnitStateHistoryDto } from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getSettings } from 'state/ducks/history-table-settings/operations';
import { AppState } from 'state/store';
import { IdDataTuple } from 'utilities/types-util';
import HistoryTable from './history-table';
import { unitHistorySorter } from './history-table-data-sorting';
import './history-table-wrapper.scss';

const mapStateToProps = (state: AppState, ownProps: PropsFromParent) => {
	let machineHistories = state.unitHistoryReducer.machineHistories.find(curr => curr.id === ownProps.machineId);
	machineHistories?.data.sort((a, b) => b.updatedDate.getTime() - a.updatedDate.getTime());

	return {
		currMachineHistories: machineHistories,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getSettings: async () => (await getSettings())(dispatch),
});
interface PropsFromParent {
	isLoading?: boolean;
	machineId: string;
	filterOnFull: boolean;
	filterOnFillLevelChanged: boolean;
	isBaler: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class HistoryTableWrapper extends React.PureComponent<Props> {
	public async componentDidMount() {
		await this.props.getSettings();
	}

	public render() {
		return (
			<div className="history-table-container">
				<HistoryTable
					isLoading={this.props.isLoading}
					data={this.filterMachineHistories(this.props.currMachineHistories)}
					{...this.props}
				/>
			</div>
		);
	}

	private filterMachineHistories(currMachineHistories: IdDataTuple<IUnitStateHistoryDto[], string> | undefined) {
		if (!currMachineHistories) return currMachineHistories;

		// If table-sorting is applied in the table, the order of the data will be messed up, so need to re-sort the array, before we apply the filter.
		let filtered = currMachineHistories.data.sort(unitHistorySorter);

		if (this.props.filterOnFillLevelChanged && this.props.filterOnFull) {
			filtered = filtered.filter(this.fillLevelOrEmptyingFilter);
		} else if (this.props.filterOnFillLevelChanged) {
			filtered = filtered.filter((row, index, arr) =>
				this.props.isBaler
					? this.balesOnSiteChangedFilter(row, index, arr)
					: this.fillLevelChangedFilter(row, index, arr)
			);
		} else if (this.props.filterOnFull) {
			filtered = filtered.filter(this.emptyingFilter);
		}

		return filtered;
	}

	// Assumes that list is sorted from oldest to newest
	private fillLevelChangedFilter(row: IUnitStateHistoryDto, index: number, arr: IUnitStateHistoryDto[]): boolean {
		// Avoid index out of bounds
		if (index === 0) return true;

		// Has fill level changed from this to the next row?
		return row.fillLevel !== arr[index - 1].fillLevel;
	}

	// Assumes that list is sorted from oldest to newest
	private balesOnSiteChangedFilter(row: IUnitStateHistoryDto, index: number, arr: IUnitStateHistoryDto[]): boolean {
		// Avoid index out of bounds
		if (index === 0) return true;

		// Has fill level changed from this to the next row?
		return row.balesOnSite !== arr[index - 1].balesOnSite;
	}

	// Assumes that list is sorted from oldest to newest
	private emptyingFilter(row: IUnitStateHistoryDto, index: number, arr: IUnitStateHistoryDto[]): boolean {
		// Avoid index out of bounds
		if (index === 0) return true;

		// If units emptied changes show prev value to see fill level before emptying
		return row.unitsEmptied !== arr[index - 1].unitsEmptied;
	}

	// Assumes that list is sorted from oldest to newest
	private fillLevelOrEmptyingFilter = (row: IUnitStateHistoryDto, index: number, arr: IUnitStateHistoryDto[]) => {
		if (this.props.isBaler)
			return this.balesOnSiteChangedFilter(row, index, arr) || this.emptyingFilter(row, index, arr);

		return this.fillLevelChangedFilter(row, index, arr) || this.emptyingFilter(row, index, arr);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTableWrapper);
