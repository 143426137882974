import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setIsAuth } from 'state/ducks/auth/operations';
import { getProfile } from 'state/ducks/profile/operations';
import { Routes, unsubscribeRoutes } from 'state/ducks/routes';
import { getUserPermission } from 'state/ducks/user-permission/operations';
import { getUserSettings } from 'state/ducks/user-settings/operations';
import history from 'state/history';
import { AppState } from 'state/store';
import { getByRefreshToken, isAuthenticated, scheduleTokenRenewal } from 'utilities/auth-helper';
import { Spinner } from '../spinner/spinner';

interface PropsFromParent {
	children?: React.ReactNode;
}

const mapStateToProps = (state: AppState) => {
	return {
		isAuthenticated: state.authReducer.isAuthenticated,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setIsAuth: (isAuth: boolean) => setIsAuth(isAuth)(dispatch),
	getProfile: async () => (await getProfile())(dispatch),
	getUserPermissions: async () => (await getUserPermission())(dispatch),
	getUserSettings: async () => (await getUserSettings())(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class AuthInitializer extends React.PureComponent<Props> {
	public render() {
		if (this.props.isAuthenticated === undefined) {
			this.init();
		}

		if (this.props.isAuthenticated !== undefined) {
			return this.props.children;
		}

		return <Spinner />;
	}

	private async init() {
		let isAuth = isAuthenticated();

		if (
			!isAuth &&
			history.location.pathname !== Routes.SignIn &&
			history.location.pathname !== Routes.Callback &&
			!history.location.pathname.includes(unsubscribeRoutes.path)
		) {
			await getByRefreshToken();

			isAuth = isAuthenticated();
		}

		if (isAuth) {
			this.props.getProfile();
			this.props.getUserSettings();
			await this.props.getUserPermissions();
			scheduleTokenRenewal();
		}

		this.props.setIsAuth(isAuth);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthInitializer);
