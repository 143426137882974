import { IUnitTracker } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('tracker');

export const CreateUnitTrackerAction = actionCreator.async<{ unitId: number; trackerId: string }, IUnitTracker>(
	constants.CREATE_UNIT_TRACKER
);
export const GetUnitTrackerAction = actionCreator.async<{ serialNumber: string }, IUnitTracker>(
	constants.GET_UNIT_TRACKER
);
export const UpdateUnitTrackerIdAction = actionCreator.async<{ unitId: number; trackerId: string }, IUnitTracker>(
	constants.UPDATE_UNIT_TRACKER_ID
);
export const DeleteUnitTrackerAction = actionCreator.async<{ unitId: number }, void>(constants.DELETE_UNIT_TRACKER);

export const GetUnitTrackersAction = actionCreator.async<undefined, IUnitTracker[]>(constants.GET_UNIT_TRACKERS);
