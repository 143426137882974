import { handleApiError } from 'utilities/api-helper';
import { documentsClient } from '../api-clients';
import { FileResponse } from '../../../api/api';
import fileSaver from 'file-saver';
import mimeTypes from 'mime-types';

export async function showDocument(fileName: string) {
	const splitFileNames = fileName.split(',').map(filename => filename.trim());

	try {
		if (splitFileNames.length === 1) {
			let document: FileResponse | null = null;
			document = await documentsClient.getByFileName(fileName);

			if (!document) {
				return;
			}
			return URL.createObjectURL(document?.data);
		} else {
			for (const splitFileName of splitFileNames) {
				const fileResponse = await documentsClient.getByFileNameAsDownload(splitFileName);

				if (fileResponse) {
					const fileExt = splitFileName.split('.')?.at(-1);
					const type = fileExt ? mimeTypes.lookup(fileExt) : undefined;
					const blob = new Blob([fileResponse.data], {
						type: type ? type : undefined,
					});
					fileSaver.saveAs(blob, splitFileName.split('/')?.at(-1));
				}
			}
		}
	} catch (error) {
		handleApiError(error);
	}
}
