import { PartnerAreaClient } from 'api/api';
import { AsyncOperationBuilder, handleApiError } from 'utilities/api-helper';
import { partnerAreaClient } from '../api-clients';
import {
	deletePartnerAreaFileAction,
	deletePartnerAreaFolderAction,
	getPartnerAreaFileTreeAction,
	getPartnerAreaFileTreeByPathAction,
	getPartnerAreaPreviewFileAction,
	getPartnerAreaRootFolderAction,
} from './actions';
import { downloadFile } from 'utilities/download-helper';

export const GetPartnerAreaFileTree = () =>
	AsyncOperationBuilder(
		getPartnerAreaFileTreeAction,
		apiClient => (apiClient as PartnerAreaClient).getFileTree(),
		undefined,
		partnerAreaClient
	);

export const GetPartnerGetRootFolderContent = () =>
	AsyncOperationBuilder(
		getPartnerAreaRootFolderAction,
		apiClient => (apiClient as PartnerAreaClient).getRootFolderContent(),
		undefined,
		partnerAreaClient
	);

export const GetPartnerAreaFileTreeByPath = (path: string) =>
	AsyncOperationBuilder(
		getPartnerAreaFileTreeByPathAction,
		apiClient => (apiClient as PartnerAreaClient).getFileTreeByPath(path),
		path,
		partnerAreaClient
	);

export async function GetPartnerAreaFile(fileName: string) {
	try {
		const document = await partnerAreaClient.getByFileName(fileName);
		if (!document) {
			return;
		}
		downloadFile(document);
	} catch (error) {
		handleApiError(error);
	}
}

export const GetPartnerAreaPreviewFile = (fileName: string) =>
	AsyncOperationBuilder(
		getPartnerAreaPreviewFileAction,
		apiClient => (apiClient as PartnerAreaClient).getPreviewByFileName(fileName),
		fileName,
		partnerAreaClient
	);

export const DeletePartnerAreaFile = (fileName: string) =>
	AsyncOperationBuilder(
		deletePartnerAreaFileAction,
		apiClient => (apiClient as PartnerAreaClient).deleteByFileName(fileName),
		fileName,
		partnerAreaClient
	);

export const DeletePartnerAreaFolder = (folderName: string) =>
	AsyncOperationBuilder(
		deletePartnerAreaFolderAction,
		apiClient => (apiClient as PartnerAreaClient).deleteByFolderName(folderName),
		folderName,
		partnerAreaClient
	);
