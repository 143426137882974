import { CreateGroupsDto, EditGroupsDebtorDto, EditGroupsDto, GroupsClient, ICreateGroupsDto, IEditGroupsDto, IUserGroupPermissionsDto, RolesType, UserGroupPermission, UserGroupPermissionsClient } from 'api/api';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { Action } from 'typescript-fsa';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { getEmailFromToken } from 'utilities/token-util';
import { groupsClient, userGroupPermissionsClient } from '../api-clients';
import { CreateChildGroupAction, DeleteGroupAction, DeleteUserGroupPermissionsAction, EditGroupAction, EditGroupDebtorAction, GetUserGroupsChildHierarchyAction, GetUsersAdminGroupsChildHierarchyAction, GetUsersCountryAdminGroupsChildHierarchyAction, GetUsersSuperAdminGroupsChildHierarchyAction, GetUsersThatIOwnAction, ResendConfirmationEmailAction, SetCurrentGroupAction, SetUserPermissionsAction, CreateUserWithGroupPermissionAction } from './actions';

/**
 * Get the current logged in user's children groups hierarchy .
 */
export async function getUserGroupsChildHierarchy(minRolesType: RolesType = RolesType.BasicUser) {
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			const res = await groupsClient.getChildTreeByUserId(minRolesType);
			dispatch(GetUserGroupsChildHierarchyAction.done({ params: getEmailFromToken(), result: res }));
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		}
	};
}

export function getUsersAdminGroupsChildHierarchy() {
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			const res = await groupsClient.getAdminChildTreeByUserId();
			dispatch(GetUsersAdminGroupsChildHierarchyAction.done({ params: getEmailFromToken(), result: res }));
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		}
	};
}

export async function getUsersCountryAdminGroupsChildHierarchy() {
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			const res = await groupsClient.getCountryAdminChildTreeByUserId();
			dispatch(GetUsersCountryAdminGroupsChildHierarchyAction.done({ params: getEmailFromToken(), result: res }));
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		}
	};
}

export async function getUsersSuperAdminGroupsChildHierarchy() {
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			const res = await groupsClient.getSuperAdminChildTreeByUserId();
			dispatch(GetUsersSuperAdminGroupsChildHierarchyAction.done({ params: getEmailFromToken(), result: res }));
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		}
	};
}

export async function getUsersThatIOwn(groupIds: number[]) {
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			const res = await userGroupPermissionsClient.getWithConfirmationStatusByGroupIds(groupIds);
			dispatch(GetUsersThatIOwnAction.done({ params: res['userId'], result: res }));
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		}
	};
}

export async function upsertUserGroupPermission(ugp: IUserGroupPermissionsDto, previousGroupId?: number) {
	const ugpToSend = new UserGroupPermission();
	ugpToSend.init(ugp);

	return AsyncOperationBuilder(
		SetUserPermissionsAction,
		apiClient =>
			(apiClient as UserGroupPermissionsClient).updateUserGroupPermission(ugpToSend, previousGroupId),
		{ ugp: ugpToSend, previousGroupId: previousGroupId || 0 },
		userGroupPermissionsClient
	);
}

export async function createUserGroupPermission(ugp: IUserGroupPermissionsDto) {
	const ugpToSend = new UserGroupPermission();
	ugpToSend.init(ugp);

	return AsyncOperationBuilder(
		CreateUserWithGroupPermissionAction,
		apiClient =>
			(apiClient as UserGroupPermissionsClient).createUserGroupPermission(ugpToSend),
		ugpToSend,
		userGroupPermissionsClient
	);
}

export function deleteUserGroupPermission(userId: string, groupId: number, roleType: number, previousGroupId?: number) {
	const param = previousGroupId ? previousGroupId : undefined;
	return AsyncOperationBuilder(
		DeleteUserGroupPermissionsAction,
		apiClient => (apiClient as UserGroupPermissionsClient).removeUserGroupPermission(userId, groupId, roleType),
		param,
		userGroupPermissionsClient
	);
}

export async function resendConfirmationEmail(
	userId: string,
	groupId: number,
) {
	return AsyncOperationBuilder(
		ResendConfirmationEmailAction,
		apiClient => (apiClient as UserGroupPermissionsClient).resendConfirmationEmail(userId, groupId),
		null,
		userGroupPermissionsClient
	);
}

export function setCurrentGroup(groupId: number) {
	return (dispatch: Dispatch<Action<{}>>) => {
		dispatch(SetCurrentGroupAction(groupId));
	};
}

export async function createChildGroup(childGroup: ICreateGroupsDto) {
	return AsyncOperationBuilder(
		CreateChildGroupAction,
		apiClient => (apiClient as GroupsClient).createChildGroup(new CreateGroupsDto(childGroup)),
		getEmailFromToken(),
		groupsClient
	);
}

export async function editGroup(editGroupsDto: IEditGroupsDto) {
	return AsyncOperationBuilder(
		EditGroupAction,
		apiClient => (apiClient as GroupsClient).editGroup(new EditGroupsDto(editGroupsDto)),
		editGroupsDto,
		groupsClient
	);
}

export async function deleteGroup(groupsId: number) {
	return AsyncOperationBuilder(
		DeleteGroupAction,
		apiClient => (apiClient as GroupsClient).delete(groupsId),
		groupsId,
		groupsClient
	);
}

export async function editGroupDebtor(editGroupsDto: EditGroupsDebtorDto) {
	return AsyncOperationBuilder(
		EditGroupDebtorAction,
		apiClient => (apiClient as GroupsClient).editGroupDebtor(editGroupsDto),
		editGroupsDto,
		groupsClient
	);
}