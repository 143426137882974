import React from 'react';
import { IServiceContractTileDto, IDashboardChartItem } from 'api/api';
import { Chart } from 'primereact/chart';
import { InsertChartItem } from '../dashboard-util';
import { colorGrey, colorYellow, colorRed, colorGreenLight } from 'view/styles/colors';
import { localized } from 'state/i18n';
import { DashboardTable } from './dashboard-table/dashboard-table';

interface PropsFromParent {
	tile: IServiceContractTileDto;
}

type Props = PropsFromParent;

const pieServiceOptions: Chart.ChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	legend: {
		display: false,
	},
	tooltips: {
		mode: 'index',
		intersect: true,
	},
	animation: {
		duration: 0,
	},
};
 
export class ServiceContractTile extends React.PureComponent<Props> {
	public render() {
		if (!this.props.tile) {
			return null;
		}

		const labels: string[] = [];
		const dataSets: number[] = [];
		const backgroundColor: string[] = [];

		const chartItems: IDashboardChartItem[] = [];

		if (this.props.tile.withinOneMonth && this.props.tile.withinOneMonth.chartValue) {
			this.props.tile.withinOneMonth.chartName = localized('WithinOneMonth');
			InsertChartItem(this.props.tile.withinOneMonth, labels, dataSets, backgroundColor, undefined, colorRed);

			chartItems.push(this.props.tile.withinOneMonth);
		}

		if (this.props.tile.betweenOneAndThreeMonths && this.props.tile.betweenOneAndThreeMonths.chartValue) {
			this.props.tile.betweenOneAndThreeMonths.chartName = localized('BetweenOneAndThreeMonths');
			InsertChartItem(
				this.props.tile.betweenOneAndThreeMonths,
				labels,
				dataSets,
				backgroundColor,
				undefined,
				colorYellow
			);

			chartItems.push(this.props.tile.betweenOneAndThreeMonths);
		}

		if (this.props.tile.overThreeMonths && this.props.tile.overThreeMonths.chartValue) {
			this.props.tile.overThreeMonths.chartName = localized('OverThreeMonths');

			InsertChartItem(this.props.tile.overThreeMonths, labels, dataSets, backgroundColor, undefined, colorGreenLight);

			chartItems.push(this.props.tile.overThreeMonths);
		}

		if (this.props.tile.notFilledOut && this.props.tile.notFilledOut.chartValue) {
			this.props.tile.notFilledOut.chartName = localized('NotFilledOut');
			InsertChartItem(this.props.tile.notFilledOut, labels, dataSets, backgroundColor, undefined, colorGrey);

			chartItems.push(this.props.tile.notFilledOut);
		}

		const data = {
			labels: labels,
			datasets: [
				{
					data: dataSets,
					backgroundColor: backgroundColor,
					hoverBackgroundColor: backgroundColor,
				},
			],
		};

		return (
			<>
				<div className="border-bottom-grey margin-bottom-20px" />
				<div className="dashboard-chart-pie">
					<Chart type="pie" className="margin-right-24px" data={data} options={pieServiceOptions} />
					<div className="border-right-grey" />
					<DashboardTable
						backgroundColors={backgroundColor}
						maxRows={5}
						chartItems={chartItems}
					/>
				</div>
			</>
		);
	}
}
