import actionCreatorFactory from 'typescript-fsa';
import { SortingGeneric, TableColumnWidthInfoGeneric } from '../history-table-settings/types';
import { constants } from './constants';
import { UnitCycleDtoDtoKeys } from './types';

const actionCreator = actionCreatorFactory("pressure-graph-history-settings");

export const setColumnOrderAction = actionCreator<UnitCycleDtoDtoKeys[]>(constants.SET_COLUMN_ORDER);
export const setColumnSizeAction = actionCreator<TableColumnWidthInfoGeneric<UnitCycleDtoDtoKeys>[]>(
	constants.SET_COLUMN_SIZES
);
export const setColumnSortingAction = actionCreator<SortingGeneric<UnitCycleDtoDtoKeys>[]>(
	constants.SET_COLUMN_SORTING
);
