import { ComparisonOperator as Comparer, ComparerInput } from 'api/api';
import React, { useCallback } from 'react';
import { ComparisonOperator } from './comparison-operator';
import './comparison-operators.scss';

interface Props {
	onOperatorChanged: (operator: Comparer) => void;
	value: Comparer;
	id: string | number;
	selectedComparerInput: ComparerInput;
}

// Handle special cases
export const comparisonOperatorsEqualOnly: ComparerInput[] = [
	ComparerInput.WarrantyExpiresXDays,
	ComparerInput.NextServiceXDays,
	ComparerInput.DaysSinceInstalled,
];

const comparisonOperatorsWithReoccuring: ComparerInput[] = [
	ComparerInput.TotalRunningHours,
	ComparerInput.UnitsEmptied,
	ComparerInput.TotalNumberOfCycles,
];

export const comparisonOperatorsSmallerThanOnly: ComparerInput[] = [
	ComparerInput.FillLevelEmptyBelowThreshold,
	ComparerInput.FillLevelEmptyBelowThreshold2Times,
	ComparerInput.FillLevelEmptyBelowThreshold3Times,
];

export const ComparisonOperators: React.FC<Props> = props => {
	const { onOperatorChanged, id, value, selectedComparerInput } = props;

	const onComparisonOperatorChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selected = parseInt(e.target.value) as Comparer;
		onOperatorChanged(selected);
	};

	const getOperators = useCallback(() => {
		switch (true) {
			case comparisonOperatorsEqualOnly.includes(selectedComparerInput):
				return <ComparisonOperator id={id} comparer={Comparer.Equal} value={value} />;
			case comparisonOperatorsWithReoccuring.includes(selectedComparerInput):
				return (
					<>
						<ComparisonOperator id={id} comparer={Comparer.BiggerThan} value={value} />
						<ComparisonOperator id={id} comparer={Comparer.Equal} value={value} />
						<ComparisonOperator id={id} comparer={Comparer.SmallerThan} value={value} />
						<ComparisonOperator id={id} comparer={Comparer.Interval} value={value} />
					</>
				);
			case comparisonOperatorsSmallerThanOnly.includes(selectedComparerInput):
				return <ComparisonOperator id={id} comparer={Comparer.SmallerThan} value={value} />;
			default:
				return (
					<>
						<ComparisonOperator id={id} comparer={Comparer.BiggerThan} value={value} />
						<ComparisonOperator id={id} comparer={Comparer.Equal} value={value} />
						<ComparisonOperator id={id} comparer={Comparer.SmallerThan} value={value} />
					</>
				);
		}
	}, [id, selectedComparerInput, value]);

	return (
		<div className="comparison-operators" onChange={onComparisonOperatorChanged}>
			{getOperators()}
		</div>
	);
};
