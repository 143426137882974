import { ICoordinateDto, IUnitWithLocationDto, IStaticMapMachineDto } from 'api/api';
import React from 'react';
import { StaticMapsImageUrl, StaticMapsKey, StaticMapUrlMaxSize } from 'utilities/constants';
import './static-map.scss';
import { localized } from 'state/i18n';
import { getMarkerByFillLevel } from 'utilities/location-helper';

const staticMapBaseUrl = `https://maps.googleapis.com/maps/api/staticmap?`;

export const getStaticTileMap = (
	center: ICoordinateDto,
	zoom: number,
	machines: IStaticMapMachineDto[] | IUnitWithLocationDto[],

	width?: string,
	height?: string
) => {
	let markers: string = '';

	// Adding markers to the static image
	for (const machine of machines) {
		markers += `&markers=size:small|scale:4|icon:${encodeURI(
			StaticMapsImageUrl + getMarkerByFillLevel(machine.fillLevel, machine.hasModem, machine.isOnline)
		)}|${machine.latitude || '55.4683887'},${machine.longitude || '8.6662475'}`;
	}

	const zoomConstant = 2;
	const zoomLevel = zoom >= zoomConstant ? zoom - zoomConstant : zoom;

	const src = `
        ${staticMapBaseUrl}
        ${markers}
        &scale=2
        &size=${width || '640'}x${height || '327'}
        &zoom=${zoomLevel}
        &center=${center.latitude},${center.longitude}
        &key=${StaticMapsKey || 'AIzaSyCb7Y0kpBBl0Ttu3SWStdEAkPFWnruY1kY'}
		`;

	if (src.length >= StaticMapUrlMaxSize) {
		return <p className="subtitle padding-horizontal-32px">{localized('StaticMapTooManyMachinesError')}</p>;
	}

	return <img draggable={false} width="100%" style={{ maxHeight: height }} src={src} alt={'Machines Location'} />;
};
