import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { FilterDateSearchState } from './types';

export const initialState: FilterDateSearchState = {
    filterDateStrings: {
        history: {}
    },
	homeLocationChecked: true

}

export function filterDateSearchReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
    if (isType(action, actions.SetFilterHomeLocation)) {
		return {
			...state,
			homeLocationChecked: !state.homeLocationChecked,
		};
	}
    if (isType(action, actions.SetFilterStartDateAction)) {
        const filterDateStringsCopy = { ...state.filterDateStrings }
        filterDateStringsCopy[action.payload.id].startDate = action.payload.input;

        // Returning just the stateCopy without spreading it out won't result in any re-render.
        return {
            ...state,
            filterDateStrings: filterDateStringsCopy,
        };
    } else if (isType(action, actions.SetFilterEndDateAction)) {
        const filterDateStringsCopy = { ...state.filterDateStrings }
        filterDateStringsCopy[action.payload.id].endDate = action.payload.input;

        // Returning just the stateCopy without spreading it out won't result in any re-render.
        return {
            ...state,
            filterDateStrings: filterDateStringsCopy,
        };
    }
    

    return state;
}
