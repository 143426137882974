import React from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import './bi-dialog.scss';

export interface EditColumnProps extends DialogProps {}

type Props = EditColumnProps;

class BiDialog extends React.PureComponent<Props> {
	public render() { 
		return (
			<Dialog
				{...this.props}
				className={`${this.props.className} dialog-width dialog-padding`}
				appendTo={this.props.appendTo || document.body}
			>
				{this.props.children}
			</Dialog>
		);
	}
}

export default BiDialog;
