import { InputText } from 'primereact/inputtext';
import * as React from 'react';
import { connect } from 'react-redux';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { getEmailFromToken } from 'utilities/token-util';
import { Spinner } from 'view/components/spinner/spinner';
import BiCheckbox, { BiCheckboxProps } from 'view/shared/components/bi-checkbox/bi-checkbox';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import DebtorItemDialog from './debtor-item-dialog';
import { DebtorWithImage } from './debtor-with-image';

const mapStateToProps = (state: AppState, parentProps: AppProps) => {
	const userGroupsTuple = state.groupsReducer.adminGroups.find(g => g.id === getEmailFromToken());
	const currGroupData = userGroupsTuple ? userGroupsTuple.data.find(g => g.id === parentProps.groupId) : undefined;

	return {
		currGroupData,
	};
};

interface AppProps {
	groupId: number;
	onSave: (
		groupId: number,
		isDistributor: boolean,
		debtorNumber?: string,
		countryCode?: string,
		debtor?: DebtorWithImage
	) => Promise<void>;
}

type Props = Partial<ReturnType<typeof mapStateToProps>> & AppProps;

interface State {
	newIsDistributor: boolean;
	newDebtorNumber?: string;
	newCountryCode?: string;
	isSavingData: boolean;
	showDialog: boolean;
}

class DebtorItem extends React.PureComponent<Props, State> {
	private newDebtor?: DebtorWithImage;

	constructor(props: Props) {
		super(props);
		this.state = {
			isSavingData: false,
			newIsDistributor: props.currGroupData ? props.currGroupData.isDistributor : false,
			newCountryCode: props.currGroupData ? props.currGroupData.countryCode || '' : '',
			newDebtorNumber: props.currGroupData ? props.currGroupData.debtorNumber || '' : '',
			showDialog: false,
		};
	}

	public render() {
		return (
			<div className="margin-bottom-10px debtor-row bi-label-content-item-box-shadow">
				<div>{this.props.currGroupData?.groupName}</div>

				<InputText
					placeholder={localized('EnterANewDebtorNumber')}
					className="input-box-width-100-bramidan"
					value={this.state.newDebtorNumber}
					onChange={this.setNewDebtorNumber}
				/>
				<InputText
					placeholder={localized('EnterANewCountryCode')}
					className="input-box-width-100-bramidan"
					value={this.state.newCountryCode}
					onChange={this.setNewCountryCode}
				/>
				<BiCheckbox
					containerClassName="debtor-checkbox-center"
					checked={this.state.newIsDistributor}
					onChange={this.setNewIsDistributor}
				/>
				<BiButton
					onClick={this.showDialog}
					colorTheme="org-primary-grey"
					containerTheme="normal-button-corners"
					containerClassName="debtor-item-button-container debtor-checkbox-center"
				>
					<span className="text-bold">{localized('Edit')}</span>
				</BiButton>
				<BiButton
					onClick={this.save}
					disabled={this.state.isSavingData}
					colorTheme="org-primary-grey"
					containerTheme="normal-button-corners"
					containerClassName="debtor-item-button-container"
				>
					{this.state.isSavingData ? (
						<Spinner
							shouldOverlay={false}
							shouldUseAbsolutePositioning={false}
							spinnerSize="spinner-container-1-4th-size"
							useMarginsForCorrectingCentering={false}
						/>
					) : (
						<span className="text-bold">{localized('Save')}</span>
					)}
				</BiButton>
				<DebtorItemDialog
					visible={this.state.showDialog}
					onHide={this.hideDialog}
					onSave={this.setDebtorAndSave}
					groupId={this.props.groupId}
				/>
			</div>
		);
	}

	private showDialog = () => {
		this.setState({ showDialog: true });
	};

	private hideDialog = () => {
		this.setState({ showDialog: false });
	};

	private setNewDebtorNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newDebtorNumber: e.target.value });
	};

	private setNewCountryCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newCountryCode: e.target.value });
	};

	private setNewIsDistributor = (e: Parameters<NonNullable<BiCheckboxProps['onChange']>>[0]) => {
		this.setState({ newIsDistributor: e.target.checked });
	};

	private setDebtorAndSave = (newDebtor: DebtorWithImage) => {
		this.newDebtor = newDebtor;
		this.save();
	};

	private save = async () => {
		if (this.state.isSavingData || !this.validateSave()) {
			return;
		}

		this.setState({ isSavingData: true });
		await this.props.onSave(
			this.props.groupId,
			this.state.newIsDistributor,
			this.state.newDebtorNumber,
			this.state.newCountryCode,
			this.newDebtor
		);
		this.setState({ isSavingData: false });
	};

	private validateSave = () => {
		return true;
	};
}

export default connect(mapStateToProps, null)(DebtorItem);
