import { IServiceVideoDto, ServiceVideoClient, ServiceVideoDto } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { serviceVideoClient } from '../api-clients';
import { createServiceVideoAction, getServiceVideosAction, getAllServiceVideosAction, deleteServiceVideosAction } from './actions';

export async function getServiceVideosByMachineTypeOperation(machineType: string) {
	return AsyncOperationBuilder(
		getServiceVideosAction,
		apiClient => (apiClient as ServiceVideoClient).getByMachineType(machineType),
		machineType,
		serviceVideoClient
	);
}

export async function createServiceVideoOperation(video: IServiceVideoDto) {
	return AsyncOperationBuilder(
		createServiceVideoAction,
		apiClient => (apiClient as ServiceVideoClient).post(ServiceVideoDto.fromJS(video)),
		video,
		serviceVideoClient
	);
}

export async function getAllServiceVideosOperation() {
	return AsyncOperationBuilder(
		getAllServiceVideosAction,
		apiClient => (apiClient as ServiceVideoClient).get(),
		undefined,
		serviceVideoClient
	);
}

export async function deleteServiceVideosOperation(video: IServiceVideoDto) {
	return AsyncOperationBuilder(
		deleteServiceVideosAction,
		apiClient => (apiClient as ServiceVideoClient).delete(ServiceVideoDto.fromJS(video)),
		video,
		serviceVideoClient
	);
}
