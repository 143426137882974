import { IIoTHubDuplicateDecommissionDto, IoTHubDuplicateDecommissionDto, IoTHubDuplicateDecommissionClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { ioTHubDuplicateDecommissionClient } from '../api-clients';
import { DeleteIoTHubDuplicateDecommissionAction, GetIoTHubDuplicateDecommissionsAction } from './actions';

export const getDuplicateIotHubDecommissionsOperation = () =>
	AsyncOperationBuilder(
		GetIoTHubDuplicateDecommissionsAction,
		apiClient => (apiClient as IoTHubDuplicateDecommissionClient).getDuplicates(),
		undefined,
		ioTHubDuplicateDecommissionClient
	);

export const decommissionDuplicateIotHubOperation = (
	iotHubDeviceIdToDelete: string,
	duplicateDecommission: IIoTHubDuplicateDecommissionDto
) => {
	return AsyncOperationBuilder(
		DeleteIoTHubDuplicateDecommissionAction,
		apiClient =>
			(apiClient as IoTHubDuplicateDecommissionClient).deleteDuplicate(
				iotHubDeviceIdToDelete,
				IoTHubDuplicateDecommissionDto.fromJS(duplicateDecommission)
			),
		iotHubDeviceIdToDelete,
		ioTHubDuplicateDecommissionClient
	);
};
