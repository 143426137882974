import { IUserGroupPermissionsDto } from 'api/api';
import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';

export function isEditableField(ugpObj: IUserGroupPermissionsDto, ugpArr: IUserGroupPermissionsDto[]): boolean {
	return !ugpArr.includes(ugpObj);
}

export function UserInputIsValid(user: IUserGroupPermissionsDto) {
	let isValid = true;
	if (user) {
		if (
			!user.userId ||
			!user.userId.length ||
			user.groupId === undefined ||
			user.groupId === 0 ||
			user.roleType === undefined
		) {
			isValid = false;
		}
	}
	return isValid;
}

export function UserExistInGroup(
	userId: string,
	groupId: number,
	userGroupPermissoins: IUserGroupPermissionsDto[],
	message: boolean = true
) {
	let userExists = true;
	if (userGroupPermissoins.some(usr => usr.userId === userId && usr.groupId === groupId)) {
		if (message) {
			notificationService.showErrorMessage(localized('UserExistsInSelectedGroup'));
		}
	} else {
		userExists = false;
	}
	return userExists;
}
