import { DateTypes } from './../../../api/api';
import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import * as actions from './actions';
import { ReportsState } from './types';
import { reactAI } from 'services/telemetry-service';
import moment from 'moment';
import { DefaultReportTileDto } from './constants';

export const initialState: ReportsState = {
	loading: false,
	dataType: undefined,
	callCounter: 0,
	filterStartDate: moment(new Date())
		.subtract(6, 'months')
		.toDate(),
	filterEndDate: new Date(),
	reportType: 'Graph',
	dateType: DateTypes.Month,
	reportTileDateType: DateTypes.Week,
	reportTilePreview: DefaultReportTileDto,
	reportTileLoading: false,
	reportTileCallCounter: 0
};

export function reportReducer(state: ReportsState = initialState, action: Action): ReportsState {
	if (isType(action, actions.GetTableReportAction)) {
		return {
			...state,
			columns: action.payload.cols,
			tableDataset: action.payload.rows,
		};
	} else if (isType(action, actions.GetGraphReportAction)) {
		return {
			...state,
			graphDataset: action.payload,
		};
	} else if (isType(action, actions.ClearReportsAction)) {
		return {
			...state,
			columns: undefined,
			graphDataset: undefined,
			tableDataset: undefined,
			reportData: undefined,
			dataType: undefined
		};
	} else if (isType(action, actions.SetDataTypeAction)) {
		return {
			...state,
			dataType: action.payload,
		};
	} else if (isType(action, actions.GetReportDataAction.started)) {
		if (action.payload.callCounter <= state.callCounter) {
			return state;
		}

		return {
			...state,
			callCounter: action.payload.callCounter
		};
	} else if (isType(action, actions.GetReportDataAction.done)) {
		//This is not the newest request - ignore the value
		if (action.payload.params.callCounter !== state.callCounter) {
			reactAI.appInsights!.trackEvent({ name: 'ReportCallCountMismatch', properties: { stateCallCounter: state.callCounter, paramsCallCounter: action.payload.params.callCounter } });
			return state;
		}

		return {
			...state,
			reportData: action.payload.result
		};
	} else if (isType(action, actions.SetReportsLoadingStateAction)) {
		return {
			...state,
			loading: action.payload,
		};
	} else if (isType(action, actions.SetStartDateAction)) {
		return {
			...state,
			filterStartDate: action.payload
		};
	} else if (isType(action, actions.SetEndDateAction)) {
		return {
			...state,
			filterEndDate: action.payload
		};
	} else if (isType(action, actions.SetReportTypeAction)) {
		return {
			...state,
			reportType: action.payload
		};
	} else if (isType(action, actions.SetDateTypeAction)) {
		return {
			...state,
			dateType: action.payload
		};
	} else if (isType(action, actions.GetReportTilePreviewAction.started)) {
		return {
			...state,
			reportTileLoading: true,
			reportTileCallCounter: action.payload.callCounter
		};
	} else if (isType(action, actions.GetReportTilePreviewAction.done)) {
		if (state.reportTileCallCounter === action.payload.params.callCounter) {
			return {
				...state,
				reportTilePreview: action.payload.result,
				reportTileLoading: false
			};
		}
	} else if (isType(action, actions.SetReportTileDateTypeAction)) {
		return {
			...state,
			reportTileDateType: action.payload
		};
	} else if (isType(action, actions.ClearReportTilePreviewAction)) {
		return {
			...state,
			reportTilePreview: DefaultReportTileDto
		};
	}

	return state;
}
