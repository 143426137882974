import React from 'react';
import { changeLanguage } from '../../../../state/i18n';
import './language-picker.scss';
import { AppState } from 'state/store';
import { Dispatch } from 'redux';
import { IUserSettings, SelectedLanguage } from 'api/api';
import { upsertUserSettings } from 'state/ducks/user-settings/operations';
import { connect } from 'react-redux';
import LanguagePicker from './language-picker';
import { getSelectedLanguage } from 'utilities/language-util';

const mapStateToProps = (state: AppState) => {
	return {
		userSettings: state.userSettingReducer.userSettings,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	updateUserSettings: async (userSetting: IUserSettings) => (await upsertUserSettings(userSetting))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class SetUserLanguagePicker extends React.PureComponent<Props> {
	public render() {
		return (
			<LanguagePicker
				onSelectedChange={this.handleOnSiteLanguageChange}
				parentLanguage={this.props.userSettings.selectedLanguage}
			/>
		);
	}

	private handleOnSiteLanguageChange = async (selectedLanguage: SelectedLanguage) => {
		await changeLanguage(getSelectedLanguage(selectedLanguage));
		await this.props.updateUserSettings({
			...this.props.userSettings,
			selectedLanguage: selectedLanguage,
		});
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SetUserLanguagePicker);
