import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
import { SetRefreshRateAction } from './actions';
import { refreshRateKeys, availableRefreshRatesKeys } from './types';

export function setRefreshRate(id: refreshRateKeys, rate: availableRefreshRatesKeys) {
    return (dispatch: Dispatch<Action<{}>>) => {
        dispatch(SetRefreshRateAction({ id, rate }));
    }
}
