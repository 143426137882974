import {
	DayOfWeek,
	INotificationFillLevelSettingsDto,
	INotificationTypeDto,
	IUnitSelectedDto,
	NotificationMachineType,
	RolesType,
} from 'api/api';
import { store } from 'index';
import { ContainerModelName, SharkCompactorModelName } from 'utilities/constants';
import { isAtLeastSuperAdminFromState } from 'utilities/roles-util';
import { MachineSelectedState } from './../../../../state/ducks/machine-selected/types';

export function getRolePermissionFromUnitIds(unitIds?: number[]): RolesType {
	let lowestUserPermmisison: RolesType = Math.min(
		...store.getState().userPermissionReducer.UserGroupPermissions.map(permission => permission.roleType)
	);

	// If user only has one permissiongroup => return that permissions roletype
	if (store.getState().userPermissionReducer.UserGroupPermissions.length === 1) return lowestUserPermmisison;
	//if no machines are selected yet, return lowest (most access) permission
	if (!unitIds || unitIds.length === 0) return lowestUserPermmisison;

	let roletypes: RolesType[] = unitIds.map(id => getRolePermissionFromUnitId(id));
	return Math.max(...roletypes);
}

function getRolePermissionFromUnitId(unitId: number): RolesType {
	let groupId = (store.getState().machineSelectedReducer as MachineSelectedState).machinesSelected?.find(
		machineDto => machineDto.id === unitId
	)?.ownerGroupId;
	if (!groupId) return RolesType.DataOutOfRange;
	let groupDtos = store.getState().groupsReducer.groups.flatMap(g => g.data);
	let groupDto = groupDtos.find(g => g.id === groupId);
	if (!groupDto) return RolesType.DataOutOfRange;

	return groupDto.roleType;
}

interface ControllerToMachineType {
	key: string;
	value: NotificationMachineType;
}

const controllerToMachinetypeLookup: ControllerToMachineType[] = [
	{ key: 'JE846', value: NotificationMachineType.Baler },
	{ key: 'JE911', value: NotificationMachineType.Baler },
	{ key: 'JE882', value: NotificationMachineType.Compactor },
	{ key: 'JE982', value: NotificationMachineType.Compactor },
	{ key: 'JE910', value: NotificationMachineType.Link },
];

const extraModelsForNotification = [ContainerModelName];

function machineUnitIdsToIMachineSelectedDto(machineIds: number[]): IUnitSelectedDto[] {
	return machineIds
		.map(id =>
			(store.getState().machineSelectedReducer as MachineSelectedState).machinesSelected?.find(
				selectedMachine => selectedMachine.id === id
			)
		)
		.filter(
			(selectedMachine: IUnitSelectedDto | undefined): selectedMachine is IUnitSelectedDto => !!selectedMachine
		);
}

function findControllerMachineType(machine: { controllerName?: string; modelName?: string }) {
	return controllerToMachinetypeLookup.find(
		lookup =>
			machine.controllerName?.includes(lookup.key) ||
			(machine.modelName && extraModelsForNotification.includes(machine.modelName))
	);
}

export function notificationMachineIdsFilter(unitIds: number[]): number[] {
	// Super Admins are allowed to create notification Definitions units that have never been online => none known controller
	const isSuperAdmin = isAtLeastSuperAdminFromState(store.getState());

	let selectedMachines = machineUnitIdsToIMachineSelectedDto(unitIds);
	return selectedMachines.filter(unit => findControllerMachineType(unit) || isSuperAdmin).map(machine => machine.id);
}

export interface MachineTypeSelectedlist {
	machineType: NotificationMachineType | undefined;
	selectedMachines: IUnitSelectedDto[];
}

export function machineIdsToDistinctMachineTypeSelectedList(unitIds: number[]): MachineTypeSelectedlist[] {
	let selectedMachines = machineUnitIdsToIMachineSelectedDto(unitIds);

	let machineTypeControllerlist: MachineTypeSelectedlist[] = [];

	selectedMachines.forEach(machine => {
		let machineType: NotificationMachineType | undefined = findControllerMachineType(machine)?.value;

		if (machine.modelName === SharkCompactorModelName) machineType = NotificationMachineType.SharkMK3;

		if (machine.modelName === ContainerModelName) machineType = NotificationMachineType.Container;

		let machineTypeController = machineTypeControllerlist.find(element => element.machineType === machineType);

		if (machineTypeController) {
			machineTypeController.selectedMachines.push(machine);
		} else machineTypeControllerlist.push({ machineType: machineType, selectedMachines: [machine] });
	});
	return machineTypeControllerlist;
}

export const makeINotificationFillLevelSettingDto = (
	thresholdPercentage: number,
	dayOfWeek: DayOfWeek,
	notificationType: INotificationTypeDto
): INotificationFillLevelSettingsDto => {
	return {
		notificationTypeId: notificationType.id,
		thresholdPercentage: thresholdPercentage,
		machineType: notificationType.machineType,
		dayOfWeek: dayOfWeek,
	};
};

// Important: Fill level settings should be the same in the frontend and on the backend!
// If the default fill level settings are changed in the frontend, remember to change them on the backend aswell (eg. 100% and 80% threshold) - Function name: GetDefaultFillLevelSettings
export const getDefaultFillLevelSettings = (
	notificationType: INotificationTypeDto
): INotificationFillLevelSettingsDto[] => {
	let list: INotificationFillLevelSettingsDto[] = [];
	for (let i: number = 0; i <= 6; i++) {
		//for each of the 7 weekdays
		list.push(makeINotificationFillLevelSettingDto(100, i, notificationType));
		list.push(makeINotificationFillLevelSettingDto(80, i, notificationType));
	}
	return list;
};
