import { FileDirectoryTypeEnum, IFileDirectoryDto } from 'api/api';
import React, { FC, useMemo } from 'react';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import { getFileExtentionName, PreviewableFileTypes } from 'view/shared/utils/file-type-helper';
import { getIcon, getIconByFileName } from 'view/shared/utils/icon-helper';
import { PartnerAreaIconOverlay } from './partner-area-icon-overlay';
import './partner-area.scss';

type Props = {
	obj: IFileDirectoryDto;
};

export const PartnerAreaIcon: FC<Props> = props => {
	const fileExtType = useMemo(() => getFileExtentionName(props.obj.fullPath), [props.obj.fullPath]);

	const getIconInternal = useMemo(() => {
		return props.obj.fileDirectoryType === FileDirectoryTypeEnum.Folder
			? getIcon('folder')
			: getIconByFileName(fileExtType);
	}, [fileExtType, props.obj.fileDirectoryType]);

	return fileExtType && PreviewableFileTypes.includes(fileExtType) ? (
		<BiTooltip overlay={<PartnerAreaIconOverlay {...props} />} backgroundColor="white">
			{getIconInternal}
		</BiTooltip>
	) : (
		getIconInternal
	);
};
