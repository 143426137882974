import React from 'react';
import { showDocument } from 'state/ducks/documents/operations';
import { Spinner } from 'view/components/spinner/spinner';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { ManualItem, ManualType } from '../documents-helper';
import './document-button.scss';
import { getDocumentIconByManualType } from './document-helper';

interface AppProps {
	manualItem: ManualItem;
	handleVideoBtnPressed: (videoName: string) => void;
}

interface State {
	isLoading: boolean;
}

class DocumentButton extends React.PureComponent<AppProps, State> {
	constructor(props: AppProps) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}
	handleClick = async () => {
		if (this.props.manualItem.type === ManualType.pdf || this.props.manualItem.type === ManualType.svg) {
			this.setState({ isLoading: true });
			let blobUrl = await showDocument(this.props.manualItem.url);
			if (blobUrl) window.open(blobUrl);

			this.setState({ isLoading: false });
		} else {
			this.props.handleVideoBtnPressed(this.props.manualItem.name);
		}
	};

	public render() {
		return (
			<BiButton onClick={this.handleClick} colorTheme={'org-white'} containerClassName={'document-btn-container'}>
				<div className="document-btn-content">
					{this.state.isLoading ? (
						<Spinner
							shouldOverlay={false}
							shouldUseAbsolutePositioning={false}
							spinnerSize="spinner-container-1-4th-size"
							useMarginsForCorrectingCentering={false}
						/>
					) : (
						<>
							<span>{this.props.manualItem.name}</span>
							<div className="margin-left-auto">
								{getDocumentIconByManualType(this.props.manualItem.type)}
							</div>
						</>
					)}
				</div>
			</BiButton>
		);
	}
}

export default DocumentButton;
