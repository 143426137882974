import { AppState } from 'state/store';
import { Dispatch } from 'redux';
import React from 'react';
import { connect } from 'react-redux';
import { getGPSHistory } from 'state/ducks/gps-history/operations';
import GPSHistoryTable from './gps-history-table';
import { initStartDate, initEndDate } from '../history/history';
import { getSettings } from 'state/ducks/history-table-settings/operations';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	return {
		currGPSHistories: state.gpsHistoryReducer.gpsHistories.find(curr => curr.id === ownProps.machineId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getGPSHistory: async (machineId: string, startDate: Date, endDate: Date) =>
		(await getGPSHistory(machineId, startDate, endDate))(dispatch),
	getSettings: async () => (await getSettings())(dispatch),
});

interface AppProps {
	isLoading?: boolean;
	machineId: string;
	autoStartHistoryQuery?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

class GPSHistoryTableWrapper extends React.PureComponent<Props> {
	public async componentDidMount() {
		await this.props.getSettings();
		this.props.autoStartHistoryQuery === true &&
			(await this.props.getGPSHistory(this.props.machineId, initStartDate, initEndDate));
	}

	public render() {
		return (
			<div className="history-table-container">
				<GPSHistoryTable
					isLoading={this.props.isLoading}
					data={this.props.currGPSHistories && this.props.currGPSHistories.data}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GPSHistoryTableWrapper);
