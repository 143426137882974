import { Action } from 'redux';
import { UpdateMachinesOwnerGroupIdAction } from 'state/ducks/machines/actions';
import { isType } from 'typescript-fsa';
import { updateMachineOwnerGroupIds } from 'utilities/reducer-helpers-update-machine-ownergroup';
import * as actions from './actions';
import { MapTypeId } from './constants';
import { LocationState } from './types';

export const initialState: LocationState = {
	machinesWithLocation: undefined,
	callCounter: 0,
	mapBounds: undefined,
	mapType: MapTypeId.ROADMAP
};

export function locationReducer(state: LocationState = initialState, action: Action): LocationState {
	if (
		isType(action, actions.getMachineLocationsFilteredAction.started) ||
		isType(action, actions.getMachineLocationsAction.started)
	) {
		return {
			...state,
			callCounter: action.payload.callCounter
		}
	}
	if (
		isType(action, actions.getMachineLocationsFilteredAction.done) ||
		isType(action, actions.getMachineLocationsAction.done)
	) {
		if (state.callCounter === action.payload.params.callCounter) {
			return {
				...state,
				machinesWithLocation: action.payload.result,
			};
		}
	}
	if (isType(action, actions.setActivelySelectedMachineAction)) {
		return {
			...state,
			activelySelectedMachines: undefined,
			activelySelectedMachine: action.payload ? { ...action.payload } : action.payload, //Make new copy of the object to trigger rerender in location-sidebar
		};
	}
	if (isType(action, UpdateMachinesOwnerGroupIdAction.done)) {
		return {
			...state,
			machinesWithLocation: updateMachineOwnerGroupIds(
				state.machinesWithLocation || [],
				action.payload.params.MachineIds,
				action.payload.params.destinationGroupId
			)
		};
	}
	if (isType(action, actions.setMapBoundsAction)) {
		return {
			...state,
			mapBounds: action.payload,
		};
	}
	if (isType(action, actions.setMapZoomLevelAction)) {
		return {
			...state,
			mapZoomLevel: action.payload,
		};
	}
	if (isType(action, actions.setMapCenterAction)) {
		return {
			...state,
			mapCenter: action.payload,
		};
	}
	if (isType(action, actions.setActivelySelectedMachinesAction)) {
		return {
			...state,
			activelySelectedMachine: undefined,
			activelySelectedMachines: action.payload ? [...action.payload] : action.payload, //Make new copy of the array to trigger rerender in location-sidebar
		};
	} else if (isType(action, actions.clearMachinesLocationsAction)) {
		return {
			...state,
			machinesWithLocation: [],
			activelySelectedMachine: undefined,
			activelySelectedMachines: []
		};
	}
	if (isType(action, actions.setMapTypeAction)) {
		return {
			...state,
			mapType: action.payload
		}
	}

	return state;
}
