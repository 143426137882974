import { IUnitStateHistoryDto } from 'api/api';

export const unitHistorySorter = (a: IUnitStateHistoryDto, b: IUnitStateHistoryDto) => {
	// There might be multiple changes with the same timestamp, so in order to make the order is deterministic we
	// also sort on cosmosTimestamp and then by Id. Cosmos timestamps resolution only goes to seconds and is not
	// unique, but can help to provide the preferred order.

	if (!a || !b) return 0;

	// Sort by updateDate
	if (a.updatedDate > b.updatedDate) {
		return 1;
	} else if (a.updatedDate < b.updatedDate) {
		return -1;
	}

	// Then by cosmos timestamp (saving time in cosmos db)
	if (a.cosmosTimestamp < b.cosmosTimestamp) {
		return -1;
	} else if (a.cosmosTimestamp > b.cosmosTimestamp) {
		return 1;
	}

	// NOTE: incrementingCounterForSorting is a property assigned to the model by the API on extraction time,
	// meaning this property can be used to sort the objects and keep a the order matching with the insertion order to either cosmos/parquet,
	// where timestamps are identical. This fixes issues where eg the emptyCounter went up and down ramdomly within rows with identical timestamps.
	if (a.incrementingCounterForSorting < b.incrementingCounterForSorting) {
		return -1;
	} else if (a.incrementingCounterForSorting > b.incrementingCounterForSorting) {
		return 1;
	}

	// Then by id (id is a guid, hence this sorting does not provide priority but is just here for consitency)
	if (a.id < b.id) {
		return -1;
	} else if (a.id > b.id) {
		return 1;
	} else {
		return 0;
	}
};
