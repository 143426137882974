import i18n from 'i18next';
import danishTranslation from './i18n/da.json';
import englishTranslation from './i18n/en.json';
import germanTranslation from './i18n/de.json';
import frenchTranslation from './i18n/fr.json';
import spanishTranslation from './i18n/es.json';
import italianTranslation from './i18n/it.json';
import dutchTranslation from './i18n/nl.json';
import polishTranslation from './i18n/pl.json';
import portugueseTranslation from './i18n/pt.json';
import norwegianTranslation from './i18n/nb.json';
import swedishTranslation from './i18n/sv.json';
import detector from 'i18next-browser-languagedetector';
import 'moment/locale/en-gb';
import 'moment/locale/da'; // Import languages so the moment locale is set correctly
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/es-us';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/nb';
import 'moment/locale/sv';
import moment from 'moment';

export const Languages = {
	da: 'Dansk',
	en: 'English',
	de: 'Deutsche',
	fr: 'Français',
	es: 'Español',
	it: 'Italiano',
	nl: 'Nederlands',
	pl: 'Polski',
	pt: 'Português',
	nb: 'Norsk',
	sv: 'Svensk',
} as const;

export type LanguageType = keyof typeof Languages;

export type Translation = typeof englishTranslation;

// NB. When adding a NEW TRANSLATION, REMEMBER to IMPORT the corresponding MOMENT LOCALE at the top!
const i18nResources = {
	da: {
		translation: danishTranslation,
	},
	en: {
		translation: englishTranslation,
	},
	de: {
		translation: germanTranslation,
	},
	fr: {
		translation: frenchTranslation,
	},
	es: {
		translation: spanishTranslation,
	},
	it: {
		translation: italianTranslation,
	},
	nl: {
		translation: dutchTranslation,
	},
	pl: {
		translation: polishTranslation,
	},
	pt: {
		translation: portugueseTranslation,
	},
	nb: {
		translation: norwegianTranslation,
	},
	sv: {
		translation: swedishTranslation,
	},
};

export type i18nTranslationKey = keyof Translation;

i18n.use(detector);
i18n.init(
	{
		fallbackLng: 'en',
		resources: i18nResources,
		// have a common namespace used around the full app
		ns: ['translation'],
		defaultNS: 'translation',
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react as it does escape per default to prevent xss!
		},
		react: {
			wait: false,
			//withRef: false,
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'default',
		},
	},
	(err, t) => {
		moment.locale([i18n.language === 'es' ? 'es-us' : i18n.language, 'en']);
	}
);

// translate.* was replaced in place of this syntax.
// i18n.use({
//   wait: false,
//   withRef: false,
//   bindI18n: 'languageChanged loaded',
//   bindStore: 'added removed',
//   nsMode: 'default'
// });

// type-safe translation lookup
export const localized = (key: i18nTranslationKey): string => {
	return i18n.t(key);
};

export const localizedDynamic = (key: i18nTranslationKey | string): string => {
	return i18n.t(key);
};

export const localizedInterpolation = (key: i18nTranslationKey, keysToReplace: any): string => {
	return i18n.t(key, keysToReplace);
};

export const getTranslation = (language: keyof typeof Languages) => i18n.getFixedT(language);

export const getLanguage = () => {
	return i18n.language === 'en-US' ? 'en' : i18n.language;
};

export const changeLanguage = async (language: keyof typeof Languages) => {
	await i18n.changeLanguage(language);

	moment.locale([language === 'es' ? 'es-us' : language, 'en']);
};

export const getTranslationNamespace = () => i18n.getFixedT('translation');

export const getLocaleData = () => {
	return moment.localeData(i18n.language);
};
