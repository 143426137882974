import { IGetDebtorDto } from 'api/api';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyImage } from 'state/ducks/debtors/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { stringOrDefault } from 'utilities/string-utils';
import { ImageBase64 } from 'view/components/image/image-base64';
import WhiteCard from 'view/shared/components/cards/white-card';
import LabelValueCard, {
	LabelValueForCardMap,
	addLabelValueLink,
	addTranslationLabelValueMapToList,
} from 'view/shared/components/label-value-card';
import { cardDetailsStyles, cardGeneralClassNames } from '../constants';
import './debtor-details.scss';

const containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer}`;
const defaultValue = '';

export const DebtorDetails: React.FC<{ debtor: IGetDebtorDto | undefined; groupsId: number | undefined }> = props => {
	const dispatch = useDispatch();
	const image = useSelector((state: AppState) =>
		state.debtorsReducer.debtorImages.find(debtorImage => debtorImage.id === props.groupsId)
	);

	useEffect(() => {
		if (props.groupsId && props.debtor?.imageFileName && !image) {
			getCompanyImage(props.groupsId)(dispatch);
		}
	}, [dispatch, image, props.debtor, props.groupsId]);

	const labelPairValues = useMemo(() => {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		if (!props.debtor) return retMap;

		const website = stringOrDefault(props.debtor.website, defaultValue);
		website !== defaultValue && addLabelValueLink('Website', website, retMap);
		const address = stringOrDefault(props.debtor.address, defaultValue);
		address !== defaultValue && addTranslationLabelValueMapToList('Address', address, retMap);
		const address2 = stringOrDefault(props.debtor.address2, defaultValue);
		address2 !== defaultValue && addTranslationLabelValueMapToList('Address2', address2, retMap);
		const postalCode = stringOrDefault(props.debtor.postalCode, defaultValue);
		postalCode !== defaultValue && addTranslationLabelValueMapToList('PostalCode', postalCode, retMap);
		const city = stringOrDefault(props.debtor.city, defaultValue);
		city !== defaultValue && addTranslationLabelValueMapToList('city', city, retMap);
		const state = stringOrDefault(props.debtor.state, defaultValue);
		state !== defaultValue && addTranslationLabelValueMapToList('CityState', state, retMap);
		const country = stringOrDefault(props.debtor.country, defaultValue);
		country !== defaultValue && addTranslationLabelValueMapToList('country', country, retMap);
		const phoneNumber = stringOrDefault(props.debtor.phoneNumber, defaultValue);
		phoneNumber !== defaultValue && addTranslationLabelValueMapToList('PhoneNo', phoneNumber, retMap);
		const email = stringOrDefault(props.debtor.email, defaultValue);
		email !== defaultValue && addLabelValueLink('email', email, retMap, `mailto:${email}`, false);
		const phoneNumberSales = stringOrDefault(props.debtor.phoneNumberSales, defaultValue);
		phoneNumberSales && addTranslationLabelValueMapToList('PhoneNumberSales', phoneNumberSales, retMap);
		const orderServiceLink = stringOrDefault(props.debtor.orderServiceLink, defaultValue);
		orderServiceLink !== defaultValue &&
			addLabelValueLink('Service', orderServiceLink, retMap, undefined, true, 'button', 'OrderService');
		const orderStrappingLink = stringOrDefault(props.debtor.orderStrappingLink, defaultValue);
		orderStrappingLink !== defaultValue &&
			addLabelValueLink('Consumables', orderStrappingLink, retMap, undefined, true, 'button', 'OrderConsumables');

		return retMap;
	}, [props.debtor]);

	return !props.debtor || labelPairValues.valuesForLabels.length === 0 ? null : (
		<WhiteCard title={localized('AfterSales')} classNameContainer={containerClassName}>
			<ImageBase64
				image={image?.data}
				className="margin-bottom-1em company-image"
				alt={localized('AfterSales')}
			/>
			<LabelValueCard labels={labelPairValues.labels} values={labelPairValues.valuesForLabels} />
		</WhiteCard>
	);
};
