import React, { FC, useEffect } from 'react';
import { useGA4React } from 'ga-4-react';
import { useLocation } from 'react-router-dom';
import { GoogleAnalyticsKey } from './constants';
import { ga4react } from 'index';

export const GoogleAnalytics: FC = React.memo(() => {
	const location = useLocation();
	const ga = useGA4React(GoogleAnalyticsKey);

	useEffect(() => {
		if (ga) {
			ga.pageview(location.pathname + location.search);
		}
	}, [ga, location]);

	return null;
});

export const gaModalView = (modalName: string) => {
	ga4react.pageview(modalName);
};

export const gaEvent = (category: string, action: string, label: string = '') => {
	ga4react.event(action, label, category);
};
