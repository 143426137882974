import {
	NotificationAdvancedSettingDto,
	NotificationColumnSelected,
	NotificationFillLevelSettingsDto,
	NotificationMachineType,
	NotificationTypeDto,
	NotificationUserDto,
} from 'api/api';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { isType } from 'typescript-fsa';
import { upsertValuesInArray } from 'utilities/reducer-helpers';
import * as filterActions from '../filter-search/actions';
import * as actions from './actions';
import { NotificationsState } from './types';

export const initialState: NotificationsState = {
	notificationTypes: [],
	notificationDefinitions: [],
	filteredOut: 0,
	loading: false,
	callCounter: 0,
	users: [],
};

export function notificationsReducer(state: NotificationsState = initialState, action: Action): NotificationsState {
	if (isType(action, filterActions.SetAppliedFilterAction)) {
		return { ...state, notificationDefinitions: [] };
	}

	if (isType(action, actions.GetNotificationTypesAction.done)) {
		return {
			...state,
			notificationTypes: action.payload.result,
		};
	}

	if (isType(action, actions.GetNotificationDefinitionsAction.started)) {
		return {
			...state,
			loading: true,
			callCounter: action.payload.callCounter,
		};
	}

	if (isType(action, actions.GetNotificationDefinitionsAction.done)) {
		if (state.callCounter === action.payload.params.callCounter) {
			return {
				...state,
				notificationDefinitions: action.payload.result,
				loading: false,
			};
		}
	}

	if (isType(action, actions.DeleteTemporaryNotificationDefinitionAction.done)) {
		return {
			...state,
			notificationDefinitions: state.notificationDefinitions.filter(notDef => notDef !== action.payload.params),
		};
	}

	if (isType(action, actions.SaveTemporaryNotificationDefinitionAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));

		if (state.notificationDefinitions.map(def => def.id).includes(action.payload.result.id)) {
			return {
				...state,
				notificationDefinitions: state.notificationDefinitions.map(notDef =>
					notDef.id === action.payload.result.id ? action.payload.result : notDef
				),
				temporaryNotificationDefinition: undefined,
			};
		}
		return {
			...state,
			notificationDefinitions: [...state.notificationDefinitions, action.payload.result],
			temporaryNotificationDefinition: undefined,
		};
	}

	if (isType(action, actions.SetTemporaryNotificationDefinitionAction)) {
		return {
			...state,
			temporaryNotificationDefinition: action.payload.notDef,
		};
	}

	if (isType(action, actions.RemoveTemporaryNotificationDefinitionAction)) {
		return {
			...state,
			temporaryNotificationDefinition: undefined,
		};
	}

	if (isType(action, actions.AddTemporaryNotificationTypeAction)) {
		if (state.temporaryNotificationDefinition) {
			const definitions = state.temporaryNotificationDefinition.notificationTypes
				? state.temporaryNotificationDefinition.notificationTypes.concat(
						action.payload.notTypes as NotificationTypeDto[]
				  )
				: (action.payload.notTypes as NotificationTypeDto[]);

			// Ensure that no notification appears twice. Mainly because fill level is added twice
			const uniqueNotificationTypes = Array.from(new Map(definitions.map(item => [item.id, item])).values());

			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					notificationTypes: uniqueNotificationTypes,
				},
			};
		}
		return state;
	}
	if (isType(action, actions.RemoveTemporaryNotificationTypeAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					notificationTypes: state.temporaryNotificationDefinition.notificationTypes?.filter(
						notType => !action.payload.notType.map(n => n.id).includes(notType.id)
					),
					fillLevelSettings: state.temporaryNotificationDefinition.fillLevelSettings?.filter(
						fillSetting => !action.payload.notType.map(n => n.id).includes(fillSetting.notificationTypeId)
					),
					notificationAdvancedSettings: state.temporaryNotificationDefinition.notificationAdvancedSettings?.filter(
						advancedSetting =>
							!action.payload.notType.map(n => n.id).includes(advancedSetting.notificationTypeId)
					),
				},
			};
		return state;
	}

	if (isType(action, actions.SetTemporaryNotificationNameAction)) {
		if (state.temporaryNotificationDefinition) {
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					name: action.payload.name,
				},
			};
		}
	}

	if (isType(action, actions.AddTemporaryNotificationUserAction)) {
		if (state.temporaryNotificationDefinition) {
			if (
				state.temporaryNotificationDefinition.notificationUsers
					.map(user => user.email)
					.includes(action.payload.user.email)
			)
				return state;
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					notificationUsers: [
						...(state.temporaryNotificationDefinition.notificationUsers || []),
						action.payload.user as NotificationUserDto,
					],
				},
			};
		}
		return state;
	}
	if (isType(action, actions.RemoveTemporaryNotificationUserAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					notificationUsers: state.temporaryNotificationDefinition.notificationUsers?.filter(
						user => user.email !== action.payload.user.email
					),
				},
			};
		return state;
	}

	if (isType(action, actions.SetTemporaryNotificationMachineIdsAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					unitIds: action.payload.deviceIds,
				},
			};
		return state;
	}

	if (isType(action, actions.SetTemporaryNotificationFillLevelByIdAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					fillLevelSettings: action.payload.settings.concat(
						...(state.temporaryNotificationDefinition.fillLevelSettings?.filter(
							set => set.notificationTypeId !== action.payload.id
						) || [])
					) as NotificationFillLevelSettingsDto[],
				},
			};
		return state;
	}

	if (isType(action, actions.SetTemporaryNotificationAdvancedSettingsByIdAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					notificationAdvancedSettings: action.payload.settings.concat(
						...(state.temporaryNotificationDefinition.notificationAdvancedSettings?.filter(
							set => set.notificationTypeId !== action.payload.id
						) || [])
					) as NotificationAdvancedSettingDto[],
				},
			};
		return state;
	}

	if (isType(action, actions.SetTemporaryNotificationSelectedColumnsAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					columnsSelected: action.payload.columns as NotificationColumnSelected[],
				},
			};
		return state;
	}

	if (isType(action, actions.GetNotificationDefinitionsForMachineAction.done)) {
		return {
			...state,
			notificationDefinitions: upsertValuesInArray(state.notificationDefinitions, action.payload.result),
			loading: false,
		};
	}

	if (isType(action, actions.GetNotificationDefinitionsForMachineAction.started)) {
		return {
			...state,
			loading: true,
		};
	}

	if (isType(action, actions.SetFilteredOutAction)) {
		return {
			...state,
			filteredOut: action.payload.filteredOut,
		};
	}

	if (isType(action, actions.SetTemporaryNotificationLanguageAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					selectedLanguage: action.payload.language,
				},
			};
	}

	if (isType(action, actions.SetTemporaryNotificationNotifyByEmailAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					shouldNotifyByEmail: action.payload.notifyType,
				},
			};
	}

	if (isType(action, actions.SetTemporaryNotificationNotifyByPushAction)) {
		if (state.temporaryNotificationDefinition)
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					shouldNotifyByPush: action.payload.notifyType,
				},
			};
	}

	if (isType(action, actions.SetTemporaryNotificationTypeAction)) {
		if (state.temporaryNotificationDefinition) {
			return {
				...state,
				temporaryNotificationDefinition: {
					...state.temporaryNotificationDefinition,
					notificationTypes: action.payload.notTypes as NotificationTypeDto[],
				},
			};
		}
	}

	if (isType(action, actions.GetUsersByGroupId.done)) {
		return {
			...state,
			users: action.payload.result,
		};
	}

	return state;
}

export const selectTemporaryNotificationType = createSelector(
	[
		(state: AppState) => state.notificationsReducer.temporaryNotificationDefinition?.notificationTypes,
		(_: AppState, name: string) => name,
		(_: AppState, __: string, machineType: NotificationMachineType) => machineType,
	],
	(notificationTypes, name, machineType) => {
		return notificationTypes?.find(s => s.name === name && s.machineType === machineType);
	}
);

export const selectNotificationAdvancedSettingsByMachineType = createSelector(
	[
		(state: AppState) => state.notificationsReducer.temporaryNotificationDefinition?.notificationAdvancedSettings,
		(_: AppState, machineType: NotificationMachineType) => machineType,
	],
	(settings, machineType) => {
		return settings?.filter(s => s.machineType === machineType);
	}
);
