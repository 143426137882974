import { connect } from 'react-redux';
import React from 'react';
import { Dispatch } from 'redux';
import { getUserPermission } from 'state/ducks/user-permission/operations';
import { getProfile } from 'state/ducks/profile/operations';
import { getUserSettings } from 'state/ducks/user-settings/operations';
import { AppState } from 'state/store';
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions';

const mapStateToProps = (state: AppState) => {
	return {
		isAuthenticated: state.authReducer.isAuthenticated,
		agreedToTermsAndConditions: state.userSettingReducer.userSettings.agreedToTermsAndConditions,
		userId: state.userSettingReducer.userSettings.userId,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getUserPermissions: async () => (await getUserPermission())(dispatch),
	getProfileDetails: async () => (await getProfile())(dispatch),
	getUserSettings: async () => (await getUserSettings())(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AuthListener extends React.PureComponent<Props> {
	public async componentDidUpdate(prevProps: Props) {
		if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
			await this.props.getUserPermissions();
			await this.props.getProfileDetails();
			await this.props.getUserSettings();
		}
	}

	// Show modal if and only if we have a userObject (id) and that user have not yet confirmed.
	public render() {
		return !this.props.agreedToTermsAndConditions && this.props.userId ? <TermsAndConditions /> : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthListener);
