import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import WhiteCard from 'view/shared/components/cards/white-card';
import { cardDetailsStyles, cardGeneralClassNames } from '../../constants';
import { PageState, Paginator } from 'primereact/paginator';
import './notification-subscription-list.scss';
import { NotificationSubscriptionRows } from './notification-rows';
import { Spinner } from 'view/components/spinner/spinner';

const containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer}`;

type Props = {
	machineDetailsId: number;
};

export const NotificationSubscriptionList: FC<Props> = React.memo((props: Props) => {
	const notificationDefinitions = useSelector((state: AppState) =>
		state.notificationsReducer.notificationDefinitions.filter(nd => nd.unitIds?.includes(props.machineDetailsId))
	);
	const loading = useSelector((state: AppState) => state.notificationsReducer.loading);

	const [paginatorFirst, setPaginatorFirst] = useState(0);
	const [paginatorRows] = useState(11);
	const [totalRows, setTotalRows] = useState(0); //sum of users

	useEffect(() => {
		let totalRows =
			notificationDefinitions?.map(def => def.notificationUsers.length).reduce((a, b) => a + b, 0) || 0;
		setTotalRows(totalRows);
	}, [notificationDefinitions]);

	const getList = () => {
		return notificationDefinitions
			?.flatMap(def =>
				def.notificationUsers.map(nu => (
					<NotificationSubscriptionRows key={nu.id} notificationUser={nu} notificationDefinition={def} />
				))
			)
			.slice(paginatorFirst, paginatorFirst + paginatorRows);
	};

	const onPageChangeHandler = (e: PageState) => {
		setPaginatorFirst(e.first);
	};

	const getContent = () => {
		// If we already have data in redux then show that instead of a spinner
		if (totalRows !== 0) {
			return (
				<>
					<div className="notification-subscription-list">
						<div className={`margin-bottom-10px notification-subscription-grid`}>
							<b>{localized('NotificationName')}</b>
							<b>{localized('email')}</b>
							<b>{localized('GoToDefinition')}</b>
						</div>
						{getList()}
					</div>

					{totalRows > paginatorRows && (
						<div className="flex-end-row">
							<Paginator
								first={paginatorFirst}
								rows={paginatorRows}
								totalRecords={totalRows}
								onPageChange={onPageChangeHandler}
								template="PrevPageLink PageLinks NextPageLink"
							/>
						</div>
					)}
				</>
			);
		}

		// Render a spinner while we are waiting for a response from the API
		if (loading)
			return (
				<div className="margin-top-60px">
					<Spinner shouldOverlay={false} spinnerSize={'spinner-container-half-size'} />
				</div>
			);

		// Display message if there is no data
		if (totalRows === 0)
			return (
				<div>
					<p>{localized('NoNotificationsForCurrentDevice')}</p>
				</div>
			);
	};

	return (
		<WhiteCard title={localized('Notifications')} classNameContainer={containerClassName}>
			{getContent()}
		</WhiteCard>
	);
});
