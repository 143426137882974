import React from 'react';
import DocumentTitle from 'react-document-title';
import { BrandName } from 'utilities/constants';

export interface BiTitleProps {
    title: string;
    shouldPrefixBrandName?: boolean;
}

class BiTitle extends React.PureComponent<BiTitleProps> {
    public render() {
        const fullTitle = this.props.shouldPrefixBrandName !== false
            ? (
                BrandName
                    ? (`${BrandName} - ${this.props.title}`)
                    : this.props.title
            )
            : this.props.title;

        return (
            <DocumentTitle title={fullTitle}>
                {this.props.children}
            </DocumentTitle>
        );
    }
}

export default BiTitle;
