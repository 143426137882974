import { DashboardBase, DashboardTileType, IReportTileDto, ReportTileSettingsDto } from 'api/api';
import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const PropNameYearPart = 'yearPart';
export const PropNameDatePart = 'datePart';
export const PropNameUnitId = 'unitId';
export const PropNameUnitType = 'unitType';
export const PropNameSiteUser = 'siteUser';

export const constants = getArgsAsTypesafeObjectProperties(
	'GET_TABLE_REPORT',
	'GET_GRAPH_REPORT',
	'CLEAR_REPORTS',
	'GET_REPORT_DATA',
	'SET_REPORTS_LOADING_STATE',
	'RESET_DATATYPE',
	'SET_START_DATE',
	'SET_END_DATE',
	'SET_DATETYPE',
	'SET_REPORT_TYPE',
	'GET_REPORT_TILE_PREVIEW',
	"SET_REPORT_TILE_DATETYPE",
	"CLEAR_REPORT_TILE_PREVIEW"
);

export const DefaultReportTileDto: IReportTileDto = {
	reportTileSettingsDto: new ReportTileSettingsDto(),
	isPreview: true,
	dashboardBase: new DashboardBase({
		id: 0,
		type: DashboardTileType.ReportPrintout,
		x_location: 0,
		y_location: 0,
		isDeleted: false,
		createdDate: new Date(),
		updatedDate: new Date()
	})
}