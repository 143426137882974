import { AppState } from 'state/store';
import React from 'react';
import { connect } from 'react-redux';
import { ReportGraph } from './report-graph';
import WhiteCard from 'view/shared/components/cards/white-card';
import './report-graph.scss';

const mapStateToProps = (state: AppState) => {
	return {
		graphDataset: state.reportReducer.graphDataset,
	};
};

interface AppProps {
	xAxisLabel: string;
	yAxisLabel: string;
	datasetLabel: string;
	dataUnit: string;
}

type Props = ReturnType<typeof mapStateToProps> & AppProps;

class ReportGraphWrapper extends React.PureComponent<Props> {
	public render() {
		return (
			<WhiteCard classNameContainer="width-auto min-graph-card-sizes">
				<div className="report-graph-container">
					<ReportGraph
						xAxisLabel={this.props.xAxisLabel}
						yAxisLabel={this.props.yAxisLabel}
						datasetLabel={this.props.datasetLabel}
						graphDataset={this.props.graphDataset}
						aspectRatio={2.5}
						dataUnit={this.props.dataUnit}
					/>
				</div>
			</WhiteCard>
		);
	}
}

export default connect(mapStateToProps, {})(ReportGraphWrapper);
