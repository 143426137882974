import { IGroupsDto, IEditGroupsDto, IEditGroupsDebtorDto, IUserGroupPermissionsDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('groups');

export const GetUserGroupsChildHierarchyAction = actionCreator.async<string, IGroupsDto[]>(
	constants.GET_USER_GROUPS_CHILD_HIERARCHY
);
export const GetUsersAdminGroupsChildHierarchyAction = actionCreator.async<string, IGroupsDto[]>(
	constants.GET_USERS_ADMIN_GROUPS_CHILD_HIERARCHY
);
export const GetUsersCountryAdminGroupsChildHierarchyAction = actionCreator.async<string, IGroupsDto[]>(
	constants.GET_USERS_COUNTRY_ADMIN_GROUPS_CHILD_HIERARCHY
);
export const GetUsersSuperAdminGroupsChildHierarchyAction = actionCreator.async<string, IGroupsDto[]>(
	constants.GET_USERS_SUPER_ADMIN_GROUPS_CHILD_HIERARCHY
);

export const GetUsersThatIOwnAction = actionCreator.async<string, IUserGroupPermissionsDto[]>(
	constants.GET_USERS_THAT_I_OWN
);

export const SetUserPermissionsAction = actionCreator.async<
	{ ugp: IUserGroupPermissionsDto; previousGroupId: number },
	IUserGroupPermissionsDto
>(constants.SET_USERS_GROUP_PERMISSIONS);

export const DeleteUserGroupPermissionsAction = actionCreator.async<number | undefined, IUserGroupPermissionsDto>(
	constants.DELETE_USER_GROUP_PERMISSION
);

export const CreateUserWithGroupPermissionAction = actionCreator.async<
	IUserGroupPermissionsDto,
	IUserGroupPermissionsDto
>(constants.CREATE_USER_WITH_GROUP_PERMISSION);

export const SetCurrentGroupAction = actionCreator<number>(constants.SET_CURRENT_GROUP);

export const CreateChildGroupAction = actionCreator.async<string, IGroupsDto>(constants.CREATE_CHILD_GROUP);

export const EditGroupAction = actionCreator.async<IEditGroupsDto, IGroupsDto>(constants.EDIT_GROUP);

export const DeleteGroupAction = actionCreator.async<number, void>(constants.DELETE_GROUP);

export const EditGroupDebtorAction = actionCreator.async<IEditGroupsDebtorDto, IGroupsDto>(
	constants.EDIT_GROUP_DEBITOR
);

export const ResendConfirmationEmailAction = actionCreator.async(constants.RESEND_CONFIRMATION_EMAIL);
