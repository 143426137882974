import { Location } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RouteBasePath, Routes } from 'state/ducks/routes';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { authPermissionCheck } from '../auth/auth-helper';
import {
	locationPageAuthRequirements,
	notificationPageAuthRequirements,
	partnerAreaPageAuthRequirements,
	reportPageAuthRequirements,
	unitsPageAuthRequirements,
} from '../auth/auth-requirements';
import './table-page-picker.scss';

const mapStateToProps = (state: AppState) => {
	return {
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		selectedLanguage: state.userSettingReducer.userSettings.selectedLanguage,
		tempNotId: state.notificationsReducer.temporaryNotificationDefinition?.id,
		userGroupPermissions: state.userPermissionReducer.UserGroupPermissions,
	};
};

type Props = ReturnType<typeof mapStateToProps>;

class TablePagePicker extends React.PureComponent<Props> {
	public render() {
		return (
			<ul className="nav-links">
				{/* TODO: DASHBOARD. Once dashboard is stable, add this back */}
				{/* <li className="nav-item">
					<NavLink
						activeClassName="current-page"
						to={Routes.Dashboard}
						isActive={this.checkIfDashboardIsActive}
					>
						{localized('Dashboard')}
					</NavLink>
				</li> */}
				{authPermissionCheck(this.props.userGroupPermissions, unitsPageAuthRequirements) && (
					<li className="nav-item">
						<NavLink activeClassName="current-page" to={Routes.Machines}>
							{localized('Units')}
						</NavLink>
					</li>
				)}
				{authPermissionCheck(this.props.userGroupPermissions, locationPageAuthRequirements) && (
					<li className="nav-item">
						<NavLink activeClassName="current-page" to={Routes.Location}>
							{localized('location')}
						</NavLink>
					</li>
				)}
				{authPermissionCheck(this.props.userGroupPermissions, reportPageAuthRequirements) && (
					<li className="nav-item">
						<NavLink activeClassName="current-page" to={Routes.Reports}>
							{localized('Reports')}
						</NavLink>
					</li>
				)}
				{authPermissionCheck(this.props.userGroupPermissions, notificationPageAuthRequirements) && (
					<li className="nav-item">
						<NavLink
							activeClassName="current-page"
							to={
								this.props.tempNotId !== undefined
									? `${Routes.NotificationsEdit}/${this.props.tempNotId}`
									: Routes.Notifications
							}
						>
							{localized('Notifications')}
						</NavLink>
					</li>
				)}
				{authPermissionCheck(this.props.userGroupPermissions, partnerAreaPageAuthRequirements) && (
					<li className="nav-item">
						<NavLink activeClassName="current-page" to={Routes.PartnerArea}>
							{localized('PartnerArea')}
						</NavLink>
					</li>
				)}
			</ul>
		);
	}

	private checkIfDashboardIsActive = (params: any, location: Location) => {
		if (location.pathname === Routes.Dashboard || location.pathname === RouteBasePath) {
			return true;
		} else {
			return false;
		}
	};
}

export default connect(mapStateToProps, {})(TablePagePicker);
