import { SelectedLanguage } from 'api/api';
import { LabelValuePair } from 'types/label-value-pair';

export const getSelectedLanguage = (selectedLanguage: SelectedLanguage) => {
	switch (selectedLanguage) {
		case SelectedLanguage.Da:
			return 'da';
		case SelectedLanguage.De:
			return 'de';
		case SelectedLanguage.Fr:
			return 'fr';
		case SelectedLanguage.Es:
			return 'es';
		case SelectedLanguage.It:
			return 'it';
		case SelectedLanguage.Nl:
			return 'nl';
		case SelectedLanguage.Pl:
			return 'pl';
		case SelectedLanguage.Pt:
			return 'pt';
		case SelectedLanguage.Nb:
			return 'nb';
		case SelectedLanguage.Sv:
			return 'sv';
		default:
			return 'en';
	}
};

export const getSelectedLangauge = (value: string) => {
	switch (value) {
		case 'da':
			return SelectedLanguage.Da;
		case 'fr':
			return SelectedLanguage.Fr;
		case 'de':
			return SelectedLanguage.De;
		case 'es':
			return SelectedLanguage.Es;
		case 'it':
			return SelectedLanguage.It;
		case 'nl':
			return SelectedLanguage.Nl;
		case 'pl':
			return SelectedLanguage.Pl;
		case 'pt':
			return SelectedLanguage.Pt;
		case 'nb':
			return SelectedLanguage.Nb;
		case 'sv':
			return SelectedLanguage.Sv;
		default:
			return SelectedLanguage.En;
	}
};



export const LanguageOptions: LabelValuePair<SelectedLanguage>[] = [
	{ label: 'Dansk', value: SelectedLanguage.Da },
	{ label: 'English', value: SelectedLanguage.En },
	{ label: 'Français', value: SelectedLanguage.Fr },
	{ label: 'Deutsch', value: SelectedLanguage.De },
	{ label: 'Español', value: SelectedLanguage.Es },
	{ label: 'Italiano', value: SelectedLanguage.It },
	{ label: 'Nederlands', value: SelectedLanguage.Nl },
	{ label: 'Polski', value: SelectedLanguage.Pl },
	{ label: 'Português', value: SelectedLanguage.Pt },
	{ label: 'Norsk', value: SelectedLanguage.Nb },
	{ label: 'Svensk', value: SelectedLanguage.Sv },
];
