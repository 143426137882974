import { IIoTHubDuplicateDecommissionDto } from 'api/api';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { decommissionDuplicateIotHubOperation } from 'state/ducks/duplicate-iothub-decommission/operations';
import { localized } from 'state/i18n';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { DecommissionItemDialog } from './decommission-item-dialog';

export const DecommissionItem: React.FC<{ decommissionObj: IIoTHubDuplicateDecommissionDto }> = props => {
	const dispatch = useDispatch();

	const [iotHubIdToDelete, setIotHubIdToDelete] = useState<string | undefined>(undefined);

	const deleteDuplicate = async () => {
		iotHubIdToDelete && dispatch(decommissionDuplicateIotHubOperation(iotHubIdToDelete, props.decommissionObj));
	};

	return (
		<div className="margin-bottom-10px decommission-row bi-label-content-item-box-shadow">
			<div>{props.decommissionObj.serialNumber}</div>
			<div>{props.decommissionObj.unitStateDeviceId}</div>
			<div>
				<BiButton
					onClick={() => setIotHubIdToDelete(props.decommissionObj.deviceIdOld)}
					disabled={iotHubIdToDelete !== undefined}
					colorTheme="org-red"
					containerTheme="normal-button-corners"
					containerClassName="decommission-item-button-container"
				>
					<span className="text-bold">
						{localized('Delete')} {props.decommissionObj.deviceIdOld} ({props.decommissionObj.modemOld})
					</span>
				</BiButton>
			</div>
			<div>
				<BiButton
					onClick={() => setIotHubIdToDelete(props.decommissionObj.deviceIdNew)}
					disabled={iotHubIdToDelete !== undefined}
					colorTheme="org-red"
					containerTheme="normal-button-corners"
					containerClassName="decommission-item-button-container"
				>
					<span className="text-bold">
						{localized('Delete')} {props.decommissionObj.deviceIdNew} ({props.decommissionObj.modemNew})
					</span>
				</BiButton>
			</div>

			{/* Only render dialog if needed (Don't render a dialog for each row) */}
			{iotHubIdToDelete && (
				<DecommissionItemDialog
					visible={true}
					onHide={() => setIotHubIdToDelete(undefined)}
					onConfirm={deleteDuplicate}
					decommissionObj={props.decommissionObj}
					iotHubIdToDelete={iotHubIdToDelete}
				/>
			)}
		</div>
	);
};
