import { INotificationCustomMessage, NotificationCustomMessage, NotificationCustomMessageClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { notificationCustomMessageClient } from '../api-clients';
import { GetCustomMessagesAction, SaveCustomMessagesAction } from './actions';

export const SaveCustomMessages = (serialNumber: string, customMessages: INotificationCustomMessage[]) => {
	let customMsgListToPost: NotificationCustomMessage[] = [];
	customMessages.forEach(msg => {
		let tempMsg = new NotificationCustomMessage();
		tempMsg.init(msg);
		customMsgListToPost.push(tempMsg);
	})

	return AsyncOperationBuilder(
		SaveCustomMessagesAction,
		apiClient => (apiClient as NotificationCustomMessageClient).saveNotificationCustomMessage(serialNumber, customMsgListToPost),
		customMsgListToPost,
		notificationCustomMessageClient
	);
}


export const GetCustomMessages = (serialNumber: string) => {
	return AsyncOperationBuilder(
		GetCustomMessagesAction,
		apiClient => (apiClient as NotificationCustomMessageClient).getNotificationCustomMessage(serialNumber),
		{ id: serialNumber },
		notificationCustomMessageClient
	);
}
