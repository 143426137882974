import { IPortfolioTileDto } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import { TwoNuberTileTemplate } from './templates/two-nuber-tile-template';

interface PropsFromParent {
	tile: IPortfolioTileDto;
}

type Props = PropsFromParent;

export class PortfolioTile extends React.PureComponent<Props> {
	public render() {
		return (
			<TwoNuberTileTemplate
				firstNumberheadline={localized('NumberOfUnits')}
				secondNumberHeadline={localized('NumberOfOnlineUnits')}
				firstNumberValue={this.props.tile.portfolioDto?.units || 0}
				secondNumberValue={this.props.tile.portfolioDto?.connected || 0}
				secondNumberColor='color-green-light'
			/>
		);
	}
}
