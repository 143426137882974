import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
	administrationPageAuthRequirements,
	partnerAreaPageAuthRequirements,
	profilePageAuthRequirements,
	unitsPageAuthRequirements,
} from 'view/components/auth/auth-requirements';
import AuthRoute from 'view/components/auth/auth-route';
import { UnsubscribeNotifications } from 'view/components/table/notifications/notification-unsubscribe';
import HomePage from 'view/pages/home';
import { RouteBasePath, Routes, routesToHomePage } from './state/ducks/routes';
import MachineDetails from './view/components/table/details/details';
import UnitHistory from './view/components/table/history/history';
import AdministrationPage from './view/pages/administration/administration';
import Callback from './view/pages/auth/callback';
import LoginPage from './view/pages/auth/sign-in';
import LoginRedirectPage from './view/pages/auth/sign-in-redirect';
import LogoutRedirectPage from './view/pages/auth/sign-out-redirect';
import ProfilePage from './view/pages/profile/profile';
import './view/styles/anchor.scss';
import './view/styles/borders.scss';
import './view/styles/buttons.scss';
import './view/styles/colors.scss';
import './view/styles/common-styles.scss';
import './view/styles/flex.scss';
import './view/styles/images.scss';
import './view/styles/margins.scss';
import './view/styles/outline.scss';
import './view/styles/paddings.scss';
import './view/styles/positioning.scss';
import './view/styles/scrollpanels.scss';
import './view/styles/spacing.scss';
import './view/styles/typography.scss';
import './view/styles/paginator.scss';
import './view/styles/search-bar.scss';
import { PartnerAreaPage } from 'view/pages/partner-area/partner-area';
import { authPermissionCheck } from 'view/components/auth/auth-helper';
import { AppState } from 'state/store';
import { connect } from 'react-redux';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactAI } from 'services/telemetry-service';

const mapStateToProps = (state: AppState) => {
	return {
		userGroupPermissions: state.userPermissionReducer.UserGroupPermissions,
	};
};

type Props = ReturnType<typeof mapStateToProps>;

class App extends React.PureComponent<Props, {}> {
	public render() {
		return (
			<Switch>
				{/* Unauthorized routes has to be at the top */}
				<Route path={Routes.Callback} component={Callback} />
				<Route path={Routes.SignInRedirect} component={LoginRedirectPage} />
				<Route path={Routes.SignIn} component={LoginPage} />
				<Route path={Routes.SignOutRedirect} component={LogoutRedirectPage} />
				<Route path={Routes.NotificationsUnsubscribeWithParam} component={UnsubscribeNotifications} />
				<AuthRoute
					path={Routes.Profile}
					component={ProfilePage}
					authPageRequirement={profilePageAuthRequirements}
				/>
				<AuthRoute
					path={Routes.Administration}
					component={AdministrationPage}
					authPageRequirement={administrationPageAuthRequirements}
				/>
				<AuthRoute
					path={Routes.UnitHistoryWithParam}
					component={UnitHistory}
					authPageRequirement={unitsPageAuthRequirements}
				/>
				<AuthRoute
					path={Routes.MachineDetailsWithParam}
					component={MachineDetails}
					authPageRequirement={unitsPageAuthRequirements}
				/>
				<AuthRoute
					path={
						authPermissionCheck(this.props.userGroupPermissions, unitsPageAuthRequirements)
							? Routes.PartnerArea
							: [RouteBasePath, Routes.PartnerArea]
					}
					component={PartnerAreaPage}
					authPageRequirement={partnerAreaPageAuthRequirements}
				/>
				{/* TODO: DASHBOARD. Once dashboard is stable, add this back */}
				{/* <AuthRoute path={routesToDashboard} exact={true} component={Dashboard} /> */}
				{/* // HomePage is RouteSafe internally - so no need for AuthRoute here */}
				<Route path={routesToHomePage} component={HomePage} />
			</Switch>
		);
	}
}

export default connect(mapStateToProps)(withAITracking(reactAI.reactPlugin, App, 'App'));
