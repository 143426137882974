import React from 'react';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import './bi-checkbox.scss';

export interface BiCheckboxProps extends CheckboxProps {
	label?: React.ReactNode;
	containerClassName?: string;
	containerStyle?: React.CSSProperties;
	labelClassName?: string;
	dataId?: string;
}

class BiCheckbox extends React.PureComponent<BiCheckboxProps> {
	public render() {
		return (
			<div key={this.props.id} className={this.props.containerClassName} style={this.props.containerStyle}>
				<Checkbox data-id={this.props.dataId} id={this.props.dataId} {...this.props} className={this.props.className || 'bi-checkbox'} />
				{this.props.label && (
					<label
						htmlFor={this.props.inputId}
						className={`p-checkbox-label ${this.props.labelClassName || ''}`}
					>
						{this.props.label}
					</label>
				)}
			</div>
		);
	}
}
export default BiCheckbox;
