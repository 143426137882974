import { IServiceVideoDto, UnitDocument } from 'api/api';
import { localized } from 'state/i18n';

export enum ManualType {
	pdf,
	svg,
	video,
}

export enum DocumentGroupType {
	document,
	userDocument,
	video,
	userVideo,
	service,
}

export interface ManualItem {
	url: string;
	type: ManualType;
	group: DocumentGroupType;
	name: string;
}

export const getManualItemsFromServiceTechnicianVideos = (videos: IServiceVideoDto[]): ManualItem[] => {
	const manualItems = videos.map(vid =>
		getSingleManualItem(DocumentGroupType.service, vid.title ?? '', vid.vimeoVideoId ?? '')
	);
	return manualItems.filter((x): x is ManualItem => x !== null);
};

export const getManualItemsFromDeviceDocuments = (deviceDocuments: UnitDocument | undefined): ManualItem[] => {
	let manualItems = new Array<ManualItem | null>();

	// User manuals
	manualItems.push(
		getSingleManualItem(DocumentGroupType.userDocument, localized('UserDocument'), deviceDocuments?.userManual)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.userDocument,
			localized('MANUALS_QuickGuideCordAndStrap'),
			deviceDocuments?.userManual2
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.userDocument,
			localized('MANUALS_QuickGuideSteelWire'),
			deviceDocuments?.userManual3
		)
	);

	// Quick guide
	manualItems.push(
		getSingleManualItem(DocumentGroupType.document, localized('MANUALS_QuickGuide'), deviceDocuments?.quickGuides)
	);

	// Operation videos
	manualItems.push(
		getSingleManualItem(DocumentGroupType.userVideo, localized('MANUALS_UserManual1'), deviceDocuments?.userVideo)
	);
	manualItems.push(
		getSingleManualItem(DocumentGroupType.userVideo, localized('MANUALS_UserManual2'), deviceDocuments?.userVideo2)
	);
	manualItems.push(
		getSingleManualItem(DocumentGroupType.userVideo, localized('MANUALS_UserManual3'), deviceDocuments?.userVideo3)
	);

	// Service videos
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.service,
			localized('MANUALS_ServiceVideo1'),
			deviceDocuments?.serviceVideo1
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.service,
			localized('MANUALS_ServiceVideo2'),
			deviceDocuments?.serviceVideo2
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.service,
			localized('MANUALS_ServiceVideo3'),
			deviceDocuments?.serviceVideo3
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.service,
			localized('MANUALS_ServiceVideo4'),
			deviceDocuments?.serviceVideo4
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.service,
			localized('MANUALS_ServiceVideo5'),
			deviceDocuments?.serviceVideo5
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.service,
			localized('MANUALS_ServiceVideo6'),
			deviceDocuments?.serviceVideo6
		)
	);

	// Misc documents
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.document,
			localized('MANUALS_SparePartsManual'),
			deviceDocuments?.sparePartsManual
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.document,
			localized('MANUALS_HydraulicsDiagram'),
			deviceDocuments?.hydraulicsDiagram
		)
	);
	manualItems.push(
		getSingleManualItem(
			DocumentGroupType.document,
			localized('MANUALS_ElectricalDiagram'),
			deviceDocuments?.electricalDiagram
		)
	);
	manualItems.push(
		getSingleManualItem(DocumentGroupType.document, localized('MANUALS_Diagrams'), deviceDocuments?.diagram)
	);
	// Remove nulls
	let filteredManualItems = manualItems.filter((x): x is ManualItem => x !== null);

	return filteredManualItems;
};

const getManualType = (fileName: string, documentVideoType: DocumentGroupType): ManualType => {
	// video
	if (
		documentVideoType === DocumentGroupType.video ||
		documentVideoType === DocumentGroupType.userVideo ||
		documentVideoType === DocumentGroupType.service
	)
		return ManualType.video;
	// svg
	if (fileName.endsWith('.svg')) return ManualType.svg;
	// pdf
	return ManualType.pdf;
};

const getSingleManualItem = (
	documentVideoType: DocumentGroupType,
	name: string,
	fileName: string | undefined
): ManualItem | null => {
	if (!fileName) {
		return null;
	}

	const manualType: ManualType = getManualType(fileName, documentVideoType);

	return {
		name: name,
		type: manualType,
		group: documentVideoType,
		url: fileName + (manualType === ManualType.pdf && !fileName.endsWith('.pdf') ? '.pdf' : ''),
	};
};
