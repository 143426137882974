import * as React from "react";
import { Button } from "primereact/button";
import { localized } from "state/i18n";
import history from "state/history";
import { Routes } from "state/ducks/routes";
import "./sign-in.scss";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactAI } from 'services/telemetry-service';
import BiTitle from 'view/shared/components/bi-title/bi-title';

interface State {}

class LoginPage extends React.PureComponent<{}, State> {

  constructor(props: {}) {
    super(props);
    history.replace(Routes.SignInRedirect);
  }
  public render() {
    return (
      <BiTitle title={localized("SignIn")}>
      <div className="outer-div flex-center-row">
        <div className="flex-center-column">
          <Button
            className="p-button-custom p-button-secondary"
            label={localized("SignIn")}
            onClick={this.signIn}
          />
        </div>
      </div>
      </BiTitle>
    );
  }

  private signIn = () => {
    history.replace(Routes.SignInRedirect);
  };
}

export default withAITracking(reactAI.reactPlugin, LoginPage, "Sign in");
