import { ProfileUnit } from "api/api";
import { getStore } from "state/store";


/**
 * Rounds a number to a specified number of decimals
 * @param value The number to round down
 * @param decimals Number of decimals to round down to. Default is 2.
 */
export function roundToDecimals(value: number, decimals: number = 2) {
    const tenToThePowerOfDecimals = Math.pow(10, decimals);
    return Math.round(value * tenToThePowerOfDecimals) / tenToThePowerOfDecimals;
}


export const biConvertNumberToSeparatedString = (value: number) => {
    const profileUnit = getStore().getState().userSettingReducer.userSettings.profileUnits;

    if (profileUnit === ProfileUnit.Imperial)
        return value.toLocaleString("en");
    return value.toLocaleString("da");
}

export function isNumeric(value: any) {
    return !isNaN(value - parseFloat(value));
}