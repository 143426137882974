import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ReadReleaseNotes } from 'state/ducks/release-notes/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { BiStepConfirmDialog } from 'view/shared/components/dialogs/bi-step-confirm-dialog/bi-step-confirm-dialog';
import ReleaseNote from '../release-note';
import './release-note-step-confirm-dialog.scss';

export const ReleaseNoteStepConfirmDialog: React.FC<{}> = props => {
	const dispatch: Dispatch = useDispatch();

	const newReleaseNotes = useSelector((state: AppState) =>
		state.releaseNoteReducer.notes.filter(releaseNote => releaseNote.isNew)
	);

	const onClose = async () => {
		await ReadReleaseNotes(newReleaseNotes)(dispatch);
	};

	const releaseNoteItems = newReleaseNotes
		.sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1))
		.map(releaseNote => (
			<div className="releasenote-wrapper release-note-dialog-wrapper no-select margin-top-10px">
				<ReleaseNote releaseNoteId={releaseNote.id} />
			</div>
		));

	const releaseNoteLabels = newReleaseNotes.map(item => item.title ?? '-');

	return newReleaseNotes.length ? (
		<BiStepConfirmDialog
			title={localized('ReleaseNote')}
			items={releaseNoteItems}
			itemLabels={releaseNoteLabels}
			visible={!!newReleaseNotes.length}
			onClose={onClose}
			onHide={() => {}}
		></BiStepConfirmDialog>
	) : null;
};
