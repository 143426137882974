import { AccessTokenKey } from './constants';

import { decodeToken } from './auth-helper';

type TokenProps = 'Email' | 'sub';

export function getAccessTokenFromLocalStorage() {
	return localStorage.getItem(AccessTokenKey);
}

function getFieldFromToken(field: TokenProps, token?: string | null) {
	if (!token) {
		token = localStorage.getItem(AccessTokenKey);
	}

	const decodedToken = decodeToken(token);
	let tokenField = '';

	if (decodedToken) {
		tokenField = decodedToken[field] || '';
	}

	return tokenField;
}

export function getEmailFromToken(token?: string | null) {
	return getFieldFromToken('Email', token);
}
