import { ITableSettings, TableId, TableSettings } from 'api/api';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import {
	allColumns,
	defaultColumns,
	MachineDtoKeys,
	TableColumnWidthInfoGeneric,
	TableSettingsList,
	TableSettingsState
} from './types';
import { Sorting } from '@devexpress/dx-react-grid';


export const permanentLockedColumns: MachineDtoKeys[] = [
	"select",
	"unitId",
	"moreInfo"
]

const defaultTableSettingColumnOrder: MachineDtoKeys[] =
	[
		"select",
		"unitId",
		"connectionStatusAsSignalStrength",
		"isOnline",
		"updatedDateFriendly",
		"lastestChange",
		"errorAndSeverities",
		"devicePhoneNo",
		"fillLevel",
		"customerUnitId",
		"manufacturer",
		"unitModel",
		"containerSize",
		"controllerName",
		"modemName",
		"firmwareVersion",
		"gpsLocation",
		"homeLocation",
		"trackerBatteryLevel",
		"pickupSystem",
		"wasteTypeTranslationKey",
		"collector",
		"distributor",
		"distributorCountry",
		"groupName",
		"siteUser",
		"siteAddress",
		"siteDescription",
		"siteCity",
		"sitePostalCode",
		'ownershipTranslationKey',
		'installationDate',
		"warrantyExpires",
		"serviceContractTypeTranslationKey",
		"lastService",
		"nextService",
		"unitsEmptied",
		"unitsEmptiedThirtyDays",
		"averageCyclesPerUnitEmptiedThirtyDays",
		"totalRunningHours",
		"totalNumberOfCycles",
		"cyclesSinceLastOutput",
		"pressSeries",
		"pressSeriesSinceLastOutput",
		"comments",
		"predictedEmptyingDate",
		"moreInfo",
		"uploadedDate",
		"daysSinceInstalled",
		"fullCounterAverage",
		"balesOnSite"
	];

const defaultTableSettingColumnWidth: TableColumnWidthInfoGeneric<MachineDtoKeys>[] =
	[
		{ columnName: "select", width: 35 },
		{ columnName: "isOnline", width: 151 },
		{ columnName: "connectionStatusAsSignalStrength", width: 120 },
		{ columnName: "updatedDateFriendly", width: 115 },
		{ columnName: "lastestChange", width: 154 },
		{ columnName: "devicePhoneNo", width: 158 },
		{ columnName: "fillLevel", width: 200 },
		{ columnName: "balesOnSite", width: 200 },
		{ columnName: "pickupSystem", width: 136 },
		{ columnName: "unitId", width: 105 },
		{ columnName: "gpsLocation", width: 170 },
		{ columnName: "homeLocation", width: 170 },
		{ columnName: "trackerBatteryLevel", width: 140 },
		{ columnName: "controllerName", width: 140 },
		{ columnName: "firmwareVersion", width: 170 },
		{ columnName: "manufacturer", width: 140 },
		{ columnName: "unitModel", width: 151 },
		{ columnName: "customerUnitId", width: 235 },
		{ columnName: "wasteTypeTranslationKey", width: 98 },
		{ columnName: "collector", width: 86 },
		{ columnName: "distributor", width: 180 },
		{ columnName: "distributorCountry", width: 180 },
		{ columnName: "groupName", width: 215 },
		{ columnName: "siteUser", width: 180 },
		{ columnName: "siteAddress", width: 330 },
		{ columnName: "siteCity", width: 186 },
		{ columnName: "sitePostalCode", width: 106 },
		{ columnName: 'ownershipTranslationKey', width: 110 },
		{ columnName: 'installationDate', width: 140 },
		{ columnName: "serviceContractTypeTranslationKey", width: 165 },
		{ columnName: 'lastService', width: 115 },
		{ columnName: "nextService", width: 116 },
		{ columnName: "unitsEmptied", width: 75 },
		{ columnName: "unitsEmptiedThirtyDays", width: 112 },
		{ columnName: "predictedEmptyingDate", width: 200 },
		{ columnName: "totalRunningHours", width: 123 },
		{ columnName: "totalNumberOfCycles", width: 125 },
		{ columnName: 'cyclesSinceLastOutput', width: 159 },
		{ columnName: "pressSeries", width: 125 },
		{ columnName: 'pressSeriesSinceLastOutput', width: 159 },
		{ columnName: "averageCyclesPerUnitEmptiedThirtyDays", width: 156 },
		{ columnName: "warrantyExpires", width: 150 },
		{ columnName: "comments", width: 180 },
		{ columnName: "moreInfo", width: 140 },
		{ columnName: "connectionStatusAsSignalStrength", width: 160 },
		{ columnName: "errorAndSeverities", width: 70 },
		{ columnName: "uploadedDate", width: 154 },
		{ columnName: "siteDescription", width: 150 },
		{ columnName: "daysSinceInstalled", width: 75 },
		{ columnName: "fullCounterAverage", width: 75 },
		{ columnName: "containerSize", width: 170 },
		{ columnName: "modemName", width: 170 },
	];

const defaultTableSettingColumnSorting: Sorting[] = [{
	columnName: "unitId",
	direction: "desc"
}];

const defaultTableSettingColumnsLocked: MachineDtoKeys[] = permanentLockedColumns;


export const defaultTableSettings: TableSettingsList = {
	columnOrder: defaultTableSettingColumnOrder,
	columnWidths: defaultTableSettingColumnWidth,
	sorting: defaultTableSettingColumnSorting,
	columns: [...defaultColumns.map(a => { return { ...a } })],

	tableSettings: TableSettings.fromJS({ id: 0, tableId: TableId.Machines, isActive: false }),
	columnsLocked: defaultTableSettingColumnsLocked,
}


export const initialState: TableSettingsState = {
	currentTableSettingId: defaultTableSettings.tableSettings.id,
	loaded: false,
	selectedMachines: [],
	tableSettingsList: [
		{ ...defaultTableSettings },
	],
	timesUserSelectedMachines: 0
}

export function tableSettingsReducer(
	state: TableSettingsState = initialState,
	action: Action
): TableSettingsState {
	if (isType(action, actions.deleteTableSettingsAction.done)) {
		return {
			...state,
			tableSettingsList: [
				...state.tableSettingsList.filter(set => set.tableSettings.id !== action.payload.params)
			]
		};
	}

	if (isType(action, actions.addMachineTableSettingAction.done)) {
		let tableSettingsList = [...state.tableSettingsList];
		tableSettingsList.push(ITableSettingToTableSettingsList(action.payload.result));
		return {
			...state,
			tableSettingsList: tableSettingsList
		}
	}

	if (isType(action, actions.setNameAction)) {
		return {
			...state,
			tableSettingsList: [
				...state.tableSettingsList.map((list) => list.tableSettings.id !== state.currentTableSettingId
					? list
					: {
						...list, tableSettings: {
							...list.tableSettings, settingsName: action.payload
						}
					}
				)
			]
		};
	}

	if (isType(action, actions.setColumnOrderAction)) {
		return {
			...state,
			tableSettingsList: [
				...state.tableSettingsList.map((list) => list.tableSettings.id !== state.currentTableSettingId
					? list
					: { ...list, columnOrder: action.payload }
				)
			]
		};
	}

	if (isType(action, actions.setLockedColumnsAction)) {
		let lockedColumns: MachineDtoKeys[] = action.payload;
		permanentLockedColumns.forEach(col => {
			if (!lockedColumns.includes(col))
				lockedColumns.push(col);
		})

		return {
			...state,
			tableSettingsList: [
				...state.tableSettingsList.map((list) => list.tableSettings.id !== state.currentTableSettingId
					? list
					: { ...list, columnsLocked: lockedColumns }
				)
			]
		};
	}

	if (isType(action, actions.setColumnSizeAction)) {
		return {
			...state,
			tableSettingsList: [
				...state.tableSettingsList.map((list) => list.tableSettings.id !== state.currentTableSettingId
					? list
					: { ...list, columnWidths: action.payload }
				)
			]
		};
	}
	if (isType(action, actions.setColumnSortingAction)) {
		return {
			...state,
			tableSettingsList: [
				...state.tableSettingsList.map((list) => list.tableSettings.id !== state.currentTableSettingId
					? list
					: { ...list, sorting: action.payload }
				)
			]
		};
	}
	if (isType(action, actions.setColumnsAction)) {
		return {
			...state,
			tableSettingsList: [
				...state.tableSettingsList.map((list) => list.tableSettings.id !== state.currentTableSettingId
					? list
					: { ...list, columns: action.payload }
				)
			]
		};
	}
	if (isType(action, actions.getTableSettingsAction.done)) {
		if (action.payload.result) {
			let tableSettingsList: TableSettingsList[] = [...state.tableSettingsList.filter(tableSetting => tableSetting.tableSettings.id === defaultTableSettings.tableSettings.id)];	//keep default
			action.payload.result.forEach(tableSetting => {
				tableSettingsList.push(ITableSettingToTableSettingsList(tableSetting));
			});
			return {
				...state,
				loaded: true,
				tableSettingsList: tableSettingsList,
				currentTableSettingId: (tableSettingsList.find(tsl => tsl.tableSettings.isActive) || defaultTableSettings).tableSettings.id
			};
		}
	}
	if (isType(action, actions.saveTableSettingsAction.done)) {
		if (action.payload.result) {
			let result: TableSettingsList | undefined = state.tableSettingsList.find(tsl => tsl.tableSettings.id === action.payload.result!.id)
			if (!result) return state;
			result.tableSettings = action.payload.result;

			return {
				...state,
				tableSettingsList: state.tableSettingsList.map(ts =>
					action.payload.result?.id === ts.tableSettings.id
						? result!
						: ts
				),
				currentTableSettingId: result.tableSettings.id,
			};
		}
	}
	if (isType(action, actions.setSelectedMachinesAction)) {
		return {
			...state,
			selectedMachines: action.payload
		};
	}
	if (isType(action, actions.setUserSelectedMachinesAction)) {
		return {
			...state,
			selectedMachines: action.payload,
			timesUserSelectedMachines: ++state.timesUserSelectedMachines
		};
	}
	if (isType(action, actions.setMachineSelectedAction)) {
		let index = state.selectedMachines.indexOf(action.payload);
		if (index < 0) {
			let clone = [...state.selectedMachines];
			clone.push(action.payload);

			return {
				...state,
				selectedMachines: clone
			};
		}
	}
	if (isType(action, actions.selectOnlyOneMachineAction)) {
		return {
			...state,
			selectedMachines: [action.payload]
		};
	}
	if (isType(action, actions.setMachineUnSelectedAction)) {
		let index = state.selectedMachines.indexOf(action.payload);
		if (index > -1) {
			let clone = [...state.selectedMachines];
			clone.splice(index, 1);
			return {
				...state,
				selectedMachines: clone
			};
		}
	}

	if (isType(action, actions.setCurrentTableSettingIdAction)) {
		return {
			...state,
			currentTableSettingId: action.payload
		}
	}

	return state;
}


function isColumnInArrayOfAllColumns(colId: MachineDtoKeys): boolean {
	return allColumns.some(allCol => allCol.name === colId);
}



function ITableSettingToTableSettingsList(tableSetting: ITableSettings): TableSettingsList {
	const settings = JSON.parse(tableSetting.settingsJson!) as TableSettingsList;

	// Clean up column widths and orders for old data.
	const newColumnWidths = settings.columnWidths.filter(col => isColumnInArrayOfAllColumns(col.columnName));
	const newColumnOrders = settings.columnOrder.filter(col => isColumnInArrayOfAllColumns(col));
	const newColumns = settings.columns.filter(col => isColumnInArrayOfAllColumns(col.name));
	const columnsInOrderFromApi = newColumns.map(column => ({
		name: column.name,
		title: ''
	}));
	const newColumnsLocked: MachineDtoKeys[] = settings.columnsLocked?.filter(col => isColumnInArrayOfAllColumns(col)) || defaultTableSettingColumnsLocked;

	return {
		columnOrder: newColumnOrders,
		columnWidths: newColumnWidths,
		columns: columnsInOrderFromApi,
		sorting: settings.sorting,
		tableSettings: tableSetting,
		columnsLocked: newColumnsLocked,
	}
}