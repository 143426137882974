import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { UnitModelCodeState } from './types';

const initialState: UnitModelCodeState = {
	unitModelCodes: [],
};

export function unitModelCodeReducer(state: UnitModelCodeState = initialState, action: Action): UnitModelCodeState {
	if (isType(action, actions.getUnitModelCodesAction.done)) {
		return {
			...state,
			unitModelCodes: action.payload.result,
		};
	}

	return state;
}
