import React from 'react';
import { LabelValues } from './label-value-card';
import './text-input.scss';

interface AppProps {
	value: LabelValues;
	hidden: boolean;
	callbackSave?: (value: any) => void;
}

interface State {
	value: LabelValues;
	hidden: boolean;
}

type Props = AppProps;
export default class TextInput extends React.PureComponent<Props, State> {
	public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (nextProps.hidden !== prevState.hidden) {
			return {
				...prevState,
				hidden: nextProps.hidden,
				value: nextProps.value
			};
		} else {
			return {
				...prevState
			};
		}
	}

	private nameInput: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			value: this.props.value,
			hidden: this.props.hidden
		};

		//this.nameInput = React.createRef();
	}

	public componentDidUpdate() {
		this.nameInput.focus();
	}

	public render() {
		return (
			<input
				ref={(input: any) => {
					this.nameInput = input;
				}}
				className="text-input"
				value={this.state.value ?? undefined}
				onBlur={this.props.callbackSave}
				onChange={this.handleChange}
			></input>
		);
	}

	private handleChange = (e: any) => {
		this.setState({
			...this.state,
			value: e.target.value
		});
	};
}
