import { IIoTHubDuplicateDecommissionDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const iotHubDecommissionCreator = actionCreatorFactory('iot-hub-decommissions');

export const GetIoTHubDuplicateDecommissionsAction = iotHubDecommissionCreator.async<
	undefined,
	IIoTHubDuplicateDecommissionDto[]
>(constants.GET_DUPLICATES);
export const DeleteIoTHubDuplicateDecommissionAction = iotHubDecommissionCreator.async<
	string,
	IIoTHubDuplicateDecommissionDto[]
>(constants.DELETE_DUPLICATE);
