import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import './language-picker.scss';
import { AppState } from 'state/store';
import { SelectedLanguage } from 'api/api';
import { connect } from 'react-redux';
import { LanguageOptions } from 'utilities/language-util';


interface State {
	currentLanguage: SelectedLanguage;
}

interface ParentProps {
	onSelectedChange?: (selectedLanguage: SelectedLanguage) => void;
	parentLanguage?: SelectedLanguage;
}

const mapStateToProps = (state: AppState) => {
	return {
		userSettings: state.userSettingReducer.userSettings,
	};
};



type Props = ParentProps & ReturnType<typeof mapStateToProps>;

class LanguagePicker extends React.PureComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			currentLanguage: this.props.parentLanguage ?? props.userSettings.selectedLanguage,
		};
	}

	componentDidMount = () => {
		if (this.props.onSelectedChange && this.props.parentLanguage !== this.state.currentLanguage){
			this.props.onSelectedChange(this.state.currentLanguage);
		}
	}

	public render() {

		return (
			<div className="flex-direction-column language-picker">
				<Dropdown
					onChange={this.setLanguage}
					options={LanguageOptions}
					value={this.state.currentLanguage} />
			</div>
		);
	}



	private setLanguage = (e: any) => {
		if (this.props.onSelectedChange) {
			this.props.onSelectedChange(e.target.value);
		}

		this.setState({
			...this.state,
			currentLanguage: e.target.value,
		});
	};

}

export default connect(mapStateToProps)(LanguagePicker);
