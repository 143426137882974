import { IUnitTracker } from 'api/api';
import { PageState, Paginator } from 'primereact/paginator';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getUnitTrackers } from 'state/ducks/tracker/operations';
import { localized } from 'state/i18n';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import './tracker-unit-overview.scss';

type Props = {};

export const TrackerUnitOverview: React.FC<Props> = React.memo((props: Props) => {

    const dispatch = useAppDispatch();
    const deviceTrackers: IUnitTracker[] = useAppSelector(state => state.deviceTrackerReducer.unitTrackers)

    const [paginatorFirst, setPaginatorFirst] = useState(0);
    const [paginatorCurrentPage, setPaginatorCurrentPage] = useState(0);
    const [paginatorRows, setPaginatorRows] = useState(12);
    const [totalRows, setTotalRows] = useState(0);
    const [paginatorPageCount, setPaginatorPageCount] = useState(0);



    useEffect(() => {
        // Constructor
        (getUnitTrackers())(dispatch)
    }, [dispatch])

    const itemGenerator = (serialNumber: string | undefined, trackerid: string | undefined) => {
        const unit = <span>{serialNumber}</span>;
        const tracker = <span>{trackerid}</span>;

        return (
            <div
                key={serialNumber ?? '' + trackerid ?? ''}
                className={`margin-bottom-10px bi-label-content-item-box-shadow bi-label-content-item-lg tracker-unit-grid min-height-50px`}
            >
                {unit}
                {tracker}
            </div >
        )
    }

    const getTrackerToUnits = (): JSX.Element[] => {
        let rows: JSX.Element[] = deviceTrackers
            //Sort by deviceid. If equal, sort by trackerid
            .sort((ut1, ut2) => {
                if (!ut1.unit?.serialNumber || !ut2.unit?.serialNumber) return 0;
                return ut1.unit?.serialNumber > ut2.unit?.serialNumber ? 1 : -1;
            })
            .map(ut => itemGenerator(ut.unit?.serialNumber, ut.trackerId))
            .filter((e: JSX.Element | undefined): e is JSX.Element => !!e);

        if (totalRows !== rows.length) setTotalRows(rows.length);

        return rows.slice(paginatorFirst, paginatorFirst + paginatorRows);
    }

    const onPageChangeHandler = (e: PageState) => {
        setPaginatorFirst(e.first);
        setPaginatorRows(e.rows);
        setPaginatorCurrentPage(e.page);
        setPaginatorPageCount(e.pageCount);
    };

    const paginatedrows: JSX.Element[] = getTrackerToUnits();

    return (
        <div id="tracker-unit-overview">
            <BiLabelContent containerClassName="flex-fill-width">
                <div className="tracker-unit-grid padding-left-20px padding-right-20px">
                    <b>{localized('unitId')}</b>
                    <b>{localized('Tracker')}</b>
                </div>

                <div>
                    {paginatedrows}
                </div>

                <div className="flex-end-row">
                    <Paginator
                        first={paginatorFirst}
                        rows={paginatorRows}
                        totalRecords={totalRows}
                        onPageChange={onPageChangeHandler}
                        template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    ></Paginator>
                </div>
            </BiLabelContent>
        </div>
    );
});