import React from 'react';
import { localized } from 'state/i18n';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import { DocumentGroupType, ManualItem } from '../documents-helper';
import './document-button.scss';

interface AppProps {
	manualItems: ManualItem[];
	documentGroupType: DocumentGroupType | undefined;
	onHide: () => void;
	visible: boolean;
	manualItemToDomElememt: (item: ManualItem) => JSX.Element;
}

export const DeviceDocumentGroupDialog: React.FC<AppProps> = props => {
	const getDocuments = (): JSX.Element[] => {
		return props.manualItems
			?.filter(item => item.group === props.documentGroupType)
			.map(manual => props.manualItemToDomElememt(manual));
	};

	const getTitle = (): string => {
		if (props.documentGroupType === DocumentGroupType.document) return localized('Documents');
		if (props.documentGroupType === DocumentGroupType.userDocument) return localized('Documents');
		if (props.documentGroupType === DocumentGroupType.video) return localized('Videos');
		if (props.documentGroupType === DocumentGroupType.userVideo) return localized('Videos');
		if (props.documentGroupType === DocumentGroupType.service) return localized('Service');

		return localized('Documents');
	};

	return (
		<BiTextDialog visible={props.visible} onHide={props.onHide} title={getTitle()}>
			<div className="document-btn-dialog-grid">{getDocuments()}</div>
		</BiTextDialog>
	);
};
