import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/store';
import { useQueryParam } from 'use-query-params';
import { DashboardTileIdQueryParam, GPSHistoryIdQueryParam, GPSHistoryLocationQueryParam } from 'utilities/constants';
import { usePrevious } from 'utilities/use-previous';
import { DashboardLocation } from './dashboard-location';
import { GpsHistoryLocation } from './gps-history-location';
import { UnitsLocation } from './units-location';

export const Location: FC = React.memo(() => {
	const [dashboardTileId, setDashboardTileId] = useQueryParam<string | undefined>(DashboardTileIdQueryParam);
	const [gpsHistoryId, setGpsHistoryId] = useQueryParam<string | undefined>(GPSHistoryIdQueryParam);
	const [gpsHistoryLocation, setGpsHistoryLocation] = useQueryParam<string | undefined>(GPSHistoryLocationQueryParam);
	const timesTriggeredByUser = useSelector((state: AppState) => state.filterSearchReducer.timesTriggeredByUser);
	const prevTimesTriggeredByUser = usePrevious(timesTriggeredByUser);
	const timesUserSelectedMachines = useSelector(
		(state: AppState) => state.tableSettingsReducer.timesUserSelectedMachines
	);
	const prevTimesUserSelectedMachines = usePrevious(timesUserSelectedMachines);

	// Clear URL query params then user changes filter
	useEffect(() => {
		if (prevTimesTriggeredByUser !== timesTriggeredByUser && prevTimesTriggeredByUser !== undefined) {
			setDashboardTileId(undefined);
			setGpsHistoryId(undefined);
			setGpsHistoryLocation(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps,
	}, [timesTriggeredByUser]);

	// Clear URL query params then user changes selected machines
	useEffect(() => {
		if (
			prevTimesUserSelectedMachines !== timesUserSelectedMachines &&
			prevTimesUserSelectedMachines !== undefined
		) {
			setDashboardTileId(undefined);
			setGpsHistoryId(undefined);
			setGpsHistoryLocation(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps,
	}, [timesUserSelectedMachines]);

	if (dashboardTileId) {
		return <DashboardLocation />;
	} else if (gpsHistoryId) {
		return <GpsHistoryLocation />;
	} else {
		return <UnitsLocation />;
	}
});
