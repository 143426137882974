import { MachineSelectedState } from './types';
import { FilterDto, IFilterDto, UnitSelectedClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { unitSelectedClient } from '../api-clients';
import { GetMachinesSelectedAction } from './actions';
import { store } from 'index';

export function getMachinesSelected(filter?: IFilterDto) {
	let apiInput = new FilterDto(filter);
	let callCounter = (store.getState().machineSelectedReducer as MachineSelectedState).callCounter;

	return AsyncOperationBuilder(
		GetMachinesSelectedAction,
		apiClient => (apiClient as UnitSelectedClient).getByFilter(apiInput),
		{ callCounter: ++callCounter },
		unitSelectedClient
	);
}
