import { IServiceVideoDto } from 'api/api';
import { Button } from 'primereact/button';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteServiceVideosOperation } from 'state/ducks/service-video/operations';
import { localized, localizedInterpolation } from 'state/i18n';
import { ConfirmDialog } from 'view/shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';

interface Props {
	item: IServiceVideoDto;
}

export const ServiceVideoListItem: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();

	const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

	const showDeleteDialog = () => {
		setDeleteDialogVisible(true);
	};

	const hideDeleteDialog = () => {
		setDeleteDialogVisible(false);
	};

	const onDelete = async () => {
		(await deleteServiceVideosOperation(props.item))(dispatch);
		hideDeleteDialog();
	};

	const deleteDialog = (
		<ConfirmDialog
			onHide={hideDeleteDialog}
			visible={deleteDialogVisible}
			header={localized('Delete')}
			dialogMessage={localizedInterpolation('XDeleteConfirmMsg', { X: props.item.title })}
			onConfirm={onDelete}
			onCancel={hideDeleteDialog}
			confirmColor="org-red"
		></ConfirmDialog>
	);

	const title = <div>{props.item.title}</div>;
	const videoId = <div>{props.item.vimeoVideoId}</div>;
	const machineTypes = <div>{props.item.machineTypes?.join(', ')}</div>;

	const buttons = (
		<div className="flex-space-between-row content-container-groups">
			{/* TODO::: Edit is not yet supported - remove "invisible" from className when implemented */}
			<Button
				className="p-button-custom p-button-secondary margin-right-10px invisible"
				label={localized('Edit')}
				disabled={true}
				//onClick={handleShowEditDialog}
			/>

			<BiTooltip overlay={localized('Delete')}>
				<div className="flex-container">
					<i onClick={showDeleteDialog} className="pi pi-times-circle"></i>
				</div>
			</BiTooltip>
		</div>
	);

	return (
		<>
			<div
				className="margin-bottom-10px bi-label-content-item-box-shadow bi-label-content-item-lg service-video-grid min-height-50px"
				key={'ServiceVideoAdminItemKey:' + props.item.id}
			>
				{title}
				{videoId}
				{machineTypes}
				{buttons}
			</div>
			{deleteDialog}
		</>
	);
});
