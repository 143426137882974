import { SortingState } from '@devexpress/dx-react-grid';
import { AppState } from 'state/store';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getSettings } from 'state/ducks/table-settings/operations';
import { MachineDtoKeys } from 'state/ducks/table-settings/types';
import { Spinner } from 'view/components/spinner/spinner';
import BiTable from './bi-table';

const mapStateToProps = (state: AppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getSettings: async () => (await getSettings())(dispatch),
});

interface ColumnSortingOptions extends SortingState.ColumnExtension {
	columnName: MachineDtoKeys;
}

const sortingOptions: ColumnSortingOptions[] = [
	{
		columnName: 'moreInfo',
		sortingEnabled: false,
	},
];

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
interface State {
	isLoading?: boolean;
}
class MachineTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = { isLoading: true };
	}

	public async componentDidMount() {
		await this.props.getSettings();
		this.setState({ isLoading: false });
	}

	public render() {
		return this.state.isLoading ? (
			<Spinner shouldOverlay={true} shouldUseAbsolutePositioning={true} />
		) : (
			<BiTable sortingColumnExtensions={sortingOptions} />
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineTable);
