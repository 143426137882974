import React, { CSSProperties } from 'react';
import { i18nTranslationKey, localized } from 'state/i18n';
import BiButton from './buttons/bi-button/bi-button';
import EditableValue, { editableValueMode } from './editable-value';
import './label-value-card.scss';
import { isNullOrWhitespace } from './string-helpers';

const baseRowClassNames = 'border-bottom-grey-thin';
const baseLabelClassNames = 'width-230-px padding-right-2';
const baseValueClassNames = 'width-230-px padding-right-2';

export interface LabelValueForCardMap {
	labels: JSX.Element[];
	valuesForLabels: JSX.Element[];
}

export type LabelValues = string | number | undefined;
export type LabelValuesJSX = LabelValues | JSX.Element;

const removeHttp = (url: string) => {
	const httpWwwText = 'http://www.';
	const httpsWwwText = 'https://www.';
	const httpText = 'http://';
	const httpsText = 'https://';
	const wwwText = 'www.';

	if (url.indexOf(httpWwwText) === 0) {
		return url.substring(httpWwwText.length);
	} else if (url.indexOf(httpsWwwText) === 0) {
		return url.substring(httpsWwwText.length);
	} else if (url.indexOf(httpText) === 0) {
		return url.substring(httpText.length);
	} else if (url.indexOf(httpsText) === 0) {
		return url.substring(httpsText.length);
	} else if (url.indexOf(wwwText) === 0) {
		return url.substring(wwwText.length);
	}

	return url;
};

export const addLabelValueLink = (
	key: i18nTranslationKey,
	valueForLabel: LabelValuesJSX,
	labelValueList: LabelValueForCardMap,
	link = valueForLabel?.toString(),
	shouldOpenInNewWindow = true,
	component: 'button' | 'a' = 'a',
	text?: i18nTranslationKey
) => {
	const newWindowProps: React.DetailedHTMLProps<
		React.AnchorHTMLAttributes<HTMLAnchorElement>,
		HTMLAnchorElement
	> = shouldOpenInNewWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {};

	const displayValueWithoutHttp = typeof valueForLabel !== 'string' ? valueForLabel : removeHttp(valueForLabel);
	const textOrLink = text ? localized(text) : displayValueWithoutHttp;

	const labelValue = (
		<a
			{...newWindowProps}
			href={displayValueWithoutHttp ? link : ''}
			key={`${key}Value`}
			className="word-break-all"
		>
			{component === 'a' ? (
				<>{textOrLink}</>
			) : (
				<BiButton colorTheme="org-dark-blue-with-grey-text" containerTheme="normal-button-corners">
					<span className="flex-wrap flex-center-row">
						<span className="white-space-nowrap text-bold">{textOrLink}</span>
					</span>
				</BiButton>
			)}
		</a>
	);

	labelValueList.labels.push(<span key={`${key}Label`}>{localized(key)}</span>);
	labelValueList.valuesForLabels.push(labelValue);
};

export function addTranslationLabelValueMapToList(
	key: i18nTranslationKey,
	valueForLabel: LabelValuesJSX,
	labelValueList: LabelValueForCardMap,
	editable: boolean = false,
	saveProperty?: (value: any) => void,
	mode?: editableValueMode,
	selection?: any,
	options?: any
): void {
	const modeToUse = mode ? mode : 'text';
	labelValueList.labels.push(<span key={`${key}Label`}>{localized(key)}</span>);
	if (editable) {
		if (options && selection) {
			labelValueList.valuesForLabels.push(
				<EditableValue
					translationKey={key}
					valueForLabel={valueForLabel as LabelValues}
					callbackSave={saveProperty}
					mode={modeToUse}
					dropdownOptions={options}
					selection={selection}
				></EditableValue>
			);
		} else if (mode === 'datePicker') {
			labelValueList.valuesForLabels.push(
				<EditableValue
					mode={modeToUse}
					translationKey={key}
					valueForLabel={valueForLabel as LabelValues}
					callbackSave={saveProperty}
					selection={selection}
				/>
			);
		} else {
			labelValueList.valuesForLabels.push(
				<EditableValue
					mode={modeToUse}
					translationKey={key}
					valueForLabel={valueForLabel as LabelValues}
					callbackSave={saveProperty}
				/>
			);
		}
	} else {
		labelValueList.valuesForLabels.push(
			<span key={`${key}Value`} className="word-break-all">
				{valueForLabel}
			</span>
		);
	}
}

export function getLabelValueOrDefaultToLocalizedNoDataString(strProp?: string | undefined | null) {
	return strProp && !isNullOrWhitespace(strProp) ? strProp : '';
}

export function addJsxLabelAndValueMapToList(
	key: i18nTranslationKey,
	label: JSX.Element,
	valueForLabel: LabelValuesJSX,
	labelValueList: LabelValueForCardMap
): void {
	labelValueList.labels.push(label);
	labelValueList.valuesForLabels.push(<span key={`${key}Value`}>{valueForLabel}</span>);
}

interface AppProps {
	labels: JSX.Element[];
	values: JSX.Element[];
	rowClassName?: string;
	rowStyle?: CSSProperties;
	labelClassName?: string;
	labelStyle?: CSSProperties;
	valueClassName?: string;
	valueStyle?: CSSProperties;
}

type Props = AppProps;

export default class LabelValueCard extends React.PureComponent<Props> {
	public render() {
		const rows = this.props.labels.map((label, index) => {
			const valueIfAny = (index in this.props.values && this.props.values[index]) || null;

			return (
				<div
					key={label.key + index.toString() || index}
					className={`flex-direction-row padding-vertical-0-5 flex-start-column
                ${this.props.rowClassName === undefined ? baseRowClassNames : this.props.rowClassName}`}
					style={this.props.rowStyle}
				>
					<span
						className={`${
							this.props.labelClassName === undefined ? baseLabelClassNames : this.props.labelClassName
						}`}
						style={this.props.labelStyle}
					>
						{label}
					</span>
					<span
						className={`${
							this.props.valueClassName === undefined ? baseValueClassNames : this.props.valueClassName
						}`}
						style={this.props.valueStyle}
					>
						{valueIfAny}
					</span>
				</div>
			);
		});

		return <div className="flex-direction-column flex-fill-remaining-space">{rows}</div>;
	}
}
