import React from 'react';
import './bi-button.scss';
import {
	wrapContentInDarkOverlayWhenHover,
	removeNonHtmlPropsFromBiButtonImmutable,
	getBiButtonClassName,
	BiButtonBaseProps,
} from '../bi-button-base/bi-button-base';
import BiTooltip from '../../bi-tooltip/bi-tooltip';

export interface BiButtonCustomProps {
	childButtonRef?: string | ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement>;
	shouldHighlight?: boolean;
	tooltip?: string;
}

export interface BiButtonProps extends BiButtonCustomProps, BiButtonBaseProps, React.HTMLProps<HTMLButtonElement> {}

class BiButton extends React.PureComponent<BiButtonProps> {
	public render() {
		const { containerClassName, colorTheme, containerTheme, enableDarkOverlay, disabled, tooltip } = this.props;
		const fullClassName = getBiButtonClassName('bi-button', containerTheme, disabled ? 'org-grey' : colorTheme);
		const htmlPropsOnly = removeNonHtmlPropsFromBiButtonImmutable(this.props);
		const buttonPropsOnly = this.removeNonHtmlPropsFromPropsCopy({ ...htmlPropsOnly });

		const buttonContent = (
			<button
				{...buttonPropsOnly}
				className={`${fullClassName}${this.props.shouldHighlight ? ' highlight' : ''}`}
				ref={this.props.childButtonRef}
			>
				<div className="flex-center-column">{this.props.children}</div>
			</button>
		);

		const button =
			enableDarkOverlay !== false ? (
				wrapContentInDarkOverlayWhenHover(buttonContent, containerClassName, containerTheme)
			) : (
				<div className={`${containerClassName || ''}`}>{buttonContent}</div>
			);

		return (
			<BiTooltip overlay={tooltip} noNesting={true}>
				{button}
			</BiTooltip>
		);
	}

	private removeNonHtmlPropsFromPropsCopy = (props: BiButtonCustomProps) => {
		delete props.childButtonRef;
		delete props.shouldHighlight;
		delete props.tooltip;
		return props;
	};
}

export default BiButton;
