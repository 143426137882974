import { IEditGroupsDto } from 'api/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { groupsClient } from 'state/ducks/api-clients';
import { deleteGroup, editGroup } from 'state/ducks/groups/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { mapTreeItemsToTreeSelectItems } from 'utilities/array-to-tree';
import { getEmailFromToken } from 'utilities/token-util';
import BiTreeSelect from 'view/components/bi-tree-select/bi-tree-select';
import { TreeSelectItem } from 'view/components/bi-tree-select/tree-select-item';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import BiLabelContentItem from 'view/shared/components/bi-label-content/bi-label-content-item';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import './edit-group.scss';

const mapStateToProps = (state: AppState) => {
	const groups = state.groupsReducer.adminGroups.find(g => g.id === getEmailFromToken());
	let groupTreeSelect: TreeSelectItem[] = [];

	if (groups) {
		groupTreeSelect = mapTreeItemsToTreeSelectItems(groups.data);
	}

	return {
		groupTreeSelect: groupTreeSelect,
		groups: groups,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	editGroup: async (editGroupsDto: IEditGroupsDto) => (await editGroup(editGroupsDto))(dispatch),
	deleteGroup: async (groupsId: number) => (await deleteGroup(groupsId))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface State {
	selectedGroupId: number;
	newGroupName: string;
	deleteDialogOpen: boolean;
	isSavingData: boolean;
}

class EditGroup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedGroupId: 0,
			newGroupName: '',
			deleteDialogOpen: false,
			isSavingData: false,
		};
	}

	public render() {
		if (!this.props.groupTreeSelect.length) {
			return null;
		}

		return (
			<div id="edit-group" className="margin-bottom-20px">
				<div className="flex-direction-column">
					<div className="margin-bottom-5px">
						<h5 className="text-bold">{localized('EditGroup')}</h5>
					</div>
					<BiLabelContent containerClassName="flex-fill-width">
						{this.getHeaderLabels()}
						{this.getGroupRows()}
					</BiLabelContent>
				</div>

				{this.getDeleteDialog()}
			</div>
		);
	}

	private getDeleteDialog() {
		return (
			<BiTextDialog
				visible={this.state.deleteDialogOpen}
				onHide={this.closeDeleteDialog}
				title={localized('DeleteGroup')}
				subtitle={localized('DeleteGroupDialog')}
			>
				<div className="flex-end-row margin-top-10px">
					<BiButton
						colorTheme="org-primary-grey"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={this.closeDeleteDialog}
					>
						{localized('Cancel')}
					</BiButton>
					<BiButton
						colorTheme="org-red"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={this.deleteGroup}
					>
						{localized('DeleteGroup')}
					</BiButton>
				</div>
			</BiTextDialog>
		);
	}

	private setNewGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newGroupName: e.target.value });
	};

	private getGroupRows = () => {
		let parentGroupTree = (
			<BiTreeSelect
				onCheckChanged={this.groupsChanged}
				nodes={this.props.groupTreeSelect}
				selectedItems={
					this.state.selectedGroupId && this.state.selectedGroupId !== 0 ? [this.state.selectedGroupId] : []
				}
				mustHaveSelection={true}
			/>
		);

		let childGroupName = (
			<div className="flex-space-between-row content-container-groups ">
				<InputText
					placeholder="Group name"
					className="input-box-bramidan"
					value={this.state.newGroupName}
					onChange={this.setNewGroupName}
				/>
				<Button className="p-button-custom p-button-secondary" label={localized('Save')} onClick={this.save} />
				<BiTooltip overlay={localized('DeleteGroup')}>
					<div className="flex-container">
						<i onClick={this.openDeleteDialog} className="pi pi-times-circle"></i>
					</div>
				</BiTooltip>
			</div>
		);

		return (
			<BiLabelContentItem
				containerClassName="margin-bottom-10px"
				defaultClassNames="bi-label-content-item-lg"
				label={parentGroupTree}
				content={childGroupName}
			/>
		);
	};

	private closeDeleteDialog = () => {
		this.setState({ deleteDialogOpen: false });
	};

	private openDeleteDialog = () => {
		this.setState({ deleteDialogOpen: true });
	};

	private groupsChanged = async (groupIds: number[]) => {
		let groupId = groupIds && groupIds.length ? groupIds[0] : 0;
		let group = this.props.groups!.data.find(superGroup => superGroup.id === groupId);
		let selectedGroupName = '';

		if (group && group.groupName) {
			selectedGroupName = group.groupName;
		}

		this.setState({ selectedGroupId: groupId, newGroupName: selectedGroupName });
	};

	private save = async () => {
		if (this.state.isSavingData || !(await this.validateSave())) {
			return;
		}
		this.setState({ isSavingData: true });
		try {
			await this.props.editGroup({
				groupName: this.state.newGroupName,
				groupId: this.state.selectedGroupId,
			});
			notificationService.showSuccessMessage(localized('Saved'));
			this.setState({
				newGroupName: '',
				selectedGroupId: 0,
			});
		} finally {
			this.setState({ isSavingData: false });
		}
	};

	private deleteGroup = async () => {
		if (this.state.isSavingData || !(await this.validateDelete())) {
			return;
		}

		this.setState({ isSavingData: true });
		try {
			await this.props.deleteGroup(this.state.selectedGroupId);
			notificationService.showSuccessMessage(localized('Deleted'));
			this.setState({
				newGroupName: '',
				selectedGroupId: 0,
				deleteDialogOpen: false,
			});
		} finally {
			this.setState({ isSavingData: false });
		}
	};

	private validateSave = async () => {
		if (!this.state.newGroupName || !this.state.selectedGroupId) {
			notificationService.showWarningMessage(localized('InputFieldsNotFilledOut'));
			return false;
		}

		return true;
	};

	private validateDelete = async () => {
		if (!this.state.selectedGroupId) {
			notificationService.showWarningMessage(localized('InputFieldsNotFilledOut'));
			return false;
		}

		if (await groupsClient.hasMachines(this.state.selectedGroupId)) {
			notificationService.showWarningMessage(localized('HasMachinesWarning'));
			return false;
		}

		return true;
	};

	private getHeaderLabels = () => {
		let parentGroupTree = (
			<div className="content-container-container">
				<label>{localized('Group')}</label>
			</div>
		);

		let content = (
			<div className="flex-space-between-row content-container-groups">
				<label>{localized('groupName')}</label>
				<label className="p-button-custom p-button-secondary" style={{ opacity: 0 }}>
					{localized('Save')}
				</label>
			</div>
		);

		return (
			<BiLabelContentItem
				defaultClassNames="bi-label-content-item-lg"
				label={parentGroupTree}
				content={content}
				showBoxShadow={false}
			/>
		);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
