import { NotificationHistoryDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('machine-history');

export const GetNotificationHistoryAction = actionCreator.async<
	{ serialNumber: string; startDate: Date; endDate: Date },
	NotificationHistoryDto[]
>(constants.GET_NOTIFICATION_HISTORY);

export const UpdatePickUpAmountAction = actionCreator.async<
	{ historyId: number; unitId: string; pickUpAmount: number },
	void
>(constants.UPDATE_PICK_UP_AMOUNT);
