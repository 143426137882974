import { IFilterDto, IUnitSelectedDto } from 'api/api';
import { DialogProps } from 'primereact/dialog';
import * as React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { List as VirtualizedList } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { Dispatch } from 'redux';
import { getMachineLocationsFiltered } from 'state/ducks/location/operations';
import { getMachineCount } from 'state/ducks/machines/operations';
import { setUserSelectedMachines } from 'state/ducks/table-settings/operations';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import BiDialog from 'view/shared/components/dialogs/bi-dialog/bi-dialog';
import BiSimpleInputText from 'view/shared/components/bi-input-text/bi-simple-input-text';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { Spinner } from '../spinner/spinner';
import './units-selected.scss';
import { fuzzySearchOccurrences } from 'utilities/string-utils';

const mapStateToProps = (state: AppState) => ({
	selectedMachines: state.tableSettingsReducer.selectedMachines,
	availableMachines: state.machineSelectedReducer.machinesSelected,
	machineCount: state.machinesReducer.machineCount,
	appliedFilter: state.filterSearchReducer.appliedFilters,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setUserSelectedMachines: (machines: number[]) => setUserSelectedMachines(machines)(dispatch),
	getMachineCount: async (filter?: IFilterDto) => await getMachineCount(filter)(dispatch),
	getMachineLocationsFiltered: async (filter: IFilterDto) => await getMachineLocationsFiltered(filter)(dispatch),
});

type UnitSearchResult = {
	searchOccurrences: number;
	unit: IUnitSelectedDto;
};

interface UnitsSelectedProps extends DialogProps {}

interface State {
	selectedColumns: IUnitSelectedDto[];
	extraColumns: ExtraColumns[];
	isLoading: boolean;
	filteredAndSortedAvailableMachines: IUnitSelectedDto[];
	searchString: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & UnitsSelectedProps;

const allNoneColumns = ['all', 'none'] as const;
const extraColumns = [...allNoneColumns] as const;
type ExtraColumns = typeof extraColumns[number];

class UnitsSelected extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		if (!this.props.machineCount) {
			this.props.getMachineCount(this.props.appliedFilter);
		}

		this.state = {
			selectedColumns: this.getSelectedCheckboxesFromMachines(this.props.selectedMachines),
			extraColumns: this.getSelectedExtraCheckboxes(this.props.selectedMachines),
			isLoading: false,
			searchString: '',
			filteredAndSortedAvailableMachines: this.sortAndFilterAvailableUnits(),
		};
	}

	public sortAndFilterAvailableUnits = (
		searchString: string = this.state?.searchString ?? ''
	): IUnitSelectedDto[] => {
		searchString = searchString.toLowerCase();
		const mappedUnits = this.props.availableMachines?.map(u => this.unitsToSearchResult(searchString, u));
		let checkedUnits = this.getSelectedCheckboxesFromMachines(this.props.selectedMachines);
		let checkedArray: UnitSearchResult[] = [];
		let uncheckedArray: UnitSearchResult[] = [];

		mappedUnits?.forEach((unitSearchResult: UnitSearchResult) => {
			if (checkedUnits?.some(cu => cu.id === unitSearchResult.unit.id)) {
				checkedArray.push(unitSearchResult);
			} else if (unitSearchResult.searchOccurrences > 0) {
				uncheckedArray.push(unitSearchResult);
			}
		});

		return this.searchResultSorter(checkedArray).concat(this.searchResultSorter(uncheckedArray));
	};

	public async componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			!isEqual(prevProps.appliedFilter, this.props.appliedFilter) ||
			!isEqual(prevProps.selectedMachines, this.props.selectedMachines) ||
			!isEqual(prevProps.availableMachines, this.props.availableMachines)
		) {
			this.setState({
				...this.state,
				isLoading: true,
			});

			this.setState({
				...this.state,
				selectedColumns: this.getSelectedCheckboxesFromMachines(this.props.selectedMachines),
				extraColumns: this.getSelectedExtraCheckboxes(this.props.selectedMachines),
				filteredAndSortedAvailableMachines: this.sortAndFilterAvailableUnits(),
				isLoading: false,
			});
		}
	}

	public render() {
		const buttonCancel = (
			<BiButton
				colorTheme="org-red"
				containerTheme="slim-with-rounded-corners"
				containerClassName="margin-right-24px"
				onClick={this.handleCancel}
			>
				{localized('Cancel')}
			</BiButton>
		);
		const buttonSave = (
			<BiButton colorTheme="org-green" containerTheme="slim-with-rounded-corners" onClick={this.handleSave}>
				{localized('Save')}
			</BiButton>
		);

		const buttonContent = (
			<div className="flex-end-row margin-top-10px">
				{buttonCancel}
				{buttonSave}
			</div>
		);

		if (!this.props.availableMachines || this.state.isLoading) {
			return (
				<BiDialog {...this.props}>
					<div className="margin-top-20px">
						<Spinner shouldOverlay={false} spinnerSize="spinner-container-half-size" />
					</div>
					<div className="flex-end-row">{buttonCancel}</div>
				</BiDialog>
			);
		}

		// width and height don't matter as we overwrite them to 'auto' in class. No, <AutoSizer> is equally horrible in its own way
		const tempWidth = 900;
		const tempHeight = 600;

		return (
			<BiTextDialog title={localized('Units')} showHr={false} className="dialogConatiner" {...this.props}>
				<div className="p-grid flex-direction-column">
					<div className="p-col-12 margin-top-10px">
						<div className="flex-space-between-row flex-baseline-column">
							{this.getExtraCheckboxes()}
							<div className="search-field">
								<BiSimpleInputText
									value={this.state.searchString}
									placeholder={localized('Search')}
									onChange={this.onSearchChange}
								/>
							</div>
						</div>
						<hr className="margin-bottom-0" />
					</div>
					<VirtualizedList
						className="height-width-auto-important dialog-box-auto-height"
						rowCount={this.state.filteredAndSortedAvailableMachines.length}
						width={tempWidth}
						height={tempHeight}
						rowHeight={50}
						rowRenderer={this.rowRenderer}
						overscanRowCount={10}
						selectedColumns={this.state.selectedColumns} // Pass our selected columns to force an update upon change
					/>
					{buttonContent}
				</div>
			</BiTextDialog>
		);
	}

	public handleCancel = () => {
		this.props.onHide();
	};

	public handleSave = () => {
		const checkboxesAsMachineKeys: number[] = this.state.selectedColumns.map(col => col.id);
		this.props.setUserSelectedMachines(checkboxesAsMachineKeys);
		this.props.onHide();
	};

	public handleColumnCheckbox = (e: { checked: boolean; value: any }) => {
		let selectedColumns = [...this.state.selectedColumns];

		if (e.checked) {
			this.props.availableMachines &&
				selectedColumns.push(this.props.availableMachines.find(allC => allC.id === e.value)!);
		} else {
			selectedColumns.splice(
				selectedColumns.findIndex(c => c.id === e.value),
				1
			);
		}

		const selectedExtraColumns = this.getSelectedExtraCheckboxes(selectedColumns);

		this.setState({
			...this.state,
			selectedColumns,
			extraColumns: selectedExtraColumns,
		});
	};

	private rowRenderer = (props: import('react-virtualized').ListRowProps): React.ReactNode => {
		if (!this.state.filteredAndSortedAvailableMachines || this.state.isLoading) {
			return <span />;
		}

		return (
			<div key={props.key} style={props.style}>
				{this.getColumnAsCheckbox(this.state.filteredAndSortedAvailableMachines![props.index])}
			</div>
		);
	};

	private getColumnAsCheckbox = (col: IUnitSelectedDto) => (
		<div className="p-col-12" key={col.id}>
			<BiCheckbox
				key={col.id}
				containerClassName="flex-container padding-right-1em"
				inputId={col.id + ''}
				value={col.id}
				checked={this.state.selectedColumns.findIndex(c => c.id === col.id) > -1}
				onChange={this.handleColumnCheckbox}
				labelClassName="flex-fill-remaining-space"
				label={
					<div className="units-selected-container">
						<span className="text-bold">{col.serialNumber || ''}</span>
						<span className="white-space-pre">{col.groupName ? `: ${col.groupName} ` : ' '}</span>
						<span className="white-space-pre">{`- ${col.modelName}`}</span>
						{col.wasteTypeTranslationKey && (
							<span className="white-space-pre">
								{` - ${localizedDynamic(col.wasteTypeTranslationKey)} `}
							</span>
						)}
						{col.siteUser && (
							<span className="white-space-pre">{` - ${localizedDynamic(col.siteUser)}`}</span>
						)}
						{col.siteAddress && (
							<span className="white-space-pre">{` - ${localizedDynamic(col.siteAddress)}`}</span>
						)}
						{col.siteCity && (
							<span className="white-space-pre">{` - ${localizedDynamic(col.siteCity)}`}</span>
						)}
						{col.sitePostalCode && (
							<span className="white-space-pre">{` - ${localizedDynamic(col.sitePostalCode)}`}</span>
						)}
					</div>
				}
			/>
		</div>
	);

	private getSelectedCheckboxesFromMachines = (machineIds: number[]): IUnitSelectedDto[] => {
		if (this.props.availableMachines) {
			const sm: IUnitSelectedDto[] = [];

			machineIds.forEach(mi => {
				if (this.props.availableMachines) {
					const res = this.props.availableMachines.find(am => am.id === mi);

					res && sm.push(res);
				}
			});

			return sm;
		} else {
			return [];
		}
	};

	private getExtraCheckboxes = () => {
		return (
			<div className="flex-direction-row">
				<BiCheckbox
					containerClassName="padding-right-2"
					checked={this.state.extraColumns.findIndex(c => c === 'all') > -1}
					onChange={this.handleSelectAllCheckboxes}
					label={localized('All')}
				/>
				<BiCheckbox
					checked={this.state.extraColumns.findIndex(c => c === 'none') > -1}
					onChange={this.handleSelectNoCheckboxes}
					label={localized('None')}
				/>
			</div>
		);
	};

	private getSelectedExtraCheckboxes = <T extends {}>(selectedColumns: T[]): ExtraColumns[] => {
		return this.props.availableMachines && selectedColumns.length === this.props.availableMachines.length
			? ['all']
			: selectedColumns.length === 0
			? ['none']
			: [];
	};

	private handleSelectAllCheckboxes = () => {
		this.setState({
			...this.state,
			selectedColumns: this.props.availableMachines || [],
			extraColumns: ['all'],
		});
	};

	private getUnitSearchString = (unit: IUnitSelectedDto): string => {
		let searchStr: string = '';
		unit.serialNumber && (searchStr += ` ${unit.serialNumber}`);
		unit.groupName && (searchStr += ` ${unit.groupName}`);
		unit.modelName && (searchStr += ` ${unit.modelName}`);
		unit.wasteTypeTranslationKey && (searchStr += ` ${localizedDynamic(unit.wasteTypeTranslationKey)}`);
		unit.siteUser && (searchStr += ` ${unit.siteUser}`);
		unit.siteAddress && (searchStr += ` ${unit.siteAddress}`);
		unit.siteCity && (searchStr += ` ${unit.siteCity}`);
		unit.sitePostalCode && (searchStr += ` ${unit.sitePostalCode}`);
		return searchStr;
	};

	private unitsToSearchResult = (searchString: string, unit: IUnitSelectedDto): UnitSearchResult => {
		const unitString = this.getUnitSearchString(unit).toLowerCase();
		const occurrences: number = fuzzySearchOccurrences(searchString, unitString);

		return {
			unit: unit,
			searchOccurrences: occurrences,
		};
	};

	private searchResultSorter = (arr: UnitSearchResult[]): IUnitSelectedDto[] => {
		return arr.sort((a, b) => (a.searchOccurrences > b.searchOccurrences ? -1 : 1)).map(u => u.unit);
	};

	private onSearchChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const searchString = e.currentTarget.value ?? '';
		this.setState({
			...this.state,
			searchString: searchString,
			// String needs to be parsed here, since state.searchString is updated later, and needed in sortAndFilterAvailableUnits()
			filteredAndSortedAvailableMachines: this.sortAndFilterAvailableUnits(searchString),
		});
	};

	private handleSelectNoCheckboxes = () => {
		this.setState({
			...this.state,
			selectedColumns: [],
			extraColumns: ['none'],
		});
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitsSelected);
