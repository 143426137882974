import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUnitsDto, IUnitStateHistoryDto } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import { getIcon, getWifiStrengthIcon } from 'view/shared/utils/icon-helper';

export function getNoDataMessage() {
	return '';
}

export const getFormattedConnectionStatusAsSignalStrength = (machine: IUnitsDto | IUnitStateHistoryDto) => {
	if (machine.hasModem) {
		switch (machine.isOnline) {
			case false:
				return (
					<BiTooltip overlay={localized('Tooltip_MachineOffline')}>{getIcon('rssi-wifi-slash')}</BiTooltip>
				);
			default:
				return (
					<BiTooltip overlay={`${localized('Tooltip_MachineOnline')} (${machine.signalStrength} %)`}>
						{getWifiStrengthIcon(machine.signalStrength)}
					</BiTooltip>
				);
		}
	} else {
		return (
			<BiTooltip overlay={localized('Tooltip_MachineHasNoSimCard')}>
				<FontAwesomeIcon icon={faBan} />
			</BiTooltip>
		);
	}
};
