import * as React from "react";
import { requestAuthCode } from "utilities/auth-helper";
import "./sign-in-redirect.scss";
import { Spinner } from "view/components/spinner/spinner";
import { localized } from 'state/i18n';
import BiTitle from 'view/shared/components/bi-title/bi-title';

export default class LoginRedirectPage extends React.PureComponent<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};

    this.signIn();
  }

  public render() {
    return (
      <BiTitle title={localized("Title_RedirectToSignIn")}>
        <Spinner />
      </BiTitle>
    )
  }

  private signIn() {
    requestAuthCode();
  }
}
