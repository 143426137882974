import { IProfileDto, ProfileClient, ProfileDto } from 'api/api';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { decodeToken } from 'utilities/auth-helper';
import { getAccessTokenFromLocalStorage } from 'utilities/token-util';
import { profileClient } from '../api-clients';
import { getProfileAction, updateProfileAction } from './actions';

export async function getProfile() {
	return async (dispatch: Dispatch<Action<{}>>) => {
		let accessTokenProfile = getProfileFromLocalStorage();
		dispatch(getProfileAction({ profile: accessTokenProfile }));
	};
}
export async function updateProfile(userProfile: IProfileDto) {
	return AsyncOperationBuilder(
		updateProfileAction,
		apiClient => (apiClient as ProfileClient).updateProfile(userProfile as ProfileDto),
		userProfile,
		profileClient
	);
}

function getProfileFromLocalStorage(): IProfileDto {
	let accessToken = getAccessTokenFromLocalStorage();
	let decodedToken = decodeToken(accessToken);

	let accessTokenProfile = {} as IProfileDto;
	accessTokenProfile.firstName = decodedToken['FirstName'];
	accessTokenProfile.lastName = decodedToken['LastName'];
	accessTokenProfile.email = decodedToken['Email'];
	accessTokenProfile.company = decodedToken['Company'];
	accessTokenProfile.jobtitle = decodedToken['Jobtitle'];
	accessTokenProfile.phoneNumber = decodedToken['Phone'];
	return accessTokenProfile;
}
