import { LocationTileDto } from 'api/api';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from 'state/ducks/routes';
import { getStaticTileMap } from 'view/components/static-map/static-tile-map';
import '../dashboard-tile.scss';
import { DashboardTileIdQueryParam } from 'utilities/constants';

interface PropsFromParent {
	tile: LocationTileDto;
	clickable: boolean;
}

type Props = PropsFromParent;

type State = {};

class LocationTile extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {};
	}
	
	public render() {
		return (
			<>
			<NavLink onClick={this.handleClick} to={`${Routes.Location}?${DashboardTileIdQueryParam}=${this.props.tile.dashboardBase?.id}` }>
				<div className="width-100-percent align-center">{this.renderStaticMap()}</div>
			</NavLink>
			</>
		);
	}
	private handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (!this.props.clickable)
			event.preventDefault();
	}

	private renderStaticMap = () => {
		if (this.props.tile.locationTileSettings.mapCenter && this.props.tile.staticMapMachines)
			return getStaticTileMap(
				this.props.tile.locationTileSettings.mapCenter,
				this.props.tile.locationTileSettings.zoomLevel,
				this.props.tile.staticMapMachines
			);
	};
}

export default LocationTile;
