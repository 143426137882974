import { INotificationCustomMessage, LinkErrorEnum } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import BiTextArea from 'view/components/bi-text/bi-textarea';

type Props = {
	msg: INotificationCustomMessage;
	onTextChange: (msg: INotificationCustomMessage) => void;
};

export class CustomMessageDialogItem extends React.PureComponent<Props> {
	onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.props.onTextChange({ ...this.props.msg, message: event.target.value });
	};

	render() {
		return (
			<>
				<h4>{LinkErrorEnum[this.props.msg.linkError].replace(/_/g, " ").split(" ").slice(1).join(' ')}</h4>
				<BiTextArea
					placeholder={localized('InsertCustomMessage')}
					value={this.props.msg.message}
					onChange={this.onTextChange}
				/>
			</>
		);
	}
}
