import React, { FC, useEffect, useState } from 'react';

type Props = {
	serviceToolCode?: string;
};

export const ServiceToolCode: FC<Props> = React.memo(({ serviceToolCode }) => {
	const [serviceCodeList, setServiceCodeList] = useState<string[]>([]);

	useEffect(() => {
		if (serviceToolCode) {
			let firstFiveChars = serviceToolCode.substring(0, 5);
			let secondFiveChars = serviceToolCode.substring(5, 10);
			let thirdFiveChars = serviceToolCode.substring(10, 15);
			let fourthFiveChars = serviceToolCode.substring(15);
			setServiceCodeList([firstFiveChars, secondFiveChars, thirdFiveChars, fourthFiveChars]);
		}
	}, [serviceToolCode]);

	return (
		<div>
			{serviceToolCode ? (
				<div>
					{serviceCodeList.map((s, i) => (
						<>
							<span className="font-size-big">{s}</span>
							{i < serviceCodeList.length - 1 && <span className="font-size-big">-</span>}
						</>
					))}
				</div>
			) : null}
		</div>
	);
});
