import { Action } from 'redux';
import { PartnerAreaState } from './types';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { FileDirectoryTypeEnum, IFileDirectoryDto } from 'api/api';

const initialState: PartnerAreaState = {
	fileTree: undefined,
	loading: false,
	wholeFileTreeLoaded: false,
	previewFiles: [],
};

export const partnerAreaReducer = (state: PartnerAreaState = initialState, action: Action): PartnerAreaState => {
	if (
		isType(action, actions.getPartnerAreaFileTreeAction.started) ||
		isType(action, actions.getPartnerAreaFileTreeByPathAction.started) ||
		isType(action, actions.getPartnerAreaRootFolderAction.started)
	) {
		return {
			...state,
			loading: true,
		};
	}

	if (
		isType(action, actions.getPartnerAreaFileTreeAction.failed) ||
		isType(action, actions.getPartnerAreaFileTreeByPathAction.failed) ||
		isType(action, actions.getPartnerAreaRootFolderAction.failed)
	) {
		return {
			...state,
			loading: false,
		};
	}

	if (isType(action, actions.getPartnerAreaFileTreeAction.done)) {
		return {
			...state,
			loading: false,
			fileTree: action.payload.result,
			wholeFileTreeLoaded: true,
		};
	}

	if (isType(action, actions.getPartnerAreaRootFolderAction.done)) {
		return {
			...state,
			loading: false,
			fileTree: action.payload.result,
		};
	}

	if (isType(action, actions.getPartnerAreaFileTreeByPathAction.done)) {
		return {
			...state,
			loading: false,
			fileTree: addToTree(state.fileTree, action.payload.result, action.payload.params),
		};
	}

	if (
		isType(action, actions.deletePartnerAreaFileAction.done) ||
		isType(action, actions.deletePartnerAreaFolderAction.done)
	) {
		return {
			...state,
			fileTree: removeFromTree(state.fileTree, action.payload.params),
		};
	}
	if (isType(action, actions.getPartnerAreaPreviewFileAction.started)) {
		if (state.previewFiles.some(preview => preview.filePath === action.payload)) return state;
		return {
			...state,
			previewFiles: [...state.previewFiles, { filePath: action.payload, isLoading: true, fileResponse: null }],
		};
	}
	if (isType(action, actions.getPartnerAreaPreviewFileAction.done)) {
		return {
			...state,
			previewFiles: [
				...state.previewFiles.filter(preview => preview.filePath !== action.payload.params),
				{ filePath: action.payload.params, isLoading: false, fileResponse: action.payload.result },
			],
		};
	}

	return state;
};

const removeFromTree = (
	stateTree: IFileDirectoryDto | undefined,
	filterOutPath: string
): IFileDirectoryDto | undefined => {
	if (stateTree === undefined) return undefined;

	let parentPath = filterOutPath.substring(0, filterOutPath.lastIndexOf('/'));

	let tree = findFolder(stateTree, parentPath);
	if (!tree) return;

	let index = tree.fileDirectoryDtos?.findIndex(dto => dto.fullPath === filterOutPath);
	if (index === -1) return;

	tree.fileDirectoryDtos = tree.fileDirectoryDtos?.filter(dto => dto.fullPath !== filterOutPath);

	return { ...stateTree };
};

export const findFolder = (tree: IFileDirectoryDto | undefined, addToPath: string) => {
	if (tree === undefined) return undefined;

	let pathParts = addToPath.split('/');
	let path = '';
	let subTree = tree;

	pathParts.forEach((p, index) => {
		if (p === '') return;

		path += `/${p}`;

		// Ignore root path
		if (index == '0') return;

		var res = subTree.fileDirectoryDtos?.find(
			dto => dto.fullPath == path && dto.fileDirectoryType === FileDirectoryTypeEnum.Folder
		);
		if (res) subTree = res;
	});

	return subTree;
};

const addToTree = (
	stateTree: IFileDirectoryDto | undefined,
	inputTree: IFileDirectoryDto | undefined,
	addToPath: string
): IFileDirectoryDto | undefined => {
	if (inputTree === undefined) return undefined;
	if (stateTree === undefined) return undefined;

	const stateTreeCopy = { ...stateTree };

	let tree = findFolder(stateTree, addToPath);
	if (!tree) return;

	tree.fileDirectoryDtos = inputTree.fileDirectoryDtos;

	return stateTreeCopy;
};
