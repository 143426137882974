import { FavoriteDevicesClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { favoriteDevicesClient } from '../api-clients';
import { createFavouriteAction, deleteFavouriteAction } from './actions';

export async function createFavourite(unitId: number) {
	return AsyncOperationBuilder(
		createFavouriteAction,
		apiClient => (apiClient as FavoriteDevicesClient).createFavoriteDevice(unitId),
		{ unitId },
		favoriteDevicesClient
	);
}

export async function deleteFavourite(unitId: number) {
	return AsyncOperationBuilder(
		deleteFavouriteAction,
		apiClient => (apiClient as FavoriteDevicesClient).deleteFavoriteDevice(unitId),
		{ unitId },
		favoriteDevicesClient
	);
}