import { TableSettings } from 'api/api';
import { Action } from 'redux';
import { localizedDynamic } from 'state/i18n';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { allColumns, HistoryTableSettingsState } from './types';

export const initialState: HistoryTableSettingsState = {
	columnOrder: [
		'connectionStatusAsSignalStrength',
		'updatedDateTuple',
		'fillLevel',
		'balesOnSite',
		'unitsEmptied',
		'totalRunningHours',
		'totalNumberOfCycles',
		'cyclesSinceLastOutput',
		'pressSeries',
		'pressSeriesSinceLastOutput',
		'weightLastOutput',
		'errors',
		'userDefinedWeight',
		'userDefinedComment',
		'editHistoryDto',
	],
	columnWidths: [
		{ columnName: 'connectionStatusAsSignalStrength', width: 155 },
		{ columnName: 'updatedDateTuple', width: 141 },
		{ columnName: 'fillLevel', width: 130 },
		{ columnName: 'balesOnSite', width: 130 },
		{ columnName: 'unitsEmptied', width: 110 },
		{ columnName: 'totalRunningHours', width: 167 },
		{ columnName: 'totalNumberOfCycles', width: 182 },
		{ columnName: 'cyclesSinceLastOutput', width: 190 },
		{ columnName: 'pressSeries', width: 182 },
		{ columnName: 'pressSeriesSinceLastOutput', width: 190 },
		{ columnName: 'weightLastOutput', width: 170 },
		{ columnName: 'errors', width: 115 },
		{ columnName: 'userDefinedWeight', width: 130 },
		{ columnName: 'userDefinedComment', width: 200 },
		{ columnName: 'editHistoryDto', width: 80 },
	],
	sorting: [
		{
			columnName: 'updatedDateTuple',
			direction: 'desc',
		},
	],
	columns: allColumns,
	tableSettings: new TableSettings(),
	loaded: false,
};

export function historyTableSettingsReducer(
	state: typeof initialState = initialState,
	action: Action
): typeof initialState {
	if (isType(action, actions.setColumnOrderAction)) {
		return {
			...state,
			columnOrder: action.payload,
		};
	}
	if (isType(action, actions.setColumnSizeAction)) {
		return {
			...state,
			columnWidths: action.payload,
		};
	}
	if (isType(action, actions.setColumnSortingAction)) {
		return {
			...state,
			sorting: action.payload,
		};
	}
	if (isType(action, actions.getTableSettingsAction.done)) {
		if (action.payload.result) {
			const settings = JSON.parse(action.payload.result.settingsJson!) as HistoryTableSettingsState;

			const columnsInOrderFromApi = settings.columns.map(column => ({
				name: column.name,
				title: state.columns.find(c => c.name === column.name)?.title || localizedDynamic(column.name),
			}));

			state = {
				...state,
				columnOrder: settings.columnOrder,
				columnWidths: settings.columnWidths,
				columns: columnsInOrderFromApi,
				sorting: settings.sorting,
				tableSettings: action.payload.result,
			};
		}

		return {
			...state,
			loaded: true,
		};
	}
	if (isType(action, actions.saveTableSettingsAction.done)) {
		if (action.payload.result) {
			return {
				...state,
				tableSettings: action.payload.result,
			};
		}
	}

	return state;
}
