import React, { PureComponent } from 'react';
import { INotificationDefinitionDto, NotificationMachineType } from 'api/api';
import { classNameCardContainer } from './notifications-list';
import Card from 'view/shared/components/cards/white-card';
import { localizedDynamic } from 'state/i18n';
import DeleteNotificationDefinitionDialog from './delete-definition-dialog';
import { getDateToString } from 'view/shared/components/date-to-string';
import { localizeNotificationTypeName } from 'utilities/notification-util';

interface Props {
	def: INotificationDefinitionDto;
	buttonsGenerator: (
		def: INotificationDefinitionDto,
		handleEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
		handleDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	) => JSX.Element;
	handleEdit: (def: INotificationDefinitionDto) => void;
	handleDelete: (def: INotificationDefinitionDto) => void;
}

interface State {
	showDeleteDialog: boolean;
}

class NotificationAccordionHeader extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { showDeleteDialog: false };
	}

	handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		this.props.handleEdit(this.props.def);
	};

	buttonHandleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		this.setState({ showDeleteDialog: true });
	};

	dialogHandleDelete = () => {
		this.props.handleDelete(this.props.def);
	};

	hideDialog = () => {
		this.setState({ showDeleteDialog: false });
	};

	getControllerTypes = (): string[] => {
		let controllerTypes: string[] = [];
		if (this.props.def.controllerTypes?.balers && this.props.def.controllerTypes.balers.length > 0)
			controllerTypes.push(
				localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Baler]}`)
			);

		if (this.props.def.controllerTypes?.compactors && this.props.def.controllerTypes.compactors.length > 0)
			controllerTypes.push(
				localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Compactor]}`)
			);

		if (this.props.def.controllerTypes?.link && this.props.def.controllerTypes.link.length > 0)
			controllerTypes.push(
				localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Link]}`)
			);

		if (this.props.def.controllerTypes?.shark && this.props.def.controllerTypes.shark.length > 0)
			controllerTypes.push(
				localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.SharkMK3]}`)
			);

		if (this.props.def.controllerTypes?.containers && this.props.def.controllerTypes.containers.length > 0)
			controllerTypes.push(
				localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Container]}`)
			);

		return controllerTypes;
	};

	render() {
		let controllerTypes: string[] = this.getControllerTypes();
		let notifications: string[] | undefined = this.props.def.notificationTypes?.map(localizeNotificationTypeName);
		let Users: (string | undefined)[] | undefined = this.props.def.notificationUsers?.map(user => user.email);

		return (
			<Card classNameContainer={classNameCardContainer}>
				<div className="accordion-grid">
					<div className="single-line-ellipsis">{this.props.def.name}</div>
					<div className="mini-grid">
						<div className="single-line-ellipsis">{controllerTypes?.join(', ')}</div>
						<div className="single-line-ellipsis">{notifications?.join(', ')}</div>
					</div>
					<div className="single-line-ellipsis">{Users?.join(', ')}</div>
					<div className="single-line-ellipsis">{this.props.def.createdBy}</div>
					<div className="single-line-ellipsis">{getDateToString(this.props.def.modifiedOn)}</div>
					<div className="single-line-ellipsis">{this.props.def.modifiedBy}</div>
					{this.props.buttonsGenerator(this.props.def, this.handleEdit, this.buttonHandleDelete)}
				</div>
				<DeleteNotificationDefinitionDialog
					onDelete={this.dialogHandleDelete}
					onCancel={this.hideDialog}
					showDialog={this.state.showDeleteDialog}
				/>
			</Card>
		);
	}
}

export default NotificationAccordionHeader;
