import { Dispatch } from 'redux';
import { SortingGeneric, TableColumnWidthInfoGeneric } from '../table-settings/types';
import { setColumnOrderAction, setColumnSizeAction, setColumnSortingAction } from './actions';
import { UnitCycleDtoDtoKeys } from './types';

export function setSorting(sorting: SortingGeneric<UnitCycleDtoDtoKeys>[]) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setColumnSortingAction(sorting));
	};
}

export function setSize(columnWidths: TableColumnWidthInfoGeneric<UnitCycleDtoDtoKeys>[]) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setColumnSizeAction(columnWidths));
	};
}

export function setOrder(columnOrder: UnitCycleDtoDtoKeys[]) {
	return (dispatch: Dispatch<any>) => {
		dispatch(setColumnOrderAction(columnOrder));
	};
}
