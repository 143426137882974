import { FileDirectoryDto, FileResponse } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('partner-area');

export const getPartnerAreaFileTreeByPathAction = actionCreator.async<string, FileDirectoryDto>(
	constants.GET_PARTNER_AREA_FILE_TREE_BY_PATH
);
export const getPartnerAreaRootFolderAction = actionCreator.async<undefined, FileDirectoryDto>(
	constants.GET_PARTNER_AREA_ROOT_FOLDER
);
export const getPartnerAreaFileTreeAction = actionCreator.async<undefined, FileDirectoryDto>(
	constants.GET_PARTNER_AREA_FILE_TREE
);
export const getPartnerAreaPreviewFileAction = actionCreator.async<string, FileResponse | null>(
	constants.GET_PARTNER_AREA_PREVIEW_FILE
);
export const deletePartnerAreaFileAction = actionCreator.async<string, void>(constants.DELETE_PARTNER_AREA_FILE);
export const deletePartnerAreaFolderAction = actionCreator.async<string, void>(constants.DELETE_PARTNER_AREA_FOLDER);
