// TODO: Replace component with general version of bi-table (i.e. implement a general version). Like, bi-table, history-table and now this one too??
import { SortingState, Column, IntegratedSorting, GridColumnExtension } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, VirtualTable, TableFixedColumns } from '@devexpress/dx-react-grid-bootstrap4';
import { AppState } from 'state/store';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BiNoData from '../../bi-table/bi-no-data/bi-no-data';
import { DeviceStateHistoriesDtoKeys } from 'state/ducks/reports/types';
import './bi-report-table.scss';
import { SortingGeneric } from 'state/ducks/history-table-settings/types';
import { PropNameUnitId } from 'state/ducks/reports/constants';
import { TableId } from 'api/api';
import { ROW_HEIGHT } from '../../bi-table/bi-table';

const mapStateToProps = (state: AppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

type dataType = Array<Record<typeof PropNameUnitId, string>>;
type dataElementType = dataType[0];

interface PropsFromParent {
	columns: Array<Column & GridColumnExtension>;
	data?: dataType;
	dataIdProp: keyof dataElementType;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

interface State {
	fixedLeftColumns: DeviceStateHistoriesDtoKeys[];
	fixedRightColumns: DeviceStateHistoriesDtoKeys[];
}

const getRowId = (row: any, idProp: keyof dataElementType) => {
	return row[idProp];
};

const TableComponent = ({ ...restProps }) => <VirtualTable.Table {...restProps} className="bi-report-table-striped" />;

const HeaderComponent = ({ ...restProps }) => <VirtualTable.TableHead {...restProps} className="report-table-header" />;

const CellConponent = (obj: any) => <VirtualTable.Cell {...obj} value={obj.value ? obj.value : 0} />;

const rootStyle: React.CSSProperties = { height: '100%', width: '100%' };

const Root = (props: Grid.RootProps) => <Grid.Root {...props} style={rootStyle} />;

const defaultSorting: Array<SortingGeneric<DeviceStateHistoriesDtoKeys>> = [
	{
		columnName: 'unitId',
		direction: 'asc',
	},
];
function compareAsNumbersIfPossible(a: any, b: any): number {
	const aAsNumber = Number(a);
	const bAsNumber = Number(b);

	if (!isNaN(aAsNumber) && !isNaN(bAsNumber)) {
		return aAsNumber < bAsNumber ? -1 : 1;
	}

	return `${a}` < `${b}` ? -1 : 1;
}

let columnSortingExtensions: IntegratedSorting.ColumnExtension[] = [];

class BiReportTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			fixedLeftColumns: ['unitId', 'unitType', 'siteUser'],
			fixedRightColumns: ['Total'],
		};
	}

	public render() {
		// TODO: Currently only way to specify a global compare function because we use DevExtreme (newer but less features)
		// Relevant link (tl;dr: not possible YET): https://community.devexpress.com/blogs/oliver/archive/2018/04/20/devextreme-new-react-wrappers-vs-native-react-components.aspx
		columnSortingExtensions = this.props.columns.map(curr => ({
			columnName: curr.name,
			compare: compareAsNumbersIfPossible,
		}));

		const content = (
			<Grid
				rows={this.props.data || []}
				columns={this.props.columns}
				getRowId={this.getRowIdWrapper}
				rootComponent={Root}
			>
				<SortingState defaultSorting={defaultSorting} />
				<IntegratedSorting columnExtensions={columnSortingExtensions} />
				<VirtualTable
					tableComponent={TableComponent}
					headComponent={HeaderComponent}
					cellComponent={CellConponent}
					columnExtensions={this.props.columns}
					height="auto"
					noDataCellComponent={this.NoDataCellComponent}
					estimatedRowHeight={ROW_HEIGHT}
				/>
				<TableHeaderRow showSortingControls={true} />

				<TableFixedColumns
					leftColumns={this.state.fixedLeftColumns}
					rightColumns={this.state.fixedRightColumns}
				/>
			</Grid>
		);
		return content;
	}

	private NoDataCellComponent = (restProps: Table.NoDataCellProps): JSX.Element => (
		<BiNoData noDataCellProps={restProps} tableId={TableId.Reports} />
	);

	private getRowIdWrapper = (row: any) => getRowId(row, this.props.dataIdProp);
}

export default connect(mapStateToProps, mapDispatchToProps)(BiReportTable);
