import { FilterDto, ICoordinateDto, IUnitWithLocationDto, IMapBounds } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants, MapTypeId } from './constants';

const actionCreator = actionCreatorFactory('location');

export const getMachineLocationsAction = actionCreator.async<{ callCounter: number, filter: number[] }, IUnitWithLocationDto[] | undefined>(
	constants.GET_MACHINE_LOCATIONS
);

export const getMachineLocationsFilteredAction = actionCreator.async<{ callCounter: number, filter: FilterDto }, IUnitWithLocationDto[] | undefined>(
	constants.GET_MACHINE_LOCATIONS_FILTERED
);

export const setActivelySelectedMachineAction = actionCreator<IUnitWithLocationDto | undefined>(
	constants.SET_ACTIVE_MACHINE
);
export const setMapBoundsAction = actionCreator<IMapBounds>(
	constants.SET_MAP_BOUNDS
);

export const setMapZoomLevelAction = actionCreator<number>(
	constants.SET_MAP_ZOOMLEVEL
);

export const setMapCenterAction = actionCreator<ICoordinateDto>(
	constants.SET_MAP_CENTER
);

export const setActivelySelectedMachinesAction = actionCreator<IUnitWithLocationDto[] | undefined>(
	constants.SET_ACTIVE_MACHINES
);

export const clearMachinesLocationsAction = actionCreator<void>(
	constants.CLEAR_MACHINE_LOCATIONS
);

export const setMapTypeAction = actionCreator<MapTypeId>(
	constants.SET_MAP_TYPE
);