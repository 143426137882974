import { AuthorizationNotifier, RedirectRequestHandler } from '@openid/appauth';
import { AppState } from 'state/store';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { Routes } from 'state/ducks/routes';
import history from 'state/history';
import { getAccessToken } from 'utilities/auth-helper';
import { Spinner } from 'view/components/spinner/spinner';

const mapStateToProps = (state: AppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

type Props = ReturnType<typeof mapDispatchToProps>;

class Callback extends React.PureComponent<Props> {
	private notifier: AuthorizationNotifier;
	private authorizationHandler: RedirectRequestHandler;
	private code: string | undefined;
	private recheckTimer: NodeJS.Timeout | undefined;

	constructor(props: Props) {
		super(props);

		this.notifier = new AuthorizationNotifier();
		this.authorizationHandler = new RedirectRequestHandler();

		this.init();
	}

	public componentWillUnmount() {
		if (this.recheckTimer) {
			clearTimeout(this.recheckTimer);
			this.recheckTimer = undefined;
		}
	}

	public render() {
		return <Spinner />;
	}

	private init() {
		this.authorizationHandler.setAuthorizationNotifier(this.notifier);

		// Set a listener to listen for authorization responses
		this.notifier.setAuthorizationListener((request, response, error) => {
			if (response) {
				this.code = response.code;

				getAccessToken(this.code, request);
			}
		});

		this.checkForAuthorizationResponse();

		// Some users stranded on /callback url because of timing issues with when the check was performed
		this.recheckTimer = setTimeout(() => {
			this.checkForAuthorizationResponse();
		}, 2000);
	}

	private async checkForAuthorizationResponse() {
		try {
			// Read info from fragment in url and save in localStorage
			await this.authorizationHandler.completeAuthorizationRequestIfPossible();
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);

			history.replace(Routes.SignIn);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
