import React from 'react';
import { Button } from 'primereact/button';
import { localized } from 'state/i18n';
import { DateTypes } from 'api/api';

type OnClickType = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export interface Props {
	containerClassName?: string;
	dateType: DateTypes;
	onWeekClicked: (e?: OnClickType) => void;
	onMonthClicked: (e?: OnClickType) => void;
	onYearClicked: (e?: OnClickType) => void;
}

class ReportDateTypeButtons extends React.PureComponent<Props> {
	public render() {
		return (
			<div className={`flex-direction-row ${this.props.containerClassName || ''}`}>
				<Button
					label={localized('Week')}
					onClick={this.props.onWeekClicked}
					className={
						this.props.dateType === DateTypes.Week ? 'reports-date-button active' : 'reports-date-button'
					}
				></Button>
				<Button
					label={localized('Month')}
					onClick={this.props.onMonthClicked}
					className={
						this.props.dateType === DateTypes.Month ? 'reports-date-button active' : 'reports-date-button'
					}
				></Button>
				<Button
					label={localized('Year')}
					onClick={this.props.onYearClicked}
					className={
						this.props.dateType === DateTypes.Year ? 'reports-date-button active' : 'reports-date-button'
					}
				></Button>
			</div>
		);
	}
}

export default ReportDateTypeButtons;
