import * as React from 'react';
import { getIcon } from 'view/shared/utils/icon-helper';
import BiTooltip from '../bi-tooltip/bi-tooltip';
import { localized } from 'state/i18n';
import { ErrorAndSeverity } from 'api/api';

export type IconType = "OK" | "Warning" | "Error" | "Message";
export interface JsxIconAndType {
    icon: JSX.Element;
    iconType: IconType
}

export type ErrorOrReadonlyErrorArray = ErrorAndSeverity | readonly ErrorAndSeverity[];

export const unitStatusIconOk = getIcon("check-ok");
export const unitStatusIconWarning = getIcon("triangle-warning");
export const unitStatusIconError = getIcon("cross-error");
export const unitStatusIconMessage = getIcon("envelope-message");

export const unitStatusIconOkAndType: JsxIconAndType = ({
    iconType: "OK",
    icon: unitStatusIconOk
})

export const unitStatusIconWarningAndType: JsxIconAndType = ({
    iconType: "Warning",
    icon: unitStatusIconWarning
})

export const unitStatusIconErrorAndType: JsxIconAndType = ({
    iconType: "Error",
    icon: unitStatusIconError
})

export const unitStatusIconMessageAndType: JsxIconAndType = ({
    iconType: "Message",
    icon: unitStatusIconMessage
})

export const unitStatusIconOkAndTypeWithTooltip: JsxIconAndType = ({
    iconType: unitStatusIconOkAndType.iconType,
    icon: (
        <BiTooltip overlay={localized("Tooltip_MachineOk")}>
            {unitStatusIconOkAndType.icon}
        </BiTooltip>
    )
})
