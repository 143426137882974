import { AppState } from 'state/store';
import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './edit-column.scss';
import { ColumnGeneric, MachineDtoKeys } from 'state/ducks/table-settings/types';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import { ScrollPanel } from 'primereact/scrollpanel';
import { localized, localizedDynamic } from 'state/i18n';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { setlockColumns } from 'state/ducks/table-settings/operations';
import { permanentLockedColumns } from 'state/ducks/table-settings/reducer';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';

const mapStateToProps = (state: AppState) => ({
    lockedColumns: state.tableSettingsReducer.tableSettingsList.find(ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId)?.columnsLocked,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    SetLockedColumns: (columns: MachineDtoKeys[]) => setlockColumns(columns)(dispatch),
});


interface LockColumnProps extends DialogProps {
    columns: ColumnGeneric<MachineDtoKeys>[];
    closeDialog: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & LockColumnProps;

interface LockColumnState {
    lockedColumns: MachineDtoKeys[];
}
class LockColumnsDialog extends React.PureComponent<Props, LockColumnState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            lockedColumns: this.props.lockedColumns ? [...this.props.lockedColumns] : [],
        }
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.lockedColumns !== this.props.lockedColumns && this.props.lockedColumns)
            this.setState({ lockedColumns: [...this.props.lockedColumns] })
    }

    handleColumnCheckbox = (e: { originalEvent: Event, value: any, checked: boolean, target: { type: string, name: string, id: string, value: any, checked: boolean } }) => {
        if (e.checked)
            this.setState({ lockedColumns: [...this.state.lockedColumns, e.value] });
        else
            this.setState({ lockedColumns: this.state.lockedColumns.filter(locked => locked !== e.value) });
    }

    getColumnsAsCheckboxes = (columns: ColumnGeneric<MachineDtoKeys>[]) => {
        columns = columns.sort((col1, col2) => col1.title > col2.title ? 1 : -1)
        return columns.map(col => {
            let isChecked = this.state.lockedColumns.includes(col.name);
            let isPermanentLocked: boolean = permanentLockedColumns.includes(col.name);
            return (
                <div className="p-col-12 p-md-6 p-lg-3" key={col.name}>
                    <BiCheckbox
                        containerClassName="flex-container"
                        inputId={col.name + "locked"}
                        value={col.name}
                        checked={isChecked}
                        onChange={this.handleColumnCheckbox}
                        label={localizedDynamic(col.name)}
                        disabled={isPermanentLocked}
                    />
                </div>
            );
        });
    };


    setLockedColumns = () => {
        this.props.SetLockedColumns(this.state.lockedColumns);
        this.props.closeDialog();
    }

    public render() {
        return (
            <BiTextDialog dismissableMask={false} {...this.props} className="lock-column" title={localized("LockColumns")} subtitle={localized("LockColumnsExplanaition")}>
                <div className="p-grid no-select">
                    <ScrollPanel className="p-scrollpanel-edit-column scrollpanel-container-normal scrollpanel-content-flex">
                        {this.getColumnsAsCheckboxes(this.props.columns)}
                    </ScrollPanel>
                </div>


                <div className="flex-end-row margin-top-10px">
                    <BiButton
                        colorTheme="org-red"
                        containerTheme="slim-with-rounded-corners"
                        containerClassName="margin-right-24px"
                        onClick={this.props.closeDialog}
                    >
                        {localized('Cancel')}
                    </BiButton>
                    <BiButton
                        colorTheme="org-green"
                        containerTheme="slim-with-rounded-corners"
                        onClick={this.setLockedColumns}
                    >
                        {localized('Save')}
                    </BiButton>
                </div>
            </BiTextDialog>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockColumnsDialog);
