import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import './navigation-bar.scss';
import ReleaseNote from './release-note';
import './release-note.scss';

const mapStateToProps = (state: AppState) => {
	return {
		releaseNotes: state.releaseNoteReducer.notes,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ReleaseNotes extends React.PureComponent<Props> {
	public render() {
		const notes: JSX.Element[] = this.props.releaseNotes.map((note, index) => {
			let lineBreak: JSX.Element | undefined;
			if (this.props.releaseNotes.length > 1 && index < this.props.releaseNotes.length - 1) lineBreak = <hr />;

			return (
				<div key={`releaseNote:${note.id}`}>
					<ReleaseNote releaseNoteId={note.id} />
					{lineBreak}
				</div>
			);
		});

		return <div className="releasenote-wrapper no-select">{notes.length ? notes : localized('NoData')}</div>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
