import { FileResponse } from 'api/api';
import React, { useEffect, useState } from 'react';
import './image-base64.scss';

export const ImageBase64: React.FC<{
	image: FileResponse | null | undefined;
	className?: string;
	imgClassName?: string;
	alt?: string;
}> = props => {
	const [image, setImage] = useState<JSX.Element | null>(null);

	useEffect(() => {
		if (props.image) {
			let reader = new FileReader();
			reader.readAsDataURL(props.image.data);
			reader.onloadend = () => {
				const base64data = reader.result;
				const img = (
					<div className={`image-base64 ${props.className ?? ''}`}>
						<img src={base64data?.toString()} className={`${props.imgClassName ?? ''}`} alt={props.alt} />
					</div>
				);

				setImage(img);
			};
		}
	}, [props.alt, props.className, props.image, props.imgClassName]);

	return image;
};
