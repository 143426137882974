import { DateTypes, ICreateReportTileSettingsDto, IReportSingleUnitDto, IReportTileDto } from './../../../api/api';
import { ReportTypes } from './../../../view/components/table/reports/reports';
import { Column, GridColumnExtension } from '@devexpress/dx-react-grid';
import { DataTypes, IGraphReportDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('reports');

export const GetTableReportAction = actionCreator<{
	cols: (Column & GridColumnExtension)[];
	rows: Record<string, string>[];
}>(constants.GET_TABLE_REPORT);
export const GetGraphReportAction = actionCreator<IGraphReportDto>(constants.GET_GRAPH_REPORT);
export const ClearReportsAction = actionCreator(constants.CLEAR_REPORTS);
export const GetReportDataAction = actionCreator.async<
	{ dataTypes: DataTypes; callCounter: number },
	IReportSingleUnitDto[]
>(constants.GET_REPORT_DATA);
export const SetReportsLoadingStateAction = actionCreator<boolean>(constants.SET_REPORTS_LOADING_STATE);
export const SetDataTypeAction = actionCreator<DataTypes | undefined>(constants.RESET_DATATYPE);

export const SetStartDateAction = actionCreator<Date>(constants.SET_START_DATE);
export const SetEndDateAction = actionCreator<Date>(constants.SET_END_DATE);
export const SetReportTypeAction = actionCreator<ReportTypes>(constants.SET_REPORT_TYPE);
export const SetDateTypeAction = actionCreator<DateTypes>(constants.SET_DATETYPE);
export const GetReportTilePreviewAction = actionCreator.async<
	{ callCounter: number; createReportTileSettingsDto: ICreateReportTileSettingsDto },
	IReportTileDto
>(constants.GET_REPORT_TILE_PREVIEW);
export const SetReportTileDateTypeAction = actionCreator<DateTypes>(constants.SET_REPORT_TILE_DATETYPE);
export const ClearReportTilePreviewAction = actionCreator(constants.CLEAR_REPORT_TILE_PREVIEW);
