import { constants } from './constants';
import { DashboardBaseDto, DashboardBase, IDashboardBase, ICreateReportTileSettingsDto, ILocationTileSettingsDto, DateTypes } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('dashboard');

export const GetDashboardContentAction = actionCreator.async<{ callCounter: number }, DashboardBaseDto[]>(constants.GetDashboardContent);
export const GetSingleDashboardTileAction = actionCreator.async<{ id: number }, DashboardBaseDto>(constants.GetSingleDashboardTile);
export const CreateDashboardContentAction = actionCreator.async<{ callCounter: number, dashboardBases: IDashboardBase[] }, DashboardBaseDto[]>(constants.CreateDashboardContent);
export const UpdateDashboardContentAction = actionCreator.async<{ callCounter: number, dashboardBases: IDashboardBase[] }, DashboardBase[]>(constants.UpdateDashboardContent);
export const DeleteDashboardContentAction = actionCreator.async<{ callCounter: number, dashboardBase: IDashboardBase }, void>(constants.DeleteDashboardContent);
export const SetMenuToShowIdAction = actionCreator<{ id: number }>(constants.SetMenuId);
export const CreateReportTileAction = actionCreator.async<{ callCounter: number, createReportTileSettingsDto: ICreateReportTileSettingsDto }, DashboardBaseDto>(constants.CREATE_REPORT_TILE);
export const CreateGoogleMapsLocationAction = actionCreator.async<{ callCounter: number, locationTileSettingsDto: ILocationTileSettingsDto }, DashboardBaseDto>(
	constants.CREATE_STATIC_MAP_LOCATION
);
export const SetPickupsDateTypeAction = actionCreator<{ dateType: DateTypes }>(constants.SET_PICKUPS_DATE_TYPE);