import React from 'react';
import './notification-utility.scss';

type Props = {
	children?: React.ReactNode | React.ReactNode[];
};

const NotificationUtility: React.FC = (props: Props) => {
	const { children } = props;

	return <div className="notification-utility">{children}</div>;
};

export default NotificationUtility;
