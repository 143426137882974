import React from 'react';
import { StaticMapsImageUrl, StaticMapsKey } from 'utilities/constants';
import { getMarkerByFillLevel } from 'utilities/location-helper';
import './static-map.scss';

export const getStaticMap = (
	gpsLocation?: { latitude: string | undefined; longitude: string | undefined },
	fillLevel?: number,
	width?: string,
	height?: string,
	hasModem?: boolean,
	isOnline?: boolean
) => {
	const latitude = gpsLocation && gpsLocation.latitude;
	const longitude = gpsLocation && gpsLocation.longitude;
	const src = `https://maps.googleapis.com/maps/api/staticmap?markers=scale:4|icon:${encodeURI(
		StaticMapsImageUrl + getMarkerByFillLevel(fillLevel, hasModem, isOnline)
	)}|${latitude || '55.4683887'},${longitude || '8.6662475'}&scale=2&size=${width || '640'}x${
		height || '640'
	}&zoom=14&key=${StaticMapsKey || 'AIzaSyCb7Y0kpBBl0Ttu3SWStdEAkPFWnruY1kY'}`;

	return <img className="static-map" width="100%" style={{ maxHeight: height }} src={src} alt={'Machine Location'} />;
};
