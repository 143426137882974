import { INotificationColumnSelected, INotificationDefinitionDto, INotificationTypeDto } from 'api/api';
import { IsBrandBramidan } from 'utilities/brand-utils';
import { ColumnGeneric, MachineDtoKeys, basicColumns } from '../table-settings/types';

export interface NotificationsState {
	notificationTypes: INotificationTypeDto[];
	notificationDefinitions: INotificationDefinitionDto[];
	temporaryNotificationDefinition?: INotificationDefinitionDto;
	filteredOut: number;
	loading: boolean;
	callCounter: number;
	users: string[];
}

export type NotificationColumnKeys = MachineDtoKeys | 'newLine1' | 'newLine2' | 'newLine3';

const defaultNotificationColumnNames: string[] = IsBrandBramidan
	? // Bramidan
	  ['unitId', 'customerUnitId', 'fillLevel', 'wasteTypeTranslationKey', 'siteUser', 'siteAddress', 'siteCity']
	: // Presto
	  [
			'unitId',
			'fillLevel',
			'wasteTypeTranslationKey',
			'unitModel',
			'pickupSystem',
			'containerSize',
			'customerUnitId',

			'newLine1',

			'siteUser',
			'siteCity',
			'siteAddress',
			'sitePostalCode',
			'siteDescription',
			'gpsLocation',

			'newLine2',

			'totalRunningHours',
			'totalNumberOfCycles',
			'cyclesSinceLastOutput',
			'averageCyclesPerUnitEmptiedThirtyDays',
	  ];

const excludedColumnNames: string[] = ['select', 'updatedDateFriendly', 'moreInfo'];

const extraColumns: ColumnGeneric<NotificationColumnKeys>[] = [
	{ name: 'newLine1', title: ' ' },
	{ name: 'newLine2', title: ' ' },
	{ name: 'newLine3', title: ' ' },
];

export const basicNotificationTableColumns: ColumnGeneric<NotificationColumnKeys>[] = extraColumns
	.concat(basicColumns)
	.filter(col => !excludedColumnNames.includes(col.name));

export const defaultNotificationTableColumns: ColumnGeneric<
	NotificationColumnKeys
>[] = defaultNotificationColumnNames
	.map(columnName => basicNotificationTableColumns.find(tableCol => tableCol.name === columnName))
	.filter(col => col !== undefined && col !== null) as ColumnGeneric<NotificationColumnKeys>[];

export const defaultNotificationColumnsSelected: INotificationColumnSelected[] = defaultNotificationTableColumns.map(
	(currElement: ColumnGeneric<NotificationColumnKeys>, index: number) => ({
		id: 0,
		sortingIndex: index,
		columnName: currElement.name,
		notificationDefinitionId: 0,
		isDeleted: false,
		createdDate: new Date(),
		updatedDate: new Date(),
	})
);
