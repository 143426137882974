import { IUnitManufacturerDto } from 'api/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { notificationService } from 'services/notification-service';
import { createUnitManufacturer, updateUnitManufacturer } from 'state/ducks/unit-manufacturer/operations';
import { localized } from 'state/i18n';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';

interface Props {
	unitManufacturer: IUnitManufacturerDto;
	deleteUnitManufacturer: (model: IUnitManufacturerDto) => void;
	clearTempRow: () => void;
}

export const UnitManufacturerField: React.FC<Props> = props => {
	const dispatch = useAppDispatch();

	const unitManufacturers = useAppSelector(state => state.unitManufacturerReducer.unitManufactures);

	const [tmpUnitManufacturer, setTempUnitManufacturer] = useState<IUnitManufacturerDto>(props.unitManufacturer);

	useEffect(() => {
		setTempUnitManufacturer({ ...props.unitManufacturer });
	}, [props.unitManufacturer]);

	const getUnitManufacturerContent = () => {
		const content = (
			<div className="content-container-unit-manufacturers">
				<InputText
					placeholder={localized('manufacturer')}
					className="input-box-width-100-bramidan"
					onBlur={handleNameChanged}
					value={tmpUnitManufacturer?.name}
					onChange={e => setTempUnitManufacturer({ ...tmpUnitManufacturer, name: e.currentTarget.value })}
				/>
				<span className='margin-left-auto'>
					{tmpUnitManufacturer.isDeletable && (
						<Button
							title={localized('Delete')}
							className={'p-button-custom bi-button-icon-only'}
							icon="pi pi-times-circle"
							onClick={() => props.deleteUnitManufacturer(props.unitManufacturer)}
						/>
					)}
					{tmpUnitManufacturer.id === 0 && (
						<BiButton
							onClick={createModel}
							colorTheme={'org-primary'}
							containerTheme={'normal-button-corners'}
						>
							{localized('Save')}
						</BiButton>
					)}
				</span>
			</div>
		);
		return content;
	};

	const handleNameChanged = async (e: React.FormEvent<HTMLInputElement>) => {
		var newModel: IUnitManufacturerDto = { ...tmpUnitManufacturer, name: e.currentTarget.value };
		await updateModel(newModel);
	};

	const isNameValid = (model: IUnitManufacturerDto): boolean => {
		if (unitManufacturers.map(um => um.name).includes(model.name)) return false;
		return true;
	};

	const updateModel = async (unitManufacturer: IUnitManufacturerDto) => {
		if (unitManufacturer.id === 0) return;

		setTempUnitManufacturer(unitManufacturer);
		await updateUnitManufacturer(unitManufacturer)(dispatch);
		notificationService.showSuccessMessage(localized('Saved'), undefined);
	};

	const createModel = async () => {
		if (!isNullOrWhitespace(tmpUnitManufacturer.name) && isNameValid(tmpUnitManufacturer)) {
			await createUnitManufacturer(tmpUnitManufacturer)(dispatch);
			notificationService.showSuccessMessage(localized('Saved'), undefined);
			props.clearTempRow();
		}
	};

	return <div className="unit-manufacturer-field">{getUnitManufacturerContent()}</div>;
};
