import { FC } from "react";
import React from "react";
import { biConvertNumberToSeparatedString } from "utilities/number-helpers";

interface Props {
    number: number;
    subtitle: string;
}

export const OneNumberTileTemplate: FC<Props> = React.memo((props: Props) => {
    return (
        <>
            <div className="border-bottom-grey margin-bottom-20px" />
            <div className="flex-direction-column flex-center-column">
                <span className="font-size-3-8em text-bolder">
                    {biConvertNumberToSeparatedString(props.number)}
                </span>
                <span className="font-size-normal">{props.subtitle}</span>
            </div>
        </>
    )
});