import { EditGroupsDebtorDto, IGroupsDto, EditDebtorDto } from 'api/api';
import { PageState, Paginator } from 'primereact/paginator';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { editGroupDebtor } from 'state/ducks/groups/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { getEmailFromToken } from 'utilities/token-util';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import DebtorItem from './debtor-item';
import { DebtorWithImage } from './debtor-with-image';
import './debtors.scss';
import { debtorClient } from 'state/ducks/api-clients';
import { InputText } from 'primereact/inputtext';
import { fuzzySearchOccurrences } from 'utilities/string-utils';

const mapStateToProps = (state: AppState) => {
	const userGroupsTuple = state.groupsReducer.adminGroups.find(g => g.id === getEmailFromToken());
	const userGroupsData = userGroupsTuple ? userGroupsTuple.data : [];

	return {
		userGroupsData,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	editGroupDebtor: async (editGroupDebtorDto: EditGroupsDebtorDto) =>
		(await editGroupDebtor(editGroupDebtorDto))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface State {
	paginatorFirst: number;
	paginatorPageCount?: number;
	paginatorCurrentPage: number;
	paginatorRows: number;
	searchBarText: string;
	filteredGroups: IGroupsDto[];
}

class Debtors extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			paginatorFirst: 0,
			paginatorRows: 11,
			paginatorCurrentPage: 0,
			searchBarText: '',
			filteredGroups: this.getFilteredGroups(''),
		};
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if (prevProps.userGroupsData !== this.props.userGroupsData) {
			this.setState({
				filteredGroups: this.getFilteredGroups(this.state.searchBarText),
			});
		}
	}

	public render() {
		const paginatedRows = this.getPaginationRows();

		return (
			<div className="margin-bottom-20px flex-container">
				<div className="bi-container flex-direction-column flex-fill-width">
					<h6 className="text-bold">{localized('FilterDebtorsByGroupOrNumber')}</h6>
					<div className="flex-start-row search-bar margin-bottom-10px">
						<InputText
							placeholder={localized('Search')}
							value={this.state.searchBarText}
							onChange={this.onSearchBarTextChanged}
						/>
					</div>

					<BiLabelContent containerClassName="flex-fill-width">
						{this.getHeaderLabels()}

						{paginatedRows}

						<div className="flex-end-row">
							<Paginator
								first={this.state.paginatorFirst}
								rows={this.state.paginatorRows}
								totalRecords={this.state.filteredGroups.length}
								onPageChange={this.onPageChangeHandler}
								template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
							/>
						</div>
					</BiLabelContent>
				</div>
			</div>
		);
	}

	private getPaginationRows = (): JSX.Element[] => {
		const slicedGroups = this.state.filteredGroups.slice(
			this.state.paginatorFirst,
			this.state.paginatorFirst + this.state.paginatorRows
		);

		return this.getRows(slicedGroups);
	};

	private onPageChangeHandler = (e: PageState) => {
		this.setState({
			paginatorFirst: e.first,
			paginatorRows: e.rows,
			paginatorCurrentPage: e.page,
			paginatorPageCount: e.pageCount,
		});
	};

	private getRows = (groups: IGroupsDto[]) => {
		return groups.map(group => {
			return <DebtorItem groupId={group.id} onSave={this.save} key={`${group.id}-debtor`} />;
		});
	};

	private save = async (
		groupId: number,
		isDistributor: boolean,
		debtorNumber?: string,
		countryCode?: string,
		newDebtor?: DebtorWithImage
	) => {
		debtorNumber = debtorNumber === '' ? undefined : debtorNumber;
		countryCode = countryCode === '' ? undefined : countryCode;
		const debtor = newDebtor?.debtorInfo ? new EditDebtorDto() : undefined;
		debtor?.init(newDebtor?.debtorInfo);

		if (debtor && newDebtor?.image) {
			debtor.imageFileName = await debtorClient.postCompanyImage(groupId, newDebtor.image);
		}

		const dto: EditGroupsDebtorDto = {
			init: EditGroupsDebtorDto.prototype.init,
			toJSON: EditGroupsDebtorDto.prototype.toJSON,
			groupId,
			debtorNumber,
			countryCode,
			isDistributor,
			debtor,
		};

		await this.props.editGroupDebtor(dto);
	};

	private getHeaderLabels = () => (
		<div className="content-container-debtors">
			<label>{localized('groupName')}</label>
			<label>{localized('DebtorNumber')}</label>
			<label>{localized('CountryCode')}</label>
			<label className="debtor-checkbox-center">{localized('distributor')}</label>
			<div />
		</div>
	);

	private getFilteredGroups = (searchString: string = this.state.searchBarText): IGroupsDto[] => {
		let userGroupsData = this.props.userGroupsData;
		if (searchString.length) {
			userGroupsData = userGroupsData.filter(group => {
				const groupString = (group.groupName ?? '') + ' ' + (group.debtorNumber ?? '');
				return fuzzySearchOccurrences(searchString.toLowerCase(), groupString.toLowerCase());
			});
		}
		userGroupsData.sort((a, b) => ((a.groupName?.trim() ?? '') > (b.groupName?.trim() ?? '') ? 1 : -1));

		return userGroupsData;
	};

	private onSearchBarTextChanged = (e: React.FormEvent<HTMLInputElement>) => {
		const newSearchString = e.currentTarget.value;

		this.setState({
			searchBarText: newSearchString,
			paginatorFirst: 0,
			paginatorCurrentPage: 0,
			filteredGroups: this.getFilteredGroups(newSearchString),
		});
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Debtors);
