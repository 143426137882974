import { localized } from 'state/i18n';
import * as colors from 'view/styles/colors';
import { AvailableElementsForStyling } from 'view/shared/components/cards/white-card';
import { ChartPoint } from 'view/shared/components/graph/graph';

export interface SubtitleData {
    color?: string;
    styling: AvailableElementsForStyling;
    level?: string;
    onlineStatus?: string;
}

export const cardGeneralClassNames: AvailableElementsForStyling = {
    classNameTitle: "",
    classNameSubtitle: "",
    classNameContainer: "margin-right-40px margin-bottom-40",
}

export const emptyColorData: SubtitleData = {
    styling: {
        ...cardGeneralClassNames,
    },
}

export const cardDeviceStyles: AvailableElementsForStyling = {
    classNameContainer: "card-height-normal",
}

export const cardGraphStyles: AvailableElementsForStyling = {
    classNameContainer: "card-height-big",
}

export const cardDetailsStyles: AvailableElementsForStyling = {
    classNameContainer: "card-width-big"
}

export const cardGreenData: SubtitleData = {
    color: colors.colorGreen,
    styling: {
        ...cardGeneralClassNames,
        classNameSubtitle: `${cardGeneralClassNames.classNameSubtitle} color-green-dark`
    },
    level: localized("Low"),
    onlineStatus: localized("Online"),
}

export const cardOrangeData: SubtitleData = {
    color: colors.colorOrange,
    styling: {
        ...cardGeneralClassNames,
        classNameSubtitle: `${cardGeneralClassNames.classNameSubtitle} color-orange-light`
    },
    level: localized("Medium"),
    onlineStatus: localized("Warning"),
}

export const cardRedData: SubtitleData = {
    color: colors.colorRed,
    styling: {
        ...cardGeneralClassNames,
        classNameSubtitle: `${cardGeneralClassNames.classNameSubtitle} color-red-dark`
    },
    level: localized("High"),
    onlineStatus: localized("Offline"),
}

export interface LabelsDataForGraphMap {
    labels: string[];
    data: number[] & ChartPoint[]
}

