import { INotificationDefinitionDto, NotificationUserDto } from 'api/api';
import React, { FC } from 'react';
import { localized } from 'state/i18n';
import './notification-subscription-list.scss';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { getIcon } from 'view/shared/utils/icon-helper';
import { useDispatch } from 'react-redux';
import { navigateToSpecificNotification } from 'state/ducks/notifications/operations';

type Props = {
	notificationDefinition: INotificationDefinitionDto;
	notificationUser: NotificationUserDto;
};

export const NotificationSubscriptionRows: FC<Props> = React.memo(props => {
	const dispatch = useDispatch();

	const goToNotificationDefinition = () => {
		navigateToSpecificNotification(props.notificationDefinition.id)(dispatch);
	};

	return (
		<div
			className={`margin-bottom-10px bi-label-content-item-box-shadow bi-label-content-item-lg notification-subscription-grid min-height-50px`}
		>
			<span className="justify-self-start">{props.notificationDefinition.name}</span>
			<span>{props.notificationUser.email}</span>
			<BiButton
				tooltip={
					props.notificationDefinition.isEditAllowed ? localized('Edit') : localized('PermissionRequired')
				}
				containerClassName="edit-btn-container"
				onClick={goToNotificationDefinition}
				colorTheme="org-primary"
				containerTheme="icon-sized-with-soft-rounded-corners"
				disabled={!props.notificationDefinition.isEditAllowed}
			>
				{getIcon('pencil', 18)}
			</BiButton>
		</div>
	);
});
