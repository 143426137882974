import { INotificationHistoryDto, NotificationMachineType, RolesType } from 'api/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { updateNotificationHistory } from 'state/ducks/notification-history/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { createHasRequiredRoleInHistorySelector } from 'utilities/hooks';
import {
	PickupOnDateOrderedNotificationTypeName,
	PickupOrderedNotificationTypeName,
} from 'utilities/notification-constants';
import { usePrevious } from 'utilities/use-previous';
import { Spinner } from 'view/components/spinner/spinner';
import BiInputNumber from 'view/shared/components/bi-input-number/bi-input-number';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { getIcon } from 'view/shared/utils/icon-helper';

export const NotificationHistoryTableEdit = React.memo((props: { row: INotificationHistoryDto; unitId: string }) => {
	const dispatch: Dispatch = useDispatch();
	const [showDialog, setShowDialog] = useState(false);
	const notificationEvent = useMemo(
		() =>
			props.row.notificationEvents?.find(
				event =>
					event.notificationType?.name === PickupOrderedNotificationTypeName ||
					event.notificationType?.name === PickupOnDateOrderedNotificationTypeName
			),
		[props.row.notificationEvents]
	);
	const [pickUpAmount, setPickUpAmount] = useState<number | undefined>(notificationEvent?.pickUpOrderedAmount);
	const isUpdateLoading = useSelector((state: AppState) => state.notificationHistoryReducer.isUpdateLoading);
	const previousIsLoading = usePrevious(isUpdateLoading, true);
	const isAdmin = useSelector((state: AppState) =>
		createHasRequiredRoleInHistorySelector(state, { unitId: props.unitId, requiredRole: RolesType.Admin })
	);

	const clearState = useCallback(() => {
		setPickUpAmount(notificationEvent?.pickUpOrderedAmount);
	}, [notificationEvent]);
	const onClick = useCallback(() => {
		setShowDialog(true);
	}, []);
	const onCancel = useCallback(() => {
		setShowDialog(false);
		clearState();
	}, [clearState]);

	const onSave = useCallback(async () => {
		if (typeof pickUpAmount !== 'number') return;

		updateNotificationHistory(props.row.id, props.unitId, pickUpAmount)(dispatch);
	}, [dispatch, pickUpAmount, props.row.id, props.unitId]);

	useEffect(() => {
		if (previousIsLoading && !isUpdateLoading) {
			// Only called if the update fails. On success, the row data is updated
			// which, in turn, unmounts this component.
			setShowDialog(false);
		}
	}, [isUpdateLoading, previousIsLoading]);

	const getDialogContent = useCallback(() => {
		return (
			<div className="history-table-dialog-grid">
				<h6>{localized('PickUpAmount')}:</h6>
				<BiInputNumber
					onValueChange={setPickUpAmount}
					value={pickUpAmount}
					suffix={
						notificationEvent?.notificationType?.machineType !== NotificationMachineType.Baler
							? '%'
							: ` ${localized('Bales').toLowerCase()}`
					}
				/>
			</div>
		);
	}, [notificationEvent, pickUpAmount]);

	const isSaveDisabled =
		pickUpAmount === undefined ||
		isUpdateLoading ||
		notificationEvent?.pickUpOrderedAmount === pickUpAmount ||
		pickUpAmount < 0;

	return !notificationEvent || !isAdmin ? null : (
		<>
			<BiButton
				title={localized('Edit')}
				containerClassName="history-table-edit-btn"
				onClick={onClick}
				colorTheme="org-primary"
				containerTheme="icon-sized-with-soft-rounded-corners"
			>
				{getIcon('pencil', 18)}
			</BiButton>
			<BiTextDialog
				onHide={onCancel}
				visible={showDialog}
				title={`${localized('Edit')} - ${localized('NotificationHistory')}`}
				subtitle={localized('NotificationHistoryUserDefinedExplained')}
			>
				{getDialogContent()}
				<br />
				<div className="flex-end-row margin-top-10px">
					<BiButton
						onClick={onCancel}
						colorTheme="org-red"
						containerClassName="margin-right-24px"
						containerTheme="slim-with-rounded-corners"
					>
						{localized('Cancel')}
					</BiButton>
					<BiButton
						onClick={onSave}
						colorTheme="org-green"
						containerTheme="slim-with-rounded-corners"
						disabled={isSaveDisabled}
					>
						{isUpdateLoading ? (
							<Spinner
								shouldOverlay={false}
								spinnerSize="spinner-container-1-4th-size"
								useMarginsForCorrectingCentering={false}
							/>
						) : (
							localized('Save')
						)}
					</BiButton>
				</div>
			</BiTextDialog>
		</>
	);
});
