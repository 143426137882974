import React, { useState } from 'react';
import { localized } from 'state/i18n';
import BiButton from '../../../../shared/components/buttons/bi-button/bi-button';
import { getIcon } from '../../../../shared/utils/icon-helper';
import { DocumentGroupType, ManualItem } from '../documents-helper';
import { DeviceDocumentGroupDialog } from './device-document-group-dialog';
import DocumentButton from './document-button';
import './document-button.scss';

interface AppProps {
	machineDetailsId: string;
	handleVideoBtnPressed: (videoName: string) => void;
	manualItems: ManualItem[];
}

export const DeviceDocumentButtons: React.FC<AppProps> = props => {
	const [dialogShowGroup, setDialogShowGroup] = useState<DocumentGroupType | undefined>();

	const openVideoGroupDialog = () => {
		setDialogShowGroup(DocumentGroupType.userVideo);
	};
	const openDocumentsGroupDialog = () => {
		setDialogShowGroup(DocumentGroupType.userDocument);
	};

	const openServiceGroupDialog = () => {
		setDialogShowGroup(DocumentGroupType.service);
	};

	const manualItemToDomElememt = (manual: ManualItem): JSX.Element => {
		return (
			<DocumentButton
				key={manual.name}
				manualItem={manual}
				handleVideoBtnPressed={props.handleVideoBtnPressed}
			></DocumentButton>);
	}

	const getDocuments = (): JSX.Element[] => {
		const relevantItems = props.manualItems.filter(item =>
			item.group !== DocumentGroupType.userDocument &&
			item.group !== DocumentGroupType.userVideo &&
			item.group !== DocumentGroupType.service
		)

		return relevantItems.map(manualItemToDomElememt);
	};

	const getDocumentGroups = (): JSX.Element[] => {
		if (!props.manualItems?.length) return [];

		const resultButtons: JSX.Element[] = [];
		const groupsArray = Array.from(new Set(props.manualItems.map(item => item.group)));

		if (groupsArray.some(type => type === DocumentGroupType.userDocument)) {
			resultButtons.push(
				<BiButton
					onClick={openDocumentsGroupDialog}
					colorTheme={'org-white'}
					containerClassName={'document-btn-container'}
				>
					<div className="document-btn-content">
						<span>{localized('UserDocuments')}</span>
						<div className="margin-left-auto">{getIcon('pdf', 30)}</div>
					</div>
				</BiButton>
			);
		}
		if (groupsArray.some(type => type === DocumentGroupType.userVideo)) {
			resultButtons.push(
				<BiButton
					onClick={openVideoGroupDialog}
					colorTheme={'org-white'}
					containerClassName={'document-btn-container'}
				>
					<div className="document-btn-content">
						<span>{localized('UserVideos')}</span>
						<div className="margin-left-auto">{getIcon('video', 30)}</div>
					</div>
				</BiButton>
			);
		}
		if (groupsArray.some(type => type === DocumentGroupType.service)) {
			resultButtons.push(
				<BiButton
					onClick={openServiceGroupDialog}
					colorTheme={'org-white'}
					containerClassName={'document-btn-container'}
				>
					<div className="document-btn-content">
						<span>{localized('Service')}</span>
						<div className="margin-left-auto">{getIcon('service', 30)}</div>
					</div>
				</BiButton>
			);
		}
		return resultButtons;
	};

	return (
		<>
			<DeviceDocumentGroupDialog
				manualItemToDomElememt={manualItemToDomElememt}
				manualItems={props.manualItems}
				documentGroupType={dialogShowGroup}
				visible={dialogShowGroup !== undefined}
				onHide={() => setDialogShowGroup(undefined)}
			/>
			{props.manualItems.length > 0 && (
				<>
					<h3 className="text-bold margin-top-40">{localized('DocumentsAndVideos')}</h3>
					{getDocumentGroups()}
					{getDocuments()}
				</>
			)}
		</>
	);
};
