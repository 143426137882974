import { ComparerInput, NotificationMachineType } from 'api/api';

const commonSettings: ComparerInput[] = [
	ComparerInput.WarrantyExpiresXDays,
	ComparerInput.NextServiceXDays,
	ComparerInput.UnitsEmptied,
	ComparerInput.TotalRunningHours,
	ComparerInput.TotalNumberOfCycles,
	ComparerInput.DaysSinceInstalled,
	ComparerInput.FullCounterAverage,
];

const getFillLevelEmptyBelowThresholdSettings = (isSuperAdmin: boolean): ComparerInput[] =>
	isSuperAdmin
		? [
				ComparerInput.FillLevelEmptyBelowThreshold,
				ComparerInput.FillLevelEmptyBelowThreshold2Times,
				ComparerInput.FillLevelEmptyBelowThreshold3Times,
		  ]
		: [];

const balerSettings: ComparerInput[] = [...commonSettings, ComparerInput.BalesOnSite];

const compactorSettings = (isSuperAdmin: boolean): ComparerInput[] => [
	...commonSettings,
	ComparerInput.FillLevel,
	ComparerInput.CyclesSinceLastOutput,
	...getFillLevelEmptyBelowThresholdSettings(isSuperAdmin),
];

// Shark has the same settings as a compactor
const sharkSettings = (isSuperAdmin: boolean): ComparerInput[] => compactorSettings(isSuperAdmin);

const containerSettings: ComparerInput[] = [
	ComparerInput.WarrantyExpiresXDays,
	ComparerInput.NextServiceXDays,
	ComparerInput.DaysSinceInstalled,
];

const linkSettings = (isSuperAdmin: boolean): ComparerInput[] => [
	...commonSettings,
	ComparerInput.FillLevel,
	ComparerInput.CyclesSinceLastOutput,
	...getFillLevelEmptyBelowThresholdSettings(isSuperAdmin),
];

export const machineTypeAdvancedSettingsMapper = (
	isSuperAdmin: boolean
): Record<NotificationMachineType, ComparerInput[]> => {
	return {
		[NotificationMachineType.Baler]: balerSettings,
		[NotificationMachineType.Compactor]: compactorSettings(isSuperAdmin),
		[NotificationMachineType.Link]: linkSettings(isSuperAdmin),
		[NotificationMachineType.Container]: containerSettings,
		[NotificationMachineType.SharkMK3]: sharkSettings(isSuperAdmin),
	};
};
