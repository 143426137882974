import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { GoogleMap, LoadScript, MarkerClusterer } from '@react-google-maps/api';
import { Cluster, Clusterer } from '@react-google-maps/marker-clusterer';
import { ICoordinateDto, IMapBounds, IUnitWithLocationDto } from 'api/api';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { MapTypeId } from 'state/ducks/location/constants';
import {
	setActivelySelectedMachine,
	setActivelySelectedMachines,
	setMapBounds,
	setMapCenter,
	setMapType,
	setMapZoomLevel,
} from 'state/ducks/location/operations';
import { getLanguage, localized } from 'state/i18n';
import { AppState } from 'state/store';
import {
	DynamicMapsKey,
	FilterBarId,
	GMapFillPageId,
	NavBarHeightPx,
	UnitsSelectedRowHeightPx,
} from 'utilities/constants';
import { images } from 'utilities/images';
import CreateLocationTile from 'view/components/dashboard/create-tile/create-location-tile';
import { Spinner } from 'view/components/spinner/spinner';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import fillLevelHighIcon from '../../../../assets/map_marker_fill_level_high_v2.png';
import fillLevelLowIcon from '../../../../assets/map_marker_fill_level_low_v2.png';
import fillLevelMediumIcon from '../../../../assets/map_marker_fill_level_medium_v2.png';
import noModemIcon from '../../../../assets/map_marker_nomodem_v2.png';
import BiMarker from './bi-marker';
import { machineHasPositionOnMap } from './location-helpers';
import LocationSidebar from './location-sidebar';
import './location.scss';

interface PropsFromParent {
	machinesWithLocation: IUnitWithLocationDto[] | undefined;
	centerLat?: number | undefined;
	centerLng?: number | undefined;
	zoomLevel?: number | undefined;
}

const mapStateToProps = (state: AppState) => {
	return {
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		activelySelectedMachine: state.locationReducer.activelySelectedMachine,
		appliedFilter: state.filterSearchReducer.appliedFilters,
		initialMapType: state.locationReducer.mapType,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setActivelySelectedMachine: (machine?: IUnitWithLocationDto) => setActivelySelectedMachine(machine)(dispatch),
	setActivelySelectedMachines: (machines?: IUnitWithLocationDto[]) => setActivelySelectedMachines(machines)(dispatch),
	setMapType: (mapType: MapTypeId) => setMapType(mapType)(dispatch),
	setMapBounds: (mapBounds: IMapBounds) => setMapBounds(mapBounds)(dispatch),
	setMapCenter: (mapCenter: ICoordinateDto) => setMapCenter(mapCenter)(dispatch),
	setMapZoomLevel: (mapZoomLevel: number) => setMapZoomLevel(mapZoomLevel)(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

type State = {
	displayInfoWindow: boolean;
	map: any;
	gmapKey: string | number | undefined;
	prevLatLng?: Coordinate;
	rerender: number;
	gmapLanguage: string;
	zoom: number;
	showLocationTile: boolean;
};

export interface Coordinate {
	lat: number;
	lng: number;
}

type LatLngBounds = { southwest: { lat: number; lng: number }; northeast: { lat: number; lng: number } };

const denmark: LatLngBounds = {
	southwest: { lat: 56.1098071, lng: 9.2007248 },
	northeast: { lat: 56.3098071, lng: 9.4007248 },
};

const googleMapStyles = {
	height: '100%',
	width: '100%',
};
const markerClustererOptions = {
	//Google default pictures
	//imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',

	styles: [
		{
			url: images.m1,
			height: 53,
			width: 53,
		},
		{
			url: images.m2,
			height: 56,
			width: 56,
		},
		{
			url: images.m3,
			height: 66,
			width: 66,
		},
		{
			url: images.m4,
			height: 78,
			width: 78,
		},
		{
			url: images.m5,
			height: 90,
			width: 90,
		},
	],
};

const center = {
	lat: 30,
	lng: 0,
};

// There has been made a pullrequest to raect-google-maps/api
// To include the function: "panTo(latLng: google.maps.LatLng | google.maps.LatLngLiteral): void"
// When the function is merged in, the type should be GoogleMap
let mapRef: any;

// List of props to use when accessing the tooltip value. Ordered.
// Example: Marker has its id stored in <my-marker>[label][text]
const titleNoTooltipPropAccessors = ['label', 'text'];

class DynamicMap extends React.PureComponent<Props, State> {
	private readonly latLngBoundsDenmark: any | undefined;
	private mapsRef = React.createRef<GoogleMap>();

	constructor(props: Props) {
		super(props);

		const defaultLanguage = getLanguage();

		this.state = {
			displayInfoWindow: false,
			map: null,
			gmapKey: 1,
			rerender: 0,
			gmapLanguage: defaultLanguage,
			zoom: props.zoomLevel || 7,
			showLocationTile: false,
		};

		this.latLngBoundsDenmark = window.google
			? new window.google.maps.LatLngBounds(
					new window.google.maps.LatLng(denmark.southwest.lat, denmark.southwest.lng),
					new window.google.maps.LatLng(denmark.northeast.lat, denmark.northeast.lng)
			  )
			: undefined;
	}

	public async componentDidMount() {
		this.setHeights();
	}

	public async componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			!isEqual(prevProps.machinesWithLocation, this.props.machinesWithLocation) ||
			!isEqual(prevProps.centerLat, this.props.centerLat) ||
			!isEqual(prevProps.centerLng, this.props.centerLng) ||
			!isEqual(prevProps.zoomLevel, this.props.zoomLevel)
		) {
			this.setState({ gmapKey: this.randomKey() });
		}
	}

	public render() {
		const googleMap: JSX.Element = this.getGoogleMapOrSpinner();

		if (!this.state.showLocationTile) {
			this.centerPinOnMap();
		}

		return (
			<LoadScript
				id="script-loader"
				googleMapsApiKey={DynamicMapsKey}
				loadingElement={<Spinner />}
				language={this.state.gmapLanguage}
			>
				<div className="map-wrapper">
					{/* // Important! Always set the container height explicitly */}
					{/* TODO: DASHBOARD. Once dashboard is stable, add this back */}
					{/* <div className="btn-wrapper">
						<AddTileButton handleClick={this.toggleDialog} />
					</div> */}

					{/* Google Maps component */}
					<div id={GMapFillPageId}>{googleMap}</div>

					<LocationSidebar />
				</div>

				<div className="tile-dialog-wrapper">
					<BiTextDialog
						onHide={this.onTileDialogHide}
						visible={this.state.showLocationTile}
						subtitle={localized('TileSubtitle')}
						title={localized('TileTitle')}
						modal={false}
					>
						<CreateLocationTile onTileAdded={this.onTileDialogHide} />
					</BiTextDialog>
				</div>
			</LoadScript>
		);
	}

	private onTileDialogHide = (): void => {
		this.setState({ showLocationTile: false });
	};

	private toggleDialog = (): void => {
		this.setState(prevState => ({
			showLocationTile: !prevState.showLocationTile,
		}));
	};

	private setMapBounds = () => {
		if (mapRef) {
			const bounds = mapRef.getBounds() as google.maps.LatLngBounds;
			const ne = bounds.getNorthEast();
			const sw = bounds.getSouthWest();
			const mapBounds: IMapBounds = {
				north: ne.lng(),
				east: ne.lat(),
				south: sw.lng(),
				west: sw.lat(),
			};

			this.props.setMapBounds!(mapBounds);
		}
	};

	private setZoomLevel = () => {
		if (mapRef) {
			this.props.setMapZoomLevel!(mapRef.getZoom());
		}
	};
	private setMapCenter = () => {
		if (mapRef) {
			const mapCenter = mapRef.getCenter();
			this.props.setMapCenter!({ latitude: mapCenter.lat(), longitude: mapCenter.lng() });
		}
	};

	private onBoundsChanged = () => {
		this.setMapBounds();
	};

	private onZoomChanged = () => {
		this.setZoomLevel();
	};

	private onCenterChanged = () => {
		this.setMapCenter();
	};

	private getGoogleMapOrSpinner = (): JSX.Element => {
		let map: JSX.Element;
		let markerClusterer: JSX.Element | null = null;

		if (this.props.machinesWithLocation && window.google) {
			if (machineHasPositionOnMap(this.props.machinesWithLocation)) {
				markerClusterer = (
					<MarkerClusterer
						onClick={this.onClusterClicked}
						children={this.getClusterer}
						options={markerClustererOptions}
					/>
				);
			}

			// Call before map, to ensure that Google map fonts does not overwrite fonts on page
			this.preventAPIFromLoadingFont();

			map = (
				<GoogleMap
					key={this.state.gmapKey}
					center={center}
					onCenterChanged={this.onCenterChanged}
					onZoomChanged={this.onZoomChanged}
					onBoundsChanged={this.onBoundsChanged}
					options={this.getMapOptions()}
					mapContainerStyle={googleMapStyles}
					zoom={this.state.zoom}
					onMapTypeIdChanged={this.mapTypeChanged}
					ref={this.mapsRef}
					onLoad={this.onGmapLoaded} // The callback we reference here is used in order to provide the auto zoom / fit to bounds functionality.
				>
					{markerClusterer}
				</GoogleMap>
			);
		} else {
			map = <Spinner />;
		}

		return map;
	};

	private getMapOptions = () => {
		const googleMapOptions = {
			zoomControlOptions: {
				position: window.google && window.google.maps.ControlPosition.LEFT_TOP,
				style: window.google && window.google.maps.ControlPosition.SMALL,
			},
			mapTypeControl: true,
			fullscreenControl: false,
			mapTypeId: this.props.initialMapType,
		};

		return googleMapOptions;
	};

	// Fired when changing between satellite/road maps
	private mapTypeChanged = async () => {
		if (this.mapsRef.current) {
			const selectedMapType = this.mapsRef.current.state.map?.getMapTypeId();
			if (selectedMapType) {
				await this.props.setMapType!(selectedMapType);
			}
		}
	};

	private onClusterClicked = async (cluster: Cluster) => {
		const currentZoomLevel = cluster.getMap()?.getZoom();
		if (currentZoomLevel && currentZoomLevel >= 20) {
			const markersInClusterWithSameCoordinates: any[] = cluster.getMarkers();
			const idsOfClusteredMachines = markersInClusterWithSameCoordinates.map(marker => {
				let currPropVal: number | undefined = marker[titleNoTooltipPropAccessors[0]];
				for (let i = 1; i < titleNoTooltipPropAccessors.length && currPropVal !== undefined; ++i) {
					const currProp = titleNoTooltipPropAccessors[i];
					currPropVal = parseInt(currPropVal[currProp]);
				}
				return currPropVal;
			});

			const machinesInClusterClicked =
				this.props.machinesWithLocation &&
				this.props.machinesWithLocation.filter(m => idsOfClusteredMachines.includes(m.id));
			this.props.setActivelySelectedMachines!(machinesInClusterClicked);
		}
	};

	private randomKey = () =>
		Math.random()
			.toString(36)
			.replace(/[^a-z]+/g, '')
			.substr(2, 10);

	private setHeights = () => {
		setTimeout(() => {
			// This sets the height of the map is because we don't know the height of the filterbar
			let filterBarElement = document.getElementById(FilterBarId);
			let gmap = document.getElementById(GMapFillPageId);

			if (gmap && filterBarElement) {
				let height = filterBarElement.clientHeight + NavBarHeightPx + UnitsSelectedRowHeightPx;
				gmap.setAttribute('style', `height: calc(100vh - ${height}px)`);
			}
		}, 0);
	};

	private onGmapLoaded = (map: any) => {
		mapRef = map as GoogleMap;

		// If we have settings from a dashboard tile then use that for positioning the map
		if (this.props.centerLat && this.props.centerLng) {
			const centerPosition: Coordinate = {
				lat: this.props.centerLat,
				lng: this.props.centerLng,
			};

			mapRef.panTo(centerPosition);

			return;
		}

		if (this.props.machinesWithLocation && this.props.machinesWithLocation.length) {
			const bounds = this.getBoundsAsNativeApiObject(this.props.machinesWithLocation);

			bounds && map.fitBounds(bounds, 0);

			// Hotfix to fix zoom level when only one machine because map.fitBounds is async with no promise
			if (this.props.machinesWithLocation.length === 1) {
				setTimeout(() => this.setState({ zoom: 17 }), 100);
			}
		}
	};

	private centerPinOnMap = () => {
		if (mapRef) {
			if (
				this.props.activelySelectedMachine &&
				this.props.activelySelectedMachine.latitude &&
				this.props.activelySelectedMachine.longitude
			) {
				const selectedMachinePosition: Coordinate = {
					lat: parseFloat(this.props.activelySelectedMachine.latitude),
					lng: parseFloat(this.props.activelySelectedMachine.longitude),
				};
				mapRef.panTo(selectedMachinePosition);
			}
		}
	};

	private getClusterer = (clusterer: Clusterer) => {
		const iconHeight: number = 47;
		const iconWidth: number = 0.6978 * iconHeight;
		return (
			(this.props.machinesWithLocation &&
				this.props.machinesWithLocation.map((m: IUnitWithLocationDto) => {
					const markerPosition = {
						lat: parseFloat(m.latitude || '0') || 0,
						lng: parseFloat(m.longitude || '0') || 1,
					};

					const markerHomePosition = {
						lat: parseFloat(m.homeLatitude || '0') || 0,
						lng: parseFloat(m.homeLongitude || '0') || 1,
					};

					const markerIcon: google.maps.Icon = {
						url: this.getMarkerByFillLevel(m),
						scaledSize: new google.maps.Size(iconWidth, iconHeight),
					};

					return m.latitude &&
						!m.latitude.startsWith('0.0') &&
						m.longitude &&
						!m.longitude.startsWith('0.0') ? (
						<BiMarker
							key={m.id}
							title={m.id}
							position={markerPosition}
							icon={markerIcon}
							machineLocation={m}
							onClick={this.props.setActivelySelectedMachine!}
							clusterer={clusterer}
						/>
					) : (
						m.homeLatitude && m.homeLongitude && (
							<BiMarker
								key={m.id}
								title={m.id}
								position={markerHomePosition}
								icon={markerIcon}
								machineLocation={m}
								onClick={this.props.setActivelySelectedMachine!}
								clusterer={clusterer}
							/>
						)
					);
				})) ||
			null
		);
	};

	/**
	 * Creates an instance of LatLngBounds native object instantiated by currently fetched machinesWithLocation (from redux store).
	 * Needed in order to be able to call bounds.getCenter() [see getMapCenter] which should return the center of all markers on the map.
	 * @param machines `machines` is an array of machinesWithLocationDto.
	 */
	private getBoundsAsNativeApiObject(machines: IUnitWithLocationDto[]) {
		let mapBounds: LatLngBounds = { southwest: { lat: 0, lng: 0 }, northeast: { lat: 0, lng: 0 } };
		const { mostSouth, mostWest, mostNorth, mostEast } = this.getBoundsBasedOnMachinesWithLocation(machines);

		if (mostSouth !== undefined && mostWest !== undefined && mostNorth !== undefined && mostEast !== undefined) {
			// Ending up in here means that we've successfully found good candidate bounds for the map.
			// This is where we'd expect to land (exceptions may be in cases where no good candidate bounds could be found, AKA due to incoherent data in DB)
			mapBounds = {
				southwest: { lat: mostSouth, lng: mostWest },
				northeast: { lat: mostNorth, lng: mostEast },
			};
			const bounds =
				(window.google &&
					new window.google.maps.LatLngBounds(
						new window.google.maps.LatLng(mapBounds.southwest.lat, mapBounds.southwest.lng),
						new window.google.maps.LatLng(mapBounds.northeast.lat, mapBounds.northeast.lng)
					)) ||
				undefined;

			return bounds;
		} else {
			// We're defaulting the map to center on Denmark if we cannot find good candidate bounds => due to incoherent data in DB (some machines have the val '0' or equivalent in either/multiple of their location related fields)
			const bounds = this.latLngBoundsDenmark;

			if (bounds) {
				return bounds;
			}
			return;
		}
	}

	private preventAPIFromLoadingFont = () => {
		// Ensure that fonts on webportal does not change, when loading google map
		// Reference to source:
		// https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font
		let head = document.getElementsByTagName('head')[0] as any;

		// Save the original method
		let insertBefore = head.insertBefore;

		// Replace it!
		head.insertBefore = (newElement: HTMLLinkElement, referenceElement: HTMLStyleElement) => {
			if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {
				return;
			}
			insertBefore.call(head, newElement, referenceElement);
		};
	};

	/**
	 * Gets the bounds of all machines passed; used in order to generate native LatLngBounds.
	 * Finds the lowest/highest value of latitude (or homeLatitude, if the former is not present) (mostSouth/mostNorth) and longitude (or homeLongitude, if the former is not present) (mostWest/mostEast).
	 * @param machines `machines` is an array of machinesWithLocationDto.
	 */
	private getBoundsBasedOnMachinesWithLocation(machines: IUnitWithLocationDto[]) {
		const emptyCoordinates = {
			mostSouth: undefined,
			mostWest: undefined,
			mostNorth: undefined,
			mostEast: undefined,
		};

		const machinesWithNonZeroLocation = machines.filter(
			machine =>
				!(
					parseFloat(machine.latitude || machine.homeLatitude || '0') === 0 ||
					parseFloat(machine.longitude || machine.homeLongitude || '0') === 0
				)
		); // What we do here is remove all the DTO's which have invalid/inaccurate lat/lng information (AKA either/both is set to 0)

		if (!machinesWithNonZeroLocation.length) {
			return emptyCoordinates;
		}

		const mostSouth = Math.min(
			...machinesWithNonZeroLocation.map((m: IUnitWithLocationDto) => {
				const lat = m.latitude || m.homeLatitude || '999';
				return parseFloat(lat);
			})
		);
		const mostWest = Math.min(
			...machinesWithNonZeroLocation.map((m: IUnitWithLocationDto) => {
				const lng = m.longitude || m.homeLongitude || '999';
				return parseFloat(lng);
			})
		);
		const mostNorth = Math.max(
			...machinesWithNonZeroLocation.map((m: IUnitWithLocationDto) => {
				const lat = m.latitude || m.homeLatitude || '-999';
				return parseFloat(lat);
			})
		);
		const mostEast = Math.max(
			...machinesWithNonZeroLocation.map((m: IUnitWithLocationDto) => {
				const lng = m.longitude || m.homeLongitude || '-999';
				return parseFloat(lng);
			})
		);

		if (mostSouth === 999 || mostWest === 999 || mostNorth === -999 || mostEast === -999) {
			return emptyCoordinates;
		} // Checking if we've defaulted to 999/-999 => if that's the case, we haven't found good candidate bounds; thus, we shall return undefined vals for each of the points in the resulting object..

		return { mostSouth, mostWest, mostNorth, mostEast };
	}

	private getMarkerByFillLevel = (machine: IUnitWithLocationDto) => {
		if (!machine.hasModem || !machine.isOnline) {
			return noModemIcon;
		}

		let fillLevel = machine.fillLevel;

		if (machine.isBaler) {
			if (machine.balesOnSite === undefined || machine.baleStorageSize === undefined) fillLevel = 0;
			else fillLevel = (machine.balesOnSite * 100) / machine.baleStorageSize;
		}

		if (!fillLevel) {
			return fillLevelLowIcon;
		}
		if (fillLevel < 80) {
			return fillLevelLowIcon;
		} else if (fillLevel >= 80 && fillLevel < 100) {
			return fillLevelMediumIcon;
		} else {
			return fillLevelHighIcon;
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, DynamicMap, 'DynamicMap'));
