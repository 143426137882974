// Taken from variables.scss because graph requires colors in string. If only we could the styles instead...
export const colorGreen = 'rgb(107, 181, 54)';
export const colorGreenLight = '#62ff00';
export const colorGreenGraphLine = '#71f130';
export const colorGreenLightTransparentGraphLine = 'rgba(225, 254, 222, 0.6)';
export const colorOrange = 'rgb(238, 166, 0)';
export const colorOrangeLightTransparent = 'rgba(252, 216, 66, 0.6)';
export const colorRed = 'rgb(254, 6, 6)';
export const colorRedLight = 'rgba(252, 80, 64, 0.6)';
export const chartGrayDark = '#545b61';
export const chartGrayLight = '#a9b0bc';
export const chartGreyLightTransparent = 'rgba(169, 176, 188, 0.6)';
export const colorGrey = '#dad8d8';
export const colorYellow = '#ffff33';
export const colorOrgBlue = '#28313C';
export const colorMetalGrey = '#ededed';
export const colorPrimaryLight10Bramidan = '#ebc876';
export const colorPrimaryLight20Bramidan ='#ffecbf';
export const colorPrimaryLight10Presto = '#a0d97b';
export const colorPrimaryLight20Presto = '#c8e2b8';