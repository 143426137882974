import { IUnitDetailsDto, IUnitSelectedDto, IUnitWithLocationDto, IUnitHistoryInfoDto, IUnitsDto } from 'api/api';
import { IdDataTuple } from './types-util';

export function updateMachineOwnerGroupName<T extends IUnitsDto | IUnitSelectedDto>(old: T[], deviceIds: number[], groupName: string): typeof old {
    const updated: T[] = [...old];
    deviceIds.forEach(devId => {
        const index = updated.findIndex(o => o.id === devId);
        if (index > -1) {
            let device = { ...updated[index] };
            device.groupName = groupName;
            updated[index] = device;
        }
    });

    return updated;
}

export function updateMachineOwnerGroupIdsInDataTupleDetail<T extends IUnitDetailsDto>(old: IdDataTuple<T, string>[], deviceIds: number[], destinationGroupId: number): typeof old {
    const updated: IdDataTuple<T, string>[] = [...old];

    deviceIds.forEach(devId => {
        const index = updated.findIndex(machine => machine.data.id === devId);
        if (index > -1) {
            let device = updated[index];
            device = { ...device };
            device.data = { ...device.data };
            device.data.ownerGroupId = destinationGroupId;
            updated[index] = device;
        }
    });

    return updated;
}

export function updateMachineOwnerGroupIdsInDataTupleHistory<T extends IUnitHistoryInfoDto>(old: IdDataTuple<T, string>[], deviceIds: number[], destinationGroupId: number): typeof old {
    const updated: IdDataTuple<T, string>[] = [...old];

    deviceIds.forEach(devId => {
        const index = updated.findIndex(machine => machine.data.id === devId);
        if (index > -1) {
            let device = updated[index];
            device = { ...device };
            device.data = { ...device.data };
            device.data.ownerGroupId = destinationGroupId;
            updated[index] = device;
        }
    });

    return updated;
}

export function updateMachineOwnerGroupIds<T extends IUnitsDto | IUnitWithLocationDto | IUnitSelectedDto>(old: T[], deviceIds: number[], destinationGroupId: number): typeof old {
    const updated: T[] = [...old];
    deviceIds.forEach(devId => {
        const index = updated.findIndex(o => o.id === devId);
        if (index > -1) {
            let device = updated[index];
            device = { ...device };
            device.ownerGroupId = destinationGroupId;
            updated[index] = device;
        }
    });

    return updated;
}
