import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { UnitTypeState } from './types';

const initialState: UnitTypeState = {
	unitTypes: [],
};

export function unitTypeReducer(state: UnitTypeState = initialState, action: Action): UnitTypeState {
	if (isType(action, actions.getUnitTypesAction.done)) {
		return {
			...state,
			unitTypes: action.payload.result,
		};
	}

	return state;
}
