import React from 'react';
import { localized } from 'state/i18n';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';

type Props = {
    onCancel: () => void;
    onDelete: () => void;
    showDialog: boolean;
};

class DeleteNotificationDefinitionDialog extends React.PureComponent<Props> {
    render() {
        return (
            <BiTextDialog onHide={this.props.onCancel} visible={this.props.showDialog} title={localized('Delete')} subtitle={localized('DeleteNotificationDefinitionConfirmMessage')}>
                <div className="flex-end-row margin-top-10px">
                    <BiButton
                        colorTheme="org-white"
                        containerTheme="slim-with-rounded-corners"
                        containerClassName="margin-right-24px"
                        onClick={this.props.onCancel}
                    >
                        {localized('Cancel')}
                    </BiButton>
                    <BiButton
                        colorTheme={"org-red"}
                        containerTheme="slim-with-rounded-corners"
                        onClick={this.props.onDelete}
                    >
                        {localized("Confirm")}
                    </BiButton>
                </div>
            </BiTextDialog>


        )
    }
}
export default DeleteNotificationDefinitionDialog;