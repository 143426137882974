import {
	CustomTreeData,
	DataTypeProvider,
	IntegratedSorting,
	SelectionState,
	Sorting,
	SortingState,
	TableColumnResizing,
	TreeDataState,
} from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, TableTreeColumn, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { FileDirectoryDto, FileDirectoryTypeEnum, IFileDirectoryDto } from 'api/api';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	GetPartnerAreaFileTree,
	GetPartnerAreaFileTreeByPath,
	GetPartnerGetRootFolderContent,
} from 'state/ducks/partner-area/operations';
import { findFolder } from 'state/ducks/partner-area/reducer';
import { ColumnGeneric } from 'state/ducks/table-settings/types';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { fuzzySearchOccurrences } from 'utilities/string-utils';
import { keyToString, propertiesToString } from 'utilities/types-util';
import { Spinner } from 'view/components/spinner/spinner';
import { ROW_HEIGHT } from 'view/components/table/bi-table/bi-table';
import BiInputText from 'view/shared/components/bi-input-text/bi-input-text';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import WhiteCard from 'view/shared/components/cards/white-card';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';
import PartnerAreaRowActions from './partner-area-row-actions';
import { PartnerAreaRowName } from './partner-area-row-name';
import './partner-area.scss';

type ExtraKeys = 'actions';
type ColumnsType = keyof IFileDirectoryDto | ExtraKeys;

const GridWidth: number = 800;
const ActionsWidth: number = 100;

const TableComponent = ({ ...restProps }) => <VirtualTable.Table {...restProps} className="bi-report-table-striped" />;
const HeaderComponent = ({ ...restProps }) => <VirtualTable.TableHead {...restProps} className="report-table-header" />;
const CellComponent = (obj: any) => <VirtualTable.Cell {...obj} value={obj.value ? obj.value : 0} />;
const rootStyle: React.CSSProperties = {
	width: `${GridWidth}px`,
};
const Root = (props: Grid.RootProps) => <Grid.Root {...props} style={rootStyle} />;

const sortingOrder: Sorting[] = [
	{
		columnName: propertiesToString<IFileDirectoryDto>('fileDirectoryType'),
		direction: 'asc',
	},
	{
		columnName: propertiesToString<IFileDirectoryDto>('name'),
		direction: 'asc',
	},
];

const NameFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const fileDirectoryDto = obj.row as IFileDirectoryDto;
	return <PartnerAreaRowName obj={fileDirectoryDto} />;
};

const ActionsFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const fileDirectoryDto = obj.row as IFileDirectoryDto;
	return <PartnerAreaRowActions obj={fileDirectoryDto} />;
};

interface Props {}

export const PartnerAreaPage: FC<Props> = props => {
	const dispatch = useDispatch();

	const partnerAreaFileTree: IFileDirectoryDto | undefined = useSelector(
		(state: AppState) => state.partnerAreaReducer.fileTree
	);
	const loading: boolean = useSelector((state: AppState) => state.partnerAreaReducer.loading);
	const wholeFileTreeLoaded: boolean = useSelector((state: AppState) => state.partnerAreaReducer.wholeFileTreeLoaded);

	const [searchString, setSearchString] = useState('');
	const [files, setFiles] = useState(partnerAreaFileTree);
	const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);

	const recursiveFileTreeSearchFilter = useCallback(
		(fileDirectoryInput: IFileDirectoryDto | undefined, searchString: string) => {
			if (fileDirectoryInput === undefined) return undefined;
			const fileDirectory = { ...fileDirectoryInput };

			if (isNullOrWhitespace(searchString)) return fileDirectory;

			searchString = searchString.toLowerCase();

			if (fileDirectory?.fileDirectoryType === FileDirectoryTypeEnum.File) {
				return fuzzySearchOccurrences(searchString, fileDirectory.fullPath?.toLocaleLowerCase() ?? '')
					? fileDirectory
					: undefined;
			}

			if (fileDirectory.fileDirectoryDtos === undefined) return undefined;

			fileDirectory.fileDirectoryDtos = fileDirectory.fileDirectoryDtos
				.map(fileDir => recursiveFileTreeSearchFilter(fileDir, searchString))
				.filter((fileDir: IFileDirectoryDto | undefined): fileDir is FileDirectoryDto => !!fileDir);

			if (fileDirectory.fileDirectoryDtos.length === 0) return undefined;

			return fileDirectory;
		},
		[]
	);

	const onSearchChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const newSearchStr: string = e.currentTarget.value ?? '';
		setSearchString(newSearchStr);
	};

	const columns: ColumnGeneric<ColumnsType>[] = [
		{ name: 'name', title: localized('Name') },
		{ name: 'actions', title: ' ' },
	];

	const getChildRows = (row: IFileDirectoryDto, rootRows: IFileDirectoryDto[]) => {
		if (!row) return rootRows;
		if (row.fileDirectoryType === FileDirectoryTypeEnum.File) return null;
		if (row.fileDirectoryType === FileDirectoryTypeEnum.Folder) return row.fileDirectoryDtos!;
		else return null;
	};

	// Get file tree
	useEffect(() => {
		const getPartnerGetRootFolderContent = async () => {
			dispatch(await GetPartnerGetRootFolderContent());
		};

		getPartnerGetRootFolderContent();
	}, [dispatch]);

	useEffect(() => {
		const search = async () => {
			if (!wholeFileTreeLoaded && searchString !== '' && !loading) {
				dispatch(await GetPartnerAreaFileTree());
			}
			setFiles(recursiveFileTreeSearchFilter(partnerAreaFileTree, searchString));
		};

		search();
	}, [partnerAreaFileTree, recursiveFileTreeSearchFilter, searchString, loading]);

	// Get folder content when tree is expanded
	useEffect(() => {
		const getPartnerAreaFileTreeByPath = async (path: string) => {
			dispatch(await GetPartnerAreaFileTreeByPath(path));
		};

		if (expandedRowIds.length === 0) return;
		let addedPath = expandedRowIds[expandedRowIds.length - 1] as string;

		var folder = findFolder(files, addedPath);

		// Don't get from API multiple times
		if (folder?.fileDirectoryDtos?.length) return;

		getPartnerAreaFileTreeByPath(addedPath);
	}, [expandedRowIds]);

	const getRowId = (row: FileDirectoryDto) => row.fullPath!;

	return (
		<BiTitle title={localized('PartnerArea')}>
			<div className="margin-horizontal-responsive margin-vertical-20px flex-center-row partner-area-container">
				<WhiteCard
					title={localized('PartnerArea')}
					subtitle={localized('PartnerAreaDesignation')}
					classNameContainer="padding-bottom-15px"
				>
					<BiInputText
						className="search"
						value={searchString}
						placeholder={localized('Search')}
						onChange={onSearchChange}
					/>
					<Grid
						rows={files?.fileDirectoryDtos ?? []}
						columns={columns}
						rootComponent={Root}
						getRowId={getRowId}
					>
						<SelectionState />
						<TreeDataState
							defaultExpandedRowIds={[]}
							onExpandedRowIdsChange={setExpandedRowIds}
							expandedRowIds={expandedRowIds}
						/>
						<CustomTreeData getChildRows={getChildRows} />
						<DataTypeProvider
							for={[keyToString<ColumnsType>('actions')]}
							formatterComponent={ActionsFormatter}
						/>
						<DataTypeProvider for={[keyToString<ColumnsType>('name')]} formatterComponent={NameFormatter} />
						<SortingState sorting={sortingOrder} defaultSorting={sortingOrder} />
						<IntegratedSorting />
						<VirtualTable
							tableComponent={TableComponent}
							headComponent={HeaderComponent}
							cellComponent={CellComponent}
							height="auto"
							estimatedRowHeight={ROW_HEIGHT}
						/>
						<TableColumnResizing
							columnWidths={[
								{ columnName: 'actions', width: ActionsWidth },
								{ columnName: 'name', width: 'auto' },
							]}
							resizingMode="nextColumn"
						/>
						<TableHeaderRow />
						<TableTreeColumn for={keyToString<ColumnsType>('name')} />
					</Grid>
					{loading && (
						<Spinner
							shouldOverlay={true}
							shouldUseAbsolutePositioning={true}
							spinnerSize="spinner-container-default-size"
						/>
					)}
				</WhiteCard>
			</div>
		</BiTitle>
	);
};
