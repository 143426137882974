import { UnitDetailsDto } from 'api/api';
import fileSaver from 'file-saver';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { notificationService } from 'services/notification-service';
import { unitDetailsClient } from 'state/ducks/api-clients';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import { MimeTypes } from 'utilities/mime-type-helper';
import { usePrevious } from 'utilities/use-previous';
import { Spinner } from 'view/components/spinner/spinner';
import BiCalendar from 'view/shared/components/bi-calendar/bi-calendar';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import WhiteCard from 'view/shared/components/cards/white-card';
import { OnCalendarChangeType } from 'view/shared/components/date-pickers';
import { cardDetailsStyles, cardGeneralClassNames } from '../constants';
import { getSelectedLanguage } from 'utilities/language-util';

type Props = {
	machineDetailsId: string;
};

const containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer} flex-direction-column`;

export const WasteHandlersEmptyings: FC<Props> = React.memo(({ machineDetailsId }) => {
	const [isExporting, setIsExporting] = useState<boolean>(false);
	const [startDateBeforeOptimize, setStartDateBeforeOptimize] = useState<Date>();
	const [endDateBeforeOptimize, setEndDateBeforeOptimize] = useState<Date>();
	const [startDateAfterOptimize, setStartDateAfterOptimize] = useState<Date>();
	const [endDateAfterOptimize, setEndDateAfterOptimize] = useState<Date>();
	const { pricePerEmptying, pricePerEmptyingCurrency } = useSelector(
		(state: AppState) =>
			getMachineFromIdDataMapOrDefault(
				state.machineDetailsReducer.machineDetails,
				machineDetailsId,
				UnitDetailsDto
			).data
	);
	const userLanguage = useSelector((state: AppState) => state.userSettingReducer.userSettings.selectedLanguage);

	const buttonRef = useRef<HTMLButtonElement>(null);
	const [buttonStyle, setButtonStyle] = useState<React.CSSProperties>({
		minHeight: 39,
	});
	const previousButtonStyle = usePrevious(buttonStyle, true);

	useEffect(() => {
		if (buttonRef.current) {
			if (!isExporting) {
				setButtonStyle({ ...previousButtonStyle, minWidth: buttonRef.current.offsetWidth });
			} else {
				setButtonStyle({ ...previousButtonStyle, minHeight: buttonRef.current.offsetHeight });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isExporting]);

	const handleExportExcel = useCallback(async () => {
		if (!pricePerEmptying || !pricePerEmptyingCurrency) {
			notificationService.showWarningMessage(localized('SetAPriceAndCurrencyPerEmptying'));
			return;
		}

		if (!startDateBeforeOptimize || !startDateAfterOptimize || !endDateBeforeOptimize || !endDateAfterOptimize) {
			notificationService.showWarningMessage(localized('Error_InvalidDates'));
			return;
		}

		setIsExporting(true);

		try {
			const fileResponse = await unitDetailsClient.downloadWasteEmptyingsForTimeRange(
				machineDetailsId,
				startDateBeforeOptimize,
				endDateBeforeOptimize,
				startDateAfterOptimize,
				endDateAfterOptimize,
				getSelectedLanguage(userLanguage)
			);

			if (fileResponse?.data?.size) {
				const blob = new Blob([fileResponse.data], {
					type: MimeTypes.ContentType.xlsx,
				});
				fileSaver.saveAs(blob, `${machineDetailsId}-waste-emptyings.xlsx`);
			} else {
				notificationService.showInfoMessage(localized('NoDataForTheSelectedPeriods'));
			}
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		} finally {
			setIsExporting(false);
		}
	}, [
		endDateAfterOptimize,
		endDateBeforeOptimize,
		machineDetailsId,
		pricePerEmptying,
		pricePerEmptyingCurrency,
		startDateAfterOptimize,
		startDateBeforeOptimize,
		userLanguage,
	]);

	const onStartDateBeforeOptimizeChange = useCallback((e: OnCalendarChangeType) => {
		if (Array.isArray(e.value)) {
			return;
		}

		setStartDateBeforeOptimize(e.value);
	}, []);

	const onEndDateBeforeOptimizeChange = useCallback((e: OnCalendarChangeType) => {
		if (Array.isArray(e.value)) {
			return;
		}

		setEndDateBeforeOptimize(new Date(e.value.setHours(23, 59, 59, 0)));
	}, []);

	const onStartDateAfterOptimizeChange = useCallback((e: OnCalendarChangeType) => {
		if (Array.isArray(e.value)) {
			return;
		}

		setStartDateAfterOptimize(e.value);
	}, []);

	const onEndDateAfterOptimizeChange = useCallback((e: OnCalendarChangeType) => {
		if (Array.isArray(e.value)) {
			return;
		}

		setEndDateAfterOptimize(new Date(e.value.setHours(23, 59, 59, 0)));
	}, []);

	return (
		<WhiteCard title={localized('WasteHandlersEmptyingsTitle')} classNameContainer={containerClassName}>
			<span>{localized('SelectAPeriodBeforeOptimizations')}</span>
			{getDatePickers(
				startDateBeforeOptimize,
				onStartDateBeforeOptimizeChange,
				endDateBeforeOptimize,
				onEndDateBeforeOptimizeChange
			)}

			<span className="margin-top-15px">{localized('SelectAPeriodAfterOptimizations')}</span>
			{getDatePickers(
				startDateAfterOptimize,
				onStartDateAfterOptimizeChange,
				endDateAfterOptimize,
				onEndDateAfterOptimizeChange,
				endDateBeforeOptimize
			)}

			<BiButton
				onClick={handleExportExcel}
				disabled={isExporting}
				colorTheme="org-grey"
				containerTheme="normal-button-corners"
				containerClassName="margin-top-15px"
				childButtonRef={buttonRef}
				style={buttonStyle}
			>
				{isExporting ? (
					<Spinner
						shouldOverlay={false}
						shouldUseAbsolutePositioning={false}
						spinnerSize="spinner-container-1-4th-size"
						useMarginsForCorrectingCentering={false}
					/>
				) : (
					<span className="color-black">{localized('DownloadReport')}</span>
				)}
			</BiButton>
		</WhiteCard>
	);
});

const getDatePickers = (
	startDate: Date | undefined,
	onStartDateBeforeOptimizeChange: (e: OnCalendarChangeType) => void,
	endDate: Date | undefined,
	onEndDateBeforeOptimizeChange: (e: OnCalendarChangeType) => void,
	startDateMin?: Date
) => {
	return (
		<table>
			<tbody>
				<tr>
					<td className="margin-right-2">{localized('StartDate')}</td>
					<td>
						<BiCalendar
							className="p-inputtext-background-color-metal-grey"
							value={startDate}
							onChange={onStartDateBeforeOptimizeChange}
							readOnlyInput={true}
							minDate={startDateMin}
							maxDate={endDate}
						/>
					</td>
				</tr>
				<tr>
					<td className="margin-right-2">{localized('EndDate')}</td>
					<td>
						<BiCalendar
							className="p-inputtext-background-color-metal-grey"
							value={endDate}
							onChange={onEndDateBeforeOptimizeChange}
							readOnlyInput={true}
							minDate={startDate}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};
