import { isNullOrWhitespace } from 'view/shared/components/string-helpers';

export const capitalize = (s: string) => {
	if (typeof s !== 'string') return '';

	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stringOrDefault = (value: string | null | undefined, defaultValue = '') =>
	valueOrDefault(value, defaultValue);

export const valueOrDefault = <T>(value: T, defaultValue: NonNullable<T>) => {
	switch (typeof value) {
		case 'string':
			return !isNullOrWhitespace(value) ? value : defaultValue;
		default:
			return value ?? defaultValue;
	}
};

export const toCamelCase = (str: string) => {
	return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
		if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
		return index === 0 ? match.toLowerCase() : match.toUpperCase();
	});
};

export const fuzzySearchOccurrences = (searchFor: string, searchIn: string) => {
	if (searchFor == '') return 1;

	var words = searchIn.split(/,| |-|\//);
	var searchWords = searchFor.split(/,| |-|\//).filter(sw => sw != '');

	var count = 0;
	searchWords.forEach(sw => {
		words.forEach(w => {
			// Match whole word
			if (w === sw) count += 2;
			// Match beginning of word
			else if (w.indexOf(sw) == 0) count++;
		});
	});

	return count;
};
