import React from 'react';
import { localized } from 'state/i18n';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { AppState } from 'state/store';
import { Dispatch } from 'redux';
import './tile-menu.scss';

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({

});

type ComponentProps = {
    selectedView: GraphTableMenuEnum,
    onMenuViewChange: (newView: GraphTableMenuEnum) => void,
    onFilterClick?: () => void,
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps;

type State = { showDialog: boolean }

export enum GraphTableMenuEnum {
    graph,
    table,
}

class FilterGraphTableMenu extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { showDialog: false }
    }

    public render() {
        const tableClassname: string = this.props.selectedView === GraphTableMenuEnum.table ? ' menu-item-selected' : ' menu-item-not-selected';
        const graphClassname: string = this.props.selectedView === GraphTableMenuEnum.graph ? ' menu-item-selected' : ' menu-item-not-selected';
        return (
            <>
                <div className="flex-1 flex-container">
                    {this.props.onFilterClick &&
                        <Button
                            title={localized('apply-filter')}
                            className={"p-button-custom bi-button-icon-only"}
                            icon="pi pi-filter"
                            onClick={this.props.onFilterClick}
                        />}
                </div>
                <div className="flex-1 flex-center-row graph-table-menu">
                    <Button
                        title={localized('Table')}
                        className={"p-button-custom bi-button-icon-only" + tableClassname}
                        icon="pi pi-table"
                        onClick={this.onTableClick}
                    />
                    <Button
                        title={localized('Graph')}
                        className={"p-button-custom bi-button-icon-only" + graphClassname}
                        icon="pi pi-chart-bar"
                        onClick={this.onGraphClick}
                    />
                </div>
            </>
        );
    }

    private onTableClick = () => {
        this.props.onMenuViewChange(GraphTableMenuEnum.table);
    }

    private onGraphClick = () => {
        this.props.onMenuViewChange(GraphTableMenuEnum.graph);
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterGraphTableMenu);
