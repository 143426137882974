import { RolesType } from 'api/api';
import { Action } from 'redux';
import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';
import { isType } from 'typescript-fsa';
import { upsertArrayOfDataMappedToIdString } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { removeGroup, removeValueInUserGroupPmsArray, updateGroup, upsertGroup, upsertValueInUserGroupPmsArray } from './reducer-helpers-groups';
import { GroupsState } from './types';

export const initialState: GroupsState = {
	groups: [],
	adminGroups: [],
	countryAdminGroups: [],
	usersThatIOwn: [],
	superAdminGroups: [],
	isLoading: false,
};

export function groupsReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.SetCurrentGroupAction)) {
		return {
			...state,
			currentGroupId: action.payload,
		};
	} else if (isType(action, actions.GetUserGroupsChildHierarchyAction.done)) {
		return {
			...state,
			groups: upsertArrayOfDataMappedToIdString(action.payload.params, action.payload.result, state.groups),
		};
	} else if (isType(action, actions.GetUsersAdminGroupsChildHierarchyAction.done)) {
		return {
			...state,
			adminGroups: upsertArrayOfDataMappedToIdString(action.payload.params, action.payload.result, state.adminGroups),
		};
	} else if (isType(action, actions.GetUsersCountryAdminGroupsChildHierarchyAction.done)) {
		return {
			...state,
			countryAdminGroups: upsertArrayOfDataMappedToIdString(action.payload.params, action.payload.result, state.countryAdminGroups),
		};
	}
	 else if (isType(action, actions.GetUsersThatIOwnAction.done)) {
		return {
			...state,
			// Sort users based on userId
			usersThatIOwn: action.payload.result.sort((user1, user2) => {
				if (user1.userId! < user2.userId!) {
					return -1;
				} else {
					return 1;
				}
			}),
		};
	} else if (isType(action, actions.DeleteUserGroupPermissionsAction.done)) {
		return {
			...state,
			usersThatIOwn: removeValueInUserGroupPmsArray(
				state.usersThatIOwn,
				action.payload.result,
				action.payload.params
			),
		};
	} else if (
		isType(action, actions.SetUserPermissionsAction.done)
	) {
		return {
			...state,
			usersThatIOwn: upsertValueInUserGroupPmsArray(
				state.usersThatIOwn,
				action.payload.result,
				action.payload.params.previousGroupId
			),
		};
	} else if (
		isType(action, actions.CreateUserWithGroupPermissionAction.done) 
	) {
		return {
			...state,
			usersThatIOwn: upsertValueInUserGroupPmsArray(
				state.usersThatIOwn,
				action.payload.result,
				0
			),
		};
	} else if (isType(action, actions.GetUsersSuperAdminGroupsChildHierarchyAction.done)) {
		return {
			...state,
			superAdminGroups: upsertArrayOfDataMappedToIdString(
				action.payload.params,
				action.payload.result,
				state.superAdminGroups
			),
		};
	} else if (isType(action, actions.CreateChildGroupAction.done)) {
		return {
			...state,
			superAdminGroups: upsertGroup(state.superAdminGroups, action.payload.result, RolesType.SuperAdmin),
			countryAdminGroups: upsertGroup(state.countryAdminGroups, action.payload.result, RolesType.CountryAdmin),
			adminGroups: upsertGroup(state.adminGroups, action.payload.result, RolesType.Admin),
			groups: upsertGroup(state.groups, action.payload.result, RolesType.BasicUser),
		};
	} else if (isType(action, actions.EditGroupAction.done)) {
		return {
			...state,
			superAdminGroups: updateGroup(state.superAdminGroups, action.payload.result),
			countryAdminGroups: updateGroup(state.countryAdminGroups, action.payload.result),
			adminGroups: updateGroup(state.adminGroups, action.payload.result),
			groups: updateGroup(state.groups, action.payload.result),
		};
	} else if (isType(action, actions.DeleteGroupAction.done)) {
		return {
			...state,
			superAdminGroups: removeGroup(state.superAdminGroups, action.payload.params),
			countryAdminGroups: removeGroup(state.countryAdminGroups, action.payload.params),
			adminGroups: removeGroup(state.adminGroups, action.payload.params),
			groups: removeGroup(state.groups, action.payload.params),
		};
	} else if (isType(action, actions.EditGroupDebtorAction.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, actions.EditGroupDebtorAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));

		return {
			...state,
			isLoading: false,
			superAdminGroups: updateGroup(state.superAdminGroups, action.payload.result),
			countryAdminGroups: updateGroup(state.countryAdminGroups, action.payload.result),
			adminGroups: updateGroup(state.adminGroups, action.payload.result),
			groups: updateGroup(state.groups, action.payload.result),
		};
	} else if (isType(action, actions.EditGroupDebtorAction.failed)) {
		return {
			...state,
			isLoading: false,
		};
	}

	return state;
}
