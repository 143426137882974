import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
	'GET_PARTNER_AREA_ROOT_FOLDER',
	'GET_PARTNER_AREA_FILE_TREE',
	'GET_PARTNER_AREA_FILE_TREE_BY_PATH',
	'GET_PARTNER_AREA_PREVIEW_FILE',
	'DELETE_PARTNER_AREA_FILE',
	'DELETE_PARTNER_AREA_FOLDER'
);
