import {
	CoordinateDto,
	DashboardBase,
	DashboardTileType,
	FilterDto,
	ICoordinateDto,
	ILocationTileDto,
	ILocationTileSettingsDto,
	IStaticMapMachineDto,
	LocationTileSettingsDto,
	MapBounds,
	StaticMapMachineDto,
} from 'api/api';
import { Guid } from 'guid-typescript';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from 'state/store';
import CreateTile from './create-tile';
import './create-tile.scss';
import { localizedDynamic } from 'state/i18n';
import { createStaticMapLocation } from 'state/ducks/dashboard/operations';

const mapDispatchToProps = (dispatch: Dispatch) => ({
	createStaticMapLocation: async (location: ILocationTileSettingsDto) =>
		(await createStaticMapLocation(location))(dispatch),
});

const mapStateToProps = (state: AppState) => {
	return {
		mapZoomLevel: state.locationReducer.mapZoomLevel,
		mapCenter: state.locationReducer.mapCenter,
		machinesWithLocation: state.locationReducer.machinesWithLocation,
		mapBounds: state.locationReducer.mapBounds,
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		appliedFilter: state.filterSearchReducer.appliedFilters,
	};
};

interface PropsFromParent {
	onTileAdded?(): void;
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & PropsFromParent;

type State = {
	location: ILocationTileDto;
	visibleMachines?: IStaticMapMachineDto[];
	tileName?: string;
};

class CreateLocationTile extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			location: {
				dashboardBase: DashboardBase.fromJS({
					id: Guid.EMPTY,
					type: DashboardTileType.EditableStaticMap,
					x_location: 0,
					y_location: 0,
					isDeleted: false,
					createdDate: new Date(),
					updatedDate: new Date(),
				}),
				locationTileSettings: LocationTileSettingsDto.fromJS({
					id: Guid.EMPTY,
					zoomLevel: this.props.mapZoomLevel ?? 0,
					mapCenter: this.props.mapCenter as CoordinateDto,
					mapBounds: (this.props.mapBounds as MapBounds) ?? undefined,
					filter: this.props.appliedFilter as FilterDto,
					selectedMachines: this.props.selectedMachines,
				}),
				staticMapMachines: [],
			},
		};
	}

	public componentDidMount() {
		this.updateMapStaticMap();
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.mapBounds !== prevProps.mapBounds ||
			this.props.appliedFilter !== prevProps.appliedFilter ||
			this.props.mapZoomLevel !== prevProps.mapZoomLevel ||
			this.props.mapCenter !== prevProps.mapCenter ||
			this.props.selectedMachines !== prevProps.selectedMachines
		) {
			this.updateMapStaticMap();
		}
	}

	public render() {
		return (
			<CreateTile
				onTileAdded={this.onAddTile}
				tile={this.state.location}
				tileNamePlaceholder={localizedDynamic('NameOfLocationTile')}
				tileType={DashboardTileType.EditableStaticMap}
				tileName={this.state.tileName}
				onTileNameChanged={this.onTileNameChanged}
				btnDisabled={!this.state.tileName}
			/>
		);
	}

	private updateMapStaticMap = () => {
		if (this.props.mapBounds && this.props.mapCenter && this.props.mapZoomLevel) {
			const locationDtoCopy = { ...this.state.location.locationTileSettings };
			locationDtoCopy.zoomLevel = this.props.mapZoomLevel;
			locationDtoCopy.mapCenter = this.props.mapCenter as CoordinateDto;
			locationDtoCopy.filter = this.props.appliedFilter as FilterDto;
			locationDtoCopy.selectedMachines = this.props.selectedMachines;
			locationDtoCopy.mapBounds = this.props.mapBounds as MapBounds;

			const visibleMachines = this.getVisibleMachines();

			this.setState(prevState => ({
				...prevState,
				location: {
					...prevState.location,
					locationTileSettings: locationDtoCopy as LocationTileSettingsDto,
					staticMapMachines: visibleMachines as StaticMapMachineDto[],
				},
			}));
		}
	};

	private getVisibleMachines = (): IStaticMapMachineDto[] => {
		if (this.props.machinesWithLocation) {
			const visibleMachines: IStaticMapMachineDto[] = [];

			for (const machine of this.props.machinesWithLocation) {
				if (machine.latitude && machine.longitude) {
					const markerPosition: ICoordinateDto = {
						latitude: parseFloat(machine.latitude),
						longitude: parseFloat(machine.longitude),
					};

					const mapBounds = this.props.mapBounds;

					if (mapBounds && mapBounds.east && mapBounds.north && mapBounds.south && mapBounds.west) {
						if (
							mapBounds.west <= markerPosition.latitude &&
							markerPosition.latitude <= mapBounds.east &&
							mapBounds.north >= markerPosition.longitude &&
							markerPosition.longitude >= mapBounds.south
						) {
							visibleMachines.push({
								isOnline: machine.isOnline,
								fillLevel: machine.fillLevel,
								hasModem: machine.hasModem,
								latitude: markerPosition.latitude,
								longitude: markerPosition.longitude,
							});
						}
					}
				}
			}

			return visibleMachines;
		}
		return [];
	};

	private onAddTile = () => {
		const locationDtoCopy = { ...this.state.location.locationTileSettings };
		locationDtoCopy.locationName = this.state.tileName;
		locationDtoCopy.mapBounds = this.props.mapBounds as MapBounds;

		this.props.createStaticMapLocation(locationDtoCopy);

		if (this.props.onTileAdded) {
			this.props.onTileAdded();
		}
	};

	private onTileNameChanged = (e: React.FormEvent<HTMLInputElement>) => {
		let text: string | undefined = e.currentTarget.value;
		if (text === '') {
			text = undefined;
		}
		this.setState({ tileName: text });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocationTile);
