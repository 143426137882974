import mimeTypes from 'mime-types';

export const MimeTypes = {
	Extensions: {
		xlsx: 'xlsx'
	},
	ContentType: {
		xlsx: mimeTypes.lookup('xlsx') || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	}
} as const;
