import { Action } from 'redux';
import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';
import { isType } from 'typescript-fsa';
import { deleteValueInArrayCustomId, upsertValueInArrayCustomId } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { XtelTrackerState } from './types';

export const initialState: XtelTrackerState = {
	xtelTrackers: [],
};

export function xtelTrackerReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.CreateXtelTrackerAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			xtelTrackers: upsertValueInArrayCustomId(
				state.xtelTrackers,
				action.payload.result,
				tracker => tracker.unitId
			),
		};
	} else if (isType(action, actions.GetXtelTrackerAction.done)) {
		return {
			...state,
			xtelTrackers: upsertValueInArrayCustomId(
				state.xtelTrackers,
				action.payload.result,
				tracker => tracker.unitId
			),
		};
	} else if (isType(action, actions.GetXtelTrackersAction.done)) {
		return {
			...state,
			xtelTrackers: action.payload.result,
		};
	} else if (isType(action, actions.UpdateXtelTrackerIdAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			xtelTrackers: upsertValueInArrayCustomId(
				state.xtelTrackers,
				action.payload.result,
				tracker => tracker.unitId
			),
		};
	} else if (isType(action, actions.DeleteXtelTrackerAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			xtelTrackers: deleteValueInArrayCustomId(
				state.xtelTrackers,
				action.payload.params.unitId,
				tracker => tracker.unitId
			),
		};
	}

	return state;
}
