import { AppState } from 'state/store';
import * as React from 'react';
import { connect } from 'react-redux';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { Dispatch } from 'redux';
import { localized } from 'state/i18n';

const mapStateToProps = (state: AppState) => ({
	machinesWithLocation: state.locationReducer.machinesWithLocation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class LocationsShown extends React.PureComponent<Props> {
	public render() {
		let itemCount = 0;
		if (this.props.machinesWithLocation) {
			itemCount = this.props.machinesWithLocation.filter(
				(m => m.latitude && Math.ceil(parseFloat(m.latitude)) !== 0 && (m.longitude && Math.ceil(parseFloat(m.latitude))))
			).length;
		}

		return (
			<div className="flex-wrap flex-center-row">
				<span className="white-space-nowrap">{itemCount + ' ' + localized('UnitsShown')}</span>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LocationsShown);
