import { Button } from 'primereact/button';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setFilterEndDate, setFilterHomeLocation, setFilterStartDate } from 'state/ducks/filter-date-search/operations';
import { filterDateStringsKeys } from 'state/ducks/filter-date-search/types';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import BiCalendar from 'view/shared/components/bi-calendar/bi-calendar';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	const datesFromProp = state.filterDateSearchReducer.filterDateStrings[ownProps.idOfFilterDateBarParent];
	const checkboxFromProp = state.filterDateSearchReducer.homeLocationChecked;

	return {
		startDate: datesFromProp && datesFromProp.startDate,
		endDate: datesFromProp && datesFromProp.endDate,
		filterHomeLocation: checkboxFromProp,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	setFilterStartDate: (id: filterDateStringsKeys, input: Date) => setFilterStartDate(id, input)(dispatch),
	setFilterEndDate: (id: filterDateStringsKeys, input: Date) => setFilterEndDate(id, input)(dispatch),
	setFilterHomeLocation: (id: filterDateStringsKeys, input: boolean) => setFilterHomeLocation(id, input)(dispatch),
});

interface AppProps {
	idOfFilterDateBarParent: filterDateStringsKeys;
	handleFilterByDates: (startDate?: Date, endDate?: Date) => Promise<void>;
	minStartDate?: Date;
	initialStartDate?: Date;
	initialEndDate?: Date;
	initialFilterOnHomeLocationChanged?: boolean;
}
interface State {
	filterOnHomeLocationChanged: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

class FilterDateBarGPSHistory extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			filterOnHomeLocationChanged: false,
		};

		if (this.props.initialStartDate) {
			this.props.setFilterStartDate(this.props.idOfFilterDateBarParent, this.props.initialStartDate);
		}

		if (this.props.initialEndDate) {
			this.props.setFilterEndDate(this.props.idOfFilterDateBarParent, this.props.initialEndDate);
		}

		if (this.props.initialFilterOnHomeLocationChanged) {
			this.props.setFilterHomeLocation(
				this.props.idOfFilterDateBarParent,
				this.props.initialFilterOnHomeLocationChanged
			);
		}
	}

	public handleChangeStartDate = (e: { originalEvent: Event; value: Date }) => {
		this.props.setFilterStartDate(this.props.idOfFilterDateBarParent, e.value);
	};

	public handleChangeEndDate = (e: { originalEvent: Event; value: Date }) => {
		this.props.setFilterEndDate(this.props.idOfFilterDateBarParent, e.value);
	};

	public handleHomeLocationCheckboxToggled = (e: { checked: boolean; value: any }) => {
		this.setState({ filterOnHomeLocationChanged: e.value });
		this.props.setFilterHomeLocation('history', e.checked);
	};

	public handleClickSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (!this.props.startDate && !this.props.endDate) {
			// What, no dates set? Then no filter for you!
			return;
		}

		await this.props.handleFilterByDates(this.props.startDate, this.props.endDate);
	};

	public render() {
		const { initialStartDate, initialEndDate, minStartDate, startDate, endDate } = this.props;
		const currStartDate = startDate || initialStartDate;
		const currEndDate = endDate || initialEndDate;

		return (
			<div className="flex-direction-row flex-center-column flex-wrap">
				<span className="margin-right-2">{localized('StartDate')}</span>
				<BiCalendar
					className="p-inputtext-background-color-metal-grey"
					value={currStartDate}
					onChange={this.handleChangeStartDate}
					readOnlyInput={true}
					minDate={minStartDate}
					maxDate={this.props.endDate}
				/>

				<span className="margin-left-40px margin-right-2">{localized('EndDate')}</span>
				<BiCalendar
					className="p-inputtext-background-color-metal-grey"
					value={currEndDate}
					onChange={this.handleChangeEndDate}
					readOnlyInput={true}
					minDate={currStartDate}
				/>
				<div className="margin-left-40px">
					<Button
						className="p-button-background-color-metal-grey"
						label={localized('Search')}
						onClick={this.handleClickSearch}
					/>
				</div>
				<div className="flex-direction-row flex-center-column flex-wrap">
					<div className="margin-left-40px">
						<BiCheckbox
							label={localized('homeLocation')}
							onChange={this.handleHomeLocationCheckboxToggled}
							checked={this.props.filterHomeLocation}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterDateBarGPSHistory);
