import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
import { SetFilterStartDateAction, SetFilterEndDateAction, SetFilterHomeLocation } from './actions';
import { filterDateStringsKeys } from './types';

export function setFilterStartDate(id: filterDateStringsKeys, input: Date) {
    return (dispatch: Dispatch<Action<{}>>) => {
        dispatch(SetFilterStartDateAction({ id, input }));
    }
}

export function setFilterEndDate(id: filterDateStringsKeys, input: Date) {
    return (dispatch: Dispatch<Action<{}>>) => {
        dispatch(SetFilterEndDateAction({ id, input }));
    }
}

export function setFilterHomeLocation(id: filterDateStringsKeys, input: boolean)
{
    return (dispatch: Dispatch<Action<{}>>) => {
        dispatch(SetFilterHomeLocation({ id, input }));
    }
}
