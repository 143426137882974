import { DashboardLast10BalesDto, ILast10BalesTileDto } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import { roundToDecimals } from 'utilities/number-helpers';
import { BiBarChart } from 'view/components/bi-bar-chart/bi-bar-chart';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';

interface PropsFromParent {
    tile: ILast10BalesTileDto;
}

type Props = PropsFromParent;

export class Last10BalesTile extends React.PureComponent<Props> {
    makeLabel = (bale: DashboardLast10BalesDto) => {
        let label: string = `${localized('unitId')}: ${bale.unitId}\n`;
        label += `${localized('unitModel')}: ${bale.unitModel}\n`;
        label += `${localized('EndDate')}: ${getDateToString(bale.date, DateFormatterModes.withMinutes)}`;
        return label;
    }

    getGraphData = (): [number[], string[]] => {
        const labels: string[] = [];
        const dataSets: number[] = [];

        if (this.props.tile.balesData) {

            for (let i = 0; i < this.props.tile.balesData.length; i++) {
                const bale = this.props.tile.balesData[i];
                bale.weight = roundToDecimals(bale.weight, 2);

                labels.push(this.makeLabel(bale));
                dataSets.push(bale.weight);
            }
        }

        return [dataSets, labels];
    }

    public render() {
        let [data, labels] = this.getGraphData();

        if (!data)
            return <></>

        return (
            <>
                <div className="border-bottom-grey margin-bottom-20px" />
                <div className="content-section implementation">
                    <BiBarChart
                        data={data}
                        labels={labels}
                        aspectRatio={2}
                        yAxisLabel={`${localized("Weight")} (kg)`}
                        showLegend={false}
                        dataUnit={"kg"}
                        animationDuration={0}
                    />
                </div>
            </>
        );
    }
}
