import { IIoTHubDuplicateDecommissionDto } from 'api/api';
import { PageState, Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDuplicateIotHubDecommissionsOperation } from 'state/ducks/duplicate-iothub-decommission/operations';
import { localized } from 'state/i18n';
import { useAppSelector } from 'utilities/hooks';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import { DecommissionItem } from './decommission-item';
import './duplicate-iothub-units-decommission.scss';
import { Spinner } from 'view/components/spinner/spinner';

export interface State {
	paginatorFirst: number;
	paginatorPageCount?: number;
	paginatorCurrentPage: number;
	paginatorRows: number;
}

export const DuplicateIotHubUnitsDecommisison: React.FC<{}> = props => {
	const dispatch = useDispatch();
	const isLoading = useAppSelector(state => state.duplicateIotHubDecommissionReducer.isLoading);
	const decommissionObjects = useAppSelector(state => state.duplicateIotHubDecommissionReducer.decommissionObjects);

	useEffect(() => {
		dispatch(getDuplicateIotHubDecommissionsOperation());
	}, [dispatch]);

	const [paginator, setPaginator] = useState<PageState>({ first: 0, rows: 6, page: 0, pageCount: 0 });

	const getPaginationRows = (): JSX.Element[] => {
		const slicedDecommissionObjects = decommissionObjects.slice(paginator.first, paginator.first + paginator.rows);

		return getRows(slicedDecommissionObjects);
	};

	const getRows = (slicedDecommissionObjects: IIoTHubDuplicateDecommissionDto[]) => {
		return slicedDecommissionObjects.map(obj => {
			return <DecommissionItem decommissionObj={obj} key={`${obj.id}-decommission`} />;
		});
	};

	const getHeaderLabels = () => (
		<div className="content-container-decommission">
			<b>{localized('SerialNumber')}</b>
			<b>{localized('active')}</b>
			<b>{localized('Device')}</b>
			<b>{localized('Device')}</b>
			<div />
		</div>
	);

	return isLoading ? (
		<Spinner
			shouldOverlay={false}
			spinnerSize="spinner-container-half-size"
			useMarginsForCorrectingCentering={false}
		/>
	) : !decommissionObjects.length ? (
		<span>{localized('NoData')}</span>
	) : (
		<div className="margin-bottom-20px flex-container">
			<BiLabelContent containerClassName="flex-fill-width">
				{getHeaderLabels()}

				{getPaginationRows()}

				<div className="flex-end-row">
					<Paginator
						first={paginator.first}
						rows={paginator.rows}
						totalRecords={decommissionObjects.length}
						onPageChange={setPaginator}
						template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
					/>
				</div>
			</BiLabelContent>
		</div>
	);
};
