import React from 'react';
import { Table, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { Spinner } from 'view/components/spinner/spinner';
import { getNoDataMessage } from 'utilities/table-utilities';
import './bi-no-data.scss';
import { AppState } from 'state/store';
import { connect } from 'react-redux';
import { TableId } from 'api/api';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	const loadingMachines = state.machinesReducer.loading;
	const loadingReports = state.reportReducer.loading;
	const loadingHistories = !state.historyTableSettingsReducer.loaded;

	const getCorrectLoadingStage = () => {
		switch (ownProps.tableId) {
			case TableId.Machines: {
				return loadingMachines;
			}
			case TableId.Histories: {
				return loadingHistories;
			}
			case TableId.Reports: {
				return loadingReports;
			}
		}
	};

	const loading = getCorrectLoadingStage();
	return {
		loading,
	};
};

interface AppProps {
	isLoading?: boolean;
	tableId?: TableId;
	noDataCellProps: Table.NoDataCellProps;
}

type Props = ReturnType<typeof mapStateToProps> & AppProps;

class BiNoData extends React.PureComponent<Props> {
	public render() {
		const content =
			this.props.loading || this.props.isLoading ? (
				<td colSpan={this.props.noDataCellProps.colSpan} className="py-5">
					<div className="bi-center-across-columns padding-vertical-10px">
						<Spinner shouldOverlay={false} shouldUseAbsolutePositioning={true} />
					</div>
				</td>
			) : (
				<VirtualTable.NoDataCell {...this.props.noDataCellProps} getMessage={getNoDataMessage} />
			);

		return content;
	}
}

export default connect(mapStateToProps)(BiNoData);
