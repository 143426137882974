import { IDeviceIdWeightIdDto } from 'api/api';
import { PageState, Paginator } from 'primereact/paginator';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getDeviceIdWeightIds } from 'state/ducks/adminstration/operations';
import { localized } from 'state/i18n';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import './weight-unit-overview.scss';


type Props = {};


export const WeightUnitOverview: React.FC<Props> = React.memo((props: Props) => {

    const dispatch = useAppDispatch();
    const deviceWeights: IDeviceIdWeightIdDto[] = useAppSelector(state => state.adminstrationReducer.deviceIdWeightIdDto)

    const [paginatorFirst, setPaginatorFirst] = useState(0);
    const [paginatorCurrentPage, setPaginatorCurrentPage] = useState(0);
    const [paginatorRows, setPaginatorRows] = useState(12);
    const [totalRows, setTotalRows] = useState(0);
    const [paginatorPageCount, setPaginatorPageCount] = useState(0);



    useEffect(() => {
        // Constructor
        (getDeviceIdWeightIds())(dispatch)
    }, [dispatch])

    const itemGenerator = (unitid: string, weightid: string) => {
        const unit = <span>{unitid}</span>;
        const weight = <span>{weightid}</span>;

        return (
            <div
                key={unitid + weightid}
                className={`margin-bottom-10px bi-label-content-item-box-shadow bi-label-content-item-lg weight-unit-grid min-height-50px`}
            >
                {unit}
                {weight}
            </div >
        )
    }

    const getWeightToUnits = (): JSX.Element[] => {
        let rows: JSX.Element[] = deviceWeights
            //Sort by deviceid. If equal, sort by weightid
            .sort((dw1, dw2) => {
                if (!dw1.serialNumber || !dw2.serialNumber) return 0;
                if (dw1.serialNumber !== dw2.serialNumber)
                    return dw1.serialNumber > dw2.serialNumber ? 1 : -1;
                if (!dw1.weightSerialNumber || !dw2.weightSerialNumber) return 0;
                return dw1.weightSerialNumber > dw2.weightSerialNumber ? 1 : -1;
            })
            .map(dw => dw.serialNumber && dw.weightSerialNumber ? itemGenerator(dw.serialNumber, dw.weightSerialNumber) : undefined)
            .filter((e: JSX.Element | undefined): e is JSX.Element => !!e);

        if (totalRows !== rows.length) setTotalRows(rows.length);

        return rows.slice(paginatorFirst, paginatorFirst + paginatorRows);
    }

    const onPageChangeHandler = (e: PageState) => {
        setPaginatorFirst(e.first);
        setPaginatorRows(e.rows);
        setPaginatorCurrentPage(e.page);
        setPaginatorPageCount(e.pageCount);
    };

    const paginatedrows: JSX.Element[] = getWeightToUnits();

    return (
        <div id="weight-unit-overview">
            <BiLabelContent containerClassName="flex-fill-width">
                <div className="weight-unit-grid padding-left-20px padding-right-20px">
                    <b>{localized('unitId')}</b>
                    <b>{localized('weightId')}</b>
                </div>

                <div>
                    {paginatedrows}
                </div>

                <div className="flex-end-row">
                    <Paginator
                        first={paginatorFirst}
                        rows={paginatorRows}
                        totalRecords={totalRows}
                        onPageChange={onPageChangeHandler}
                        template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    ></Paginator>
                </div>
            </BiLabelContent>
        </div>
    );
});