import { IPressureChartDto, IUnitCycleDto, IUnitHistoryInfoDto, IUnitStateHistoryDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('machine-history');

export const ClearUnitHistoryAction = actionCreator<string>(constants.CLEAR_MACHINE_HISTORY);
export const GetUnitHistoryFilteredAction = actionCreator.async<{ serialNumber: string }, IUnitStateHistoryDto[]>(
	constants.GET_MACHINE_HISTORY_FILTERED
);
export const GetPressureChartAction = actionCreator.async<{ serialNumber: string; startDate: Date }, IPressureChartDto>(
	constants.GET_PRESSURE_CHART
);
export const GetUnitCyclesAction = actionCreator.async<
	{ unitSerialNumber: string; startDate: Date; endDate: Date },
	IUnitCycleDto[]
>(constants.GET_UNIT_CYCLES);

export const ResetUnitCyclesAction = actionCreator(constants.RESET_UNIT_CYCLES);

export const GetUnitHistoryInfoAction = actionCreator.async<{ serialNumber: string }, IUnitHistoryInfoDto>(
	constants.GET_MACHINE_HISTORY_INFO
);
export const UpdateUnitHistoryAction = actionCreator.async<
	{ serialNumber: string; historyRow: IUnitStateHistoryDto },
	IUnitStateHistoryDto
>(constants.UPDATE_MACHINE_HISTORY);
