import bramidanTerms from 'assets/terms-and-conditions/bramidan_terms_and_conditions_V1.pdf';
import prestoTerms from 'assets/terms-and-conditions/presto_terms_and_conditions_V1.pdf';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { upsertUserSettings } from 'state/ducks/user-settings/operations';
import { localized } from 'state/i18n';
import { IsBrandBramidan } from 'utilities/brand-utils';
import { useAppSelector } from 'utilities/hooks';
import { Spinner } from 'view/components/spinner/spinner';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiDialog from 'view/shared/components/dialogs/bi-dialog/bi-dialog';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';

const termsAndConditionsPath = IsBrandBramidan ? bramidanTerms : prestoTerms;

interface Props {}

export const TermsAndConditions: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();
	const userSettings = useAppSelector(state => state.userSettingReducer.userSettings);

	const [showConfirmSpinner, setShowConfirmSpinner] = useState(false);

	const handleConfirm = async () => {
		setShowConfirmSpinner(true);
		(await upsertUserSettings({ ...userSettings, agreedToTermsAndConditions: true }))(dispatch);
	};

	return isNullOrWhitespace(termsAndConditionsPath) ? null : (
		<BiDialog modal={true} showHeader={false} dismissableMask={false} onHide={() => {}} visible={true}>
			<div className="flex-space-between-row">
				<div className="text-wrapper">
					<h2 className="text-bold title">{localized('AgreeToTermsAndConditions')}</h2>
				</div>
			</div>
			<object data={termsAndConditionsPath} type="application/pdf" width="1000" height="700">
				<p>
					Unable to display PDF file. <a href={termsAndConditionsPath}>Download</a> instead.
				</p>
			</object>
			<div className="flex-end-row margin-top-10px">
				<BiButton onClick={handleConfirm} containerTheme="slim-with-rounded-corners" colorTheme={'org-green'}>
					{showConfirmSpinner ? (
						<Spinner
							shouldOverlay={false}
							spinnerSize="spinner-container-1-4th-size"
							useMarginsForCorrectingCentering={false}
						/>
					) : (
						localized('Confirm')
					)}
				</BiButton>
			</div>
		</BiDialog>
	);
});
