import { ErrorAndSeverity, StatusSeverity } from 'api/api';
import { ErrorOrReadonlyErrorArray } from './constants';

/**
 * @param errorIds A list of error ids. MUST have at least one error.
 */
export function getMostPrioritizedError(errorIds: readonly ErrorAndSeverity[]): string {
	// Failures are prioritized by their numerical value. Ex. error 3 trumps error 7 because 3 < 7.
	// sort() mutates the original array, so we use it on a copy gotten by calling slice().
	const sortedErrorIds = errorIds.slice().sort(sortNumbersInIncreasingOrder);

	const errorIfAny = getMostPrioritized(sortedErrorIds, StatusSeverity.Error);
	if (errorIfAny !== undefined) {
		return errorIfAny.readableError!;
	}

	const warningIfAny = getMostPrioritized(sortedErrorIds, StatusSeverity.Warning);
	if (warningIfAny !== undefined) {
		return warningIfAny.readableError!;
	}

	const messageIfAny = getMostPrioritized(sortedErrorIds, StatusSeverity.Message);
	if (messageIfAny !== undefined) {
		return messageIfAny.readableError!;
	}

	return '';
}

export function getMostPrioritized(error: ErrorOrReadonlyErrorArray, errorSeverity: StatusSeverity) {
	return Array.isArray(error)
		? error.find(val => val.severity === errorSeverity)
		: error.severity === errorSeverity
		? error
		: undefined;
}

function sortNumbersInIncreasingOrder(a: ErrorAndSeverity, b: ErrorAndSeverity): number {
	return a.errorId - b.errorId;
}
