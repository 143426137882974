import { IUnitModelDto } from 'api/api';
import { InputText } from 'primereact/inputtext';
import { PageState, Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from 'react';
import { getUnitManufacturers } from 'state/ducks/unit-manufacturer/operations';
import { getUnitModelCodes } from 'state/ducks/unit-model-code/operations';
import { deleteUnitModel } from 'state/ducks/unit-model/operations';
import { getUnitTypes } from 'state/ducks/unit-type/operations';
import { localized } from 'state/i18n';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import BiLabelContentItem from 'view/shared/components/bi-label-content/bi-label-content-item';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';
import './unit-model-editor.scss';
import { UnitModelField } from './unit-model-field';

interface AppProps {}

const rowUnitModel: IUnitModelDto = { id: 0, isUserSelectable: true, isDeletable: false, name: '' };

export const UnitModelEditor: React.FC<AppProps> = props => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		getUnitManufacturers()(dispatch);
		getUnitModelCodes()(dispatch);
		getUnitTypes()(dispatch);
	}, [dispatch]);

	const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
	const [paginatorRows, setPaginatorRows] = useState<number>(9);
	const [newlyCreatedUnitModel, setNewlyCreatedUnitModel] = useState<IUnitModelDto>({ ...rowUnitModel });
	const [searchBarText, setSearchBarText] = useState<string>('');
	const [unitModelToDelete, setUnitModelToDelete] = useState<IUnitModelDto | undefined>();

	const unitModels = useAppSelector(state => state.unitModelReducer.unitModels)
		.filter(um => isNullOrWhitespace(searchBarText) || um.name?.toLowerCase().includes(searchBarText.toLowerCase()))
		.sort((a, b) => ((a?.name?.toLowerCase() ?? '') > (b?.name?.toLowerCase() ?? '') ? 1 : -1));

	const closeDeleteDialog = () => {
		setUnitModelToDelete(undefined);
	};

	const onSearchBarTextChanged = (e: React.FormEvent<HTMLInputElement>) => {
		setSearchBarText(e.currentTarget.value);
		setNewlyCreatedUnitModel({ ...rowUnitModel });
		setPaginatorFirst(0);
	};

	const onPageChangeHandler = (e: PageState) => {
		setPaginatorFirst(e.first);
		setPaginatorRows(e.rows);
		setNewlyCreatedUnitModel({ ...rowUnitModel });
	};

	const getPaginatedUnitModelRows = (): JSX.Element[] => {
		const pageinatedUnitModel: IUnitModelDto[] = unitModels.slice(paginatorFirst, paginatorFirst + paginatorRows);
		return getUnitModelComponentRows(pageinatedUnitModel);
	};

	const deleteUnitModelButtonPushed = async (unitModel: IUnitModelDto) => {
		if (unitModel.id === 0) {
			setUnitModelToDelete(undefined);
			setNewlyCreatedUnitModel({ ...rowUnitModel });
		} else {
			setUnitModelToDelete(unitModel);
		}
	};

	const deleteModel = async () => {
		if (unitModelToDelete) await deleteUnitModel(unitModelToDelete)(dispatch);
		closeDeleteDialog();
	};

	const getUnitModelComponentRows = (unitModels: IUnitModelDto[]): JSX.Element[] => {
		return unitModels.map(unitModel => {
			const content = (
				<UnitModelField
					unitModel={unitModel}
					deleteUnitModel={deleteUnitModelButtonPushed}
					clearTempRow={() => setNewlyCreatedUnitModel({ ...rowUnitModel })}
				/>
			);
			return (
				<BiLabelContentItem
					defaultClassNames="bi-label-content-item-lg unit-model-grid"
					containerClassName="margin-bottom-10px"
					key={`${unitModel.name}${unitModel.id}`}
					label={undefined}
					content={content}
				/>
			);
		});
	};

	const getNewlyCreatedField = (): JSX.Element => {
		const content = (
			<UnitModelField
				unitModel={newlyCreatedUnitModel}
				deleteUnitModel={deleteUnitModelButtonPushed}
				clearTempRow={() => setNewlyCreatedUnitModel({ ...rowUnitModel })}
			/>
		);
		return (
			<BiLabelContentItem
				defaultClassNames="bi-label-content-item-lg unit-model-grid"
				containerClassName="margin-bottom-10px"
				label={undefined}
				content={content}
			/>
		);
	};

	const UnitModelLabels = () => (
		<div className="content-container-unit-models padding-left-20px padding-right-20px">
			<label>{localized('Name')}</label>
			<label>{localized('unitType')}</label>
			<label>{localized('manufacturer')}</label>
			<label>{localized('unitModelCode')}</label>
			<label>{localized('Containers')}</label>
			<label>{localized('IsUserSelectable')}</label>
			<label></label>
		</div>
	);

	return (
		<>
			<BiTextDialog
				visible={unitModelToDelete !== undefined}
				onHide={closeDeleteDialog}
				title={localized('Delete')}
				subtitle={localized('DeleteUnitModelConfirmMessage')}
			>
				<div className="flex-end-row margin-top-10px">
					<BiButton
						colorTheme="org-primary-grey"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={closeDeleteDialog}
					>
						{localized('Cancel')}
					</BiButton>
					<BiButton colorTheme="org-red" containerTheme="slim-with-rounded-corners" onClick={deleteModel}>
						{localized('Delete')}
					</BiButton>
				</div>
			</BiTextDialog>

			<div>
				<h5 className="text-bold">{localized('FilterUnitModelsByName')}</h5>
				<div className="p-inputgroup margin-bottom-20px">
					<div className="bi-container flex-direction-column flex-fill-width">
						<div className="flex-start-row search-bar margin-bottom-5px">
							<InputText
								placeholder={localized('Search')}
								value={searchBarText}
								onChange={onSearchBarTextChanged}
							/>
						</div>
						<div className="flex-fill-width">
							<BiLabelContent containerClassName="flex-fill-width">
								{UnitModelLabels()}
								{getPaginatedUnitModelRows()}
								{getNewlyCreatedField()}
								<div className="flex-end-row">
									<Paginator
										first={paginatorFirst}
										rows={paginatorRows}
										totalRecords={unitModels.length}
										onPageChange={onPageChangeHandler}
										template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
									></Paginator>
								</div>
							</BiLabelContent>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
