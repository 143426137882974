import { ICreateXtelUnitTrackerDto, IGetXtelUnitTrackerDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('tracker');

export const CreateXtelTrackerAction = actionCreator.async<
	{ deviceId: number; trackerId: string },
	ICreateXtelUnitTrackerDto
>(constants.CREATE_XTEL_TRACKER);

export const GetXtelTrackerAction = actionCreator.async<{ unitId: string }, IGetXtelUnitTrackerDto>(
	constants.GET_XTEL_TRACKER
);

export const GetXtelTrackersAction = actionCreator.async<void, IGetXtelUnitTrackerDto[]>(constants.GET_XTEL_TRACKERS);

export const UpdateXtelTrackerIdAction = actionCreator.async<
	{ deviceId: number; trackerId: string },
	ICreateXtelUnitTrackerDto
>(constants.UPDATE_XTEL_TRACKER_ID);

export const DeleteXtelTrackerAction = actionCreator.async<{ unitId: number }, void>(constants.DELETE_XTEL_TRACKER);
