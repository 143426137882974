import { AppState } from 'state/store';
import { DialogProps } from 'primereact/dialog';
import { ScrollPanel } from 'primereact/scrollpanel';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	resetTableSettings,
	addMachineTableSetting,
	getSettings,
	saveTablesettingWithValues,
	setCurrentTableSettingId,
	setDefaultTableSettings,
} from 'state/ducks/table-settings/operations';
import {
	basicColumns,
	ColumnGeneric,
	MachineDtoKeys,
	adminColumns,
	defaultColumns,
	TableSettingsList,
} from 'state/ducks/table-settings/types';
import { localized, localizedInterpolation } from 'state/i18n';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiCheckboxButton from 'view/shared/components/buttons/bi-checkboxbutton/bi-checkboxbutton';
import './edit-column.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faPlusSquare, faDiceD6, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { RolesType } from 'api/api';
import EditableValue from 'view/shared/components/editable-value';
import { IdDataTuple } from 'utilities/types-util';
import { defaultTableSettings } from 'state/ducks/table-settings/reducer';
import { BiButtonColorThemes } from 'view/shared/components/buttons/bi-button-base/bi-button-base';
import LockColumnsDialog from './lock-columns-dialog';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';

const mapStateToProps = (state: AppState) => ({
	tableSettingsList: state.tableSettingsReducer.tableSettingsList,
	currentTableSettingId: state.tableSettingsReducer.currentTableSettingId,
	isAdmin: state.userPermissionReducer.UserGroupPermissions.map(upg => upg.roleType).some(
		role => role <= RolesType.Admin
	),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	saveCurrentTableSetting: (id: number, columns: ColumnGeneric<MachineDtoKeys>[], name?: string) => {
		const dispatchFunc = saveTablesettingWithValues(id, columns, name);
		if (dispatchFunc) dispatchFunc(dispatch);
	},
	selectDefault: () => setCurrentTableSettingId(defaultTableSettings.tableSettings.id)(dispatch),
	deleteTableSettings: (tableSettingId: number) => resetTableSettings(tableSettingId)(dispatch),
	addMachineTableSetting: () => addMachineTableSetting()(dispatch),
	getTableSettings: async () => (await getSettings())(dispatch),
	setDefaultSettings: async () => (await setDefaultTableSettings())(dispatch),
});

function sortColumnsAlphabetically(a: ColumnGeneric<MachineDtoKeys>, b: ColumnGeneric<MachineDtoKeys>): number {
	if (a.title < b.title) {
		return -1;
	}
	if (a.title > b.title) {
		return 1;
	}
	return 0;
}
export interface EditColumnProps extends DialogProps {}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & EditColumnProps;

// sort() mutates the original array, so we use it on a copy gotten by calling slice().
const allBasicColumns = basicColumns.slice().sort(sortColumnsAlphabetically);
const allAdminColumns = adminColumns.slice().sort(sortColumnsAlphabetically);

export interface EditColumnState {
	allChecked: boolean;
	noneChecked: boolean;
	currentTableSetting: IdDataTuple<TableSettingsList>;
	isSavingData: boolean;
	showSavedialog: boolean;
	showDeleteDialog: boolean;
	showLockColumnDialog: boolean;
	saveMethod: { save: () => void; cancel: () => void };
}

class EditColumn extends React.PureComponent<Props, EditColumnState> {
	constructor(props: Props) {
		super(props);

		this.props.getTableSettings();

		this.state = {
			allChecked: false,
			noneChecked: false,
			currentTableSetting: this.getTableSettingTupleByIdOrDefault(this.props.currentTableSettingId),
			isSavingData: false,
			showSavedialog: false,
			showDeleteDialog: false,
			showLockColumnDialog: false,
			saveMethod: { save: () => {}, cancel: () => {} },
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!isEqual(prevProps.tableSettingsList, this.props.tableSettingsList)) {
			let prevCurrent = prevProps.tableSettingsList.find(
				ts => ts.tableSettings.id === this.state.currentTableSetting.id
			);
			let current = this.props.tableSettingsList.find(
				ts => ts.tableSettings.id === this.state.currentTableSetting.id
			);
			if (!prevCurrent || !current) return;
			if (prevCurrent.tableSettings.id !== current.tableSettings.id) {
				this.setState({
					currentTableSetting: this.getTableSettingTupleByIdOrDefault(current.tableSettings.id),
				});
			}
		}

		// if this.props has more table settings, a new setting has just been added - therefore we want to track the new one
		if (prevProps.tableSettingsList.length < this.props.tableSettingsList.length) {
			this.handleTableSettingChange(
				this.props.tableSettingsList[this.props.tableSettingsList.length - 1].tableSettings.id
			);
		}
	}
	private getNoneChecked = (selectedColumns: ColumnGeneric<MachineDtoKeys>[]): boolean => {
		return selectedColumns.length === 0;
	};

	private getAllChecked = (selectedColumns: ColumnGeneric<MachineDtoKeys>[]): boolean => {
		let length: number = basicColumns.length;
		if (this.props.isAdmin) length += adminColumns.length;
		return length === selectedColumns.length;
	};

	private getTableSettingTupleByIdOrDefault = (id?: number): IdDataTuple<TableSettingsList> => {
		let propTable: TableSettingsList = this.props.tableSettingsList[0];
		if (id) {
			let findFromProp = this.props.tableSettingsList.find(ts => ts.tableSettings.id === id);
			if (findFromProp) propTable = findFromProp;
		}
		return {
			id: propTable.tableSettings.id,
			data: {
				...propTable,
				columnOrder: [...propTable.columnOrder],
				columnWidths: [
					...propTable.columnWidths.map(a => {
						return { ...a };
					}),
				],
				columns: [
					...propTable.columns.map(a => {
						return { ...a };
					}),
				],
				sorting: [
					...propTable.sorting.map(a => {
						return { ...a };
					}),
				],
				tableSettings: { ...propTable.tableSettings },
			},
		};
	};

	private handleAddMachineTableSetting = () => {
		this.props.addMachineTableSetting();
	};

	private setDefaultSettings = () => {
		this.props.setDefaultSettings();
		this.props.selectDefault();
	};

	private customConfirmDialog = (
		headConfirmMessage: string,
		confirmColor: BiButtonColorThemes,
		dialogMessage: string,
		visible: boolean,
		onConfirm: () => void,
		onCancel: () => void,
		declineMessage?: string
	): JSX.Element => {
		return (
			<BiTextDialog visible={visible} onHide={onCancel} title={headConfirmMessage} subtitle={dialogMessage}>
				<div className="flex-end-row margin-top-10px">
					<BiButton
						colorTheme="org-white"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={onCancel}
					>
						{declineMessage || localized('Cancel')}
					</BiButton>
					<BiButton colorTheme={confirmColor} containerTheme="slim-with-rounded-corners" onClick={onConfirm}>
						{headConfirmMessage}
					</BiButton>
				</div>
			</BiTextDialog>
		);
	};

	private handleShowDeleteTableSettingDialog = () => {
		this.setState({ showDeleteDialog: true });
	};

	private hideDeleteDialog = () => {
		this.setState({ showDeleteDialog: false });
	};

	private deleteTableSetting = () => {
		this.hideDeleteDialog();
		this.setState({
			currentTableSetting: this.getTableSettingTupleByIdOrDefault(),
		});
		this.props.deleteTableSettings(this.state.currentTableSetting.id);
	};

	private handleCancel = () => {
		this.setState({
			currentTableSetting: this.getTableSettingTupleByIdOrDefault(),
		});
		this.props.onHide();
	};

	private handleSaveColumnsAndClose = () => {
		this.handleSaveColumns();
		this.props.onHide();
	};
	private handleSaveColumns = () => {
		if (this.state.isSavingData) {
			return;
		}

		this.setState({ isSavingData: true });

		if (this.isSelectedCategoryDefault()) {
			this.setDefaultSettings();
		} else
			this.props.saveCurrentTableSetting(
				this.state.currentTableSetting.id,
				this.state.currentTableSetting.data.columns,
				this.state.currentTableSetting.data.tableSettings.settingsName
			);

		this.setState({ isSavingData: false, showSavedialog: false });
	};

	private handleColumnCheckbox = (e: { checked: boolean; value: any }) => {
		let current = { ...this.state.currentTableSetting };
		current.data = { ...current.data };
		current.data.columns = [
			...current.data.columns.map(a => {
				return { ...a };
			}),
		];
		const allDialogColumns = [...allBasicColumns, ...allAdminColumns];
		if (e.checked) current.data.columns.push(allDialogColumns.find(allC => allC.name === e.value)!);
		else {
			current.data.columns = current.data.columns.filter(col => col.name !== e.value);
		}

		this.setState({
			currentTableSetting: current,
			allChecked: this.getAllChecked(current.data.columns),
			noneChecked: this.getNoneChecked(current.data.columns),
		});
	};

	private showingSaveDialog = (setCurrentTableToNext: () => void): boolean => {
		const propTableSetting = this.props.tableSettingsList.find(
			ts => ts.tableSettings.id === this.state.currentTableSetting.id
		);
		// return if undefined
		if (!propTableSetting) return false;

		// return if nothing has changed
		if (
			propTableSetting.columns.length === this.state.currentTableSetting.data.columns.length &&
			// the name of every column should be in both lists - as the lists has same length we need only to check one way.
			propTableSetting.columns.every(col =>
				this.state.currentTableSetting.data.columns.map(stateCol => stateCol.name).includes(col.name)
			) &&
			// return if name differ
			propTableSetting.tableSettings.settingsName ===
				this.state.currentTableSetting.data.tableSettings.settingsName
		)
			return false;

		const onSave = () => {
			this.handleSaveColumns();
			setCurrentTableToNext();
		};

		const onCancel = () => {
			setCurrentTableToNext();
			this.setState({ showSavedialog: false });
		};

		this.setState({ showSavedialog: true, saveMethod: { save: onSave, cancel: onCancel } });
		return true;
	};

	private handleTableSettingChange = (nextId: number) => {
		const setCurrentTableToNext = () => {
			const next = this.getTableSettingTupleByIdOrDefault(nextId);
			this.setState({
				currentTableSetting: next,
				noneChecked: this.getNoneChecked(next.data.columns),
				allChecked: this.getAllChecked(next.data.columns),
			});
		};
		if (this.showingSaveDialog(setCurrentTableToNext)) return;

		setCurrentTableToNext();
	};
	private handleTableSettingClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		// if default tablesetting - reset to default
		if (+e.currentTarget.id === defaultTableSettings.tableSettings.id) {
			let current = { ...defaultTableSettings };
			current.columns = [
				...current.columns.map(col => {
					return { ...col };
				}),
			];
			current.columnWidths = [
				...defaultTableSettings.columnWidths.map(a => {
					return { ...a };
				}),
			];
			current.sorting = [
				...defaultTableSettings.sorting.map(a => {
					return { ...a };
				}),
			];
			current.columnOrder = [...defaultTableSettings.columnOrder];
			this.setState({
				currentTableSetting: { id: current.tableSettings.id, data: current },
				noneChecked: this.getNoneChecked(current.columns),
				allChecked: this.getAllChecked(current.columns),
			});
			return;
		}

		this.handleTableSettingChange(+e.currentTarget.id);
	};

	private getFormattedTableSettings() {
		const radioButtonName = 'category_btn'; // makes a group of buttons act mutually-exclusive
		const categories: JSX.Element[] = this.props.tableSettingsList
			.filter(c => c.tableSettings.id !== defaultTableSettings.tableSettings.id)
			.map(c => {
				if (c.tableSettings.id === this.state.currentTableSetting.id) c = this.state.currentTableSetting.data;

				return (
					<div key={c.tableSettings.id + '_btn'} className="category-btn">
						<BiCheckboxButton
							id={c.tableSettings.id + ''}
							colorTheme="org-orange"
							name={radioButtonName}
							onChange={this.handleTableSettingClick}
							checked={c.tableSettings.id === this.state.currentTableSetting.id}
						>
							<div className="margin-right-14px">
								<FontAwesomeIcon className={'fontAwesomeStyle'} icon={faCogs} />
							</div>
							<span>{c.tableSettings.settingsName}</span>
						</BiCheckboxButton>
					</div>
				);
			});

		let isDefault =
			this.state.currentTableSetting.id === defaultTableSettings.tableSettings.id &&
			this.state.currentTableSetting.data.columns.length === defaultColumns.length &&
			this.state.currentTableSetting.data.columns
				.map(stateCol => stateCol.name)
				.every(cdc => defaultColumns.map(dc => dc.name).includes(cdc));
		categories.unshift(
			<div key={'default_custom_category_btn'} className="category-btn">
				<BiCheckboxButton
					id={defaultTableSettings.tableSettings.id + ''}
					colorTheme="org-orange"
					name={radioButtonName}
					onChange={this.handleTableSettingClick}
					checked={isDefault}
				>
					<div className="margin-right-14px">
						<FontAwesomeIcon className={'fontAwesomeStyle'} icon={faDiceD6} />
					</div>
					<span>{localized('default')}</span>
				</BiCheckboxButton>
			</div>
		);

		categories.push(
			<div key={'add_custom_category_btn'} className="category-btn">
				<BiButton colorTheme="org-white" name={radioButtonName} onClick={this.handleAddMachineTableSetting}>
					<div className="margin-right-14px">
						<FontAwesomeIcon className={'fontAwesomeStyle'} icon={faPlusSquare} />
					</div>
					<span>{localized('AddNew')}</span>
				</BiButton>
			</div>
		);

		return (
			<div className="flex-direction-column flex-fill-width">
				<h6 className="text-bold p-lg-3">{localized('Categories')}</h6>
				<div className="flex-direction-row flex-wrap margin-bottom-40">{categories}</div>
			</div>
		);
	}

	private getColumnsAsCheckboxes = (columns: ColumnGeneric<MachineDtoKeys>[]) => {
		return columns.map(col => {
			let isChecked = this.state.currentTableSetting.data.columns.map(column => column.name).includes(col.name);
			let lockOrNothing: JSX.Element | undefined =
				this.props.tableSettingsList
					.find(tsl => tsl.tableSettings.id === this.state.currentTableSetting.id)
					?.columnsLocked?.find(cl => cl === col.name) &&
				this.state.currentTableSetting.data.columns.map(column => column.name).includes(col.name) ? (
					<i className="pi pi-lock margin-right-1 lock-text" />
				) : (
					<div className="margin-right-2" />
				); //Blank space with same width as the lock to make text wrap at the same point

			return (
				<div className="p-col-12 p-md-6 p-lg-3 flex-container" key={col.name}>
					<BiCheckbox
						containerClassName="flex-container"
						inputId={col.name}
						value={col.name}
						checked={isChecked}
						onChange={this.handleColumnCheckbox}
						label={col.title}
						disabled={this.isSelectedCategoryDefault()}
					/>
					{lockOrNothing}
				</div>
			);
		});
	};

	private saveCustomCategoryName = (value: string) => {
		let current = { ...this.state.currentTableSetting };
		current.data = { ...current.data };
		current.data.tableSettings = { ...current.data.tableSettings };
		current.data.tableSettings.settingsName = value;
		this.setState({ currentTableSetting: current });
	};

	private isSelectedCategoryDefault = () => {
		return this.state.currentTableSetting.id === defaultTableSettings.tableSettings.id;
	};

	private handleHideLockColumsDialog = () => {
		this.setState({ showLockColumnDialog: false });
	};
	private handleShowLockColumnDialog = () => {
		this.setState({ showLockColumnDialog: true });
	};

	private getExtraCheckboxes = () => {
		let changeText: JSX.Element | undefined;

		if (this.state.currentTableSetting.id !== defaultTableSettings.tableSettings.id)
			changeText = (
				<div className=" margin-left-auto flex-center-column">
					<div className="margin-horizontal-20px">
						<EditableValue
							mode="text"
							valueForLabel={this.state.currentTableSetting.data.tableSettings.settingsName || ''}
							translationKey="Categories"
							callbackSave={this.saveCustomCategoryName}
						/>
					</div>

					<div className="margin-left-20px">
						<BiButton
							colorTheme="org-primary"
							containerTheme="normal-button-corners"
							onClick={this.handleShowLockColumnDialog}
							disabled={this.state.noneChecked}
						>
							<div className="margin-right-14px">
								<FontAwesomeIcon className={'fontAwesomeStyle'} icon={faUserLock} />
							</div>
							<span>{localized('LockColumns')}</span>
						</BiButton>
					</div>
				</div>
			);

		return (
			<div className="p-lg-3 p-lg-3-fill-width">
				<div className="flex-center-column edit-column-extra-checkbox-container">
					<div className="flex-direction-row">
						<BiCheckbox
							containerClassName="padding-right-2"
							checked={this.state.allChecked}
							onChange={this.handleSelectAllCheckboxes}
							label={localized('All')}
							disabled={this.isSelectedCategoryDefault()}
						/>
						<BiCheckbox
							checked={this.state.noneChecked}
							onChange={this.handleSelectNoCheckboxes}
							label={localized('None')}
							disabled={this.isSelectedCategoryDefault()}
						/>
					</div>
					{changeText}
				</div>
				<hr className="margin-bottom-0" />
			</div>
		);
	};

	private handleSelectAllCheckboxes = () => {
		const allDialogColumns = this.props.isAdmin ? [...allBasicColumns, ...allAdminColumns] : allBasicColumns;
		let current = this.state.currentTableSetting;
		current.data.columns = allDialogColumns;

		this.setState({
			...this.state,
			currentTableSetting: this.state.currentTableSetting,
			noneChecked: this.getNoneChecked(allDialogColumns),
			allChecked: this.getAllChecked(allDialogColumns),
		});
	};

	private handleSelectNoCheckboxes = () => {
		let current = this.state.currentTableSetting;
		current.data.columns = [];
		this.setState({
			...this.state,
			currentTableSetting: this.state.currentTableSetting,
			noneChecked: this.getNoneChecked([]),
			allChecked: this.getAllChecked([]),
		});
	};

	public render() {
		const machineTableSettings = this.getFormattedTableSettings();
		const extraCheckboxes = this.getExtraCheckboxes();
		const basicCheckboxes = this.getColumnsAsCheckboxes(allBasicColumns);
		const adminCheckboxes = this.props.isAdmin ? this.getColumnsAsCheckboxes(allAdminColumns) : undefined;

		const deleteDialog: JSX.Element = this.customConfirmDialog(
			localized('Delete'),
			'org-red',
			localizedInterpolation('XDeleteConfirmMsg', {
				X: this.state.currentTableSetting.data.tableSettings.settingsName,
			}),
			this.state.showDeleteDialog,
			this.deleteTableSetting,
			this.hideDeleteDialog
		);

		const saveDialog: JSX.Element = this.customConfirmDialog(
			localized('Save'),
			'org-green',
			localizedInterpolation('XSaveTableSettingDialog', {
				X: this.state.currentTableSetting.data.tableSettings.settingsName,
			}),
			this.state.showSavedialog,
			this.state.saveMethod.save,
			this.state.saveMethod.cancel,
			localized('No')
		);

		const lockColumnDialog: JSX.Element = (
			<LockColumnsDialog
				onHide={this.handleHideLockColumsDialog}
				visible={this.state.showLockColumnDialog}
				columns={this.state.currentTableSetting.data.columns}
				closeDialog={this.handleHideLockColumsDialog}
			/>
		);

		let isDefaultSelected: boolean = this.state.currentTableSetting.id === defaultTableSettings.tableSettings.id;

		return (
			<div>
				<BiTextDialog title={this.props.header} {...this.props} className="edit-column">
					<div className="p-grid no-select">
						<ScrollPanel className="p-scrollpanel-edit-column scrollpanel-container-big scrollpanel-content-flex">
							{machineTableSettings}
							{extraCheckboxes}
							{basicCheckboxes}
							{adminCheckboxes}
						</ScrollPanel>
					</div>
					<div className="flex-end-row margin-top-10px">
						{!isDefaultSelected && (
							<BiButton
								colorTheme="org-red"
								containerTheme="slim-with-rounded-corners"
								containerClassName="margin-right-24px"
								onClick={this.handleShowDeleteTableSettingDialog}
							>
								{localized('Delete')}
							</BiButton>
						)}
						<BiButton
							colorTheme="org-red"
							containerTheme="slim-with-rounded-corners"
							containerClassName="margin-right-24px"
							onClick={this.handleCancel}
						>
							{localized('Cancel')}
						</BiButton>
						<BiButton
							colorTheme="org-green"
							containerTheme="slim-with-rounded-corners"
							onClick={this.handleSaveColumnsAndClose}
						>
							{localized('Save')}
						</BiButton>
					</div>
				</BiTextDialog>

				{deleteDialog}
				{saveDialog}
				{lockColumnDialog}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditColumn);
