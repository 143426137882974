import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GetReleaseNoteImage } from 'state/ducks/release-notes/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { getDateToString } from 'view/shared/components/date-to-string';
import './navigation-bar.scss';
import { ImageBase64 } from '../image/image-base64';
import './release-note.scss';

interface PropsFromParent {
	releaseNoteId: number;
}

const mapStateToProps = (state: AppState, parentProps: PropsFromParent) => {
	let releaseNote = state.releaseNoteReducer.notes.find(n => n.id === parentProps.releaseNoteId);

	return {
		image: state.releaseNoteReducer.images.find(i => i.name === releaseNote?.imageName),
		releaseNote,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getReleaseNoteImage: async (name: string) => (await GetReleaseNoteImage(name))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class ReleaseNote extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.getNotePic();
	}

	componentDidUpdate(prevProps: Props) {
		this.getNotePic();
	}

	getNotePic = () => {
		if (this.props.releaseNote && this.props.releaseNote.imageName && !this.props.image) {
			this.props.getReleaseNoteImage(this.props.releaseNote.imageName);
		}
	};

	public render() {
		if (!this.props.releaseNote) return null;

		let noteBackgroundColor = this.props.releaseNote.isNew ? 'new-note' : 'margin-left-10px';
		let dateMargin = this.props.releaseNote.isNew ? '' : 'margin-right-10px';

		return (
			<div key={this.props.releaseNote.title!}>
				<div className={noteBackgroundColor}>
					<div className="flex-center-column flex-fill-width note-header">
						<h5>{this.props.releaseNote.title}</h5>
						<span className={`text-right release-note-date ${dateMargin}`}>
							{getDateToString(this.props.releaseNote.createdDate)}
						</span>
					</div>
					<p
						className="margin-bottom-0 white-space-pre-wrap"
						dangerouslySetInnerHTML={{ __html: this.props.releaseNote.description! }}
					></p>
					<ImageBase64 image={this.props.image?.image} alt={localized('ReleaseNote')} />
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNote);
