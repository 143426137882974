export const getMarkerByFillLevel = (fillevel?: number | undefined, hasModem?: boolean, isOnline?: boolean) => {
	if (!hasModem || !isOnline) {
		return '/map_marker_nomodem_v3.png';
	}
	if (!fillevel) {
		return '/map_marker_fill_level_low_v3.png';
	}
	if (fillevel < 80) {
		return '/map_marker_fill_level_low_v3.png';
	} else if (fillevel >= 80 && fillevel < 100) {
		return '/map_marker_fill_level_medium_v3.png';
	} else {
		return '/map_marker_fill_level_high_v3.png';
	}
};