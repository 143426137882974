import React from 'react';
import './internet-explorer-util.scss'
import { Card } from 'primereact/card';


export default function InternetExplorerError() {
    return (
        <div className="ie">
            <div className="ie-container">
                <Card
                    title="Please use another browser"
                    subTitle="This site does not support Internet Explorer">
                    We recommend you to use either <a href="https://www.google.com/intl/en/chrome/">Google Chrome</a> or <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>
                </Card>
            </div>
        </div>
    )
}
