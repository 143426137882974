import {
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  RedirectRequestHandler,
  StringMap,
  TokenRequest
} from '@openid/appauth';
import {store} from 'index';
import qs from 'qs';
import {setIsAuth} from 'state/ducks/auth/operations';
import {RouteBasePath, Routes} from 'state/ducks/routes';
import history from 'state/history';
import {
  AccessTokenExpireTimeKey,
  AccessTokenKey,
  AuthClientId,
  AuthClientSecret,
  AuthConfigKey,
  AuthOpenIdConnectUrl,
  AuthPostSignInRedirectUri,
  AuthPostSignOutRedirectUri,
  AuthScopes,
  IdTokenKey,
  PreAuthRedirectLocationKey,
  RefreshTokenExpireTimeKey,
  RefreshTokenKey
} from 'utilities/constants';
import {reactAI} from "../services/telemetry-service";

const EXPIRE_BUFFER_SEC = 10;
const REFRESH_TOKEN_LIFETIME_SEC = 2592000; // This is from the Clients table in idp database
const ONE_DAY_SECONDS = 60 * 60 * 24;
const RENEWAL_TIME_MS = 1800 * 1000; // Renew 30min before

async function getAuthConfiguration() {
  // todo: removed to force users to new Gen2 idp 
  // let configuration = localStorage.getItem(AuthConfigKey);
  // if (configuration !== undefined && configuration !== null) {
  //   return new AuthorizationServiceConfiguration(JSON.parse(configuration) as AuthorizationServiceConfigurationJson);
  // }

  try {
    let response = await AuthorizationServiceConfiguration.fetchFromIssuer(
      AuthOpenIdConnectUrl, new FetchRequestor()
    );

    localStorage.setItem(AuthConfigKey, JSON.stringify(response.toJson()));

    return response;
  } catch (error) {
    console.error("Not able to get auth configuration", error);
    handleAuthError(error);
    return;
  }
}

export async function requestAuthCode() {
  let configuration = await getAuthConfiguration();

  if (configuration) {
    // uses a redirect flow
    let authorizationHandler = new RedirectRequestHandler();

    // create a request
    let request = new AuthorizationRequest({
      client_id: AuthClientId,
      redirect_uri: AuthPostSignInRedirectUri,
      scope: AuthScopes,
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      state: undefined,
      extras: { access_type: "offline", response_mode: "fragment" }
    });

    try {
      // make the authorization request
      authorizationHandler.performAuthorizationRequest(
        configuration,
        request
      );
    } catch (error) {
      console.error("Not able to request auth code");
      handleAuthError(error);
    }
  }
}

export function isTokenValid(tokenType: 'accessToken' | 'refreshToken') {
  let tokenKey: string;
  let tokenExpireKey: string;

  if (tokenType === 'accessToken') {
    tokenKey = AccessTokenKey;
    tokenExpireKey = AccessTokenExpireTimeKey;
  } else {
    tokenKey = RefreshTokenKey;
    tokenExpireKey = RefreshTokenExpireTimeKey;
  }

  let token = localStorage.getItem(tokenKey);
  if (token) {
    let expiresAt = localStorage.getItem(tokenExpireKey);

    if (expiresAt) {
      let isValid = new Date(expiresAt) > new Date();

      return isValid;
    }
  }

  return false;
}

export function isAuthenticated() {
  return isTokenValid('accessToken');
}

export function getTokenExpireTime(expiresInSeconds: number): Date {
  const tokenExpiration = new Date();
  tokenExpiration.setSeconds(tokenExpiration.getSeconds() + expiresInSeconds - EXPIRE_BUFFER_SEC);

  return tokenExpiration;
}

function clearLocalStorage() {
  localStorage.removeItem(AccessTokenKey);
  localStorage.removeItem(RefreshTokenExpireTimeKey);
  localStorage.removeItem(IdTokenKey);
  localStorage.removeItem(RefreshTokenKey);
  localStorage.removeItem(AccessTokenExpireTimeKey);
  localStorage.removeItem(AuthConfigKey);

  // Clear all persisted state
  const persistedEntryKeys: string[] = [];
  for (let i = 0; i < localStorage.length; ++i) {
    if (localStorage.key(i)?.startsWith("persist:")) {
      const key = localStorage.key(i);
      if (key) {
        persistedEntryKeys.push(key);
      }
    }
  }

  for (let i = 0; i < persistedEntryKeys.length; ++i) {
    localStorage.removeItem(persistedEntryKeys[i]);
  }

  unscheduleRenewal();
}

export async function signOut() {
  let configuration = await getAuthConfiguration();
  let idToken = localStorage.getItem(IdTokenKey);

  clearLocalStorage();

  if (configuration && idToken) {
    let params = qs.stringify({id_token_hint: idToken, post_logout_redirect_uri: AuthPostSignOutRedirectUri});
    window.location.href = `${configuration.endSessionEndpoint}?${params}`;
  }
}

export async function getAccessToken(code: string, request: AuthorizationRequest) {
  let tokenRequest: TokenRequest | undefined;

  // request.internal needed for code_verifier
  if (!request || !request.internal) {
    console.warn("No request information");
    return;
  }

  // Use the code to make the token request.
  tokenRequest = getAuthRequest(request, code);

  if (tokenRequest) {
    try {
      await getToken(tokenRequest);
      scheduleTokenRenewal();
    } catch (error) {
      history.push(Routes.SignIn)
    }
  } else {
    console.warn("No tokenRequest for AccessToken");
  }

  redirectPostSignin();
}

function redirectPostSignin() {
  const preSignInLocation = localStorage.getItem(PreAuthRedirectLocationKey);

  if (
    preSignInLocation !== undefined &&
    preSignInLocation !== null &&
    preSignInLocation !== Routes.SignIn &&
    preSignInLocation !== Routes.Callback &&
    preSignInLocation !== Routes.SignInRedirect &&
    preSignInLocation !== Routes.SignOutRedirect
  ) {
    localStorage.removeItem(PreAuthRedirectLocationKey);
    history.replace(preSignInLocation);
  } else {
    history.replace(RouteBasePath);
  }
}

export function scheduleTokenRenewal() {
  unscheduleRenewal();

  if (!isAuthenticated()) {
    return;
  }

  let expiresAt = localStorage.getItem(AccessTokenExpireTimeKey)!;
  let expiresAtDate = new Date(expiresAt);

  if (expiresAt && expiresAtDate) {
    let timeToRenewal = expiresAtDate.getTime() - new Date().getTime() - RENEWAL_TIME_MS;

    if (timeToRenewal < 0) {
      timeToRenewal = 0;
    }

    renewalTimeout = setTimeout(() => {
      getByRefreshToken();
    }, timeToRenewal);
  }
}

let renewalTimeout: NodeJS.Timeout | undefined;
export function unscheduleRenewal() {
  if (renewalTimeout) {
    clearTimeout(renewalTimeout);
    renewalTimeout = undefined;
  }
}

export async function getByRefreshToken() {
  // Use the token response to make a request for an access token
  let tokenRequest = getRefreshRequest();

  if (tokenRequest) {
    try {
      await getToken(tokenRequest);
      scheduleTokenRenewal()
    } catch (error) {
      await requestAuthCode();
    }
  } else {
    console.warn("No tokenRequest for RefreshToken");
  }
}

async function getToken(tokenRequest: TokenRequest) {
  let configuration = await getAuthConfiguration();
  if (!configuration) {
    console.warn("No configuration");
    return;
  }

  try {
    // Send POST request to Identity Server to request an Access Token
    let response = await new BaseTokenRequestHandler(new FetchRequestor()).performTokenRequest(configuration, tokenRequest);

    let accessTokenExpireTime = getTokenExpireTime(response.expiresIn!);
    let refreshTokenExpireTime = getTokenExpireTime(REFRESH_TOKEN_LIFETIME_SEC - ONE_DAY_SECONDS);

    localStorage.setItem(AccessTokenKey, response.accessToken);
    localStorage.setItem(IdTokenKey, response.idToken!);
    localStorage.setItem(RefreshTokenKey, response.refreshToken!);
    localStorage.setItem(AccessTokenExpireTimeKey, accessTokenExpireTime.toISOString());
    localStorage.setItem(RefreshTokenExpireTimeKey, refreshTokenExpireTime.toISOString());

    setIsAuth(true)(store.dispatch);
  } catch (error) {
    console.error("Not able to perform token request");
    handleAuthError(error);
  }
}

function getAuthRequest(request: AuthorizationRequest, code: string) {
  let extras: StringMap | undefined = {};

  if (request.internal) {
    // Send code verifier from code request to comply with PKCE
    extras["code_verifier"] = request.internal["code_verifier"];
  }

  addSecret(extras);

  return new TokenRequest({
    client_id: AuthClientId,
    redirect_uri: AuthPostSignInRedirectUri,
    grant_type: "authorization_code",
    code: code,
    refresh_token: undefined,
    extras: extras
  });
}

function getRefreshRequest() {
  let extras: StringMap | undefined = {};

  let refreshToken = localStorage.getItem(RefreshTokenKey);
  if (!refreshToken || !isTokenValid('refreshToken')) {
    console.warn("No valid refreshToken");
    return null;
  }

  addSecret(extras);

  return new TokenRequest({
    client_id: AuthClientId,
    redirect_uri: AuthPostSignInRedirectUri,
    grant_type: "refresh_token",
    code: undefined,
    refresh_token: refreshToken,
    extras: extras
  });
}

function addSecret(extras: StringMap) {
  // Secret matching setup on identity server
  extras["client_secret"] = AuthClientSecret;
}

function handleAuthError(error: any) {
  console.error(error);
  reactAI?.appInsights?.trackException(error)

  // Clearing storage clears all tokens, resulting in forced logout
  // without the risk of new exceptions from async calls.
  clearLocalStorage();

  throw error;
}

export function decodeToken(token: string | null) {
  if (token === null || token === '') {
    return { upn: '' };
  }
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }
  return JSON.parse(decoded);
}

function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Illegal base64url string!');
  }

  return decodeURIComponent((window as any).escape(window.atob(output)));
}
