import React from 'react';
import { InputText, InputTextProps } from 'primereact/inputtext';
import './bi-simple-input-text.scss';

export interface Props extends InputTextProps {
	icon?: string;
}

class BiSimpleInputText extends React.PureComponent<Props> {
	public render() {
		let icon: JSX.Element | undefined = this.props.icon ? (
			<span className={`bi-input-text-icon ${this.props.icon} absolute-icon`}></span>
		) : undefined;
		return (
			<div className="flex-center-column">
				<InputText
					className={`bi-simple-inputtext-no-margin ${this.props.className}`}
					id={this.props.id}
					placeholder={this.props.placeholder}
					value={this.props.value}
					onChange={this.props.onChange}
				/>
				{icon}
			</div>
		);
	}
}

export default BiSimpleInputText;
