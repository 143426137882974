import {
	CriticalErrorsTileDto,
	DashboardTileType,
	FillLevelPerUnitModelsTileDto,
	IDashboardBaseDto,
	KPIServiceTileDto,
	LocationTileDto,
	PortfolioTileDto,
	ServiceContractTileDto,
	UnitsPerUnitModelsTileDto,
	RecordedPickUpsTileDto,
	Last10BalesTileDto,
	ReportTileDto,
	KPIFillLevelTileDto,
	BalesProducedTileDto,
} from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setMenuToShowid } from 'state/ducks/dashboard/operations';
import { localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import WhiteCard from 'view/shared/components/cards/white-card';
import FilterGraphTableMenu, { GraphTableMenuEnum } from './dashboard-item-menus/filter-graph-table-menu';
import DefaultDashboardMenu from './dashboard-item-menus/default-menu';
import './dashboard-tile.scss';
import CriticalErrorsTile from './dashboard-tiles/critical-errors-tile';
import { FillLevelPerUnitModelsTile } from './dashboard-tiles/fill-level-unit-models-tile';
import { KPIFillLevelTile } from './dashboard-tiles/kpi-fill-level-tile';
import { KPIServiceTile } from './dashboard-tiles/kpi-service-tile';
import { Last10BalesTile } from './dashboard-tiles/last-10-bales-tile';
import LocationTile from './dashboard-tiles/location-tile';
import { PortfolioTile } from './dashboard-tiles/portfolio-tile';
import { RecordedPickUpsTile } from './dashboard-tiles/recorded-pick-ups';
import { ServiceContractTile } from './dashboard-tiles/service-contract-tile';
import UnitsPerUnitModelsTile from './dashboard-tiles/units-unit-models-tile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import ReportTile from './dashboard-tiles/report-tile';
import { BalesProducedTile } from './dashboard-tiles/bales-produced-tile';

const mapStateToProps = (state: AppState) => {
	return {
		menuToShowId: state.dashboardReducer.menuIdToShow,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setMenuId: (id: number) => setMenuToShowid(id)(dispatch),
});

interface TileContent {
	tile: JSX.Element;
	menu?: JSX.Element;
	disablePadding?: boolean;
	title?: string;
}

interface PropsFromParent {
	tile: IDashboardBaseDto;
	clickable: boolean;
	tileName?: string;
	disableMenu?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

type State = {
	shouldToggleMenu: boolean;
	selectedMenuType: GraphTableMenuEnum;
};

class DashboardTile extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { shouldToggleMenu: false, selectedMenuType: GraphTableMenuEnum.graph };
	}

	public render() {
		const card = this.getTileContent();
		if (!card?.tile) return <></>;

		return (
			<div
				className="dashboard-tile"
				onMouseDown={this.shouldToggle}
				onMouseMove={this.shouldNotToggle}
				onClick={this.toggleMenu}
			>
				<WhiteCard
					title={this.getCardTitle(card)}
					spaceBetweenTitlesAndContentInEm={'0-5'}
					classNameContainer="position-relative card-container "
					disablePadding={card.disablePadding}
					topRightElement={this.makeInfoElement()}
				>
					{card.tile}
					{card.menu}
				</WhiteCard>
			</div>
		);
	}

	private getCardTitle = (card: any): string => {
		if (this.props.tileName) {
			return this.props.tileName;
		}
		if (card.title) {
			return card.title;
		}

		return localizedDynamic(`DashboardTile.${this.props.tile.dashboardBase?.type}`);
	};

	private shouldToggle = () => {
		this.setState({ shouldToggleMenu: true });
	};

	private shouldNotToggle = () => {
		this.setState({ shouldToggleMenu: false });
	};
	private toggleMenu = () => {
		if (!this.props.tile.dashboardBase) return;
		if (this.state.shouldToggleMenu) {
			this.setState({ shouldToggleMenu: false });
			const setId =
				this.props.menuToShowId !== this.props.tile.dashboardBase.id ? this.props.tile.dashboardBase.id : 0;
			this.props.setMenuId(setId);
		}
	};

	private onViewChange = (newMenu: GraphTableMenuEnum) => {
		this.setState({ selectedMenuType: newMenu });
	};

	private makeDefaultMenu = (extraContent?: JSX.Element) => {
		return <DefaultDashboardMenu extraContent={extraContent} dashboardBase={this.props.tile.dashboardBase!} />;
	};
	private makeFilterGraphTableMenu = () => {
		return this.makeDefaultMenu(
			<FilterGraphTableMenu onMenuViewChange={this.onViewChange} selectedView={this.state.selectedMenuType} />
		);
	};

	private makeInfoElement = () => {
		if (!this.props.tile.dashboardBase) return undefined;
		if (!this.props.tile.dashboardBase.type) return undefined;

		const infoLookupString = `DashboardHelp.${this.props.tile.dashboardBase.type}`;
		const infoString = localizedDynamic(infoLookupString);
		if (infoLookupString === infoString) return undefined;

		const infoElement = <div className="dashboard-tile-info">{infoString}</div>;
		return (
			<div className="max-width-ka">
				<BiTooltip overlay={infoElement} placement="bottom">
					<FontAwesomeIcon icon={faQuestionCircle} />
				</BiTooltip>
			</div>
		);
	};

	private getTileContent(): TileContent | undefined {
		switch (this.props.tile.dashboardBase?.type) {
			case DashboardTileType.Portfolio:
				return {
					tile: <PortfolioTile tile={this.props.tile as PortfolioTileDto} />,
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.KPIFillLevel:
				return {
					tile: <KPIFillLevelTile tile={this.props.tile as KPIFillLevelTileDto} />,
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.KPIService:
				return {
					tile: <KPIServiceTile tile={this.props.tile as KPIServiceTileDto} />,
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.UnitsPerUnitModels:
				return {
					tile: (
						<UnitsPerUnitModelsTile
							tile={this.props.tile as UnitsPerUnitModelsTileDto}
							clickable={this.props.clickable}
						/>
					),
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.FillLevelPerUnitModels:
				return {
					tile: (
						<FillLevelPerUnitModelsTile
							tile={this.props.tile as FillLevelPerUnitModelsTileDto}
							viewMode={this.state.selectedMenuType}
						/>
					),
					menu: this.makeFilterGraphTableMenu(),
				};
			case DashboardTileType.ServiceContract:
				return {
					tile: <ServiceContractTile tile={this.props.tile as ServiceContractTileDto} />,
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.CriticalErrors:
				return {
					tile: (
						<CriticalErrorsTile
							tile={this.props.tile as CriticalErrorsTileDto}
							clickable={this.props.clickable}
						/>
					),
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.StaticMap:
				const locationTile = this.props.tile as LocationTileDto;
				return {
					tile: <LocationTile tile={locationTile} clickable={this.props.clickable} />,
					menu: this.makeDefaultMenu(),
					disablePadding: true,
					title: locationTile.locationTileSettings.locationName,
				};
			case DashboardTileType.EditableStaticMap:
				const editablelocationTile = this.props.tile as LocationTileDto;
				return {
					tile: <LocationTile tile={editablelocationTile} clickable={this.props.clickable} />,
					menu: undefined,
					disablePadding: true,
					title: editablelocationTile.locationTileSettings.locationName,
				};
			case DashboardTileType.RecordedPickUps:
				const pickUpsTile = this.props.tile as RecordedPickUpsTileDto;
				return {
					tile: <RecordedPickUpsTile tile={pickUpsTile} />,
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.Last10Bales:
				return {
					tile: <Last10BalesTile tile={this.props.tile as Last10BalesTileDto} />,
					menu: this.makeDefaultMenu(),
				};
			case DashboardTileType.ReportPrintout:
				const reportTile = this.props.tile as ReportTileDto;
				return {
					tile: (
						<ReportTile
							tile={reportTile}
							viewMode={this.state.selectedMenuType}
							clickable={this.props.clickable}
						></ReportTile>
					),
					menu: reportTile.isPreview ? undefined : this.makeFilterGraphTableMenu(),
					disablePadding: false,
					title: reportTile.reportTileSettingsDto.tileName,
				};
			case DashboardTileType.BalesProduced:
				return {
					tile: <BalesProducedTile tile={this.props.tile as BalesProducedTileDto}></BalesProducedTile>,
					menu: this.makeDefaultMenu(),
				};
			default:
				return undefined;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTile);
