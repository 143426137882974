import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import 'bootstrap/dist/css/bootstrap.css';
import 'core-js';
import { detect } from 'detect-browser';
import GA4React from 'ga-4-react';
import 'isomorphic-fetch';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import 'primeflex/primeflex.scss';
import 'primeicons/primeicons.css';
import { Growl } from 'primereact/growl';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'react-grid-layout/css/styles.css';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import history from 'state/history';
import { getStore } from 'state/store';
import { QueryParamProvider } from 'use-query-params';
import { GoogleAnalytics } from 'utilities/google-analytics';
import AuthInitializer from 'view/components/auth/auth-initializer';
import AuthListener from 'view/components/auth/auth-listener';
import 'webcrypto-shim';
import App from './App';
import './index.scss';
import { notificationService } from './services/notification-service';
import { reactAI } from './services/telemetry-service';
import './utilities/array-group-by';
import { GoogleAnalyticsKey, InsightsKey } from './utilities/constants';
import Navbar from './view/components/navigation-bar/navigation-bar';
import InternetExplorerErrorComponent from './view/shared/utils/internet-explorer/internet-explorer-util';

InsightsKey && reactAI.initialize(InsightsKey, history);
export const ga4react = new GA4React(GoogleAnalyticsKey);

// Get store
export const store = getStore();

// Persist the whole store
const persistor = persistStore(store);

//Can only be testet if compiled to production (npm run build)
const browserHandler = () => {
	const browser = detect()?.name;
	if (browser !== 'ie') return appToRender();
	return <InternetExplorerErrorComponent />;
};

const appToRender = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AuthInitializer>
					<AuthListener />
					<Growl
						ref={el => {
							notificationService.initialize(el);
						}}
					/>
					{/* We need to sync. a custom history with Redux, so we can't use
      					an abstraction like BrowserRouter. We need the low-level interface Router */}
					<Router history={history}>
						<QueryParamProvider ReactRouterRoute={Route}>
							<Navbar />
							<App />
							<GoogleAnalytics />
						</QueryParamProvider>
					</Router>
				</AuthInitializer>
			</PersistGate>
		</Provider>
	);
};

(async () => {
	try {
		await ga4react.initialize();
	} catch (error) {
		const err = error as IExceptionTelemetry;
		reactAI?.appInsights?.trackException(err);
	}

	ReactDOM.render(browserHandler(), document.getElementById('root') as HTMLElement);
})();
