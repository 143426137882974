import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { localized } from 'state/i18n';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';

type Props = DialogProps & {
	onPress: () => void;
	dialogMessage?: string | string[];
};

export const TooManyOfflineMachinesDialog: React.FC<Props> = props => {
	return (
		<BiTextDialog
			{...props}
			title={props.header}
			subtitle={props.dialogMessage}
			className="confirm-dialog-container"
		>
			<div className="flex-end-row margin-top-10px">
				<BiButton
					onClick={props.onPress}
					colorTheme="org-primary-grey"
					containerClassName="margin-right-24px"
					containerTheme="slim-with-rounded-corners"
				>
					{localized('Ok')}
				</BiButton>
			</div>
		</BiTextDialog>
	);
};
