import { IUnitDetailsDto } from 'api/api';
import React, { FC } from 'react';
import { localized } from 'state/i18n';
import { tutorialVimeoId } from 'utilities/tutorial-constants';
import { IdDataTuple } from 'utilities/types-util';
import VideoButtonDialog from 'view/components/video/video-button-dialog';
import WhiteCard from 'view/shared/components/cards/white-card';
import { cardDetailsStyles, cardGeneralClassNames } from '../../constants';
import AutomaticBaleCounterComponent from './AutomaticBaleCounterComponent';
import { BaleLinker } from './bale-linker';
import './bale-settings.scss';
import ResetBalesComponent from './reset-bale-counter';
import SetBalesOnSiteComponent from './set-bales-on-site';
import SetBalesStockComponent from './set-bales-stock';

type Props = {
	currMachine: IdDataTuple<IUnitDetailsDto, string>;
};

export const BaleSettings: FC<Props> = React.memo((props: Props) => {
	const containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer} bale-settings`;

	const topRightElement = <BaleLinker currMachine={props.currMachine} />;

	return (
		<WhiteCard
			title={localized('BalesSettings')}
			classNameContainer={containerClassName}
			topRightElement={topRightElement}
			contentBelowHeaders={
				<VideoButtonDialog
					buttonLabel={localized('Tutorial')}
					dialogTitle={localized(tutorialVimeoId.baleSettings.title)}
					vimeoId={tutorialVimeoId.baleSettings.id}
				/>
			}
		>
			<SetBalesStockComponent currMachine={props.currMachine} />

			<ResetBalesComponent currMachine={props.currMachine} />

			<SetBalesOnSiteComponent currMachine={props.currMachine} />

			<AutomaticBaleCounterComponent currMachine={props.currMachine} />
		</WhiteCard>
	);
});
