import { IIoTHubDuplicateDecommissionDto } from 'api/api';
import React, { useState } from 'react';
import { localized, localizedInterpolation } from 'state/i18n';
import { ConfirmDialog } from 'view/shared/components/dialogs/bi-dialog-confirm/dialog-confirm';

type Props = {
	onHide: () => void;
	visible: boolean;
	onConfirm: () => void;
	decommissionObj: IIoTHubDuplicateDecommissionDto;
	iotHubIdToDelete: string;
};

export const DecommissionItemDialog: React.FC<Props> = props => {
	const [showSpiner, setShowSpinner] = useState(false);

	const deleteID: string =
		props.iotHubIdToDelete === props.decommissionObj.deviceIdOld
			? props.decommissionObj.deviceIdOld
			: props.decommissionObj.deviceIdNew;

	const keepID: string =
		props.iotHubIdToDelete === props.decommissionObj.deviceIdOld
			? props.decommissionObj.deviceIdNew
			: props.decommissionObj.deviceIdOld;

	const onConfirm = () => {
		setShowSpinner(true);
		props.onConfirm();
	};

	return (
		<ConfirmDialog
			onConfirm={onConfirm}
			onCancel={props.onHide}
			onHide={props.onHide}
			visible={props.visible}
			header={localized('DuplicatedIotHubUnits')}
			dialogMessage={localizedInterpolation('DuplicatedIotHubUnitsDeleteConfirmMessage', {
				delete: deleteID,
				keep: keepID,
			})}
			showConfirmSpinner={showSpiner}
			confirmColor="org-red"
			confirmMessage={localized('Delete')}
		></ConfirmDialog>
	);
};
