import { ReportTypes } from '../reports';
import * as React from 'react';
import './table-graph-buttons.scss';
import { localized } from 'state/i18n';
import BiRadiobutton from 'view/shared/components/buttons/bi-radiobutton/bi-radiobutton';
import { images } from 'utilities/images';

export interface TableGraphButtonsProps {
	onClick: (reportType: ReportTypes) => void;
	containerClassName?: string;
	initialReportType: string;
}

const tempRadiobuttonName = 'radiobutton-table-graph';

class TableGraphButtons extends React.PureComponent<TableGraphButtonsProps> {
	public render() {
		return (
			<div className={`flex-direction-row ${this.props.containerClassName || ''}`}>
				<BiRadiobutton
					colorTheme="org-primary-colors-only"
					name={tempRadiobuttonName}
					onClick={this.handleOnGraphClick}
					defaultChecked={this.props.initialReportType === "Graph"}
					containerTheme="no-corners"
					enableDarkOverlay={false}
					containerClassName="flex-container spacing-right-table-graph table-graph-icon-inactive-color"
				>
					<img
						className="cursor-pointer table-graph-icon"
						src={images.bars_inverted}
						alt={localized('Graph')}
					/>
				</BiRadiobutton>
				
				<div className="table-graph-border" />
				
				<BiRadiobutton
					colorTheme="org-primary-colors-only"
					name={tempRadiobuttonName}
					onClick={this.handleOnTableClick}
					containerTheme="no-corners"
					enableDarkOverlay={false}
					containerClassName="flex-container spacing-left-table-graph table-graph-icon-inactive-color"
					defaultChecked={this.props.initialReportType === "Table"}
				>
					<img
						className="cursor-pointer table-graph-icon"
						src={images.spreadsheet_inverted}
						alt={localized('Table')}
					/>
				</BiRadiobutton>
			</div>
		);
	}

	private handleOnGraphClick = () => {
		this.props.onClick('Graph');
	};

	private handleOnTableClick = () => {
		this.props.onClick('Table');
	};
}

export default TableGraphButtons;
