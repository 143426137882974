import { DashboardTileType, FilterDto, ICreateReportTileSettingsDto } from 'api/api';
import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createReportTile } from 'state/ducks/dashboard/operations';
import { getReportTilePreview } from 'state/ducks/reports/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import CreateTile from 'view/components/dashboard/create-tile/create-tile';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import './create-tile.scss';

const mapStateToProps = (state: AppState) => {
	return {
		reportDataType: state.reportReducer.dataType,
		appliedFilter: state.filterSearchReducer.appliedFilters,
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		reportType: state.reportReducer.reportType,
		dateType: state.reportReducer.reportTileDateType,
		reportTilePreview: state.reportReducer.reportTilePreview,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	createReportTile: async (settings: ICreateReportTileSettingsDto) => (await createReportTile(settings))(dispatch),
	getReportTilePreview: async (settings: ICreateReportTileSettingsDto) =>
		(await getReportTilePreview(settings))(dispatch),
});

interface AddReportTileProps extends DialogProps {
	subtitle: string;
	title: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AddReportTileProps;

type State = {
	tileName?: string;
};

class CreateReportTileDialog extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {};

		this.fetchReportTile();
	}
	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.reportDataType !== prevProps.reportDataType ||
			this.props.dateType !== prevProps.dateType ||
			this.props.selectedMachines !== prevProps.selectedMachines ||
			this.props.appliedFilter !== prevProps.appliedFilter ||
			this.props.visible !== prevProps.visible
		) {
			this.fetchReportTile();
		}
	}

	public render() {
		return (
			<BiTextDialog {...this.props} modal={false}>
				<CreateTile
					onTileAdded={this.onAddTile}
					tile={this.props.reportTilePreview}
					tileNamePlaceholder={localized('NameOfLocationTile')}
					tileType={DashboardTileType.ReportPrintout}
					tileName={this.state.tileName}
					onTileNameChanged={this.onTileNameChanged}
					btnDisabled={this.state.tileName === undefined || this.props.reportDataType === undefined}
				/>
			</BiTextDialog>
		);
	}
	private fetchReportTile = () => {
		if (this.props.reportDataType === undefined || this.props.visible === false) return;

		let tileSettings: ICreateReportTileSettingsDto = {
			tileName: '',
			dataType: this.props.reportDataType,
			dateType: this.props.dateType,
			deviceIds: this.props.selectedMachines,
			filter: this.props.appliedFilter as FilterDto,
		};

		this.props.getReportTilePreview(tileSettings);
	};

	private onAddTile = () => {
		if (this.props.reportDataType === undefined) return;

		let tileSettings: ICreateReportTileSettingsDto = {
			tileName: this.state.tileName,
			dataType: this.props.reportDataType,
			dateType: this.props.dateType,
			deviceIds: this.props.selectedMachines,
			filter: this.props.appliedFilter as FilterDto,
		};

		this.props.createReportTile(tileSettings);

		this.props.onHide();
	};

	private onTileNameChanged = (e: React.FormEvent<HTMLInputElement>) => {
		let text: string | undefined = e.currentTarget.value;
		if (text === '') {
			text = undefined;
		}
		this.setState({ tileName: text });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReportTileDialog);
