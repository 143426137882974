import { IUnitCycleDto } from 'api/api';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getPressureChart } from 'state/ducks/machine-history/operations';
import { localized } from 'state/i18n';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';

interface Props {
	unitSerialNumber: string;
	unitCycle: IUnitCycleDto;
}

export const PressureGraphSelect: React.FC<Props> = props => {
	const { unitSerialNumber, unitCycle } = props;
	const dispatch = useDispatch();

	const onUnitCycleSelect = async () => {
		dispatch(await getPressureChart(unitSerialNumber, unitCycle));
	};

	return (
		<BiButton
			title={localized('Edit')}
			containerClassName="history-table-edit-btn"
			onClick={onUnitCycleSelect}
			colorTheme="org-primary"
			containerTheme="icon-sized-with-soft-rounded-corners"
		>
			{localized('Select')}
		</BiButton>
	);
};

export default PressureGraphSelect;
