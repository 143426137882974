import { INotificationHistoryDto, ITableSettings } from 'api/api';
import { localized } from 'state/i18n';
import { ColumnGeneric } from '../history-table-settings/types';
import { SortingGeneric, TableColumnWidthInfoGeneric } from '../table-settings/types';

type NotificationHistoryDtoExtraKeys = 'editHistoryDto';
export type NotificationHistoryDtoKeys = keyof INotificationHistoryDto | NotificationHistoryDtoExtraKeys;

// These are the columns that will be rendered.
export const allColumns: ColumnGeneric<NotificationHistoryDtoKeys>[] = [
	{ name: 'createdOn', title: localized('CreatedOn') },
	{ name: 'emails', title: localized('SentTo') },
	{ name: 'notificationEvents', title: localized('Notifications') },
	{ name: 'stateChangeDateTime', title: localized('Timestamp') },
	{ name: 'editHistoryDto', title: localized('Edit') },
];

export interface NotificationHistoryTableSettingsState {
	columnWidths: TableColumnWidthInfoGeneric<NotificationHistoryDtoKeys>[];
	columnOrder: NotificationHistoryDtoKeys[];
	sorting: SortingGeneric<NotificationHistoryDtoKeys>[];
	columns: ColumnGeneric<NotificationHistoryDtoKeys>[];
	tableSettings: ITableSettings;
	loaded: boolean;
}
