import { DateTypes } from "api/api";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportTileDateType } from "state/ducks/reports/operations";
import { localizedInterpolation } from "state/i18n";
import { AppState } from "state/store";
import { BiSelectButtons } from "view/components/bi-select-button/bi-select-buttons";

export const ReportTileDateTypePicker: FC = React.memo(() => {
	const dispatch = useDispatch();

	const reportTileDateType = useSelector((state: AppState) => 
        state.reportReducer.reportTileDateType
	);

	const setReportDateType = (dateType: DateTypes) => {
		setReportTileDateType(dateType)(dispatch);
	} 
		
	const options = [
		{label: localizedInterpolation('LastXDays', {days: 7}), dateType: DateTypes.Week},
		{label: localizedInterpolation('LastXDays', {days: 30}), dateType: DateTypes.Month},
		{label: localizedInterpolation('LastXDays', {days: 365}), dateType: DateTypes.Year}
	]
	
	return (
		<BiSelectButtons 
			availableOptions={options} 
			currentOption={reportTileDateType} 
			onChange={setReportDateType}
			className="margin-top-30px"/>
	);
});