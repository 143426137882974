import {
	AugerPressureChartDto,
	BalerPressureChartDto,
	CompactorPressureChartDto,
	IBalerPressureChartDto,
	ICompactorPressureChartDto,
	IPressureChartDto,
	PressureChartType,
	UnitController
} from 'api/api';
import {
	Chart as ChartJS,
	ChartDataset,
	ChartEvent,
	ChartOptions,
	ChartType,
	ChartTypeRegistry,
	LegendElement,
	LegendItem
} from 'chart.js';

import 'chartjs-adapter-moment';
import { Chart as PrimeChart } from 'primereact/chart';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { Spinner } from 'view/components/spinner/spinner';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';
import Graph, { ChartPoint } from 'view/shared/components/graph/graph';
import * as Colors from 'view/styles/colors';
import './pressure-graph.scss';

type PressureChartDataType = {
	chartDatasets: ChartDataset<ChartType, number[] | ChartPoint[]>[],
	latestDate: Date,
}


interface Props {
	unitSerialNumber: string;
}

const ZOOM_FACTOR = 0.1;

// Generated with http://vrl.cs.brown.edu/color
const colors = [
	'rgb(100,186,170)',
	'rgb(21,78,86)',
	'rgb(28,241,163)',
	'rgb(5,110,18)',
	'rgb(132,186,85)',
	'rgb(31,65,150)',
	'rgb(99,132,192)',
	'rgb(116,22,142)',
	'rgb(233,183,227)',
	'rgb(253,129,200)',
	'rgb(139,111,237)',
	'rgb(83,28,232)',
	'rgb(254,22,244)',
	'rgb(90,62,79)',
	'rgb(251,9,152)',
	'rgb(52,245,14)',
	'rgb(63,76,8)',
	'rgb(241,212,56)',
	'rgb(116,53,2)',
	'rgb(247,96,21)',
	'rgb(245,166,131)',
	'rgb(142,0,73)',
	'rgb(165,20,9)',
];

const addIOBitmasksToGraph = (
	bitmaskDict:
		| IPressureChartDto['inputBitmask']
		| IBalerPressureChartDto['outputBitmask']
		| ICompactorPressureChartDto['outputBitmask'],
	type: PressureChartType,
	controller: UnitController,
	defaultEndTime: Date,
	bitmaskOffset: number = 0
) => {
	if (bitmaskDict) {
		const chartDatasets: ChartDataset<ChartType, number[] | ChartPoint[]>[] = [];
		let offsetIndex: number = 0;
		for (const bitmask in bitmaskDict) {
			const colorIndex =
				(isNaN(parseInt(bitmask)) ? bitmaskOffset + offsetIndex++ : bitmaskOffset + parseInt(bitmask)) %
				(colors.length - 1);

			const color = colors[colorIndex];
			let inputName = '';
			switch (type) {
				case PressureChartType.Baler:
					switch (controller) {
						case UnitController.JE846:
							inputName = localizedDynamic(`PressureGraphInputsJE846.${bitmask}`);
							break;
						case UnitController.JE911:
							inputName = localizedDynamic(`PressureGraphInputsJE911.${bitmask}`);
							break;
					}
					break;
				case PressureChartType.Compactor:
					inputName = localizedDynamic(`PressureGraphInputsCompactor.${bitmask}`);
					break;
				case PressureChartType.Auger:
					inputName = localizedDynamic(`PressureGraphInputsCompactor.${bitmask}`);
					break;
			}

			let outputName = '';
			switch (type) {
				case PressureChartType.Baler:
					outputName = localizedDynamic(`PressureGraphOutputsBaler.${bitmask}`);
					break;
				case PressureChartType.Compactor:
					outputName = localizedDynamic(`PressureGraphOutputsCompactor.${bitmask}`);
					break;
				case PressureChartType.Auger:
					outputName = localizedDynamic(`PressureGraphOutputsCompactor.${bitmask}`);
					break;
			}

			const bitmaskDataset: ChartDataset<'scatter', ChartPoint[]> = {
				data: [],
				label: bitmaskOffset === 0 ? inputName : outputName,
				backgroundColor: color,
				borderColor: color,
				pointBackgroundColor: color,
				yAxisID: 'yBitmask',
				xAxisID: 'x',
				type: 'scatter',
				hidden: true,
				segment: {
					borderColor: ctx => skipped(ctx, 'transparent'),
					backgroundColor: ctx => skipped(ctx, 'transparent'),
				},
				showLine: true,
				spanGaps: true,
			};

			const bitmasks = bitmaskDict[bitmask];

			for (const bitmask of bitmasks) {
				const bitmasksChartPoints: ChartPoint[] = [
					{
						x: bitmask.startTime,
						y: colorIndex,
					},
					{
						x: bitmask.endTime ?? defaultEndTime,
						y: colorIndex,
					},
				];

				bitmaskDataset.data = [...bitmaskDataset.data, ...bitmasksChartPoints];
			}

			chartDatasets.push(bitmaskDataset);
		}
		return chartDatasets;
	}
};

const getPressureGraphOptions = (): ChartOptions => {
	return {
		plugins: {
			legend: {
				display: true,
				onClick: (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<keyof ChartTypeRegistry>) => {
					const index = legendItem.datasetIndex;
					const ci = legend.chart;
					let axisId: string | undefined;

					if (index !== undefined) {
						axisId = ci.getDatasetMeta(index).yAxisID;
					}

					if (index !== undefined && ci.isDatasetVisible(index)) {
						if (axisId !== undefined) {
							ci.options.scales![axisId] = {
								...ci.options.scales![axisId],
								display: false,
							};
						}
						ci.hide(index);
						legendItem.hidden = true;
					} else {
						if (axisId !== undefined) {
							ci.options.scales![axisId] = {
								...ci.options.scales![axisId],
								display: true,
							};
						}
						index !== undefined && ci.show(index);
						legendItem.hidden = false;
					}
				},
			},
			tooltip: {
				callbacks: {
					label: tooltipItem => {
						const label = tooltipItem.dataset.label;
						const value = tooltipItem.parsed.y;
						let result = `${label}: ${value} `;

						switch (tooltipItem.dataset.yAxisID) {
							case 'yPressure':
								return result + 'bar';
							case 'yPosition':
								return result + '%';
							case 'yCurrent':
								return result + 'A';
							case 'yBitmask':
								return `${label}`;
							default:
								return result;
						}
					},
				},
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
					},
					pinch: {
						enabled: true,
					},
					mode: 'x',
				},
				pan: {
					enabled: true,
					mode: 'x',
				},
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: localized('Time'),
				},
				type: 'time',
				display: true,
				time: {
					minUnit: 'second',
					displayFormats: {
						second: 'HH:mm:ss',
					},
					tooltipFormat: 'HH:mm:ss.SSS',
				},
			},
			// All y-axis are explicit used, the default y-axis is therefore hidden
			y: { display: false },
			// Must match the name in tooltip callback
			yPressure: {
				display: 'auto',
				title: {
					display: true,
					text: localized('Pressure'),
				},
				grid: {
					display: true,
				},
				ticks: {
					display: true,
					callback: function (value, index, ticks) {
						return `${value} bar`;
					},
				},
			},
			// Must match the name in tooltip callback
			yPosition: {
				display: 'auto',
				title: {
					display: true,
					text: localized('Position'),
				},
				grid: {
					display: true,
				},
				position: 'right',
				ticks: {
					callback: function (value, index, ticks) {
						return `${value} %`;
					},
				},
			},
			// Must match the name in tooltip callback
			yCurrent: {
				display: 'auto',
				title: {
					display: true,
					text: localized('Current'),
				},
				grid: {
					display: true,
				},
				position: 'left',
				ticks: {
					callback: function (value, index, ticks) {
						return `${value} A`;
					},
				},
			},
			// Must match the name in tooltip callback
			yBitmask: {
				type: 'linear',
				display: false,
				beginAtZero: true,
				position: 'right',
				ticks: {
					display: false,
				},
			},
		},
		elements: {
			line: {
				tension: 0,
			},
		},
	};
};

const skipped = (ctx: any, value: any) => (ctx.p0.skip || ctx.p1.skip ? value : undefined);

export const PressureGraph: FC<Props> = props => {
	const chartRef = useRef<PrimeChart>(null);
	const pressureChart = useSelector((state: AppState) => state.unitHistoryReducer.pressureChartState.data);
	const selectedUnitCycleTimestamp = useSelector(
		(state: AppState) => state.unitHistoryReducer.pressureChartState.selectedUnitCycleTimestamp
	);
	const isLoading = useSelector((state: AppState) => state.unitHistoryReducer.pressureChartState.isLoading);
	const [data, setData] = useState<Chart.ChartPoint[]>([]);
	const [dataYLabel, setDataYLabel] = useState<string>('');
	const [dataYAxisID, setdataYAxisID] = useState<string>('yPressure');
	const [dataSets, setDataSets] = useState<ChartDataset<ChartType, number[] | ChartPoint[]>[]>([]);

	useEffect(() => {
		if (!pressureChart || pressureChart.unitSerialNumber !== props.unitSerialNumber) return;

		let data: PressureChartDataType;

		switch (pressureChart.type) {
			case PressureChartType.Baler:
				data = prepareBalerData(pressureChart);
				break;
			case PressureChartType.Compactor:
				data = prepareCompactorData(pressureChart);
				break;
			case PressureChartType.Auger:
				data = prepareAugerData(pressureChart);
				break;
			default:
				return;
		}

		const inputDataset = addIOBitmasksToGraph(
			pressureChart.inputBitmask,
			pressureChart.type,
			pressureChart.unitController,
			data.latestDate
		);
		if (inputDataset !== undefined) {
			data.chartDatasets.push(...inputDataset);
		}

		const outputDataset = addIOBitmasksToGraph(
			pressureChart.outputBitmask,
			pressureChart.type,
			pressureChart.unitController,
			data.latestDate,
			16
		);
		if (outputDataset !== undefined) {
			data.chartDatasets.push(...outputDataset);
		}

		setDataSets(data.chartDatasets);
	}, [pressureChart, props.unitSerialNumber]);



	const prepareBalerData = (pressureChart: IPressureChartDto): PressureChartDataType => {
		let pressureChartBaler = pressureChart as BalerPressureChartDto;
		const chartDatasets: ChartDataset<ChartType, number[] | ChartPoint[]>[] = [];

		var oilPressure: ChartPoint[] =
			pressureChartBaler?.oilPressure
				?.sort((a, b) => (a.ts > b.ts ? 1 : -1))
				.map(o => ({ x: o.ts, y: o.value })) || [];

		let strokePosition: ChartPoint[] =
			pressureChartBaler?.strokePosition
				?.sort((a, b) => (a.ts > b.ts ? 1 : -1))
				.map(s => ({ x: s.ts, y: s.value / 10 })) || [];

		chartDatasets.push({
			data: strokePosition,
			label: localized('StrokePosition'),
			backgroundColor: Colors.colorOrange,
			borderColor: Colors.colorOrange,
			pointBackgroundColor: Colors.colorOrange,
			yAxisID: 'yPosition',
		});

		let latestDate =
			(oilPressure[oilPressure.length - 1]?.x as Date) ?? (strokePosition[strokePosition.length - 1]?.x as Date);
		if (latestDate === undefined) {
			latestDate = new Date();
		}

		setData(oilPressure);
		setDataYLabel(localized('OilPressure'));
		setdataYAxisID('yPressure');

		return { chartDatasets, latestDate };
	};

	const prepareCompactorData = (pressureChart: IPressureChartDto): PressureChartDataType => {
		let pressureChartCompactor = pressureChart as CompactorPressureChartDto;
		const chartDatasets: ChartDataset<ChartType, number[] | ChartPoint[]>[] = [];

		var oilPressure: ChartPoint[] =
			pressureChartCompactor?.oilPressure
				?.sort((a, b) => (a.ts > b.ts ? 1 : -1))
				.map(o => ({ x: o.ts, y: o.value })) || [];

		let ramPosition: ChartPoint[] =
			pressureChartCompactor?.ramPosition
				?.sort((a, b) => (a.ts > b.ts ? 1 : -1))
				.map(s => ({ x: s.ts, y: s.value / 10 })) || [];

		chartDatasets.push({
			data: ramPosition,
			label: localized('RamPosition'),
			backgroundColor: Colors.colorOrange,
			borderColor: Colors.colorOrange,
			pointBackgroundColor: Colors.colorOrange,
			yAxisID: 'yPosition',
		});

		let latestDate =
			(oilPressure[oilPressure.length - 1]?.x as Date) ?? (ramPosition[ramPosition.length - 1]?.x as Date);
		if (latestDate === undefined) {
			latestDate = new Date();
		}

		setData(oilPressure);
		setDataYLabel(localized('OilPressure'));
		setdataYAxisID('yPressure');

		return { chartDatasets, latestDate };
	};

	const prepareAugerData = (pressureChart: IPressureChartDto): PressureChartDataType => {
		let pressureChartAuger = pressureChart as AugerPressureChartDto;
		const chartDatasets: ChartDataset<ChartType, number[] | ChartPoint[]>[] = [];

		var augerMotorCurrentAmp: ChartPoint[] =
			pressureChartAuger?.augerMotorCurrent_mA
				?.sort((a, b) => (a.ts > b.ts ? 1 : -1))
				.map(val => ({ x: val.ts, y: val.value / 1000 })) || [];

		let feeder1CurrentAmp: ChartPoint[] =
			pressureChartAuger?.feeder1Current_mA
				?.sort((a, b) => (a.ts > b.ts ? 1 : -1))
				.map(val => ({ x: val.ts, y: val.value / 1000 })) || [];

		let feeder2CurrentAmp: ChartPoint[] =
			pressureChartAuger?.feeder2Current_mA
				?.sort((a, b) => (a.ts > b.ts ? 1 : -1))
				.map(val => ({ x: val.ts, y: val.value / 1000 })) || [];

		chartDatasets.push({
			data: feeder1CurrentAmp,
			label: localized('AugerFeederMotor') + ' 1',
			backgroundColor: Colors.colorOrange,
			borderColor: Colors.colorOrange,
			pointBackgroundColor: Colors.colorOrange,
			yAxisID: 'yCurrent',
		});

		chartDatasets.push({
			data: feeder2CurrentAmp,
			label: localized('AugerFeederMotor') + ' 2',
			backgroundColor: Colors.colorRed,
			borderColor: Colors.colorRed,
			pointBackgroundColor: Colors.colorRed,
			yAxisID: 'yCurrent',
		});

		let latestDate =
			(augerMotorCurrentAmp[augerMotorCurrentAmp.length - 1]?.x as Date) ??
			(feeder1CurrentAmp[feeder1CurrentAmp.length - 1]?.x as Date);
		if (latestDate === undefined) {
			latestDate = new Date();
		}

		setData(augerMotorCurrentAmp);
		setDataYLabel(localized('AugerMotor'));
		setdataYAxisID('yCurrent');

		return { chartDatasets, latestDate };
	};

	const onZoomInClick = () => {
		if (chartRef.current) {
			((chartRef.current as any).chart as ChartJS).zoom(1 + ZOOM_FACTOR);
		}
	};

	const onZoomOutClick = () => {
		if (chartRef.current) {
			((chartRef.current as any).chart as ChartJS).zoom(1 - ZOOM_FACTOR);
		}
	};

	const onZoomResetClick = () => {
		if (chartRef.current) {
			((chartRef.current as any).chart as ChartJS).resetZoom();
		}
	};

	return (
		<div className="pressure-graph-container">
			{selectedUnitCycleTimestamp && (
				<h5 className="text-bold">
					{localized('Timestamp')}:{' '}
					{getDateToString(selectedUnitCycleTimestamp, DateFormatterModes.withMinutes)}
				</h5>
			)}
			{isLoading && (
				<Spinner
					containerClassName="flex-center-column"
					shouldOverlay={true}
					spinnerSize="spinner-container-default-size"
					useMarginsForCorrectingCentering={false}
				/>
			)}
			<>
				<Graph
					lineColor={Colors.chartGrayDark}
					lineAreaColor={Colors.chartGrayDark}
					chartRef={chartRef}
					height="70vh"
					width="100%"
					options={getPressureGraphOptions()}
					fill={false}
					data={data}
					datasetLabel={dataYLabel}
					datasetScaleId={dataYAxisID}
					extraDataSets={dataSets}
				/>
				<div className="flex-center-row flex-direction-row flex-column-gap-16 padding-2">
					<BiButton colorTheme="org-primary" onClick={onZoomInClick}>
						{localized('ZoomIn')}
					</BiButton>
					<BiButton colorTheme="org-primary" onClick={onZoomOutClick}>
						{localized('ZoomOut')}
					</BiButton>
					<BiButton colorTheme="org-primary-grey" onClick={onZoomResetClick}>
						{localized('Reset')}
					</BiButton>
				</div>
			</>
		</div>
	);
};

export default PressureGraph;
