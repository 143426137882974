import { NotificationHistoryDto } from 'api/api';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import {
	PickupOnDateOrderedNotificationTypeName,
	PickupOrderedNotificationTypeName,
} from 'utilities/notification-constants';
import { upsertArrayOfDataMappedToIdString, upsertDataMappedToIdString } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { NotificationHistoryState } from './types';

export const initialState: NotificationHistoryState = {
	notificationHistories: [],
	isUpdateLoading: false,
};

export function notificationHistoryReducer(
	state: typeof initialState = initialState,
	action: Action
): typeof initialState {
	//fix
	if (isType(action, actions.GetNotificationHistoryAction.done)) {
		const idDataTupleArray = upsertArrayOfDataMappedToIdString(
			action.payload.params.serialNumber,
			action.payload.result,
			state.notificationHistories
		);

		return {
			...state,
			notificationHistories: idDataTupleArray,
		};
	} else if (isType(action, actions.UpdatePickUpAmountAction.started)) {
		return {
			...state,
			isUpdateLoading: true,
		};
	} else if (isType(action, actions.UpdatePickUpAmountAction.failed)) {
		return {
			...state,
			isUpdateLoading: false,
		};
	} else if (isType(action, actions.UpdatePickUpAmountAction.done)) {
		const stateCopy: typeof state = {
			...state,
			isUpdateLoading: false,
		};

		// Shallow copy the histories, if any
		const oldNotificationHistoriesCopy = state.notificationHistories
			.find(idTuple => idTuple.id === action.payload.params.unitId)
			?.data.slice();

		const oldHistoryIndex =
			oldNotificationHistoriesCopy?.findIndex(
				historyData => historyData.id === action.payload.params.historyId
			) ?? -1;

		if (!oldNotificationHistoriesCopy || oldHistoryIndex < 0) return stateCopy;

		// We found the index of the history with the notification event. Perform deep copy it
		const historyCopy: typeof oldNotificationHistoriesCopy[number] = NotificationHistoryDto.fromJS(
			oldNotificationHistoriesCopy[oldHistoryIndex]
		);

		// Find the pick up event, if any, and update the pick up amount
		const pickUpEvent = historyCopy.notificationEvents?.find(
			oldEvent =>
				oldEvent.notificationType?.name === PickupOrderedNotificationTypeName ||
				oldEvent.notificationType?.name === PickupOnDateOrderedNotificationTypeName
		);

		if (!pickUpEvent) return stateCopy;
		pickUpEvent.pickUpOrderedAmount = action.payload.params.pickUpAmount;

		// Replace the original history object with the copy
		oldNotificationHistoriesCopy[oldHistoryIndex] = historyCopy;
		stateCopy.notificationHistories = upsertDataMappedToIdString(
			action.payload.params.unitId,
			oldNotificationHistoriesCopy,
			state.notificationHistories
		);

		return stateCopy;
	}

	return state;
}
