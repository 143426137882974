import { ChartOptions } from 'chart.js';
import React from 'react';
import { biConvertNumberToSeparatedString } from 'utilities/number-helpers';
import Graph, { ChartPoint } from 'view/shared/components/graph/graph';
import { chartGrayDark, chartGrayLight, colorOrange } from 'view/styles/colors';
import './bi-bar-chart.scss';

interface AppProps {
	xAxisLabel?: string;
	yAxisLabel: string;
	datasetLabel?: string;
	aspectRatio: number;
	animationDuration?: number;
	dataUnit?: string;
	data: number[];
	labels: string[];
	showLegend: boolean;
}

type Props = AppProps;

interface State {
	backgroundColor?: string | CanvasGradient;
}

const chartId = 'bi-bar-chart';

export class BiBarChart extends React.PureComponent<Props, State> {
	private canvasEl: HTMLCanvasElement | null | undefined;
	private emptyCanvas = (
		<div className="position-absolute">
			{/* Empty <canvas> purely for the sake of using createLinearGradient(...) */}
			<canvas id={chartId} className="display-none" ref={ref => (this.canvasEl = ref)} />
		</div>
	);

	constructor(props: Props) {
		super(props);

		this.state = {
			backgroundColor: colorOrange, // default
		};
	}

	public componentDidMount() {
		let canvasContext: CanvasRenderingContext2D | null | undefined;
		if (this.canvasEl) {
			canvasContext = this.canvasEl.getContext('2d');
		} else {
			const canvasElement = document.getElementById(chartId);
			if (canvasElement) {
				canvasContext = (canvasElement as HTMLCanvasElement).getContext('2d');
			}
		}

		if (canvasContext) {
			const backgroundColor = canvasContext.createLinearGradient(0, 0, 0, 600);
			backgroundColor.addColorStop(0, chartGrayLight);
			backgroundColor.addColorStop(1, chartGrayDark);

			this.setState({
				backgroundColor,
			});
		}
	}
	public render() {
		if (!this.doWeHaveDataOrAGradientYet() || !this.props.data) {
			return this.emptyCanvas;
		}

		const options: ChartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: this.props.aspectRatio,

			plugins: {
				legend: { display: true, title: { text: this.props.dataUnit ?? 'banan' } },
				tooltip: {
					mode: 'index',
					intersect: true,
					callbacks: {
						label: tooltipItem => {
							let label = '';
							if (tooltipItem.formattedValue && !isNaN(tooltipItem.parsed.y))
								label = biConvertNumberToSeparatedString(
									Math.round(tooltipItem.parsed.y * 10) / 10
								);
							return `${label} ${this.props.dataUnit ?? ''}`;
						},
					},
				},
			},

			scales: {
				y: {
					title: {
						display: true,
						text: this.props.yAxisLabel,
					},
					beginAtZero: true,
				},
				x: {
					grid: {
						display: false,
					},
					ticks: {
						display: false,
					},
				},
			},
		};

		if (this.props.animationDuration !== undefined) options.animation = { duration: this.props.animationDuration };

		if (this.props.showLegend === false) {
			options.plugins!.legend = { display: false };
		}

		if (this.props.xAxisLabel) {
			options.scales!.x = {
				grid: {
					display: false,
				},
				title: {
					display: true,
					text: this.props.xAxisLabel,
				},
			};
		}

		const graphData: ChartPoint[] = this.props.data.map((curr, index) => {
			return { x: this.props.labels[index], y: curr };
		});

		this.props.data.forEach((curr, index) => {
			graphData.push({ x: this.props.labels[index], y: curr });
		});

		return (
			<>
				{!this.doWeHaveDataOrAGradientYet && this.emptyCanvas}
				<Graph
					type="bar"
					data={graphData}
					options={options}
					height="auto"
					lineColor={this.state.backgroundColor}
					lineAreaColor={this.state.backgroundColor}
					datasetLabel={this.props.datasetLabel}
				/>
			</>
		);
	}

	private doWeHaveDataOrAGradientYet = () => {
		if (typeof this.state.backgroundColor === 'string' || !this.props.data) {
			return false;
		}
		return true;
	};
}
