import { AppState } from 'state/store';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { localized, localizedInterpolation } from 'state/i18n';
import './release-notes.scss';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { deleteReleaseNote } from 'state/ducks/release-notes/operations';
import { IGetReleaseNoteDto } from 'api/api';
import ReleaseNoteDialog from './release-note-dialog';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import { Button } from 'primereact/button';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';

interface PropsFromParent {
	releaseNote: IGetReleaseNoteDto;
}

const mapStateToProps = (state: AppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	deleteReleaseNote: async (id: number) => (await deleteReleaseNote(id))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

type State = {
	showDeleteDialog: boolean;
	showEditDialog: boolean;
};

class ReleaseNoteItem extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { showEditDialog: false, showDeleteDialog: false };
	}

	hideDeleteDialog = () => {
		this.setState({ showDeleteDialog: false });
	};

	hideEditDialog = () => {
		this.setState({ showEditDialog: false });
	};

	handleShowDeleteDialog = () => {
		this.setState({ showDeleteDialog: true });
	};

	handleShowEditDialog = () => {
		this.setState({ showEditDialog: true });
	};

	handleSaveEditNote = (note: IGetReleaseNoteDto) => {
		this.hideEditDialog();
	};

	handleDeleteNote = () => {
		this.hideDeleteDialog();
		this.props.deleteReleaseNote(this.props.releaseNote.id);
	};

	deleteDialog = (): JSX.Element => {
		return (
			<BiTextDialog
				onHide={this.hideDeleteDialog}
				visible={this.state.showDeleteDialog}
				title={localized('Delete')}
				subtitle={localizedInterpolation('XDeleteConfirmMsg', { X: this.props.releaseNote.title })}
			>
				<div className="flex-end-row margin-top-10px">
					<BiButton
						colorTheme="org-white"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={this.hideDeleteDialog}
					>
						{localized('Cancel')}
					</BiButton>
					<BiButton
						colorTheme="org-red"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={this.handleDeleteNote}
					>
						{localized('Delete')}
					</BiButton>
				</div>
			</BiTextDialog>
		);
	};

	public render() {
		const header = <div>{this.props.releaseNote.title}</div>;
		const content = <div>{this.props.releaseNote.description?.substr(0, 100)}...</div>;

		const editDelete = (
			<div className="flex-space-between-row content-container-groups">
				<Button
					className="p-button-custom p-button-secondary margin-right-10px"
					label={localized('Edit')}
					onClick={this.handleShowEditDialog}
				/>

				<BiTooltip overlay={localized('Delete')}>
					<div className="flex-container">
						<i onClick={this.handleShowDeleteDialog} className="pi pi-times-circle"></i>
					</div>
				</BiTooltip>
			</div>
		);

		const editDialog = (
			<ReleaseNoteDialog
				note={this.props.releaseNote}
				key={this.props.releaseNote.id}
				onSave={this.handleSaveEditNote}
				visible={this.state.showEditDialog}
				onCancel={this.hideEditDialog}
			/>
		);

		const deleteDialog = this.deleteDialog();

		return (
			<div
				key={this.props.releaseNote.title + 'adminReleaseNote'}
				className={`margin-bottom-10px bi-label-content-item-box-shadow bi-label-content-item-lg release-note-grid min-height-50px`}
			>
				{header}
				{content}
				{editDelete}
				{editDialog}
				{deleteDialog}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNoteItem);
