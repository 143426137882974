import { constants } from './constants';
import actionCreatorFactory from 'typescript-fsa';
import { FileResponse, IGetReleaseNoteDto } from 'api/api';

const releaseNotesCreator = actionCreatorFactory("release-notes");


export const GetReleaseNotesAction = releaseNotesCreator.async<undefined, IGetReleaseNoteDto[]>(constants.GET_RELEASE_NOTES);
export const ReadReleaseNotesAction = releaseNotesCreator.async<number[], number[]>(constants.READ_RELEASE_NOTES);
export const DeleteReleaseNotesAction = releaseNotesCreator.async<number, void>(constants.DELETE_RELEASE_NOTES);
export const EditReleaseNoteAction = releaseNotesCreator.async<IGetReleaseNoteDto, IGetReleaseNoteDto>(constants.EDIT_RELEASE_NOTE);
export const SaveNewReleaseNoteAction = releaseNotesCreator.async<IGetReleaseNoteDto, IGetReleaseNoteDto>(constants.ADD_RELEASE_NOTE);
export const GetReleaseNoteImageAction = releaseNotesCreator.async<string, FileResponse | null>(constants.GET_IMAGE);