import { ComparisonOperator as Comparer } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import { getIcon } from 'view/shared/utils/icon-helper';
import './comparison-operator.scss';

type OperatorMapperText = Record<Comparer, string>;
type OperatorMapperIcon = Record<Comparer, JSX.Element>;

interface Props {
	comparer: Comparer;
	value: Comparer;
	id: string | number;
}

const operatorMapper: OperatorMapperIcon = {
	[Comparer.BiggerThan]: getIcon('greater-than', 16),
	[Comparer.Equal]: getIcon('equal-to', 16),
	[Comparer.SmallerThan]: getIcon('less-than', 16),
	[Comparer.Interval]: getIcon('re-occuring', 16),
};

const operatorMapperTranslations: OperatorMapperText = {
	[Comparer.BiggerThan]: localized('Tooltip_BiggerThan'),
	[Comparer.Equal]: localized('Tooltip_EqualTo'),
	[Comparer.SmallerThan]: localized('Tooltip_SmallerThan'),
	[Comparer.Interval]: localized('Tooltip_Interval'),
};

export const ComparisonOperator: React.FC<Props> = props => {
	const { comparer, value, id } = props;

	const selected = comparer === value;
	const identifier = `${Comparer[comparer]}-${id}`;

	return (
		<>
			<input type="radio" id={identifier} name={`comparer-${id}`} value={comparer} />
			<label className={`operator-root ${selected && 'operator-selected'}`} htmlFor={identifier}>
				<BiTooltip overlay={operatorMapperTranslations[comparer]}>
					<div>{operatorMapper[comparer]}</div>
				</BiTooltip>
			</label>
		</>
	);
};
