import { Action } from "redux";
import { notificationService } from "services/notification-service";
import { localized } from "state/i18n";
import { isType } from "typescript-fsa";
import { deleteValueInArrayCustomId, upsertValueInArrayCustomId } from "utilities/reducer-helpers";
import { UnitWeightState } from "./types";
import * as actions from './actions';

export const initialState: UnitWeightState = {
    unitWeights: [],
};

export function unitWeightReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.CreateUnitWeightAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			unitWeights: upsertValueInArrayCustomId(
				state.unitWeights,
				action.payload.result,
				weight => weight.unitId
			),
		};
	} else if (isType(action, actions.GetUnitWeightAction.done)) {
		return {
			...state,
			unitWeights: upsertValueInArrayCustomId(
				state.unitWeights,
				action.payload.result,
				weight => weight.unitId
			),
		};
    } else if (isType(action, actions.UpdateUnitWeightAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			unitWeights: upsertValueInArrayCustomId(
				state.unitWeights,
				action.payload.result,
				weight => weight.unitId
			),
		};
    } else if (isType(action, actions.DeleteUnitWeightAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			unitWeights: deleteValueInArrayCustomId(
				state.unitWeights,
				action.payload.params.unitId,
				weight => weight.unitId
			),
		};
	}
    
	return state;
}
