import { TableId, TableSettings, TableSettingsClient } from 'api/api';
import { store } from 'index';
import { Dispatch } from 'redux';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { tableSettingsClient } from '../api-clients';
import { getTableSettingsAction, setColumnOrderAction, setColumnSizeAction, setColumnSortingAction } from './actions';
import { UnitHistoryDtoKeys, SortingGeneric, TableColumnWidthInfoGeneric } from './types';

export function setSorting(sorting: SortingGeneric<UnitHistoryDtoKeys>[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(setColumnSortingAction(sorting));

		(await saveSettings())(dispatch);
	};
}

export function setSize(columnWidths: TableColumnWidthInfoGeneric<UnitHistoryDtoKeys>[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(setColumnSizeAction(columnWidths));

		(await saveSettings())(dispatch);
	};
}

export function setOrder(columnOrder: UnitHistoryDtoKeys[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(setColumnOrderAction(columnOrder));

		return (await saveSettings())(dispatch);
	};
}

export async function getSettings() {
	return AsyncOperationBuilder(getTableSettingsAction, apiClient => (apiClient as TableSettingsClient).get(TableId.Histories), TableId.Histories, tableSettingsClient);
}

export function saveSettings() {
	const historyTableSettingsState = store.getState().historyTableSettingsReducer;

	let tableSettings = { ...historyTableSettingsState.tableSettings };
	tableSettings.settingsJson = JSON.stringify({
		columnWidths: historyTableSettingsState.columnWidths,
		columnOrder: historyTableSettingsState.columnOrder,
		sorting: historyTableSettingsState.sorting,
		columns: historyTableSettingsState.columns,
	});
	tableSettings.tableId = TableId.Histories;

	if (tableSettings.id) {
		return AsyncOperationBuilder(getTableSettingsAction, apiClient => (apiClient as TableSettingsClient).put(TableSettings.fromJS(tableSettings)), tableSettings, tableSettingsClient);
	}
	return AsyncOperationBuilder(getTableSettingsAction, apiClient => (apiClient as TableSettingsClient).post((TableSettings.fromJS(tableSettings))), tableSettings, tableSettingsClient);

}
