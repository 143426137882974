import React from 'react';
import VimeoIframe from 'view/components/vimeo-iframe';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { CloseButtonDialog } from './close-button-dialog';

export const VideoButtonDialog: React.FC<{
	buttonLabel: string;
	dialogTitle: string;
	vimeoId: string | null;
	rootClassName?: string;
}> = props => {
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const openDialog = React.useCallback(() => {
		setDialogOpen(true);
	}, [setDialogOpen]);

	const closeDialog = React.useCallback(() => {
		setDialogOpen(false);
	}, [setDialogOpen]);

	return !props.vimeoId ? null : (
		<>
			<BiButton
				onClick={openDialog}
				colorTheme="org-dark-blue-with-grey-text"
				containerTheme="normal-button-corners"
				containerClassName={props.rootClassName}
			>
				<span className="flex-wrap flex-center-row">
					<span className="white-space-nowrap text-bold">{props.buttonLabel}</span>
				</span>
			</BiButton>
			{dialogOpen && (
				<CloseButtonDialog
					header={props.dialogTitle}
					visible={true}
					onHide={closeDialog}
					dismissableMask={true}
				>
					<VimeoIframe videoId={props.vimeoId} />
				</CloseButtonDialog>
			)}
		</>
	);
};

export default VideoButtonDialog;
