import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleDashboardTile } from 'state/ducks/dashboard/operations';
import { initialFilterState } from 'state/ducks/filter-search/reducer';
import { getMachineLocationsFiltered } from 'state/ducks/location/operations';
import { AppState } from 'state/store';
import { useQueryParam } from 'use-query-params';
import { DashboardTileIdQueryParam } from 'utilities/constants';
import { Spinner } from 'view/components/spinner/spinner';
import DynamicMap from './dynamic-map';
import { applyFilterAndSelection } from 'state/ducks/filter-search/operations';

export const DashboardLocation: FC = React.memo(() => {
	const [dashboardTileId] = useQueryParam<number | undefined>(DashboardTileIdQueryParam);
	const dispatch = useDispatch();
	const machines = useSelector((state: AppState) => state.locationReducer.machinesWithLocation);
	const dashboardTile = useSelector((state: AppState) =>
		state.dashboardReducer.content.find(c => c.dashboardBase?.id === dashboardTileId)
	);

	// Get location tile if we haven't got it yet
	useEffect(() => {
		if (dashboardTileId && !dashboardTile) {
			getSingleDashboardTile(dashboardTileId)(dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps,
	}, [dashboardTileId]);

	// Set settings from location tile
	useEffect(() => {
		if (dashboardTile) {
			const tileSettings = dashboardTile.dashboardBase?.staticMapTileSettings;
			const filter = tileSettings?.filter ?? initialFilterState.appliedFilters;
			const selectedDevices = tileSettings?.units?.map(device => device.id);
			applyFilterAndSelection(filter, selectedDevices)(dispatch);

			if (selectedDevices && selectedDevices.length) {
				//todo: fix when model has been corrected
				//getMachineLocations(selectedDevices)(dispatch);
			} else {
				getMachineLocationsFiltered(filter)(dispatch);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps,
	}, [dashboardTile]);

	// Wait until we have fetched the tile
	if (!dashboardTile) {
		return <Spinner />;
	}

	return (
		<DynamicMap
			machinesWithLocation={machines}
			centerLat={dashboardTile?.dashboardBase?.staticMapTileSettings?.latitude}
			centerLng={dashboardTile?.dashboardBase?.staticMapTileSettings?.longitude}
			zoomLevel={dashboardTile?.dashboardBase?.staticMapTileSettings?.zoomLevel}
		/>
	);
});
