import { ICreateUnitDto, IUnitModelDto } from 'api/api';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { containerClient } from 'state/ducks/api-clients';
import { createUnit } from 'state/ducks/machines/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { mapTreeItemsToTreeSelectItems } from 'utilities/array-to-tree';
import { getEmailFromToken } from 'utilities/token-util';
import BiTreeSelect from 'view/components/bi-tree-select/bi-tree-select';
import { TreeSelectItem } from 'view/components/bi-tree-select/tree-select-item';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import BiLabelContentItem from 'view/shared/components/bi-label-content/bi-label-content-item';
import './create-unit.scss';
import { getUnitModels } from 'state/ducks/unit-model/operations';

const mapStateToProps = (state: AppState) => {
	const groups = state.groupsReducer.adminGroups.find(g => g.id === getEmailFromToken());
	let groupTreeSelect: TreeSelectItem[] = [];

	if (groups) {
		groupTreeSelect = mapTreeItemsToTreeSelectItems(groups.data);
	}
	return {
		groupTreeSelect: groupTreeSelect,
		unitModels: state.unitModelReducer.unitModels.filter(unitModel => unitModel.isUserSelectable),
	};
};
const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getUnitModels: async () => (await getUnitModels())(dispatch),
	createUnit: async (createUnitDto: ICreateUnitDto) => (await createUnit(createUnitDto))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
interface State {
	groupId: number;
	serialNumber: string;
	buttonDisabled: boolean;
	selectedUnitModel: IUnitModelDto | null;
}

class CreateUnit extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			groupId: 0,
			serialNumber: '',
			buttonDisabled: false,
			selectedUnitModel: null,
		};
		this.props.getUnitModels();
	}

	public render() {
		if (!this.props.groupTreeSelect.length) {
			return null;
		}

		return (
			<div id="create-unit" className="margin-bottom-20px">
				<div className="flex-direction-column">
					<div className="margin-bottom-5px">
						<h5 className="text-bold">{localized('CreateUnit')}</h5>
					</div>
					<BiLabelContent containerClassName="flex-fill-width">
						{this.getHeaderLabels()}
						{this.getContainerRows()}
					</BiLabelContent>
				</div>
			</div>
		);
	}

	private setNewSerialNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ serialNumber: e.target.value });
	};

	private setMachineType = (e: { value: any }) => {
		this.setState({ selectedUnitModel: e.value });
	};

	private getContainerRows = () => {
		let parentGroupTree = (
			<BiTreeSelect
				onCheckChanged={this.groupsChanged}
				nodes={this.props.groupTreeSelect}
				selectedItems={this.state.groupId && this.state.groupId !== 0 ? [this.state.groupId] : []}
				mustHaveSelection={true}
			/>
		);

		let inputDetails = (
			<div className="flex-space-between-row content-container-container">
				<InputText
					placeholder="Serial number"
					className="input-box-bramidan-1"
					onBlur={this.newSerialNumberOnBlur}
					value={this.state.serialNumber}
					onChange={this.setNewSerialNumber}
				/>
				<Dropdown
					value={this.state.selectedUnitModel}
					options={this.props.unitModels}
					onChange={this.setMachineType}
					optionLabel="name"
					filter={true}
					showClear={true}
					filterBy="name"
					placeholder={localized('unitModel')}
					dataKey="id"
				/>
				<Button
					className="p-button-custom p-button-secondary"
					label={localized('Save')}
					onClick={this.save}
					disabled={this.state.buttonDisabled}
				/>
			</div>
		);

		return (
			<BiLabelContentItem
				containerClassName="margin-bottom-10px"
				defaultClassNames="bi-label-content-item-lg-2"
				label={parentGroupTree}
				content={inputDetails}
			/>
		);
	};

	private newSerialNumberOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
		this.setState({ serialNumber: event.currentTarget.value });
	};

	private groupsChanged = async (groupIds: number[]) => {
		let groupId = groupIds && groupIds.length ? groupIds[0] : 0;
		this.setState({ groupId: groupId });
	};

	private save = async () => {
		if (await this.validate()) {
			this.setState({
				buttonDisabled: true,
			});

			await this.props.createUnit({
				serialNumber: this.state.serialNumber,
				groupId: this.state.groupId,
				unitModelId: this.state.selectedUnitModel?.id!, // This is safe because of validate()
			});

			notificationService.showSuccessMessage(localized('Saved'));
			this.setState({
				serialNumber: '',
				groupId: 0,
				buttonDisabled: false,
				selectedUnitModel: null,
			});
		}
	};

	private validate = async () => {
		if (!this.state.serialNumber || !this.state.groupId || !this.state.selectedUnitModel?.id) {
			notificationService.showWarningMessage(localized('InputFieldsNotFilledOut'));
			return false;
		} else if (await containerClient.hasSerialNumber(this.state.serialNumber)) {
			notificationService.showWarningMessage(localized('Error_SerialnumberAlreadyExist'));
			return false;
		}

		return true;
	};

	private getHeaderLabels = () => {
		let parentGroupTree = (
			<div className="content-container-container">
				<label>{localized('Group')}</label>
			</div>
		);

		let content = (
			<div className="flex-space-between-row content-container-container">
				<label>{localized('SerialNumber')}</label>
				<label>{localized('unitModel')}</label>
				<label className="p-button-custom p-button-secondary" style={{ opacity: 0, marginRight: 20 }}>
					{localized('Save')}
				</label>
			</div>
		);

		return (
			<BiLabelContentItem
				defaultClassNames="bi-label-content-item-lg-2"
				label={parentGroupTree}
				content={content}
				showBoxShadow={false}
			/>
		);
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateUnit);
