import { TableColumnWidthInfo, Sorting, Column } from '@devexpress/dx-react-grid';
import { ITableSettings, IUnitStateHistoryDto } from 'api/api';
import { localized } from 'state/i18n';

export interface HistoryTableSettingsState {
	columnWidths: Array<TableColumnWidthInfoGeneric<UnitHistoryDtoKeys>>;
	columnOrder: UnitHistoryDtoKeys[];
	sorting: Array<SortingGeneric<UnitHistoryDtoKeys>>;
	columns: Array<ColumnGeneric<UnitHistoryDtoKeys>>;
	tableSettings: ITableSettings;
	loaded: boolean;
}

export type UnitHistoryDtoExtraKeys = 'connectionStatusAsSignalStrength' | 'editHistoryDto' | 'updatedDateTuple';
export type UnitHistoryDtoKeys = keyof IUnitStateHistoryDto | UnitHistoryDtoExtraKeys;

export interface TableColumnWidthInfoGeneric<T extends string> extends TableColumnWidthInfo {
	columnName: T;
}

export interface SortingGeneric<T extends string> extends Sorting {
	columnName: T;
}

export interface ColumnGeneric<T extends string> extends Column {
	name: T;
	title: string;
}

// These are the columns that will be rendered.
export const allColumns: Array<ColumnGeneric<UnitHistoryDtoKeys>> = [
	{ name: 'connectionStatusAsSignalStrength', title: localized('connectionStatusAsSignalStrength') },
	// { name: 'origin', title: localized('origin') },
	{ name: 'updatedDateTuple', title: localized('lastestChange') },
	{ name: 'fillLevel', title: localized('fillLevel') },
	{ name: 'balesOnSite', title: localized('balesOnSite') },
	{ name: 'unitsEmptied', title: localized('unitsEmptied') },
	{ name: 'totalRunningHours', title: localized('totalRunningHours') },
	{ name: 'totalNumberOfCycles', title: localized('totalNumberOfCycles') },
	{ name: 'cyclesSinceLastOutput', title: localized('cyclesSinceLastOutput') },
	{ name: 'pressSeries', title: localized('pressSeries') },
	{ name: 'pressSeriesSinceLastOutput', title: localized('pressSeriesSinceLastOutput') },
	{ name: 'weightLastOutput', title: localized('weightLastOutput') },
	{ name: 'errors', title: localized('failureMessage') },
	{ name: 'userDefinedWeight', title: localized('TypedWeight') },
	{ name: 'userDefinedComment', title: localized('comments') },
	{ name: 'editHistoryDto', title: localized('Edit') },
];
