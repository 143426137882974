import { DebtorClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { debtorClient } from '../api-clients';
import { GetDebtorImageAction, GetDebtorAction } from './actions';

export function getCompanyImage(groupId: number) {
	return AsyncOperationBuilder(
		GetDebtorImageAction,
		apiClient => (apiClient as DebtorClient).getCompanyImage(groupId),
		groupId,
		debtorClient
	);
}

export function getDebtor(groupId: number) {
	return AsyncOperationBuilder(
		GetDebtorAction,
		client => (client as DebtorClient).getByGroupId(groupId),
		groupId,
		debtorClient
	);
}
