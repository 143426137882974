import React from 'react';
import './bi-button-base.scss';

type ButtonType = 'bi-button' | 'bi-radiobutton' | 'bi-checkboxbutton';

export type BiButtonColorThemes =
	| 'org-orange'
	| 'org-red'
	| 'org-green'
	| 'org-grey'
	| 'org-primary'
	| 'org-primary-grey'
	| 'org-dark-blue-with-grey-text'
	| 'org-primary-colors-only'
	| 'org-white'
	| 'none';

export type BiButtonContainerThemes =
	| 'big-with-soft-rounded-corners'
	| 'slim-with-rounded-corners'
	| 'normal-button-corners'
	| 'no-corners'
	| 'icon-sized-with-soft-rounded-corners';

export interface BiButtonBaseProps {
	containerClassName?: string;
	containerTheme?: BiButtonContainerThemes;
	colorTheme?: BiButtonColorThemes;
	enableDarkOverlay?: boolean;
}

/**
 * Props we don't want to spread (using '...') into an HTML element.
 * @see {@link removeNonHtmlPropsFromBiButtonImmutable}
 */
interface ReactPropsForRemoval extends BiButtonBaseProps {
	children?: import('react').ReactNode;
}

export function getBiButtonClassName(
	buttonType: ButtonType,
	containerTheme: BiButtonContainerThemes = 'big-with-soft-rounded-corners',
	colorTheme?: BiButtonColorThemes
) {
	const containerClassName = getBiButtonContainerClassName(containerTheme);
	const colorClassName = getBiButtonColorClassName(buttonType, colorTheme);
	return `${containerClassName} ${colorClassName}`;
}

export function removeNonHtmlPropsFromBiButtonImmutable<T extends ReactPropsForRemoval>(props: T) {
	const propsCopy = { ...props };
	delete propsCopy.children;
	delete propsCopy.colorTheme;
	delete propsCopy.containerClassName;
	delete propsCopy.containerTheme;
	delete propsCopy.enableDarkOverlay;
	return propsCopy;
}

export function wrapContentInDarkOverlayWhenHover(
	content: JSX.Element,
	containerClassName?: string,
	containerTheme: BiButtonContainerThemes = 'big-with-soft-rounded-corners'
) {
	const containerClass = containerClassName || '';

	switch (containerTheme) {
		case 'slim-with-rounded-corners':
			return <div className={`bi-button-overlay-rounded-corners ${containerClass}`}>{content}</div>;
		case 'normal-button-corners':
			return <div className={`bi-button-overlay-normal-corners ${containerClass}`}>{content}</div>;
		case 'no-corners':
			return <div className={containerClass}>{content}</div>;
		case 'icon-sized-with-soft-rounded-corners':
			return (
				<div className={`bi-button-overlay-icon-with-soft-rounded-corners ${containerClass}`}>{content}</div>
			);
		case 'big-with-soft-rounded-corners':
		default:
			return <div className={`bi-button-overlay-soft-rounded-corners ${containerClass}`}>{content}</div>;
	}
}

function getBiButtonContainerClassName(
	containerTheme: BiButtonContainerThemes = 'big-with-soft-rounded-corners'
): string {
	switch (containerTheme) {
		case 'slim-with-rounded-corners':
			return `bi-button-slim-with-rounded-corners bi-button-slim-border-spacing`;
		case 'no-corners':
			return '';
		case 'normal-button-corners':
			return 'bi-button-overlay-normal-corners bi-button-icon-border-spacing';
		case 'icon-sized-with-soft-rounded-corners':
			return `bi-button-icon-sized-with-soft-rounded-corners bi-button-icon-border-spacing`;
		case 'big-with-soft-rounded-corners':
		default:
			return `bi-button-big-with-soft-rounded-corners bi-button-big-border-spacing`;
	}
}

function getBiButtonColorClassName(buttonType: ButtonType, colorTheme?: BiButtonColorThemes): string {
	return colorTheme && colorTheme !== 'none' ? `${buttonType}-${colorTheme}` : '';
}
