import { constants } from './constants';
import { TableColumnWidthInfoGeneric, MachineDtoKeys, ColumnGeneric } from './types';
import { ITableSettings } from 'api/api';
import { Sorting } from '@devexpress/dx-react-grid';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory("table-settings");

export const setSelectedMachinesAction = actionCreator<number[]>(
  constants.SET_SELECTED_MACHINES
);
export const setUserSelectedMachinesAction = actionCreator<number[]>(
  constants.SET_USER_SELECTED_MACHINES
);
export const deleteTableSettingsAction = actionCreator.async<number, void>(
  constants.DELETE_TABLE_SETTING
);

export const addMachineTableSettingAction = actionCreator.async<ITableSettings, ITableSettings>(
  constants.ADD_MACHINE_TABLE_SETTING
);
export const setMachineSelectedAction = actionCreator<number>(
  constants.SET_MACHINE_SELECTED
);

export const selectOnlyOneMachineAction = actionCreator<number>(
  constants.SET_ONE_MACHINE_SELECTED
);

export const setMachineUnSelectedAction = actionCreator<number>(
  constants.SET_MACHINE_UNSELECTED
);

export const setColumnOrderAction = actionCreator<MachineDtoKeys[]>(
  constants.SET_COLUMN_ORDER
);
export const setColumnSizeAction = actionCreator<TableColumnWidthInfoGeneric<MachineDtoKeys>[]>(constants.SET_COLUMN_SIZES);
export const setColumnSortingAction = actionCreator<Sorting[]>(constants.SET_COLUMN_SORTING);
export const setColumnsAction = actionCreator<ColumnGeneric<MachineDtoKeys>[]>(constants.SET_COLUMNS);
export const setNameAction = actionCreator<string>(constants.SET_NAME);
export const setCurrentTableSettingIdAction = actionCreator<number>(constants.SET_CURRENT_TABLE_SETTING_ID);
export const getTableSettingsAction = actionCreator.async<undefined, ITableSettings[] | null>(constants.GET_TABLE_SETTINGS);
export const saveTableSettingsAction = actionCreator.async<{}, ITableSettings | null>(constants.SAVE_TABLE_SETTINGS);

export const setLockedColumnsAction = actionCreator<MachineDtoKeys[]>(
  constants.SET_COLUMNS_LOCKED
);

export const setDefaultTableSettingsAction = actionCreator.async(constants.SET_DEFAULT_TABLE_SETTINGS);
