import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPressureChart, GetUnitCycles, ResetUnitCycles } from 'state/ducks/machine-history/operations';
import { AppState } from 'state/store';
import { initEndDate, initStartDate } from '../history/history';
import PressureGraphTable from './pressure-graph-table';

interface Props {
	unitSerialNumber: string;
}

const PressureGraphTableWrapper: FC<Props> = props => {
	const { unitSerialNumber } = props;
	const dispatch = useDispatch();
	const didMount = useRef<boolean>(false);
	const unitCycles = useSelector((state: AppState) => state.unitHistoryReducer.unitCycles.unitCycles);
	const selectedUnitCycle = useSelector(
		(state: AppState) => state.unitHistoryReducer.pressureChartState.selectedUnitCycleTimestamp
	);

	// Get unit cycles
	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
			const getUnitCyclesFiltered = async () => {
				dispatch(await GetUnitCycles(unitSerialNumber, initStartDate, initEndDate));
			};

			getUnitCyclesFiltered();
		}
	}, [dispatch, unitCycles, unitSerialNumber]);

	// Get data for latest unit cycle
	useEffect(() => {
		if (selectedUnitCycle === undefined && unitCycles && unitCycles.length > 0) {
			const getInitialGraphData = async () => {
				dispatch(await getPressureChart(unitSerialNumber, unitCycles[0]));
			};

			getInitialGraphData();
		}
	}, [dispatch, selectedUnitCycle, unitCycles, unitSerialNumber]);

	// Clear data in redux on unmount
	useEffect(() => {
		return () => {
			dispatch(ResetUnitCycles());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <PressureGraphTable unitSerialNumber={unitSerialNumber} data={unitCycles ?? []} />;
};

export default PressureGraphTableWrapper;
