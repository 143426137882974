import { Sorting } from '@devexpress/dx-react-grid';
import { TableId, TableSettings, TableSettingsClient } from 'api/api';
import { store } from 'index';
import { Dispatch } from 'redux';
import { localized } from 'state/i18n';
import { Action } from 'typescript-fsa';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { tableSettingsClient } from '../api-clients';
import { SetSkipStateAction } from '../machines/actions';
import { addMachineTableSettingAction, deleteTableSettingsAction, getTableSettingsAction, saveTableSettingsAction, selectOnlyOneMachineAction, setColumnOrderAction, setColumnsAction, setColumnSizeAction, setColumnSortingAction, setCurrentTableSettingIdAction, setDefaultTableSettingsAction, setLockedColumnsAction, setMachineSelectedAction, setMachineUnSelectedAction, setNameAction, setSelectedMachinesAction, setUserSelectedMachinesAction } from './actions';
import { defaultTableSettings } from './reducer';
import { basicColumns, ColumnGeneric, MachineDtoKeys, TableColumnWidthInfoGeneric, TableSettingsList, TableSettingsState } from './types';

export function resetTableSettings(tableSettingId: number) {
	return AsyncOperationBuilder(
		deleteTableSettingsAction,
		apiClient => (apiClient as TableSettingsClient).delete(tableSettingId),
		tableSettingId,
		tableSettingsClient
	);
}

export function addMachineTableSetting() {
	let jsonString: string = JSON.stringify({
		columnWidths: defaultTableSettings.columnWidths,
		columnOrder: defaultTableSettings.columnOrder,
		sorting: defaultTableSettings.sorting,
		columns: defaultTableSettings.columns,
		columnsLocked: defaultTableSettings.columnsLocked,
	});

	let tableSetting = TableSettings.fromJS({ id: 0, tableId: TableId.Machines, settingsJson: jsonString, settingsName: localized('Name'), isActive: true });
	return AsyncOperationBuilder(
		addMachineTableSettingAction,
		apiClient => (apiClient as TableSettingsClient).post(tableSetting),
		tableSetting,
		tableSettingsClient
	)
}

export function setSorting(sorting: Sorting[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(setColumnSortingAction(sorting));
		dispatch(SetSkipStateAction({ skip: 0 }));

		const dispatchFunc = await saveCurrentTablesetting();
		if (dispatchFunc) {
			dispatchFunc(dispatch);
		}
	};
}

export function setSize(columnWidths: TableColumnWidthInfoGeneric<MachineDtoKeys>[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(setColumnSizeAction(columnWidths));

		const dispatchFunc = await saveCurrentTablesetting();
		if (dispatchFunc) {
			dispatchFunc(dispatch);
		}
	};
}

export function setlockColumns(columns: MachineDtoKeys[]) {
	return (dispatch: Dispatch<Action<string[]>>) => {
		dispatch(setLockedColumnsAction(columns));
	};
}

export function setOrder(columnOrder: MachineDtoKeys[]) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(setColumnOrderAction(columnOrder));

		const dispatchFunc = await saveCurrentTablesetting();
		if (dispatchFunc) {
			dispatchFunc(dispatch);
		}
	};
}

// Selection was applied by code
export function setSelectedMachines(selected: number[]) {
	return (dispatch: Dispatch<Action<number[]>>) => {
		dispatch(setSelectedMachinesAction(selected));
	};
}

// Selection was applied by user
export function setUserSelectedMachines(selected: number[]) {
	return (dispatch: Dispatch<Action<number[]>>) => {
		dispatch(setUserSelectedMachinesAction(selected));
	};
}

export function selectMachine(deviceId: number) {
	return (dispatch: Dispatch<Action<number>>) => {
		dispatch(setMachineSelectedAction(deviceId));
	};
}

export function selectOnlyOneMachine(deviceId: number) {
	return (dispatch: Dispatch<Action<any>>) => {
		dispatch(selectOnlyOneMachineAction(deviceId));
	};
}

export function deselectMachine(deviceId: number) {
	return (dispatch: Dispatch<Action<number>>) => {
		dispatch(setMachineUnSelectedAction(deviceId));
	};
}

export function setName(name: string) {
	return async (dispatch: Dispatch<any>) => {
		dispatch(setNameAction(name));
	};
}

export function setColumns(columns: ColumnGeneric<MachineDtoKeys>[]) {
	if (columns.length === 0)
		columns = basicColumns.filter(col => col.name === "moreInfo" || col.name === "select");	//Table crashes if no columns are present. 
	return async (dispatch: Dispatch<any>) => {
		dispatch(setColumnsAction(columns));
	};
}

export function setCurrentTableSettingId(id: number) {
	return (dispatch: Dispatch<Action<number>>) => {
		dispatch(setCurrentTableSettingIdAction(id));
	};
}

export async function getSettings() {
	return AsyncOperationBuilder(
		getTableSettingsAction,
		apiClient => (apiClient as TableSettingsClient).getAllMachineTableSettings(),
		undefined,
		tableSettingsClient
	);
}

export async function setDefaultTableSettings() {
	return AsyncOperationBuilder(
		setDefaultTableSettingsAction,
		apiClient => (apiClient as TableSettingsClient).setDefaultTableSettings(),
		undefined,
		tableSettingsClient
	);
}

function getTableSettingList(id?: number) {
	const tableSettingsState = store.getState().tableSettingsReducer as TableSettingsState;
	let currentId = id || tableSettingsState.currentTableSettingId;

	if (currentId === defaultTableSettings.tableSettings.id) return;	//Dont push default setttings to db
	return tableSettingsState.tableSettingsList.find(ts => id ? ts.tableSettings.id === id : ts.tableSettings.id === currentId);
}

export function saveCurrentTablesetting() {
	let tableSettingsList = getTableSettingList();
	if (tableSettingsList)
		return saveTableSetting(tableSettingsList);
}


export function saveTablesettingWithValues(settingId: number, columns: ColumnGeneric<MachineDtoKeys>[], name?: string) {
	let tableSettingsList: TableSettingsList | undefined = getTableSettingList(settingId);
	if (!tableSettingsList) return;

	tableSettingsList.columns = columns;

	if (name) tableSettingsList.tableSettings.settingsName = name;

	return saveTableSetting(tableSettingsList);
}


function saveTableSetting(tableSettingsList: TableSettingsList) {
	tableSettingsList.tableSettings.settingsJson = JSON.stringify({
		columnWidths: tableSettingsList.columnWidths,
		columnOrder: tableSettingsList.columnOrder,
		sorting: tableSettingsList.sorting,
		columns: tableSettingsList.columns,
		columnsLocked: tableSettingsList.columnsLocked.filter(colLock => tableSettingsList.columns.map(col => col.name).includes(colLock)),
	});
	tableSettingsList.tableSettings.tableId = TableId.Machines;
	tableSettingsList.tableSettings.isActive = true;
	let tableSetting = TableSettings.fromJS(tableSettingsList.tableSettings);

	return AsyncOperationBuilder(
		saveTableSettingsAction,
		apiClient => (apiClient as TableSettingsClient).put(tableSetting),
		tableSetting,
		tableSettingsClient
	);
}