import { IdDataTuple, IdNumber, IdString } from './types-util';
import { IUnitDetailsDto } from 'api/api';

type IdPropNames = string[];

export function upsertArrayOfDataMappedToId<T>(
	id: number,
	newDataArray: T[],
	oldArrayOfData: IdDataTuple<typeof newDataArray>[]
): IdDataTuple<typeof newDataArray>[] {
	const tuple: IdDataTuple<typeof newDataArray> = {
		id,
		data: newDataArray,
	};

	return upsertValueInArray(oldArrayOfData, tuple);
}

export function upsertArrayOfDataMappedToIdString<T>(
	id: string,
	newDataArray: T[],
	oldArrayOfData: IdDataTuple<typeof newDataArray, string>[]
): IdDataTuple<typeof newDataArray, string>[] {
	const tuple: IdDataTuple<typeof newDataArray, string> = {
		id,
		data: newDataArray,
	};

	return upsertValueInArray(oldArrayOfData, tuple);
}

export function upsertArrayOfDataMappedToDynamicIds<T>(
	id: number,
	newDataArray: T,
	oldArrayOfData: IdDataTuple<typeof newDataArray>[],
	idPropNames: IdPropNames
): IdDataTuple<typeof newDataArray>[] {
	const tuple: IdDataTuple<typeof newDataArray> = {
		id,
		data: newDataArray,
	};

	return upsertValueInArrayWithDynamicIds(oldArrayOfData, tuple, idPropNames);
}

export function upsertValueInArrayCustomId<T>(old: T[], updated: T, idFn: (t: T) => number) {
	if (updated === null || updated === undefined)	
	{
		return old;
	}
	const index = old.findIndex(o => idFn(o) === idFn(updated));
	return upsert(old, updated, index);
}

export function deleteValueInArrayCustomId<T>(old: T[], id: number, idFn: (t: T) => number) {
	const index = old.findIndex(o => idFn(o) === id);
	return remove(old, index);
}

export function upsertDataMappedToId<T>(
	id: number,
	newData: T,
	oldArrayOfData: IdDataTuple<typeof newData>[]
): IdDataTuple<typeof newData>[] {
	const tuple: IdDataTuple<typeof newData> = {
		id,
		data: newData,
	};

	return upsertValueInArray(oldArrayOfData, tuple);
}

export function upsertDataMappedToIdString<T>(
	id: string,
	newData: T,
	oldArrayOfData: IdDataTuple<typeof newData, string>[]
): IdDataTuple<typeof newData, string>[] {
	const tuple: IdDataTuple<typeof newData, string> = {
		id,
		data: newData,
	};

	return upsertValueInArray(oldArrayOfData, tuple);
}

export function upsertDataMappedToIdInArray<T extends IdString>(
	id: string,
	newData: T,
	oldArrayOfData: IdDataTuple<T[], string>[]
): typeof oldArrayOfData {
	const idIndex = oldArrayOfData.findIndex(o => o.id === id);
	if (idIndex < 0) {
		const tuple: IdDataTuple<typeof newData[], string> = {
			id,
			data: [newData],
		};

		oldArrayOfData.push(tuple);
		return oldArrayOfData;
	}

	const currArrayOfData = oldArrayOfData[idIndex].data;
	const indexOfNewDataInOldArray = currArrayOfData.findIndex(o => o.id === newData.id);
	const newArray = upsert(currArrayOfData, newData, indexOfNewDataInOldArray);

	oldArrayOfData[idIndex] = { ...oldArrayOfData[idIndex], data: newArray };

	return [...oldArrayOfData];
}

export function upsertDataMappedToDynamicIdsInArray<T>(
	id: number,
	newData: T,
	oldArrayOfData: IdDataTuple<typeof newData[]>[],
	idPropNames: IdPropNames
): typeof oldArrayOfData {
	const idIndex = oldArrayOfData.findIndex(o => o.id === id);
	if (idIndex < 0) {
		const tuple: IdDataTuple<typeof newData[]> = {
			id,
			data: [newData],
		};

		oldArrayOfData.push(tuple);
		return oldArrayOfData;
	}

	const currArrayOfData = oldArrayOfData[idIndex].data;
	const indexOfNewDataInOldArray = currArrayOfData.findIndex(o =>
		compareObjectWithDynamicIdPropNames(o, newData, idPropNames)
	);

	const newArray = upsert(currArrayOfData, newData, indexOfNewDataInOldArray);

	oldArrayOfData[idIndex].data = newArray;

	return oldArrayOfData;
}

export function upsertValueInArray<T extends IdNumber | IdString>(old: T[], updated: T): typeof old {
	const index = old.findIndex(o => o.id === updated.id);
	return upsert(old, updated, index);
}

export function upsertValueInArray1<T extends IdNumber>(old: T[], updated: T): typeof old {
	const index = old.findIndex(o => o.id === updated.id);
	return upsert(old, updated, index);
}

export function upsertValueInArrayWithDynamicIds<T>(old: T[], updated: T, idPropNames: IdPropNames): typeof old {
	const index = old.findIndex(o => compareObjectWithDynamicIdPropNames(o, updated, idPropNames));
	return upsert(old, updated, index);
}

// Returns the union (outer join) of two arrays
export function upsertValuesInArray<T extends IdNumber | IdString>(old: T[], updated: T[]): typeof old {
	if (!updated) return old;
	let oldAndNew = updated.concat(old);

	// Filter out duplicates - if an object is present in both 'old' and 'updated', then the one from old will be removed
	return oldAndNew.filter((item, pos) => oldAndNew.findIndex(o => o.id === item.id) === pos);
}

export function upsert<T>(old: T[], updated: T, index: number) {
	//Need to be a clone, since redux have problems when mutating the state
	let clonedOld = [...old];
	if (index > -1) {
		clonedOld[index] = updated;
	} else {
		clonedOld.push(updated);
	}

	return clonedOld;
}

export function update<T>(old: T[], updated: T, index: number) {
	//Need to be a clone, since redux have problems when mutating the state
	if (index > -1) {
		let clonedOld = [...old];
		clonedOld[index] = updated;
		return clonedOld;
	}

	return old;
}

export function removeValueFromArray<T extends IdNumber | IdString>(arr: T[], id: number | string): T[] {
	const index = arr.findIndex(x => x.id === id);
	return remove(arr, index);
}

export function remove<T>(arr: T[], index: number) {
	if (index === -1) {
		return arr;
	}
	const arra = [...arr];
	arra.splice(index, 1);

	return arra;
}

/**
 * Compares two objects with the given set of prop names. The comparisons are of the form
 * `obj1[i] === obj2[i]` where `i` is **assumed** to exist in both `obj1` and `obj2`.
 *
 * Example: `obj1["groupId"] === obj2["groupId"]`.
 * @param obj1 The first object
 * @param obj2 The second object
 * @param idPropNames A list of prop names corresponding to the object's ids
 * @returns true if the given props of both objects are strictly equal to each other. False otherwise.
 */
function compareObjectWithDynamicIdPropNames<T>(obj1: T, obj2: T, idPropNames: IdPropNames): boolean {
	for (let i of idPropNames) {
		if (obj1[i] !== obj2[i]) {
			return false;
		}
	}
	return true;
}

export function updateMachineWeighingSerialNumber<T extends IUnitDetailsDto>(
	old: IdDataTuple<T, string>[],
	unitId: string,
	newWeighingSN?: string
): typeof old {
	const updated: typeof old = [...old];
	const index = updated.findIndex(o => o.id === unitId);

	if (index > -1) {
		let device = updated[index];
		device = { ...device };
		device.data.weightId = newWeighingSN;
		updated[index] = device;
	}

	return updated;
}
