import { connect } from "react-redux";
import React from "react";
import { Dispatch } from "redux";
import { filterDateStringsKeys } from "state/ducks/filter-date-search/types";
import "./filter-date-bar.scss";
import { Button } from "primereact/button";
import { localized } from "state/i18n";
import {
  setFilterStartDate,
  setFilterEndDate
} from "state/ducks/filter-date-search/operations";
import BiCalendar from 'view/shared/components/bi-calendar/bi-calendar';
import { AppState } from "state/store";

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
  const datesFromProp =
    state.filterDateSearchReducer.filterDateStrings[
      ownProps.idOfFilterDateBarParent
    ];

  return {
    startDate: datesFromProp && datesFromProp.startDate,
    endDate: datesFromProp && datesFromProp.endDate
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
  setFilterStartDate: (id: filterDateStringsKeys, input: Date) =>
    setFilterStartDate(id, input)(dispatch),
  setFilterEndDate: (id: filterDateStringsKeys, input: Date) =>
    setFilterEndDate(id, input)(dispatch)
});

interface AppProps {
  idOfFilterDateBarParent: filterDateStringsKeys;
  handleFilterByDates: (startDate?: Date, endDate?: Date) => Promise<void>;
  minStartDate?: Date;
  initialStartDate?: Date;
  initialEndDate?: Date;
  className?: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  AppProps;

class FilterDateBar extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    if (this.props.initialStartDate) {
      this.props.setFilterStartDate(
        this.props.idOfFilterDateBarParent,
        this.props.initialStartDate
      );
    }

    if (this.props.initialEndDate) {
      this.props.setFilterEndDate(
        this.props.idOfFilterDateBarParent,
        this.props.initialEndDate
      );
    }
  }

  public handleChangeStartDate = (e: { originalEvent: Event; value: Date }) => {
    this.props.setFilterStartDate(this.props.idOfFilterDateBarParent, e.value);
  };

  public handleChangeEndDate = (e: { originalEvent: Event; value: Date }) => {
    this.props.setFilterEndDate(this.props.idOfFilterDateBarParent, e.value);
  };

  public handleClickSearch = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!this.props.startDate && !this.props.endDate) {
      // What, no dates set? Then no filter for you!
      return;
    }

    await this.props.handleFilterByDates(
      this.props.startDate,
      this.props.endDate
    );
  };

  public render() {
    const {
      initialStartDate,
      initialEndDate,
      minStartDate,
      startDate,
      endDate
    } = this.props;
    const currStartDate = startDate || initialStartDate;
    const currEndDate = endDate || initialEndDate;

    return (
      <div className={`flex-direction-row flex-center-column flex-wrap ${this.props.className}`}>
        <span className="margin-right-2">{localized("StartDate")}</span>
        <BiCalendar
          className="p-inputtext-background-color-metal-grey"
          value={currStartDate}
          onChange={this.handleChangeStartDate}
          readOnlyInput={true}
          minDate={minStartDate}
          maxDate={this.props.endDate}
        />

        <span className="margin-left-40px margin-right-2">
          {localized("EndDate")}
        </span>
        <BiCalendar
          className="p-inputtext-background-color-metal-grey"
          value={currEndDate}
          onChange={this.handleChangeEndDate}
          readOnlyInput={true}
          minDate={currStartDate}
        />
        <div className="margin-left-40px">
          <Button
            className="p-button-background-color-metal-grey"
            label={localized("Search")}
            onClick={this.handleClickSearch}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterDateBar);
