import { FileResponse } from 'api/api';

const promptUserWithFileOptions = (filename: string, url: string) => {
	const link = document.createElement('a');
	link.href = url;
	link.target = '_blank';
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const downloadFile = (fileResponse: FileResponse | undefined) => {
	if (!fileResponse) return;
	let blobURL = URL.createObjectURL(fileResponse.data);
	promptUserWithFileOptions(fileResponse.fileName!, blobURL);
};
