import { IGraphReportDto } from 'api/api';
import React from 'react';
import { BiBarChart } from 'view/components/bi-bar-chart/bi-bar-chart';
import './report-graph.scss';

interface AppProps {
	xAxisLabel: string;
	yAxisLabel: string;
	datasetLabel: string;
	graphDataset: IGraphReportDto | undefined;
	aspectRatio: number;
	animationDuration?: number;
	dataUnit?: string;
}

type Props = AppProps;

const chartId = 'report-graph-chart';

export class ReportGraph extends React.PureComponent<Props> {
	private canvasEl: HTMLCanvasElement | null | undefined;
	private emptyCanvas = (
		<div className="position-absolute">
			{/* Empty <canvas> purely for the sake of using createLinearGradient(...) */}
			<canvas id={chartId} className="display-none" ref={ref => (this.canvasEl = ref)} />
		</div>
	);

	public render() {
		if (!this.props.graphDataset?.dates || !this.props.graphDataset?.values) {
			return this.emptyCanvas;
		}

		return (
			<BiBarChart
				data={this.props.graphDataset.values}
				labels={this.props.graphDataset.dates}
				aspectRatio={this.props.aspectRatio}
				datasetLabel={this.props.datasetLabel}
				xAxisLabel={this.props.xAxisLabel}
				yAxisLabel={this.props.yAxisLabel}
				showLegend={true}
				dataUnit={this.props.dataUnit}
				animationDuration={this.props.animationDuration}
			/>
		);
	}
}
