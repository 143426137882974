import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { MachineSelectedState } from './types';
import { UpdateMachinesOwnerGroupNameAction, UpdateMachinesOwnerGroupIdAction } from 'state/ducks/machines/actions';
import { updateMachineOwnerGroupName, updateMachineOwnerGroupIds } from 'utilities/reducer-helpers-update-machine-ownergroup';

export const initialState: MachineSelectedState = {
	callCounter: 0
};

export function machineSelectedReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.GetMachinesSelectedAction.started)) {
		return {
			...state,
			callCounter: action.payload.callCounter
		}
	}
	if (isType(action, actions.GetMachinesSelectedAction.done)) {
		if (state.callCounter === action.payload.params.callCounter) {
			return {
				...state,
				machinesSelected: action.payload.result
			};
		}
	}
	if (isType(action, UpdateMachinesOwnerGroupIdAction.done)) {
		return {
			...state,
			machinesSelected: updateMachineOwnerGroupIds(
				state.machinesSelected || [],
				action.payload.params.MachineIds,
				action.payload.params.destinationGroupId
			)
		};
	}
	if (isType(action, UpdateMachinesOwnerGroupNameAction)) {
		return {
			...state,
			machinesSelected: updateMachineOwnerGroupName(
				state.machinesSelected || [],
				action.payload.MachineIds,
				action.payload.groupName
			)
		};
	}
	return state;
}
