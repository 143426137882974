import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { clearMachinesLocations } from 'state/ducks/location/operations';
import { Routes } from 'state/ducks/routes';
import { selectOnlyOneMachine, setSelectedMachines } from 'state/ducks/table-settings/operations';
import { AppState } from 'state/store';
import { Spinner } from 'view/components/spinner/spinner';
import { getStaticMap } from 'view/components/static-map/static-map';
import { cardGeneralClassNames } from '../constants';
import './bi-map.scss';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	return {
		selectedMachines: state.tableSettingsReducer.selectedMachines,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	setSelectedMachines: (machineIds: number[]) => setSelectedMachines(machineIds)(dispatch),
	selectOnlyOneMachine: (deviceId: number) => selectOnlyOneMachine(deviceId)(dispatch),
	clearMachinesLocations: () => clearMachinesLocations()(dispatch),
});

interface AppProps {
	gpsLocation: { latitude: string | undefined; longitude: string | undefined };
	machineDetailsId: number;
	classNameContainer?: string;
	width?: string;
	height?: string;
	fillLevel?: number | undefined;
	hasModem?: boolean;
	isOnline: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

class BiMap extends React.PureComponent<Props> {
	public render() {
		const { gpsLocation, height, width, fillLevel, hasModem, isOnline } = this.props;
		const cardMapNoPadding = this.props.classNameContainer
			? this.props.classNameContainer
			: `${cardGeneralClassNames.classNameContainer} card-max-width-700-and-margin-spacing card-padding-0 flex-fill-remaining-space border-shadow-all-corners`;
		const machineLocationData = gpsLocation ? (
			getStaticMap(
				gpsLocation,
				fillLevel,
				`${width ? width : 640}` || '640',
				`${height ? height : 640}` || '640',
				hasModem,
				isOnline
			)
		) : (
			<Spinner shouldOverlay={false} spinnerSize="spinner-container-half-size" />
		);

		return (
			machineLocationData && (
				<NavLink
					className={`${cardMapNoPadding} border-shadow-dark-all-corners border-radius-5 map-height`}
					to={Routes.Location}
					onClick={this.setAsSelectedMachine}
				>
					{machineLocationData}
				</NavLink>
			)
		);
	}
	private setAsSelectedMachine = () => {
		this.props.clearMachinesLocations();
		this.props.selectOnlyOneMachine(this.props.machineDetailsId);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withAITracking(reactAI.reactPlugin, BiMap, 'Bi Map'));
