import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { localized } from 'state/i18n';
import { IconDefaultSize } from 'utilities/constants';
import { MinDateOnDb } from 'utilities/machine-util';
import { getIcon, getWifiStrengthIcon } from '../../utils/icon-helper';
import './online-status.scss';

export const getOnlineStatusAsIconAndTextJsx = (
	onlineStatus: boolean,
	sizeForIconAndText: number = IconDefaultSize,
	signalStrength?: number,
	lastOnline?: Date,
	hasModem?: boolean
): JSX.Element | null => {
	if (lastOnline && lastOnline > MinDateOnDb && hasModem) {
		switch (onlineStatus) {
			case false:
				return (
					<div className="flex-direction-row flex-center-column">
						{getIcon('rssi-wifi-slash', sizeForIconAndText)}
						<div className="margin-left-10px">{localized('Offline')}</div>
					</div>
				);
			default:
				const wifiStrengthIcon =
					signalStrength !== undefined ? getWifiStrengthIcon(signalStrength, sizeForIconAndText) : null;
				return (
					<div className="flex-direction-row flex-center-column">
						{wifiStrengthIcon}
						<div className="margin-left-10px">{localized('Online')}</div>
					</div>
				);
		}
	} else {
		return (
			<div className="flex-direction-row flex-center-column">
				<span className="icon-size">
					<FontAwesomeIcon icon={faBan} />
				</span>
				<div className="margin-left-10px">{localized('noSim')}</div>
			</div>
		);
	}
};
