import { IKPIFillLevelTileDto } from 'api/api';
import React from 'react';
import { localized } from 'state/i18n';
import ProgressBar from 'view/shared/components/progress-bar/progress-bar';
import './kpi-fill-level-tile.scss';
import { roundToDecimals } from 'utilities/number-helpers';

interface PropsFromParent {
	tile: IKPIFillLevelTileDto;
}

type Props = PropsFromParent;

export class KPIFillLevelTile extends React.PureComponent<Props> {
	public render() {
		return (
			<>
				<div className="border-bottom-grey margin-bottom-20px" />
				<ProgressBar value={roundToDecimals(this.props.tile.avgFillLevel, 1)} maxValue={100} showValue={true} classes="kpi-fill-level-progress-bar" />
				<p className="text-center text-bold margin-top-20px font-size-normal">{localized('AverageFillLevelForThePast30Days')}</p>
			</>
		);
	}
}
