import {AppState} from 'state/store';
import React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {localized} from 'state/i18n';
import './release-notes.scss';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import {InputText} from 'primereact/inputtext';
import BiTextArea from 'view/components/bi-text/bi-textarea';
import {isNullOrWhitespace} from 'view/shared/components/string-helpers';
import ImageUploader from 'react-images-upload';
import {FileParameter, GetReleaseNoteDto, IGetReleaseNoteDto} from 'api/api';
import {editReleaseNote, saveNewReleaseNote} from 'state/ducks/release-notes/operations';
import {releaseNoteClient} from 'state/ducks/api-clients';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';

const bullCharacterCode = '&bull;';

interface PropsFromParent {
	note?: IGetReleaseNoteDto;
	onSave: (note: IGetReleaseNoteDto) => void;
	onCancel: () => void;
	visible: boolean;
}

const mapStateToProps = (state: AppState) => {
	return {
		//note: state.releaseNoteReducer.notes,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	saveNewReleaseNote: async (note: IGetReleaseNoteDto) => (await saveNewReleaseNote(note))(dispatch),
	editReleaseNote: async (note: IGetReleaseNoteDto) => (await editReleaseNote(note))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

type State = {
	note: IGetReleaseNoteDto;
	image?: File;
};

class ReleaseNotesDialog extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			note: this.props.note ? this.props.note : new GetReleaseNoteDto(),
		};
	}

	onDrop = (imageFiles: File[], imageDataURLs: string[]) => {
		this.setState({
			image: imageFiles[0],
		});
	};

	public render() {
		const allowedToSave: boolean =
			isNullOrWhitespace(this.state.note.title) || isNullOrWhitespace(this.state.note.description);

		return (
			<BiTextDialog
				onHide={this.onCancel}
				visible={this.props.visible}
				className="release-note-dialog"
				title={localized('ReleaseNote')}
				subtitle={localized('ReleaseNoteDialogDescription')}
			>
				<p>
					Hint: Special characters can be used in the description like bullet points (&bull;) by writing "
					{bullCharacterCode}".
					<br />A complete list can be found here:{' '}
					<a target="_blank" rel="noopener noreferrer" href="https://dev.w3.org/html5/html-author/charref">
						https://dev.w3.org/html5/html-author/charref
					</a>
				</p>
				<hr />

				<div>
					<div className="flex-center-column">
						<div className="flex-1">
							<h5>{localized('Title')}</h5>
							<InputText
								className="input-box-bramidan"
								value={this.state.note.title}
								onChange={this.handleSetTitle}
							/>
						</div>
						<div className="flex-1 flex-direction-column text-right grey-text">
							{this.props.note && (
								<span className="">{`${localized(
									'CreatedOn'
								)}: ${this.props.note.createdDate.toLocaleString()}`}</span>
							)}
							{this.props.note && (
								<span className="">{`${localized(
									'LastModifiedOn'
								)}: ${this.props.note.updatedDate.toLocaleString()}`}</span>
							)}
						</div>
					</div>

					<h5 className="margin-top-20px">{localized('Description')}</h5>
					<BiTextArea value={this.state.note.description} onChange={this.handleSetDescription} />

					<ImageUploader
						withIcon={true}
						withPreview={true}
						buttonText={localized('UploadImage')}
						onChange={this.onDrop}
						imgExtension={['.jpg', '.png']}
						maxFileSize={5242880}
						singleImage={true}
						label="Max file size: 5mb, accepted: jpg|png"
					/>
				</div>

				<div className="flex-end-row margin-top-10px">
					<BiButton
						colorTheme="org-white"
						containerTheme="slim-with-rounded-corners"
						containerClassName="margin-right-24px"
						onClick={this.onCancel}
					>
						{localized('Cancel')}
					</BiButton>
					<BiButton
						colorTheme="org-green"
						containerTheme="slim-with-rounded-corners"
						onClick={this.handleSave}
						disabled={allowedToSave}
						title={allowedToSave ? localized('NeedTitleAndDescription') : ''}
					>
						{localized('Save')}
					</BiButton>
				</div>
			</BiTextDialog>
		);
	}

	private handleSave = async () => {
		let note = { ...this.state.note };

		if (this.state.image) {
			let fileName = await this.uploadReleaseNoteImage(this.state.note.id, {
				fileName: this.state.image.name,
				data: this.state.image,
			});

			// let dto: UploadReleaseNoteDto = new UploadReleaseNoteDto();
			// dto.init({
			// 	releaseNoteId: this.props.note.id,
			// 	file: this.state.image,
			// });
			// await this.props.saveImage(dto);

			note.imageName = fileName;
		}

		if (this.state.note.id) {
			await this.props.editReleaseNote(note);
		} else {
			await this.props.saveNewReleaseNote(note);
		}

		this.props.onSave(this.state.note);
	};

	private onCancel = () => {
		// Clear if new note
		if (!this.props.note) {
			this.setState({ note: new GetReleaseNoteDto() });
		}

		this.props.onCancel();
	};

	private handleSetTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		let newNote: IGetReleaseNoteDto = { ...this.state.note };
		newNote.title = e.target.value;
		this.setState({ note: newNote });
	};

	private handleSetDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		let newNote: IGetReleaseNoteDto = { ...this.state.note };
		newNote.description = e.target.value;
		this.setState({ note: newNote });
	};

	private uploadReleaseNoteImage = async (releaseNoteId: number, imageFile: FileParameter | null | undefined) => {
		let fileName = await releaseNoteClient.postNoteImage(releaseNoteId, imageFile);
		return fileName;
		// let formData: FormData = new FormData();
		// formData.append("file", imageFile);
		// formData.append("releaseNoteId", dto.releaseNoteId)

		// return await fetch(`${ApiBaseUrl}/api/releasenote/NoteImage`, {
		// 	method: 'POST',
		// 	body: formData,
		// 	headers: { 'Authorization': 'Bearer ' + getAccessTokenFromLocalStorage(), }
		// })
		// 	.then((response) => response.json())
		// 	.then((fileName: string) => {
		// 		return fileName;
		// 	});
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesDialog);
