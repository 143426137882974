import React, { PureComponent } from 'react';
import { localized } from 'state/i18n';
import { INotificationFillLevelSettingsDto, DayOfWeek } from 'api/api';
import BiMultiselect, { OnChangeEvent } from 'view/components/bi-multiselect/bi-multiselect';

interface FillLevelOption {
    label: string;
    value: number;
}

const fillLevelOptions: FillLevelOption[] = [
    { label: '0%', value: 0 },
    { label: '20%', value: 20 },
    { label: '40%', value: 40 },
    { label: '60%', value: 60 },
    { label: '80%', value: 80 },
    { label: '100%', value: 100 }
];

type Props = {
    day: DayOfWeek;
    onChange: (e: OnChangeEvent, day: DayOfWeek) => void;
    fillLevelSettings: INotificationFillLevelSettingsDto[];
};

class FillLevelMultiselect extends PureComponent<Props> {
    onChange = (e: OnChangeEvent) => {
        this.props.onChange(e, this.props.day);
    }

    render() {
        return (
            <BiMultiselect
                key={'filllevelcardSelect' + this.props.day}
                placeholder={localized('Levels')}
                fixedPlaceholder={true}
                options={fillLevelOptions}
                onChange={this.onChange}
                filter={false}
                selectedItems={this.props.fillLevelSettings
                    .filter((fs) => fs.dayOfWeek === this.props.day)
                    .map(item => item.thresholdPercentage)}
            />
        )
    }
}
export default FillLevelMultiselect;
