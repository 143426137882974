import { AppState } from 'state/store';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { localizedInterpolation } from 'state/i18n';
import { updateFilteredOutMachineIds } from 'state/ducks/notifications/operations';

const mapStateToProps = (state: AppState) => ({
	filteredOut: state.notificationsReducer.filteredOut,
	selectedMachines: state.tableSettingsReducer.selectedMachines,
	allAvailableMachines: state.machineSelectedReducer.machinesSelected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	updateFilteredOutMachineIds: (deviceIds: number[]) => updateFilteredOutMachineIds(deviceIds)(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class NotificationUnitsFilteredOut extends React.PureComponent<Props> {
	componentDidUpdate(prevProps: Props) {
		if (
			prevProps.allAvailableMachines !== this.props.allAvailableMachines ||
			prevProps.selectedMachines !== this.props.selectedMachines
		) {
			this.props.updateFilteredOutMachineIds(this.props.selectedMachines);
		}
	}

	public render() {
		if (this.props.filteredOut > 0) {
			return (
				<div className="flex-wrap flex-center-row">
					<span className="white-space-nowrap">
						{localizedInterpolation('XUnitsFilteredOut', {
							X: this.props.filteredOut,
						})}
					</span>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationUnitsFilteredOut);
