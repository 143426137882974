import BiTooltip from './bi-tooltip/bi-tooltip';

import { localized } from 'state/i18n';

import BiButton from './buttons/bi-button/bi-button';
import React from 'react'
import { Spinner } from 'view/components/spinner/spinner';

import { images } from 'utilities/images';

export function GetExportButton (handleExportExcel: () => Promise<void>, loadingState: boolean)  {
    return  <div className="align-self-flex-end margin-left-40">
        <BiTooltip overlay={localized('ExportToExcel')}>
            <BiButton
                onClick={handleExportExcel}
                disabled={loadingState}
                colorTheme="org-grey"
                containerTheme="icon-sized-with-soft-rounded-corners"
            >
                {loadingState ? (
                    <Spinner
                        shouldOverlay={false}
                        shouldUseAbsolutePositioning={false}
                        spinnerSize="spinner-container-1-4th-size"
                        useMarginsForCorrectingCentering={false}
                    />
                ) : (
                    <img className="export-icon" src={images.excel} alt={localized('ExcelFile')} />
                )}
            </BiButton>
        </BiTooltip>
    </div>
}