import React from 'react';
import './bi-label-content-item.scss';

interface Props {
	id?: string | number;
	label?: React.ReactNode;
	containerClassName?: string;
	contentContainerClassName?: string;
	containerStyle?: React.CSSProperties;
	dataId?: string;
	showBoxShadow?: boolean;
	content?: React.ReactNode;
	defaultClassNames?: string;
}

// const defaultClassNames = 'flex-direction-row flex-space-between-row bi-label-content-item';

class BiLabelContentItem extends React.PureComponent<Props> {
	public render() {
		const defaultClassNames = this.props.defaultClassNames ? this.props.defaultClassNames : 'bi-label-content-item';
		const biLabelStyle = (this.props.showBoxShadow === false ? "" : "bi-label-content-item-box-shadow");

		return (
			<div
				data-id={this.props.dataId}
				// key={this.props.id}
				className={`${defaultClassNames} ${biLabelStyle} ${this.props.containerClassName || ''}`}
				style={this.props.containerStyle}
			>
				{this.props.label !== undefined && <div>{this.props.label}</div>}
				{/* <div className="flex-wrap">{this.props.label}</div> */}
				<div className={` ${this.props.contentContainerClassName || ''}`}>
					{/* <div className={`flex-direction-row ${this.props.contentContainerClassName || ''}`}> */}
					{this.props.content}
				</div>
			</div>
		);
	}
}

export default BiLabelContentItem;
