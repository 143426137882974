import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';
import { EmailRegExp, GroupNameRegExp } from './constants';
import { AllFieldValidationTypes } from './user-input-validation-util';

interface FieldValidationInfo {
	regExp: RegExp;
	onInvalid: (field?: string) => void;
}

const EmailFieldValidationInfo: FieldValidationInfo = {
	regExp: EmailRegExp,
	onInvalid: onInvalidEmail,
};

const GroupFieldValidationInfo: FieldValidationInfo = {
	regExp: GroupNameRegExp,
	onInvalid: onInvalidGroupName,
};

export function onInvalidEmail() {
	notificationService.showInfoMessage(undefined, localized('UserEmailError'), false, 5000);
}

function onInvalidGroupName() {
	notificationService.showInfoMessage(undefined, localized('UserEmailError'), false, 5000);
}

export function getValidationInfoBasedOnFieldType(fieldType: AllFieldValidationTypes): FieldValidationInfo {
	switch (fieldType) {
		case 'Email':
			return EmailFieldValidationInfo;
		case 'Group':
			return GroupFieldValidationInfo;
		default:
			throw new Error('All field validation types must be specified');
	}
}
