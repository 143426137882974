import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { localized } from 'state/i18n';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { BiButtonColorThemes } from 'view/shared/components/buttons/bi-button-base/bi-button-base';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import { Spinner } from 'view/components/spinner/spinner';
import './dialog-confirm.scss';

type Props = DialogProps & {
	onConfirm: () => void | Promise<void>;
	onCancel: () => void;
	dialogMessage?: string | string[];
	confirmColor?: BiButtonColorThemes;
	confirmMessage?: string;
	cancelMessage?: string;
	showConfirmSpinner?: boolean;
	disabled?: boolean;
	children?: React.ReactNode;
};

export class ConfirmDialog extends React.PureComponent<Props> {
	public render() {
		return (
			<BiTextDialog
				{...this.props}
				title={this.props.header}
				subtitle={this.props.dialogMessage}
				className={`confirm-dialog-container ${this.props.className}`}
			>
				{this.props.children}
				<div className="flex-end-row margin-top-10px">
					<BiButton
						onClick={this.handleCancel}
						colorTheme="org-primary-grey"
						containerClassName="margin-right-24px"
						containerTheme="slim-with-rounded-corners"
						disabled={this.props.showConfirmSpinner}
					>
						{this.props.cancelMessage || localized('Cancel')}
					</BiButton>
					<BiButton
						onClick={this.handleConfirm}
						colorTheme={this.props.confirmColor || 'org-green'}
						containerTheme="slim-with-rounded-corners"
						disabled={this.props.showConfirmSpinner || this.props.disabled}
					>
						{this.props.showConfirmSpinner ? (
							<Spinner
								shouldOverlay={false}
								spinnerSize="spinner-container-1-4th-size"
								useMarginsForCorrectingCentering={false}
							/>
						) : (
							this.props.confirmMessage || localized('Confirm')
						)}
					</BiButton>
				</div>
			</BiTextDialog>
		);
	}

	private handleCancel = () => this.props.onHide();
	private handleConfirm = () => this.props.onConfirm();
}
