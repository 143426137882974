import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMachineLocationsFiltered, getMachineLocations } from 'state/ducks/location/operations';
import { AppState } from 'state/store';
import DynamicMap from './dynamic-map';

export const UnitsLocation: FC = React.memo(() => {
	const dispatch = useDispatch();
	const machines = useSelector((state: AppState) => state.locationReducer.machinesWithLocation);
	const selectedMachines = useSelector((state: AppState) => state.tableSettingsReducer.selectedMachines);
	const appliedFilter = useSelector((state: AppState) => state.filterSearchReducer.appliedFilters);

	useEffect(() => {
		if (selectedMachines && selectedMachines.length) {
			getMachineLocations(selectedMachines)(dispatch);
		} else {
			getMachineLocationsFiltered(appliedFilter)(dispatch);
		}
	}, [appliedFilter, selectedMachines, dispatch]);

	return <DynamicMap machinesWithLocation={machines} />;
});
