import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { upsertDataMappedToId } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { DebtorsState } from './types';

const initialState: DebtorsState = {
	debtorImages: [],
	debtors: [],
	isImageLoading: false,
	isDebtorLoading: false,
};

export function debtorsReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.GetDebtorImageAction.started)) {
		return {
			...state,
			isImageLoading: true,
		};
	} else if (isType(action, actions.GetDebtorImageAction.done)) {
		return {
			...state,
			isImageLoading: false,
			debtorImages: upsertDataMappedToId(action.payload.params, action.payload.result, state.debtorImages),
		};
	} else if (isType(action, actions.GetDebtorImageAction.failed)) {
		return {
			...state,
			isImageLoading: false,
		};
	} else if (isType(action, actions.GetDebtorAction.started)) {
		return {
			...state,
			isDebtorLoading: true,
		};
	} else if (isType(action, actions.GetDebtorAction.done)) {
		return {
			...state,
			isDebtorLoading: false,
			debtors: upsertDataMappedToId(action.payload.params, action.payload.result, state.debtors),
		};
	} else if (isType(action, actions.GetDebtorAction.failed)) {
		return {
			...state,
			isDebtorLoading: false,
		};
	}

	return state;
}
