import { IUnitModelDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('unit-model');

export const getUnitModelsAction = actionCreator.async<undefined, IUnitModelDto[]>(
	constants.GET_UNIT_MODELS
);

export const createUnitModelsAction = actionCreator.async<IUnitModelDto, IUnitModelDto>(
	constants.CREATE_UNIT_MODELS
);

export const updateUnitModelsAction = actionCreator.async<IUnitModelDto, IUnitModelDto>(
	constants.UPDATE_UNIT_MODELS
);

export const deleteUnitModelsAction = actionCreator.async<IUnitModelDto, void>(
	constants.DELETE_UNIT_MODELS
);
