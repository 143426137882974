import { ErrorIdBaler, ErrorIdCompactor, ErrorIdLink, NotificationMachineType } from 'api/api';
import { localizedDynamic } from 'state/i18n';

export type ErrorId = ErrorIdBaler | ErrorIdCompactor | ErrorIdLink;
export type ErrorIdKey = keyof typeof ErrorIdBaler | keyof typeof ErrorIdCompactor | keyof typeof ErrorIdLink;
type TypeOfErrorId = typeof ErrorIdBaler | typeof ErrorIdCompactor | typeof ErrorIdLink;

const machineTypeMapper: Record<NotificationMachineType, TypeOfErrorId | undefined> = {
	[NotificationMachineType.Baler]: ErrorIdBaler,
	[NotificationMachineType.Compactor]: ErrorIdCompactor,
	[NotificationMachineType.Link]: ErrorIdLink,
	[NotificationMachineType.SharkMK3]: ErrorIdCompactor,
	[NotificationMachineType.Container]: undefined, // Containers only have customXtelMessages.
};

export function getLocalizedUnitStatusErrorByErrorString(errorIdString: ErrorIdKey): string {
	return localizedDynamic(`ErrorIds.${errorIdString}`);
}

export function getLocalizedUnitStatusError(errorId: ErrorId, machineType: NotificationMachineType): string {
	const ErrorEnum = machineTypeMapper[machineType];

	if (ErrorEnum) {
		return getLocalizedUnitStatusErrorByErrorString(ErrorEnum[errorId] as ErrorIdKey);
	}

	return `${NotificationMachineType[machineType]}: Error id: ${errorId}`;
}

// TODO: Long descriptions should be specified and implemented
export function getLocalizedUnitStatusErrorLong(errorIdString: ErrorIdKey): string {
	return getLocalizedUnitStatusErrorByErrorString(errorIdString);
}
