import { ReleaseNoteState } from './types';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export const initialState: ReleaseNoteState = {
    notes: [],
    images: [],
}

export function releaseNoteReducer(state: ReleaseNoteState = initialState, action: Action): ReleaseNoteState {
    if (isType(action, actions.GetReleaseNotesAction.done)) {
        return {
            ...state,
            notes: action.payload.result
        }
    }

    else if (isType(action, actions.GetReleaseNoteImageAction.done)) {
        if (!action.payload.result) return state;
        return {
            ...state,
            images: [...state.images, { name: action.payload.params, image: action.payload.result }]
        }
    }

    else if (isType(action, actions.ReadReleaseNotesAction.done)) {
        return {
            ...state,
            notes: state.notes.map(note => {
                if (action.payload.result.includes(note.id)) {
                    let noteCopy = { ...note };
                    noteCopy.isNew = false;
                    return noteCopy;
                }
                else return note;
            })
        }
    }

    else if (isType(action, actions.DeleteReleaseNotesAction.done)) {
        return {
            ...state,
            notes: state.notes.filter(note => note.id !== action.payload.params)
        }
    }

    else if (isType(action, actions.SaveNewReleaseNoteAction.done)) {
        return {
            ...state,
            notes: [...state.notes.concat(action.payload.result)]
        }
    }

    else if (isType(action, actions.EditReleaseNoteAction.done)) {
        return {
            ...state,
            notes: state.notes.map(note => note.id === action.payload.result.id ? action.payload.result : note)
        }
    }

    return state;
}