import { RolesType } from 'api/api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppDispatch, AppState } from 'state/store';
import { hasAtLeast } from './roles-util';
import { getEmailFromToken } from './token-util';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const createHasRequiredRoleSelector = createSelector(
	(state: AppState) => state.groupsReducer.groups,
	(_: AppState, params: { groupId: number; requiredRole: RolesType }) => params,
	(groups, { groupId, requiredRole }) => {
		const userGroups = groups.find(g => g.id === getEmailFromToken());
		const currentGroup = userGroups?.data.find(g => g.id === groupId);
		const userRole = currentGroup?.roleType ?? RolesType.DataOutOfRange;
		return hasAtLeast(userRole, requiredRole);
	}
);

export const createUnitByUnitHistorySelector = createSelector(
	(state: AppState) => state.unitHistoryReducer.unitHistoryInfos,
	(_: AppState, unitId: string) => unitId,
	(histories, unitId) => histories.find(x => x.id === unitId)?.data
);

// Chaining selectors with parameters doesn't seem to be possible unless TS and reselect are upgraded
export const createHasRequiredRoleInHistorySelector = createSelector(
	(_: AppState, params: { unitId: string; requiredRole: RolesType }) => params,
	({ unitId, requiredRole }) => {
		const unitHistory = useSelector((state: AppState) => createUnitByUnitHistorySelector(state, unitId));
		return useSelector((state: AppState) =>
			createHasRequiredRoleSelector(state, { groupId: unitHistory?.ownerGroupId! ?? '', requiredRole })
		);
	}
);

export const createDebtorByGroupIdSelector = createSelector(
	(state: AppState) => state.debtorsReducer.debtors,
	(_: AppState, id: number) => id,
	(data, id) => data.find(x => x.id === id)?.data
);

