import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import './bi-textarea.scss';

interface AppProps {
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onFocusOut?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
	value?: string | number;
	readOnly?: boolean;
	placeholder?: string;
}

type Props = AppProps;
interface BiTextAreaState {
	value: string | number;
}

export default class BiTextArea extends React.PureComponent<Props, BiTextAreaState> {
	constructor(props: Props) {
		super(props);
		this.state = { value: props.value ? props.value : '' };
	}
	public render() {
		return (
			<InputTextarea
				className="bi-textarea"
				rows={3}
				autoResize={true}
				value={this.state.value}
				onChange={this.onTextChanged}
				onBlur={this.props.onFocusOut}
				readOnly={this.props.readOnly}
				placeholder={this.props.placeholder}
			/>
		);
	}

	private onTextChanged = (value: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ value: value.currentTarget.value });

		this.props.onChange && this.props.onChange(value);
	};
}
