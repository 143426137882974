import React from 'react';

interface Props {
	videoId: string;
}

const VIMEO_BASE = 'https://player.vimeo.com/video/';

const getEmbedUrl = (url: string): string => {
	const urlParts = url.split('/');
	const urlSuffix = urlParts.length === 2 ? urlParts[0] + '?h=' + urlParts[1] : url;
	return VIMEO_BASE + urlSuffix;
};

export const VimeoIframe: React.FC<Props> = (props) => {
    const { videoId } = props;
	return <iframe width="960" height="540" src={getEmbedUrl(videoId)} allowFullScreen={true} title="videoDialog"></iframe>;
};

export default VimeoIframe;
