import * as React from 'react';
import { connect } from 'react-redux';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { Dispatch } from 'redux';
import { localizedInterpolation } from 'state/i18n';
import { AppState } from 'state/store';
import './reports-filtered-text.scss';

const mapStateToProps = (state: AppState) => ({
	reportData: state.reportReducer.reportData,
	reportDataType: state.reportReducer.dataType,
	selectedMachines: state.tableSettingsReducer.selectedMachines,
	machineCount: state.machinesReducer.machineCount,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ReportsFilteredOut extends React.PureComponent<Props> {
	public render() {
		if (!this.props.reportData || !this.props.machineCount) return null;

		const count =
			(this.props.selectedMachines.length === 0 ? this.props.machineCount : this.props.selectedMachines.length) -
			this.props.reportData.length;
		if (count > 0) {
			return (
				<div className="flex-wrap flex-center-row">
					<span className="white-space-nowrap">
						{localizedInterpolation('XUnitsFilteredOut', {
							X: count,
						})}
					</span>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilteredOut);
