import React from 'react';
import { IFillLevelPerUnitModelsTileDto } from 'api/api';
import { Chart } from 'primereact/chart';
import { InsertChartItem, DashboardMaxNoOfUniqueItems, GetLineChartOptions } from '../dashboard-util';
import { roundToDecimals } from 'utilities/number-helpers';
import { MaxFillLevelPercent } from 'utilities/machine-util';
import { GraphTableMenuEnum } from '../dashboard-item-menus/filter-graph-table-menu';
import { localized } from 'state/i18n';
import { SimpleColumn, TileTable } from './tile-table';

interface PropsFromParent {
	tile: IFillLevelPerUnitModelsTileDto;
	viewMode: GraphTableMenuEnum;
}

type Props = PropsFromParent;

export class FillLevelPerUnitModelsTile extends React.PureComponent<Props> {

	getTable = (): JSX.Element => {
		if (!this.props.tile.fillLevelPerUnitModel || this.props.tile.fillLevelPerUnitModel.length === 0)
			return <h5>{localized('NoData')}</h5>

		let tableContent: { unitModel: string, percentage: string }[] = this.props.tile.fillLevelPerUnitModel!
			.map(a => { return { unitModel: a.chartName ? a.chartName : '', percentage: a.chartValue + " %" } });

			let cols: SimpleColumn[] = [
				{
					field: "unitModel",
					header: localized('unitModel')
				},
				{
					field: "percentage",
					header: localized('Average')
				}
			];

		return (
			<div>
				<TileTable
					rows={tableContent}
					columns={cols}
					sortingKey={"percentage"}
				/>
			</div>
		)
	}

	getGraph = (): JSX.Element => {
		if (this.props.tile.fillLevelPerUnitModel) {
			const labels: string[] = [];
			const dataSets: number[] = [];
			const backgroundColor: string[] = [];

			for (let i = 0; i < this.props.tile.fillLevelPerUnitModel.length; i++) {
				const chartItem = this.props.tile.fillLevelPerUnitModel[i];
				if (chartItem.chartValue > MaxFillLevelPercent) {
					chartItem.chartValue = MaxFillLevelPercent;
				} else {
					chartItem.chartValue = roundToDecimals(chartItem.chartValue, 2);
				}

				InsertChartItem(chartItem, labels, dataSets, backgroundColor, i % DashboardMaxNoOfUniqueItems);
			}

			const data = {
				labels: labels,
				datasets: [
					{
						data: dataSets,
						backgroundColor: backgroundColor,
						hoverBackgroundColor: backgroundColor,
					},
				],
			};

			const options = GetLineChartOptions(localized("Percent"), 0, 100, "%");

			return (
				<>
					<div className="border-bottom-grey margin-bottom-20px" />
					<div className="content-section implementation">
						<Chart type="bar" data={data} options={options} />
					</div>
				</>
			);
		} else {
			return <div />;
		}
	}

	getContent = (): JSX.Element => {
		if (this.props.viewMode === GraphTableMenuEnum.table)
			return this.getTable();
		if (this.props.viewMode === GraphTableMenuEnum.graph)
			return this.getGraph();

		else return <div />;
	}
	public render() {
		return this.getContent();
	}
}
