import { AdministrationClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { adminstrationClient } from '../api-clients';
import { getDeviceIdWeightIdsAction } from './actions';

export function getDeviceIdWeightIds() {
	return AsyncOperationBuilder(
		getDeviceIdWeightIdsAction,
		apiClient => (apiClient as AdministrationClient).getWeightUnitDtos(),
		undefined,
		adminstrationClient
	);
}
