import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { INotificationColumnSelected } from 'api/api';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { setTemporaryNotificationSelectedColumns } from 'state/ducks/notifications/operations';
import {
	basicNotificationTableColumns,
	defaultNotificationTableColumns,
	NotificationColumnKeys,
} from 'state/ducks/notifications/types';
import { ColumnGeneric } from 'state/ducks/table-settings/types';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import EditNotificationColumnsTable from './edit-notification-columns-table';
import './edit-notification-columns.scss';

interface PropsFromParent {
	onCancel: () => void;
	visible: boolean;
}

interface State {
	selectedTableColumns: ColumnGeneric<NotificationColumnKeys>[];
	allTableColumns: ColumnGeneric<NotificationColumnKeys>[];
}

const mapStateToProps = (state: AppState) => {
	return {
		columnsSelected: state.notificationsReducer.temporaryNotificationDefinition?.columnsSelected,
		notificationDefinitionId: state.notificationsReducer.temporaryNotificationDefinition?.id,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setTemporaryNotificationSelectedColumns: (columns: INotificationColumnSelected[]) =>
		setTemporaryNotificationSelectedColumns(columns)(dispatch),
});

type Props = PropsFromParent & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const getSortedAllColumns = (notificationColumns: INotificationColumnSelected[] | undefined) => {
	let allColumns = basicNotificationTableColumns.sort((a, b) => {
		// Make sure empty lines are sorted to the bottom
		if (a.name.includes('newLine')) return 1;

		if (b.name.includes('newLine')) return -1;

		return a.title.localeCompare(b.title);
	});

	if (!notificationColumns) return allColumns;

	notificationColumns = notificationColumns.sort((a, b) => a.sortingIndex - b.sortingIndex);
	notificationColumns.forEach((currElement: INotificationColumnSelected, toIndex: number) => {
		let fromIndex = allColumns.findIndex(ac => ac?.name === currElement.columnName); // Look up current position
		if (fromIndex >= 0) {
			let columnToMove = allColumns[fromIndex]; // Get element

			allColumns.splice(fromIndex, 1); // Remove from current position
			allColumns.splice(toIndex, 0, columnToMove); // Insert at new position
		}
	});

	return allColumns;
};

const getTableColumnsFromNotificationColumnsSelected = (
	notificationColumns: INotificationColumnSelected[] | undefined,
	allColumns: ColumnGeneric<NotificationColumnKeys>[]
) => {
	let selectedColumns: ColumnGeneric<NotificationColumnKeys>[] = [];

	if (!notificationColumns) return defaultNotificationTableColumns;
	notificationColumns.forEach(s => {
		let column = allColumns.find(ac => ac.name === s.columnName);

		if (column) selectedColumns.push(column);
	});

	return selectedColumns ?? defaultNotificationTableColumns;
};

class EditNotificationColumnsDialog extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			allTableColumns: [],
			selectedTableColumns: [],
		};
	}

	componentDidMount() {
		this.init();
	}

	init = () => {
		let allColumns = getSortedAllColumns(this.props.columnsSelected as INotificationColumnSelected[] | undefined);
		let selectedTableColumns = getTableColumnsFromNotificationColumnsSelected(
			this.props.columnsSelected as INotificationColumnSelected[] | undefined,
			allColumns
		);

		this.setState(
			{
				allTableColumns: allColumns,
				selectedTableColumns: selectedTableColumns,
			},
			this.saveSelectedNotificationColumns
		);
	};

	saveSelectedNotificationColumns = () => {
		let selectedNotificationColumns: INotificationColumnSelected[] = [];

		this.state.selectedTableColumns.forEach(selected => {
			let index = this.state.allTableColumns.findIndex(ac => ac.name === selected.name);
			let currId = this.props.columnsSelected?.find(col => col.columnName === selected.name)?.id; // If this column was already selected then reuse the ID

			if (index >= 0) {
				// findIndex return -1 for no match
				let notificationColumn: INotificationColumnSelected = {
					id: currId ?? 0,
					sortingIndex: index,
					columnName: selected.name,
					notificationDefinitionId: this.props.notificationDefinitionId ?? 0,
				} as INotificationColumnSelected;

				selectedNotificationColumns.push(notificationColumn);
			}
		});

		this.props.setTemporaryNotificationSelectedColumns(selectedNotificationColumns);
		this.props.onCancel();
	};

	onReorder = (columns: ColumnGeneric<NotificationColumnKeys>[]) => {
		this.setState({ allTableColumns: columns });
	};

	onSelection = (columns: ColumnGeneric<NotificationColumnKeys>[]) => {
		this.setState({ selectedTableColumns: columns });
	};

	onCancel = () => {
		this.init();
		this.props.onCancel();
	};

	render() {
		return (
			<BiTextDialog
				id="edit-notification-columns-dialog"
				onHide={this.onCancel}
				visible={this.props.visible}
				closable={true}
				blockScroll={false}
				title={localized('CustomizeEmail')}
			>
				<div className="edit-notification-columns-dialog-container">
					<p>
						{localized('CustomizeEmailExplained1')}
						<br />
						{localized('CustomizeEmailExplained2')}
						<br />
						{localized('CustomizeEmailExplained3')}
						<br />
						{localized('CustomizeEmailExplained4')}
					</p>
					<div className="edit-notification-columns-table-container">
						<EditNotificationColumnsTable
							onCancel={this.props.onCancel}
							allTableColumns={this.state.allTableColumns}
							selectedTableColumns={this.state.selectedTableColumns}
							onReorder={this.onReorder}
							onSelection={this.onSelection}
						></EditNotificationColumnsTable>
					</div>
					<div className="flex-end-row margin-top-10px">
						<BiButton
							onClick={this.onCancel}
							colorTheme="org-red"
							containerTheme="slim-with-rounded-corners"
							containerClassName="margin-right-24px"
						>
							{localized('Cancel')}
						</BiButton>

						<BiButton
							onClick={this.saveSelectedNotificationColumns}
							colorTheme={'org-green'}
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Save')}
						</BiButton>
					</div>
				</div>
			</BiTextDialog>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, EditNotificationColumnsDialog, 'EditNotificationColumnsDialog'));
