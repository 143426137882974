import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { localized, localizedInterpolation } from 'state/i18n';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import WhiteCard from 'view/shared/components/cards/white-card';
import { getIcon } from 'view/shared/utils/icon-helper';
import './bale-settings.scss';

type BigButtonProps = {
	name: string;
	onClick: () => void;
};

type EditButtonProps = {
	text: string;
	value: number | undefined;
	onClick: () => void;
};

type Props = {
	title: string;
	description1: string;
	description2?: string;
	icon: string;
	tooltip?: string[];
	editButton?: EditButtonProps;
	bigButton?: BigButtonProps;
	dissableBtn?: boolean;
};

const BaleSettingCard: FC<Props> = React.memo((props: Props) => {
	const tooltipOverlay: JSX.Element = (
		<div>
			<b>{props.title}</b>
			{props.tooltip?.map(tt => (
				<p key={tt}>{tt}</p>
			))}
		</div>
	);

	const tooltip: JSX.Element | undefined = props.tooltip ? (
		<BiTooltip overlay={tooltipOverlay}>
			<FontAwesomeIcon icon={faQuestionCircle} />
		</BiTooltip>
	) : undefined;

	return (
		<>
			<WhiteCard
				classNameContainer="bale-setting-card-container"
				subtitle={props.title}
				topRightElement={tooltip}
			>
				<div className="flex-container">
					<img className="setting-image" src={props.icon} alt={'Bale Setting'} />
					<div className="flex-fill-width flex-direction-column">
						{props.description1}
						<br />
						{props.description2}

						{props.editButton && (
							<div className="flex-center-column flex-space-between-row">
								<b>
									{props.editButton.text}: {props.editButton.value ?? localized('Unknown')}
								</b>
								<BiButton
									colorTheme="org-primary"
									containerTheme="icon-sized-with-soft-rounded-corners"
									onClick={props.editButton.onClick}
									disabled={props.dissableBtn}
									title={
										props.dissableBtn
											? localizedInterpolation('SetXfirst', {
													X: localized('AutomaticBalesOnSiteReset'),
											  })
											: ''
									}
								>
									{getIcon('pencil', 14)}
								</BiButton>
							</div>
						)}

						{props.bigButton && (
							<BiButton
								onClick={props.bigButton.onClick}
								colorTheme="org-primary"
								containerTheme="normal-button-corners"
								containerClassName="big-button"
								disabled={props.dissableBtn}
							>
								{props.bigButton.name}
							</BiButton>
						)}
					</div>
				</div>
			</WhiteCard>
		</>
	);
});

export default BaleSettingCard;
