import { DayOfWeek, INotificationFillLevelSettingsDto, INotificationTypeDto } from 'api/api';
import { Card } from 'primereact/card';
import React, { PureComponent } from 'react';
import { localized, localizedDynamic } from 'state/i18n';
import { OnChangeEvent } from 'view/components/bi-multiselect/bi-multiselect';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { getDefaultFillLevelSettings, makeINotificationFillLevelSettingDto } from '../../notification-helper';
import FillLevelMultiselect from './fill-level-multiselect';
import './fill-level.scss';

const fillLevelSettingsOrDeafault = (
	fillLevelSettings?: INotificationFillLevelSettingsDto[],
	notificationType?: INotificationTypeDto
): INotificationFillLevelSettingsDto[] => {
	if (!notificationType) return [];
	if (!fillLevelSettings) return getDefaultFillLevelSettings(notificationType);
	let filteredSettings: INotificationFillLevelSettingsDto[] = fillLevelSettings.filter(
		set => set.machineType === notificationType.machineType
	);
	if (filteredSettings.length === 0) return getDefaultFillLevelSettings(notificationType);

	return filteredSettings;
};

type Props = {
	onCancel: () => void;
	onSave: (settings: INotificationFillLevelSettingsDto[], notificationTypeId: number) => void;
	notificationType: INotificationTypeDto | undefined;
	fillLevelSettings?: INotificationFillLevelSettingsDto[];
};

interface State {
	fillLevelSettings: INotificationFillLevelSettingsDto[];
}

class FillLevelDialog extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			fillLevelSettings: fillLevelSettingsOrDeafault(this.props.fillLevelSettings, this.props.notificationType),
		};
	}

	componentDidUpdate(preProps: Props) {
		if (
			preProps.fillLevelSettings !== this.props.fillLevelSettings ||
			preProps.notificationType !== this.props.notificationType
		)
			this.setState({
				fillLevelSettings: fillLevelSettingsOrDeafault(
					this.props.fillLevelSettings,
					this.props.notificationType
				),
			});
	}

	handleFillLevelChange = (e: OnChangeEvent, day: DayOfWeek) => {
		let fillLevelSettings: INotificationFillLevelSettingsDto[] = e.value.map((val: number) =>
			makeINotificationFillLevelSettingDto(val, day, this.props.notificationType!)
		);
		fillLevelSettings.push(...this.state.fillLevelSettings.filter(set => set.dayOfWeek !== day));
		this.setState({ fillLevelSettings: fillLevelSettings });
	};

	makeFillLevelCardsByDay = (day: DayOfWeek): JSX.Element[] => {
		let percentages: number[] = this.state.fillLevelSettings
			.filter(set => set.dayOfWeek === day)
			.map(set => set.thresholdPercentage)
			.sort((a, b) => a - b);
		let cards: JSX.Element[] = percentages.map(percentage => (
			<Card key={day + percentage}>&#8805; {percentage}%</Card>
		));

		cards.unshift(
			<FillLevelMultiselect
				key={`${day}filllevelselector`}
				onChange={this.handleFillLevelChange}
				day={day}
				fillLevelSettings={this.state.fillLevelSettings}
			/>
		);
		for (let i: number = cards.length; i < 7; i++) cards.push(<div key={day + i} />);
		return cards;
	};

	makeFillLevelCards = (): JSX.Element => {
		return (
			<div className="fill-level-grid-content">
				{this.makeFillLevelCardsByDay(DayOfWeek.Monday)}
				{this.makeFillLevelCardsByDay(DayOfWeek.Tuesday)}
				{this.makeFillLevelCardsByDay(DayOfWeek.Wednesday)}
				{this.makeFillLevelCardsByDay(DayOfWeek.Thursday)}
				{this.makeFillLevelCardsByDay(DayOfWeek.Friday)}
				{this.makeFillLevelCardsByDay(DayOfWeek.Saturday)}
				{this.makeFillLevelCardsByDay(DayOfWeek.Sunday)}
			</div>
		);
	};

	onSave = () => {
		this.props.onSave(this.state.fillLevelSettings, this.props.notificationType!.id);
	};

	render() {
		if (typeof this.props.notificationType === 'undefined') return null;
		return (
			<BiTextDialog
				id="fill-level-dialog-container"
				onHide={this.props.onCancel}
				visible={true}
				closable={false}
				blockScroll={true}
				title={localized('fillLevel')}
				subtitle={localized('CustomFillLevelExplained')}
			>
				<div className="fill-level-dialog">
					<div className="fill-level-grid-header">
						<b>{localizedDynamic('Weekday.Monday')}</b>
						<b>{localizedDynamic('Weekday.Tuesday')}</b>
						<b>{localizedDynamic('Weekday.Wednesday')}</b>
						<b>{localizedDynamic('Weekday.Thursday')}</b>
						<b>{localizedDynamic('Weekday.Friday')}</b>
						<b>{localizedDynamic('Weekday.Saturday')}</b>
						<b>{localizedDynamic('Weekday.Sunday')}</b>
					</div>

					{this.makeFillLevelCards()}

					<br />
					<div className="flex-end-row margin-top-10px">
						<BiButton
							onClick={this.props.onCancel}
							colorTheme="org-red"
							containerTheme="slim-with-rounded-corners"
							containerClassName="margin-right-24px"
						>
							{localized('Cancel')}
						</BiButton>
						<BiButton
							onClick={this.onSave}
							colorTheme={'org-green'}
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Save')}
						</BiButton>
					</div>
				</div>
			</BiTextDialog>
		);
	}
}
export default FillLevelDialog;
