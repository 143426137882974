import { IUnitCycleDto, IUnitStateHistoryDto, UnitStateHistoryClient, UnitStateHistoryDto } from 'api/api';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { setCorrectTimeStamp } from 'view/shared/components/date-to-string';
import { unitStateHistoryClient } from '../api-clients';
import {
	ClearUnitHistoryAction,
	GetPressureChartAction,
	GetUnitCyclesAction,
	GetUnitHistoryFilteredAction,
	GetUnitHistoryInfoAction,
	ResetUnitCyclesAction,
	UpdateUnitHistoryAction,
} from './actions';

export async function getUnitHistoryFilteredByDates(id: string, startDate?: Date, endDate?: Date) {
	let correctStartDate = setCorrectTimeStamp(startDate, false, false);
	let correctEndDate = setCorrectTimeStamp(endDate, false, true);

	return AsyncOperationBuilder(
		GetUnitHistoryFilteredAction,
		apiClient => (apiClient as UnitStateHistoryClient).getFilteredByDates(id, correctStartDate, correctEndDate),
		{ serialNumber: id },
		unitStateHistoryClient
	);
}

export async function getPressureChart(id: string, unitCycle: IUnitCycleDto) {
	return AsyncOperationBuilder(
		GetPressureChartAction,
		apiClient => (apiClient as UnitStateHistoryClient).getPressureChart(id, unitCycle.id, unitCycle.timestamp),
		{ serialNumber: id, startDate: unitCycle.timestamp },
		unitStateHistoryClient
	);
}

export async function GetUnitCycles(id: string, startDate: Date, endDate: Date) {
	return AsyncOperationBuilder(
		GetUnitCyclesAction,
		apiClient => (apiClient as UnitStateHistoryClient).getUnitCyclesFilteredByDates(id, startDate, endDate),
		{
			unitSerialNumber: id,
			startDate: startDate,
			endDate: endDate,
		},
		unitStateHistoryClient
	);
}

export function ResetUnitCycles() {
	return (dispatch: Dispatch<any>) => {
		dispatch(ResetUnitCyclesAction());
	};
}

export async function getUnitHistoryInfo(unitId: string) {
	return AsyncOperationBuilder(
		GetUnitHistoryInfoAction,
		apiClient => (apiClient as UnitStateHistoryClient).getInfo(unitId),
		{ serialNumber: unitId },
		unitStateHistoryClient
	);
}

export async function UpdateUnitHistoryRow(id: string, row: IUnitStateHistoryDto) {
	return AsyncOperationBuilder(
		UpdateUnitHistoryAction,
		apiClient => (apiClient as UnitStateHistoryClient).putHistoryRow(id, UnitStateHistoryDto.fromJS(row)),
		{ serialNumber: id, historyRow: row },
		unitStateHistoryClient
	);
}

export function clearUnitHistory(id: string) {
	return (dispatch: Dispatch<Action<{}>>) => {
		dispatch(ClearUnitHistoryAction(id));
	};
}
