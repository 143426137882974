import { IUnitModelDto, UnitModelClient, UnitModelDto } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { unitModelClient } from '../api-clients';
import { createUnitModelsAction, deleteUnitModelsAction, getUnitModelsAction, updateUnitModelsAction } from './actions';

export function getUnitModels() {
	return AsyncOperationBuilder(
		getUnitModelsAction,
		apiClient => (apiClient as UnitModelClient).getUnitModels(),
		undefined,
		unitModelClient
	);
}

export function createUnitModel(model: IUnitModelDto) {
	return AsyncOperationBuilder(
		createUnitModelsAction,
		apiClient => (apiClient as UnitModelClient).createUnitModel(UnitModelDto.fromJS(model)),
		model,
		unitModelClient
	);
}

export function updateUnitModel(model: IUnitModelDto) {
	return AsyncOperationBuilder(
		updateUnitModelsAction,
		apiClient => (apiClient as UnitModelClient).updateUnitModel(UnitModelDto.fromJS(model)),
		model,
		unitModelClient
	);
}

export function deleteUnitModel(model: IUnitModelDto) {
	return AsyncOperationBuilder(
		deleteUnitModelsAction,
		apiClient => (apiClient as UnitModelClient).deleteUnitModel(UnitModelDto.fromJS(model)),
		model,
		unitModelClient
	);
}
