import { IUnitDetailsDto, DropdownDataDto, IBaleStorageDto } from 'api/api';
import { constants } from './constants';
import actionCreatorFactory from 'typescript-fsa';
import { UnitFavouriteChangeType } from './types';


const actionCreator = actionCreatorFactory('machine-details');

export const GetMachineDetailsAction = actionCreator.async<{ serialNumber: string }, IUnitDetailsDto>(
	constants.GET_MACHINE_DETAILS
);
export const putMachineDetailsAction = actionCreator.async<IUnitDetailsDto, IUnitDetailsDto>(
	constants.PUT_MACHINE_DETAILS
);
export const GetDropdownOptions = actionCreator.async<{}, DropdownDataDto>(constants.GET_DROPDOWN_OPTIONS);
export const UpdateMachineWeighingSerialNumberAction = actionCreator.async<
	{ unitId: string; weighingSerialNumber?: string },
	void
>(constants.UPDATE_MACHINE_WEIGHING_SERIAL_NUMBER);

// Baler functions
export const UpsertBaleStorageAction = actionCreator.async<IBaleStorageDto, IBaleStorageDto>(
	constants.UPSERT_BALE_STORAGE
);

export const UpdateIsFavouriteAction = actionCreator<UnitFavouriteChangeType>(constants.UPDATE_IS_FAVOURITE);
