import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllServiceVideosOperation } from 'state/ducks/service-video/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import './admin-service-videos.scss';
import { ServiceVideoListItem } from './service-video-list-item';

interface Props {}

export const AdminServiceVideos: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();
	const items = useSelector((state: AppState) => state.serviceVideoReducer.videos);

	const getAllServiceVideos = useCallback(async () => {
		(await getAllServiceVideosOperation())(dispatch);
	}, [dispatch]);
	useEffect(() => {
		getAllServiceVideos();
	}, [getAllServiceVideos]);

	return (
		<div id="service-video">
			<div className="service-video-grid padding-left-20px padding-right-20px">
				<b>{localized('Title')}</b>
				<b>{localized('VideoId')}</b>
				<b>{localized('unitModel')}</b>
			</div>
			{items.map(i => (
				<ServiceVideoListItem item={i} key={`serviceVideoList:${i.id}`} />
			))}
		</div>
	);
});
