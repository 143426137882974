import { IUserSettings, UserSettingsClient, UserSettings } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { getUserSettingsAction, upsertUserSettingsAction } from './actions';
import { userSettingsClient } from '../api-clients';

export async function getUserSettings() {
	return AsyncOperationBuilder(
		getUserSettingsAction,
		apiClient => (apiClient as UserSettingsClient).getByUserId(),
		{},
		userSettingsClient
	);
}

export async function upsertUserSettings(
	userSettings: IUserSettings
) {
	return AsyncOperationBuilder(
		upsertUserSettingsAction,
		apiClient =>
			(apiClient as UserSettingsClient).upsertUserSettings(userSettings as UserSettings),
		userSettings,
		userSettingsClient
	);
}
