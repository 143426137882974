import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { AuthState } from './types';

export const initialState: AuthState = {
    isAuthenticated: undefined
}

export function authReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
    if (isType(action, actions.SetIsAuthAction)) {
        return {
            ...state,
            isAuthenticated: action.payload
        }
    }

    return state;
}