import { GetReleaseNoteDto, IGetReleaseNoteDto, ReleaseNoteClient } from "api/api";
import { AsyncOperationBuilder } from "utilities/api-helper";
import { releaseNoteClient } from "../api-clients";
import { DeleteReleaseNotesAction, EditReleaseNoteAction, GetReleaseNoteImageAction, GetReleaseNotesAction, ReadReleaseNotesAction, SaveNewReleaseNoteAction } from "./actions";

export const getReleaseNotes = () =>
	AsyncOperationBuilder(
		GetReleaseNotesAction,
		apiClient => (apiClient as ReleaseNoteClient).get(),
		undefined,
		releaseNoteClient
	);

export const ReadReleaseNotes = (notes: IGetReleaseNoteDto[]) => {
	let noteIds = notes.filter(note => note.isNew).map(note => note.id);

	return AsyncOperationBuilder(
		ReadReleaseNotesAction,
		apiClient => (apiClient as ReleaseNoteClient).postLog(noteIds),
		noteIds,
		releaseNoteClient
	);
}


export const GetReleaseNoteImage = (name: string) => {
	return AsyncOperationBuilder(
		GetReleaseNoteImageAction,
		apiClient => (apiClient as ReleaseNoteClient).getReleaseNoteImage(name),
		name,
		releaseNoteClient
	);
}

export const deleteReleaseNote = (id: number) => {
	return AsyncOperationBuilder(
		DeleteReleaseNotesAction,
		apiClient => (apiClient as ReleaseNoteClient).deleteNote(id),
		id,
		releaseNoteClient
	);
}

export const saveNewReleaseNote = (note: IGetReleaseNoteDto) => {
	let releaseNote: GetReleaseNoteDto = new GetReleaseNoteDto();
	releaseNote.init(note);

	return AsyncOperationBuilder(
		SaveNewReleaseNoteAction,
		apiClient => (apiClient as ReleaseNoteClient).postNote(releaseNote),
		note,
		releaseNoteClient
	);
}

export const editReleaseNote = (note: IGetReleaseNoteDto) => {
	let releaseNote: GetReleaseNoteDto = new GetReleaseNoteDto();
	releaseNote.init(note);

	return AsyncOperationBuilder(
		EditReleaseNoteAction,
		apiClient => (apiClient as ReleaseNoteClient).updateNote(releaseNote),
		note,
		releaseNoteClient
	);
}