import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { deleteValueInArrayCustomId, upsertValueInArrayCustomId } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { UnitManufacturerState } from './types';

const initialState: UnitManufacturerState = {
	unitManufactures: [],
};

export function unitManufacturerReducer(state: UnitManufacturerState = initialState, action: Action): UnitManufacturerState {
	if (isType(action, actions.getUnitManufacturersAction.done)) {
		return {
			...state,
			unitManufactures: action.payload.result,
		};
	}
	if (isType(action, actions.createUnitManufacturersAction.done)) {
		return {
			...state,
			unitManufactures: upsertValueInArrayCustomId(state.unitManufactures, action.payload.result, obj => obj.id),
		};
	}
	if (isType(action, actions.updateUnitManufacturersAction.done)) {
		return {
			...state,
			unitManufactures: upsertValueInArrayCustomId(state.unitManufactures, action.payload.result, obj => obj.id),
		};
	}
	if (isType(action, actions.deleteUnitManufacturersAction.done)) {
		return {
			...state,
			unitManufactures: deleteValueInArrayCustomId(state.unitManufactures, action.payload.params.id, obj => obj.id),
		};
	}

	return state;
}
