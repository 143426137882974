import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import BiTextArea from 'view/components/bi-text/bi-textarea';
import BiCalendar from './bi-calendar/bi-calendar';
import './editable-value.scss';
import { LabelValues } from './label-value-card';
import TextInput from './text-input';

export type editableValueMode = 'dropdown' | 'datePicker' | 'text' | 'textArea';

interface AppProps {
	translationKey: React.ReactText;
	valueForLabel: LabelValues;
	callbackSave?: (value: any) => void;
	mode: editableValueMode;
	selection?: any;
	dropdownOptions?: any;
}

interface State {
	hideInput: boolean;
	valueForLabel: LabelValues;
	translationKey: React.ReactText;
}

type Props = AppProps;
export default class EditableValue extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			hideInput: true,
			valueForLabel: undefined,
			translationKey: this.props.translationKey,
		};
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (nextProps.valueForLabel !== prevState.valueForLabel) {
			return {
				...prevState,
				valueForLabel: nextProps.valueForLabel,
				hideInput: true,
			};
		}
		return {
			...prevState,
			valueForLabel: nextProps.valueForLabel,
		};
	}

	public render() {
		const editableValueClassNames = `flex-direction-row editableValue `;
		return (
			<div>
				<div className={editableValueClassNames + `${this.state.hideInput ? '' : 'hidden'}`}>
					<span className="valueForLabel" key={`${this.props.translationKey}Value`}>
						{this.props.valueForLabel || '-'}
					</span>
					<i onClick={this.onEditClicked} className="pi pi-pencil cursor-pointer height-20"></i>
				</div>
				<div className={editableValueClassNames + `${this.state.hideInput ? 'hidden' : ''}`}>
					{this.props.mode === 'dropdown' ? (
						<>
							<Dropdown
								value={this.props.selection}
								options={this.props.dropdownOptions}
								onChange={this.onSaveEdit}
								filter={true}
								filterPlaceholder="Filter"
								filterBy="label,value"
							/>
							<i onClick={this.onEditClicked} className="pi pi-times cursor-pointer height-20"></i>
						</>
					) : this.props.mode === 'datePicker' ? (
						<>
							<BiCalendar
								className="date-picker"
								showIcon={false}
								readOnlyInput={true}
								value={this.props.selection}
								onChange={this.onSaveEdit}
							/>
							<i onClick={this.onEditClicked} className="pi pi-times cursor-pointer height-20"></i>
						</>
					) : this.props.mode === 'textArea' ? (
						<BiTextArea value={this.state.valueForLabel} onFocusOut={this.onSaveEdit} />
					) : (
						<TextInput
							hidden={this.state.hideInput}
							value={this.state.valueForLabel}
							callbackSave={this.onSaveEdit}
						></TextInput>
					)}
				</div>
			</div>
		);
	}

	private onEditClicked = (e: any) => {
		this.setState({
			...this.state,
			valueForLabel: this.props.valueForLabel,
			hideInput: !this.state.hideInput,
		});
	};

	private onSaveEdit = async (e: any) => {
		const { value } = e.target;

		if (this.props.callbackSave !== undefined) {
			await this.props.callbackSave!(value);
		}

		this.setState({
			...this.state,
			hideInput: true,
		});
	};
}
