import React from 'react';
import { i18nTranslationKey, localized } from 'state/i18n';
import VimeoIframe from 'view/components/vimeo-iframe';

export const Tutorial: React.FC<{ id: string; title: i18nTranslationKey }> = props => {
	return (
		<div key={props.title}>
			<div className="flex-center-column flex-fill-width note-header">
				<h5>{localized(props.title)}</h5>
			</div>
			<VimeoIframe videoId={props.id} />
		</div>
	);
};
 