import { TableSettings } from 'api/api';
import { Action } from 'redux';
import { allColumns, GPSHistoryTableSettingsState, withoutHomeLocationColumns } from './types';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export const initialState: GPSHistoryTableSettingsState = {
	columnOrder: [
		"updatedDate",
		"homeLocation",
		"currentLocation",
		"distance",
		"source",
		"triggeredBy",
	],
	columnWidths: [
		{ columnName: 'updatedDate', width: 155 },
		{ columnName: 'homeLocation', width: 300 },
		{ columnName: 'currentLocation', width: 300 },
		{ columnName: 'distance', width: 200 },
		{ columnName: 'source', width: 155 },
		{ columnName: 'triggeredBy', width: 155 },
	],
	sorting: [
		{
			columnName: 'updatedDate',
			direction: 'desc',
		},
	],
	allColumns: allColumns,
	withoutHomeLocationColums: withoutHomeLocationColumns,
	tableSettings: new TableSettings(),
	loaded: false,
};

export function gpsHistoryTableSettingsReducer(
	state: typeof initialState = initialState, action: Action
): typeof initialState {
	if (isType(action, actions.setColumnOrderAction)) {
		return {
			...state,
			columnOrder: action.payload,
		};
	}
	if (isType(action, actions.setColumnSizeAction)) {
		return {
			...state,
			columnWidths: action.payload,
		};
	}
	if (isType(action, actions.setColumnSortingAction)) {
		return {
			...state,
			sorting: action.payload,
		};
	}

	return state;
}
