import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import {  RefreshRateState } from './types';

export const initialState: RefreshRateState = {
    refreshRates: {
        home: "NEVER",
    },
}

export function tableUtilityReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
    if (isType(action, actions.SetRefreshRateAction)) {
        const refreshRatesCopy = { ...state.refreshRates };
        refreshRatesCopy[action.payload.id] = action.payload.rate;

        return {
            ...state,
            refreshRates: refreshRatesCopy,
        };
    }

    return state;
}
