import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getSettings } from 'state/ducks/history-table-settings/operations';
import { getNotificationHistory } from 'state/ducks/notification-history/operations';
import { AppState } from 'state/store';
import { initEndDate, initStartDate } from '../history/history';
import NotificationHistoryTable from './notification-history-table';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	return {
		currNotificationHistories: state.notificationHistoryReducer.notificationHistories.find(
			curr => curr.id === ownProps.machineId
		),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getNotificationHistory: async (serialNumber: string, startDate: Date, endDate: Date) =>
		(await getNotificationHistory(serialNumber, startDate, endDate))(dispatch),
	getSettings: async () => (await getSettings())(dispatch),
});

interface AppProps {
	isLoading?: boolean;
	machineId: string;
	autoStartHistoryQuery?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

class NotificationHistoryTableWrapper extends React.PureComponent<Props> {
	public async componentDidMount() {
		await this.props.getSettings();
		this.props.autoStartHistoryQuery === true &&
			(await this.props.getNotificationHistory(
				this.props.machineId,
				initStartDate,
				initEndDate
			));
	}

	public render() {
		return (
			<div className="notification-history-table-container">
				<NotificationHistoryTable
					isLoading={this.props.isLoading}
					data={this.props.currNotificationHistories && this.props.currNotificationHistories.data}
					unitId={this.props.machineId}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHistoryTableWrapper);
