import { DialogProps } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { getServiceToolCode } from 'state/ducks/service-tool-code/operations';
import { localized } from 'state/i18n';
import { ServiceToolCode } from 'view/components/table/details/service-tool-code';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';

type Props = DialogProps & {
	serviceToolCode?: string;
};

interface State {
	newUnitId: string;
	serviceCode?: string;
}

export class DialogServiceCodeNewUnit extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			newUnitId: '',
			serviceCode: '',
		};
	}

	public render() {
		return (
			<div>
				{this.state.serviceCode ? (
					<div>
						<div>
							<BiTextDialog {...this.props} title={localized('ServiceToolCode')}>
								<ServiceToolCode serviceToolCode={this.state.serviceCode}></ServiceToolCode>
								<div className="flex-end-row margin-top-10px">
									<BiButton
										onClick={this.handleGenerateNewCode}
										colorTheme="org-green"
										containerTheme="slim-with-rounded-corners"
										disabled={!this.state.newUnitId}
									>
										{localized('GenerateNewServiceToolCode')}
									</BiButton>
								</div>
							</BiTextDialog>
						</div>
					</div>
				) : (
					<div>
						<BiTextDialog {...this.props} title={localized('ServiceToolCodeNewUnit')}>
							<h5 className="margin-vertical-20px">{localized('SetUnitIdForServiceCode')}</h5>
							<div className="p-grid">
								<div className="p-col-fixed text-bold align-self-center">{localized('unitId')}</div>
								<div className="p-col">
									<InputText
										placeholder={localized('EnterANewUnitId')}
										value={this.state.newUnitId}
										onChange={this.setNewUnitId}
									/>
								</div>
							</div>
							<div className="flex-end-row margin-top-10px">
								<BiButton
									onClick={this.handleGenerateCodeConfirm}
									colorTheme="org-green"
									containerTheme="slim-with-rounded-corners"
									disabled={!this.state.newUnitId}
								>
									{localized('GenerateServiceToolCode')}
								</BiButton>
							</div>
						</BiTextDialog>
					</div>
				)}
			</div>
		);
	}

	private setNewUnitId = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newUnitId: e.target.value });
	};

	private handleGenerateNewCode = () => {
		this.setState({ newUnitId: '' });
		this.setState({ serviceCode: '' });
	};

	private handleGenerateCodeConfirm = () => {
		this.getServiceToolCode();
	};

	private getServiceToolCode = async () => {
		const serviceCode = await getServiceToolCode(this.state.newUnitId);

		if (serviceCode) {
			this.setState({ serviceCode: serviceCode });
		} else {
			this.setState({ serviceCode: '' });
		}
	};
}
