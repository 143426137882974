import { NotificationMachineType } from 'api/api';
import React, { FC } from 'react';
import { localized } from 'state/i18n';
import BiCheckboxButton from 'view/shared/components/buttons/bi-checkboxbutton/bi-checkboxbutton';
import { PredefinedNotificationSetting } from './notification-settings-helper';
import './notification-type-selector.scss';

export interface NotificationSettingNameValue {
	name: string;
	value: PredefinedNotificationSetting;
}

interface Props {
	options: NotificationSettingNameValue[];
	machineType: NotificationMachineType;
	onChange: (setting: PredefinedNotificationSetting, machineType: NotificationMachineType) => void;
	isChecked: (machineType: NotificationMachineType, setting: PredefinedNotificationSetting) => boolean;
}

export const NotificationTypeSelector: FC<Props> = React.memo((props: Props) => {
	const onChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		// Convert the id we sat on BiCheckboxButton to an int - enums are really just ints
		const predefinedNotificationSettingEnum = Number(e.currentTarget.id);

		props.onChange(predefinedNotificationSettingEnum, props.machineType);
	};

	const buttons = props.options.map(option => (
		<div key={option.name} className="category-btn margin-right-10px">
			<BiCheckboxButton
				id={option.value.toString()} // We can read this value again in onChange
				colorTheme="org-orange"
				onChange={onChange}
				checked={props.isChecked(props.machineType, option.value)}
			>
				<span>{option.name}</span>
			</BiCheckboxButton>
		</div>
	));

	return (
		<div className="flex-direction-column margin-top-15px">
			<h6>
				<b>{localized('StandardSettings')}</b>
			</h6>
			<div className="flex-direction-row">{buttons}</div>
		</div>
	);
});
