import { FilterDto, ICoordinateDto, IFilterDto, IMapBounds, IUnitWithLocationDto, UnitLocationClient } from 'api/api';
import { store } from 'index';
import { Dispatch } from 'redux';
import { LocationState } from 'state/ducks/location/types';
import { Action } from 'typescript-fsa';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { unitLocationClient } from '../api-clients';
import {
	clearMachinesLocationsAction,
	getMachineLocationsAction,
	getMachineLocationsFilteredAction,
	setActivelySelectedMachineAction,
	setActivelySelectedMachinesAction,
	setMapBoundsAction,
	setMapCenterAction,
	setMapTypeAction,
	setMapZoomLevelAction,
} from './actions';
import { MapTypeId } from './constants';

export function clearMachinesLocations() {
	return (dispatch: Dispatch<Action<void>>) => {
		dispatch(clearMachinesLocationsAction());
	};
}

export function getMachineLocations(machineIds: number[]) {
	// Casting done to make compiler happy
	let callCounter = (store.getState().locationReducer as LocationState).callCounter;

	return AsyncOperationBuilder(
		getMachineLocationsAction,
		apiClient => (apiClient as UnitLocationClient).getMachinesWithLocations(machineIds),
		{ callCounter: ++callCounter, filter: machineIds },
		unitLocationClient
	);
}

export function getMachineLocationsFiltered(filter: IFilterDto) {
	// Casting done to make compiler happy
	let callCounter = (store.getState().locationReducer as LocationState).callCounter;

	let apiInput = new FilterDto(filter);

	return AsyncOperationBuilder(
		getMachineLocationsFilteredAction,
		apiClient => (apiClient as UnitLocationClient).getMachinesWithLocationsFiltered(apiInput),
		{ callCounter: ++callCounter, filter: apiInput },
		unitLocationClient
	);
}

export function setActivelySelectedMachine(machine?: IUnitWithLocationDto) {
	return (dispatch: Dispatch<Action<IUnitWithLocationDto | undefined>>) => {
		dispatch(setActivelySelectedMachineAction(machine));
	};
}

export function setMapBounds(bounds: IMapBounds) {
	return (dispatch: Dispatch<Action<IMapBounds>>) => {
		dispatch(setMapBoundsAction(bounds));
	};
}

export function setMapZoomLevel(zoomLevel: number) {
	return (dispatch: Dispatch<Action<number>>) => {
		dispatch(setMapZoomLevelAction(zoomLevel));
	};
}

export function setMapCenter(center: ICoordinateDto) {
	return (dispatch: Dispatch<Action<ICoordinateDto>>) => {
		dispatch(setMapCenterAction(center));
	};
}

export function setActivelySelectedMachines(machines?: IUnitWithLocationDto[]) {
	return (dispatch: Dispatch<Action<IUnitWithLocationDto[] | undefined>>) => {
		dispatch(setActivelySelectedMachinesAction(machines));
	};
}

export function setMapType(mapType: MapTypeId) {
	return (dispatch: Dispatch<Action<MapTypeId>>) => {
		dispatch(setMapTypeAction(mapType));
	};
}
