import { IFileDirectoryDto } from 'api/api';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { GetPartnerAreaPreviewFile } from 'state/ducks/partner-area/operations';
import { localized } from 'state/i18n';
import { useAppSelector } from 'utilities/hooks';
import { Spinner } from 'view/components/spinner/spinner';
import './partner-area-icon-overview.scss';

type Props = {
	obj: IFileDirectoryDto;
};

export const PartnerAreaIconOverlay: FC<Props> = props => {
	const dispatch = useDispatch();

	const previewFiles = useAppSelector(state => state.partnerAreaReducer.previewFiles);

	const previewFile = useMemo(() => previewFiles.find(file => props.obj.fullPath === file.filePath), [
		previewFiles,
		props.obj.fullPath,
	]);

	useEffect(() => {
		if (!previewFile && props.obj.fullPath) GetPartnerAreaPreviewFile(props.obj.fullPath)(dispatch);
	}, [dispatch, previewFile, props.obj.fullPath]);

	return previewFile && previewFile.fileResponse !== null && !previewFile.isLoading ? (
		<img
			src={(window.URL || window.webkitURL).createObjectURL(previewFile.fileResponse.data)}
			className="preview-image"
			alt={localized('File')}
		/>
	) : (
		<Spinner
			shouldOverlay={false}
			shouldUseAbsolutePositioning={false}
			spinnerSize="spinner-container-default-size"
			useMarginsForCorrectingCentering={false}
		/>
	);
};
