import { Card } from 'primereact/card';
import React, { FC, useEffect, useState } from 'react';
import { removeUserFromNotificationDefinitions } from 'state/ducks/notifications/operations';
import { localized } from 'state/i18n';
import { Spinner } from 'view/components/spinner/spinner';

export const UnsubscribeNotifications: FC = () => {
	const [isSpinning, setIsSpinning] = useState(false);

	useEffect(() => {
		async function unsubscribeUser() {
			let path = window.location.pathname;
			let encryptedString = path.substring(27);
			await removeUserFromNotificationDefinitions(encryptedString);
			setIsSpinning(false);
		}

		setIsSpinning(true);
		unsubscribeUser();
	}, []);

	return (
		<>
			{isSpinning ? (
				<div className="ie">
					<div className="ie-container">
						<Card title={localized('UnsubscribingNotification')}>
							<Spinner
								shouldOverlay={false}
								spinnerSize="spinner-container-1-4th-size"
								useMarginsForCorrectingCentering={false}
							/>
						</Card>
					</div>
				</div>
			) : (
				<div className="ie">
					<div className="ie-container">
						<Card title={localized('SuccesfullUnsubscribe')}></Card>
					</div>
				</div>
			)}
		</>
	);
};
