import React from 'react';
import { ProgressBar as ProgressBarPrime, ProgressBarProps } from 'primereact/progressbar';
import './progress-bar.scss';
import { MaxFillLevelCompactor } from 'utilities/machine-util';

export type MaxValues = number;

interface AppProps {
	id?: string;
	value: number;
	maxValue: MaxValues;
	styles?: React.CSSProperties;
	classes?: string;
	mode?: string;
	unitToPostfix?: string;
	showValue?: boolean;
}

type Props = AppProps;

export default class ProgressBar extends React.PureComponent<Props> {
	public getCorrectLabelWidth(value: number, valueMax: MaxValues): number {
		if (valueMax === MaxFillLevelCompactor && value <= valueMax) {
			return value;
		}
		if (value > valueMax) {
			return 100;
		} else if (value <= 0) {
			return 0;
		} else {
			return Math.round((value / valueMax) * 100);
		}
	}

	public render() {
		const currProgressWidth = this.getCorrectLabelWidth(this.props.value, this.props.maxValue);

		const fillLevel = this.props.value ? this.props.value / this.props.maxValue : 0;

		const propsOrDefault: ProgressBarProps = {
			className:
				`${this.props.classes || ''} text-center progress-bar label-width-${Math.floor(
					currProgressWidth
				)} max-value-${this.props.maxValue} ` + (fillLevel < 0.8 ? 'green' : fillLevel >= 1 ? 'red' : 'yellow'),
			unit: this.getUnitPostFix(),
		};

		const showValue = this.props.showValue === undefined ? true : this.props.showValue;

		return (
			<ProgressBarPrime
				id={this.props.id}
				value={this.props.value ? this.props.value : 0}
				showValue={showValue}
				unit={this.props.unitToPostfix}
				style={this.props.styles}
				mode={this.props.mode}
				displayValueTemplate={this.fillLevelFormatted}
				{...propsOrDefault}
			/>
		);
	}

	private getUnitPostFix = () => {
		return (
			this.props.unitToPostfix ||
			(this.props.maxValue !== MaxFillLevelCompactor ? `/${this.props.maxValue}` : '%')
		);
	};

	private fillLevelFormatted = (percentage: number) => {
		let prefix = '';
		if (percentage === 50) {
			prefix = '< ';
		}

		return <span>{prefix + percentage + this.getUnitPostFix()}</span>;
	};
}
