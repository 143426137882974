// Formatting date nicely
import moment, { Moment } from 'moment';
import * as React from 'react';
import { DateFourLetterYear, YearWithSeparatorRegExp } from 'utilities/constants';

interface AppProps {
	date: Date | undefined;
}

const browserLang = navigator.language;
export const momentLocaleData = moment.localeData(browserLang);
export enum DateFormatterModes {
	normal,
	byYear,
	withMinutes,
	byDayMonth,
}

export function getDateToString(date: Date | undefined, mode: DateFormatterModes = DateFormatterModes.normal) {
	if (!date) {
		return '';
	} else {
		return getMomentToString(moment(date.toISOString()), mode);
	}
}

export const getMomentToString = (date: Moment, mode: DateFormatterModes = DateFormatterModes.normal) => {
	switch (mode) {
		case DateFormatterModes.byYear:
			return date.format(DateFourLetterYear);
		case DateFormatterModes.byDayMonth:
			// Force browser language for consistency
			return date.locale(browserLang).format('l').replace(YearWithSeparatorRegExp, '');
		default:
			return date.format(
				mode === DateFormatterModes.normal
					? momentLocaleData.longDateFormat('L')
					: momentLocaleData.longDateFormat('L') + ' - HH:mm'
			);
	}
};

export const getDateToStringPretty = (date: Date | undefined) => {
	if (!date) {
		return '';
	} else {
		return moment(date).fromNow();
	}
};

export function setCorrectTimeStamp(
	newDate: Date | undefined,
	includeTimeStamp: boolean,
	isEndDate: boolean
): Date | undefined {
	if (newDate) {
		if (isEndDate) {
			return new Date(newDate.setHours(23, 59, 59, 0));
		} else if (includeTimeStamp) {
			const currentTime = new Date();
			return new Date(
				newDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds())
			);
		} else {
			return new Date(newDate.setHours(0, 0, 0, 0));
		}
	}
	return undefined;
}

class DateToString extends React.PureComponent<AppProps> {
	public render() {
		return getDateToString(this.props.date);
	}
}

export default DateToString;
