import React from 'react';
import { Button } from 'primereact/button';
import { localized } from 'state/i18n';
import { DataTypes } from 'api/api';

type OnClickType = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export interface Props {
	containerClassName?: string;
	dataType?: DataTypes;
	onBaleOutputClicked: (e?: OnClickType) => void;
	onNoEmptyingClicked: (e?: OnClickType) => void;
	onFillRateClicked: (e?: OnClickType) => void;
}

class ReportContentButtons extends React.PureComponent<Props> {
	public render() {
		return (
			<div className={`flex-direction-row ${this.props.containerClassName || ''}`}>
				<Button
					label={localized('BaleOutput')}
					onClick={this.props.onBaleOutputClicked}
					className={
						this.props.dataType === DataTypes.BaleOutput
							? 'p-button-rounded reports-button current-view'
							: 'p-button-rounded reports-button'
					}
				/>
				<Button
					label={localized('unitsEmptied')}
					onClick={this.props.onNoEmptyingClicked}
					className={
						this.props.dataType === DataTypes.NoEmptyings
							? 'p-button-rounded reports-button current-view'
							: 'p-button-rounded reports-button'
					}
				/>
				<Button
					label={localized('fillLevel')}
					onClick={this.props.onFillRateClicked}
					className={
						this.props.dataType === DataTypes.FillRate
							? 'p-button-rounded reports-button current-view'
							: 'p-button-rounded reports-button'
					}
				/>
			</div>
		);
	}
}

export default ReportContentButtons;
