import { IFilterDto } from 'api/api';
import { FilterOptions } from 'state/models/FilterOptions';
import actionCreatorFactory from 'typescript-fsa';
import { FilterSelections } from '../../models/FilterSelections';
import { constants } from './constants';

const actionCreator = actionCreatorFactory("filter-search");

export const SetFilterAction = actionCreator<{ input: FilterSelections }>(constants.SET_FILTER);

export const SetAppliedFilterAction = actionCreator<{ input: IFilterDto }>(constants.SET_APPLIED_FILTER);
export const SetUserAppliedFilterAction = actionCreator<{ input: IFilterDto }>(constants.SET_USER_APPLIED_FILTER);
export const GetFilterOptionsAction = actionCreator.async<{}, FilterOptions>(constants.GET_FILTER_OPTIONS);
