import {
	DataTypeProvider,
	DataTypeProviderProps,
	IntegratedSorting,
	Sorting,
	SortingState,
	TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
	Grid,
	Table,
	TableColumnReordering,
	TableColumnResizing,
	TableHeaderRow,
	VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IUnitCycleDto } from 'api/api';
import { Guid } from 'guid-typescript';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrder, setSize, setSorting } from 'state/ducks/pressure-graph-history-settings/operations';
import { UnitCycleDtoDtoKeys } from 'state/ducks/pressure-graph-history-settings/types';
import { SortingGeneric, TableColumnWidthInfoGeneric } from 'state/ducks/table-settings/types';
import { AppState } from 'state/store';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';
import BiNoData from './bi-no-data/bi-no-data';
import { ROW_HEIGHT } from './bi-table';
import PressureGraphSelect from './pressure-graph-select';

interface Props {
	data: IUnitCycleDto[];
	unitSerialNumber: string;
}

const rootStyle: React.CSSProperties = { height: '100%' };
const Root = (props: Grid.RootProps) => (
	<Grid.Root className="devextreme-react-grid-remove-margin-bottom" {...props} style={rootStyle} />
);

const TableComponent = ({ ...restProps }) => <VirtualTable.Table {...restProps} className="bi-report-table-striped" />;

const HeaderComponent = ({ ...restProps }) => <VirtualTable.TableHead {...restProps} className="table-header" />;

const GenericTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider {...props} />;

const TableRow = (obj: any) => <VirtualTable.Row {...obj} />;

const DateFormatter = (obj: { value: Date }) => {
	return <span>{getDateToString(obj.value, DateFormatterModes.withMinutes)}</span>;
};

const getRowId = (row: any) => {
	return Guid.create().toString();
};

const PressureGraphTable: FC<Props> = props => {
	const { data, unitSerialNumber } = props;
	const dispatch = useDispatch();
	const isLoading = useSelector((state: AppState) => state.unitHistoryReducer.unitCycles.isLoading);
	const sorting = useSelector((state: AppState) => state.pressureGraphTableSettingsReducer.sorting);
	const columnOrder = useSelector((state: AppState) => state.pressureGraphTableSettingsReducer.columnOrder);
	const columnWidths = useSelector((state: AppState) => state.pressureGraphTableSettingsReducer.columnWidths);
	const columnsAll = useSelector((state: AppState) => state.pressureGraphTableSettingsReducer.allColumns);

	const [timestampColumns] = useState<UnitCycleDtoDtoKeys[]>(['timestamp']);
	const [showGraphColumn] = useState<UnitCycleDtoDtoKeys[]>(['showChosenGraph']);

	const changeColumnWidths = useCallback(
		(columnWidths: TableColumnWidthInfo[]) =>
			dispatch(setSize(columnWidths as TableColumnWidthInfoGeneric<UnitCycleDtoDtoKeys>[])),
		[dispatch]
	);

	const changeSorting = useCallback(
		(sorting: Sorting[]) => dispatch(setSorting(sorting as SortingGeneric<UnitCycleDtoDtoKeys>[])),
		[dispatch]
	);

	const changeColumnOrder = useCallback(
		(order: string[]) => dispatch(setOrder(order as UnitCycleDtoDtoKeys[])),
		[dispatch]
	);

	const getNoDataComponent = useCallback(
		(props: Table.NoDataCellProps) => {
			return <BiNoData isLoading={isLoading} noDataCellProps={props} />;
		},
		[isLoading]
	);

	const showGraphFormatter = useCallback((obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
		const currObj = obj.row as IUnitCycleDto;
		return <PressureGraphSelect unitSerialNumber={unitSerialNumber} unitCycle={currObj} />;
	}, [unitSerialNumber]);

	return (
		<Grid rows={data || []} columns={columnsAll} getRowId={getRowId} rootComponent={Root}>
			<GenericTypeProvider for={timestampColumns} formatterComponent={DateFormatter} />
			<GenericTypeProvider for={showGraphColumn} formatterComponent={showGraphFormatter} />
			<SortingState sorting={sorting} onSortingChange={changeSorting} />
			<IntegratedSorting />
			<VirtualTable
				tableComponent={TableComponent}
				headComponent={HeaderComponent}
				height="auto"
				columnExtensions={columnWidths}
				noDataCellComponent={getNoDataComponent}
				rowComponent={TableRow}
				estimatedRowHeight={ROW_HEIGHT}
			/>
			<TableColumnReordering onOrderChange={changeColumnOrder} order={columnOrder} />
			<TableColumnResizing onColumnWidthsChange={changeColumnWidths} columnWidths={columnWidths} />
			<TableHeaderRow showSortingControls />
		</Grid>
	);
};

export default PressureGraphTable;
