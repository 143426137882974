import { Sorting } from '@devexpress/dx-react-grid';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { FilterDto, IFilterDto, INotificationDefinitionDto, NotificationColumnSelected } from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { setFilter, setUserAppliedFilter } from 'state/ducks/filter-search/operations';
import { initialFilterState } from 'state/ducks/filter-search/reducer';
import { getMachinesSelected } from 'state/ducks/machine-selected/operations';
import { clearMachines, getMachineCount, getMachinesFiltered } from 'state/ducks/machines/operations';
import {
	getNotificationTypes,
	removeTemporaryNotificationDefinition,
	setTemporaryNotificationDefinition,
} from 'state/ducks/notifications/operations';
import { defaultNotificationColumnsSelected } from 'state/ducks/notifications/types';
import { Routes } from 'state/ducks/routes';
import { getXtelUnitTrackers } from 'state/ducks/xtel-tracker/operations';
import history from 'state/history';
import { localized } from 'state/i18n';
import { FilterSelections } from 'state/models/FilterSelections';
import { AppState } from 'state/store';
import {
	FilterBarId,
	NavBarHeightPx,
	SpecificNotificationContainerId,
	UnitsSelectedRowHeightPx,
} from 'utilities/constants';
import { Spinner } from 'view/components/spinner/spinner';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { VIRTUAL_PAGE_SIZE } from '../../bi-table/bi-table';
import { machineIdsToDistinctMachineTypeSelectedList } from '../notification-helper';
import EmailConfiguration from './email-configuration';
import NotificationSelector, { NotSelectorContainerId } from './notification-selector';
import './specific-notification.scss';

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps<AppRouterProps>) => {
	return {
		temporaryDefinition: state.notificationsReducer.temporaryNotificationDefinition,
		selectedDeviceIds: state.tableSettingsReducer.selectedMachines,
		notificationDefinition: state.notificationsReducer.notificationDefinitions.find(
			nd => nd.id === +ownProps.match.params.notification_definition_id
		),
		userSettings: state.userSettingReducer.userSettings,
		notificationTypes: state.notificationsReducer.notificationTypes,
		allAvailableDevices: state.machineSelectedReducer.machinesSelected,
		notificationMachineTypes: machineIdsToDistinctMachineTypeSelectedList(
			state.tableSettingsReducer.selectedMachines
		),
		filter: state.filterSearchReducer.filter,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setTemporaryNotificationDefinition: (id: number, notDef?: INotificationDefinitionDto) =>
		setTemporaryNotificationDefinition(id, notDef)(dispatch),
	clearMachines: () => clearMachines()(dispatch),
	setFilterOptions: (input: FilterSelections) => setFilter(input)(dispatch),
	setUserAppliedFilter: (input: IFilterDto) => setUserAppliedFilter(input)(dispatch),
	getMachineCount: (filter?: IFilterDto) => getMachineCount(filter)(dispatch),
	getMachines: (filter?: IFilterDto, skip?: number, take?: number, sorting?: Sorting) =>
		getMachinesFiltered(filter, skip, take, sorting)(dispatch),
	getMachinesSelected: (filter: IFilterDto) => getMachinesSelected(filter)(dispatch),
	getNotificationTypes: () => getNotificationTypes()(dispatch),
	getXtelUnitTrackers: () => getXtelUnitTrackers()(dispatch),
	removeTemporaryNotificationDefinition: () => removeTemporaryNotificationDefinition()(dispatch),
});

interface AppRouterProps {
	notification_definition_id: string;
}

export type SpecificNotificationLocationState = {
	isFromNotificationsPage?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	RouteComponentProps<AppRouterProps, {}, SpecificNotificationLocationState>;

class SpecificNotification extends React.PureComponent<Props> {
	private isFromNotificationsPageNavParam: boolean = false;

	componentDidMount() {
		// SetTimeout makes sure that the component with a spinner is rendered immediately when navigating to the page
		setTimeout(() => {
			this.isFromNotificationsPageNavParam = !!this.props.location.state?.isFromNotificationsPage;
			this.props.getXtelUnitTrackers();

			if (!this.props.notificationTypes || this.props.notificationTypes.length === 0) {
				this.props.getNotificationTypes();
			}

			if (!this.props.temporaryDefinition) {
				if (this.props.match.params.notification_definition_id === '0') {
					let newNotificationDefinition: INotificationDefinitionDto = {
						id: 0,
						isEditAllowed: true,
						notificationUsers: [],
						notificationTypes: [],
						selectedLanguage: this.props.userSettings?.selectedLanguage,
						columnsSelected: defaultNotificationColumnsSelected as NotificationColumnSelected[],
						unitIds: this.props.selectedDeviceIds,
						shouldNotifyByEmail: true,
						shouldNotifyByPush: true,
					};

					this.init(0, newNotificationDefinition);
				} else {
					this.init(+this.props.match.params.notification_definition_id, this.props.notificationDefinition);
				}
			}
		}, 0);
	}

	componentDidUpdate() {
		if (!this.isLoading()) this.setHeights();
	}

	public render() {
		return (
			<div className="specific-notification">
				<div className="flex-stretch-column" id={SpecificNotificationContainerId}>
					{this.isLoading() ? (
						<Spinner />
					) : this.props.notificationMachineTypes.length === 0 ? (
						<div className="flex-1 flex-direction-column">
							<h4 className="margin-top-80px margin-right-1 flex-1 text-center">
								{localized('NoNotificationMachinesSelected')}
							</h4>
							<BiButton
								onClick={this.cancel}
								colorTheme="org-red"
								containerTheme="normal-button-corners"
								containerClassName="margin-left-auto margin-right-3"
							>
								<span className="flex-wrap flex-center-row">
									<span className="text-bold margin-left-1 margin-right-1">
										{localized('Cancel')}
									</span>
								</span>
							</BiButton>
						</div>
					) : (
						<>
							<div className="flex-3 border-right-shaded">
								<NotificationSelector />
							</div>
							<div className="flex-2">
								<EmailConfiguration />
							</div>
						</>
					)}
				</div>
			</div>
		);
	}

	private cancel = () => {
		this.props.removeTemporaryNotificationDefinition();
		history.replace(Routes.Notifications);
	};

	private isLoading = () => {
		return (
			!this.props.temporaryDefinition ||
			!this.props.selectedDeviceIds ||
			!this.props.notificationTypes?.length ||
			!this.props.allAvailableDevices?.length
		);
	};

	private setHeights = () => {
		// This sets the height of the table is because we don't know the height of the filterbar
		let filterBarElement = document.getElementById(FilterBarId);
		let notificationsContainer = document.getElementById(SpecificNotificationContainerId);
		let notificationsSelector = document.getElementById(NotSelectorContainerId);

		if (notificationsContainer && filterBarElement && notificationsSelector) {
			let height = filterBarElement.clientHeight + NavBarHeightPx + UnitsSelectedRowHeightPx;
			notificationsContainer.style.minHeight = `calc(100vh - ${height}px)`;
			notificationsSelector.style.minHeight = `calc(100vh - ${height}px)`;
		}
	};
	private clearFilter = () => {
		this.props.clearMachines();
		this.props.setFilterOptions(initialFilterState.filter);
		this.props.setUserAppliedFilter(initialFilterState.appliedFilters);
		this.props.getMachineCount();
		this.props.getMachines(undefined, 0, VIRTUAL_PAGE_SIZE);
		this.props.getMachinesSelected(new FilterDto());
	};

	private init = (id: number, notificationDefinition?: INotificationDefinitionDto) => {
		// Only clear filter if page is accessed directly. The user's filter state can make
		// this notification definition inaccessible.
		if (!this.isFromNotificationsPageNavParam) {
			this.clearFilter();
		}

		this.props.setTemporaryNotificationDefinition(id, notificationDefinition);
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, SpecificNotification, 'SpecificNotification'));
