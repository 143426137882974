import { Sorting } from '@devexpress/dx-react-grid';
import { ApiException, FilterDto, IFilterDto, UnitClient, IUnitsDto, ICreateUnitDto, CreateUnitDto, MoveUnitDto } from 'api/api';
import { store } from 'index';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { Action } from 'typescript-fsa';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { signOut } from 'utilities/auth-helper';
import { unitClient } from '../api-clients';
import {
	ClearMachines,
	GetMachineCountAction,
	GetMachinesAction,
	RenewTableKeyAction,
	SetLoadingStateAction,
	SetMachinesAction,
	SetSkipStateAction,
	UpdateMachinesOwnerGroupIdAction,
	UpdateMachinesOwnerGroupNameAction,
	DeleteMachineAction,
	CreateMachineAction,
} from './actions';

const randomKey = () =>
	Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, '')
		.substr(2, 10);

export function clearMachines() {
	return (dispatch: Dispatch<Action<void>>) => {
		dispatch(ClearMachines());
	};
}

export function setSkipState(skip: number) {
	return (dispatch: Dispatch<Action<{ skip: number }>>) => {
		dispatch(SetSkipStateAction({ skip }));
	};
}

export function updateMachinesOwnerGroup(moveMachineDto: MoveUnitDto, groupName: string) {
	// You need to send the groupName of the new ownergroup as parameter to the reducer,
	// to update the groupName multiple redux states.

	// Dispatching two actions, to update ownergroup id- and group name in other redux states.
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			const fileResponse = await unitClient.updateUnitOwnerGroup(moveMachineDto);
			const deviceIds = moveMachineDto.unitIds || [];

			dispatch(
				UpdateMachinesOwnerGroupIdAction.done({
					params: {
						MachineIds: deviceIds,
						destinationGroupId: moveMachineDto.newGroupId,
					},
					result: fileResponse,
				})
			);

			dispatch(
				UpdateMachinesOwnerGroupNameAction({
					MachineIds: deviceIds,
					groupName: groupName,
				})
			);
		} catch (error: any) {
			if (error.status) {
				let apiException = error as ApiException;

				notificationService.showErrorMessageWithError(
					`${apiException.status} - ${apiException.message}`,
					undefined,
					error
				);
				if (apiException.status === 401) {
					signOut();
				}
			}
		}
	};
}

export function setLoadingState(loadingState: boolean) {
	return (dispatch: Dispatch<Action<{ loadingState: boolean }>>) => {
		dispatch(SetLoadingStateAction({ loadingState }));
	};
}

export function setMachines(machines: IUnitsDto[], skip: number) {
	return (dispatch: Dispatch) => {
		dispatch(SetMachinesAction({ machines, skip }));
	};
}

export function renewTableKey() {
	return (dispatch: Dispatch<Action<{ tableKey: string }>>) => {
		dispatch(RenewTableKeyAction({ tableKey: randomKey() }));
	};
}

export function getMachinesFiltered(filter?: IFilterDto, skip?: number, take?: number, sorting?: Sorting) {
	let filterInput = filter || new FilterDto();
	let skipInput = skip || 0;
	let columnName = (sorting && sorting.columnName) || null;
	let direction = (sorting && sorting.direction) || null;
	let callCounter = store.getState().machinesReducer.machinesCallCounter;

	return AsyncOperationBuilder(
		GetMachinesAction,
		apiClient =>
			(apiClient as UnitClient).getByFilter(
				new FilterDto(filterInput),
				skipInput,
				take,
				columnName,
				direction
			),
		{ callCounter: ++callCounter, skipInput },
		unitClient
	);
}

export function getMachineCount(filter?: IFilterDto) {
	let apiInput = filter || new FilterDto();
	let callCounter = store.getState().machinesReducer.machineCountCallCounter;

	return AsyncOperationBuilder(
		GetMachineCountAction,
		apiClient => (apiClient as UnitClient).getMachineCount(new FilterDto(apiInput)),
		{ callCounter: ++callCounter },
		unitClient
	);
}

export const deleteMachine = (machineId: number) =>
	AsyncOperationBuilder(
		DeleteMachineAction,
		apiClient => (apiClient as UnitClient).deleteMachine(machineId),
		{ machineId },
		unitClient
	);

export const createUnit = (createUnitDto: ICreateUnitDto) =>
	AsyncOperationBuilder(
		CreateMachineAction,
		apiClient => (apiClient as UnitClient).createUnit(new CreateUnitDto(createUnitDto)),
		createUnitDto,
		unitClient
	);


