import {
	DataTypeProvider,
	DataTypeProviderProps,
	IntegratedSorting,
	Sorting,
	SortingState,
	TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
	DragDropProvider,
	Grid,
	Table,
	TableColumnReordering,
	TableColumnResizing,
	TableHeaderRow,
	VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IGPSHistoryDto, TableId } from 'api/api';
import { Guid } from 'guid-typescript';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setOrder, setSize, setSorting } from 'state/ducks/gps-history-setting/operations';
import { GPSHistoryDtoKeys, IGPSHistoryDtoCustom } from 'state/ducks/gps-history-setting/types';
import { SortingGeneric, TableColumnWidthInfoGeneric } from 'state/ducks/history-table-settings/types';
import { clearMachinesLocations } from 'state/ducks/location/operations';
import { selectOnlyOneMachine } from 'state/ducks/table-settings/operations';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { IsBrandBramidan } from 'utilities/brand-utils';
import { FormatDistanceWithUnit } from 'utilities/unit-utils';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';
import BiNoData from './bi-no-data/bi-no-data';
import { ROW_HEIGHT } from './bi-table';
import GPSHistoryGpsLocation from './extra-columns/gps-history-table-gps-location';

interface GPSHistoryDtoForTable extends IGPSHistoryDto {
	index: number;
}

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	return {
		sorting: state.gpsHistoryTableSettingsReducer.sorting,
		columnOrder: state.gpsHistoryTableSettingsReducer.columnOrder,
		columnWidths: state.gpsHistoryTableSettingsReducer.columnWidths,
		columnsAll: state.gpsHistoryTableSettingsReducer.allColumns,
		columnsWithOutHomeLocation: state.gpsHistoryTableSettingsReducer.withoutHomeLocationColums,
		checkbox: state.filterDateSearchReducer.homeLocationChecked,
		userSettings: state.userSettingReducer.userSettings,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setSorting: (sorting: SortingGeneric<GPSHistoryDtoKeys>[]) => setSorting(sorting)(dispatch),
	setSize: (size: TableColumnWidthInfoGeneric<GPSHistoryDtoKeys>[]) => setSize(size)(dispatch),
	setOrder: (order: GPSHistoryDtoKeys[]) => setOrder(order)(dispatch),
	selectOnlyOneMachine: (deviceId: number) => selectOnlyOneMachine(deviceId)(dispatch),
	clearMachinesLocations: () => clearMachinesLocations()(dispatch),
});
type dataType = IGPSHistoryDtoCustom[];
type dataElementType = dataType[0];

interface AppProps {
	isLoading?: boolean;
	data?: IGPSHistoryDto[];
}
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

interface State {
	dateColumns: GPSHistoryDtoKeys[];
	timestampColumns: GPSHistoryDtoKeys[];
	homeLocationColumns: GPSHistoryDtoKeys[];
	currentLocationColumns: GPSHistoryDtoKeys[];
	distanceColumns: GPSHistoryDtoKeys[];
	sourceColums: GPSHistoryDtoKeys[];
	triggeredByColumns: GPSHistoryDtoKeys[];
}

const GenericTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider {...props} />;

const getRowId = (row: IGPSHistoryDto) => {
	return Guid.create().toString();
};
const TableRow = (obj: any) => <VirtualTable.Row {...obj} />;
const TableComponent = ({ ...restProps }) => <VirtualTable.Table {...restProps} className="bi-report-table-striped" />;
const HeaderComponent = ({ ...restProps }) => <VirtualTable.TableHead {...restProps} className="table-header" />;

const DateFormatter = (obj: { value: Date }) => {
	return <span>{getDateToString(obj.value, DateFormatterModes.withMinutes)}</span>;
};

const SourceFormatter = (obj: { value: string }) => {
	return <span>{obj.value}</span>;
};

const TriggeredByFormatter = (obj: { value: string }) => {
	const key = `XtelEventData.${obj.value}`;
	const translatedKey = localizedDynamic(key);
	return <span>{key !== translatedKey ? translatedKey : localized('Unknown')}</span>;
};

const rootStyle: React.CSSProperties = { height: '100%' };
const Root = (props: Grid.RootProps) => (
	<Grid.Root className="devextreme-react-grid-remove-margin-bottom" {...props} style={rootStyle} />
);

class GPSHistoryTable extends React.PureComponent<Props, State> {
	private currMachine: dataElementType | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			dateColumns: ['createdOn'],
			timestampColumns: ['updatedDate'],
			homeLocationColumns: ['homeLocation'],
			currentLocationColumns: ['currentLocation'],
			distanceColumns: ['distance'],
			sourceColums: ['source'],
			triggeredByColumns: IsBrandBramidan ? ['triggeredBy'] : [],
		};
	}

	public render() {
		let data: GPSHistoryDtoForTable[] = [];
		if (this.props.data) {
			data = this.props.data.map((d, index) => {
				return { ...d, index };
			});
		}

		return (
			<Grid
				rows={data || []}
				columns={this.props.checkbox === false ? this.props.columnsWithOutHomeLocation : this.props.columnsAll}
				getRowId={getRowId}
				rootComponent={Root}
			>
				<GenericTypeProvider for={this.state.dateColumns} formatterComponent={DateFormatter} />
				<GenericTypeProvider for={this.state.timestampColumns} formatterComponent={DateFormatter} />
				<GenericTypeProvider
					for={this.state.homeLocationColumns}
					formatterComponent={this.HomeLocationFormatter}
				/>
				<GenericTypeProvider
					for={this.state.currentLocationColumns}
					formatterComponent={this.CurrentLocationFormatter}
				/>
				<GenericTypeProvider for={this.state.distanceColumns} formatterComponent={this.DistanceFormatter} />
				<GenericTypeProvider for={this.state.sourceColums} formatterComponent={SourceFormatter} />
				<GenericTypeProvider for={this.state.triggeredByColumns} formatterComponent={TriggeredByFormatter} />

				<DragDropProvider />
				<SortingState sorting={this.props.sorting} onSortingChange={this.changeSorting} />
				<IntegratedSorting />

				<VirtualTable
					tableComponent={TableComponent}
					headComponent={HeaderComponent}
					height="auto"
					noDataCellComponent={this.noCell}
					rowComponent={TableRow}
					estimatedRowHeight={ROW_HEIGHT}
				/>
				<TableColumnReordering onOrderChange={this.changeColumnOrder} order={this.props.columnOrder} />
				<TableColumnResizing
					onColumnWidthsChange={this.changeColumnWidths}
					columnWidths={this.props.columnWidths}
				/>
				<TableHeaderRow showSortingControls={true} />
			</Grid>
		);
	}

	private HomeLocationFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
		const currMachine = obj.row as dataElementType;
		this.currMachine = currMachine;
		if (currMachine.homeLat && currMachine.homeLng) {
			return (
				<GPSHistoryGpsLocation
					machine={currMachine}
					value={
						(currMachine.homeLat ? currMachine.homeLat : '') +
						(currMachine.homeLat && currMachine.homeLng ? ', ' : '') +
						(currMachine.homeLng ? currMachine.homeLng : '')
					}
					location={`${currMachine.homeLat},${currMachine.homeLng}`}
				/>
			);
		} else {
			return <div />;
		}
	};
	private CurrentLocationFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
		const currMachine = obj.row as dataElementType;
		this.currMachine = currMachine;
		if (currMachine.currentLat && currMachine.currentLng) {
			return (
				<GPSHistoryGpsLocation
					machine={currMachine}
					value={
						(currMachine.currentLat ? currMachine.currentLat : '') +
						(currMachine.currentLat && currMachine.currentLng ? ', ' : '') +
						(currMachine.currentLng ? currMachine.currentLng : '')
					}
					location={`${currMachine.currentLat},${currMachine.currentLng}`}
				/>
			);
		} else {
			return <div />;
		}
	};

	private DistanceFormatter = (obj: { value: number | undefined }) => {
		return <span>{FormatDistanceWithUnit(obj.value, this.props.userSettings.profileUnits)}</span>;
	};

	private noCell = (rest: Table.NoDataCellProps) => this.NoDataCellComponent(rest, this.props.isLoading);

	private NoDataCellComponent = (restProps: Table.NoDataCellProps, loading = this.props.isLoading): JSX.Element => (
		<BiNoData isLoading={this.props.isLoading} noDataCellProps={restProps} tableId={TableId.Histories} />
	);
	private changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
		this.props.setSize(columnWidths as TableColumnWidthInfoGeneric<GPSHistoryDtoKeys>[]);
	};

	private changeColumnOrder = (newOrder: string[]) => {
		this.props.setOrder(newOrder as GPSHistoryDtoKeys[]);
	};

	private changeSorting = (sorting: Sorting[]) => {
		this.props.setSorting(sorting as SortingGeneric<GPSHistoryDtoKeys>[]);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GPSHistoryTable);
