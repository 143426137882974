import { DashboardClient, DashboardBase, ICreateReportTileSettingsDto, CreateReportTileSettingsDto, ILocationTileSettingsDto, LocationTileSettingsDto, DateTypes } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { dashboardClient } from '../api-clients';
import { GetDashboardContentAction, CreateDashboardContentAction, UpdateDashboardContentAction, DeleteDashboardContentAction, SetMenuToShowIdAction, CreateReportTileAction, CreateGoogleMapsLocationAction, SetPickupsDateTypeAction, GetSingleDashboardTileAction } from './actions';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
import { DashboardState } from './types';
import { store } from 'index';

export async function getDashboardContent() {
	let callCounter = (store.getState().dashboardReducer as DashboardState).callCounter;
	let isFetchingContent = (store.getState().dashboardReducer as DashboardState).isFetchingContent;

	// Don't start new request if one is already active
	if (isFetchingContent) {
		return (dispatch: Dispatch<Action<any>>) => {
			return
		};
	}

	return AsyncOperationBuilder(
		GetDashboardContentAction,
		apiClient => (apiClient as DashboardClient).get(),
		{ callCounter: ++callCounter },
		dashboardClient
	);
}

export async function createDashboardContent(bases: DashboardBase[]) {
	let callCounter = (store.getState().dashboardReducer as DashboardState).callCounter;

	return await AsyncOperationBuilder(
		CreateDashboardContentAction,
		apiClient => (apiClient as DashboardClient).post(bases),
		{ callCounter: ++callCounter, dashboardBases: bases },
		dashboardClient
	);
}

export async function updateDashboardContent(bases: DashboardBase[]) {
	let callCounter = (store.getState().dashboardReducer as DashboardState).callCounter;

	return AsyncOperationBuilder(
		UpdateDashboardContentAction,
		apiClient => (apiClient as DashboardClient).put(bases),
		{ callCounter: ++callCounter, dashboardBases: bases },
		dashboardClient
	);
}

export async function deleteDashboardContent(base: DashboardBase) {
	let callCounter = (store.getState().dashboardReducer as DashboardState).callCounter;

	return AsyncOperationBuilder(
		DeleteDashboardContentAction,
		apiClient => (apiClient as DashboardClient).delete(base),
		{ callCounter: ++callCounter, dashboardBase: base },
		dashboardClient
	);
}

export function setMenuToShowid(id: number) {
	return (dispatch: Dispatch<Action<{}>>) => {
		dispatch(SetMenuToShowIdAction({ id }));
	};
}
export async function createReportTile(settings: ICreateReportTileSettingsDto) {
	let callCounter = (store.getState().dashboardReducer as DashboardState).callCounter;

	return await AsyncOperationBuilder(
		CreateReportTileAction,
		apiClient => (apiClient as DashboardClient).createReportTile(settings as CreateReportTileSettingsDto),
		{ callCounter: ++callCounter, createReportTileSettingsDto: settings },
		dashboardClient
	);
}

export async function createStaticMapLocation(location: ILocationTileSettingsDto) {
	let callCounter = (store.getState().dashboardReducer as DashboardState).callCounter;

	return await AsyncOperationBuilder(
		CreateGoogleMapsLocationAction,
		apiClient => (apiClient as DashboardClient).createLocationTile(location as LocationTileSettingsDto),
		{ callCounter: ++callCounter, locationTileSettingsDto: location },
		dashboardClient
	);
}

export function setPickupsDateType(dateType: DateTypes) {
	return (dispatch: Dispatch<Action<{}>>) => {
		dispatch(SetPickupsDateTypeAction({ dateType }));
	};
}

export async function getSingleDashboardTileNoRedux(id: number) {
	return await dashboardClient.getById(id);
}

export function getSingleDashboardTile(id: number) {
	return AsyncOperationBuilder(
		GetSingleDashboardTileAction,
		apiClient => (apiClient as DashboardClient).getById(id),
		{ id },
		dashboardClient
	)
}