import React, { ReactNode, PureComponent } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { AppState } from 'state/store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reactAI } from 'services/telemetry-service';
import './accordion.scss'


const mapStateToProps = (state: AppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

type AccordionSectionProps = {
    isOpen: boolean,
    componentInList: number,
    onClick: (id: number) => void,
    header: ReactNode,
    children?: React.ReactNode,
};
export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AccordionSectionProps

class BiAccordionSection extends PureComponent<Props> {
    onClick = () => {
        this.props.onClick(this.props.componentInList);
    }
    render() {
        return (
            <div className='accordion-section'>
                {!this.props.isOpen && (
                    <div onClick={this.onClick} className='pointor-cursor'>
                        {this.props.header}
                    </div>
                )}

                {this.props.isOpen && (
                    <div>
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, BiAccordionSection, 'BiAccordionSection'));