// relative path to image
import balerIconBramidan from 'assets/baler-black-bramidan.png';
import balerBigBramidan from 'assets/baler-big-bramidan.png';
import balerIconPresto from 'assets/baler-black-presto.png';
import bars from 'assets/bars.png';
import bars_inverted from 'assets/bars_inverted.png';
import cog_wrench from 'assets/cog_wrench.png';
import excel from 'assets/excel.png';
import logo from 'assets/BRA-IN logo_no payoff.png';
import map_marker from 'assets/map_marker.png';
import map_marker_online from 'assets/map_marker_online.png';
import map_marker_offline from 'assets/map_marker_offline.png';
import map_marker_failure from 'assets/marker_red.png';
import map_marker_warning from 'assets/map_marker_warning.png';
import map_marker_nomodem from 'assets/map_marker_nomodem_v2.png';
import service from 'assets/service.png';
import spreadsheet from 'assets/spreadsheet.png';
import spreadsheet_inverted from 'assets/spreadsheet_inverted.png';
import weight from 'assets/weight.png';
import tracker from 'assets/tracker.png';
import m1 from 'assets/cluster-images/m1.png';
import m2 from 'assets/cluster-images/m2.png';
import m3 from 'assets/cluster-images/m3.png';
import m4 from 'assets/cluster-images/m4.png';
import m5 from 'assets/cluster-images/m5.png';
import close from 'assets/exit.png';
import dashboard from 'assets/dashboard.png';
import message from 'assets/message_icon_brain.png';
import setCurrentBalesBramidan from 'assets/bale/bramidan_set_current_bales.png';
import resetBaleCounterBramidan from 'assets/bale/bramidan_reset_bale_counter.png';
import editNumberOfBalesBramidan from 'assets/bale/bramidan_edit_number_of_bales.png';
import automaticBaleCounterBramidan from 'assets/bale/bramidan_automatic_bale_counter.png';
import setCurrentBalesPresto from 'assets/bale/presto_set_current_bales.png';
import resetBaleCounterPresto from 'assets/bale/presto_reset_bale_counter.png';
import editNumberOfBalesPresto from 'assets/bale/presto_edit_number_of_bales.png';
import automaticBaleCounterPresto from 'assets/bale/presto_automatic_bale_counter.png';
import linkBramidan from 'assets/bale/bramidan_linked.png';
import linkPresto from 'assets/bale/presto_linked.png';
import favoriteFilled from 'assets/Favorite_filled_dark.png';
import favoriteOutline from 'assets/Favorite_outline_dark.png';
import { IsBrandBramidan } from './brand-utils';

export const images = {
	dashboard,
	brandLogo: logo,
	balerIconBramidan,
	balerBigBramidan,
	balerIconPresto,
	bars,
	bars_inverted,
	cog_wrench,
	excel,
	map_marker,
	map_marker_online,
	map_marker_offline,
	map_marker_failure,
	map_marker_warning,
	map_marker_nomodem,
	service,
	close,
	spreadsheet,
	spreadsheet_inverted,
	weight,
	tracker,
	m1,
	m2,
	m3,
	m4,
	m5,
	message,
	automaticBaleCounter: IsBrandBramidan ? automaticBaleCounterBramidan : automaticBaleCounterPresto,
	editNumberOfBales: IsBrandBramidan ? editNumberOfBalesBramidan : editNumberOfBalesPresto,
	resetBaleCounter: IsBrandBramidan ? resetBaleCounterBramidan : resetBaleCounterPresto,
	setCurrentBales: IsBrandBramidan ? setCurrentBalesBramidan : setCurrentBalesPresto,
	link: IsBrandBramidan ? linkBramidan : linkPresto,
	favoriteFilled,
	favoriteOutline,
} as const;
