import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { UnitModelState } from './types';
import { deleteValueInArrayCustomId, upsertValueInArrayCustomId } from 'utilities/reducer-helpers';

const initialState: UnitModelState = {
	unitModels: [],
};

export function unitModelReducer(state: UnitModelState = initialState, action: Action): UnitModelState {
	if (isType(action, actions.getUnitModelsAction.done)) {
		return {
			...state,
			unitModels: action.payload.result,
		};
	}
	if (isType(action, actions.createUnitModelsAction.done)) {
		return {
			...state,
			unitModels: upsertValueInArrayCustomId(state.unitModels, action.payload.result, obj => obj.id),
		};
	}
	if (isType(action, actions.updateUnitModelsAction.done)) {
		return {
			...state,
			unitModels: upsertValueInArrayCustomId(state.unitModels, action.payload.result, obj => obj.id),
		};
	}
	if (isType(action, actions.deleteUnitModelsAction.done)) {
		return {
			...state,
			unitModels: deleteValueInArrayCustomId(state.unitModels, action.payload.params.id, obj => obj.id),
		};
	}

	return state;
}
