import { IUserGroupPermissionsDto } from 'api/api';
import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { localized } from 'state/i18n';
import { UserExistInGroup } from 'view/pages/administration/cards/user-manager/add-user-helpers';
import './bi-tree-select.scss';
import { TreeSelectItem } from './tree-select-item';

interface MultiselectState {
	checked: number[];
	expanded: number[];
	isOpen: boolean;
	breadCrumbTitle: string;
	hierarchy: TreeItem[];
}

interface MultiselectProps {
	nodes: TreeSelectItem[];
	selectedItems: number[];
	onCheckChanged: (items: number[], breadCrumb?: string) => void;
	onEnterPressed?: (e: KeyboardEvent) => void;
	mustHaveSelection?: boolean;

	// Used in user-field on administration page
	// to determine whether it's possible to change group/item
	parentSelectedItems?: boolean;
	userId?: string;
	userGroupPermissions?: IUserGroupPermissionsDto[];
	className?: string;
}

const breadCrumbTitleDefault = localized('group');

class BiTreeSelect extends React.PureComponent<MultiselectProps, MultiselectState> {
	private wrapperRef: any;
	constructor(props: MultiselectProps) {
		super(props);

		this.state = {
			checked: [],
			expanded: [],
			isOpen: false,
			breadCrumbTitle: breadCrumbTitleDefault,
			hierarchy: [],
		};
	}

	public componentDidUpdate(prevProps: MultiselectProps, prevState: MultiselectState) {
		if (
			JSON.stringify(prevProps.nodes) !== JSON.stringify(this.props.nodes) ||
			(!this.state.hierarchy.length && this.props.nodes.length)
		) {
			this.updateCheckedItem();
		}

		if (this.props.selectedItems?.length === 0 && this.state.checked.length !== 0) {
			this.setState({
				checked: [],
				expanded: [],
				isOpen: false,
				breadCrumbTitle: breadCrumbTitleDefault,
				hierarchy: [],
			});
		}
	}

	public setWrapperRef = (node: any) => {
		this.wrapperRef = node;
	};

	public handleClickOutside = (event: any) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.toggleContent();
			this.setState({ isOpen: false });
		}
	};

	public toggleContent = () => {
		//Setup click out outside dropdown
		if (this.state.isOpen) {
			document.removeEventListener('mousedown', this.handleClickOutside);
		} else {
			document.addEventListener('mousedown', this.handleClickOutside);
			if (this.props.onEnterPressed) {
				document.addEventListener('keypress', this.OnEnterPressed);
			}
		}

		this.setState({ isOpen: !this.state.isOpen });
	};

	public render() {
		return (
			<div
				ref={this.setWrapperRef}
				className={`p-multiselect p-component tree-select-dropdown no-select ${
					this.props.selectedItems?.length > 0 && 'org-background'
				} ${this.props.className}`}
			>
				<div className="p-hidden-accessible"></div>
				<div
					className={`p-multiselect-label-container flex-1 ${
						this.props.selectedItems?.length > 0 && 'org-background'
					}`}
					onClick={this.toggleContent}
				>
					<label
						className="p-multiselect-label p-placeholder text-direction-rtl"
						title={this.state.breadCrumbTitle}
					>
						{this.state.breadCrumbTitle}
					</label>
				</div>

				<div
					className={`p-multiselect-trigger ${this.props.selectedItems?.length > 0 && 'org-background'}`}
					onClick={this.toggleContent}
				>
					<span className="p-multiselect-trigger-icon pi pi-chevron-down p-c" />
				</div>

				<div className={`tree-select-dropdown-content ${this.state.isOpen ? 'is-open' : 'is-closed'}`}>
					<this.renderTreeView />
				</div>
			</div>
		);
	}

	public renderTreeView = () => {
		return (
			<CheckboxTree
				nodes={this.props.nodes as any[]}
				checked={this.state.checked as any[]}
				expanded={this.state.expanded as any[]}
				onCheck={this.getCheckedItemsHandler as any}
				onExpand={this.expandedItems}
				noCascade={true}
				icons={{
					collapseAll: undefined,
					parentClose: undefined,
					parentOpen: undefined,
					leaf: undefined,
				}}
			/>
		);
	};

	public generateBreadCrumb = (treeItem: TreeItem): string => {
		let breadCrumb = breadCrumbTitleDefault;
		if (treeItem && treeItem.owner) {
			breadCrumb = this.generateBreadCrumb(this.state.hierarchy.find(item => treeItem.owner === item.value)!);
			return breadCrumb + ' > ' + (treeItem.label || '');
		}
		return treeItem && treeItem.label ? treeItem.label : breadCrumb;
	};

	public subtractArrays(A: number[], B: number[]) {
		// This happends when a group is edited
		if (A.length === 1 && B.length === 1 && A[0] === B[0]) {
			return A;
		}

		return A.filter(a => {
			return B.indexOf(a) === -1;
		});
	}

	public checkedItemsFromParent = (checked: number[], node: any) => {
		this.checkItemsHelper(checked, true);
	};

	public checkedItems = (checked: number[], node: any) => {
		this.checkItemsHelper(checked);
	};

	public expandedItems = (expanded: any[]) => {
		this.setState({ expanded });
	};

	private OnEnterPressed = (e: KeyboardEvent) => {
		if (this.props.onEnterPressed) {
			document.removeEventListener('keypress', this.props.onEnterPressed);
		}
		if (this.props.onEnterPressed) {
			this.props.onEnterPressed(e);
		}
	};

	private updateCheckedItem = () => {
		const hierarchy = this.generateHierarchy(this.props.nodes);
		this.setState(
			{
				...this.state,
				hierarchy,
			},
			() => this.checkedItems(this.props.selectedItems, {})
		);
	};

	private checkItemsHelper = (checked: number[], checkUser: boolean = false) => {
		if (this.props.mustHaveSelection && checked.length < 1) {
			return;
		}

		let breadCrumb = breadCrumbTitleDefault;
		if (checked.length !== 0 && this.state.hierarchy.length) {
			// Cast callback value from CheckboxTree to number[], since it returns string[]
			checked = checked.map(selected => +selected).filter(s => !isNaN(s));
			checked = this.subtractArrays(checked, this.state.checked);
			if (checkUser) {
				if (
					this.props.userId &&
					this.props.userGroupPermissions &&
					UserExistInGroup(this.props.userId, checked[0], this.props.userGroupPermissions)
				) {
					return;
				}
			}
			breadCrumb = this.generateBreadCrumb(this.state.hierarchy.find(item => checked[0] === item.value)!);
		}

		this.props.onCheckChanged(checked, breadCrumb);
		this.setState({ checked: checked, breadCrumbTitle: breadCrumb });
	};

	/**
	 * Generate hierarchy recursively to easily map breadcrumb string
	 */
	private generateHierarchy = (nodes: Node[] | any[]) => {
		const treeItems: TreeItem[] = [];

		this.generateHierarchyRecursive(treeItems, nodes);
		return treeItems;
	};

	private generateHierarchyRecursive = (treeItems: TreeItem[], nodes: Node[] | any[], owner?: string) => {
		nodes.forEach(node => {
			treeItems.push(new TreeItem(owner, node.value, node.label));
			if (node.children) {
				this.generateHierarchyRecursive(treeItems, node.children, node.value);
			}
		});
	};

	// tslint:disable-next-line: member-ordering
	private getCheckedItemsHandler =
		this.props.parentSelectedItems === true ? this.checkedItemsFromParent : this.checkedItems;
}

export default BiTreeSelect;

class TreeItem {
	public owner: string | undefined;
	public value: number | undefined;
	public label: string | undefined;
	constructor(owner: string | undefined, value: number | undefined, label: string | undefined) {
		this.owner = owner;
		this.value = value;
		this.label = label;
	}
}
