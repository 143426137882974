export enum FileTypes {
	pdf = 'pdf',
	svg = 'svg',
	png = 'png',
	jpg = 'jpg',
	jpeg = 'jpeg',
	csv = 'csv',
	xls = 'xls',
	pptx = 'pptx',
	doc = 'doc',
	docx = 'docx',
	bmp = 'bmp',
}

export const PreviewableFileTypes: FileTypes[] = [
	FileTypes.svg,
	FileTypes.png,
	FileTypes.jpg,
	FileTypes.jpeg,
	FileTypes.bmp,
];

export const getFileExtentionName = (fileName: string | undefined): FileTypes | undefined => {
	const fileExtName = fileName
		?.split('.')
		.reverse()
		.find(_ => true)
		?.toLowerCase();

	return FileTypes[fileExtName as keyof typeof FileTypes];
};
