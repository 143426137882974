export const availableRefreshRates = {
    30: 30,
    45: 45,
    60: 60,
    90: 90,
    120: 120,
    NEVER: "NEVER"
}

export type availableRefreshRatesKeys = keyof typeof availableRefreshRates;

export interface RefreshRateProps {
    home: availableRefreshRatesKeys;
}

export type refreshRateKeys = keyof RefreshRateProps;

export interface RefreshRateState {
    refreshRates: RefreshRateProps;
}
