import { FileParameter } from 'api/api';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ImageUploader from 'react-images-upload';
import { getDebtor } from 'state/ducks/debtors/operations';
import { localized } from 'state/i18n';
import { createDebtorByGroupIdSelector, useAppDispatch, useAppSelector } from 'utilities/hooks';
import { validateUrl, ValidateUserEmail } from 'utilities/user-input-validation-util';
import { Spinner } from 'view/components/spinner/spinner';
import BiSimpleInputText from 'view/shared/components/bi-input-text/bi-simple-input-text';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { DebtorWithImage } from './debtor-with-image';
import './debtors.scss';

type Props = {
	onHide: () => void;
	visible: boolean;
	groupId: number;
	onSave: (debtor: DebtorWithImage) => void;
};

const DebtorItemDialog: FC<Props> = React.memo(({ groupId, onHide, onSave, visible }: Props) => {
	const isLoading = useAppSelector(state => state.debtorsReducer.isDebtorLoading);
	const debtor = useAppSelector(state => createDebtorByGroupIdSelector(state, groupId));
	const [website, setWebsite] = useState(debtor?.website ?? '');
	const [address, setAddress] = useState(debtor?.address ?? '');
	const [address2, setAddress2] = useState(debtor?.address2 ?? '');
	const [postalCode, setPostalCode] = useState(debtor?.postalCode ?? '');
	const [city, setCity] = useState(debtor?.city ?? '');
	const [cityState, setCityState] = useState(debtor?.state ?? '');
	const [country, setCountry] = useState(debtor?.country ?? '');
	const [phoneNumber, setPhoneNumber] = useState(debtor?.phoneNumber ?? '');
	const [email, setEmail] = useState(debtor?.email ?? '');
	const [phoneNumberSales, setPhoneNumberSales] = useState(debtor?.phoneNumberSales ?? '');
	const [orderServiceLink, setOrderServiceLink] = useState(debtor?.orderServiceLink ?? '');
	const [orderStrappingLink, setOrderStrappingLink] = useState(debtor?.orderStrappingLink ?? '');
	const [image, setImage] = useState<FileParameter | undefined>(undefined);
	const dispatch = useAppDispatch();

	/**
	 * Avoid useEffect fetching infinite times if no debtor is returned
	 */
	const hasFetchedDebtor = useRef(false);

	useEffect(() => {
		if (visible && !isLoading && debtor === undefined && !hasFetchedDebtor.current) {
			getDebtor(groupId)(dispatch);
			hasFetchedDebtor.current = true;
		}
	}, [debtor, dispatch, isLoading, groupId, visible]);

	useEffect(() => {
		if (debtor) {
			setWebsite(debtor.website ?? '');
			setAddress(debtor.address ?? '');
			setAddress2(debtor.address2 ?? '');
			setPostalCode(debtor.postalCode ?? '');
			setCity(debtor.city ?? '');
			setCityState(debtor.state ?? '');
			setCountry(debtor.country ?? '');
			setPhoneNumber(debtor.phoneNumber ?? '');
			setEmail(debtor.email ?? '');
			setPhoneNumberSales(debtor.phoneNumberSales ?? '');
			setOrderServiceLink(debtor.orderServiceLink ?? '');
			setOrderStrappingLink(debtor.orderStrappingLink ?? '');
		}
	}, [debtor]);

	const validateInput = useCallback((): boolean => {
		let isValid = true;

		isValid &&= !email || ValidateUserEmail(email);
		isValid &&= !website || validateUrl(website, localized('Website'));
		isValid &&= !orderServiceLink || validateUrl(orderServiceLink, localized('OrderService'));
		isValid &&= !orderStrappingLink || validateUrl(orderStrappingLink, localized('OrderStrapping'));

		return isValid;
	}, [email, orderServiceLink, orderStrappingLink, website]);

	const saveChanges = useCallback(() => {
		if (!validateInput()) {
			return;
		}

		const newDebtor: DebtorWithImage = {
			debtorInfo: {
				website,
				address,
				address2,
				postalCode,
				city,
				state: cityState,
				country,
				phoneNumber,
				email,
				phoneNumberSales,
				orderServiceLink,
				orderStrappingLink,
			},
			image,
		};

		onSave(newDebtor);
		onHide();
	}, [
		address,
		address2,
		city,
		cityState,
		country,
		email,
		image,
		orderServiceLink,
		orderStrappingLink,
		phoneNumber,
		phoneNumberSales,
		postalCode,
		onHide,
		onSave,
		validateInput,
		website,
	]);

	const textFieldGenerator = useCallback(
		(title: string, value: string, setStateFunction: React.Dispatch<React.SetStateAction<string>>) => {
			const onChange = (e: React.FormEvent<HTMLInputElement>) => {
				setStateFunction(e.currentTarget.value);
			};
			return (
				<>
					<span className="align-self-center">{title}</span>
					<BiSimpleInputText className="debtor-item-full-width-important" value={value} onChange={onChange} />
				</>
			);
		},
		[]
	);

	const onDrop = useCallback((imageFiles: File[], _imageDataURLs: string[]) => {
		setImage({ data: imageFiles[0], fileName: imageFiles[0].name });
	}, []);

	return (
		<BiTextDialog title={localized('DebtorManagement')} onHide={onHide} visible={visible} className="debtor-dialog">
			{isLoading ? (
				<Spinner
					shouldOverlay={false}
					shouldUseAbsolutePositioning={false}
					useMarginsForCorrectingCentering={false}
				/>
			) : (
				<>
					<div className="debtor-item-grid">
						<div>
							<h5>{localized('company')}</h5>
							<div className="debtor-item-grid">
								{textFieldGenerator(localized('Website'), website, setWebsite)}
								{textFieldGenerator(localized('Address'), address, setAddress)}
								{textFieldGenerator(localized('Address2'), address2, setAddress2)}
								{textFieldGenerator(localized('PostalCode'), postalCode, setPostalCode)}
								{textFieldGenerator(localized('city'), city, setCity)}
								{textFieldGenerator(localized('CityState'), cityState, setCityState)}
								{textFieldGenerator(localized('country'), country, setCountry)}
							</div>
						</div>

						<div>
							<h5>{localized('Service')}</h5>
							<div className="debtor-item-grid">
								{textFieldGenerator(localized('PhoneNo'), phoneNumber, setPhoneNumber)}
								{textFieldGenerator(localized('email'), email, setEmail)}
								{textFieldGenerator(
									localized('PhoneNumberSales'),
									phoneNumberSales,
									setPhoneNumberSales
								)}
							</div>
						</div>

						<div className="margin-top-20px">
							<h5>{localized('Consumables')}</h5>
							<div className="debtor-item-grid">
								{textFieldGenerator(localized('OrderService'), orderServiceLink, setOrderServiceLink)}
								{textFieldGenerator(
									localized('OrderStrapping'),
									orderStrappingLink,
									setOrderStrappingLink
								)}
							</div>
						</div>
					</div>

					<ImageUploader
						withIcon={true}
						withPreview={true}
						buttonText={localized('UploadImage')}
						onChange={onDrop}
						imgExtension={['.jpg', '.png']}
						maxFileSize={5242880}
						singleImage={true}
						label="Max file size: 5mb, accepted: jpg|png"
					/>

					<div className="flex-end-row margin-top-10px">
						<BiButton
							onClick={saveChanges}
							colorTheme={'org-green'}
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Save')}
						</BiButton>
					</div>
				</>
			)}
		</BiTextDialog>
	);
});

export default DebtorItemDialog;
