import { gpsHistoryClient } from '../api-clients';
import { GetGPSHistoryAction } from './actions';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { GPSHistoryClient } from 'api/api';


export async function getGPSHistory(serialNumber: string, startDate: Date, endDate: Date) {
    return AsyncOperationBuilder(
        GetGPSHistoryAction,
        apiClient => (apiClient as GPSHistoryClient).getGPSHistoryByDeviceId(serialNumber, startDate, endDate),
        { serialNumber, startDate, endDate },
        gpsHistoryClient
    );
}