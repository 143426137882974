import { ProfileUnit } from 'api/api';
import { localized } from 'state/i18n';
import { PoundConversionFactor } from './constants';
import { biConvertNumberToSeparatedString } from './number-helpers';

/** Fo */
export function FormatWeightWithUnit(weightInKg: number | null | undefined, convertToUnit: ProfileUnit): string {
	if (weightInKg === null || weightInKg === undefined) return '';

	if (convertToUnit === ProfileUnit.Metric) {
		return `${biConvertNumberToSeparatedString(Math.round(weightInKg))} ${localized('Unit_KiloGram')}`;
	} else {
		return `${biConvertNumberToSeparatedString(Math.round(weightInKg * PoundConversionFactor))} ${localized(
			'Unit_Pound'
		)}`;
	}
}

export function ConvertToKg(weight: number | undefined, currentUnit: ProfileUnit) {
	if (weight === undefined) return undefined;

	return currentUnit === ProfileUnit.Metric ? weight : weight / PoundConversionFactor;
}

export function FormatDistanceWithUnit(
	convertedDistance: number | null | undefined,
	convertToUnit: ProfileUnit
): string {
	if (convertedDistance === null || convertedDistance === undefined) return '';

	if (convertToUnit === ProfileUnit.Metric) {
		return `${biConvertNumberToSeparatedString(Math.round(convertedDistance))} ${localized('Unit_Km')}`;
	} else {
		return `${biConvertNumberToSeparatedString(Math.round(convertedDistance))} ${localized('Unit_Miles')}`;
	}
}
