import { FC } from "react";
import React from "react";
import { IDashboardChartItem } from "api/api";
import { localized } from "state/i18n";
import { getElementCircle } from "view/shared/utils/trigonometry-util";
import { biConvertNumberToSeparatedString } from "utilities/number-helpers";

interface Props {
    chartItems: IDashboardChartItem[],
    backgroundColors: string[],
    maxRows: number
    onRowClick?: (item: IDashboardChartItem, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
}

export const DashboardTable: FC<Props> = React.memo((props: Props) => {
    let chartItems = props.chartItems;

    // Handle case where we are given more items than maxRows. 
    // An 'others' category is added which groups all additional items
    if (props.chartItems.length > props.maxRows) {
        const othersValue = props.chartItems
            .slice(props.maxRows - 1, props.chartItems.length - 1)
            .reduce((a, b) => a + b.chartValue, 0);

        chartItems = chartItems.slice(0, props.maxRows - 1);
        chartItems.push({
            chartName: localized('Other'),
            chartValue: othersValue
        });
    }
    const tableRows = chartItems.map((item, i) => (
        <DashboardTableRow
            key={item.chartName}
            chartItem={item} 
            onClick={props.onRowClick}
            color={props.backgroundColors[i]}
        />
    ));

    return (
        <table className="dashboard-table align-left margin-left-20px">
            <tbody>{tableRows}</tbody>
        </table>
    )
});


interface TableRowProps {
    chartItem: IDashboardChartItem,
	color: string,
	addSpacing?: React.ReactNode | undefined,
    onClick?: (item: IDashboardChartItem, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
}

const tableRowSpacer = <tr className="table-row-spacer-5px" />
const DashboardTableRow: FC<TableRowProps> = React.memo((props: TableRowProps) => {
    const onClick = props.onClick !== undefined ? 
        (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {props.onClick!(props.chartItem, event);} :
        undefined

    const label = (
		<div className="flex-center-column">
			{getElementCircle({backgroundColor: props.color})}
			<span className="margin-left-10px line-height-normal">{props.chartItem.chartName}</span>
		</div>
	);

    return (
        <>
            <tr className={`margin-bottom-0-5em ${onClick ? "cursor-pointer" : ""}`} onClick={onClick}>
                <td className="text-bold font-size-normal padding-right-10px">{label}</td>
                <td className="padding-left-10px font-size-normal">{biConvertNumberToSeparatedString(props.chartItem.chartValue)}</td>
            </tr>
            {props.addSpacing || tableRowSpacer}
        </>
    )
});