import { IDashboardChartItem } from 'api/api';
import { BrandName } from 'utilities/constants';
import { BrandBramidan } from '../navigation-bar/brand-details';
import {
	colorOrange,
	colorOrgBlue,
	colorMetalGrey,
	colorPrimaryLight10Bramidan,
	colorPrimaryLight20Bramidan,
	colorGreen,
	colorPrimaryLight10Presto,
	colorPrimaryLight20Presto,
} from 'view/styles/colors';
import './dashboard-util.scss';

export const chartColorListBramidan: string[] = [
	colorOrange,
	colorOrgBlue,
	colorMetalGrey,
	colorPrimaryLight10Bramidan,
	colorPrimaryLight20Bramidan,
];
export const chartColorListPresto: string[] = [
	colorGreen,
	colorOrgBlue,
	colorMetalGrey,
	colorPrimaryLight10Presto,
	colorPrimaryLight20Presto,
];

// Safety check. Remove if color lists are replaced with lists from API.
if (chartColorListBramidan.length !== chartColorListPresto.length) {
	throw new Error('Brand color lists must have equal lengths');
}
export const DashboardMaxNoOfUniqueItems = chartColorListBramidan.length;

function getCompanyColorList() {
	// To ensure BrandName is set, we put the check in a function.
	return BrandName === BrandBramidan ? chartColorListBramidan : chartColorListPresto;
}

export function InsertChartItem(
	dashboardChartItem: IDashboardChartItem,
	labels: string[],
	dataSets: number[],
	backgroundColor: string[],
	index?: number,
	color: string = getRandomHexColor()
) {
	labels.push(dashboardChartItem.chartName || '');
	dataSets.push(dashboardChartItem.chartValue);

	if (index !== undefined && index < DashboardMaxNoOfUniqueItems && index in getCompanyColorList()) {
		backgroundColor.push(getCompanyColorList()[index]);
	} else {
		backgroundColor.push(color);
	}
}

function getRandomHexColor() {
	return '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
}

export function GetLineChartOptions(
	yAxisLabel: string,
	min?: number,
	max?: number,
	dataUnit?: string
): Chart.ChartOptions {
	return {
		responsive: true,
		legend: {
			display: false,
		},
		tooltips: {
			mode: 'index',
			intersect: true,
			callbacks: {
				label: tooltipItem => {
					return `${tooltipItem.yLabel} ${dataUnit ?? ''}`;
				},
			},
		},
		animation: {
			duration: 0,
		},

		scales: {
			xAxes: [
				{
					ticks: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					scaleLabel: {
						labelString: yAxisLabel,
						display: true,
						padding: 0,
					},
					ticks: {
						beginAtZero: false,
						suggestedMin: min,
						suggestedMax: max,
					},
				},
			],
		},
	};
}
