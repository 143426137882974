import { TabPanel, TabPanelProps, TabView, TabViewProps } from 'primereact/tabview';
import React from 'react';
import './bi-tabview.scss';

export interface BiTabViewProps extends TabViewProps {
	containerClassNames?: string;
	onTabChange1?: (index: number) => void;
}

interface State {}

export class BiTabView extends React.PureComponent<BiTabViewProps, State> {
	constructor(props: BiTabViewProps) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className={`biTab ${this.props.containerClassNames}`}>
				<TabView
					activeIndex={this.props.activeIndex}
					onTabChange={this.onChange}
					id={this.props.id}
					style={this.props.style}
				>
					{this.props.children}
				</TabView>
			</div>
		);
	}

	private onChange = (e: { originalEvent: Event; index: number }) => {
		if (this.props.onTabChange1) {
			this.props.onTabChange1(e.index);
		}

		if (this.props.onTabChange) {
			this.props.onTabChange(e);
		}
	};
}

export interface BiTabPanelProps extends TabPanelProps {}
export class BiTabPanel extends React.PureComponent<BiTabPanelProps> {
	render() {
		return (
			<div className="biTab">
				<TabPanel
					header={this.props.header}
					leftIcon={this.props.leftIcon}
					rightIcon={this.props.rightIcon}
					disabled={this.props.disabled}
				>
					{this.props.children}
				</TabPanel>
			</div>
		);
	}
}
