import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { INotificationCustomMessage } from 'api/api';
import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { SaveCustomMessages } from 'state/ducks/notification-custom-message/operations';
import { getUserSettings } from 'state/ducks/user-settings/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { Spinner } from 'view/components/spinner/spinner';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { CustomMessageDialogItem } from './cards/custom-msg-dialog-item';

const mapStateToProps = (state: AppState) => {
	return {
		customMessages: state.notificationCustomMessageReducer.customMessages,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getUserSettings: async () => (await getUserSettings())(dispatch),
	saveCustomMessages: (serialnumber: string, messages: INotificationCustomMessage[]) =>
		SaveCustomMessages(serialnumber, messages)(dispatch),
});

type Props = DialogProps &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> & {
		serialNumber: string;
	};

type State = {
	currentCustomMessages: INotificationCustomMessage[];
	showSpinner: boolean;
};

class CustomMessageDialog extends React.PureComponent<Props, State> {
	componentDidUpdate = (oldProps: Props) => {
		if (oldProps.customMessages !== this.props.customMessages) {
			this.setState({ currentCustomMessages: this.props.customMessages });
		}
	};

	onTextChange = (msg: INotificationCustomMessage) => {
		this.setState({
			currentCustomMessages: [
				...this.state.currentCustomMessages.map(old => {
					if (old.unitId === msg.unitId && old.linkError === msg.linkError) return msg;
					return old;
				}),
			],
		});
	};

	onSave = async () => {
		this.setState({ showSpinner: true });
		await this.props.saveCustomMessages(this.props.serialNumber, this.state.currentCustomMessages);
		this.setState({ showSpinner: false });
		this.props.onHide();
	};

	shouldDisableSaveBtn = (): boolean => {
		if (!this.state?.currentCustomMessages) return true;

		return this.state.currentCustomMessages.every(cpy => {
			const currMsg = this.props.customMessages.find(
				msg => msg.unitId === cpy.unitId && msg.linkError === cpy.linkError
			);
			if (currMsg?.message === cpy.message) {
				return true;
			}
			return false;
		});
	};

	getCustomMessageItems = (): JSX.Element => {
		if (!this.props.customMessages) return <div />;
		let sortedProps = [...this.props.customMessages].sort((a, b) => a.linkError - b.linkError);

		return (
			<div className="custom-msg-grid-container">
				{sortedProps.map(a => (
					<CustomMessageDialogItem msg={a} onTextChange={this.onTextChange} key={a.linkError} />
				))}
			</div>
		);
	};
	render() {
		return (
			<BiTextDialog
				showHeader={false}
				title={this.props.header}
				subtitle={localized('CustomMessageDetailsExplained')}
				{...this.props}
				className="custom-msg-dialog-container"
			>
				{this.getCustomMessageItems()}
				<br />
				<div className="flex-end-row margin-top-10px">
					<BiButton
						onClick={this.props.onHide}
						colorTheme="org-primary-grey"
						containerClassName="margin-right-24px"
						containerTheme="slim-with-rounded-corners"
					>
						{localized('Cancel')}
					</BiButton>
					<BiButton
						onClick={this.onSave}
						colorTheme="org-green"
						containerTheme="slim-with-rounded-corners"
						disabled={this.shouldDisableSaveBtn()}
					>
						{localized('Save')}
					</BiButton>
				</div>
				{this.state?.showSpinner && <Spinner />}
			</BiTextDialog>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, CustomMessageDialog, 'Custom Message Dialog'));
