import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
    'SET_HISTORY_COLUMN_ORDER',
    'SET_HISTORY_COLUMN_SIZES',
    'SET_HISTORY_COLUMN_SORTING',
    'SET_HISTORY_COLUMNS',
    'GET_HISTORY_TABLE_SETTINGS',
    'SAVE_HISTORY_TABLE_SETTINGS',
);
