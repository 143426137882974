import { i18nTranslationKey } from 'state/i18n';
import { IsBrandBramidan } from './brand-utils';

type TutorialVimeoId = {
	id: string | null;
	title: i18nTranslationKey;
};

type TutorialType = 'gettingStarted' | 'reports' | 'baleSettings' | 'notifications' | 'userManagement';

export const tutorialVimeoId: Record<TutorialType, TutorialVimeoId> = {
	reports: {
		id: '729895686/bb7a8b12ab',
		title: 'CreateReports',
	},
	baleSettings: {
		id: '729895858/6a88aa77af',
		title: 'balesOnSite',
	},
	gettingStarted: {
		id: '729895895/6fd4f63205',
		title: 'GetStartedWithTheWebPortal',
	},
	notifications: {
		id: '729895907/ec527180ec',
		title: 'Notifications',
	},
	userManagement: {
		id: '729895930/5a78a889cf',
		title: 'SetUpANewCustomer',
	},
} as const;

export const TutorialVimeoIds = Object.values(tutorialVimeoId);
export const ShouldShowTutorials = IsBrandBramidan;

if (!ShouldShowTutorials) {
	for (const tutorial of TutorialVimeoIds) {
		tutorial.id = null;
	}
}
