import { PureComponent } from "react";
import React from "react";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { ColumnGeneric } from "state/ducks/table-settings/types";
import "./edit-notification-columns.scss";
import { localized } from "state/i18n";
import { NotificationColumnKeys } from "state/ducks/notifications/types";


type Props = {
    onCancel: () => void;
    selectedTableColumns: ColumnGeneric<NotificationColumnKeys>[];
    allTableColumns: ColumnGeneric<NotificationColumnKeys>[];
    onReorder: (allColumns: ColumnGeneric<NotificationColumnKeys>[]) => void;
    onSelection: (allColumns: ColumnGeneric<NotificationColumnKeys>[]) => void;
}

const columnWidth = {width: '3em'}

class EditNotificationColumnsTable extends PureComponent<Props> {
    onReorder = (e: {
        originalEvent: Event;
        value: any;
        dragIndex: number;
        dropIndex: number;
    }) => {
        this.props.onReorder(e.value);
    }

    onSelection = (e: {
        originalEvent: Event;
        value: any;
    }) => {
        this.props.onSelection(e.value);
    }


    render() {
        return (
            <DataTable value={this.props.allTableColumns} 
                selection={this.props.selectedTableColumns}
                onRowReorder={this.onReorder}
                onSelectionChange={this.onSelection}
                scrollable={true}
                scrollHeight={"50vh"}
                >
                <Column rowReorder={true} style={columnWidth}/>
                <Column selectionMode="multiple" style={columnWidth}/>
                <Column columnKey="columnName" field="title" header={localized("MachineDetails")}></Column>
            </DataTable>
        )
    }
}
export default EditNotificationColumnsTable;