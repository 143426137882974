import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
	'CLEAR_MACHINES',
	'GET_MACHINES',
	'GET_MACHINE',
	'GET_MACHINE_COUNT',
	'SET_LOADING_STATE',
	'SET_MACHINES',
	'RENEW_TABLE_KEY',
	'UPDATE_MACHINES_OWNER_GROUP_ID',
	'UPDATE_MACHINES_OWNER_GROUP_NAME',
	'SET_SKIP_STATE',
	'DELETE_MACHINE',
	'CREATE_MACHINE'
);
