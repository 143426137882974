import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
	'GET_NOTIFICATION_TYPES',
	'GET_NOTIFICATION_DEFINITIONS',
	'SET_TEMP_NOTIFICATION_DEFINITION',
	'ADD_TEMP_NOTIFICATION_TYPE',
	'REMOVE_TEMP_NOTIFICATION_TYPE',
	'REMOVE_TEMP_NOTIFICATION_DEFINITION',
	'SAVE_TEMP_NOTIFICATION_DEFINITION',
	'DELETE_NOTIFICATION_DEFINITION',
	'ADD_TEMP_NOTIFICATION_USER',
	'REMOVE_TEMP_NOTIFICATION_USER',
	'SET_TEMP_NOTIFICATION_NAME',
	'SET_TEMP_NOTIFICATION_DEVICE_IDS',
	'SET_TEMP_NOTIFICATION_FILLLEVEL_BY_ID',
	'SET_TEMP_NOTIFICATION_SELECTED_COLUMNS',
	'SET_NOTIFICATION_LOADING',
	'GET_NOTIFICATION_DEFINITIONS_FOR_MACHINE',
	'SET_FILTERED_OUT',
	'SET_TEMP_LANGUAGE',
	'SET_TEMP_NOTIFICATION_TYPES',
	'SET_TEMP_NOTIFICATION_NOTIFY_BY_EMAIL',
	'SET_TEMP_NOTIFICATION_NOTIFY_BY_PUSH',
	'GET_USERS_BY_GROUP_ID',
	'SET_TEMP_ADVANCED_SETTINGS_BY_ID'
);

export const BalerFirmwareErrorMessage: string = 'Warning_NotificationDefinitionBalerFirmwareVersion'; //Const from API
