import actionCreatorFactory from 'typescript-fsa';
import { SortingGeneric, TableColumnWidthInfoGeneric } from '../history-table-settings/types';
import { constants } from './constants';
import { GPSHistoryDtoKeys } from './types';

const actionCreator = actionCreatorFactory("notification-table-settings");

export const setColumnOrderAction = actionCreator<GPSHistoryDtoKeys[]>(constants.SET_COLUMN_ORDER);
export const setColumnSizeAction = actionCreator<TableColumnWidthInfoGeneric<GPSHistoryDtoKeys>[]>(
	constants.SET_COLUMN_SIZES
);
export const setColumnSortingAction = actionCreator<SortingGeneric<GPSHistoryDtoKeys>[]>(
	constants.SET_COLUMN_SORTING
);