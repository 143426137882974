import { DashboardTileType, IDashboardBaseDto } from 'api/api';
import { Layout } from 'react-grid-layout';
import { Action } from 'redux';
import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { DashboardState } from './types';
import { upsertValueInArrayCustomId } from 'utilities/reducer-helpers';

export const defaultTileTypes: DashboardTileType[] = [
	DashboardTileType.Portfolio,
	DashboardTileType.KPIFillLevel,
	DashboardTileType.KPIService,
	DashboardTileType.UnitsPerUnitModels,
	DashboardTileType.FillLevelPerUnitModels,
	DashboardTileType.CriticalErrors,
	DashboardTileType.Last10Bales,
	DashboardTileType.RecordedPickUps,
	DashboardTileType.BalesProduced
];

export const initialState: DashboardState = {
	content: [],
	layout: [],
	menuIdToShow: 0,
	waitingCreate: false,
	callCounter: 0,
	tileCreationInProgress: [],
	lastFetchDurationMs: 20000,
	isFetchingContent: false,
	getContentStartTime: Date.now()
};

export function dashboardReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.GetDashboardContentAction.started)) {
		return {
			...state,
			getContentStartTime: Date.now(),
			isFetchingContent: true,
			callCounter: action.payload.callCounter
		}
	}

	if (
		isType(action, actions.UpdateDashboardContentAction.started) ||
		isType(action, actions.DeleteDashboardContentAction.started)
	) {
		return {
			...state,
			callCounter: action.payload.callCounter
		};
	}

	if (isType(action, actions.GetDashboardContentAction.done)) {
		// Make sure that callCounter has not changed since the call started - otherwise we might overwrite changes made by Create/Delete in the meantime
		if (state.callCounter === action.payload.params.callCounter) {
			return {
				...state,
				content: action.payload.result,
				layout: generateLayout(action.payload.result),
				lastUpdated: new Date(),
				isFetchingContent: false,
				lastFetchDurationMs: (Date.now() - state.getContentStartTime)
			};
		}
	}

	if (isType(action, actions.GetDashboardContentAction.failed)) {
		return {
			...state,
			isFetchingContent: false
		};
	}

	if (isType(action, actions.GetSingleDashboardTileAction.done)) {
		return {
			...state,
			content: upsertValueInArrayCustomId(state.content, action.payload.result, elem => elem.dashboardBase!.id),
		};
	}

	if (isType(action, actions.CreateDashboardContentAction.started)) {
		const newTypes = action.payload.dashboardBases.map(db => db.type);

		return {
			...state,
			waitingCreate: true,
			callCounter: action.payload.callCounter,
			tileCreationInProgress: [...state.tileCreationInProgress, ...newTypes]
		};
	}
	if (isType(action, actions.CreateDashboardContentAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		const content = [...state.content, ...action.payload.result];
		const newTypes = action.payload.params.dashboardBases.map(db => db.type);

		return {
			...state,
			content: content,
			layout: generateLayout(content),
			waitingCreate: false,
			tileCreationInProgress: state.tileCreationInProgress.filter(tileType => !newTypes.includes(tileType))
		};
	}
	if (isType(action, actions.CreateDashboardContentAction.failed)) {
		const newTypes = action.payload.params.dashboardBases.map(db => db.type);

		return {
			...state,
			waitingCreate: false,
			tileCreationInProgress: state.tileCreationInProgress.filter(tileType => !newTypes.includes(tileType))
		};
	}

	if (isType(action, actions.UpdateDashboardContentAction.done)) {
		const content = state.content.map(a => {
			if (a.dashboardBase?.id && action.payload.result.map(b => b.id).includes(a.dashboardBase.id)) {
				a.dashboardBase = action.payload.result.find(b => b.id === a.dashboardBase?.id);
			}
			return a;
		});

		return {
			...state,
			content: content,
			layout: generateLayout(content),
		};
	}

	if (isType(action, actions.DeleteDashboardContentAction.done)) {
		const content = state.content.filter(a => a.dashboardBase?.id !== action.payload.params.dashboardBase.id);

		return {
			...state,
			content: content,
			layout: generateLayout(content),
		};
	}

	if (isType(action, actions.SetMenuToShowIdAction)) {
		return {
			...state,
			menuIdToShow: action.payload.id,
		};
	}

	if (
		isType(action, actions.CreateReportTileAction.started) ||
		isType(action, actions.CreateGoogleMapsLocationAction.started)
	) {
		return {
			...state,
			waitingCreate: true,
			callCounter: action.payload.callCounter
		};
	}

	if (
		isType(action, actions.CreateReportTileAction.done) ||
		isType(action, actions.CreateGoogleMapsLocationAction.done)
	) {
		notificationService.showSuccessMessage(localized('TileAddedToDashboard'));
		const content = [...state.content, action.payload.result];

		return {
			...state,
			waitingCreate: false,
			content: content,
			layout: generateLayout(content),
		};
	}

	// Used for buttons on recorded pickups tile
	// if (isType(action, actions.SetPickupsDateTypeAction)) {
	// 	return {
	// 		...state,
	// 		pickUpsDateType: action.payload.dateType
	// 	};
	// }

	return state;
}

export const AddNewTilesKey: string = 'AddNewTilesKey';

const getFirstFreeTile = (layouts: Layout[]): { x: number; y: number } => {
	const currentMaxY = Math.max(...layouts.map(layout => layout.y));
	const elementsInLastRow = layouts.filter(layout => layout.y === currentMaxY);

	for (let i: number = 0; i < 3; i++) {
		let X = elementsInLastRow.find(l => l.x === i);
		if (!X) {
			return { x: i, y: currentMaxY };
		}
	}
	return { x: 0, y: currentMaxY + 1 };
};

const generateLayout = (dashboardContent: IDashboardBaseDto[]) => {
	let layouts: Layout[] = dashboardContent.map(item => {
		return {
			x: item.dashboardBase?.x_location || 0,
			y: item.dashboardBase?.y_location || 0,
			w: 1,
			h: 1,
			i: item.dashboardBase?.id ? item.dashboardBase?.id + '' : 'unknown',
		};
	});

	const firstFreeTile = getFirstFreeTile(layouts);

	layouts.push({
		x: firstFreeTile.x,
		y: firstFreeTile.y,
		w: 1,
		h: 1,
		i: AddNewTilesKey,
		static: true,
	});
	layouts = layouts.sort((a, b) => a.y - b.y);
	return layouts;
};
