import React from 'react';
import './tile-template.scss'
import '../../dashboard-tile.scss';
import { NavLink } from 'react-router-dom';
import { Routes } from 'state/ducks/routes';
import { biConvertNumberToSeparatedString } from 'utilities/number-helpers';

type Props = {
    firstNumberheadline: string,
    secondNumberHeadline: string,
    firstNumberValue: number,
    secondNumberValue: number,
    firstNumberColor?: string,
    secondNumberColor?: string,
    showLine?: boolean,
    firstNumberOnClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
    secondNumberOnClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
};

const colClassNameDefault = 'text-bolder margin-top-20px';

export class TwoNuberTileTemplate extends React.PureComponent<Props> {

    getContent = (value: number, onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) => {
        if (onClick) {
            return (
                <NavLink className="link-style-unset" to={Routes.Machines} onClick={onClick}>
                    <div>{biConvertNumberToSeparatedString(value)}</div>
                </NavLink>
            );
        }

        return <>{biConvertNumberToSeparatedString(value)}</>
    }

    public render() {
        return (
            <div className="two-number-tile">
                {this.props.showLine !== false && (<div className="border-bottom-grey" />)}
                <table className="width-100-percent dashboard-table align-center margin-top-20px">
                    <tbody>
                        <tr>
                            <td className="font-size-normal">{this.props.firstNumberheadline}</td>
                            <td className="font-size-normal">{this.props.secondNumberHeadline}</td>
                        </tr>
                        <tr>
                            <td className={`${colClassNameDefault} font-size-3-8em ${this.props.firstNumberColor ?? ""}`}>{this.getContent(this.props.firstNumberValue, this.props.firstNumberOnClick)}</td>
                            <td className={`${colClassNameDefault} font-size-3-8em ${this.props.secondNumberColor ?? ""}`}>{this.getContent(this.props.secondNumberValue, this.props.secondNumberOnClick)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
