import { withAITracking } from '@microsoft/applicationinsights-react-js';
import {
	DashboardTileType,
	DataTypes,
	DateTypes,
	FilterDto,
	IFilterDto,
	IReportSingleUnitDto,
	ReportTileDto,
} from 'api/api';
import fileSaver from 'file-saver';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { reactAI } from 'services/telemetry-service';
import { reportsExcelClient } from 'state/ducks/api-clients';
import { getSingleDashboardTileNoRedux } from 'state/ducks/dashboard/operations';
import { applyFilterAndSelection } from 'state/ducks/filter-search/operations';
import { initialFilterState } from 'state/ducks/filter-search/reducer';
import {
	clearReportTilePreview,
	getGraphReport,
	getReportByFilter,
	getReportByMachineIds,
	getTableReport,
	setDatatype,
	setDateType,
	setEndDate,
	setReportType,
	setStartDate,
} from 'state/ducks/reports/operations';
import {
	decrementByDateType,
	getDatasetLabel,
	getUnitOfDataType,
	getYLabel,
} from 'state/ducks/reports/reports-helpers';
import { getLanguage, localized } from 'state/i18n';
import { AppState } from 'state/store';
import { DashboardTileIdQueryParam, ReportsFilterContainerId } from 'utilities/constants';
import { gaEvent } from 'utilities/google-analytics';
import { MimeTypes } from 'utilities/mime-type-helper';
import { Spinner } from 'view/components/spinner/spinner';
import { GetExportButton } from 'view/shared/components/export-button';
import DatePickers from '../../../shared/components/date-pickers';
import CreateReportTileDialog from '../../dashboard/create-tile/create-report-tile-dialog';
import ReportContentButtons from './report-content-buttons';
import ReportDateTypeButtons from './report-date-type-buttons';
import ReportGraphWrapper from './report-graph-wrapper';
import ReportTable from './report-table/report-table';
import './reports.scss';
import TableGraphButtons from './table-graph-buttons/table-graph-buttons';

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps) => {
	let searchParams = new URLSearchParams(ownProps.location.search);
	let tileId = searchParams.get(DashboardTileIdQueryParam);
	let tileIdNumber = tileId ? +tileId : undefined;

	return {
		reportData: state.reportReducer.reportData,
		reportDataType: state.reportReducer.dataType,
		appliedFilter: state.filterSearchReducer.appliedFilters,
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		loading: state.reportReducer.loading,
		startDate: state.reportReducer.filterStartDate,
		endDate: state.reportReducer.filterEndDate,
		reportType: state.reportReducer.reportType,
		dateType: state.reportReducer.dateType,
		dashboardTileId: tileIdNumber,
		dashboardTile: state.dashboardReducer.content.find(c => c.dashboardBase?.id === tileIdNumber),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setDataType: (dt: DataTypes | undefined) => setDatatype(dt)(dispatch),
	getReportDataByMachineIds: async (
		machineIds: number[],
		dataType: DataTypes,
		dateType: DateTypes,
		startDate: Date,
		endDate: Date
	) => (await getReportByMachineIds(machineIds, dataType, dateType, startDate, endDate))(dispatch),
	getReportDataByFilter: async (
		filter: IFilterDto,
		dataType: DataTypes,
		dateType: DateTypes,
		startDate: Date,
		endDate: Date
	) => (await getReportByFilter(filter, dataType, dateType, startDate, endDate))(dispatch),
	getGraphReport: (
		reportData: IReportSingleUnitDto[],
		dataType: DataTypes,
		dateType: DateTypes,
		startDate: Date,
		endDate: Date
	) => getGraphReport(reportData, dataType, dateType, startDate, endDate)(dispatch),
	getTableReport: (
		reportData: IReportSingleUnitDto[],
		dataType: DataTypes,
		dateType: DateTypes,
		startDate: Date,
		endDate: Date
	) => getTableReport(reportData, dataType, dateType, startDate, endDate)(dispatch),
	setStartDate: (startDate: Date) => setStartDate(startDate)(dispatch),
	setEndDate: (endDate: Date) => setEndDate(endDate)(dispatch),
	setReportType: (reportType: ReportTypes) => setReportType(reportType)(dispatch),
	setDateType: (dateType: DateTypes) => setDateType(dateType)(dispatch),
	clearReportTilePreview: () => clearReportTilePreview()(dispatch),
	applyFilterAndSelection: async (filter: IFilterDto, selectedMachines: number[] | undefined) =>
		await applyFilterAndSelection(filter as FilterDto, selectedMachines)(dispatch),
});

interface State {
	// Table and graph specific
	xAxisLabel: string;
	yAxisLabel: string;
	graphDatasetLabel: string;

	showSpinner: boolean;
	loading: boolean;
	showGraph: boolean;
	showAddTileDialog: boolean;
	dataUnit: string;
}

export type ReportTypes = 'Table' | 'Graph';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Reports extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			xAxisLabel: localized('Time'),
			yAxisLabel: localized('PCS'),
			graphDatasetLabel: localized('BaleOutput'),
			showSpinner: false,
			loading: false,
			showGraph: false,
			showAddTileDialog: false,
			dataUnit: '%',
		};
		this.props.setDataType(undefined);
		this.props.clearReportTilePreview();
	}

	public async componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			(!prevProps.loading && this.props.loading) ||
			!isEqual(this.props.selectedMachines, prevProps.selectedMachines) ||
			prevProps.startDate !== this.props.startDate ||
			prevProps.endDate !== this.props.endDate
		) {
			await this.getReportIfBothDatesAreSet();
		}
	}

	public async componentDidMount() {
		let dashboardTile = this.props.dashboardTile;
		if (this.props.dashboardTileId && !this.props.dashboardTile) {
			// Fetch tile
			dashboardTile = await getSingleDashboardTileNoRedux(this.props.dashboardTileId);
		}

		if (
			dashboardTile?.dashboardBase?.reportTileSettings &&
			dashboardTile.dashboardBase.type === DashboardTileType.ReportPrintout
		) {
			const reportTile = dashboardTile as ReportTileDto;
			const tileSettings = dashboardTile.dashboardBase.reportTileSettings;
			const selectedDevices = tileSettings?.units?.map(device => device.id);
			await this.props.applyFilterAndSelection(
				tileSettings.filter ?? initialFilterState.appliedFilters,
				selectedDevices
			);

			// Calculate start and end date based on tile settings
			const endDate = new Date();
			const startDate = decrementByDateType(reportTile.reportTileSettingsDto.dateType, endDate);

			// Set start date. This will trigger componentDidUpdate but no data is fetched because datatype is not set yet
			this.props.setStartDate(startDate);

			this.props.setDataType(tileSettings.dataType);
			this.props.setDateType(reportTile.reportTileSettingsDto.groupingDateType);

			// Set end date. This will trigger componentDidUpdate and report data will be fetched
			this.props.setEndDate(endDate);

			this.setState({
				...this.state,
				yAxisLabel: getYLabel(tileSettings.dataType),
				graphDatasetLabel: getDatasetLabel(tileSettings.dataType),
				dataUnit: getUnitOfDataType(tileSettings.dataType),
			});
		}
	}

	public render() {
		const exportButton = GetExportButton(this.handleExportExcel, this.state.loading);
		const content = this.state.showGraph && this.props.reportDataType !== undefined ? this.getGraphContent() : null;

		const startDate = new Date(this.props.startDate);
		const endDate = new Date(this.props.endDate);
		return (
			<div className="flex-direction-column reports">
				<div
					id={ReportsFilterContainerId}
					className="flex-direction-row flex-center-content flex-wrap margin-bottom--20px"
				>
					<ReportContentButtons
						containerClassName="report-content-buttons margin-bottom-20px"
						dataType={this.props.reportDataType}
						onBaleOutputClicked={this.onBaleOutputClicked}
						onFillRateClicked={this.onFillRateClicked}
						onNoEmptyingClicked={this.onNoEmptyingClicked}
					/>

					<div className="flex-direction-row flex-wrap flex-center-row margin-bottom-20px">
						<ReportDateTypeButtons
							containerClassName="margin-top-20px"
							dateType={this.props.dateType}
							onWeekClicked={this.onWeekClicked}
							onMonthClicked={this.onMonthClicked}
							onYearClicked={this.onYearClicked}
						/>

						<div className="flex-direction-row flex-center-row">
							<DatePickers
								startDate={startDate}
								endDate={endDate}
								onStartDateChanged={this.onStartDateChanged}
								onEndDateChanged={this.onEndDateChanged}
							/>
							<TableGraphButtons
								onClick={this.handleGraphOrTableClick}
								containerClassName="margin-top-20px flex-center-column"
								initialReportType={this.props.reportType}
							/>
							{exportButton}
							{/* TODO: DASHBOARD. Once dashboard is stable, add this back */}
							{/* <AddTileButton handleClick={this.openAddTileDialog} /> */}
						</div>
					</div>
				</div>
				<div className="graph-table">{content}</div>
				<CreateReportTileDialog
					onHide={this.onTileDialogHide}
					visible={this.state.showAddTileDialog}
					title={localized('AddReportTileTitle')}
					subtitle={localized('AddReportTileSubtitle')}
				/>
			</div>
		);
	}

	public onTableClicked = async () => {
		await this.props.setReportType('Table');
	};

	public onGraphClicked = async () => {
		await this.props.setReportType('Graph');
	};

	// Calendar
	public onStartDateChanged = async (e: { value: Date | Date[] }) => {
		if (Array.isArray(e.value)) {
			return;
		}

		await this.props.setStartDate(e.value);
	};

	public onEndDateChanged = async (e: { value: Date | Date[] }) => {
		if (Array.isArray(e.value)) {
			return;
		}

		await this.props.setEndDate(e.value);
	};

	// Week/Month/Year buttons
	public onWeekClicked = async (e: any) => {
		const previousDateType = this.props.dateType;

		await this.props.setDateType(DateTypes.Week);
		await this.getReportBasedOnDateType(previousDateType, DateTypes.Week);
	};

	public onMonthClicked = async (e: any) => {
		const previousDateType = this.props.dateType;

		await this.props.setDateType(DateTypes.Month);
		await this.getReportBasedOnDateType(previousDateType, DateTypes.Month);
	};

	public onYearClicked = async (e: any) => {
		const previousDateType = this.props.dateType;

		await this.props.setDateType(DateTypes.Year);
		await this.getReportBasedOnDateType(previousDateType, DateTypes.Year);
	};

	// Data types
	public onBaleOutputClicked = async (e: any) => {
		gaEvent('Reports', 'BaleOutput');

		const previousYAxisLabel = this.state.yAxisLabel;
		const previousGraphDatasetLabel = this.state.graphDatasetLabel;

		this.props.setDataType(DataTypes.BaleOutput);
		this.setState({
			...this.state,
			yAxisLabel: getYLabel(DataTypes.BaleOutput),
			graphDatasetLabel: getDatasetLabel(DataTypes.BaleOutput),
			dataUnit: getUnitOfDataType(DataTypes.BaleOutput),
		});

		await this.getReportBasedOnContentButton(previousYAxisLabel, DataTypes.BaleOutput, previousGraphDatasetLabel);
	};

	public onNoEmptyingClicked = async (e: any) => {
		gaEvent('Reports', 'FullCounter');

		const previousYAxisLabel = this.state.yAxisLabel;
		const previousGraphDatasetLabel = this.state.graphDatasetLabel;
		this.props.setDataType(DataTypes.NoEmptyings);
		this.setState({
			...this.state,
			yAxisLabel: getYLabel(DataTypes.NoEmptyings),
			graphDatasetLabel: getDatasetLabel(DataTypes.NoEmptyings),
			dataUnit: getUnitOfDataType(DataTypes.NoEmptyings),
		});

		await this.getReportBasedOnContentButton(previousYAxisLabel, DataTypes.NoEmptyings, previousGraphDatasetLabel);
	};

	public onFillRateClicked = async (e: any) => {
		gaEvent('Reports', 'FillLevel');

		const previousYAxisLabel = this.state.yAxisLabel;
		const previousGraphDatasetLabel = this.state.graphDatasetLabel;
		this.props.setDataType(DataTypes.FillRate);
		this.setState({
			...this.state,
			yAxisLabel: getYLabel(DataTypes.FillRate),
			graphDatasetLabel: getDatasetLabel(DataTypes.FillRate),
			dataUnit: getUnitOfDataType(DataTypes.FillRate),
		});

		await this.getReportBasedOnContentButton(previousYAxisLabel, DataTypes.FillRate, previousGraphDatasetLabel);
	};

	private handleGraphOrTableClick = (reportType: ReportTypes) => {
		switch (reportType) {
			case 'Graph':
				this.onGraphClicked();
				break;
			case 'Table':
				this.onTableClicked();
				break;
		}
	};

	private getGraphContent = (contentBeforeGraph?: JSX.Element) => {
		return this.state.showSpinner ? (
			<div className="margin-top-30px">
				<Spinner shouldOverlay={false} shouldUseAbsolutePositioning={true} />
			</div>
		) : this.props.reportType === 'Graph' ? (
			<ReportGraphWrapper
				yAxisLabel={this.state.yAxisLabel}
				xAxisLabel={this.state.xAxisLabel}
				datasetLabel={this.state.graphDatasetLabel}
				dataUnit={this.state.dataUnit}
			></ReportGraphWrapper>
		) : (
			<>
				{contentBeforeGraph}
				<ReportTable />
			</>
		);
	};

	private shouldSpinnerShow = (showSpinner: boolean) => {
		this.setState({
			showSpinner,
		});
	};

	private getReport = async (
		filter: IFilterDto = this.props.appliedFilter,
		dataType: DataTypes = this.props.reportDataType!,
		dateType: DateTypes = this.props.dateType,
		startDate: Date = this.props.startDate,
		endDate: Date = this.props.endDate
	) => {
		if (dataType === null || dataType === undefined) {
			return;
		}

		this.shouldSpinnerShow(true);
		this.setGraphVisibility(true);

		// Get raw report data first before processing it.
		if (this.props.selectedMachines.length) {
			await this.props.getReportDataByMachineIds(
				this.props.selectedMachines,
				dataType,
				dateType,
				startDate,
				endDate
			);
		} else {
			await this.props.getReportDataByFilter(filter, dataType, dateType, startDate, endDate);
		}

		this.shouldSpinnerShow(false);
	};

	private setGraphVisibility = (visibility: boolean) => {
		this.setState({ showGraph: visibility });
	};

	private getReportBasedOnDateType = async (previousDateType: DateTypes, newDateType: DateTypes) => {
		this.shouldSpinnerShow(true);

		try {
			await this.getReport(undefined, undefined, newDateType);
		} catch (error) {
			notificationService.showErrorMessageWithError(
				localized('Error_ServerUnavailable'),
				localized('Error_CannotConnectToServerTryAgainLater'),
				error
			);

			await this.props.setDateType(previousDateType);
		} finally {
			this.shouldSpinnerShow(false);
		}
	};

	private getReportBasedOnContentButton = async (
		previousYAxisLabel: string,
		newDataType: DataTypes,
		previousGraphDatasetLabel: string
	) => {
		this.shouldSpinnerShow(true);
		try {
			await this.getReport(undefined, newDataType);
		} catch (error) {
			notificationService.showErrorMessageWithError(
				localized('Error_ServerUnavailable'),
				localized('Error_CannotConnectToServerTryAgainLater'),
				error
			);
			this.setState({
				...this.state,
				yAxisLabel: previousYAxisLabel,
				graphDatasetLabel: previousGraphDatasetLabel,
			});
		} finally {
			this.shouldSpinnerShow(false);
		}
	};

	private handleExportExcel = async () => {
		this.setState({ loading: true });

		try {
			const fileResponse =
				this.props.selectedMachines && this.props.selectedMachines.length
					? await reportsExcelClient.downloadFileByUnitIds(
							//todo: fix when model has been corrected
							//this.props.selectedMachines,
							this.props.selectedMachines,
							this.props.reportDataType,
							this.props.dateType,
							this.props.startDate,
							this.props.endDate,
							getLanguage()
					  )
					: await reportsExcelClient.downloadFileByFilter(
							new FilterDto(this.props.appliedFilter),
							this.props.reportDataType,
							this.props.dateType,
							this.props.startDate,
							this.props.endDate,
							getLanguage()
					  );

			if (fileResponse) {
				const blob = new Blob([fileResponse.data], {
					type: MimeTypes.ContentType.xlsx,
				});
				fileSaver.saveAs(blob, `Report-Table.${MimeTypes.Extensions.xlsx}`);
			}
		} catch (error) {
			notificationService.showErrorMessageWithError((error as any).toString(), undefined, error);
		} finally {
			this.setState({ loading: false });
		}
	};

	private getReportIfBothDatesAreSet = async () => {
		if (!this.props.startDate || !this.props.endDate) {
			return;
		}

		this.shouldSpinnerShow(true);

		await this.getReport();

		this.shouldSpinnerShow(false);
	};

	private onTileDialogHide = () => {
		this.setState({ showAddTileDialog: false });
	};

	private openAddTileDialog = () => {
		this.setState({ showAddTileDialog: true });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withAITracking(reactAI.reactPlugin, Reports, 'Reports'));
