import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

class TelemetryService {
    public readonly reactPlugin = new ReactPlugin();
    /**
     * Application insights. Exposes useful tracking utilities like
     * ```
     * trackPageView(),
     * trackPageViewPerformance(...),
     * trackEvent(), // Shows in "Usage" -> Events on Azure.
     * trackException()
     * ```
     */
    public appInsights?: ApplicationInsights;

    public initialize(insightsKey: string, appHistory: any) {
        if (this.appInsights) {
            console.warn("You cannot initialize the telemetry service if it already exists!")
            return;
        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: insightsKey,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: { history: appHistory }
                }
            }
        });

        try {
            this.appInsights.loadAppInsights();
        } catch (error) {
            console.warn("Unable to load insights key. Are you sure it's valid?")
        }
    }

    public stopTelemetry() {
        this.appInsights = undefined;
    }
}

export const reactAI = new TelemetryService();
