import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { upsertArrayOfDataMappedToIdString } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { GPSHistoryState } from './types';

export const initialState: GPSHistoryState = {
    gpsHistories: []
}

export function gpsHistoryReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
    if (isType(action, actions.GetGPSHistoryAction.done)) {
        const idDataTupleArray = upsertArrayOfDataMappedToIdString(action.payload.params.serialNumber, action.payload.result, state.gpsHistories);

        return {
            ...state,
            gpsHistories: idDataTupleArray
        }
    }

    return state;
}