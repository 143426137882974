import React from 'react';
import { NavLink } from 'react-router-dom';
import { IGPSHistoryDtoCustom } from 'state/ducks/gps-history-setting/types';
import { Routes } from 'state/ducks/routes';
import { GPSHistoryIdQueryParam, GPSHistoryLocationQueryParam } from 'utilities/constants';
import './machine-info.scss';

interface AppProps {
	machine: IGPSHistoryDtoCustom;
	value: string;
    location: string;
}

type Props = AppProps;

class GPSHistoryGpsLocation extends React.PureComponent<Props> {
	public render() {
		return (
			<NavLink
				to={`${Routes.Location}?${GPSHistoryIdQueryParam}=${this.props?.machine.deviceId}&${GPSHistoryLocationQueryParam}=${this.props?.location}`}
				className="biTableGpsLocation-container"
			>
				{this.props.value}
			</NavLink>
		);
	}
}

export default GPSHistoryGpsLocation;
