import React, { PureComponent } from 'react';
import AccordionSection from './accordion-section';
import { Guid } from 'guid-typescript';

interface Child {
    header: JSX.Element,
    content: JSX.Element,
}

type AccordionProps = {
    sections: Child[]
};

type Props = AccordionProps;


type State = {
    openId: number,
}


class BiAccordion extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { openId: 0 };
    }

    onClick = (id: number) => {
        this.setState({ openId: id });
    };

    render() {
        let componentCounter: number = 1;
        return (
            <div className="accordion">
                {(this.props.sections)?.map((child: any) => (
                    <div key={`accordionkey: ${Guid.create()}`} className="margin-bottom-20px">
                        <AccordionSection
                            isOpen={(componentCounter === this.state.openId)}
                            header={child.header}
                            componentInList={componentCounter++}
                            onClick={this.onClick}
                        >
                            {child.content}
                        </AccordionSection>
                    </div>
                ))}
            </div>
        );
    }
}

export default BiAccordion;