import { IUnitStateHistoryDto, ProfileUnit } from 'api/api';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { UpdateUnitHistoryRow } from 'state/ducks/machine-history/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { ConvertToKg } from 'utilities/unit-utils';
import BiTextArea from 'view/components/bi-text/bi-textarea';
import { Spinner } from 'view/components/spinner/spinner';
import BiInputNumber from 'view/shared/components/bi-input-number/bi-input-number';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';
import { getIcon } from 'view/shared/utils/icon-helper';
import './history-table.scss';

type Props = {
	row: IUnitStateHistoryDto;
	machineId: string;
};

const HistoryTableEdit: FC<Props> = React.memo((props: Props) => {
	const dispatch: Dispatch = useDispatch();
	const profileUnit: ProfileUnit = useSelector(
		(state: AppState) => state.userSettingReducer.userSettings.profileUnits
	);

	const [isSaving, setIsSaving] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [weight, setWeight] = useState<number | undefined>(props.row.userDefinedWeight);
	const [comment, setComment] = useState<string | undefined>(props.row.userDefinedComment);

	const clearState = () => {
		setWeight(props.row.userDefinedWeight);
		setComment(props.row.userDefinedComment);
	};

	const onClick = () => {
		setShowDialog(true);
	};

	const onCancel = () => {
		setShowDialog(false);
		clearState();
	};

	const onSave = async () => {
		setIsSaving(true);

		let row = {
			...props.row,
			userDefinedWeight: ConvertToKg(weight, profileUnit),
			userDefinedComment: comment,
		};
		await (await UpdateUnitHistoryRow(props.machineId, row))(dispatch);

		setIsSaving(false);
		setShowDialog(false);
	};

	const onCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(event.target.value);
	};

	const shouldDisableSave = () => {
		if (isSaving) return true;
		if (props.row.userDefinedWeight !== weight) return false;
		if (isNullOrWhitespace(props.row.userDefinedComment) && isNullOrWhitespace(comment)) return true;
		if (props.row.userDefinedComment !== comment) return false;

		return true;
	};

	const getDialogContent = () => {
		return (
			<div className="history-table-dialog-grid">
				<h6>{localized('UserDefinedWeight')}:</h6>
				<BiInputNumber
					onValueChange={setWeight}
					value={weight}
					suffix={' ' + (profileUnit === ProfileUnit.Imperial ? localized('Unit_Pound') : localized('Kg'))}
				/>
				<h6>{localized('comments')}:</h6>
				<BiTextArea value={comment} onChange={onCommentChange} />
			</div>
		);
	};

	return (
		<>
			<BiButton
				title={localized('Edit')}
				containerClassName="history-table-edit-btn"
				onClick={onClick}
				colorTheme="org-primary"
				containerTheme="icon-sized-with-soft-rounded-corners"
			>
				{getIcon('pencil', 18)}
			</BiButton>

			{showDialog && (
				<BiTextDialog
					onHide={onCancel}
					visible={showDialog}
					title={`${localized('Edit')} - ${localized('UnitHistory')}`}
					subtitle={localized('UnitHistoryUserdefinedExplained')}
				>
					{getDialogContent()}
					<br />
					<div className="flex-end-row margin-top-10px">
						<BiButton
							onClick={onCancel}
							colorTheme="org-red"
							containerClassName="margin-right-24px"
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Cancel')}
						</BiButton>
						<BiButton
							onClick={onSave}
							colorTheme="org-green"
							containerTheme="slim-with-rounded-corners"
							disabled={shouldDisableSave()}
						>
							{isSaving ? (
								<Spinner
									shouldOverlay={false}
									spinnerSize="spinner-container-1-4th-size"
									useMarginsForCorrectingCentering={false}
								/>
							) : (
								localized('Save')
							)}
						</BiButton>
					</div>
				</BiTextDialog>
			)}
		</>
	);
});

export default HistoryTableEdit;
