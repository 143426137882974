import { ITableSettings, TableId } from 'api/api';
import { constants } from './constants';
import { UnitHistoryDtoKeys, SortingGeneric, TableColumnWidthInfoGeneric } from './types';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory("history-table-settings");

export const setColumnOrderAction = actionCreator<UnitHistoryDtoKeys[]>(constants.SET_HISTORY_COLUMN_ORDER);
export const setColumnSizeAction = actionCreator<TableColumnWidthInfoGeneric<UnitHistoryDtoKeys>[]>(
	constants.SET_HISTORY_COLUMN_SIZES
);
export const setColumnSortingAction = actionCreator<SortingGeneric<UnitHistoryDtoKeys>[]>(
	constants.SET_HISTORY_COLUMN_SORTING
);
export const getTableSettingsAction = actionCreator.async<ITableSettings | TableId, ITableSettings | null>(
	constants.GET_HISTORY_TABLE_SETTINGS
);
export const saveTableSettingsAction = actionCreator.async<TableId |undefined, ITableSettings | null>(
	constants.SAVE_HISTORY_TABLE_SETTINGS
);
