import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IUnitHistoryInfoDto } from 'api/api';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { getGPSHistory } from 'state/ducks/gps-history/operations';
import { getUserGroupsChildHierarchy } from 'state/ducks/groups/operations';
import {
	clearUnitHistory,
	GetUnitCycles,
	getUnitHistoryFilteredByDates,
	getUnitHistoryInfo,
	ResetUnitCycles,
} from 'state/ducks/machine-history/operations';
import { getNotificationHistory } from 'state/ducks/notification-history/operations';
import { Routes } from 'state/ducks/routes';
import history from 'state/history';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { generateBreadCrumbFromUnorderedList, PropNameGroupName } from 'utilities/array-to-tree';
import { isAtLeastSuperAdminFromState, isServiceTechnicianFromState } from 'utilities/roles-util';
import { getEmailFromToken } from 'utilities/token-util';
import { IdDataTuple } from 'utilities/types-util';
import { Spinner } from 'view/components/spinner/spinner';
import { BiTabPanel, BiTabView } from 'view/shared/components/bi-tabview/bi-tabview';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import WhiteCard, { AvailableElementsForStyling } from 'view/shared/components/cards/white-card';
import LabelValueCard, {
	addTranslationLabelValueMapToList,
	LabelValueForCardMap,
} from 'view/shared/components/label-value-card';
import ResponsiveSpacingWrapper from 'view/shared/components/responsive-wrappers/responsive-spacing-wrapper';
import FilterDateBar from '../../filter-date-bar/filter-date-bar';
import FilterDateBarGPSHistory from '../../filter-date-bar/filter-date-bar-gps-history';
import GPSHistoryTableWrapper from '../bi-table/gps-history-table-wrapper';
import NotificationHistoryTableWrapper from '../bi-table/notification-history-table-wrapper';
import PressureGraphTableWrapper from '../bi-table/pressure-graph-table-wrapper';
import BiMap from '../details/cards/bi-map';
import { cardDetailsStyles } from '../details/constants';
import HistoryTableWithFilter from './history-table-with-filter/history-table-with-filter';
import './history.scss';
import PressureGraph from './pressure-graph';

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps<AppRouterProps>) => {
	const serialNumber = decodeURIComponent(ownProps.match.params.url_id);

	const currMachineHistory = state.unitHistoryReducer.unitHistoryInfos?.find(curr => curr.id === serialNumber);

	return {
		currMachineHistory,
		userGroups: state.groupsReducer.groups.find(g => g.id === getEmailFromToken()),
		serialNumber,
		isSuperAdmin: isAtLeastSuperAdminFromState(state),
		isTechnician: isServiceTechnicianFromState(state, currMachineHistory?.data.ownerGroupId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	clearUnitHistory: (id: string) => clearUnitHistory(id)(dispatch),
	getUnitHistoryInfo: async (id: string) => (await getUnitHistoryInfo(id))(dispatch),
	resetUnitCycles: () => ResetUnitCycles()(dispatch),
	getUnitHistoryFiltered: async (id: string, startDate?: Date, endDate?: Date) =>
		(await getUnitHistoryFilteredByDates(id, startDate, endDate))(dispatch),
	getUserGroupsChildHierarchy: async () => (await getUserGroupsChildHierarchy())(dispatch),
	getNotificationHistory: async (machineId: string, startDate: Date, endDate: Date) =>
		(await getNotificationHistory(machineId, startDate, endDate))(dispatch),
	getGPSHistory: async (serialNumber: string, startDate: Date, endDate: Date) =>
		(await getGPSHistory(serialNumber, startDate, endDate))(dispatch),
	getUnitCyclesFiltered: async (serialNumber: string, startDate: Date, endDate: Date) =>
		(await GetUnitCycles(serialNumber, startDate, endDate))(dispatch),
});

const cardGeneralClassNames: AvailableElementsForStyling = {
	classNameContainer: 'margin-bottom-40',
};

const cardDoubleContainerLength = `${cardGeneralClassNames.classNameContainer} card-max-width-and-margin-spacing info flex-fill-remaining-space`;
const cardPaddingContainer = `card-width-fit-content card-padding-0`;

export const initStartDate = moment().subtract(1, 'week').toDate();
export const initEndDate = new Date();
export const initHomeLocationCheckbox = false;

const targetMapAspectRatio = 740 / 500; // Actual dimensions of image shown
const targetMapHeight = 0.75 * 500; // Because the Gmaps API does not return images larger than 1280 in either dimension, the height is defined as 0.75*500px
const targetMapWidth = targetMapAspectRatio * targetMapHeight;

interface AppRouterProps {
	url_id: string;
	tab_index: string;
}

type Props = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	RouteComponentProps<AppRouterProps>;

export interface HistoryState {
	isLoading?: boolean;
	activeTab: number;
}

class UnitHistory extends React.PureComponent<Props, HistoryState> {
	private containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer} card-container-no-padding  card-max-width-and-margin-spacing map`;
	private startDate?: Date;
	private endDate?: Date;

	constructor(props: Props) {
		super(props);

		this.startDate = initStartDate;
		this.endDate = initEndDate;

		// Queue fetch data operation as soon as possible
		this.props.getUnitHistoryInfo(this.props.serialNumber);
		this.props.getUnitHistoryFiltered(this.props.serialNumber, initStartDate, initEndDate);
		this.props.getUserGroupsChildHierarchy();
		this.state = {
			isLoading: undefined,
			activeTab: +this.props.match.params.tab_index || 0,
		};
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: HistoryState): Partial<HistoryState> {
		return {
			activeTab: +nextProps.match.params.tab_index || 0,
		};
	}

	private getTabPanels = (
		machineId: string,
		currMachine: IdDataTuple<IUnitHistoryInfoDto, string> | undefined
	): JSX.Element[] => {
		const result: JSX.Element[] = [];
		result.push(
			<BiTabPanel header="Unit history" key="unitHistoryTabPanel">
				<WhiteCard classNameContainer={cardPaddingContainer}>
					<HistoryTableWithFilter
						initialStartDate={initStartDate}
						initialEndDate={initEndDate}
						machineId={machineId}
						isBaler={currMachine?.data.isBaler ?? false}
					/>
				</WhiteCard>
			</BiTabPanel>
		);

		result.push(
			<BiTabPanel header="Notification history" key="notificationHistoryHistoryTabPanel">
				<WhiteCard classNameContainer={cardPaddingContainer}>
					<div className="padding-2">
						<FilterDateBar
							idOfFilterDateBarParent="history"
							handleFilterByDates={this.handleFilterByDatesNotifications}
							initialStartDate={initStartDate}
							initialEndDate={initEndDate}
						/>
					</div>
					<NotificationHistoryTableWrapper
						isLoading={this.state.isLoading}
						machineId={machineId}
						autoStartHistoryQuery={true}
					/>
				</WhiteCard>
			</BiTabPanel>
		);

		result.push(
			<BiTabPanel header="Tracking history" key="trackingHistoryTabPanel">
				<WhiteCard classNameContainer={cardPaddingContainer}>
					<div className="padding-2">
						<FilterDateBarGPSHistory
							idOfFilterDateBarParent="history"
							handleFilterByDates={this.handleFilterByDatesGPS}
							initialStartDate={initStartDate}
							initialEndDate={initEndDate}
						/>
					</div>
					<GPSHistoryTableWrapper
						isLoading={this.state.isLoading}
						machineId={machineId}
						autoStartHistoryQuery={true}
					/>
				</WhiteCard>
			</BiTabPanel>
		);

		if (this.props.currMachineHistory?.data.isGen2 && (this.props.isSuperAdmin || this.props.isTechnician)) {
			result.push(
				<BiTabPanel header={localized('PressureGraph')} key="pressureGraphTabPanel">
					<WhiteCard classNameContainer={cardPaddingContainer}>
						<div className="padding-2">
							<FilterDateBar
								idOfFilterDateBarParent="history"
								handleFilterByDates={this.handleFilterByDatesPressureGraph}
								initialStartDate={this.startDate}
								initialEndDate={this.endDate}
								className="padding-bottom-2"
							/>
							<div className="flex-container flex-direction-row">
								<div className="flex-basis-0 pressure-graph-table-wrapper-container">
									<PressureGraphTableWrapper unitSerialNumber={this.props.serialNumber} />
								</div>
								<div className="padding-left-32px flex-1">
									<PressureGraph unitSerialNumber={this.props.serialNumber} />
								</div>
							</div>
						</div>
					</WhiteCard>
				</BiTabPanel>
			);
		}

		return result;
	};

	public render() {
		const { url_id: machineId } = this.props.match.params;

		const { currMachineHistory } = this.props;

		const mapContainerClassName = `${cardGeneralClassNames.classNameContainer} margin-top-30px card-padding-0 flex-fill-remaining-space limit-height margin-bottom-0-important`;
		const machineInfoData =
			currMachineHistory && currMachineHistory.data ? (
				this.getFilledLabelValueCard(machineId, currMachineHistory.data)
			) : (
				<Spinner shouldOverlay={false} spinnerSize="spinner-container-half-size" />
			);

		return (
			<BiTitle title={localized('UnitHistory')}>
				<div className="history">
					<ResponsiveSpacingWrapper>
						<div className="flex-direction-row flex-space-between-row margin-bottom-20">
							<div className="flex-direction-column margin-top-30px flex-fill-remaining-space">
								<div className="flex-direction-row flex-center-column margin-bottom-40">
									<h1 className="text-bold margin-bottom-0">{localized('UnitHistory')}</h1>
									<NavLink
										className="margin-left-40 margin-right-5px"
										to={`${Routes.MachineDetails}/${encodeURIComponent(
											this.props.match.params.url_id
										)}`}
									>
										<BiButton colorTheme="org-dark-blue-with-grey-text">
											{localized('MachineDetails')}
										</BiButton>
									</NavLink>
								</div>
								{/* To make the card fill out the row, not the column, we nest it */}
								<div className="flex-start-column history-container">
									<WhiteCard classNameContainer={cardDoubleContainerLength}>
										{machineInfoData}
									</WhiteCard>

									{this.props.currMachineHistory &&
										((this.props.currMachineHistory.data.latitude &&
											this.props.currMachineHistory.data.longitude) ||
											(this.props.currMachineHistory.data.homeLatitude &&
												this.props.currMachineHistory.data.homeLongitude)) && (
											<WhiteCard
												title={
													this.props.currMachineHistory.data.latitude &&
													this.props.currMachineHistory.data.longitude
														? localized('gpsLocation')
														: localized('HomeLocation')
												}
												classNameTitle={'padding-32px'}
												classNameContainer={this.containerClassName}
											>
												<BiMap
													gpsLocation={{
														latitude: this.props.currMachineHistory.data.latitude
															? this.props.currMachineHistory.data.latitude
															: this.props.currMachineHistory.data.homeLatitude,
														longitude: this.props.currMachineHistory.data.latitude
															? this.props.currMachineHistory.data.longitude
															: this.props.currMachineHistory.data.homeLongitude,
													}}
													width={`${targetMapWidth}`}
													height={`${targetMapHeight}`}
													machineDetailsId={this.props.currMachineHistory.data.id}
													classNameContainer={mapContainerClassName}
													fillLevel={this.props.currMachineHistory.data.fillLevel}
													hasModem={this.props.currMachineHistory.data.hasModem}
													isOnline={this.props.currMachineHistory.data.isOnline}
												/>
											</WhiteCard>
										)}
								</div>
							</div>
						</div>
					</ResponsiveSpacingWrapper>
					<div className="margin-horizontal-responsive">
						<BiTabView activeIndex={this.state.activeTab} onTabChange1={this.handleChangeTabView}>
							{this.getTabPanels(machineId, currMachineHistory)}
						</BiTabView>
					</div>
				</div>
			</BiTitle>
		);
	}

	private getFilledLabelValueCard(machineId: string, currMachine: IUnitHistoryInfoDto) {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		const ownerGroupBreadCrumb = this.props.userGroups
			? generateBreadCrumbFromUnorderedList(
					this.props.userGroups.data,
					PropNameGroupName,
					currMachine.ownerGroupId
			  )
			: '';

		addTranslationLabelValueMapToList('unitId', machineId, retMap);
		addTranslationLabelValueMapToList('OwnerGroup', ownerGroupBreadCrumb, retMap);
		addTranslationLabelValueMapToList('homeLocation', currMachine.siteAddress, retMap);
		addTranslationLabelValueMapToList('unitType', localizedDynamic(currMachine.unitTypeTranslationKey!), retMap);
		addTranslationLabelValueMapToList('devicePhoneNo', currMachine.devicePhoneNo, retMap);
		addTranslationLabelValueMapToList('siteUser', currMachine.siteUser, retMap);
		addTranslationLabelValueMapToList('city', currMachine.siteCity, retMap);
		addTranslationLabelValueMapToList('PostalCode', currMachine.sitePostalCode, retMap);
		addTranslationLabelValueMapToList('customerUnitId', currMachine.customerUnitId, retMap);
		addTranslationLabelValueMapToList('wasteType', localizedDynamic(currMachine.wasteType || ''), retMap);

		return <LabelValueCard labels={retMap.labels} values={retMap.valuesForLabels} />;
	}

	private handleFilterByDatesNotifications = async (startDate?: Date, endDate?: Date) => {
		if (!startDate || !endDate) return;

		this.startDate = startDate;
		this.endDate = endDate;

		this.setState({ isLoading: true });
		await this.props.getNotificationHistory(this.props.serialNumber, startDate, endDate);
		this.setState({ isLoading: false });
	};

	private handleFilterByDatesPressureGraph = async (startDate?: Date, endDate?: Date) => {
		if (!startDate || !endDate) return;

		this.startDate = startDate;
		this.endDate = moment(endDate).add('1', 'day').toDate();

		this.props.resetUnitCycles();

		this.setState({ isLoading: true });
		await this.props.getUnitCyclesFiltered(this.props.serialNumber, this.startDate, this.endDate);
		this.setState({ isLoading: false });
	};

	private handleFilterByDatesGPS = async (startDate?: Date, endDate?: Date) => {
		if (!startDate || !endDate) return;

		this.startDate = startDate;
		this.endDate = endDate;

		this.setState({ isLoading: true });
		await this.props.getGPSHistory(this.props.serialNumber, startDate, endDate);
		this.setState({ isLoading: false });
	};

	private handleChangeTabView = (tabIndex: number) => {
		this.setState({ activeTab: tabIndex });
		history.push(`${Routes.UnitHistory}/${this.props.serialNumber}/${tabIndex}`);
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, UnitHistory, 'Machine history'));
