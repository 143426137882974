import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { ProfileState } from './types';

export const initialState: ProfileState = {
	profile: {},
};

export function profileReducer(state: ProfileState = initialState, action: Action): ProfileState {
	if (isType(action, actions.getProfileAction)) {
		return {
			...state,
			profile: action.payload.profile,
		};
	} else if (isType(action, actions.updateProfileAction.done)) {
		return {
			...state,
			profile: action.payload.result,
		};
	}
	return state;
}
