import { notificationService } from 'services/notification-service';
import { localizedInterpolation } from 'state/i18n';
import { EmailRegExp, UrlRegExp } from './constants';
import { onInvalidEmail } from './user-input-validation-consts';

export type AllFieldValidationTypes = 'Email' | 'Group';

export function ValidateUserEmail(email: string): boolean {
	let acceptedCharacters = EmailRegExp;
	let isValid: boolean = acceptedCharacters.test(email.toLowerCase());

	if (!isValid) {
		onInvalidEmail();
	}
	return isValid;
}

export function validateUrl(url: string, forFieldName: string, shouldNotify = true): boolean {
	if (!UrlRegExp.test(url)) {
		shouldNotify &&
			notificationService.showInfoMessage(
				undefined,
				localizedInterpolation('Error_InvalidUrlForX', { X: forFieldName }),
				false,
				5000
			);

		return false;
	}

	return true;
}
