export interface IdDataTuple<T, TId = number> {
	id: TId;
	data: T;
}

export interface IdNumber {
	id: number;
}

export interface IdString {
	id: string;
}

/**
 * Example: Given the arg `o => o.createdDate`, it will match the word after the last period.
 * In this case, it will match and return `createdDate`.
 * @param property A function with the arg of some type (ex. interface) where
 * the relevant prop is accessed with dot-notation.
 * @see https://stackoverflow.com/a/29696050
 */
export function getInterfacePropToString<T>(property: (object: T) => void): keyof T {
	const chaine = property.toString();
	// Paste the regexp in regex101.com for a nice explanation.
	const arr = chaine.match(/[\s\S]*[\s\S]*\.([^.; ]*)[ ;\n]*/);
	return arr![1] as keyof T;
}


// TypeSafe way to extract properties as strings
export const propertiesToString = <T extends Object>(key: keyof T): string => {
	return key.toString();
}

// TypeSafe way to extract properties as strings
export const keyToString = <T extends Object>(key: T): string => {
	return key.toString();
}