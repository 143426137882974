import { AppState } from 'state/store';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import { setSelectedMachines, selectMachine, deselectMachine } from 'state/ducks/table-settings/operations';
import { IUnitSelectedDto } from 'api/api';

const mapStateToProps = (state: AppState) => {
	return {
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		machineCount: state.machinesReducer.machineCount,
		machinesSelected: state.machineSelectedReducer.machinesSelected,
	};
};

interface PropsFromParent {
	deviceId?: number;
	isSelectAll?: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setSelectedMachines: (machineIds: number[]) => setSelectedMachines(machineIds)(dispatch),
	selectMachine: (deviceId: number) => selectMachine(deviceId)(dispatch),
	deselectMachine: (deviceId: number) => deselectMachine(deviceId)(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

interface State {
	selected: boolean;
}

class MachinesSelectedCheckbox extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selected: false,
		};
	}

	public shouldComponentUpdate(nextProps: Props, nextState: State) {
		if (this.props.isSelectAll) {
			if (this.props.selectedMachines.length !== nextProps.selectedMachines.length) {
				return true;
			}
		} else {
			const index = this.props.selectedMachines.indexOf(this.props.deviceId!);
			const newIndex = nextProps.selectedMachines.indexOf(this.props.deviceId!);

			if (index !== newIndex) {
				return true;
			}
		}

		return false;
	}

	public render() {
		return <BiCheckbox onChange={this.handleChange} checked={this.isChecked()} />;
	}

	private handleChange = async (e: { originalEvent: Event; checked: boolean }) => {
		if (!this.props.deviceId || this.props.isSelectAll) {
			if (e.checked) {
				const newList = this.props.machinesSelected!.map((machine: IUnitSelectedDto) => {
					return machine.id;
				});

				this.props.setSelectedMachines(newList);
			} else {
				this.props.setSelectedMachines([]);
			}

			return;
		}

		if (e.checked) {
			this.props.selectMachine(this.props.deviceId);
		} else {
			this.props.deselectMachine(this.props.deviceId);
		}
	};

	private isChecked(): boolean {
		if (this.props.isSelectAll) {
			if (this.props.machineCount !== 0 && this.props.machineCount === this.props.selectedMachines.length) {
				return true;
			}
		} else if (this.props.selectedMachines.indexOf(this.props.deviceId!) > -1) {
			return true;
		}

		return false;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MachinesSelectedCheckbox);
