import {
	ComparisonOperator as Comparer,
	INotificationAdvancedSettingDto,
	INotificationTypeDto,
	NotificationMachineType,
} from 'api/api';
import { Button } from 'primereact/button';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notificationService } from 'services/notification-service';
import { removeTemporaryNotificationTypes } from 'state/ducks/notifications/operations';
import {
	selectNotificationAdvancedSettingsByMachineType,
	selectTemporaryNotificationType,
} from 'state/ducks/notifications/reducer';
import { localized } from 'state/i18n';
import { AdvancedNotificationName } from 'utilities/constants';
import { useAppSelector } from 'utilities/hooks';
import { localizeNotificationTypeName } from 'utilities/notification-util';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { AdvancedNotificationSetting } from './advanced-notification-setting';
import './advanced-notification.scss';

interface Props {
	onSave: (settings: INotificationAdvancedSettingDto[], notificationTypeId: number) => void;
	notificationType: INotificationTypeDto;
	isChecked: boolean;
	machineType: NotificationMachineType;
}

const AdvancedNotificationsValid = (settings: INotificationAdvancedSettingDto[]) => {
	for (var setting of settings) {
		if (
			setting.comparerInput === undefined ||
			setting.comparisonOperator === undefined ||
			setting.comparisonValue === undefined
		) {
			notificationService.showErrorMessage(undefined, localized('AdvancedNotificationValidationError'));
			return false;
		}
	}
	return true;
};

export const AdvancedNotification: FC<Props> = props => {
	const { notificationType, onSave, isChecked, machineType } = props;
	const dispatch = useDispatch();
	const notificationAdvancedSettings = useAppSelector(state =>
		selectNotificationAdvancedSettingsByMachineType(state, machineType)
	);
	const advancedNotificationsEnabled = useAppSelector(state =>
		selectTemporaryNotificationType(state, AdvancedNotificationName, machineType)
	);
	const [dialogVisible, setDialogVisible] = useState(false);
	const [advancedNotificationSettings, setAdvancedNotificationSettings] = useState<INotificationAdvancedSettingDto[]>(
		() => {
			if (notificationAdvancedSettings !== undefined) {
				return notificationAdvancedSettings;
			}
			return [
				{
					notificationTypeId: notificationType.id,
					comparisonOperator: Comparer.Equal,
					machineType: machineType,
				} as INotificationAdvancedSettingDto,
			];
		}
	);

	const displayConfigurationDialog = useCallback(() => {
		if (notificationAdvancedSettings !== undefined && notificationAdvancedSettings.length > 0) {
			setAdvancedNotificationSettings(notificationAdvancedSettings);
		} else {
			setAdvancedNotificationSettings([
				{
					notificationTypeId: notificationType.id,
					comparisonOperator: Comparer.Equal,
					machineType: machineType,
				} as INotificationAdvancedSettingDto,
			]);
		}
		setDialogVisible(true);
	}, [machineType, notificationAdvancedSettings, notificationType.id]);

	const saveAdvancedNotification = () => {
		if (AdvancedNotificationsValid(advancedNotificationSettings)) {
			onSave(advancedNotificationSettings, notificationType.id);
			hideConfigurationDialog();
		}
	};

	const hideConfigurationDialog = () => {
		setDialogVisible(false);
	};

	const addNotifificationSetting = () => {
		setAdvancedNotificationSettings([
			...advancedNotificationSettings,
			{
				notificationTypeId: notificationType.id,
				comparisonOperator: Comparer.Equal,
				machineType: machineType,
			} as INotificationAdvancedSettingDto,
		]);
	};

	const removeNotificationSetting = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const index = parseInt(event.currentTarget.value);
		const newArr = [...advancedNotificationSettings];
		newArr.splice(index, 1);
		setAdvancedNotificationSettings(newArr);
	};

	const onNotificationSettingChanged = (settings: INotificationAdvancedSettingDto, id: string | number) => {
		setAdvancedNotificationSettings(prevSettings => {
			const settingCopy = [...prevSettings];
			settingCopy[id] = settings;
			return settingCopy;
		});
	};

	const toggleAdvancedNotification = (e: { value: INotificationTypeDto; checked: boolean }) => {
		if (e.checked) {
			displayConfigurationDialog();
		} else {
			if (advancedNotificationsEnabled !== undefined) {
				removeTemporaryNotificationTypes([advancedNotificationsEnabled])(dispatch);
				setAdvancedNotificationSettings([]);
			}
		}
	};

	return (
		<>
			<BiCheckbox
				checked={isChecked}
				value={notificationType}
				onChange={toggleAdvancedNotification}
				label={localizeNotificationTypeName(notificationType)}
				key={localizeNotificationTypeName(notificationType)}
				inputId={localizeNotificationTypeName(notificationType)}
			/>

			<Button
				title={localized('AdvancedNotification')}
				className={'p-button-custom bi-button-icon-only'}
				icon="pi pi-plus-circle"
				value={notificationType.id}
				onClick={displayConfigurationDialog}
			/>
			{dialogVisible && (
				<BiTextDialog
					id="advanced-notification-dialog-container"
					onHide={hideConfigurationDialog}
					visible={dialogVisible}
					closable={false}
					blockScroll={true}
					title={localized('AdvancedNotification')}
					subtitle={localized('AdvancedNotificationExplained')}
				>
					<div className="advanced-notification-container">
						<div className="notification-settings-container">
							{advancedNotificationSettings.map((settings, index) => (
								<div className="notification-setting-container" key={index}>
									<Button
										title={localized('Clear')}
										className={'p-button-custom bi-button-icon-only notification-delete-btn'}
										icon="pi pi-times-circle"
										value={index}
										onClick={removeNotificationSetting}
									/>
									<AdvancedNotificationSetting
										value={settings}
										machineType={machineType}
										onChange={onNotificationSettingChanged}
										id={index}
									/>
								</div>
							))}
							<div className="flex-center-content add-new-advanced-notification">
								<BiButton
									colorTheme="org-primary-grey"
									containerTheme="slim-with-rounded-corners"
									containerClassName="margin-right-24px"
									onClick={addNotifificationSetting}
								>
									{localized('AddNew')}
								</BiButton>
							</div>
						</div>

						<br />
						<div className="flex-end-row margin-top-10px">
							<BiButton
								onClick={hideConfigurationDialog}
								colorTheme="org-red"
								containerTheme="slim-with-rounded-corners"
								containerClassName="margin-right-24px"
							>
								{localized('Cancel')}
							</BiButton>
							<BiButton
								onClick={saveAdvancedNotification}
								colorTheme={'org-green'}
								containerTheme="slim-with-rounded-corners"
							>
								{localized('Save')}
							</BiButton>
						</div>
					</div>
				</BiTextDialog>
			)}
		</>
	);
};

export default AdvancedNotification;
