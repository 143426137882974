import { UnitTypeClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { unitTypeClient } from '../api-clients';
import { getUnitTypesAction } from './actions';

export function getUnitTypes() {
	return AsyncOperationBuilder(
		getUnitTypesAction,
		apiClient => (apiClient as UnitTypeClient).getUnitTypes(),
		undefined,
		unitTypeClient
	);
}


