import React from 'react';
import './bi-checkboxbutton.scss';
import {
	wrapContentInDarkOverlayWhenHover,
	removeNonHtmlPropsFromBiButtonImmutable,
	getBiButtonClassName,
	BiButtonBaseProps
} from '../bi-button-base/bi-button-base';

export interface BiCheckboxButtonCustomProps {}

export interface BiCheckboxButtonProps extends BiCheckboxButtonCustomProps, BiButtonBaseProps, React.HTMLProps<HTMLInputElement> {}

class BiCheckboxButton extends React.PureComponent<BiCheckboxButtonProps> {
	public render() {
		const { containerClassName, colorTheme, containerTheme, enableDarkOverlay } = this.props;
		const fullClassName = getBiButtonClassName('bi-checkboxbutton', containerTheme, colorTheme);
		const htmlPropsOnly = removeNonHtmlPropsFromBiButtonImmutable(this.props);

		const content = (
			<label className="margin-bottom-0">
				<input {...htmlPropsOnly} className="checkbox-hide" type="checkbox" />
				<div className={`flex-direction-row flex-center-column ${fullClassName}`}>{this.props.children}</div>
			</label>
		);

		return enableDarkOverlay !== false ? (
			wrapContentInDarkOverlayWhenHover(content, containerClassName, containerTheme)
		) : (
			<div className={`${containerClassName || ''}`}>{content}</div>
		);
	}
}

export default BiCheckboxButton;
