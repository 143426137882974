import { Chart, ChartData, ChartDataset, ChartOptions, ChartType, CommonElementOptions, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as PrimeChart } from 'primereact/chart';
import React from 'react';

Chart.register(...registerables, zoomPlugin);

export interface ColorStop {
	offset: number;
	color: string;
}

type ChartPointDataType = number | string | Date;

export interface ChartPoint {
	x?: ChartPointDataType;
	y?: ChartPointDataType;
	r?: number;
	t?: ChartPointDataType;
}

type LineColor = CommonElementOptions['backgroundColor'];
type AreaColor = LineColor;

interface AppProps {
	canvasId?: string;
	/**
	 * Adds color stops with the given colors to the gradient at the given offsets.
	 * 0.0 is the offset at one end of the gradient, 1.0 is the offset at the other end.
	 */
	colorStops?: ColorStop[];
	type?: ChartType;
	lineColor?: LineColor;
	lineAreaColor?: AreaColor;
	datasetLabel?: string;
	datasetScaleId?: string;
	labels?: string[];
	data: number[] | ChartPoint[];
	options?: ChartOptions;
	height?: string;
	width?: string;
	shouldOnlyShowLastPoint?: boolean;
	fill?: boolean;
	chartRef?: React.LegacyRef<PrimeChart>;
	extraDataSets?: ChartDataset<ChartType, number[] | ChartPoint[]>[];
}

const defaultType: ChartType = 'line';
const pointRadius = 6;
type Props = AppProps;

interface State {
	backgroundColor?: string | CanvasGradient;
}

export default class Graph extends React.PureComponent<Props, State> {
	private canvasEl: HTMLCanvasElement | null | undefined;

	private emptyCanvas = (
		<div className="position-absolute">
			{/* Empty <canvas> purely for the sake of using createLinearGradient(...) */}
			<canvas id={this.props.canvasId} className="display-none" ref={ref => (this.canvasEl = ref)} />
		</div>
	);

	constructor(props: Props) {
		super(props);

		this.state = {
			backgroundColor: '', // default
		};
	}

	public componentDidMount() {
		if (!this.props.canvasId || !this.props.colorStops) {
			return;
		}

		let canvasContext: CanvasRenderingContext2D | null | undefined;
		if (this.canvasEl) {
			canvasContext = this.canvasEl.getContext('2d');
		} else {
			const canvasElement = document.getElementById(this.props.canvasId);
			if (canvasElement) {
				canvasContext = (canvasElement as HTMLCanvasElement).getContext('2d');
			}
		}

		if (canvasContext && this.props.colorStops) {
			const backgroundColor = canvasContext.createLinearGradient(0, 0, 0, 600);
			this.props.colorStops.forEach(colorStop => {
				backgroundColor.addColorStop(colorStop.offset, colorStop.color);
			});

			this.setState({
				backgroundColor,
			});
		}
	}

	public render() {
		if (!this.doWeHaveDataOrAGradientYet()) {
			return this.emptyCanvas;
		}

		let pointRadiiWithOnlyLastPointVisible;
		let pointRadiiWithOnlyLastPointVisibleHover;

		if (this.props.shouldOnlyShowLastPoint) {
			pointRadiiWithOnlyLastPointVisible = Array(this.props.data.length).fill(0);
			pointRadiiWithOnlyLastPointVisible[pointRadiiWithOnlyLastPointVisible.length - 1] = pointRadius; // 3 is default
			pointRadiiWithOnlyLastPointVisibleHover = [...pointRadiiWithOnlyLastPointVisible];
			pointRadiiWithOnlyLastPointVisibleHover[pointRadiiWithOnlyLastPointVisible.length - 1] = pointRadius + 2;
		}

		const extendedOptions = this.getChartOptions(this.props.options);

		const data: ChartData<ChartType, number[] | ChartPoint[]> = {
			labels: this.props.labels,
			datasets: [
				{
					label: this.props.datasetLabel,
					data: this.props.data,
					backgroundColor: this.state.backgroundColor || this.props.lineAreaColor,
					borderColor: this.props.lineColor,
					pointRadius: pointRadiiWithOnlyLastPointVisible,
					pointBackgroundColor: this.props.lineColor,
					pointHoverRadius: pointRadiiWithOnlyLastPointVisibleHover,
					fill: this.props.fill === undefined ? true : this.props.fill,
					yAxisID: this.props.datasetScaleId,
				},
			],
		};

		if (this.props.extraDataSets) {
			const extraProps = {
				pointRadius: pointRadiiWithOnlyLastPointVisible,
				pointHoverRadius: pointRadiiWithOnlyLastPointVisibleHover,
				fill: this.props.fill ?? true,
			};

			for (const dataset of this.props.extraDataSets) {
				let dataCopy;
				if (dataset.type === 'line') {
					dataCopy = { ...dataset, ...extraProps };
				} else {
					dataCopy = { ...dataset };
				}

				data.datasets?.push({
					...dataCopy,
				});
			}
		}

		return (
			<>
				{!this.doWeHaveDataOrAGradientYet && this.emptyCanvas}
				<PrimeChart
					type={this.props.type || defaultType}
					data={data}
					ref={this.props.chartRef}
					options={extendedOptions}
					height={this.props.height !== undefined ? this.props.height : '250px'}
					width={this.props.width || ''}
				/>
			</>
		);
	}

	private mergeDefaultAndCustomOptions = (customOptions?: ChartOptions) => {
		let mergedOptions = this.getDefaultOptionsCopy();

		for (const key in customOptions) {
			if (customOptions.hasOwnProperty(key)) {
				if (mergedOptions[key]) {
					mergedOptions[key] = Object.assign(mergedOptions[key], customOptions[key]);
				} else {
					mergedOptions[key] = customOptions[key];
				}
			}
		}

		return mergedOptions;
	};

	private getChartOptions = (customOptions?: ChartOptions) => {
		return this.mergeDefaultAndCustomOptions(customOptions);
	};

	private getDefaultOptionsCopy = () => {
		// Source: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#tick-configuration-options
		const chartOptions: ChartOptions = {
			responsive: true,
			maintainAspectRatio: false,
			layout: {
				// Avoid last point being cut off by the card. The bigger the point radius, the bigger the padding
				padding: {
					right: 10,
				},
			},
			animation: {
				duration: 0,
			},
			plugins: {
				legend: {
					display: false,
				},
			},

			scales: {
				x: {
					title: {
						display: true,

						text: 'title',
					},
					grid: {
						display: false,
					},
				},
				y: {
					// grid: {
					//   display: false
					// }
				},
			},
		};

		return chartOptions;
	};

	private doWeHaveDataOrAGradientYet = () => {
		if (!this.props.data) {
			return false;
		}

		if (this.props.colorStops && typeof this.state.backgroundColor === 'string') {
			return false;
		}

		return true;
	};
}
