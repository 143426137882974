import moment from 'moment';
import { LongDayFormat, LongMonthFormat } from 'utilities/constants';
import { capitalize } from 'utilities/string-utils';
import { getMomentToString, DateFormatterModes } from './date-to-string';

export function getLocalizedStringMonthXIndexed(monthIndex: string | number, startingIndex: number = 0) {
    let monthString = moment().month(monthIndex).subtract(startingIndex, "months").format(LongMonthFormat);
    return capitalize(monthString);
}

export function getLocalizedStringWeekXIndexed(weekIndex: number, startingIndex: number = 0) {
    const valueFromStart = moment().isoWeek(weekIndex).subtract(startingIndex, 'weeks');
	const startOfWeek = getMomentToString(valueFromStart.startOf('isoWeeks'), DateFormatterModes.byDayMonth);
	const endOfWeek = getMomentToString(valueFromStart.endOf('isoWeeks'), DateFormatterModes.byDayMonth);
	return capitalize(`${startOfWeek} - ${endOfWeek}`);
}

export function getLocalizedStringDayXIndexed(dayIndex: string | number, startingIndex: number = 0) {
    let monthString = moment().day(dayIndex).subtract(startingIndex, "days").format(LongDayFormat);
    return capitalize(monthString);
}

export function getLocalizedStringMonthsXIndexed(monthIndices: (string | number)[], startingIndex: number = 0) {
    return monthIndices.map(month => getLocalizedStringMonthXIndexed(month, startingIndex));
}
