import { Action } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { isType } from 'typescript-fsa';
import { updateMachineOwnerGroupIds, updateMachineOwnerGroupName } from 'utilities/reducer-helpers-update-machine-ownergroup';
import * as actions from './actions';
import { MachinesState } from './types';
export const initialState: MachinesState = {
	loading: true,
	skip: undefined,
	machineTableKey: '',
	machinesCallCounter: 0,
	machineCountCallCounter: 0
};

export function machinesReducer(state: MachinesState = initialState, action: Action): MachinesState {
	if (isType(action, actions.GetMachinesAction.started)) {
		return {
			...state,
			machinesCallCounter: action.payload.callCounter,
			loading: true,
		};
	} else if (isType(action, actions.GetMachinesAction.done)) {
		//This is not the newest request - ignore the value
		if (action.payload.params.callCounter !== state.machinesCallCounter) {
			reactAI.appInsights!.trackEvent({
				name: 'MachinesCallCountMismatch',
				properties: {
					stateCallCounter: state.machinesCallCounter,
					paramsCallCounter: action.payload.params.callCounter,
				},
			});
			return { ...state, loading: false };
		}

		return {
			...state,
			machines: action.payload.result,
			skip: action.payload.params.skipInput,
			loading: false,
		};
	} else if (isType(action, actions.ClearMachines)) {
		return {
			...state,
			machines: [],
			skip: 0,
		};
	} else if (isType(action, actions.GetMachineCountAction.started)) {
		return {
			...state,
			machineCountCallCounter: action.payload.callCounter,
			machineCount: undefined
		};
	} else if (isType(action, actions.GetMachineCountAction.done)) {
		if (action.payload.params.callCounter === state.machineCountCallCounter) {
			return {
				...state,
				machineCount: action.payload.result,
			};
		}
	} else if (isType(action, actions.UpdateMachinesOwnerGroupIdAction.done)) {
		return {
			...state,
			machines: updateMachineOwnerGroupIds(
				state.machines || [],
				action.payload.params.MachineIds,
				action.payload.params.destinationGroupId
			),
		};
	} else if (isType(action, actions.UpdateMachinesOwnerGroupNameAction)) {
		return {
			...state,
			machines: updateMachineOwnerGroupName(
				state.machines || [],
				action.payload.MachineIds,
				action.payload.groupName
			),
		};
	} else if (isType(action, actions.SetLoadingStateAction)) {
		return {
			...state,
			loading: action.payload.loadingState,
		};
	} else if (isType(action, actions.SetMachinesAction)) {
		return {
			...state,
			machines: [...action.payload.machines],
			skip: action.payload.skip,
		};
	} else if (isType(action, actions.RenewTableKeyAction)) {
		return {
			...state,
			machineTableKey: action.payload.tableKey,
			machines: [],
			skip: 0,
		};
	} else if (isType(action, actions.DeleteMachineAction.done)) {
		return {
			...state,
			machines:
				(state.machines && [...state.machines.filter(m => m.id !== action.payload.params.machineId)]) || [],
		};
	}

	return state;
}
