import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'state/store';
import { localizedDynamic } from 'state/i18n';

const mapStateToProps = (state: AppState, props: ParentProps) => {
	const machineDetails = state.machineSelectedReducer.machinesSelected
		? state.machineSelectedReducer.machinesSelected.find(machine => machine.id === props.machineId)
		: undefined;
	return {
		machineDetails: machineDetails,
	};
};

interface ParentProps {
	machineId: number;
	selectedGroup: string;
}

type Props = ReturnType<typeof mapStateToProps> & ParentProps;

class MachineDetailsRow extends React.PureComponent<Props> {
	public render() {
		const machineDetails = this.formatMachineDetails();
		return machineDetails;
	}

	private formatMachineDetails = (): JSX.Element | null => {
		let machineDetails = null;
		let selectedGroup = this.props.selectedGroup;
		if (this.props.machineDetails) {
			machineDetails = (
				<div className="selected-machines-container">
					<span>{this.props.machineDetails.serialNumber}</span>
					<span>{this.props.machineDetails.modelName}</span>
					<span>
						{this.props.machineDetails.wasteTypeTranslationKey
							? localizedDynamic(this.props.machineDetails.wasteTypeTranslationKey)
							: ''}
					</span>
					<span>{this.props.machineDetails.groupName}</span>
					<i className="pi pi-arrow-right"></i>
					<span>{selectedGroup}</span>
				</div>
			);
		}
		return machineDetails;
	};
}

export default connect(mapStateToProps, {})(MachineDetailsRow);
