import React from 'react';
import './bi-radiobutton.scss';
import {
	wrapContentInDarkOverlayWhenHover,
	removeNonHtmlPropsFromBiButtonImmutable,
	getBiButtonClassName,
	BiButtonBaseProps
} from '../bi-button-base/bi-button-base';

export interface BiRadiobuttonCustomProps {}

export interface BiRadiobuttonProps extends BiRadiobuttonCustomProps, BiButtonBaseProps, React.HTMLProps<HTMLInputElement> {}

class BiRadiobutton extends React.PureComponent<BiRadiobuttonProps> {
	public render() {
		const { containerClassName, colorTheme, containerTheme, enableDarkOverlay } = this.props;
		const fullClassName = getBiButtonClassName('bi-radiobutton', containerTheme, colorTheme);
		const htmlPropsOnly = removeNonHtmlPropsFromBiButtonImmutable(this.props);

		const content = (
			<label className="margin-bottom-0">
				<input {...htmlPropsOnly} className="radio-hide" type="radio" />
				<div className={`flex-direction-row flex-center-column ${fullClassName}`}>{this.props.children}</div>
			</label>
		);

		return enableDarkOverlay !== false ? (
			wrapContentInDarkOverlayWhenHover(content, containerClassName, containerTheme)
		) : (
			<div className={`${containerClassName || ''}`}>{content}</div>
		);
	}
}

export default BiRadiobutton;
