type mapToExtendedString<T extends string> = { [K in T]: K };

/**
 * Generates an object with the same properties given as args. These
 * properties will have the same name as their corresponds args and
 * can be accessed with dot-notation, type-safely!
 * 
 * @param args A list of elements.
 */
export function getArgsAsTypesafeObjectProperties<T extends string>(...args: T[]): mapToExtendedString<T> {
    const ret = {} as mapToExtendedString<T>;
    args.forEach(v => ret[v] = v);
    return ret;
}
