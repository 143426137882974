import { DuplicateIotHubDecommissionState } from './types';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export const initialState: DuplicateIotHubDecommissionState = {
	isLoading: false,
	decommissionObjects: [],
};

export function duplicateIotHubDecommissionReducer(
	state: DuplicateIotHubDecommissionState = initialState,
	action: Action
): DuplicateIotHubDecommissionState {
	if (isType(action, actions.GetIoTHubDuplicateDecommissionsAction.started)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (
		isType(action, actions.GetIoTHubDuplicateDecommissionsAction.done) ||
		isType(action, actions.DeleteIoTHubDuplicateDecommissionAction.done)
	) {
		return {
			...state,
			decommissionObjects: action.payload.result,
			isLoading: false,
		};
	}

	return state;
}
