import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { localized } from 'state/i18n';
import { images } from 'utilities/images';
import BiDialog from 'view/shared/components/dialogs/bi-dialog/bi-dialog';
import './bi-text-dialog.scss';
import { gaModalView } from 'utilities/google-analytics';

export interface EditColumnProps extends DialogProps { }

interface PropsFromParent {
	title: string;
	subtitle?: string | string[];
	showHr?: boolean;
}

type Props = EditColumnProps & PropsFromParent;
class BiTextDialog extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);

		if (this.props.visible) {
			gaModalView(this.props.title);
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.visible === false && this.props.visible === true) {
			gaModalView(this.props.title);
		}
	}

	public render() {
		return (
			<BiDialog modal={true} showHeader={false} dismissableMask={true} {...this.props} className="bi-text-dialog">
				<div className="flex-space-between-row">
					<div className="text-wrapper">
						<h3 className="text-bold title">{this.props.title}</h3>
						{this.props.subtitle &&
							(typeof this.props.subtitle === 'string' ? (
								<p className="subtitle">{this.props.subtitle}</p>
							) : (
								this.props.subtitle.map(str => <p className="subtitle">{str}</p>)
							))}
					</div>
					<img
						className="close-icon"
						src={images.close}
						alt={localized('Close')}
						onClick={this.props.onHide}
					/>
				</div>
				{(this.props.showHr === undefined || this.props.showHr) && <hr></hr>}
				<div>{this.props.children}</div>
			</BiDialog>
		);
	}
}

export default BiTextDialog;
