import {
	FilterDto,
	INotificationAdvancedSettingDto,
	INotificationColumnSelected,
	INotificationDefinitionDto,
	INotificationFillLevelSettingsDto,
	INotificationTypeDto,
	INotificationUserDto,
	SelectedLanguage,
} from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const notificationsCreator = actionCreatorFactory('notifications');

export const GetNotificationTypesAction = notificationsCreator.async<undefined, INotificationTypeDto[]>(
	constants.GET_NOTIFICATION_TYPES
);
export const GetNotificationDefinitionsAction = notificationsCreator.async<
	{ callCounter: number; filterDto: FilterDto },
	INotificationDefinitionDto[]
>(constants.GET_NOTIFICATION_DEFINITIONS);

export const DeleteTemporaryNotificationDefinitionAction = notificationsCreator.async<INotificationDefinitionDto, void>(
	constants.DELETE_NOTIFICATION_DEFINITION
);
export const SaveTemporaryNotificationDefinitionAction = notificationsCreator.async<
	INotificationDefinitionDto,
	INotificationDefinitionDto
>(constants.SAVE_TEMP_NOTIFICATION_DEFINITION);
export const SetTemporaryNotificationDefinitionAction = notificationsCreator<{ notDef: INotificationDefinitionDto }>(
	constants.SET_TEMP_NOTIFICATION_DEFINITION
);
export const RemoveTemporaryNotificationDefinitionAction = notificationsCreator(
	constants.REMOVE_TEMP_NOTIFICATION_DEFINITION
);
export const AddTemporaryNotificationTypeAction = notificationsCreator<{ notTypes: INotificationTypeDto[] }>(
	constants.ADD_TEMP_NOTIFICATION_TYPE
);
export const RemoveTemporaryNotificationTypeAction = notificationsCreator<{ notType: INotificationTypeDto[] }>(
	constants.REMOVE_TEMP_NOTIFICATION_TYPE
);
export const AddTemporaryNotificationUserAction = notificationsCreator<{ user: INotificationUserDto }>(
	constants.ADD_TEMP_NOTIFICATION_USER
);
export const RemoveTemporaryNotificationUserAction = notificationsCreator<{ user: INotificationUserDto }>(
	constants.REMOVE_TEMP_NOTIFICATION_USER
);
export const SetTemporaryNotificationLanguageAction = notificationsCreator<{ language: SelectedLanguage }>(
	constants.SET_TEMP_LANGUAGE
);
export const SetTemporaryNotificationNameAction = notificationsCreator<{ name: string }>(
	constants.SET_TEMP_NOTIFICATION_NAME
);
export const SetTemporaryNotificationMachineIdsAction = notificationsCreator<{ deviceIds: number[] }>(
	constants.SET_TEMP_NOTIFICATION_DEVICE_IDS
);
export const SetTemporaryNotificationFillLevelByIdAction = notificationsCreator<{
	id: number;
	settings: INotificationFillLevelSettingsDto[];
}>(constants.SET_TEMP_NOTIFICATION_FILLLEVEL_BY_ID);
export const SetTemporaryNotificationAdvancedSettingsByIdAction = notificationsCreator<{
	id: number;
	settings: INotificationAdvancedSettingDto[];
}>(constants.SET_TEMP_ADVANCED_SETTINGS_BY_ID);

export const SetTemporaryNotificationSelectedColumnsAction = notificationsCreator<{
	columns: INotificationColumnSelected[];
}>(constants.SET_TEMP_NOTIFICATION_SELECTED_COLUMNS);
export const GetNotificationDefinitionsForMachineAction = notificationsCreator.async<
	{ serialNumber: string },
	INotificationDefinitionDto[]
>(constants.GET_NOTIFICATION_DEFINITIONS_FOR_MACHINE);
export const SetFilteredOutAction = notificationsCreator<{ filteredOut: number }>(constants.SET_FILTERED_OUT);
export const SetTemporaryNotificationTypeAction = notificationsCreator<{ notTypes: INotificationTypeDto[] }>(
	constants.SET_TEMP_NOTIFICATION_TYPES
);
export const SetTemporaryNotificationNotifyByEmailAction = notificationsCreator<{ notifyType: boolean }>(
	constants.SET_TEMP_NOTIFICATION_NOTIFY_BY_EMAIL
);
export const SetTemporaryNotificationNotifyByPushAction = notificationsCreator<{ notifyType: boolean }>(
	constants.SET_TEMP_NOTIFICATION_NOTIFY_BY_PUSH
);
export const GetUsersByGroupId = notificationsCreator.async<number, string[]>(constants.GET_USERS_BY_GROUP_ID);
