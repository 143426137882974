import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { INotificationDefinitionDto, INotificationUserDto, RolesType, SelectedLanguage } from 'api/api';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { reactAI } from 'services/telemetry-service';
import { BalerFirmwareErrorMessage } from 'state/ducks/notifications/constants';
import {
	addTemporaryUser as addTemporaryNotificationUser,
	getUsersByGroupId,
	postNotificationDefinition,
	removeTemporaryNotificationDefinition,
	removeTemporaryUser as removeTemporaryNotificationUser,
	sendExampleNotificationEmail,
	setTemporaryLanguage,
	setTemporaryNotificationName,
	setTemporaryNotifyByEmail,
	setTemporaryNotifyByPush,
} from 'state/ducks/notifications/operations';
import { Routes } from 'state/ducks/routes';
import history from 'state/history';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { mapTreeItemsToTreeSelectItems } from 'utilities/array-to-tree';
import {
	EmailRegExp,
	FilterBarId,
	NavBarHeightPx,
	OfflineForXHoursMaxDevices,
	OfflineForXHoursNotificationName,
	UnitsSelectedRowHeightPx,
} from 'utilities/constants';
import { getEmailFromToken } from 'utilities/token-util';
import BiTreeSelect from 'view/components/bi-tree-select/bi-tree-select';
import { TreeSelectItem } from 'view/components/bi-tree-select/tree-select-item';
import { Spinner } from 'view/components/spinner/spinner';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import BiInputText from 'view/shared/components/bi-input-text/bi-input-text';
import LanguagePicker from 'view/shared/components/bi-language-picker/language-picker';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { ConfirmDialog } from '../../../../shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import { getRolePermissionFromUnitIds, machineIdsToDistinctMachineTypeSelectedList } from '../notification-helper';
import EditNotificationColumnsDialog from './edit-notification-columns/edit-notification-columns-dialog';
import './email-configuration.scss';
import { TooManyOfflineMachinesDialog } from './too-many-offline-machines-dialog';

const emailViewPixelOffset = 470;
export const calculateNotificationEmailScrollbarHeight = (
	notificationEmailListContainer: HTMLElement | null,
	filterBarElement: HTMLElement | null,
	setHeight: boolean = false
): number | null => {
	if (notificationEmailListContainer && filterBarElement) {
		const height =
			window.innerHeight -
			(filterBarElement.clientHeight + NavBarHeightPx + UnitsSelectedRowHeightPx + emailViewPixelOffset);
		if (setHeight) notificationEmailListContainer.setAttribute('style', `height: ${height}px`);
		return height;
	}
	return null;
};

const mapStateToProps = (state: AppState) => {
	return {
		notificationMachineTypes: machineIdsToDistinctMachineTypeSelectedList(
			state.tableSettingsReducer.selectedMachines
		),
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		temporaryDefinition: state.notificationsReducer.temporaryNotificationDefinition,
		userGroupPermissions: state.userPermissionReducer.UserGroupPermissions,
		userRootGroups: state.groupsReducer.groups.filter(g => g.id === getEmailFromToken()),
		userGroups: state.groupsReducer.groups,
		temporaryNotificationDefinition: state.notificationsReducer.temporaryNotificationDefinition,
		users: state.notificationsReducer.users,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	addTemporaryNotificationUser: (user: INotificationUserDto) => addTemporaryNotificationUser(user)(dispatch),
	removeTemporaryNotificationUser: (user: INotificationUserDto) => removeTemporaryNotificationUser(user)(dispatch),
	removeTemporaryNotificationDefinition: () => removeTemporaryNotificationDefinition()(dispatch),
	setDefinitionName: (name: string) => setTemporaryNotificationName(name)(dispatch),
	setTemporaryLanguage: (language: SelectedLanguage) => setTemporaryLanguage(language)(dispatch),
	postNotificationDefinition: async (
		notDef: INotificationDefinitionDto,
		selectedMachines: number[],
		checkForWarnings: boolean = false
	) => await postNotificationDefinition(notDef, selectedMachines, checkForWarnings)(dispatch),
	setTemporaryNotifyByEmail: (notifyType: boolean) => setTemporaryNotifyByEmail(notifyType)(dispatch),
	setTemporaryNotifyByPush: (notifyType: boolean) => setTemporaryNotifyByPush(notifyType)(dispatch),
	getUsersByGroupId: async (groupId: number) => (await getUsersByGroupId(groupId))(dispatch),
});

export const NotificationEmailListContainerId = 'notifications-email-list';
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
	selectedGroup: number;
	filteredUsersEmails: string[];
	groupsBreadCrumb: TreeSelectItem[];
	searchString: string;
	customEmail: string;
	rolePermission: RolesType;
	shouldUpdateFilteredEmails: boolean; //Should update on change in notificationUsers from added custom emails, but not from checked regular emails
	isEmailSending: boolean;
	showSelectColumnsDialog: boolean;
	showFirmwareDialog: boolean;
	waitingForSaveFinish: boolean;
	showOfflineMachinesDialog: boolean;
};

const NotSaveCancelId = 'not-save-cancel';

class EmailConfiguration extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			selectedGroup: this.props.userGroupPermissions[0].groupId,
			groupsBreadCrumb: [],
			searchString: '',
			customEmail: '',
			rolePermission: RolesType.BasicUser,
			filteredUsersEmails: [],
			shouldUpdateFilteredEmails: false,
			isEmailSending: false,
			showSelectColumnsDialog: false,
			showFirmwareDialog: false,
			waitingForSaveFinish: false,
			showOfflineMachinesDialog: false,
		};
	}

	makeBreadCrumb = () => {
		let groupsBreadCrumb: TreeSelectItem[] = [];
		this.props.userRootGroups.forEach(element => {
			groupsBreadCrumb = groupsBreadCrumb.concat(mapTreeItemsToTreeSelectItems(element.data));
		});
		this.setState({ groupsBreadCrumb: groupsBreadCrumb });
		if (groupsBreadCrumb[0] && groupsBreadCrumb[0].value)
			this.setState({ selectedGroup: groupsBreadCrumb[0].value });
	};

	componentDidMount() {
		this.makeBreadCrumb();
		this.setState({ rolePermission: getRolePermissionFromUnitIds(this.props.temporaryDefinition?.unitIds) });
		this.setFilteredEmails(this.state.selectedGroup);
	}
	setFilteredEmails = (groupId: number) => {
		let userEmails: string[] = [];

		let tempEmails: string[] | undefined = this.props.temporaryDefinition?.notificationUsers
			.map(user => user.email)
			.filter((email: string | undefined): email is string => !!email);
		if (tempEmails) userEmails.push(...tempEmails);

		if (this.state.rolePermission <= RolesType.Admin) userEmails.push(...this.props.users);
		else userEmails.push(getEmailFromToken());

		userEmails = userEmails.filter((email, index) => userEmails.indexOf(email) === index); //no duplicates
		this.setState({ filteredUsersEmails: userEmails });
	};
	componentDidUpdate(preProps: Props, preState: State) {
		if (preProps.userRootGroups !== this.props.userRootGroups) this.makeBreadCrumb();

		if (preProps.users !== this.props.users) this.setFilteredEmails(this.state.selectedGroup);

		if (preProps.temporaryDefinition?.unitIds !== this.props.temporaryDefinition?.unitIds) {
			this.setState({ rolePermission: getRolePermissionFromUnitIds(this.props.temporaryDefinition?.unitIds) });
			this.setFilteredEmails(this.state.selectedGroup);
		}

		if (preState.rolePermission !== this.state.rolePermission) {
			this.setState({ rolePermission: getRolePermissionFromUnitIds(this.props.temporaryDefinition?.unitIds) });
			this.setFilteredEmails(this.state.selectedGroup);

			if (this.state.rolePermission >= RolesType.BasicUser) {
				this.props.temporaryDefinition?.notificationUsers.forEach(
					user => user.email !== getEmailFromToken() && this.props.removeTemporaryNotificationUser(user)
				);
			}
		}

		//  Should update on change in notificationUsers from added custom emails, but not from checked regular emails
		if (
			this.state.shouldUpdateFilteredEmails &&
			preProps.temporaryDefinition?.notificationUsers !== this.props.temporaryDefinition?.notificationUsers
		) {
			this.setState({ shouldUpdateFilteredEmails: false });
			this.setFilteredEmails(this.state.selectedGroup);
		}
	}

	handleClickEmailFilter = (groupId: number[]) => {
		this.setState({ selectedGroup: groupId[0] });
		this.props.getUsersByGroupId(groupId[0]);
		this.setFilteredEmails(groupId[0]);
	};

	setSearchString = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({ searchString: e.currentTarget.value });
	};

	searchField = (): JSX.Element | undefined => {
		if (this.state.rolePermission >= RolesType.BasicUser) return;

		return (
			<div>
				<h6>
					<b>{localized('FilterUsers')}</b>
				</h6>
				<div className="notification-2-col-grid-container">
					<div>
						{localized('groups')}
						<BiTreeSelect
							className="wider-inputfield"
							onCheckChanged={this.handleClickEmailFilter}
							nodes={this.state.groupsBreadCrumb}
							selectedItems={[this.state.selectedGroup]}
						/>
					</div>
					<div>
						{localized('email')}
						<BiInputText
							placeholder={localized('Search')}
							value={this.state.searchString}
							onChange={this.setSearchString}
							icon="pi pi-search"
						/>
					</div>
				</div>
			</div>
		);
	};

	setCustomEmail = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({ customEmail: e.currentTarget.value });
	};

	handleAddCustomEmail = () => {
		this.props.addTemporaryNotificationUser({ email: this.state.customEmail, id: 0 });
		this.setState({ customEmail: '', shouldUpdateFilteredEmails: true });
	};

	handleClearCustomEmailString = () => {
		this.setState({ customEmail: '' });
	};

	handleSendExampleEmail = async () => {
		// Start spinner
		this.setState({ isEmailSending: true });

		// Make API request
		let success = await sendExampleNotificationEmail(this.state.customEmail);

		// Stop spinner
		this.setState({ isEmailSending: false });

		// Only show notification is reqeust was successfull. Failed requests are handled in sendExampleNotificationEmail()
		if (success) {
			notificationService.showSuccessMessage(localized('ExampleEmail'), localized('EmailSuccessfullySent'));
		}
	};

	addCustomEmail = (): JSX.Element | undefined => {
		if (this.state.rolePermission >= RolesType.BasicUser) return; //Basicusers cant add custom mails

		const initialLanguage: SelectedLanguage | undefined =
			this.props.temporaryDefinition?.id === 0 ? undefined : this.props.temporaryDefinition?.selectedLanguage;

		return (
			<div>
				{localized('AddAlternateEmail')}
				<div className="flex-wrap">
					<BiInputText
						className="wider-inputfield"
						value={this.state.customEmail}
						onChange={this.setCustomEmail}
					/>
					<Button
						title={EmailRegExp.test(this.state.customEmail) ? localized('Add') : localized('InvalidEmail')}
						className={'p-button-custom bi-button-icon-only'}
						icon="pi pi-check-circle"
						onClick={this.handleAddCustomEmail}
						disabled={!EmailRegExp.test(this.state.customEmail)}
					/>
					<Button
						title={localized('Clear')}
						className={'p-button-custom bi-button-icon-only'}
						icon="pi pi-times-circle"
						onClick={this.handleClearCustomEmailString}
					/>
					<BiButton
						onClick={this.handleSendExampleEmail}
						colorTheme="org-green"
						containerTheme="normal-button-corners"
						disabled={!EmailRegExp.test(this.state.customEmail)}
						containerClassName="send-email-btn-container"
					>
						<div className="send-email-btn-content">
							{this.state.isEmailSending ? (
								<Spinner
									shouldOverlay={false}
									shouldUseAbsolutePositioning={false}
									spinnerSize="spinner-container-1-4th-size"
									useMarginsForCorrectingCentering={false}
								/>
							) : (
								<>{localized('SendTestEmail')}</>
							)}
						</div>
					</BiButton>
				</div>
				<br />
				{localized('languageForNonUsers')}
				<LanguagePicker parentLanguage={initialLanguage} onSelectedChange={this.props.setTemporaryLanguage} />
				<br />
				{localized('NotificationType')}
				<BiCheckbox
					checked={this.props.temporaryDefinition?.shouldNotifyByEmail}
					onChange={this.toggleNotifyByEmail}
					label={localized('ReceiveMail')}
				/>
				<BiCheckbox
					checked={this.props.temporaryDefinition?.shouldNotifyByPush}
					onChange={this.toggleNotifyByPush}
					label={localized('ReceivePushNotification')}
				/>
			</div>
		);
	};

	hideColumnsDialog = () => {
		this.setState({ showSelectColumnsDialog: false });
	};

	showColumnsDialog = () => {
		this.setState({ showSelectColumnsDialog: true });
	};

	hideFirmwareCheckDialog = () => {
		this.setState({ showFirmwareDialog: false });
	};
	showFirmwareCheckDialog = () => {
		this.setState({ showFirmwareDialog: true });
	};

	hideOfflineMachinesDialog = () => {
		this.setState({ showOfflineMachinesDialog: false });
	};
	showOfflineMachinesDialog = () => {
		this.setState({ showOfflineMachinesDialog: true });
	};

	cancel = () => {
		this.props.removeTemporaryNotificationDefinition();
		history.replace(Routes.Notifications);
	};

	saveOrConfirm = async () => {
		if (!this.props.temporaryNotificationDefinition) return;

		// Checking for existing offline machines is very expensive. Set a limit
		if (
			this.props.temporaryDefinition?.notificationTypes?.some(x => x.name === OfflineForXHoursNotificationName) &&
			this.props.selectedMachines.length > OfflineForXHoursMaxDevices
		) {
			this.setState({ showOfflineMachinesDialog: true });
			return;
		}

		this.setState({ waitingForSaveFinish: true });

		try {
			await this.props.postNotificationDefinition(
				this.props.temporaryNotificationDefinition,
				this.props.selectedMachines,
				true
			);
		} catch (error: any) {
			const errorObj = JSON.parse(error.response);

			if (errorObj.message === BalerFirmwareErrorMessage) {
				this.setState({ showFirmwareDialog: true });
			}

			return;
		} finally {
			this.setState({ waitingForSaveFinish: false });
		}

		this.setState({ waitingForSaveFinish: false });
		history.replace(Routes.Notifications);
	};

	save = async () => {
		this.setState({ waitingForSaveFinish: true });

		if (this.props.temporaryNotificationDefinition) {
			try {
				await this.props.postNotificationDefinition(
					this.props.temporaryNotificationDefinition,
					this.props.selectedMachines
				);
			} catch (error) {
				this.setState({ waitingForSaveFinish: false });
				return;
			}
		}

		this.setState({ waitingForSaveFinish: false });
		history.replace(Routes.Notifications);
	};

	shouldDisableSaveButton = (): boolean => {
		if (this.props.notificationMachineTypes.length === 0) return true;
		if (!this.props.temporaryNotificationDefinition?.name || this.props.temporaryNotificationDefinition.name === '')
			return true;
		if (this.props.temporaryNotificationDefinition.notificationUsers.length === 0) return true;
		if (this.state.waitingForSaveFinish) return true;
		if (this.props.temporaryNotificationDefinition.notificationTypes?.length === 0) return true;

		return false;
	};

	actionButtons = () => {
		return (
			<>
				<div className="flex-direction-row flex-1 margin-top-20px" id={NotSaveCancelId}>
					<div className="margin-right-1 margin-top-auto margin-bottom-3em">
						<BiButton
							onClick={this.showColumnsDialog}
							colorTheme="org-primary-grey"
							containerTheme="normal-button-corners"
						>
							<span className="flex-wrap flex-center-row">
								<span className="text-bold margin-left-1 margin-right-1">
									{localized('CustomizeEmail')}
								</span>
							</span>
						</BiButton>
					</div>

					<div className="margin-right-1 margin-top-auto margin-bottom-3em">
						<BiButton onClick={this.cancel} colorTheme="org-red" containerTheme="normal-button-corners">
							<span className="flex-wrap flex-center-row">
								<span className="text-bold margin-left-1 margin-right-1">{localized('Cancel')}</span>
							</span>
						</BiButton>
					</div>

					<div className="margin-top-auto margin-bottom-3em">
						<BiButton
							onClick={this.saveOrConfirm}
							colorTheme="org-green"
							containerTheme="normal-button-corners"
							disabled={this.shouldDisableSaveButton()}
							title={this.shouldDisableSaveButton() ? localized('CantSaveNotification') : ''}
							containerClassName="save-btn-container"
						>
							<div className="save-btn-content">
								{this.state.waitingForSaveFinish ? (
									<Spinner
										shouldOverlay={false}
										spinnerSize="spinner-container-1-4th-size"
										useMarginsForCorrectingCentering={false}
									/>
								) : (
									<span className="flex-wrap flex-center-row">
										<span className="text-bold margin-left-1 margin-right-1">
											{localized('Save')}
										</span>
									</span>
								)}
							</div>
						</BiButton>
					</div>
				</div>
				<EditNotificationColumnsDialog
					onCancel={this.hideColumnsDialog}
					visible={this.state.showSelectColumnsDialog}
				/>

				<ConfirmDialog
					visible={this.state.showFirmwareDialog}
					onHide={this.hideFirmwareCheckDialog}
					onCancel={this.hideFirmwareCheckDialog}
					onConfirm={this.save}
					showConfirmSpinner={this.state.waitingForSaveFinish}
					header={localized('Warning')}
					dialogMessage={localized('SaveNotificationDefinitionFirmwareDescription')}
					confirmMessage={localized('Save')}
				/>

				<TooManyOfflineMachinesDialog
					visible={this.state.showOfflineMachinesDialog}
					onHide={this.hideOfflineMachinesDialog}
					onPress={this.hideOfflineMachinesDialog}
					header={localized('Warning')}
					dialogMessage={localized('Warning_TooManyOfflineMachines')}
					dismissableMask={true}
				/>
			</>
		);
	};

	toggleEmail = (e: { value: string; checked: boolean }) => {
		let user: INotificationUserDto = { id: 0, email: e.value };
		if (e.checked) this.props.addTemporaryNotificationUser(user);
		else this.props.removeTemporaryNotificationUser(user);
	};

	toggleNotifyByEmail = () => {
		if (!this.props.temporaryDefinition) return;
		this.props.setTemporaryNotifyByEmail(!this.props.temporaryDefinition.shouldNotifyByEmail);
	};

	toggleNotifyByPush = () => {
		if (!this.props.temporaryDefinition) return;
		this.props.setTemporaryNotifyByPush(!this.props.temporaryDefinition.shouldNotifyByPush);
	};

	isChecked = (email: string): boolean => {
		if (!this.props.temporaryDefinition) return false;
		if (!this.props.temporaryDefinition.notificationUsers) return false;
		return this.props.temporaryDefinition.notificationUsers.map(user => user.email).includes(email);
	};

	checkboxGenerator = (email: string): JSX.Element => {
		return (
			<div className="flex-1">
				<BiCheckbox
					value={email}
					checked={this.isChecked(email)}
					onChange={this.toggleEmail}
					label={email}
					inputId={email}
				/>
			</div>
		);
	};

	emailList = (): JSX.Element | undefined => {
		let emailElements: JSX.Element[] = [];
		if (!this.state.filteredUsersEmails) return;

		let userEmails: string[] = this.state.filteredUsersEmails.filter(user =>
			user.includes(this.state.searchString)
		);
		userEmails.forEach((email: string, index: number) => {
			if (index % 2) return; //each completed iteration prints two. Therefore skip

			let nextEmail: string | undefined = userEmails[index + 1];
			let nextEmailCheckBox: JSX.Element | undefined;
			if (nextEmail) nextEmailCheckBox = this.checkboxGenerator(nextEmail);
			let currentEmailCheckbox = this.checkboxGenerator(email);

			emailElements.push(
				<div className="flex-container" key={index + email + nextEmail}>
					{currentEmailCheckbox}
					{nextEmailCheckBox}
				</div>
			);
			emailElements.push(
				<div className="flex-container" key={index}>
					<hr className="flex-1" />
				</div>
			);
		});

		return <div className="flex-column">{emailElements}</div>;
	};

	saveDefinitionName = (e: React.FormEvent<HTMLInputElement>) => {
		this.props.setDefinitionName(e.currentTarget.value);
	};

	notificationDefinitionName = (): JSX.Element => {
		return (
			<div>
				<h6>
					<b>{localized('NotificationName')}</b>
				</h6>
				<BiInputText
					className="wider-inputfield"
					value={this.props.temporaryDefinition?.name || ''}
					onChange={this.saveDefinitionName}
				/>
			</div>
		);
	};

	emailScrollbarHeight: number | null = 0;

	// Hacked solution - Needed to calculate the height of the componenet which have to be defined with inline-styling in <ScrollPanel /> to work properly
	// Since this calculation is based on the height of other components which will not trigger a rerender of this, we need to subscribe in other ways - here by ref
	// Without this function there is a risk that this component will render first, and the height being calculated wrong.
	onScrollPanelRef = (ref: ScrollPanel | null) => {
		if (ref)
			this.emailScrollbarHeight = calculateNotificationEmailScrollbarHeight(
				document.getElementById(NotificationEmailListContainerId),
				document.getElementById(FilterBarId)
			);
	};

	render() {
		return (
			<div className="margin-left-3 margin-right-3 margin-vertical-20px">
				{this.notificationDefinitionName()}
				<br />

				{this.searchField()}
				<hr />

				<ScrollPanel
					style={{ height: `${this.emailScrollbarHeight}px` }}
					className={'flex-fill-width'}
					id={NotificationEmailListContainerId}
					ref={this.onScrollPanelRef}
				>
					{this.emailList()}
				</ScrollPanel>

				{this.addCustomEmail()}

				{this.actionButtons()}
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, EmailConfiguration, 'EmailConfiguration'));
