import { ComparerInput, ComparisonOperator, INotificationAdvancedSettingDto, NotificationMachineType } from 'api/api';
import { SelectItem } from 'primereact/components/selectitem/SelectItem';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { localized, localizedDynamic } from 'state/i18n';
import { useAppSelector } from 'utilities/hooks';
import { isAtLeastSuperAdminFromState } from 'utilities/roles-util';
import { toCamelCase } from 'utilities/string-utils';
import { OnChangeEvent } from 'view/components/bi-multiselect/bi-multiselect';
import BiTextArea from 'view/components/bi-text/bi-textarea';
import './advanced-notification-setting.scss';
import { machineTypeAdvancedSettingsMapper } from './advanced-notification-types-helper';
import {
	ComparisonOperators,
	comparisonOperatorsEqualOnly,
	comparisonOperatorsSmallerThanOnly,
} from './comparison-operators/comparison-operators';
import ComparisonValue from './comparison-value/comparison-value';

interface Props {
	id: string | number;
	onChange: (settings: INotificationAdvancedSettingDto, id: string | number) => void;
	value: INotificationAdvancedSettingDto;
	machineType: NotificationMachineType;
}

const getSelectItems = (machineType: NotificationMachineType, isSuperAdmin: boolean) => {
	return Object.entries(ComparerInput)
		.filter(
			v =>
				isNaN(Number(v[0])) &&
				machineTypeAdvancedSettingsMapper(isSuperAdmin)[machineType].includes(v[1] as ComparerInput)
		)
		.map(([key, value]) => {
			return {
				value: value,
				label: localizedDynamic(toCamelCase(key)),
			} as SelectItem;
		});
};

export const AdvancedNotificationSetting: React.FC<Props> = props => {
	const isSuperAdmin = useAppSelector(isAtLeastSuperAdminFromState);

	const { id, onChange, value, machineType } = props;

	const onInputChange = (e: OnChangeEvent) => {
		const values = { ...value };
		if (comparisonOperatorsEqualOnly.includes(e.value)) {
			values.comparisonOperator = ComparisonOperator.Equal;
		}
		if (comparisonOperatorsSmallerThanOnly.includes(e.value)) {
			values.comparisonOperator = ComparisonOperator.SmallerThan;
		}
		onChange(
			{
				...values,
				comparisonValue: undefined,
				comparerInput: e.value,
			},
			id
		);
	};

	const onComparisonOperatorChanged = (comparer: ComparisonOperator) => {
		onChange(
			{
				...value,
				comparisonOperator: comparer,
			},
			id
		);
	};

	const onValueChange = (comparisonValue: string) => {
		onChange(
			{
				...value,
				comparisonValue: comparisonValue,
			},
			id
		);
	};

	const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		onChange(
			{
				...value,
				notificationMessage: event.currentTarget.value,
			},
			id
		);
	};

	return (
		<div className="advanced-notification-setting-root">
			<h5 className="text-bold title">{localized('IF')}</h5>
			<Dropdown
				value={value?.comparerInput}
				options={getSelectItems(machineType, isSuperAdmin)}
				onChange={onInputChange}
				placeholder={localized('ChooseInput')}
			/>

			<ComparisonOperators
				id={id}
				value={value.comparisonOperator}
				onOperatorChanged={onComparisonOperatorChanged}
				selectedComparerInput={value?.comparerInput}
			/>

			<ComparisonValue value={value.comparisonValue} onChange={onValueChange} />

			<h5 className="text-bold title">{localized('THEN')}</h5>
			<div>
				<BiTextArea
					placeholder={localized('InsertCustomMessage')}
					onChange={onMessageChange}
					value={value?.notificationMessage}
				/>
			</div>
		</div>
	);
};
