import { DashboardBase } from 'api/api';
import { Button } from 'primereact/button';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { deleteDashboardContent } from 'state/ducks/dashboard/operations';
import { localized, localizedDynamic, localizedInterpolation } from 'state/i18n';
import { AppState } from 'state/store';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import './tile-menu.scss';


const showMenuclassname: string = "showMenu";


const mapStateToProps = (state: AppState) => {
	return {
		menuIdToShow: state.dashboardReducer.menuIdToShow
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	deleteTile: async (dashboardBase: DashboardBase) => (await deleteDashboardContent(dashboardBase))(dispatch),
});

type ComponentProps = {
	dashboardBase: DashboardBase,
	extraContent?: JSX.Element
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps;

type State = { showDeleteDialog: boolean }

class DefaultDashboardMenu extends React.PureComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = { showDeleteDialog: false }
	}


	public render() {
		const showMenu: boolean = this.props.menuIdToShow === this.props.dashboardBase.id || this.state.showDeleteDialog;
		return (
			<div className="dashboard-tile-menu">
				<BiTextDialog visible={this.state.showDeleteDialog} onHide={this.hideDialog} title={localized("Delete")} >
					<p>{localizedInterpolation("XDeleteDashboardItem", { X: localizedDynamic(`DashboardTile.${this.props.dashboardBase.type}`) })}</p>

					<div className="flex-end-row margin-top-10px">
						<BiButton
							onClick={this.hideDialog}
							colorTheme="org-primary-grey"
							containerClassName="margin-right-24px"
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Cancel')}
						</BiButton>
						<BiButton
							onClick={this.deleteDashboardItem}
							colorTheme={"org-red"}
							containerTheme="slim-with-rounded-corners"
						>
							{localized("Delete")}
						</BiButton>
					</div>
				</BiTextDialog>

				<div className={`position-absolute flex-container menu ${showMenu ? showMenuclassname : ""} ${this.props.extraContent ? "items" : "item"}`}>
					{this.props.extraContent}
					<div className="flex-1 flex-end-row">
						<Button
							title={localized('Delete')}
							className={"p-button-custom bi-button-icon-only"}
							icon="pi pi-trash"
							onClick={this.handleDelete}
						/>
					</div>
				</div>
			</div>
		);
	}

	private showDialog = () => {
		this.setState({ showDeleteDialog: true });
	}

	private hideDialog = () => {
		this.setState({ showDeleteDialog: false });
	}

	private deleteDashboardItem = () => {
		this.props.deleteTile(this.props.dashboardBase);
	}

	private handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.showDialog();
		e.stopPropagation();
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DefaultDashboardMenu);
