import React from 'react';
import { TutorialVimeoIds } from 'utilities/tutorial-constants';
import { Tutorial } from './tutorial';
import './tutorials.scss';

export const Tutorials: React.FC<{}> = () => {
	const items = TutorialVimeoIds.map((tutorial, i) => {
		let lineBreak: JSX.Element | undefined;
		if (TutorialVimeoIds.length > 1 && i < TutorialVimeoIds.length - 1) lineBreak = <hr />;

		return !tutorial.id ? null : (
			<div key={`tutorial:${tutorial.id}`}>
				<Tutorial id={tutorial.id} title={tutorial.title} />
				{lineBreak}
			</div>
		);
	});

	return <div className="tutorials no-select">{items}</div>;
};
