import { IUnitManufacturerDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('unitManufacturer');

export const getUnitManufacturersAction = actionCreator.async<undefined, IUnitManufacturerDto[]>(
	constants.GET_UNIT_MANUFACTURERS
);

export const createUnitManufacturersAction = actionCreator.async<IUnitManufacturerDto, IUnitManufacturerDto>(
	constants.CREATE_UNIT_MANUFACTURERS
);

export const updateUnitManufacturersAction = actionCreator.async<IUnitManufacturerDto, IUnitManufacturerDto>(
	constants.UPDATE_UNIT_MANUFACTURERS
);

export const deleteUnitManufacturersAction = actionCreator.async<IUnitManufacturerDto, void>(
	constants.DELETE_UNIT_MANUFACTURERS
);
