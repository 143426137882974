import { NotificationMachineType } from 'api/api';
import { PredefinedNotificationSetting, getPredefinedNotificationSettings } from './notification-settings-helper';
import React, { FC } from 'react';
import { NotificationTypeSelector, NotificationSettingNameValue } from './notification-type-selector';
import { useSelector } from 'react-redux';
import { AppState } from 'state/store';
import { localized } from 'state/i18n';

interface Props {
    machineType: NotificationMachineType;
    onChange: (setting: PredefinedNotificationSetting, machineType: NotificationMachineType) => void;
}

export const NotificationTypeSelectorWrapper: FC<Props> = React.memo((props: Props) => {
    let options: NotificationSettingNameValue[] = [];

    const allNotificationTypes = useSelector((state: AppState) => state.notificationsReducer.notificationTypes);
    const currentlySelectedNotificationTypes = useSelector(
        (state: AppState) => state.notificationsReducer.temporaryNotificationDefinition?.notificationTypes
    );

    // Checks for set equality between the currently selected notification types and the ones defined by the given 'PredefinedNotificationSetting'
    const isPredefinedSettingChecked = (
        machineType: NotificationMachineType,
        setting: PredefinedNotificationSetting
    ) => {
        const notificationTypesForSetting = getPredefinedNotificationSettings(
            setting,
            machineType,
            allNotificationTypes
        ).map(nt => nt.id);

        const currentTypesForMachineType = new Set(
            currentlySelectedNotificationTypes?.filter(tn => tn.machineType === machineType)?.map(nt => nt.id)
        );

        if (!currentTypesForMachineType?.size || notificationTypesForSetting.length !== currentTypesForMachineType.size)
            return false;

        return notificationTypesForSetting.every(foo => currentTypesForMachineType.has(foo));
    };


    switch (props.machineType) {
        case NotificationMachineType.Baler:
            options = [
                { name: localized("BalePickUp"), value: PredefinedNotificationSetting.Baler_BalePickUp },
                { name: localized("siteUser"), value: PredefinedNotificationSetting.Baler_SiteUser },
                { name: localized("Technician"), value: PredefinedNotificationSetting.Baler_Technician },
            ];
            break;
        case NotificationMachineType.Compactor:
            options = [
                { name: localized("CompactorPickUp"), value: PredefinedNotificationSetting.Compactor_CompactorPickUp },
                { name: localized("siteUser"), value: PredefinedNotificationSetting.Compactor_SiteUser },
                { name: localized("TechnicalInformation"), value: PredefinedNotificationSetting.Compactor_TechnicalInformation },
            ];
            break;
        case NotificationMachineType.Link:
            options = [
                { name: localized("WastehandlerSettings"), value: PredefinedNotificationSetting.Link_WastehandlerSettings },
                { name: localized("siteUser"), value: PredefinedNotificationSetting.Link_SiteUser },
                { name: localized("TechnicalInformation"), value: PredefinedNotificationSetting.Link_TechnicalInformation },
            ];
            break;
        case NotificationMachineType.SharkMK3:
            options = [
                { name: localized("WastehandlerSettings"), value: PredefinedNotificationSetting.Shark_WastehandlerSettings },
                { name: localized("siteUser"), value: PredefinedNotificationSetting.Shark_SiteUser },
                { name: localized("TechnicalInformation"), value: PredefinedNotificationSetting.Shark_TechnicalInformation },
            ];
            break;
    }

    return (
        <NotificationTypeSelector
            machineType={props.machineType}
            options={options}
            onChange={props.onChange}
            isChecked={isPredefinedSettingChecked}
        />
    );
});
