import { XtelUnitTrackerClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { xtelUnitTrackerClient } from '../api-clients';
import {
	CreateXtelTrackerAction,
	DeleteXtelTrackerAction,
	GetXtelTrackerAction,
	GetXtelTrackersAction,
	UpdateXtelTrackerIdAction,
} from './actions';

export function createXtelDeviceTracker(deviceId: number, trackerId: string, message: string) {
	return AsyncOperationBuilder(
		CreateXtelTrackerAction,
		apiClient => (apiClient as XtelUnitTrackerClient).createXtelUnitTracker(deviceId, trackerId, message),
		{ deviceId, trackerId },
		xtelUnitTrackerClient
	);
}

export function getXtelUnitTracker(unitId: string) {
	return AsyncOperationBuilder(
		GetXtelTrackerAction,
		apiClient => (apiClient as XtelUnitTrackerClient).getXtelUnitTracker(unitId),
		{ unitId },
		xtelUnitTrackerClient
	);
}

export function getXtelUnitTrackers() {
	return AsyncOperationBuilder(
		GetXtelTrackersAction,
		apiClient => (apiClient as XtelUnitTrackerClient).getXtelUnitTrackers(),
		undefined,
		xtelUnitTrackerClient
	);
}

export function updateXtelDeviceTrackerId(unitId: number, trackerId: string, message: string) {
	return AsyncOperationBuilder(
		UpdateXtelTrackerIdAction,
		apiClient => (apiClient as XtelUnitTrackerClient).updateXtelUnitTrackerId(unitId, trackerId, message),
		{ deviceId: unitId, trackerId },
		xtelUnitTrackerClient
	);
}

export function deleteXtelDeviceTracker(unitId: number) {
	return AsyncOperationBuilder(
		DeleteXtelTrackerAction,
		apiClient => (apiClient as XtelUnitTrackerClient).deleteXtelUnitTracker(unitId),
		{ unitId },
		xtelUnitTrackerClient
	);
}
