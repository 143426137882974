import { IUnitWeightDto } from "api/api";
import actionCreatorFactory from "typescript-fsa";
import { constants } from "./constants";

const actionCreator = actionCreatorFactory('unitWeight');

export const CreateUnitWeightAction = actionCreator.async<
	{ unitId: number; weighingSerialNumber: string },
	IUnitWeightDto
>(constants.CREATE_UNIT_WEIGHT);

export const GetUnitWeightAction = actionCreator.async<
	{ unitId: string },
	IUnitWeightDto
>(constants.GET_UNIT_WEIGHT);

export const UpdateUnitWeightAction = actionCreator.async<
	{ unitId: number; weighingSerialNumber: string },
	IUnitWeightDto
>(constants.UPDATE_UNIT_WEIGHT_ID);

export const DeleteUnitWeightAction = actionCreator.async<
	{ unitId: number },
	void
>(constants.DELETE_UNIT_WEIGHT);