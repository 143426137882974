import { IUnitsPerUnitModelsTileDto, IDashboardChartItem, IFilterDto, FilterDto } from 'api/api';
import { Chart } from 'primereact/chart';
import React from 'react';
import { localized } from 'state/i18n';
import { DashboardMaxNoOfUniqueItems, InsertChartItem } from '../dashboard-util';
import { DashboardTable } from './dashboard-table/dashboard-table';
import { Dispatch } from 'redux';
import { applyFilterAndSelection } from 'state/ducks/filter-search/operations';
import { connect } from 'react-redux';
import history from 'state/history';
import { Routes } from 'state/ducks/routes';

const mapDispatchToProps = (dispatch: Dispatch) => ({
	applyFilterAndSelection: async (filter: IFilterDto, selectedMachines?: number[] | undefined) =>
		await applyFilterAndSelection(filter as FilterDto, selectedMachines)(dispatch),
});

interface PropsFromParent {
	tile: IUnitsPerUnitModelsTileDto;
	clickable: boolean;
}

type Props = ReturnType<typeof mapDispatchToProps> & PropsFromParent;

const unitsPerModelsOptions: Chart.ChartOptions = {
	// Responsive: true and maintainAspectRatio: false are very important if you want to set custom sizes!
	responsive: true,
	maintainAspectRatio: false,
	legend: {
		display: false,
	},
	tooltips: {
		mode: 'index',
		intersect: true,
	},
	animation: {
		duration: 0,
	},
};

const chartItemOther: IDashboardChartItem = {
	chartName: localized('Other'),
	chartValue: 0,
};

export class UnitsPerUnitModelsTile extends React.PureComponent<Props> {
	navigateToUnitModel = (item: IDashboardChartItem, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
		if (!this.props.clickable) {
			e.preventDefault();
			return;
		}

		this.props.applyFilterAndSelection({ machineTypesSelected: [item.chartName!] });
		history.push(Routes.Machines);
	};

	public render() {
		if (!this.props.tile.unitsPerUnitModel) {
			return null;
		}

		const labels: string[] = [];
		const dataSets: number[] = [];
		const backgroundColor: string[] = [];

		for (let i = 0; i < this.props.tile.unitsPerUnitModel.length && i < DashboardMaxNoOfUniqueItems - 1; i++) {
			const chartItem = this.props.tile.unitsPerUnitModel[i];
			InsertChartItem(chartItem, labels, dataSets, backgroundColor, i);
		}

		if (this.props.tile.unitsPerUnitModel.length >= DashboardMaxNoOfUniqueItems) {
			chartItemOther.chartValue = this.props.tile.unitsPerUnitModel
				.slice(DashboardMaxNoOfUniqueItems - 1, this.props.tile.unitsPerUnitModel.length - 1)
				.reduce((a, b) => a + b.chartValue, 0);

			InsertChartItem(chartItemOther, labels, dataSets, backgroundColor, DashboardMaxNoOfUniqueItems - 1);
		}

		const data = {
			labels: labels,
			datasets: [
				{
					data: dataSets,
					backgroundColor: backgroundColor,
					hoverBackgroundColor: backgroundColor,
				},
			],
		};

		return (
			<>
				<div className="border-bottom-grey margin-bottom-20px" />
				<div className="dashboard-chart-pie">
					<Chart type="pie" className="margin-right-24px" data={data} options={unitsPerModelsOptions} />
					<div className="border-right-grey" />
					<DashboardTable
						backgroundColors={backgroundColor}
						chartItems={this.props.tile.unitsPerUnitModel}
						maxRows={5}
						onRowClick={this.navigateToUnitModel}
					/>
				</div>
			</>
		);
	}
}

export default connect(undefined, mapDispatchToProps)(UnitsPerUnitModelsTile);
