import { IGraphReportDto, ReportTileDto } from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { getTableColsAndRows } from 'state/ducks/reports/operations';
import { getDatasetLabel, getUnitOfDataType, getXLabel, getYLabel, processReportDataToGraph } from 'state/ducks/reports/reports-helpers';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { Spinner } from 'view/components/spinner/spinner';
import { ReportGraph } from '../../table/reports/report-graph';
import { GraphTableMenuEnum } from '../dashboard-item-menus/filter-graph-table-menu';
import { SimpleColumn, TileTable } from './tile-table';
import { NavLink } from 'react-router-dom';
import { Routes } from 'state/ducks/routes';
import { DashboardTileIdQueryParam } from 'utilities/constants';
import '../dashboard-tile.scss';

const mapStateToProps = (state: AppState) => {
	return {
		loading: state.reportReducer.reportTileLoading,
	};
};

interface PropsFromParent {
    tile: ReportTileDto;
    viewMode: GraphTableMenuEnum;
    clickable: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

// Used to sort out columns used on report page
const TotalColumnHeader = "Total";
const tableCols = {"unitId": 18, "unitType": 25, "siteUser": 42, "Total": 15};

class ReportTile extends React.PureComponent<Props> {
	getTable = (): JSX.Element => {
        if (!this.props.tile.reportData)
            return <Spinner></Spinner>

		const colsAndRows = getTableColsAndRows(
            this.props.tile.reportData, 
            this.props.tile.reportTileSettingsDto.dataType, 
            this.props.tile.reportTileSettingsDto.dateType, 
            this.props.tile.reportTileSettingsDto.startDate, 
            this.props.tile.reportTileSettingsDto.endDate
        );

        let filteredCols = colsAndRows.cols
            .filter(col => col.name in tableCols)
            .map(reportCol => {
                const col: SimpleColumn = {
                    field: reportCol.name,
                    header: reportCol.title || "",
                    widthPercentage: tableCols[reportCol.name]
                };
    
                return col;
            });
        
        
        // Round total to nearest integer
        const rows = colsAndRows.rows.map((row): Record<string, string> => {
            return {...row, "Total": parseFloat(row[TotalColumnHeader]).toFixed(0)}
        });


        if (filteredCols.length === 0)
			return <h5>{localized('NoData')}</h5>

        return (
            <TileTable columns={filteredCols} rows={rows} sortingKey={TotalColumnHeader}/>
        )
    }

    

	getGraph = (): JSX.Element => {
        if (this.props.tile.reportTileSettingsDto.dataType === undefined)
			return <h5>{localized('NoReportDataType')}</h5>

        

        return (
            <>
                <div className="border-bottom-grey" />
                <ReportGraph 
                    yAxisLabel={getYLabel(this.props.tile.reportTileSettingsDto.dataType)}
                    xAxisLabel={getXLabel(this.props.tile.reportTileSettingsDto.groupingDateType)}
                    datasetLabel={getDatasetLabel(this.props.tile.reportTileSettingsDto.dataType)}
                    graphDataset={this.getGraphDataset()}
                    aspectRatio={1.7}
                    animationDuration={0}
                    dataUnit={getUnitOfDataType(this.props.tile.reportTileSettingsDto.dataType)}
                />
            </>
        )
    }

	public render() {
		return (
            <>
                <div className="content-section implementation">
    			    <NavLink onClick={this.handleClick} className="link-style-unset" to={`${Routes.Reports}?${DashboardTileIdQueryParam}=${this.props.tile.dashboardBase?.id}`}>
                        {this.props.loading 
                            ? <Spinner
                                shouldOverlay={false}
                                shouldUseAbsolutePositioning={false}
                                useMarginsForCorrectingCentering={false}
                            /> 
                            : this.props.viewMode === GraphTableMenuEnum.graph 
                                ? this.getGraph() 
                                : this.getTable()
                        }
                    </NavLink>

                </div>
            </>
		);
    }

    private getGraphDataset = () => {
        const graphReport: IGraphReportDto = {
			dates: [],
			values: [],
        };
     
		processReportDataToGraph(
			this.props.tile.reportTileSettingsDto.groupingDateType,
			this.props.tile.reportData ?? [],
			this.props.tile.reportTileSettingsDto.startDate,
			this.props.tile.reportTileSettingsDto.endDate,
			this.props.tile.reportTileSettingsDto.dataType,
			graphReport
        );

        return graphReport;
    }

    private handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (!this.props.clickable)
			event.preventDefault();
	}
}

export default connect(mapStateToProps)(ReportTile);
