import { IUnitsDto } from 'api/api';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from 'state/ducks/routes';
import './machine-info.scss';

interface AppProps {
	machine: IUnitsDto;
	value: string;
	setAsSelectedMachine: (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		currMachineClicked?: IUnitsDto
	) => void;
}

type Props = AppProps;

class BiTableGpsLocation extends React.PureComponent<Props> {
	public gpsClicked = (event: any) => {
		this.props.setAsSelectedMachine(event, this.props.machine);
	};

	public render() {
		return (
			<NavLink to={Routes.Location} onClick={this.gpsClicked} className="biTableGpsLocation-container">
				{this.props.value}
			</NavLink>
		);
	}
}

export default BiTableGpsLocation;
