import { ClipLoader } from 'react-spinners';
import * as React from 'react';
import './spinner.scss';

type SpinnerSizes = 'spinner-container-default-size' | 'spinner-container-half-size' | 'spinner-container-1-4th-size';

interface AppProps {
	containerClassName?: string;
	shouldOverlay?: boolean;
	/**
	 * If not set to true, then it will default to using flex-positioning.
	 */
	shouldUseAbsolutePositioning?: boolean;
	// Workaround to not being able to figure out how to set max-width = max-height = 100
	// while still maintaining aspect ratio.
	spinnerSize?: SpinnerSizes;
	/**
	 * When set to true, negative margins will be applied to the Spinner container.
	 * Default is 'true'. Set it to false if used in a container with padding instead of margin.
	 */
	useMarginsForCorrectingCentering?: boolean;
}

type Props = AppProps;

export class Spinner extends React.PureComponent<Props> {
	public render() {
		let spinnerContainerClass =
			this.props.shouldOverlay !== false
				? 'spinner-container-fixed '
				: this.props.shouldUseAbsolutePositioning
				? 'spinner-container-absolute '
				: 'spinner-container-flex ';

		spinnerContainerClass += this.props.spinnerSize || ' spinner-container-default-size';
		spinnerContainerClass +=
			this.props.useMarginsForCorrectingCentering !== false ? ' margin-corrections-for-centering' : '';

		return (
			<div className={`${spinnerContainerClass} ${this.props.containerClassName || ''}`}>
				<ClipLoader />
			</div>
		);
	}
}
