import { IUnitWithLocationDto } from 'api/api';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMachineLocations } from 'state/ducks/location/operations';
import { AppState } from 'state/store';
import { useQueryParam } from 'use-query-params';
import { GPSHistoryIdQueryParam, GPSHistoryLocationQueryParam } from 'utilities/constants';
import DynamicMap from './dynamic-map';

export const GpsHistoryLocation: FC = React.memo(() => {
	const [gpsHistoryId] = useQueryParam<number | undefined>(GPSHistoryIdQueryParam);
	const [gpsHistoryLocation] = useQueryParam<string | undefined>(GPSHistoryLocationQueryParam);
	const [machine, setMachine] = useState<IUnitWithLocationDto[]>();
	const dispatch = useDispatch();
	const gpsHistory = useSelector((state: AppState) => state.locationReducer.machinesWithLocation);

	useEffect(() => {
		const coordinatesArray = gpsHistoryLocation?.split(',');

		if (gpsHistory && coordinatesArray && coordinatesArray.length > 1) {
			gpsHistory[0].latitude = coordinatesArray[0];
			gpsHistory[0].longitude = coordinatesArray[1];

			setMachine(gpsHistory);
		}
	}, [gpsHistoryLocation, gpsHistory]);

	useEffect(() => {
		const idArray: number[] = [gpsHistoryId!];

		if (idArray && idArray.length) {
			getMachineLocations(idArray)(dispatch);
		}
	}, [gpsHistoryId, dispatch]);

	return <DynamicMap machinesWithLocation={machine} />;
});
