import { Growl, GrowlMessage } from 'primereact/growl';
import React from 'react';
import { reactAI } from './telemetry-service';

const closableDefault = true;
const stickyDefault = false;
const infoDurationInMsDefault = 3000;
const warningDurationInMsDefault = 5000;
const errorDurationInMsDefault = 10 * 1000;

class NotificationService {
	private growl!: Growl | null;

	public initialize(growl: Growl | null) {
		this.growl = growl;
	}

	public clearGrowlMessages = () => {
		this.growl && this.growl.clear();
	};

	public showSuccessMessage = (
		msgHeader?: React.ReactNode,
		fullMsg?: React.ReactNode,
		durationInMs: number = infoDurationInMsDefault
	) => {
		this.addGrowlMessageToQueue('success', msgHeader, fullMsg, closableDefault, stickyDefault, durationInMs);
	};

	public showInfoMessage = (
		msgHeader?: React.ReactNode,
		fullMsg?: React.ReactNode,
		sticky?: boolean,
		durationInMs: number = infoDurationInMsDefault

	) => {
		this.addGrowlMessageToQueue('info', msgHeader, fullMsg, closableDefault, sticky, durationInMs);
		console.info(msgHeader, fullMsg);
	};

	public showWarningMessage = (
		msgHeader?: React.ReactNode,
		fullMsg?: React.ReactNode,
		durationInMs: number = warningDurationInMsDefault
	) => {
		this.addGrowlMessageToQueue('warn', msgHeader, fullMsg, closableDefault, stickyDefault, durationInMs);
		console.warn(msgHeader, fullMsg);
	};

	public showErrorMessage = (
		msgHeader?: React.ReactNode,
		fullMsg?: React.ReactNode,
		durationInMs: number = errorDurationInMsDefault
	) => {
		this.addGrowlMessageToQueue('error', msgHeader, fullMsg, closableDefault, stickyDefault, durationInMs);

		let errorMessage = '';

		if (typeof msgHeader === 'string' || typeof msgHeader === 'number') {
			errorMessage += msgHeader;
		}
		if (typeof fullMsg === 'string' || typeof fullMsg === 'number') {
			errorMessage += msgHeader;
		}

		console.error(msgHeader, fullMsg, errorMessage);

		errorMessage && reactAI.appInsights && reactAI.appInsights.trackException({ error: new Error(errorMessage) })
	};

	public showErrorMessageWithError = (
		msgHeader?: string,
		fullMsg?: string,
		error?: any
	) => {
		this.showErrorMessage(msgHeader, fullMsg);

		console.error(msgHeader, fullMsg, error);

		reactAI.appInsights && reactAI.appInsights.trackException({ error: error })
	};

	private showGrowlMessage = (growlMsg: GrowlMessage | GrowlMessage[]) => {
		this.growl && this.growl.show(growlMsg);
	};

	private addGrowlMessagesToQueue = (growlMsgs: GrowlMessage[]) => {
		this.growl && setTimeout(this.showGrowlMessage, 0, growlMsgs);
	};

	private addGrowlMessageToQueue(
		severity: 'success' | 'info' | 'warn' | 'error',
		summary?: React.ReactNode,
		detail?: React.ReactNode,
		closable?: boolean,
		sticky?: boolean,
		life?: number
	) {
		const growlMsg: GrowlMessage = {
			severity,
			summary,
			detail,
			closable,
			sticky,
			life
		};

		// setTimeout is to make sure that growl.show, specifically its "setState(...)", is executed
		// one at a time by queuing it as a new event.
		this.growl && setTimeout(this.showGrowlMessage, 0, growlMsg);
	}
}

/**
 * NB. Don't call its methods in ``render()``! Currently, the service uses Growl.
 * which has its own state. It calls setState(...).
 */
export const notificationService = new NotificationService();
