import { ErrorIdBaler, ErrorIdCompactor, ErrorIdLink, INotificationTypeDto, NotificationMachineType } from 'api/api';
import {
	BalesAlmostFinishedNotificationTypeName,
	BalesFinishedNotificationTypeName,
	BalesReadyForPickupNotificationTypeName,
	CustomMessageNotificationName,
	FillLevelNotificationName,
} from 'utilities/constants';
import { ErrorId } from 'utilities/machine-error-util';

export enum PredefinedNotificationSetting {
	Baler_BalePickUp,
	Baler_SiteUser,
	Baler_Technician,
	Compactor_CompactorPickUp,
	Compactor_SiteUser,
	Compactor_TechnicalInformation,
	Link_WastehandlerSettings,
	Link_SiteUser,
	Link_TechnicalInformation,
	Shark_WastehandlerSettings,
	Shark_SiteUser,
	Shark_TechnicalInformation,
}

export const getPredefinedNotificationSettings = (
	settings: PredefinedNotificationSetting,
	machineType: NotificationMachineType,
	allTypes: INotificationTypeDto[]
): INotificationTypeDto[] => {
	let notificationTypes: INotificationTypeDto[] = [];

	const errorTypesForGivenSettings = settingToErrorIdsMap.get(settings);

	if (errorTypesForGivenSettings === undefined) {
		return notificationTypes;
	}

	// Find notification types that matches on errorId
	notificationTypes = allTypes.filter(
		nt =>
			nt.machineType === machineType &&
			nt.errorId !== undefined &&
			errorTypesForGivenSettings.includes(nt.errorId)
	);

	// Add notification types that matches on name (I.e FillLevel and Custom Messages)
	notificationTypes = notificationTypes.concat(
		allTypes.filter(
			nt =>
				nt.machineType === machineType && nt.name !== undefined && errorTypesForGivenSettings.includes(nt.name)
		)
	);

	return notificationTypes;
};

// Baler settings
const BalerBalePickUpTypes: (ErrorIdBaler | string)[] = [BalesReadyForPickupNotificationTypeName];

const BalerSiteUserTypes: (ErrorIdBaler | string)[] = [
	ErrorIdBaler.Safety,
	ErrorIdBaler.Service,
	BalesFinishedNotificationTypeName,
	BalesAlmostFinishedNotificationTypeName,
	BalesReadyForPickupNotificationTypeName,
];

const BalerTechnicianTypes: ErrorIdBaler[] = [
	ErrorIdBaler.ButtonHanging,
	ErrorIdBaler.K1K2FeedbackFail,
	ErrorIdBaler.MotorThermo,
	ErrorIdBaler.OilTemperature,
	ErrorIdBaler.TemperatureSensor,
	ErrorIdBaler.OtherSafetyRelatedError,
	ErrorIdBaler.PressureSensor,
	ErrorIdBaler.CycleTiming,
	ErrorIdBaler.TopSwitchFail,
	ErrorIdBaler.ADMotionFailure,
	ErrorIdBaler.CycleStrokeError,
	ErrorIdBaler.Service,
	ErrorIdBaler.EncoderFailure,
	ErrorIdBaler.ExternalInputFailure,
	ErrorIdBaler.OutputFbError,
	ErrorIdBaler.Leakage,
];

// Compactor settings
const CompactorCompactorPickUpTypes: (ErrorIdCompactor | string)[] = [
	ErrorIdCompactor.STATUS_FSMP_MACHINE_IS_OVERFULL,
	FillLevelNotificationName,
];

const CompactorSiteUserTypes: (ErrorIdCompactor | string)[] = [
	ErrorIdCompactor.STATUS_FSMP_CONTAINER_NOT_PRESENT,
	ErrorIdCompactor.STATUS_FSMP_FRONT_BLOCK_ERROR,
	ErrorIdCompactor.STATUS_FSMP_MACHINE_IS_OVERFULL,
	ErrorIdCompactor.STATUS_SERVICE_IS_DUE,
	FillLevelNotificationName,
];
const CompactorTechnicalInformationTypes: ErrorIdCompactor[] = [
	ErrorIdCompactor.STATUS_FSMP_OIL_LEVEL_ERROR,
	ErrorIdCompactor.STATUS_FSMP_FEEDBACK_FAILURE,
	ErrorIdCompactor.STATUS_FSMP_PARAMS_UNSAFE,
	ErrorIdCompactor.STATUS_FSMP_MOTOR_THERMO_NOT_OK,
	ErrorIdCompactor.STATUS_FSMP_OIL_TEMPERATURE_ERROR,
	ErrorIdCompactor.STATUS_FSMP_OIL_LEAKAGE_DETECTED,
	ErrorIdCompactor.STATUS_OSSD_TEST_FAILED,
	ErrorIdCompactor.STATUS_CPU2_FLASH_CRC_VALIDATION_FAILED,
	ErrorIdCompactor.STATUS_POWER_RAIL_FAILURE,
	ErrorIdCompactor.STATUS_POWER_OFF_SIGNAL_FAILURE,
	ErrorIdCompactor.STATUS_CONTACTOR_FAILURE,
	ErrorIdCompactor.STATUS_TRAVEL_TIMES_ERROR,
	ErrorIdCompactor.STATUS_OIL_TRANSDUCER_ERROR,
	ErrorIdCompactor.STATUS_TOO_FULL_TO_BALE_OUT,
	ErrorIdCompactor.STATUS_PHASE_DETECT_OPERATION_BLOCKED,
	ErrorIdCompactor.STATUS_MAX_VALVE_CURRENT_ERROR,
	ErrorIdCompactor.STATUS_SHARK_LOADING_BIN_ERROR,
	ErrorIdCompactor.STATUS_SHARK_PRESSING_RAM_ERROR,
	ErrorIdCompactor.BACK_BLOCK_DETECT_ERROR,
	ErrorIdCompactor.INPUT_TIMING_ERROR,
	ErrorIdCompactor.MTS_MH_MEASUREMENT_AGE_ERROR,
	ErrorIdCompactor.AUGER_ERROR_START_INPUT_HANGING,
	ErrorIdCompactor.AUGER_ERROR_OVERLOAD,
	ErrorIdCompactor.AUGER_ERROR_NO_LOAD,
	ErrorIdCompactor.PRESTO_AUGER_LUBRICATION_ERROR,
	ErrorIdCompactor.PRESTO_AUGER_FEEDER_MOTOR_PROTECT,
	ErrorIdCompactor.PRESTO_AUGER_FEEDER_ERROR_OVERLOAD,
	ErrorIdCompactor.AUGER_FEEDER_ERROR_NO_LOAD,
	ErrorIdCompactor.AUTOSTART_REACHED_MAX_CONTINUOUS_TIME,
	ErrorIdCompactor.DO_NOT_RUN_FOREVER_TIMER_EXPIRED,
	ErrorIdCompactor.MOTOR_ERROR,
	ErrorIdCompactor.PRESTO_AUGER_LUBRICATION_WARNING,
];

// LINK settings
const LinkWastehandlerSettingsTypes: (ErrorIdLink | string)[] = [
	ErrorIdLink.MS_MACHINE_IS_OVERFULL,
	FillLevelNotificationName,
];

const LinkSiteUserTypes: (ErrorIdLink | string)[] = [
	ErrorIdLink.MS_CONTAINER_NOT_PRESENT,
	ErrorIdLink.MS_MACHINE_IS_OVERFULL,
	ErrorIdLink.MS_SERVICE_IS_DUE,
	ErrorIdLink.MS_WIRE_RUN_OUT_INPUT_ACTIVE,
	FillLevelNotificationName,
	CustomMessageNotificationName,
];

const LinkTechnicalInformation: (ErrorIdLink | string)[] = [
	ErrorIdLink.MS_WIRE_RUN_OUT_INPUT_ACTIVE,
	ErrorIdLink.MS_SERVICE_IS_DUE,
	ErrorIdLink.MS_OIL_LEVEL_AND_TEMP_ERROR,
	ErrorIdLink.MS_OIL_LEVEL_LOW,
	ErrorIdLink.MS_OIL_TEMPERATURE_HIGH_ERROR,
	ErrorIdLink.MS_MOTOR_PROTECTION_ERROR,
	ErrorIdLink.MS_MOTOR_THERMO_ERROR,
	ErrorIdLink.MS_MACHINE_FAULT_INPUT,
	CustomMessageNotificationName,
];

// Shark settings
const SharkWastehandlerSettingsTypes = [FillLevelNotificationName];

const SharkSiteUserTypes: (ErrorIdCompactor | string)[] = [
	ErrorIdCompactor.STATUS_SERVICE_IS_DUE,
	FillLevelNotificationName,
];

const SharkTechnicalInformation: (ErrorIdCompactor | string)[] = [
	// Shark related notification errors:
	ErrorIdCompactor.AUTO_CLOSE_TIME_LIMIT,
	ErrorIdCompactor.END_POS_SW_ACTIVE_AT_TURNING_POINT,
	ErrorIdCompactor.LEAD_CYL_DID_NOT_TURN_BEFORE_END_SW,
	ErrorIdCompactor.LEAD_CYL_TURN_SIGNAL_SETUP_ERROR,
	ErrorIdCompactor.LEAD_CYL_TURNING_POINT_SW_ERROR,
	ErrorIdCompactor.BIN_ALMOST_CLOSED_SWITCH_HANGING,
	ErrorIdCompactor.BIN_ALMOST_CLOSED_SIGNAL_MISSING,
	ErrorIdCompactor.BIN_CLOSED_SWITCH_HANGING,
	ErrorIdCompactor.MAX_PRESSURE_REACHED_PRESS,
	ErrorIdCompactor.MAX_PRESSURE_REACHED_RETURN,
	ErrorIdCompactor.TAIL_CYL_DID_NOT_TURN_BEFORE_END_SW,
	ErrorIdCompactor.TAIL_CYL_TURNING_POINT_SW_ERROR,
	ErrorIdCompactor.TAIL_CYL_TURN_SIGNAL_SETUP_ERROR,
	ErrorIdCompactor.UNEXPECTED_BIN_ALMOST_CLOSED_SIGNAL,
	ErrorIdCompactor.UNEXPECTED_BIN_CLOSED_SIGNAL,
	ErrorIdCompactor.UNLOCK_TIME_LIMIT,
	ErrorIdCompactor.WRONG_CYLINDER_TURNED_FIRST,

	// Standard Compactor notification errors:
	ErrorIdCompactor.STATUS_CONTACTOR_FAILURE,
	ErrorIdCompactor.STATUS_FSMP_OIL_LEAKAGE_DETECTED,
	ErrorIdCompactor.STATUS_POWER_RAIL_FAILURE,
	ErrorIdCompactor.INPUT_TIMING_ERROR,
	ErrorIdCompactor.STATUS_TRAVEL_TIMES_ERROR,
	ErrorIdCompactor.STATUS_MAX_VALVE_CURRENT_ERROR,
	ErrorIdCompactor.STATUS_OSSD_TEST_FAILED,
	ErrorIdCompactor.STATUS_FSMP_OIL_TEMPERATURE_ERROR,
	ErrorIdCompactor.STATUS_FSMP_MOTOR_THERMO_NOT_OK,
	ErrorIdCompactor.STATUS_FSMP_OIL_LEVEL_ERROR,
	ErrorIdCompactor.STATUS_OIL_TRANSDUCER_ERROR,
	ErrorIdCompactor.STATUS_SERVICE_IS_DUE
];

// Mapping from enum to list of error ids
const settingToErrorIdsMap = new Map<PredefinedNotificationSetting, (ErrorId | string)[]>([
	[PredefinedNotificationSetting.Baler_BalePickUp, BalerBalePickUpTypes],
	[PredefinedNotificationSetting.Baler_SiteUser, BalerSiteUserTypes],
	[PredefinedNotificationSetting.Baler_Technician, BalerTechnicianTypes],
	[PredefinedNotificationSetting.Compactor_CompactorPickUp, CompactorCompactorPickUpTypes],
	[PredefinedNotificationSetting.Compactor_SiteUser, CompactorSiteUserTypes],
	[PredefinedNotificationSetting.Compactor_TechnicalInformation, CompactorTechnicalInformationTypes],
	[PredefinedNotificationSetting.Link_WastehandlerSettings, LinkWastehandlerSettingsTypes],
	[PredefinedNotificationSetting.Link_SiteUser, LinkSiteUserTypes],
	[PredefinedNotificationSetting.Link_TechnicalInformation, LinkTechnicalInformation],
	[PredefinedNotificationSetting.Shark_WastehandlerSettings, SharkWastehandlerSettingsTypes],
	[PredefinedNotificationSetting.Shark_SiteUser, SharkSiteUserTypes],
	[PredefinedNotificationSetting.Shark_TechnicalInformation, SharkTechnicalInformation],
]);
