import { TableSettings } from 'api/api';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { allColumns, PressureGraphTableSettingsState } from './types';

export const initialState: PressureGraphTableSettingsState = {
	columnOrder: [
		"timestamp",
		"showChosenGraph"
	],
	columnWidths: [
		{ columnName: "timestamp", width: 155 },
		{ columnName: 'showChosenGraph', width: 90 },
	],
	sorting: [
		{
			columnName: 'timestamp',
			direction: 'desc',
		},
	],
	allColumns: allColumns,
	tableSettings: new TableSettings(),
	loaded: false,
};

export function pressureGraphTableSettingsReducer(
	state: typeof initialState = initialState, action: Action
): typeof initialState {
	if (isType(action, actions.setColumnOrderAction)) {
		return {
			...state,
			columnOrder: action.payload,
		};
	}
	if (isType(action, actions.setColumnSizeAction)) {
		return {
			...state,
			columnWidths: action.payload,
		};
	}
	if (isType(action, actions.setColumnSortingAction)) {
		return {
			...state,
			sorting: action.payload,
		};
	}

	return state;
}
