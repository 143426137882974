import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import {
	removeValueFromArray,
	upsertArrayOfDataMappedToIdString,
	upsertDataMappedToIdString,
	upsertDataMappedToIdInArray,
} from 'utilities/reducer-helpers';
import * as actions from './actions';
import { UnitHistoryState } from './types';
import { UpdateMachinesOwnerGroupIdAction } from '../machines/actions';
import { updateMachineOwnerGroupIdsInDataTupleHistory } from 'utilities/reducer-helpers-update-machine-ownergroup';

export const initialState: UnitHistoryState = {
	machineHistories: [],
	unitHistoryInfos: [],
	pressureChartState: {
		selectedUnitCycleTimestamp: undefined,
		data: undefined,
		isLoading: false
	},
	unitCycles: {
		unitCycles: undefined,
		isLoading: false
	},
};

export function unitHistoryReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.GetUnitHistoryFilteredAction.done)) {
		if (action.payload.result?.length) {
			const serialNumber = action.payload.result[0].unitSerialNumber;

			const idDataTupleArray = upsertArrayOfDataMappedToIdString(
				serialNumber!,
				action.payload.result,
				state.machineHistories
			);

			return {
				...state,
				machineHistories: idDataTupleArray,
			};
		}
	} else if (isType(action, actions.GetUnitHistoryInfoAction.done)) {
		if (action.payload.result) {
			const idDataTupleArray = upsertDataMappedToIdString(
				action.payload.result.unitId!,
				action.payload.result,
				state.unitHistoryInfos
			);

			return {
				...state,
				unitHistoryInfos: idDataTupleArray,
			};
		}
	} else if (isType(action, UpdateMachinesOwnerGroupIdAction.done)) {
		return {
			...state,
			unitHistoryInfos: updateMachineOwnerGroupIdsInDataTupleHistory(
				state.unitHistoryInfos,
				action.payload.params.MachineIds,
				action.payload.params.destinationGroupId
			),
		};
	} else if (isType(action, actions.ClearUnitHistoryAction)) {
		return {
			...state,
			machineHistories: state.machineHistories && removeValueFromArray(state.machineHistories, action.payload),
		};
	} else if (isType(action, actions.UpdateUnitHistoryAction.done)) {
		return {
			...state,
			machineHistories: upsertDataMappedToIdInArray(
				action.payload.params.serialNumber,
				action.payload.result,
				state.machineHistories
			),
		};
	} 
	else if (isType(action, actions.GetPressureChartAction.started)) {
		return {
			...state,
			pressureChartState: {
				...state.pressureChartState,
				selectedUnitCycleTimestamp: action.payload.startDate,
				isLoading: true
			},
		};
	} 
	else if (isType(action, actions.GetPressureChartAction.failed)) {
		return {
			...state,
			pressureChartState: {
				...state.pressureChartState,
				isLoading: false
			},
		};
	} 
	else if (isType(action, actions.GetPressureChartAction.done)) {
		return {
			...state,
			pressureChartState: {
				...state.pressureChartState,
				data: action.payload.result,
				isLoading: false
			},
		};
	} else if (isType(action, actions.GetUnitCyclesAction.done)) {
		return {
			...state,
			pressureChartState: {
				...state.pressureChartState,
				isLoading: false
			},
			unitCycles: {
				...state.unitCycles,
                unitCycles: action.payload.result,
				isLoading: false
			},
		};
	} else if (isType(action, actions.GetUnitCyclesAction.started)) {
		return {
			...state,
			pressureChartState: {
				...state.pressureChartState,
				isLoading: true
			},
			unitCycles: {
				...state.unitCycles,
				isLoading: true
			},
		};
	}
	else if (isType(action, actions.GetUnitCyclesAction.failed)) {
		return {
			...state,
			pressureChartState: {
				...state.pressureChartState,
				isLoading: false
			},
			unitCycles: {
				...state.unitCycles,
				isLoading: false
			},
		};
	}
	else if (isType(action, actions.ResetUnitCyclesAction)) {
		return {
			...state,
			pressureChartState: {
				selectedUnitCycleTimestamp: undefined,
				data: undefined,
				isLoading: false
			},
			unitCycles: {
				unitCycles: undefined,
				isLoading: false
			},
		};
	}
	return state;
}
