import { IUnitManufacturerDto, UnitManufacturerClient, UnitManufacturerDto } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { unitManufacturerClient } from '../api-clients';
import {
	createUnitManufacturersAction,
	deleteUnitManufacturersAction,
	getUnitManufacturersAction,
	updateUnitManufacturersAction,
} from './actions';

export function getUnitManufacturers() {
	return AsyncOperationBuilder(
		getUnitManufacturersAction,
		apiClient => (apiClient as UnitManufacturerClient).getUnitManufacturers(),
		undefined,
		unitManufacturerClient
	);
}

export function createUnitManufacturer(model: IUnitManufacturerDto) {
	return AsyncOperationBuilder(
		createUnitManufacturersAction,
		apiClient => (apiClient as UnitManufacturerClient).createUnitManufacturer(UnitManufacturerDto.fromJS(model)),
		model,
		unitManufacturerClient
	);
}

export function updateUnitManufacturer(model: IUnitManufacturerDto) {
	return AsyncOperationBuilder(
		updateUnitManufacturersAction,
		apiClient => (apiClient as UnitManufacturerClient).updateUnitManufacturer(UnitManufacturerDto.fromJS(model)),
		model,
		unitManufacturerClient
	);
}

export function deleteUnitManufacturer(model: IUnitManufacturerDto) {
	return AsyncOperationBuilder(
		deleteUnitManufacturersAction,
		apiClient => (apiClient as UnitManufacturerClient).deleteUnitManufacturer(UnitManufacturerDto.fromJS(model)),
		model,
		unitManufacturerClient
	);
}
