import { ITableSettings, IUnitCycleDto } from "api/api";
import { localized } from "state/i18n";
import { ColumnGeneric } from "../history-table-settings/types";
import { SortingGeneric, TableColumnWidthInfoGeneric } from "../table-settings/types";

export type UnitCycleDtoDtoKeys = keyof IUnitCycleDto | 'showChosenGraph';

// These are the columns that will be rendered.
export const allColumns: Array<ColumnGeneric<UnitCycleDtoDtoKeys>> = [
    { name: "timestamp", title: localized('Timestamp') },
    { name: "showChosenGraph", title: localized('Select') },
];


export interface PressureGraphTableSettingsState {
	columnWidths: Array<TableColumnWidthInfoGeneric<UnitCycleDtoDtoKeys>>;
	columnOrder: UnitCycleDtoDtoKeys[];
	sorting: Array<SortingGeneric<UnitCycleDtoDtoKeys>>;
	allColumns: Array<ColumnGeneric<UnitCycleDtoDtoKeys>>;
	tableSettings: ITableSettings;
	loaded: boolean;
}