import React from 'react';
import { IRecordedPickUpsTileDto } from 'api/api';
import { localized } from 'state/i18n';
import { OneNumberTileTemplate } from './templates/one-number-tile-template';

interface PropsFromParent {
    tile: IRecordedPickUpsTileDto;
}

type Props = PropsFromParent;

export class RecordedPickUpsTile extends React.PureComponent<Props> {
    public render() {
        const totalPickups = this.props.tile.recordedPickUps?.amount ?? 0;
        
        return (
			<OneNumberTileTemplate
                number={totalPickups}
                subtitle={localized("PickupsForCompactorsSubtitle")}
            />
		);
    }
}

/*
    This is the version of recorded-pick-ups that has buttons for choosing time span
*/

// const mapStateToProps = (state: AppState) => {
// 	return {
// 		pickUpsDateType: state.dashboardReducer.pickUpsDateType
// 	};
// };

// const mapDispatchToProps = (dispatch: Dispatch) => ({
//     setPickupsDateType: (dateType: DateTypes) => setPickupsDateType(dateType)(dispatch),
// });

// const options = [
//     {label: `7 ${localized('days')}`, dateType: DateTypes.Week},
//     {label: `30 ${localized('days')}`, dateType: DateTypes.Month},
//     {label: `365 ${localized('days')}`, dateType: DateTypes.Year},
//     {label: localized('All'), dateType: DateTypes.All}
// ]
    
// export class RecordedPickUpsTile extends React.PureComponent<Props> {

//     public render() {
//         const chosenPickups = this.props.tile.recordedPickUps?.find(pickups => pickups.dateType === this.props.pickUpsDateType);

//         return (
//             <div>
//                 <div className="border-bottom-grey" />
//                 <BiSelectButtons 
//                     availableOptions={options} 
//                     currentOption={this.props.pickUpsDateType} 
//                     onChange={this.onChange}
//                     className={"margin-top-10px"}/>
                    
//                 <TwoNuberTileTemplate
//                     firstNumberheadline={localizedDynamic('UnitTypes.Baler')}
//                     secondNumberHeadline={localizedDynamic('UnitTypes.Compactor')}
//                     firstNumberValue={chosenPickups?.balers || 0}
//                     secondNumberValue={chosenPickups?.compactors || 0}
//                     showLine={false}
//                 />
//             </div>
//         );
//     }

//     private onChange = (dateType: DateTypes, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//         e.preventDefault();
//         e.stopPropagation();

//         this.props.setPickupsDateType(dateType);
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(RecordedPickUpsTile);
