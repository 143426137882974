import { DialogProps } from 'primereact/dialog';
import React from 'react';
import { notificationService } from 'services/notification-service';
import { getServiceToolCode } from 'state/ducks/service-tool-code/operations';
import { localized } from 'state/i18n';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import { ServiceToolCode } from './service-tool-code';

type Props = DialogProps & {
	machineId: string;
};

interface State {
	serviceToolCode: string;
}

export class DialogServiceCodeExistingUnit extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			serviceToolCode: '',
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.visible === false && this.props.visible === true) {
			this.getServiceToolCode();
		}
	}

	public render() {
		return (
			<div>
				<BiTextDialog {...this.props} title={localized('ServiceToolCode')}>
					<ServiceToolCode serviceToolCode={this.state.serviceToolCode}></ServiceToolCode>
				</BiTextDialog>
			</div>
		);
	}

	private getServiceToolCode = async () => {
		const serviceCode = await getServiceToolCode(this.props.machineId);
		if (serviceCode) {
			this.setState({ serviceToolCode: serviceCode });
		}
	};
}
