import positions from 'positions';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { ReactNode } from 'react';
import { findDOMNode } from 'react-dom';
import './bi-tooltip.scss';

const placementsMap = {
	tc: 'top center',
	bc: 'bottom center',
	cl: 'center right',
	cr: 'center left',
};

interface Props {
	inTable?: boolean;
	overlay?: string | JSX.Element;
	children: React.ReactElement;
	placement?: string;
	noNesting?: boolean;
	backgroundColor?: 'white' | 'black';
}

const uglyCorrectionForCenterAlign = 6;

const backgroundBlackStyle: React.CSSProperties = { backgroundColor: 'black', color: 'white' };
const backgroundWhiteStyle: React.CSSProperties = { backgroundColor: 'white', color: 'black' };

/**
 * Show a (top-positioned) tooltip for an element. Tested on <div>-elements
 * Usage: Use `overlay` to set a message and wrap your elements in this component.
 */
export default class BiTooltip extends React.PureComponent<Props> {
	// Source: https://github.com/react-component/tooltip/issues/54#issuecomment-248006676
	// Related PR which was updated May 17 2019! Link: https://github.com/react-component/tooltip/pull/166
	public handleAlignPopup = (tooltipEl: any, align: any) => {
		const arrowEl = tooltipEl.querySelector('.rc-tooltip-arrow');
		if (!arrowEl) {
			return;
		}

		const targetEl = findDOMNode(this);
		const position = positions(arrowEl, placementsMap[align.points[0]], targetEl, placementsMap[align.points[1]]);

		if (align.points[0] === 'tc' || align.points[0] === 'bc') {
			arrowEl.style.top = '';
			arrowEl.style.left = `${position.left + uglyCorrectionForCenterAlign}px`;
		} else {
			arrowEl.style.top = `${position.top}px`;
			arrowEl.style.left = '';
		}
	};

	public render() {
		// NOTE: If the root child has a width larger than its content, the tooltip arrow
		// won't center. `display: inline-block` (wrapped in a `<div>`) fixes that.
		const tooltip = (
			<Tooltip
				overlayInnerStyle={this.props.backgroundColor === 'white' ? backgroundWhiteStyle : backgroundBlackStyle}
				placement={this.props.placement || 'top'}
				overlayClassName="rc-tooltip-custom"
				overlay={this.props.overlay || ''}
				mouseLeaveDelay={0}
				{...this.props}
				onPopupAlign={this.handleAlignPopup}
			>
				{this.props.children}
			</Tooltip>
		);

		return this.props.overlay ? (
			this.props.noNesting ? (
				tooltip
			) : (
				<div className={this.props.inTable ? 'display-inline-grid' : 'display-inline-block'}>{tooltip}</div>
			)
		) : (
			this.props.children
		);
	}
}
