import React from 'react';
import { InputText, InputTextProps } from 'primereact/inputtext';
import './bi-input-text.scss';

export interface Props extends InputTextProps {
	icon?: string;
}

class BiInputText extends React.PureComponent<Props> {
	public render() {
		let icon: JSX.Element | undefined = this.props.icon ? (
			<span className={`bi-input-text-icon ${this.props.icon} absolute-icon`}></span>
		) : undefined;
		return (
			<div className="flex-center-column">
				<div className="input-text-wrapper">
					<InputText
						className={`bi-inputtext-no-margin ${this.props.className}`}
						id={this.props.id}
						placeholder={this.props.placeholder}
						value={this.props.value}
						onChange={this.props.onChange}
						onBlur={this.props.onBlur}
					/>
					{icon}
				</div>
			</div>
		);
	}
}

export default BiInputText;
