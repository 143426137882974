import { IFilterDto, ICriticalErrorsTileDto, FilterDto } from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { applyFilterAndSelection } from 'state/ducks/filter-search/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { TwoNuberTileTemplate } from './templates/two-nuber-tile-template';

const mapStateToProps = (state: AppState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	applyFilterAndSelection: async (filter: IFilterDto, selectedMachines?: number[] | undefined) =>
		await applyFilterAndSelection(filter as FilterDto, selectedMachines)(dispatch),
});

interface PropsFromParent {
	tile: ICriticalErrorsTileDto;
	clickable: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class CriticalErrorsTile extends React.PureComponent<Props> {
	getWarningsAndErrors = (): { Warnings: number; Errors: number } => {
		const initialErrorsWarnings = { Errors: 0, Warnings: 0 };

		if (!this.props.tile.criticalErrorList) return initialErrorsWarnings;

		return this.props.tile.criticalErrorList.reduce((acc, curr) => {
			return {
				Errors: acc.Errors + Number(curr.hasErrors),
				Warnings: acc.Warnings + Number(curr.hasWarnings),
			};
		}, initialErrorsWarnings);
	};

	navigateToErrors = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (!this.props.clickable) {
			event.preventDefault();
			return;
		}

		this.props.applyFilterAndSelection({ statusSelected: ['Error'] });
	};

	navigateToWarnings = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.props.applyFilterAndSelection({ statusSelected: ['Warning'] });
	};

	public render() {
		let warningsAndErrors = this.getWarningsAndErrors();
		return (
			<TwoNuberTileTemplate
				firstNumberheadline={localized('Errors')}
				secondNumberHeadline={localized('Warnings')}
				firstNumberValue={warningsAndErrors.Errors}
				secondNumberValue={warningsAndErrors.Warnings}
				firstNumberColor="color-error-red"
				firstNumberOnClick={this.navigateToErrors}
				secondNumberOnClick={this.navigateToWarnings}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CriticalErrorsTile);
