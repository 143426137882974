import { IBaleStorageDto, IPotentialLinkingMachineDto, IUnitDetailsDto } from 'api/api';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpsertBaleStorage } from 'state/ducks/machine-details/operations';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { images } from 'utilities/images';
import { isAtLeastAdminFromState } from 'utilities/roles-util';
import { IdDataTuple } from 'utilities/types-util';
import BiPickList from 'view/shared/components/bi-picklist/bi-pick-list';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { ConfirmDialog } from '../../../../../shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import { baleStorageBuilder, hasValidBaleStorage } from './bale-storage-helper';

type Props = {
	currMachine: IdDataTuple<IUnitDetailsDto, string>;
};

export const BaleLinker: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();

	const isAtLeastAdmin = useSelector((state: AppState) =>
		isAtLeastAdminFromState(state, props.currMachine.data.ownerGroupId)
	);

	const machines = props.currMachine.data.potentialLinkingMachines?.sort(
		(a: IPotentialLinkingMachineDto, b: IPotentialLinkingMachineDto) => {
			if (!a.serialNumber || !b.serialNumber) return 0;
			if (a.serialNumber < b.serialNumber) return -1;
			if (a.serialNumber > b.serialNumber) return 1;
			return 0;
		}
	);

	const [isDialogViewable, setDialogViewablility] = useState(false);

	const [targetArray, setTargetArray] = useState<IPotentialLinkingMachineDto[]>([]);
	const [sourceArray, setSourceArray] = useState<IPotentialLinkingMachineDto[]>([]);

	useEffect(() => {
		let selectedMachines: IPotentialLinkingMachineDto[] =
			machines?.filter(machine => props.currMachine.data.baleStorageDevices?.includes(machine.id)) ?? [];

		selectedMachines = addSelfOnListIfNotPresent(selectedMachines);

		setTargetArray(selectedMachines);
		setSourceArray(machines?.filter(machine => !selectedMachines.includes(machine)) ?? []);
		// eslint-disable-next-line
	}, [machines]);

	const onConfirm = async () => {
		const baleStorage: IBaleStorageDto = baleStorageBuilder(props.currMachine.data, {
			unitIds: targetArray.map(m => m.id),
		});

		await UpsertBaleStorage(baleStorage, !props.currMachine.data.baleStorageId)(dispatch);
		hideDialog();
	};

	const hideDialog = () => {
		setDialogViewablility(false);
	};

	const showDialog = () => {
		setDialogViewablility(true);
	};

	const addSelfOnListIfNotPresent = (target: IPotentialLinkingMachineDto[]): IPotentialLinkingMachineDto[] => {
		const thisMachine = machines?.find(m => m.id === props.currMachine.data.id);
		if (!target.some(m => m === thisMachine) && thisMachine !== undefined) {
			target.push(thisMachine);
		}

		return target;
	};

	const onChange = (e: { event: Event; source: any; target: any }) => {
		let target: IPotentialLinkingMachineDto[] = e.target;

		target = addSelfOnListIfNotPresent(target);
		const source: IPotentialLinkingMachineDto[] = machines?.filter(s => !target.includes(s)) ?? [];

		setTargetArray(target);
		setSourceArray(source);
	};

	const itemTemplate = (input: any) => {
		const item = input as IPotentialLinkingMachineDto;
		return (
			<div>
				<span className="text-bold">{item.serialNumber || ''}</span>
				<span>{` - ${item.modelName}`}</span>
				<span>{item.wasteType ? ` - ${localizedDynamic(item.wasteType)} ` : ' '}</span>
			</div>
		);
	};

	return (
		<>
			<BiButton
				onClick={showDialog}
				colorTheme="org-white"
				containerTheme="icon-sized-with-soft-rounded-corners"
				disabled={!hasValidBaleStorage(props.currMachine)}
			>
				<span>{localized('LinkedUnits')}</span>
				<img src={images.link} className="btn-icon" alt={'Bale Link'} />
			</BiButton>
			<ConfirmDialog
				onHide={hideDialog}
				visible={isDialogViewable}
				confirmColor={'org-primary'}
				header={localized('LinkedUnits')}
				dialogMessage={[localized('LinkedUnitsDescription1'), localized('LinkedUnitsDescription2')]}
				onConfirm={onConfirm}
				onCancel={hideDialog}
				className="width-fit-content"
				disabled={!isAtLeastAdmin}
			>
				<BiPickList
					sourceHeader={localized('Units')}
					targetHeader={localized('LinkedUnits')}
					source={sourceArray}
					target={targetArray}
					itemTemplate={itemTemplate}
					onChange={onChange}
				/>
			</ConfirmDialog>
		</>
	);
});
