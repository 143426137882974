import React from 'react';
import './machine-info.scss';
import history from 'state/history';
import { Routes } from 'state/ducks/routes';
import { localized } from 'state/i18n';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import { getIcon } from 'view/shared/utils/icon-helper';

interface AppProps {
	id: string;
}

type Props = AppProps;

class MachineInfo extends React.PureComponent<Props> {
	public handleMachineDetails = () => {
		history.push(`${Routes.MachineDetails}/${encodeURIComponent(this.props.id)}`);
	};

	public handleUnitHistory = () => {
		history.push(`${Routes.UnitHistory}/${encodeURIComponent(this.props.id)}`);
	};

	public render() {
		return (
			<div className="flex-direction-row ">
				<BiTooltip overlay={localized('Tooltip_InfoDetails')}>
					<div className="margin-left-1 cursor-pointer" onClick={this.handleMachineDetails}>
						{getIcon('info-circle')}
					</div>
				</BiTooltip>
				<BiTooltip overlay={localized('Tooltip_InfoHistory')}>
					<div className="margin-left-1 margin-right-1 cursor-pointer" onClick={this.handleUnitHistory}>
						{getIcon('history')}
					</div>
				</BiTooltip>
			</div>
		);
	}
}

export default MachineInfo;
