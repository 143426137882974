import React from 'react';
import BiReportTable from './bi-report-table';
import './report-table.scss';
import { AppState } from 'state/store';
import { connect } from 'react-redux';
import {
	FilterBarId,
	ReportsTableContainerId,
	NavBarHeightPx,
	UnitsSelectedRowHeightPx,
	ReportsFilterContainerId,
} from 'utilities/constants';

const mapStateToProps = (state: AppState) => {
	return {
		reportColumns: state.reportReducer.columns,
		reportRows: state.reportReducer.tableDataset,
	};
};

interface AppProps {}

type Props = ReturnType<typeof mapStateToProps> & AppProps;

class ReportTable extends React.PureComponent<Props> {
	private observer: MutationObserver | undefined;

	public componentDidMount() {
		this.setHeights();
		this.setHeightsEvent();
	}

	public componentWillUnmount() {
		this.removeHeightsEvent();
	}

	public render() {
		return (
			<div id={ReportsTableContainerId}>
				<BiReportTable
					columns={this.props.reportColumns || []}
					data={this.props.reportRows}
					dataIdProp="unitId"
				/>
			</div>
		);
	}

	private setHeights = () => {
		// This sets the height of the table is because we don't know the height of the filterbar
		let filterBarElement = document.getElementById(FilterBarId);
		let reportsTableContainer = document.getElementById(ReportsTableContainerId);
		let reportsFilterContainer = document.getElementById(ReportsFilterContainerId);

		if (reportsTableContainer && filterBarElement && reportsFilterContainer) {
			let height =
				filterBarElement.offsetHeight +
				reportsFilterContainer.offsetHeight +
				NavBarHeightPx +
				UnitsSelectedRowHeightPx;

			reportsTableContainer.setAttribute('style', `height: calc(100vh - ${height}px)`);
		}
	};

	private setHeightsEvent = () => {
		window.addEventListener('resize', this.setHeights);
	};

	private removeHeightsEvent = () => {
		window.removeEventListener('resize', this.setHeights);
	};
}

export default connect(mapStateToProps, {})(ReportTable);
