import { Marker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { IUnitWithLocationDto } from 'api/api';
import React from 'react';

export interface Props {
	position: { lat: number; lng: number };
	icon: google.maps.Icon; //{ url: string; scaledSize: { height: number; width: number } };
	title: number | string;
	machineLocation: IUnitWithLocationDto;
	clusterer: Clusterer;
	onClick: (m: IUnitWithLocationDto) => void;
}

class BiMarker extends React.PureComponent<Props> {
	public render() {
		// Source: https://developers.google.com/maps/documentation/javascript/reference#MarkerLabel
		const markerLabel = {
			fontSize: '0',
			text: this.props.title + '',
		};

		return (
			<Marker
				position={this.props.position}
				icon={this.props.icon}
				label={markerLabel}
				onClick={this.setActivelySelectedMachine}
				clusterer={this.props.clusterer}
			/>
		);
	}

	private setActivelySelectedMachine = () => {
		this.props.onClick(this.props.machineLocation);
	};
}

export default BiMarker;
