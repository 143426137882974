import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
  'SET_COLUMN_ORDER',
  'SET_COLUMN_SIZES',
  'SET_COLUMN_SORTING',
  'SET_COLUMNS',
  'GET_TABLE_SETTINGS',
  'SAVE_TABLE_SETTINGS',
  'SET_SELECTED_MACHINES',
  'SET_USER_SELECTED_MACHINES',
  'SET_MACHINE_SELECTED',
  'SET_ONE_MACHINE_SELECTED',
  'SET_MACHINE_UNSELECTED',
  'DELETE_TABLE_SETTING',
  'ADD_MACHINE_TABLE_SETTING',
  'SET_CURRENT_TABLE_SETTING_ID',
  'SET_NAME',
  "SET_COLUMNS_LOCKED",
  "SET_DEFAULT_TABLE_SETTINGS"
);
