import { localizedDynamic } from "state/i18n";

export type LabelValuePair<T> = {
    label: string;
    value: T;
}

export const toLabelValuePair = <T extends {}>(input: T, labelProp: keyof T, valueProp: keyof T, localizeLabel: boolean = false): LabelValuePair<any> => {
    let label: string = input[labelProp] as string;
    if (localizeLabel) label = localizedDynamic(label);
    return { label, value: input[valueProp] };
}