import { Calendar, CalendarProps, LocaleSettings } from 'primereact/calendar';
import React from 'react';
import { getLanguage, getLocaleData, localized } from 'state/i18n';
import { momentLocaleData } from '../date-to-string';

export interface Props {
	value?: Date;
	onChange?: (e?: any) => void;
	minDate?: Date;
	maxDate?: Date;
	className?: string;
	readOnlyInput?: boolean;
	showIcon?: boolean;
}

export interface State {
	currLng: string;
	locale: LocaleSettings;
	calendarProps: CalendarProps;
}

class BiCalendar extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			currLng: getLanguage(),
			calendarProps: this.getCalendarProps(),
			locale: this.getLocaleDataForCalendar(),
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		let currentLanguage = getLanguage();

		if (prevState.currLng !== currentLanguage) {
			this.setState({
				currLng: currentLanguage,
				calendarProps: this.getCalendarProps(),
				locale: this.getLocaleDataForCalendar(),
			});
		}
	}

	public render() {
		return (
			<Calendar
				{...this.state.calendarProps}
				locale={this.state.locale}
				value={this.props.value}
				onChange={this.props.onChange}
				minDate={this.props.minDate}
				maxDate={this.props.maxDate}
				className={this.props.className}
				readOnlyInput={this.props.readOnlyInput}
				showIcon={this.props.showIcon}
				monthNavigator={true}
				yearNavigator={true}
				yearRange='2000:2030' //Bramidan needs warranty to start at year 2000
			/>
		);
	}

	private getLocaleDataForCalendar(): LocaleSettings {
		const localeData = getLocaleData();

		return {
			firstDayOfWeek: getLocaleData().firstDayOfWeek(),
			clear: localized('Clear'),
			today: localized('Today'),
			dayNames: localeData.weekdays(),
			dayNamesShort: localeData.weekdaysShort(),
			dayNamesMin: localeData.weekdaysMin(),
			monthNames: localeData.months(),
			monthNamesShort: localeData.monthsShort(),
		};
	}

	private getCalendarProps = (): CalendarProps => {
		// PrimeReact and Moment format doesn't match - so we need to modify it: https://www.primefaces.org/primereact/#/calendar
		// MM/DD/YYYY -> mm/dd/yy -> 01/30/2020
		let dateFormat = momentLocaleData
			.longDateFormat('L')
			.toLowerCase()
			.slice(0, 8);

		return {
			dateFormat,
		};
	};
}

export default BiCalendar;
