import * as React from "react";
import { signOut } from 'utilities/auth-helper';
import { Spinner } from 'view/components/spinner/spinner';
import { localized } from 'state/i18n';
import BiTitle from 'view/shared/components/bi-title/bi-title';

export default class LogoutRedirectPage extends React.PureComponent<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};

    this.signOut();
  }

  public render() {
    return (
      <BiTitle title={localized("Title_RedirectToSignOut")}>
        <Spinner />
      </BiTitle>
    );
  }

  private signOut() {
    signOut();
  }
}
