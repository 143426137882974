import { withAITracking } from '@microsoft/applicationinsights-react-js';
import {
	INotificationAdvancedSettingDto,
	INotificationFillLevelSettingsDto,
	INotificationTypeDto,
	NotificationMachineType,
	StatusSeverity as NotificationSeverity,
	NotificationTypeDto,
} from 'api/api';
import { Button } from 'primereact/button';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import {
	addTemporaryNotificationTypes,
	removeTemporaryNotificationTypes,
	setTemporaryNotificationAdvancedSettingsByMachineType,
	setTemporaryNotificationFillLevelByMachineType,
} from 'state/ducks/notifications/operations';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { AdvancedNotificationName, FillLevelNotificationName } from 'utilities/constants';
import { CustomXtelMessageNotificationTypeName } from 'utilities/notification-constants';
import { localizeNotificationTypeName } from 'utilities/notification-util';
import { isAtLeastSuperAdminFromState } from 'utilities/roles-util';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import { BiTabPanel, BiTabView } from 'view/shared/components/bi-tabview/bi-tabview';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import { getIcon } from 'view/shared/utils/icon-helper';
import { getDefaultFillLevelSettings, machineIdsToDistinctMachineTypeSelectedList } from '../notification-helper';
import AdvancedNotification from './advanced-notification/advanced-notification';
import {
	PredefinedNotificationSetting,
	getPredefinedNotificationSettings,
} from './default-selection/notification-settings-helper';
import { NotificationTypeSelectorWrapper } from './default-selection/notification-type-selector-wrapper';
import FillLevelDialog from './fill-level/fill-level-dialog';
import './notification-selector.scss';

export const NotSelectorContainerId = 'specific-not-selector';
const NotTabviewId = 'not-tab-view';

const mapStateToProps = (state: AppState) => {
	const hasXtelTracker = state.xtelTrackerReducer.xtelTrackers.some(tracker =>
		state.tableSettingsReducer.selectedMachines.includes(tracker.unitId)
	);

	const machineTypeSelectedlist = machineIdsToDistinctMachineTypeSelectedList(
		state.tableSettingsReducer.selectedMachines
	);

	const notificationMachineTypes = Array.from(new Set(machineTypeSelectedlist.map(item => item.machineType)));

	const isSuperAdmin = isAtLeastSuperAdminFromState(state);

	return {
		baleTypes: state.notificationsReducer.notificationTypes?.filter(
			type => type.machineType === NotificationMachineType.Baler
		),
		compactorTypes: state.notificationsReducer.notificationTypes?.filter(
			type => type.machineType === NotificationMachineType.Compactor
		),
		linkTypes: state.notificationsReducer.notificationTypes?.filter(
			type => type.machineType === NotificationMachineType.Link
		),
		notificationMachineTypes,
		notificationTypes: state.notificationsReducer.notificationTypes,
		sharkMK3Types: state.notificationsReducer.notificationTypes?.filter(
			type => type.machineType === NotificationMachineType.SharkMK3
		),
		containerTypes: state.notificationsReducer.notificationTypes?.filter(
			type => type.machineType === NotificationMachineType.Container
		),
		temporaryNotificationDefinition: state.notificationsReducer.temporaryNotificationDefinition,
		hasXtelTracker,
		isSuperAdmin,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	addTemporaryNotificationtypes: (notType: INotificationTypeDto[]) =>
		addTemporaryNotificationTypes(notType)(dispatch),
	removeTemporaryNotificationtypes: (notType: INotificationTypeDto[]) =>
		removeTemporaryNotificationTypes(notType)(dispatch),
	setTemporaryNotificationFillLevelByMachineType: (id: number, settings: INotificationFillLevelSettingsDto[]) =>
		setTemporaryNotificationFillLevelByMachineType(id, settings)(dispatch),
	setTemporaryNotificationAdvancedSettingsByMachineType: (id: number, settings: INotificationAdvancedSettingDto[]) =>
		setTemporaryNotificationAdvancedSettingsByMachineType(id, settings)(dispatch),
});
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State {
	activeTab: number;
	balerAll: boolean;
	balerNone: boolean;
	compactorAll: boolean;
	compactorNone: boolean;
	linkAll: boolean;
	linkNone: boolean;
	sharkMK3All: boolean;
	sharkMK3None: boolean;
	containerAll: boolean;
	containerNone: boolean;
	fillLevel: { notificationType?: INotificationTypeDto };
}

class NotificationSelector extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			activeTab: 0,
			balerAll: false,
			balerNone: false,
			compactorAll: false,
			compactorNone: false,
			linkAll: false,
			linkNone: false,
			sharkMK3All: false,
			sharkMK3None: false,
			containerAll: false,
			containerNone: false,
			fillLevel: { notificationType: undefined },
		};
	}

	componentDidMount() {
		this.updateState();
	}

	componentDidUpdate(preProps: Props) {
		this.updateState(preProps);
	}

	checkIfAll = (machineType: NotificationMachineType): boolean => {
		let tempTypes:
			| NotificationTypeDto[]
			| undefined = this.props.temporaryNotificationDefinition?.notificationTypes?.filter(
			type => type.machineType === machineType
		);
		if (
			tempTypes &&
			tempTypes.length === this.props.notificationTypes.filter(type => type.machineType === machineType).length
		)
			return true;
		return false;
	};

	checkIfNone = (machineType: NotificationMachineType): boolean => {
		let tempTypes:
			| NotificationTypeDto[]
			| undefined = this.props.temporaryNotificationDefinition?.notificationTypes?.filter(
			type => type.machineType === machineType
		);
		if (!tempTypes || tempTypes.length === 0) return true;
		return false;
	};

	updateState = (preProps?: Props) => {
		if (!preProps || preProps.baleTypes !== this.props.baleTypes) {
			this.setState({
				balerAll: this.checkIfAll(NotificationMachineType.Baler),
				balerNone: this.checkIfNone(NotificationMachineType.Baler),
			});
		}
		if (!preProps || preProps.compactorTypes !== this.props.compactorTypes) {
			this.setState({
				compactorAll: this.checkIfAll(NotificationMachineType.Compactor),
				compactorNone: this.checkIfNone(NotificationMachineType.Compactor),
			});
		}

		if (!preProps || preProps.linkTypes !== this.props.linkTypes) {
			this.setState({
				linkAll: this.checkIfAll(NotificationMachineType.Link),
				linkNone: this.checkIfNone(NotificationMachineType.Link),
			});
		}

		if (!preProps || preProps.sharkMK3Types !== this.props.sharkMK3Types) {
			this.setState({
				sharkMK3All: this.checkIfAll(NotificationMachineType.SharkMK3),
				sharkMK3None: this.checkIfNone(NotificationMachineType.SharkMK3),
			});
		}

		if (!preProps || preProps.containerTypes !== this.props.containerTypes) {
			this.setState({
				containerAll: this.checkIfAll(NotificationMachineType.Container),
				containerNone: this.checkIfNone(NotificationMachineType.Container),
			});
		}
	};

	changeTabView = (e: { index: number }) => {
		this.setState({ activeTab: e.index });
	};

	setPredefinedSettings = (settings: PredefinedNotificationSetting, machineType: NotificationMachineType) => {
		// Clear current selection
		this.checkNone({ value: machineType });

		const notificationTypes = getPredefinedNotificationSettings(
			settings,
			machineType,
			this.props.notificationTypes
		);

		// Handle FillLevel notification types if this is set
		const fillLevelNotification = notificationTypes.find(nt => nt.name === FillLevelNotificationName);
		if (
			fillLevelNotification &&
			!this.props.temporaryNotificationDefinition?.notificationTypes?.find(x => x.id !== fillLevelNotification.id)
		) {
			this.addDefaultFillLevelSettings(fillLevelNotification);
		}

		this.props.addTemporaryNotificationtypes(notificationTypes);
	};

	addDefaultFillLevelSettings = (fillLevelNotificationType: INotificationTypeDto) => {
		const defaultFillSettings = getDefaultFillLevelSettings(fillLevelNotificationType);
		this.saveFillLevel(defaultFillSettings, fillLevelNotificationType.id);
	};

	checkAll = (e: { value: NotificationMachineType }) => {
		let notifications = this.props.notificationTypes.filter(
			not =>
				not.machineType === e.value &&
				!this.props.temporaryNotificationDefinition?.notificationTypes?.map(tn => tn.id).includes(not.id)
		);
		this.props.addTemporaryNotificationtypes(notifications);
	};

	checkNone = (e: { value: NotificationMachineType }) => {
		let notifications = this.props.temporaryNotificationDefinition?.notificationTypes?.filter(
			tn => tn.machineType === e.value
		);
		if (!notifications) return;
		this.props.removeTemporaryNotificationtypes(notifications);
	};

	toggleCheckSingle = (e: { value: INotificationTypeDto; checked: boolean }) => {
		if (e.checked) {
			if (e.value.name === FillLevelNotificationName) {
				this.addDefaultFillLevelSettings(e.value);
			} else {
				this.props.addTemporaryNotificationtypes([e.value]);
			}
		} else {
			this.props.removeTemporaryNotificationtypes([e.value]);
		}
	};

	isChecked = (notType: INotificationTypeDto): boolean => {
		if (this.props.temporaryNotificationDefinition?.notificationTypes) {
			let result: boolean = this.props.temporaryNotificationDefinition.notificationTypes
				.map(type => type.id)
				.includes(notType.id);
			return result;
		}
		return false;
	};

	saveAdvancedNotificationSettings = (settings: INotificationAdvancedSettingDto[], notificationTypeId: number) => {
		this.props.setTemporaryNotificationAdvancedSettingsByMachineType(notificationTypeId, settings);
		let notType: INotificationTypeDto | undefined = this.props.notificationTypes.find(
			not => not.id === settings[0]?.notificationTypeId
		);
		if (
			notType &&
			!this.props.temporaryNotificationDefinition?.notificationTypes?.some(
				not => not.errorId === notType?.errorId && not.id === notType?.id
			)
		)
			this.props.addTemporaryNotificationtypes([notType]);
	};

	saveFillLevel = (settings: INotificationFillLevelSettingsDto[], notificationTypeId: number) => {
		this.props.setTemporaryNotificationFillLevelByMachineType(notificationTypeId, settings);
		let notType: INotificationTypeDto | undefined = this.props.notificationTypes.find(
			not => not.id === settings[0].notificationTypeId
		);
		if (
			notType &&
			!this.props.temporaryNotificationDefinition?.notificationTypes?.some(
				not => not.errorId === notType?.errorId && not.id === notType?.id
			)
		)
			this.props.addTemporaryNotificationtypes([notType]);

		this.hideFillLevelDialog();
	};

	hideFillLevelDialog = () => {
		this.setState({ fillLevel: { notificationType: undefined } });
	};

	handleFillLevel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		let notType: INotificationTypeDto | undefined = this.props.notificationTypes.find(
			type => type.id === parseInt(event.currentTarget.value)
		);
		this.setState({ fillLevel: { notificationType: notType } });
	};

	getTypeSpecificComponent = (
		machineType: NotificationMachineType,
		notTypeDto: INotificationTypeDto
	): JSX.Element | undefined => {
		switch (notTypeDto.name) {
			case 'FillLevel':
				return (
					<>
						{this.getDefaultCheckbox(notTypeDto)}
						<Button
							title={localized('fillLevel')}
							className={'p-button-custom bi-button-icon-only'}
							icon="pi pi-plus-circle"
							value={notTypeDto.id}
							onClick={this.handleFillLevel}
						/>
					</>
				);

			case AdvancedNotificationName:
				return (
					<AdvancedNotification
						machineType={machineType}
						isChecked={this.isChecked(notTypeDto)}
						onSave={this.saveAdvancedNotificationSettings}
						notificationType={notTypeDto}
					/>
				);
		}

		return undefined;
	};

	getDefaultCheckbox = (notTypeDto: INotificationTypeDto): JSX.Element => {
		return (
			<BiCheckbox
				checked={this.isChecked(notTypeDto)}
				value={notTypeDto}
				onChange={this.toggleCheckSingle}
				label={localizeNotificationTypeName(notTypeDto)}
				key={localizeNotificationTypeName(notTypeDto)}
				inputId={localizeNotificationTypeName(notTypeDto)}
			/>
		);
	};

	generateCheckbox = (machineType: NotificationMachineType, notTypeDto: INotificationTypeDto): JSX.Element => {
		let typeSpecificComponent: JSX.Element | undefined = this.getTypeSpecificComponent(machineType, notTypeDto);

		return (
			<div className="flex-center-column" key={`notbox ${notTypeDto.id}`}>
				{!typeSpecificComponent && this.getDefaultCheckbox(notTypeDto)}
				{typeSpecificComponent}
			</div>
		);
	};

	notificationTypeColumnGenerator = (
		checkboxList: JSX.Element[],
		nameOfColumn: string,
		icon: JSX.Element
	): JSX.Element | undefined => {
		if (checkboxList.length === 0) return undefined;
		return (
			<div className="flex-direction-column" key={`notificationTypeColumn${nameOfColumn}`}>
				<div className="flex-center-column margin-bottom-10px">
					<BiTooltip>{icon}</BiTooltip>
					<b className="margin-left-1">{nameOfColumn}</b>
				</div>
				{checkboxList}
			</div>
		);
	};

	sortErrorUnit = (a?: string, b?: string) => {
		return (localizedDynamic(a!) || 0) > (localizedDynamic(b!) || 0) ? 1 : -1;
	};

	checkboxList = (machineType: NotificationMachineType, list?: INotificationTypeDto[]) => {
		if (!list) return;
		let errors: INotificationTypeDto[] = list
			?.filter(type => type.severity === NotificationSeverity.Error)
			.sort((a, b) => this.sortErrorUnit(localizeNotificationTypeName(a), localizeNotificationTypeName(b)));
		let warnings: INotificationTypeDto[] = list
			?.filter(type => type.severity === NotificationSeverity.Warning)
			.sort((a, b) => this.sortErrorUnit(localizeNotificationTypeName(a), localizeNotificationTypeName(b)));
		let messages: INotificationTypeDto[] = list
			?.filter(type => type.severity === NotificationSeverity.Message)
			.sort((a, b) => this.sortErrorUnit(localizeNotificationTypeName(a), localizeNotificationTypeName(b)));

		let errorList: JSX.Element[] = errors.map(element => this.generateCheckbox(machineType, element));
		let warningList: JSX.Element[] = warnings.map(element => this.generateCheckbox(machineType, element));
		let messageList: JSX.Element[] = messages.reduce((res: typeof messageList, msg) => {
			if (msg.name !== CustomXtelMessageNotificationTypeName || this.props.hasXtelTracker) {
				res.push(this.generateCheckbox(machineType, msg));
			}

			return res;
		}, []);

		let notificationLists: (JSX.Element | undefined)[] = [
			this.notificationTypeColumnGenerator(errorList, localized('Errors'), getIcon('cross-error')),
			this.notificationTypeColumnGenerator(warningList, localized('Warnings'), getIcon('triangle-warning')),
			this.notificationTypeColumnGenerator(messageList, localized('Messages'), getIcon('envelope-message')),
		];

		return (
			<div className="flex-space-between-row">
				{notificationLists}
				<span />
			</div>
		);
	};

	commonInTabs = (all: boolean, none: boolean, machineType: NotificationMachineType) => {
		return (
			<div className="margin-top-30px">
				<div className="flex-space-between-row">
					<h6 className="margin-bottom-0">
						<b>{localized('Notifications')}</b>
					</h6>
					<div className="flex-container">
						<div className="margin-right-2">
							<BiCheckbox
								inputId={`checkboxall ${machineType}`}
								checked={all}
								value={machineType}
								onChange={this.checkAll}
								label={localized('All')}
							/>
						</div>
						<div className="margin-right-5">
							<BiCheckbox
								inputId={`checkboxnone ${machineType}`}
								checked={none}
								value={machineType}
								onChange={this.checkNone}
								label={localized('None')}
							/>
						</div>
					</div>
				</div>
				<hr />
			</div>
		);
	};

	tabView = (): JSX.Element => {
		const unknownMachineTypeAndSuperAdmin: boolean =
			this.props.isSuperAdmin && this.props.notificationMachineTypes.includes(undefined);

		const tabPanels: JSX.Element[] = [];

		if (
			unknownMachineTypeAndSuperAdmin ||
			this.props.notificationMachineTypes.includes(NotificationMachineType.Baler)
		)
			tabPanels.push(
				<BiTabPanel header={localizedDynamic('UnitTypes.Baler')} key="BalerTab">
					<NotificationTypeSelectorWrapper
						machineType={NotificationMachineType.Baler}
						onChange={this.setPredefinedSettings}
					/>
					{this.commonInTabs(this.state.balerAll, this.state.balerNone, NotificationMachineType.Baler)}
					{this.checkboxList(NotificationMachineType.Baler, this.props.baleTypes)}
				</BiTabPanel>
			);

		if (
			unknownMachineTypeAndSuperAdmin ||
			this.props.notificationMachineTypes.includes(NotificationMachineType.Compactor)
		)
			tabPanels.push(
				<BiTabPanel header={localizedDynamic('UnitTypes.Compactor')} key="CompactorTab">
					<NotificationTypeSelectorWrapper
						machineType={NotificationMachineType.Compactor}
						onChange={this.setPredefinedSettings}
					/>
					{this.commonInTabs(
						this.state.compactorAll,
						this.state.compactorNone,
						NotificationMachineType.Compactor
					)}
					{this.checkboxList(NotificationMachineType.Compactor, this.props.compactorTypes)}
				</BiTabPanel>
			);

		if (
			unknownMachineTypeAndSuperAdmin ||
			this.props.notificationMachineTypes.includes(NotificationMachineType.Link)
		)
			tabPanels.push(
				<BiTabPanel header={localizedDynamic('UnitTypes.Link')} key="LinkTab">
					<NotificationTypeSelectorWrapper
						machineType={NotificationMachineType.Link}
						onChange={this.setPredefinedSettings}
					/>
					{this.commonInTabs(this.state.linkAll, this.state.linkNone, NotificationMachineType.Link)}
					{this.checkboxList(NotificationMachineType.Link, this.props.linkTypes)}
				</BiTabPanel>
			);

		if (
			unknownMachineTypeAndSuperAdmin ||
			this.props.notificationMachineTypes.includes(NotificationMachineType.SharkMK3)
		)
			tabPanels.push(
				<BiTabPanel header={localizedDynamic('UnitTypes.SharkMK3')} key="SharkMK3Tab">
					<NotificationTypeSelectorWrapper
						machineType={NotificationMachineType.SharkMK3}
						onChange={this.setPredefinedSettings}
					/>
					{this.commonInTabs(
						this.state.sharkMK3All,
						this.state.sharkMK3None,
						NotificationMachineType.SharkMK3
					)}
					{this.checkboxList(NotificationMachineType.SharkMK3, this.props.sharkMK3Types)}
				</BiTabPanel>
			);

		if (
			unknownMachineTypeAndSuperAdmin ||
			this.props.notificationMachineTypes.includes(NotificationMachineType.Container)
		)
			tabPanels.push(
				<BiTabPanel header={localizedDynamic('UnitTypes.Container')} key="ContainerTab">
					<NotificationTypeSelectorWrapper
						machineType={NotificationMachineType.Container}
						onChange={this.setPredefinedSettings}
					/>
					{this.commonInTabs(
						this.state.containerAll,
						this.state.containerNone,
						NotificationMachineType.Container
					)}
					{this.checkboxList(NotificationMachineType.Container, this.props.containerTypes)}
				</BiTabPanel>
			);

		return tabPanels.length > 0 ? (
			<BiTabView id={NotTabviewId} activeIndex={this.state.activeTab} onTabChange={this.changeTabView}>
				{tabPanels}
			</BiTabView>
		) : (
			<h4 className="margin-vertical-20px margin-right-1">{localized('NoNotificationMachinesSelected')}</h4>
		);
	};

	render() {
		return (
			<div className="margin-left-3 flex-direction-column" id={NotSelectorContainerId}>
				{this.tabView()}

				<FillLevelDialog
					notificationType={this.state.fillLevel.notificationType}
					fillLevelSettings={this.props.temporaryNotificationDefinition?.fillLevelSettings}
					onCancel={this.hideFillLevelDialog}
					onSave={this.saveFillLevel}
				/>
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, NotificationSelector, 'NotificationSelector'));
