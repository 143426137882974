export {};

// Source: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
Array.groupBy = <TItem, TModdedGroup>(
	data: TItem[],
	key: string,
	modifyItemGroup?: (item: TModdedGroup) => TModdedGroup
): { [key: string]: TItem[] } => {
	// reduce runs this anonymous function on each element of `data` (the `item` parameter),
	// returning the `storage` parameter at the end
	return data.reduce((storage, item) => {
		// get the first instance of the key by which we're grouping
		// const group = item[key];
		let group = item[key];
		if (modifyItemGroup) {
			group = modifyItemGroup(group);
		}

		// set `storage` for this instance of group to the outer scope (if not empty) or initialize it
		storage[group] = storage[group] || [];

		// add this item to its group within `storage`
		storage[group].push(item);

		// return the updated storage to the reduce function, which will then loop through the next
		return storage;
	}, {}); // {} is the initial value of the storage
};
