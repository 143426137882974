import { IGroupsDto, RolesType, IUserGroupPermissionsDto } from 'api/api';
import { upsert, remove, update } from 'utilities/reducer-helpers';
import { IdDataTuple } from 'utilities/types-util';
import { getEmailFromToken } from 'utilities/token-util';

export function upsertValueInUserGroupPmsArray<T extends IUserGroupPermissionsDto>(old: T[], updated: T, oldGroupId: number): typeof old {
    const index = old.findIndex(s => s.userId === updated.userId && s.groupId === oldGroupId);
    return upsert(old, updated, index);
}

export function removeValueInUserGroupPmsArray<T extends IUserGroupPermissionsDto>(old: T[], updated: T, oldGroupId?: number): typeof old {
    if (oldGroupId) {
        const index = old.findIndex(s => s.userId === updated.userId && s.groupId === oldGroupId);
        return remove(old, index);
    } else {
        return old;
    }
}

export function removeGroup<T extends IdDataTuple<IGroupsDto[], string>>(old: T[], groupId?: number): typeof old {
    let userId = getEmailFromToken();

    if (groupId && old && userId) {
        const index = old.findIndex(s => s.id === userId);

        if (index > -1) {
            const indexToDelete = old[index].data.findIndex(s => s.id === groupId);

            const newGroupArray = remove(old[index].data, indexToDelete);

            old = [...old];
            old[index] = { ...old[index] };
            old[index].data = newGroupArray;
        }
    }

    return old;
}

export function upsertGroup<T extends IdDataTuple<IGroupsDto[], string>>(old: T[], updated: IGroupsDto, minRolesTypeToAdd: RolesType): typeof old {
    let userId = getEmailFromToken();

    if (updated && old && userId && updated.roleType <= minRolesTypeToAdd) {
        const index = old.findIndex(s => s.id === userId);

        if (index > -1) {
            const indexToUpsert = old[index].data.findIndex(s => s.id === updated.id);

            const newGroupArray = upsert(old[index].data, updated, indexToUpsert);

            old = [...old];
            old[index] = { ...old[index] };
            old[index].data = newGroupArray;
        }
    }

    return old;
}

export function updateGroup<T extends IdDataTuple<IGroupsDto[], string>>(old: T[], updated: IGroupsDto): typeof old {
    let userId = getEmailFromToken();

    if (updated && old && userId) {
        const index = old.findIndex(s => s.id === userId);

        if (index > -1) {
            const indexToUpsert = old[index].data.findIndex(s => s.id === updated.id);

            const newGroupArray = update(old[index].data, updated, indexToUpsert);

            old = [...old];
            old[index] = { ...old[index] };
            old[index].data = newGroupArray;
        }
    }

    return old;
}