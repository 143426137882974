import React from 'react';
import { localized } from 'state/i18n';
import BiCalendar from 'view/shared/components/bi-calendar/bi-calendar';

export type OnCalendarChangeType = { originalEvent: Event; value: Date | Date[] };

export interface Props {
	containerClassName?: string;
	startDate: Date;
	endDate: Date;
	onStartDateChanged: (e: OnCalendarChangeType) => void;
	onEndDateChanged: (e: OnCalendarChangeType) => void;
}

class DatePickers extends React.PureComponent<Props> {
	public render() {
		return (
			<div className={`flex-direction-row ${this.props.containerClassName || ''}`}>
				<div className="flex-direction-column flex-center-column">
					<span className="date-picker-label">{localized('StartDate')}</span>
					<BiCalendar
						className="date-picker"
						showIcon={true}
						maxDate={new Date()}
						value={this.props.startDate}
						onChange={this.props.onStartDateChanged}
					/>
				</div>
				<div className="flex-direction-column flex-center-column">
					<span className="date-picker-label">{localized('EndDate')}</span>
					<BiCalendar
						className="date-picker"
						showIcon={true}
						maxDate={new Date()}
						value={this.props.endDate}
						onChange={this.props.onEndDateChanged}
					/>
				</div>
			</div>
		);
	}
}

export default DatePickers;
