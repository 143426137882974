import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { AdminstrationState } from './types';

export const initialState: AdminstrationState = {
	deviceIdWeightIdDto: [],
};

export function adminstrationReducer(state: AdminstrationState = initialState, action: Action): AdminstrationState {
	if (isType(action, actions.getDeviceIdWeightIdsAction.done)) {
		return {
			...state,
			deviceIdWeightIdDto: action.payload.result,
		};
	}

	return state;
}
