import { NotificationCustomMessageState } from './types';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export const initialState: NotificationCustomMessageState = {
    customMessages: []
}

export function notificationCustomMessageReducer(state: NotificationCustomMessageState = initialState, action: Action): NotificationCustomMessageState {

    if (isType(action, actions.GetCustomMessagesAction.done)) {
        return {
            ...state,
            customMessages: action.payload.result
        }
    }



    if (isType(action, actions.SaveCustomMessagesAction.done)) {
        return {
            ...state,
            customMessages: [
                ...state.customMessages
                    .filter(oldMsg => !action.payload.result.some(newMsg => newMsg.unitId === oldMsg.unitId && newMsg.linkError === oldMsg.linkError)),
                ...action.payload.result
            ]
        }
    }


    return state;
}