import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import './bi-multiselect.scss';
import React from 'react';
import { connect } from 'react-redux';

interface MultiselectState {
	isOpen: boolean;
}

interface Props extends MultiSelectProps {
	options?: any[];
	onChange?: (e: OnChangeEvent) => void;
	onEnterPressed?: (e: KeyboardEvent) => void;
	filter?: boolean;
	placeholder?: string;
	selectedItems: any[];
}

export interface OnChangeEvent {
	originalEvent: Event;
	value: any;
}

class BiMultiselect extends React.PureComponent<Props, MultiselectState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}

	public render() {
		const multiSelectClassName = 'p-multiselect';

		return (
			<MultiSelect
				placeholder={this.props.placeholder}
				className={
					multiSelectClassName + (this.state.isOpen
						? ' '
						: ' hide') + (this.props.selectedItems && this.props.selectedItems.length > 0 ? ' not-empty no-select' : ' no-select')
				}
				onFocus={this.onFocus}
				value={this.props.selectedItems}
				options={this.props.options}
				onChange={this.onChangeEvent}
				filter={this.props.filter}
				{...this.props}
			/>
		);
	}
	private onChangeEvent = (e: OnChangeEvent) => {

		if ((e.originalEvent as any).key === 'Enter' && this.props.onEnterPressed) {
			this.setState({ isOpen: false })
			this.props.onEnterPressed(e.originalEvent as KeyboardEvent)
		} else if (this.props.onChange) {
			this.props.onChange(e);
		}
	}
	private onFocus = () => {
		document.addEventListener('keypress', this.enterKeyPressed);
	};

	private enterKeyPressed = (e: KeyboardEvent) => {
		if (this.props.onEnterPressed) {
			this.props.onEnterPressed(e)
		}
		document.removeEventListener('keypress', this.enterKeyPressed);
	};
}

export default connect(null, null)(BiMultiselect);
