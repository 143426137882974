import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
	'GET_USER_GROUPS_CHILD_HIERARCHY',
	'SET_CURRENT_GROUP',
	'GET_USERS_ADMIN_GROUPS_CHILD_HIERARCHY',
	'GET_USERS_THAT_I_OWN',
	'SET_USERS_GROUP_PERMISSIONS',
	'DELETE_USER_GROUP_PERMISSION',
	'CREATE_USER_WITH_GROUP_PERMISSION',
	'GET_USERS_COUNTRY_ADMIN_GROUPS_CHILD_HIERARCHY',
	'GET_USERS_SUPER_ADMIN_GROUPS_CHILD_HIERARCHY',
	'CREATE_CHILD_GROUP',
	'EDIT_GROUP',
	'DELETE_GROUP',
	'EDIT_GROUP_DEBITOR',
	'RESEND_CONFIRMATION_EMAIL'
);
