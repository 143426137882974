import { GPSHistoryDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constrants';

const actionCreator = actionCreatorFactory('machine-history');

export const GetGPSHistoryAction = actionCreator.async<
	{ serialNumber: string; startDate: Date; endDate: Date },
	GPSHistoryDto[]
>(constants.GET_GPS_HISTORY);
