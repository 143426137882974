import React from 'react';
import {useDispatch} from 'react-redux';
import {navigateToSpecificNotification} from 'state/ducks/notifications/operations';
import {localized} from 'state/i18n';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';

const AddNewNotification: React.FC = () => {
	const dispatch = useDispatch();

	const addNewNotification = () => {
		navigateToSpecificNotification(undefined, true)(dispatch);
	};

	return (
		<BiButton onClick={addNewNotification} colorTheme="org-primary" containerTheme="normal-button-corners">
			<span className="flex-wrap flex-center-row">
				<span className="white-space-nowrap text-bold">{localized('AddNewNotification')}</span>
			</span>
		</BiButton>
	);
};

export default AddNewNotification;
