import "./responsive-spacing-wrapper.scss";
import React from 'react';

export default class ResponsiveSpacingWrapper extends React.PureComponent {
    public render() {
        return (
            <div className="margin-horizontal-responsive">
                {this.props.children}
            </div>
        )
    }
}
