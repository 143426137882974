import React from 'react';
import { connect } from 'react-redux';
import { localized } from 'state/i18n';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import './filter-checkboxes.scss';

interface PropsFromParent {
	setFillLevelFilterState: (newState: boolean) => void;
	setEmptiedFilterState: (newState: boolean) => void;
	isBaler: boolean;
}

interface State {
	filterOnFillLevelChanged: boolean;
	filterOnFull: boolean;
}

type Props = PropsFromParent;

class FilterCheckboxes extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			filterOnFillLevelChanged: false,
			filterOnFull: false,
		};
	}

	public handleFillLevelCheckboxToggled = (e: { checked: boolean; value: any }) => {
		this.setState({ filterOnFillLevelChanged: e.checked });
		this.props.setFillLevelFilterState(e.checked);
	};

	public handleFullCounterCheckboxToggled = (e: { checked: boolean; value: any }) => {
		this.setState({ filterOnFull: e.checked });
		this.props.setEmptiedFilterState(e.checked);
	};

	public render() {
		return (
			<div className="flex-direction-row flex-center-column flex-wrap">
				<div className="margin-left-40px">
					<BiCheckbox
						label={this.props.isBaler ? localized('balesOnSite') : localized('fillLevel')}
						onChange={this.handleFillLevelCheckboxToggled}
						checked={this.state.filterOnFillLevelChanged}
					/>
				</div>
				<div className="margin-left-40px">
					<BiCheckbox
						label={localized('FullCounter')}
						onChange={this.handleFullCounterCheckboxToggled}
						checked={this.state.filterOnFull}
					/>
				</div>
			</div>
		);
	}
}

export default connect()(FilterCheckboxes);
