import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { FileDirectoryTypeEnum, IFileDirectoryDto } from 'api/api';
import { Button } from 'primereact/button';
import React, { FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reactAI } from 'services/telemetry-service';
import {
	DeletePartnerAreaFile,
	DeletePartnerAreaFolder,
	GetPartnerAreaFile,
} from 'state/ducks/partner-area/operations';
import { localized, localizedInterpolation } from 'state/i18n';
import { AppState } from 'state/store';
import { isAtLeastSuperAdminFromState } from 'utilities/roles-util';
import { ConfirmDialog } from 'view/shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import './partner-area.scss';

type Props = ReturnType<typeof mapStateToProps> & {
	obj: IFileDirectoryDto;
};

const mapStateToProps = (state: AppState) => {
	const isSuperAdmin = isAtLeastSuperAdminFromState(state);

	return {
		isSuperAdmin,
	};
};

const PartnerAreaRowActions: FC<Props> = props => {
	const dispatch = useDispatch();

	const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);

	const handleDownloadFile = async () => {
		if (props.obj.fullPath) {
			setIsDownloading(true);
			await GetPartnerAreaFile(props.obj.fullPath);
			setIsDownloading(false);
		}
	};

	const handleDeleteFile = () => {
		if (!props.obj.fullPath) return;

		if (props.obj.fileDirectoryType === FileDirectoryTypeEnum.File) {
			DeletePartnerAreaFile(props.obj.fullPath)(dispatch);
		} else {
			DeletePartnerAreaFolder(props.obj.fullPath)(dispatch);
		}

		setIsDeleteDialogVisible(false);
	};

	return (
		<>
			{/* Only render Dialog if we need to show it */}
			{isDeleteDialogVisible && (
				<ConfirmDialog
					header={localized('DeleteFile')}
					visible={isDeleteDialogVisible}
					onHide={() => setIsDeleteDialogVisible(false)}
					confirmColor="org-red"
					confirmMessage={localized('Delete')}
					dialogMessage={localizedInterpolation('XDeleteConfirmMsg', { X: props.obj.name })}
					onConfirm={handleDeleteFile}
					onCancel={() => setIsDeleteDialogVisible(false)}
				/>
			)}
			<div className="flex-direction-row flex-center-row">
				<div className="flex-1"></div>
				{props.obj.fileDirectoryType === FileDirectoryTypeEnum.Folder ? null : isDownloading ? (
					<Button
						tooltip={'loading'}
						className={'p-button-custom bi-button-icon-only'}
						icon="pi pi-spin pi-spinner"
					/>
				) : (
					<Button
						tooltip={localized('download')}
						className={'p-button-custom bi-button-icon-only'}
						icon="pi pi-download"
						onClick={handleDownloadFile}
					/>
				)}
				{props.isSuperAdmin && (
					<Button
						tooltip={localized('Delete')}
						className={'p-button-custom bi-button-icon-only'}
						icon="pi pi-trash"
						onClick={() => setIsDeleteDialogVisible(true)}
					/>
				)}
			</div>
		</>
	);
};

export default connect(mapStateToProps)(
	withAITracking(reactAI.reactPlugin, PartnerAreaRowActions, 'PartnerAreaRowActions')
);
