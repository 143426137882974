import {
	DataTypes,
	DateTypes,
	IGraphReportDto,
	IReportSingleUnitDto,
	ITableReportDto,
	KeyValuePairOfStringAndString,
} from 'api/api';
import moment from 'moment';
import { localized } from 'state/i18n';
import { Compactor, DateFourLetterYear, DateFourLetterYearDashDateIndexLength } from 'utilities/constants';
import { isBaler, MaxFillLevelPercent } from 'utilities/machine-util';
import { roundToDecimals } from 'utilities/number-helpers';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';
import {
	getLocalizedStringDayXIndexed,
	getLocalizedStringMonthXIndexed,
	getLocalizedStringWeekXIndexed,
} from 'view/shared/components/string-to-date';
import { PropNameDatePart, PropNameSiteUser, PropNameUnitId, PropNameUnitType, PropNameYearPart } from './constants';

type DateIntervalAndIncrementer = (currentDate: Date) => Date;
export const ColumnTotalName = 'Total';

export function getDateIncrementerImmutable(dateType: DateTypes): DateIntervalAndIncrementer {
	switch (dateType) {
		case DateTypes.Day:
			return addDay;
		case DateTypes.Week:
			return addWeek;
		case DateTypes.Month:
			return addMonth;
		case DateTypes.Year:
			return addYear;
		default:
			return addYear;
	}
}

function addDay(date: Date): Date {
	return moment(date).add(1, 'days').toDate();
}

function addWeek(date: Date): Date {
	return moment(date).add(1, 'weeks').toDate();
}

function addMonth(date: Date): Date {
	return moment(date).add(1, 'months').toDate();
}

function addYear(date: Date): Date {
	return moment(date).add(1, 'year').toDate();
}

export function getStartOfDateType(dateType: DateTypes) {
	switch (dateType) {
		case DateTypes.Day:
			return startOfDay;
		case DateTypes.Week:
			return startOfWeek;
		case DateTypes.Month:
			return startOfMonth;
		case DateTypes.Year:
			return startOfYear;
		default:
			return startOfYear;
	}
}

function startOfDay(date: Date): Date {
	return moment(date).startOf('day').toDate();
}

function startOfWeek(date: Date): Date {
	return moment(date).startOf('week').toDate();
}

function startOfMonth(date: Date): Date {
	return moment(date).startOf('month').toDate();
}

function startOfYear(date: Date): Date {
	return moment(date).startOf('year').toDate();
}

//Months in JavaScript are 0 indexed but in the real world they are 1 indexed
export function convertMonthToJS(reportData: IReportSingleUnitDto[]) {
	reportData.forEach(report => {
		if (report.values && report.values.length) {
			report.values.forEach(value => value.datePart--);
		}
	});
}

export function getDateTypeIndex(dateType: DateTypes, currentDate: Date): number {
	switch (dateType) {
		case DateTypes.Day:
			return moment(currentDate).day();
		case DateTypes.Week:
			return moment(currentDate).isoWeek();
		case DateTypes.Month:
			return moment(currentDate).month();
		case DateTypes.Year:
			return moment(currentDate).year();
		default:
			return moment(currentDate).year();
	}
}

export function getDateTypeByIndex(dateType: DateTypes, dateIndex: number | string): string {
	switch (dateType) {
		case DateTypes.Day:
			return getLocalizedStringDayXIndexed(dateIndex);
		case DateTypes.Week:
			const dateIndexNum = Number(dateIndex);
			return !isNaN(dateIndexNum) ? getLocalizedStringWeekXIndexed(dateIndexNum) : dateIndex.toString();
		case DateTypes.Month:
			return getLocalizedStringMonthXIndexed(dateIndex);
		case DateTypes.Year:
			return dateIndex.toString();
		default:
			return dateIndex.toString();
	}
}

export function getReportTableDateColumnKeyPostfix(currentDate: Date) {
	return `${moment(currentDate).format(DateFourLetterYear)}`;
}

export function getReportTableDateColumnKey(dateType: DateTypes, currentDate: Date) {
	return `${getDateTypeIndex(dateType, currentDate)}-${getReportTableDateColumnKeyPostfix(currentDate)}`;
}

export function processReportDataToGraph(
	dateType: DateTypes,
	reportData: IReportSingleUnitDto[],
	startDate: Date,
	endDate: Date,
	dataType: DataTypes,
	graphReport: IGraphReportDto
): {
	totalFilteredCompactors: number;
	totalFilteredBalers: number;
} {
	let totalFilteredCompactors = 0;
	let totalFilteredBalers = 0;

	for (let item of reportData) {
		processOneReportDataToCustomType(
			dateType,
			item,
			startDate,
			endDate,
			dataType,
			graphReport,
			storeProcessedReportDataOnGraphFormat
		);

		// Filter out compactors if the user has set baler-specific options
		// opposite for balers
		if (shouldBalerBeFiltered(dataType, item)) {
			++totalFilteredBalers;
		} else if (shouldCompactorBeFiltered(dataType, item)) {
			++totalFilteredCompactors;
		}
	}

	for (let index = 0; index < graphReport.dates!.length; index++) {
		const element = graphReport.dates![index];

		// Dates are assumed to be on the format "YYYY-{date-index}" for uniqueness. Strip away the "YYYY-"
		const dateIndex = element.substr(DateFourLetterYearDashDateIndexLength);

		// Months are returned as number indices from the backend. Thus, we must localize them.
		const dateValue = getDateTypeByIndex(dateType, dateIndex);

		graphReport.dates![index] = dateValue;
	}

	// FillRate graph should show averages, not sums
	if (dataType === DataTypes.FillRate) {
		graphReport.dates!.forEach((date, index: number, arr) => {
			if (graphReport.values) {
				const countGreaterThanZero = reportData.reduce(
					(a, b) =>
						a +
						(b.values &&
						b.values.filter(x => getDateTypeByIndex(dateType, x.datePart) === date && x.value > 0).length >
							0
							? 1
							: 0),
					0
				);
				graphReport.values![index] =
					countGreaterThanZero !== 0 ? graphReport.values![index] / countGreaterThanZero : 0;

				if (graphReport.values[index] > MaxFillLevelPercent) {
					graphReport.values[index] = MaxFillLevelPercent;
				}
			}
		});
	}

	return { totalFilteredCompactors, totalFilteredBalers };
}

export function processReportDataToTable(
	dateType: DateTypes,
	reportData: IReportSingleUnitDto[],
	startDate: Date,
	endDate: Date,
	dataType: DataTypes,
	processedTableReport: ITableReportDto[]
): {
	totalFilteredCompactors: number;
	totalFilteredBalers: number;
} {
	let totalFilteredCompactors = 0;
	let totalFilteredBalers = 0;

	for (let item of reportData) {
		const tableReportDto: ITableReportDto = {
			dataset: [],
		};

		processOneReportDataToCustomType(
			dateType,
			item,
			startDate,
			endDate,
			dataType,
			tableReportDto,
			storeProcessedReportDataOnTableFormat
		);

		// Filter out compactors if the user has set baler-specific options
		// opposite for balers
		if (shouldBalerBeFiltered(dataType, item)) {
			++totalFilteredBalers;
			continue;
		} else if (shouldCompactorBeFiltered(dataType, item)) {
			++totalFilteredCompactors;
			continue;
		}

		tableReportDto.dataset!.unshift(
			// Always set UnitId and MachineType as they are the first two columns.
			{
				key: PropNameUnitId,
				value: item.unitSerialNumber || '',
				toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
				init: KeyValuePairOfStringAndString.prototype.init,
			},
			{
				key: PropNameUnitType,
				value: item.unitType || '',
				toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
				init: KeyValuePairOfStringAndString.prototype.init,
			},
			{
				key: PropNameSiteUser,
				value: item.siteUser || '',
				toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
				init: KeyValuePairOfStringAndString.prototype.init,
			}
		);

		let sumOfValues = item.values ? item.values.reduce((a, b) => a + b.value, 0) : 0;

		//Total for fillrate should be an average, not a sum
		if (dataType === DataTypes.FillRate && item.values) {
			const totalValues = item.values.reduce((a, b) => a + b.value * b.numberOfEmptyings, 0);
			const sumEmptings = item.values.reduce((a, b) => a + b.numberOfEmptyings, 0);

			sumOfValues = roundToDecimals(totalValues / (sumEmptings > 0 ? sumEmptings : 1));

			if (sumOfValues > MaxFillLevelPercent) {
				sumOfValues = MaxFillLevelPercent;
			}
		}

		tableReportDto.dataset!.push({
			key: ColumnTotalName,
			value: `${sumOfValues}`,
			toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
			init: KeyValuePairOfStringAndString.prototype.init,
		});
		processedTableReport.push(tableReportDto);
	}

	return { totalFilteredCompactors, totalFilteredBalers };
}

function shouldBeFiltered(dataType: DataTypes, item: IReportSingleUnitDto): boolean {
	return shouldBalerBeFiltered(dataType, item) || shouldCompactorBeFiltered(dataType, item);
}

function shouldBalerBeFiltered(dataType: DataTypes, item: IReportSingleUnitDto): boolean {
	return dataType === DataTypes.NoEmptyings && isBaler(item.machineGroupsName);
}

function shouldCompactorBeFiltered(dataType: DataTypes, item: IReportSingleUnitDto): boolean {
	return dataType === DataTypes.BaleOutput && item.machineGroupsName === Compactor;
}

function processOneReportDataToCustomType<T>(
	dateType: DateTypes,
	item: IReportSingleUnitDto,
	startDate: Date,
	endDate: Date,
	dataType: DataTypes,
	processedData: T,
	storeProcessedDataInParam: (currValue: number, dateType: DateTypes, currentDate: Date, processedData: T) => void
): number {
	const getIncrementedDateByDateType = getDateIncrementerImmutable(dateType);
	const getStartOfCurrentDateType = getStartOfDateType(dateType);
	const startOfStartDateByDateType = getStartOfCurrentDateType(startDate);
	const startOfEndDateByDateType = getStartOfCurrentDateType(endDate);

	const getIncrementedDateByYear = getDateIncrementerImmutable(DateTypes.Year);
	const getStartOfCurrentYear = getStartOfDateType(DateTypes.Year);
	const startOfStartDateByYear = getStartOfCurrentYear(startDate);
	const startOfEndDateByYear = getStartOfCurrentYear(endDate);

	if (!item.values) {
		return 0;
	}

	if (shouldBeFiltered(dataType, item)) {
		// Filtered out - don't add to the list
		return 1;
	}

	// First, group by year and then by the user-specific date type.
	const dataGroupedByYear = Array.groupBy(item.values, PropNameYearPart);

	if (!dataGroupedByYear) {
		return 0;
	}

	for (
		let currentYear = startOfStartDateByYear;
		currentYear <= startOfEndDateByYear;
		currentYear = getIncrementedDateByYear(currentYear)
	) {
		const currentYearGroup = dataGroupedByYear[getDateToString(currentYear, DateFormatterModes.byYear)];

		if (!currentYearGroup) {
			// Skip the whole year as the device has no registered values.
			continue;
		}

		// Now we can group by user-specific date type
		const dataGroupedByDateType = Array.groupBy(currentYearGroup, PropNameDatePart);

		for (
			let currentDate = startOfStartDateByDateType;
			currentDate <= startOfEndDateByDateType;
			currentDate = getIncrementedDateByDateType(currentDate)
		) {
			if (
				getDateToString(currentDate, DateFormatterModes.byYear) !==
				getDateToString(currentYear, DateFormatterModes.byYear)
			) {
				storeProcessedDataInParam(0, dateType, currentDate, processedData);
				continue;
			}

			// We grouped by datePart, which translates to the index of the chosen date type.
			const currentDateTypeGroup = dataGroupedByDateType[getDateTypeIndex(dateType, currentDate)];

			let sumOfValues = 0;

			if (currentDateTypeGroup) {
				sumOfValues = currentDateTypeGroup.reduce((a, b) => a + b.value, 0);
			}

			storeProcessedDataInParam(sumOfValues, dateType, currentDate, processedData);
		}
	}

	return 0;
}

function getDateAndDateIndexAsFourLetterYearDashIndex(currentDate: Date, dateTypeIndex: number) {
	return `${getDateToString(currentDate, DateFormatterModes.byYear)}-${dateTypeIndex}`;
}

function storeProcessedReportDataOnGraphFormat(
	currValue: number,
	dateType: DateTypes,
	currentDate: Date,
	graphReport: IGraphReportDto
) {
	const dateTypeIndex = getDateTypeIndex(dateType, currentDate);
	const dateAsYearPartAndIndex = getDateAndDateIndexAsFourLetterYearDashIndex(currentDate, dateTypeIndex);

	const indexOfExistingDateIfAny = graphReport.dates!.indexOf(dateAsYearPartAndIndex);

	if (indexOfExistingDateIfAny > -1) {
		graphReport.values![indexOfExistingDateIfAny] += currValue;
	} else {
		graphReport.dates!.push(dateAsYearPartAndIndex);
		graphReport.values!.push(currValue);
	}
}

function storeProcessedReportDataOnTableFormat(
	currValue: number,
	dateType: DateTypes,
	currentDate: Date,
	tableReport: ITableReportDto
): void {
	tableReport.dataset &&
		tableReport.dataset.push({
			key: getReportTableDateColumnKey(dateType, currentDate),
			value: `${currValue}`,
			init: KeyValuePairOfStringAndString.prototype.init,
			toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
		});
}

const indexTableStartData = 3; // First two columns from API are MachineId and MachineType.

export function getHeaderRowForReportTable(
	startOfStartDateByDateType: Date,
	startOfEndDateByDateType: Date,
	getIncrementedDateByDateType: (currentDate: Date) => Date,
	dateType: DateTypes
) {
	// The first three columns are MachineId, MachineType and SiteUser while the last one is Total.
	const headerRow: ITableReportDto = {
		dataset: [
			{
				key: PropNameUnitId,
				value: localized('unitId'),
				toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
				init: KeyValuePairOfStringAndString.prototype.init,
			},
			{
				key: PropNameUnitType,
				value: localized('unitType'),
				toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
				init: KeyValuePairOfStringAndString.prototype.init,
			},
			{
				key: PropNameSiteUser,
				value: localized('siteUser'),
				toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
				init: KeyValuePairOfStringAndString.prototype.init,
			},
		],
	};

	let indexOfHeaderRow = indexTableStartData;
	for (
		let currentDate = startOfStartDateByDateType;
		currentDate <= startOfEndDateByDateType;
		currentDate = getIncrementedDateByDateType(currentDate)
	) {
		const dateTypeIndex = getDateTypeIndex(dateType, currentDate);
		const key = getReportTableDateColumnKey(dateType, currentDate);

		// Translate dates if necessary.
		let updatedValue = `${dateTypeIndex}`;
		if (dateType === DateTypes.Month) {
			updatedValue = getLocalizedStringMonthXIndexed(dateTypeIndex);
		} else if (dateType === DateTypes.Week) {
			updatedValue = getLocalizedStringWeekXIndexed(dateTypeIndex);
		}

		headerRow.dataset![indexOfHeaderRow] = {
			key,
			value: updatedValue,
			toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
			init: KeyValuePairOfStringAndString.prototype.init,
		};

		++indexOfHeaderRow;
	}

	headerRow.dataset!.push({
		key: ColumnTotalName,
		value: localized('Total'),
		toJSON: KeyValuePairOfStringAndString.prototype.toJSON,
		init: KeyValuePairOfStringAndString.prototype.init,
	});

	return headerRow;
}

export function getXLabel(dateType: DateTypes) {
	switch (dateType) {
		case DateTypes.Day:
			return localized('Day');
		case DateTypes.Week:
			return localized('Week');
		case DateTypes.Month:
			return localized('Month');
		case DateTypes.Year:
			return localized('Year');
		default:
			return localized('Year');
	}
}

export function getYLabel(dataType: DataTypes) {
	switch (dataType) {
		case DataTypes.BaleOutput:
			return localized('NumberOfBales');
		case DataTypes.FillRate:
			return localized('Percent');
		case DataTypes.NoEmptyings:
			return localized('Emptyings');
	}
}

export function getDatasetLabel(dataType: DataTypes) {
	switch (dataType) {
		case DataTypes.BaleOutput:
			return localized('BaleOutput');
		case DataTypes.FillRate:
			return localized('fillLevel');
		case DataTypes.NoEmptyings:
			return localized('unitEmptiedNoOfTimes');
	}
}

export function getUnitOfDataType(dataType: DataTypes): string {
	switch (dataType) {
		case DataTypes.BaleOutput:
			return ` ${localized('Bales')}`;
		case DataTypes.FillRate:
			return '%';
		case DataTypes.NoEmptyings:
			return ` ${localized('Emptyings')}`;
	}
}

export function decrementByDateType(dateType: DateTypes, date: Date): Date {
	switch (dateType) {
		case DateTypes.Day:
			return moment(date).subtract(1, 'days').toDate();
		case DateTypes.Week:
			return moment(date).subtract(1, 'weeks').toDate();
		case DateTypes.Month:
			return moment(date).subtract(1, 'months').toDate();
		case DateTypes.Year:
			return moment(date).subtract(1, 'years').toDate();
		default:
			return moment(date).subtract(1, 'years').toDate();
	}
}
