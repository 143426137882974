import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
    'CLEAR_MACHINE_HISTORY',
    'GET_MACHINE_HISTORY_FILTERED',
    'GET_MACHINE_HISTORY_INFO',
    'UPDATE_MACHINE_HISTORY',
    'GET_PRESSURE_CHART',
    'GET_UNIT_CYCLES',
    'RESET_UNIT_CYCLES'
);
