import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export enum MapTypeId {
	/** This map type displays a transparent layer of major streets on satellite images. */
	HYBRID = 'hybrid',
	/** This map type displays a normal street map. */
	ROADMAP = 'roadmap',
	/** This map type displays satellite images. */
	SATELLITE = 'satellite',
	/** This map type displays maps with physical features such as terrain and vegetation. */
	TERRAIN = 'terrain',
}
export const constants = getArgsAsTypesafeObjectProperties(
	'GET_MACHINE_LOCATIONS',
	'SET_ACTIVE_MACHINE',
	'SET_ACTIVE_MACHINES',
	'GET_MACHINE_LOCATIONS_FILTERED',
	'CLEAR_MACHINE_LOCATIONS',
	'SET_MAP_TYPE',
	'SET_MAP_BOUNDS',
	'SET_MAP_ZOOMLEVEL',
	'SET_MAP_CENTER',
);


