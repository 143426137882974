import React, { PureComponent } from 'react';
import { INotificationDefinitionDto, NotificationMachineType, INotificationTypeDto } from 'api/api';
import { classNameCardContainer } from './notifications-list';
import Card from 'view/shared/components/cards/white-card';
import { localizedDynamic } from 'state/i18n';
import DeleteNotificationDefinitionDialog from './delete-definition-dialog';
import { getDateToString } from 'view/shared/components/date-to-string';

type Props = {
    def: INotificationDefinitionDto;
    buttonsGenerator: (def: INotificationDefinitionDto, handleEdit: () => void, handleDelete: () => void) => JSX.Element;
    handleEdit: (def: INotificationDefinitionDto) => void;
    handleDelete: (def: INotificationDefinitionDto) => void;
};

interface State {
    showDeleteDialog: boolean;
}

class NotificationAccordionContent extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { showDeleteDialog: false };
    }

    handleEdit = () => {
        this.props.handleEdit(this.props.def);
    }

    buttonHandleDelete = () => {
        this.setState({ showDeleteDialog: true });
    }

    dialogHandleDelete = () => {
        this.props.handleDelete(this.props.def);
    }

    hideDialog = () => {
        this.setState({ showDeleteDialog: false });
    }

    minicardsGenerator = (elements: string[]): JSX.Element => {
        let elementCards: JSX.Element[] = elements.map(element =>
            <Card
                key={element}
                classNameContainer="mini-card">
                {element}
            </Card>)
        return <div className="flex-wrap" key={elements.toString()}>{elementCards}</div>
    }


    makeControllerList = (controllerString: string, models?: string[]): JSX.Element => {

        if (models && models.length > 0) {
            let modelCards = this.minicardsGenerator(models);
            return (
                <div key={controllerString}>
                    <div>
                        {controllerString}
                    </div>
                    {modelCards}
                </div>
            );
        }
        return <div key={controllerString} />;
    }

    makeControllerTypeCards = (): JSX.Element[] => {
        const cards: JSX.Element[] = [];


        const balers: string[] | undefined = this.props.def.controllerTypes?.balers;
        if (balers && balers?.length > 0)
            cards.push(this.makeControllerList(
                localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Baler]}`),
                balers
            ));

        const compactors: string[] | undefined = this.props.def.controllerTypes?.compactors;
        if (compactors && compactors?.length > 0)
            cards.push(this.makeControllerList(
                localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Compactor]}`),
                compactors
            ));

        const links: string[] | undefined = this.props.def.controllerTypes?.link;
        if (links && links?.length > 0)
            cards.push(this.makeControllerList(
                localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Link]}`),
                links
            ));

        const sharkMK3s: string[] | undefined = this.props.def.controllerTypes?.shark;
        if (sharkMK3s && sharkMK3s?.length > 0)
            cards.push(this.makeControllerList(
                localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.SharkMK3]}`),
                sharkMK3s
            ));

        const containers: string[] | undefined = this.props.def.controllerTypes?.containers;
        if (containers && containers?.length > 0)
            cards.push(this.makeControllerList(
                localizedDynamic(`UnitTypes.${NotificationMachineType[NotificationMachineType.Container]}`),
                containers
            ));

        return cards;
    }

    buildNotificationNames = (notificationTypes: INotificationTypeDto[], machineType: NotificationMachineType) =>
		notificationTypes
			.filter(type => type.machineType === machineType)
			.map(not => not.name)
			.filter((not: string | undefined): not is string => !!not)

    notificationCards = (notificationTypes?: INotificationTypeDto[]): JSX.Element[] => {
        if (!notificationTypes) return [];
        let Cards: JSX.Element[] = []

        const notBalers = this.buildNotificationNames(notificationTypes, NotificationMachineType.Baler);
        const notCompactors = this.buildNotificationNames(notificationTypes, NotificationMachineType.Compactor);
        const notLink = this.buildNotificationNames(notificationTypes, NotificationMachineType.Link);
        const notSharkMK3 = this.buildNotificationNames(notificationTypes, NotificationMachineType.SharkMK3);
        const notContainer = this.buildNotificationNames(notificationTypes, NotificationMachineType.Container);

        if (notBalers.length !== 0)
            Cards.push(this.minicardsGenerator(notBalers.map(not => localizedDynamic(not))));
        if (notCompactors.length !== 0)
            Cards.push(this.minicardsGenerator(notCompactors.map(not => localizedDynamic(not))));
        if (notLink.length !== 0)
            Cards.push(this.minicardsGenerator(notLink.map(not => localizedDynamic(not))));
        if (notSharkMK3.length !== 0)
            Cards.push(this.minicardsGenerator(notSharkMK3.map(not => localizedDynamic(not))));
        if (notContainer.length !== 0)
            Cards.push(this.minicardsGenerator(notContainer.map(not => localizedDynamic(not))));

        return Cards;
    }

    combinedControllersnotification = () => {
        let controllerCards = this.makeControllerTypeCards();
        let notificationCards = this.notificationCards(this.props.def.notificationTypes)

        let elementsList: JSX.Element[] = [];

        for (let i: number = 0; i < Math.max(controllerCards.length, notificationCards.length); i++) {
            elementsList.push(controllerCards[i]);
            elementsList.push(notificationCards[i]);
        }


        return (
            <div className="mini-grid grid-controller-notifications">
                {elementsList}
            </div>
        )
    }

    render() {
        let users: string[] = this.props.def.notificationUsers?.map(user => user.email).filter((user: string | undefined): user is string => !!user);

        return (
            <Card classNameContainer={classNameCardContainer + "border-left-org-primary"}>
                <div className="accordion-grid content">
                    <div>
                        {this.props.def.name}
                    </div>
                    {this.combinedControllersnotification()}
                    <div>
                        {this.minicardsGenerator(users)}
                    </div>
                    {this.minicardsGenerator([this.props.def.createdBy!])}
                    {this.minicardsGenerator([getDateToString(this.props.def.modifiedOn)])}
                    {this.minicardsGenerator([this.props.def.modifiedBy ?? ''])}
                    {this.props.buttonsGenerator(this.props.def, this.handleEdit, this.buttonHandleDelete)}
                </div>
                <DeleteNotificationDefinitionDialog onDelete={this.dialogHandleDelete} onCancel={this.hideDialog} showDialog={this.state.showDeleteDialog} />
            </Card>
        )
    }
}

export default NotificationAccordionContent;