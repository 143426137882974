import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { DialogProps } from 'primereact/dialog';
import React, { useState } from 'react';
import BiDialog from 'view/shared/components/dialogs/bi-dialog/bi-dialog';
import './bi-step-confirm-dialog.scss';
import BiButton from '../../buttons/bi-button/bi-button';
import { localized } from 'state/i18n';
import { BiSteps } from '../../bi-steps/bi-steps';

type PropsFromParent = {
	title: string;
	subtitle?: string | string[];
	items: React.ReactNode[];
	itemLabels: string[];
	onClose: () => void;
};

type Props = DialogProps & PropsFromParent;

const maxLabelLength = 30;

export const BiStepConfirmDialog: React.FC<Props> = props => {
	const [activeIndex, setActiveIndex] = useState(0);

	const stepItems = props.itemLabels.map(item => {
		return {
			label: item.length > maxLabelLength ? `${item.substring(0, maxLabelLength)}...` : item,
			separator: false,
		} as MenuItem;
	});

	const multipleItems: boolean = props.items.length > 1;
	const isLastItem: boolean = !multipleItems || activeIndex === props.items.length - 1;

	return (
		<BiDialog modal={true} showHeader={false} dismissableMask={false} {...props} className="bi-step-confirm-dialog">
			<div className="flex-space-between-row">
				<div className="text-wrapper">
					<h3 className="text-bold title">{props.title}</h3>
					{props.subtitle &&
						(typeof props.subtitle === 'string' ? (
							<p className="subtitle">{props.subtitle}</p>
						) : (
							props.subtitle.map(str => <p className="subtitle">{str}</p>)
						))}
				</div>
			</div>
			<div>{props.children}</div>

			{props.items.at(activeIndex)}

			{multipleItems && <BiSteps model={stepItems} activeIndex={activeIndex} readOnly={true} />}

			<div className="flex-end-row margin-top-10px">
				{multipleItems && activeIndex !== 0 && (
					<BiButton
						onClick={() => setActiveIndex(activeIndex - 1)}
						colorTheme="org-primary-grey"
						containerClassName="margin-right-14px"
						containerTheme="slim-with-rounded-corners"
					>
						{localized('Back')}
					</BiButton>
				)}
				<BiButton
					onClick={() => (isLastItem ? props.onClose() : setActiveIndex(activeIndex + 1))}
					colorTheme={'org-primary'}
					containerTheme="slim-with-rounded-corners"
				>
					{localized(isLastItem ? 'Close' : 'Next')}
				</BiButton>
			</div>
		</BiDialog>
	);
};
