import { PickList, PickListProps } from 'primereact/picklist';
import React from 'react';
import { FC } from 'react';
import './bi-pick-list.scss';

type Props = PickListProps & {};

const BiPickList: FC<Props> = React.memo((props: Props) => {
	return <PickList showSourceControls={false} showTargetControls={false} className="bi-pick-list" {...props} />;
});

export default BiPickList;
