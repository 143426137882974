import {
	DataTypeProvider,
	DataTypeProviderProps,
	IntegratedSorting,
	Sorting,
	SortingState,
	TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
	DragDropProvider,
	Grid,
	Table,
	TableColumnReordering,
	TableColumnResizing,
	TableHeaderRow,
	VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { INotificationHistoryDto, NotificationEvent, NotificationMachineType, TableId } from 'api/api';
import { Guid } from 'guid-typescript';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { SortingGeneric, TableColumnWidthInfoGeneric } from 'state/ducks/history-table-settings/types';
import { setOrder, setSize, setSorting } from 'state/ducks/notification-history-settings/operations';
import { NotificationHistoryDtoKeys } from 'state/ducks/notification-history-settings/types';
import { localized, localizedDynamic, localizedInterpolation } from 'state/i18n';
import { AppState } from 'state/store';
import { AdvancedNotificationName, OfflineForXHoursThreshold } from 'utilities/constants';
import { getLocalizedUnitStatusError } from 'utilities/machine-error-util';
import {
	BalesAlmostFinishedNotificationTypeName,
	BalesFinishedNotificationTypeName,
	CustomMessagesNotificationTypeName,
	CustomXtelMessageNotificationTypeName,
	EmptyDetectionNotificationTypeName,
	FillLevelNotificationTypeName,
	OfflineForXHoursNotificationTypeName,
	PickupOnDateOrderedNotificationTypeName,
	PickupOrderedNotificationTypeName,
} from 'utilities/notification-constants';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';
import BiNoData from './bi-no-data/bi-no-data';
import { ROW_HEIGHT } from './bi-table';
import { NotificationHistoryTableEdit } from './notification-history-table-edit';

interface NotificationHistoryDtoForTable extends INotificationHistoryDto {
	index: number;
}

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	return {
		sorting: state.notificationHistoryTableSettingsReducer.sorting,
		columnOrder: state.notificationHistoryTableSettingsReducer.columnOrder,
		columnWidths: state.notificationHistoryTableSettingsReducer.columnWidths,
		columns: state.notificationHistoryTableSettingsReducer.columns,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setSorting: (sorting: SortingGeneric<NotificationHistoryDtoKeys>[]) => setSorting(sorting)(dispatch),
	setSize: (size: TableColumnWidthInfoGeneric<NotificationHistoryDtoKeys>[]) => setSize(size)(dispatch),
	setOrder: (order: NotificationHistoryDtoKeys[]) => setOrder(order)(dispatch),
});

interface AppProps {
	isLoading?: boolean;
	data?: INotificationHistoryDto[];
	unitId: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

interface State {
	dateColumns: NotificationHistoryDtoKeys[];
	emailsColumns: NotificationHistoryDtoKeys[];
	typesColumns: NotificationHistoryDtoKeys[];
	timestamp: NotificationHistoryDtoKeys[];
	editHistoryBtn: NotificationHistoryDtoKeys[];
}

const getRowId = (row: INotificationHistoryDto) => {
	return Guid.create().toString();
};

const TableRow = (obj: any) => <VirtualTable.Row {...obj} />;

const TableComponent = ({ ...restProps }) => <VirtualTable.Table {...restProps} className="bi-report-table-striped" />;

const HeaderComponent = ({ ...restProps }) => <VirtualTable.TableHead {...restProps} className="table-header" />;

const GenericTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider {...props} />;

const DateFormatter = (obj: { value: Date }) => {
	return <span>{getDateToString(obj.value, DateFormatterModes.withMinutes)}</span>;
};

const EmailsFormatter = (obj: { value: string[] }) => {
	return <span>{obj.value.join(', ')}</span>;
};

const TypesFormatter = (obj: { value: NotificationEvent[] }) => {
	return (
		<span>
			{obj.value.reduce(
				(a, b) => (!a ? GetNameFromNotificationEvent(b) : `${a}, ${GetNameFromNotificationEvent(b)}`),
				''
			)}
		</span>
	);
};

const getThresholdString = (threshold?: number): string => {
	if (threshold === undefined) return '';
	if (threshold === null) return '';
	return `≥ ${threshold}%`;
};

const GetNameFromNotificationEvent = (value: NotificationEvent): string => {
	let res = '';

	if (value.notificationType?.name === FillLevelNotificationTypeName) {
		res = `${localized('fillLevel')} ${getThresholdString(value.fillLevelSettings?.thresholdPercentage)}`;
	} else if (value.notificationType?.name === AdvancedNotificationName) {
		if (value.advancedNotificationSettings) {
			res = value.advancedNotificationSettings[0]?.notificationMessage ?? '';
		}
	} else if (value.notificationType?.name === CustomMessagesNotificationTypeName) {
		res = value.customMessage ?? `${localizedDynamic('NotificationTypes.CustomMessages')}`;
	} else if (
		value.notificationType?.name === PickupOrderedNotificationTypeName ||
		value.notificationType?.name === PickupOnDateOrderedNotificationTypeName
	) {
		const optionalPickupOnDateText =
			value.notificationType?.name === 'PickupOnDateOrdered' && value.pickUpOnDate
				? `. ${localized('PickUp_Verb')} ${getDateToString(value.pickUpOnDate)}`
				: '';

		if (value.notificationType?.machineType === NotificationMachineType.Baler) {
			res = `${localized('PickupOrderedBaler')}: ${value.pickUpOrderedAmount} ${localized(
				'Bales'
			).toLowerCase()}, ${value.triggeredByUser}${optionalPickupOnDateText}`;
		} else {
			res = `${localized('PickupOrderedCompactor')} ${value.pickUpOrderedAmount}%, ${
				value.triggeredByUser
			}${optionalPickupOnDateText}`;
		}
	} else if (value.notificationType?.name === EmptyDetectionNotificationTypeName) {
		res = `${localized('EmptyDetectionAt')} ${value.fillLevelWhenEmptied}%`;
	} else if (value.notificationType?.name === CustomXtelMessageNotificationTypeName) {
		res = value.customXtelMessage ?? `${localizedDynamic('NotificationTypes.CustomXtelMessages')}`;
	} else if (value.notificationType?.name === OfflineForXHoursNotificationTypeName) {
		res = `${localizedInterpolation('OfflineForXHours', { x: OfflineForXHoursThreshold })}`;
	} else if (value.notificationType?.name === BalesFinishedNotificationTypeName) {
		res = `${localizedDynamic('NotificationTypes.BaleFinished')}`;
	} else if (value.notificationType?.name === BalesAlmostFinishedNotificationTypeName) {
		res = `${localizedDynamic('NotificationTypes.BaleAlmostFinished')}`;
	} else {
		res = getLocalizedUnitStatusError(value.notificationType?.errorId!, value.notificationType?.machineType!);
	}

	return res;
};

const UnfoldAdvancedNotificationMessagesFromData = (
	data: NotificationHistoryDtoForTable[]
): NotificationHistoryDtoForTable[] => {
	for (let d of data) {
		if (!d.notificationEvents) continue;

		let unfoldedNotificationEvents: NotificationEvent[] = [];

		for (let ne of d.notificationEvents) {
			if (!ne || !ne.advancedNotificationSettings || ne.advancedNotificationSettings.length <= 1) continue;
			for (let cMsg of ne.advancedNotificationSettings) {
				let notEvent: NotificationEvent = new NotificationEvent();
				notEvent.init({ ...ne, advancedNotificationSettings: [cMsg] });
				unfoldedNotificationEvents.push(notEvent);
			}
		}
		d.notificationEvents = d.notificationEvents.filter(
			ne => ne.advancedNotificationSettings && ne.advancedNotificationSettings?.length <= 1
		);
		d.notificationEvents = d.notificationEvents.concat(unfoldedNotificationEvents);
	}

	return data;
};

const rootStyle: React.CSSProperties = { height: '100%' };
const Root = (props: Grid.RootProps) => (
	<Grid.Root className="devextreme-react-grid-remove-margin-bottom" {...props} style={rootStyle} />
);

class NotificationHistoryTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			dateColumns: ['createdOn'],
			emailsColumns: ['emails'],
			typesColumns: ['notificationEvents'],
			timestamp: ['stateChangeDateTime'],
			editHistoryBtn: ['editHistoryDto'],
		};
	}

	editHistoryDtoFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
		const currMachine = obj.row as INotificationHistoryDto;

		return <NotificationHistoryTableEdit row={currMachine} {...this.props} />;
	};

	public render() {
		let data: NotificationHistoryDtoForTable[] = [];
		if (this.props.data) {
			data = this.props.data.map((d, index) => {
				return { ...d, index };
			});

			data = UnfoldAdvancedNotificationMessagesFromData(data);
		}

		return (
			<Grid rows={data || []} columns={this.props.columns} getRowId={getRowId} rootComponent={Root}>
				<GenericTypeProvider for={this.state.dateColumns} formatterComponent={DateFormatter} />
				<GenericTypeProvider for={this.state.emailsColumns} formatterComponent={EmailsFormatter} />
				<GenericTypeProvider for={this.state.typesColumns} formatterComponent={TypesFormatter} />
				<GenericTypeProvider for={this.state.timestamp} formatterComponent={DateFormatter} />
				<GenericTypeProvider
					for={this.state.editHistoryBtn}
					formatterComponent={this.editHistoryDtoFormatter}
				/>

				<DragDropProvider />
				<SortingState sorting={this.props.sorting} onSortingChange={this.changeSorting} />
				<IntegratedSorting />

				<VirtualTable
					tableComponent={TableComponent}
					headComponent={HeaderComponent}
					height="auto"
					noDataCellComponent={this.noCell}
					rowComponent={TableRow}
					estimatedRowHeight={ROW_HEIGHT}
				/>
				<TableColumnReordering onOrderChange={this.changeColumnOrder} order={this.props.columnOrder} />
				<TableColumnResizing
					onColumnWidthsChange={this.changeColumnWidths}
					columnWidths={this.props.columnWidths}
				/>
				<TableHeaderRow showSortingControls={true} />
			</Grid>
		);
	}
	private noCell = (rest: Table.NoDataCellProps) => this.NoDataCellComponent(rest, this.props.isLoading);

	private NoDataCellComponent = (restProps: Table.NoDataCellProps, loading = this.props.isLoading): JSX.Element => (
		<BiNoData isLoading={this.props.isLoading} noDataCellProps={restProps} tableId={TableId.Histories} />
	);

	private changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
		this.props.setSize(columnWidths as TableColumnWidthInfoGeneric<NotificationHistoryDtoKeys>[]);
	};

	private changeColumnOrder = (newOrder: string[]) => {
		this.props.setOrder(newOrder as NotificationHistoryDtoKeys[]);
	};

	private changeSorting = (sorting: Sorting[]) => {
		this.props.setSorting(sorting as SortingGeneric<NotificationHistoryDtoKeys>[]);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHistoryTable);
