import {
	FilterDto,
	GroupsClient,
	IFilterDto,
	INotificationAdvancedSettingDto,
	INotificationColumnSelected,
	INotificationDefinitionDto,
	INotificationFillLevelSettingsDto,
	INotificationTypeDto,
	INotificationUserDto,
	NotificationDefinitionClient,
	NotificationDefinitionDto,
	NotificationTypeClient,
	NotificationUnsubscribeClient,
	SelectedLanguage,
} from 'api/api';
import { store } from 'index';
import { Dispatch } from 'redux';
import history from 'state/history';
import { AsyncOperationBuilder, AsyncOperationBuilderWithoutState, handleApiError } from 'utilities/api-helper';
import { isAtLeastSuperAdminFromState } from 'utilities/roles-util';
import {
	machineIdsToDistinctMachineTypeSelectedList,
	notificationMachineIdsFilter,
} from 'view/components/table/notifications/notification-helper';
import { SpecificNotificationLocationState } from '../../../view/components/table/notifications/specific-notification-definition/specific-notification';
import {
	groupsClient,
	notificationDefinitionClient,
	notificationEmailClient,
	notificationTypeClient,
	notificationUnsubscribeClient,
} from '../api-clients';
import { Routes } from '../routes';
import { setSelectedMachines } from '../table-settings/operations';
import {
	AddTemporaryNotificationTypeAction as AddTemporaryNotificationTypesAction,
	AddTemporaryNotificationUserAction,
	DeleteTemporaryNotificationDefinitionAction,
	GetNotificationDefinitionsAction,
	GetNotificationDefinitionsForMachineAction,
	GetNotificationTypesAction,
	GetUsersByGroupId,
	RemoveTemporaryNotificationDefinitionAction,
	RemoveTemporaryNotificationTypeAction as RemoveTemporaryNotificationTypesAction,
	RemoveTemporaryNotificationUserAction,
	SaveTemporaryNotificationDefinitionAction,
	SetFilteredOutAction,
	SetTemporaryNotificationAdvancedSettingsByIdAction,
	SetTemporaryNotificationDefinitionAction,
	SetTemporaryNotificationFillLevelByIdAction,
	SetTemporaryNotificationLanguageAction,
	SetTemporaryNotificationMachineIdsAction,
	SetTemporaryNotificationNameAction,
	SetTemporaryNotificationNotifyByEmailAction,
	SetTemporaryNotificationNotifyByPushAction,
	SetTemporaryNotificationSelectedColumnsAction,
	SetTemporaryNotificationTypeAction,
} from './actions';

export const setTemporaryNotificationDefinition = (id: number, definition?: INotificationDefinitionDto) => {
	return async (dispatch: Dispatch) => {
		if (!definition) {
			definition = await notificationDefinitionClient.getById(id);
		}

		setSelectedMachines(definition.unitIds || [])(dispatch);
		dispatch(SetTemporaryNotificationDefinitionAction({ notDef: definition }));
	};
};

export const getNotificationTypes = () =>
	AsyncOperationBuilder(
		GetNotificationTypesAction,
		apiClient => (apiClient as NotificationTypeClient).get(),
		undefined,
		notificationTypeClient
	);

export const getNotificationDefinitions = (filter: IFilterDto) => {
	let callCounter = store.getState().notificationsReducer.callCounter;

	const filterDto = new FilterDto();
	filterDto.init(filter);

	return AsyncOperationBuilder(
		GetNotificationDefinitionsAction,
		apiClient => (apiClient as NotificationDefinitionClient).getByFilter(filterDto),
		{ filterDto, callCounter: ++callCounter },
		notificationDefinitionClient
	);
};

export async function removeUserFromNotificationDefinitions(unsubcribeParam: string) {
	return await AsyncOperationBuilderWithoutState(
		apiClient => (apiClient as NotificationUnsubscribeClient).deleteUserFromNotification(unsubcribeParam),
		notificationUnsubscribeClient
	);
}

export const removeTemporaryNotificationDefinition = () => {
	return (dispatch: Dispatch) => dispatch(RemoveTemporaryNotificationDefinitionAction());
};

export const navigateToSpecificNotification = (id?: number, isFromNotificationsPage = false) => {
	const locationState: SpecificNotificationLocationState = { isFromNotificationsPage };
	return (dispatch: Dispatch) => {
		dispatch(RemoveTemporaryNotificationDefinitionAction());
		history.push({ pathname: `${Routes.NotificationsEdit}/${id ?? 0}`, state: locationState });
	};
};

export const postNotificationDefinition = (
	notDef: INotificationDefinitionDto,
	selectedMachines: number[],
	checkForWarnings = false
) => {
	if (notDef.unitIds) {
		const isSuperAdmin = isAtLeastSuperAdminFromState(store.getState());

		notDef.unitIds = notificationMachineIdsFilter(selectedMachines);
		let includedMachinetypes = machineIdsToDistinctMachineTypeSelectedList(notDef.unitIds).map(a => a.machineType);

		// Super Admins are allowed to create notification Definitions for units that have never been online => none known controller.
		// And therefore also notification types, even though there are no matching units of same type
		// If user is not both superAdmin AND making a definition including units with no machinetype, we filter out the irrelevant notificationTypes and fillSettings
		if (!(isSuperAdmin && includedMachinetypes.includes(undefined))) {
			notDef.notificationTypes = notDef.notificationTypes?.filter(notType =>
				includedMachinetypes.includes(notType.machineType)
			);
			notDef.fillLevelSettings = notDef.fillLevelSettings?.filter(setting =>
				includedMachinetypes.includes(setting.machineType)
			);
		}
	}

	let definition = new NotificationDefinitionDto();
	definition.init(notDef);

	const getClientOperation = (client: NotificationDefinitionClient) => {
		if (checkForWarnings) return client.postWithValidation(definition);
		else return client.post(definition);
	};
	return AsyncOperationBuilder(
		SaveTemporaryNotificationDefinitionAction,
		apiClient => getClientOperation(apiClient as NotificationDefinitionClient),
		notDef,
		notificationDefinitionClient,
		true
	);
};

export const deleteNotificationDefinition = (notDef: INotificationDefinitionDto) =>
	AsyncOperationBuilder(
		DeleteTemporaryNotificationDefinitionAction,
		apiClient => (apiClient as NotificationDefinitionClient).delete(new NotificationDefinitionDto(notDef)),
		notDef,
		notificationDefinitionClient
	);

export const addTemporaryNotificationTypes = (notType: INotificationTypeDto[]) => {
	return (dispatch: Dispatch) => dispatch(AddTemporaryNotificationTypesAction({ notTypes: notType }));
};

export const removeTemporaryNotificationTypes = (notType: INotificationTypeDto[]) => {
	return (dispatch: Dispatch) => dispatch(RemoveTemporaryNotificationTypesAction({ notType }));
};

export const addTemporaryUser = (user: INotificationUserDto) => {
	return (dispatch: Dispatch) => dispatch(AddTemporaryNotificationUserAction({ user }));
};

export const setTemporaryLanguage = (language: SelectedLanguage) => {
	return (dispatch: Dispatch) => dispatch(SetTemporaryNotificationLanguageAction({ language }));
};

export const removeTemporaryUser = (user: INotificationUserDto) => {
	return (dispatch: Dispatch) => dispatch(RemoveTemporaryNotificationUserAction({ user }));
};

export const setTemporaryNotificationName = (name: string) => {
	return (dispatch: Dispatch) => dispatch(SetTemporaryNotificationNameAction({ name }));
};

export const setTemporaryNotificationMachineIds = (deviceIds: number[]) => {
	let filteredDeviceIds = notificationMachineIdsFilter(deviceIds);

	return (dispatch: Dispatch) => dispatch(SetTemporaryNotificationMachineIdsAction({ deviceIds: filteredDeviceIds }));
};

export const updateFilteredOutMachineIds = (deviceIds: number[]) => {
	let filteredDeviceIds = notificationMachineIdsFilter(deviceIds);
	let filteredOut: number = deviceIds.length - filteredDeviceIds.length;

	return (dispatch: Dispatch) => dispatch(SetFilteredOutAction({ filteredOut: filteredOut }));
};

export const setTemporaryNotificationFillLevelByMachineType = (
	id: number,
	settings: INotificationFillLevelSettingsDto[]
) => {
	return (dispatch: Dispatch) =>
		dispatch(SetTemporaryNotificationFillLevelByIdAction({ id: id, settings: settings }));
};

export const setTemporaryNotificationAdvancedSettingsByMachineType = (
	id: number,
	settings: INotificationAdvancedSettingDto[]
) => {
	return (dispatch: Dispatch) =>
		dispatch(SetTemporaryNotificationAdvancedSettingsByIdAction({ id: id, settings: settings }));
};

export const setTemporaryNotificationSelectedColumns = (columns: INotificationColumnSelected[]) => {
	return (dispatch: Dispatch) => dispatch(SetTemporaryNotificationSelectedColumnsAction({ columns: columns }));
};

export async function sendExampleNotificationEmail(emailAddress: string): Promise<boolean> {
	try {
		await notificationEmailClient.sendExampleEmail(emailAddress);
		return true;
	} catch (error) {
		handleApiError(error);
		return false;
	}
}
export async function getNotificationDefinitionsForMachine(serialNumber: string) {
	return AsyncOperationBuilder(
		GetNotificationDefinitionsForMachineAction,
		apiClient => (apiClient as NotificationDefinitionClient).getBySerialNumber(serialNumber),
		{ serialNumber },
		notificationDefinitionClient
	);
}

export const setTemporaryNotificationTypes = (notType: INotificationTypeDto[]) => {
	return (dispatch: Dispatch) => dispatch(SetTemporaryNotificationTypeAction({ notTypes: notType }));
};

export const setTemporaryNotifyByEmail = (notifyType: boolean) => {
	return (dispatch: Dispatch) => dispatch(SetTemporaryNotificationNotifyByEmailAction({ notifyType }));
};

export const setTemporaryNotifyByPush = (notifyType: boolean) => {
	return (dispatch: Dispatch) => dispatch(SetTemporaryNotificationNotifyByPushAction({ notifyType }));
};

export async function getUsersByGroupId(groupId: number) {
	return AsyncOperationBuilder(
		GetUsersByGroupId,
		apiClient => (apiClient as GroupsClient).getUsersByGroupId(groupId),
		groupId,
		groupsClient
	);
}
