
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export interface SimpleColumn {
    field: string;
    header: string;
    widthPercentage?: number;
}

interface Props {
    columns: SimpleColumn[];
    rows: Record<string, string>[];
    sortingKey: string;
}

// We need to specify all styles here in order to support passing individual widths of columns
const defaultStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}

const bodyTemplate = (data: any, props: any) => {
    return (
        <React.Fragment>
            <span title={data[props.field]}>
                {data[props.field]}
            </span>
        </React.Fragment>
    );
}

export const TileTable = ({columns, rows, sortingKey}: Props) => {
    let filteredRows = rows
            .sort((a, b) => parseInt(b[sortingKey]) - parseInt(a[sortingKey]))
            .slice(0, 5);

    const dynamicColumns = columns.map((col,i) => {
        // Add width to style if it is specified
        const style = col.widthPercentage !== undefined ? {...defaultStyle, width: `${col.widthPercentage}%`} : defaultStyle;

        return <Column key={i} field={col.field} header={col.header} style={style} body={bodyTemplate}/>;
    });

    return (
        <DataTable value={filteredRows}>
            {dynamicColumns}
        </DataTable>
    );
}