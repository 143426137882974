import { localized } from 'state/i18n';
import { isNumeric } from 'utilities/number-helpers';
import BiInputText from 'view/shared/components/bi-input-text/bi-input-text';
import React, { useCallback, FormEvent, FC } from 'react';

interface Props {
	onChange: (value: string) => void;
	value: string | undefined;
}

const ComparisonValue: FC<Props> = props => {
	const { onChange, value } = props;

	const onValueChange = useCallback(
		(event: FormEvent<HTMLInputElement>) => {
			const val = event.currentTarget.value.replace(',', '.');
			if (val === '' || val === '-' || isNumeric(val)) {
				onChange(val);
			}
		},
		[onChange]
	);

	return <BiInputText className="advanced-notification-setting-value" placeholder={localized('Value')} value={value ?? ''} onChange={onValueChange} />;
};
	
export default ComparisonValue;
