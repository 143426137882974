import React, { FC } from "react";
import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import "./bi-input-number.scss";


interface Props extends InputNumberProps {
    onValueChange: (newValue: number) => void;
}
const BiInputNumber: FC<Props> = React.memo((props: Props) => {


    const onValueChange = (e: { originalEvent: Event, value: any, target: { name: string, id: string, value: any } }): void => {
        props.onValueChange(e.value);
    }

    return (
        <InputNumber
            onChange={onValueChange}
            min={0}
            useGrouping={false}
            {...props}
            className={"bi-input-number " + props.className}
        />
    )
});


export default BiInputNumber;