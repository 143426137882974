import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IGetReleaseNoteDto } from 'api/api';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { getUsersSuperAdminGroupsChildHierarchy } from 'state/ducks/groups/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { IsBrandBramidan } from 'utilities/brand-utils';
import { getEmailFromToken } from 'utilities/token-util';
import { tutorialVimeoId } from 'utilities/tutorial-constants';
import { cardGeneralClassNames } from 'view/components/table/details/constants';
import VideoButtonDialog from 'view/components/video/video-button-dialog';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import WhiteCard, { AvailableElementsForStyling } from 'view/shared/components/cards/white-card';
import ResponsiveSpacingWrapper from 'view/shared/components/responsive-wrappers/responsive-spacing-wrapper';
import './adminstration.scss';
import ReleaseNoteDialog from './cards//release-notes/release-note-dialog';
import CreateGroup from './cards/create-group/create-group';
import CreateUnit from './cards/create-unit/create-unit';
import { DialogServiceCodeNewUnit } from './cards/create-unit/dialog-service-code-new-unit';
import Debtors from './cards/debtors/debtors';
import { DuplicateIotHubUnitsDecommisison } from './cards/duplicated-iothub-units-decommission/duplicate-iothub-units-decommission';
import EditGroup from './cards/edit-group/edit-group';
import ReleaseNotes from './cards/release-notes/release-notes';
import { TrackerUnitOverview } from './cards/tracker-unit-overview/tracker-unit-overview';
import { UnitManufacturerEditor } from './cards/unit-manufacturer-editor/unit-manufacturer-editor';
import { UnitModelEditor } from './cards/unit-model-editor/unit-model-editor';
import { AdminServiceVideos } from './cards/upload-service-video/admin-service-videos';
import { UploadServiceVideoDialog } from './cards/upload-service-video/upload-service-video-dialog';
import UserManager from './cards/user-manager/user-manager';
import { WeightUnitOverview } from './cards/weight-unit-overview/weight-unit-overview';

export const ProfileCardDoubleContainerLength: AvailableElementsForStyling = {
	classNameTitle: '',
	classNameSubtitle: '',
	classNameContainer: `${cardGeneralClassNames.classNameContainer} flex-fill-remaining-space 
      card-width-1100-and-margin-spacing flex-direction-column`,
};

const mapStateToProps = (state: AppState) => {
	const usersSuperAdminGroups = state.groupsReducer.superAdminGroups.find(g => g.id === getEmailFromToken());
	let isSuperAdmin = false;

	if (usersSuperAdminGroups && usersSuperAdminGroups.data && usersSuperAdminGroups.data.length) {
		isSuperAdmin = true;
	}

	let isServiceToolCode = false;
	const usersServiceToolCodeGroups = state.userPermissionReducer.UserGroupPermissions.filter(
		ugp => ugp.isServiceToolCode
	).map(ugp => ugp.isServiceToolCode);

	if (usersServiceToolCodeGroups.find(s => s) || isSuperAdmin) {
		isServiceToolCode = true;
	}

	return {
		isSuperAdmin,
		isServiceToolCode,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getUsersSuperAdminGroupsChildHierarchy: async () => (await getUsersSuperAdminGroupsChildHierarchy())(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
	showNewReleaseNoteDialog: boolean;
	showServiceTechnicianVideoDialog: boolean;
	generateServiceCodeDialogVisible: boolean;
};

class AdministrationPage extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			showNewReleaseNoteDialog: false,
			showServiceTechnicianVideoDialog: false,
			generateServiceCodeDialogVisible: false,
		};
		this.props.getUsersSuperAdminGroupsChildHierarchy();
	}

	handleSaveNewNote = (note: IGetReleaseNoteDto) => {
		this.setState({ showNewReleaseNoteDialog: false });
	};

	handleShowEditDialog = () => {
		this.setState({ showNewReleaseNoteDialog: true });
	};
	handleHideEditDialog = () => {
		this.setState({ showNewReleaseNoteDialog: false });
	};

	handleHideServiceTechnicianVideoDialog = () => {
		this.setState({ showServiceTechnicianVideoDialog: false });
	};

	handleShowServiceTechnicianVideoDialog = () => {
		this.setState({ showServiceTechnicianVideoDialog: true });
	};

	handleServiceToolCodePressed = () => {
		this.setState({ generateServiceCodeDialogVisible: true });
	};
	handleServiceToolCodeClose = () => this.setState({ generateServiceCodeDialogVisible: false });

	public render() {
		let unitsTopRight;
		if (this.props.isServiceToolCode) {
			unitsTopRight = (
				<BiButton
					onClick={this.handleServiceToolCodePressed}
					colorTheme="org-primary-grey"
					containerTheme="normal-button-corners"
					containerClassName="debtor-item-button-container"
				>
					<span className="text-bold">{localized('GenerateServiceToolCode')}</span>
				</BiButton>
			);
		}

		return (
			<BiTitle title={localized('Administration')}>
				<div>
					<ResponsiveSpacingWrapper>
						<div className="margin-horizontal-page-normal">
							<div className="flex-direction-row margin-top-40 margin-bottom-40 flex-space-between-row">
								<h1 className="text-bold">{localized('Administration')}</h1>
							</div>

							<div className="flex-direction-row flex-wrap flex-start-column">
								<div className="flex-direction-column">
									<div className="flex-direction-column">
										<div className="flex-direction-row">
											<WhiteCard
												title={localized('UserManagement')}
												subtitle={localized('UserManagementIntro')}
												classNameContainer={`profile-notifications ${ProfileCardDoubleContainerLength.classNameContainer}`}
												topRightElement={
													<VideoButtonDialog
														buttonLabel={localized('Tutorial')}
														dialogTitle={localized(tutorialVimeoId.userManagement.title)}
														vimeoId={tutorialVimeoId.userManagement.id}
													/>
												}
											>
												<UserManager isAdmin={true} />
											</WhiteCard>
										</div>
									</div>
								</div>
								{this.props.isSuperAdmin && (
									<div className="flex-direction-row">
										<WhiteCard
											title={localized('DebtorManagement')}
											subtitle={localized('DebtorManagementIntro')}
											classNameContainer={`${ProfileCardDoubleContainerLength.classNameContainer}`}
										>
											<Debtors />
										</WhiteCard>
									</div>
								)}

								{this.props.isSuperAdmin && (
									<div className="flex-direction-row">
										<WhiteCard
											title={localized('ReleaseNote')}
											subtitle={localized('ReleaseNoteIntro')}
											classNameContainer={`${ProfileCardDoubleContainerLength.classNameContainer}`}
											topRightElement={
												<BiButton
													onClick={this.handleShowEditDialog}
													colorTheme="org-primary-grey"
													containerTheme="normal-button-corners"
													containerClassName="debtor-item-button-container"
												>
													<span className="text-bold">{localized('AddNew')}</span>
												</BiButton>
											}
										>
											<ReleaseNotes />
										</WhiteCard>
									</div>
								)}
								<div className="flex-direction-row">
									<WhiteCard
										title={localized('groups')}
										subtitle={localized('AdminGroupsIntro')}
										classNameContainer={`${ProfileCardDoubleContainerLength.classNameContainer}`}
									>
										<CreateGroup />
										<EditGroup />
									</WhiteCard>
								</div>
								<div className="flex-direction-row">
									<WhiteCard
										title={localized('Units')}
										subtitle={localized('AdminManageUnitsIntro')}
										classNameContainer={`${ProfileCardDoubleContainerLength.classNameContainer}`}
										topRightElement={unitsTopRight}
									>
										<CreateUnit />
									</WhiteCard>
								</div>
								{this.props.isSuperAdmin && (
									<div className="flex-direction-row">
										<WhiteCard
											title={localized('DuplicatedIotHubUnits')}
											subtitle={localized('DuplicatedIotHubUnitsIntro')}
											classNameContainer={`${cardGeneralClassNames.classNameContainer} flex-fill-remaining-space flex-direction-column`}
										>
											<DuplicateIotHubUnitsDecommisison />
										</WhiteCard>
									</div>
								)}

								{this.props.isSuperAdmin && (
									<div className="flex-direction-row">
										<WhiteCard
											title={localized('UploadServiceTechnicianVideo')}
											subtitle={localized('UploadServiceTechnicianVideoDescription')}
											classNameContainer={`${ProfileCardDoubleContainerLength.classNameContainer}`}
											topRightElement={
												<BiButton
													onClick={this.handleShowServiceTechnicianVideoDialog}
													colorTheme="org-primary-grey"
													containerTheme="normal-button-corners"
													containerClassName="debtor-item-button-container"
												>
													<span className="text-bold">{localized('UploadVideo')}</span>
												</BiButton>
											}
										>
											<AdminServiceVideos />
										</WhiteCard>
									</div>
								)}
								{IsBrandBramidan && (
									<div className="flex-direction-row">
										<WhiteCard
											title={localized('UnitWithWeight')}
											subtitle={localized('AdminWeightUnitIntro')}
											classNameContainer={`${cardGeneralClassNames.classNameContainer} flex-fill-remaining-space card-width-big flex-direction-column`}
										>
											<WeightUnitOverview />
										</WhiteCard>
									</div>
								)}
								{this.props.isSuperAdmin && (
									<div className="flex-direction-row">
										<WhiteCard
											title={localized('manufacturer')}
											subtitle={localized('Tooltip_manufacturer')}
											classNameContainer={`profile-notifications ${ProfileCardDoubleContainerLength.classNameContainer}`}
										>
											<UnitManufacturerEditor />
										</WhiteCard>
									</div>
								)}
								{this.props.isSuperAdmin && (
									<div className="flex-direction-row">
										<WhiteCard
											title={localized('unitModel')}
											subtitle={localized('Tooltip_unitModel')}
											classNameContainer={`profile-notifications ${ProfileCardDoubleContainerLength.classNameContainer}`}
										>
											<UnitModelEditor />
										</WhiteCard>
									</div>
								)}
								<div className="flex-direction-row">
									<WhiteCard
										title={localized('UnitWithTrackers')}
										subtitle={localized('AdminTrackerUnitIntro')}
										classNameContainer={`${cardGeneralClassNames.classNameContainer} flex-fill-remaining-space card-width-big flex-direction-column`}
									>
										<TrackerUnitOverview />
									</WhiteCard>
								</div>
							</div>
						</div>
					</ResponsiveSpacingWrapper>
					<ReleaseNoteDialog
						onSave={this.handleSaveNewNote}
						visible={this.state.showNewReleaseNoteDialog}
						onCancel={this.handleHideEditDialog}
					/>
					<UploadServiceVideoDialog
						showDialog={this.state.showServiceTechnicianVideoDialog}
						onHide={this.handleHideServiceTechnicianVideoDialog}
					/>
					{this.props.isServiceToolCode && (
						<DialogServiceCodeNewUnit
							visible={this.state.generateServiceCodeDialogVisible}
							onHide={this.handleServiceToolCodeClose}
						/>
					)}
				</div>
			</BiTitle>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, AdministrationPage, 'Administration'));
