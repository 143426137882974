import { ICreateGroupsDto } from 'api/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { createChildGroup } from 'state/ducks/groups/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { mapTreeItemsToTreeSelectItems } from 'utilities/array-to-tree';
import { getEmailFromToken } from 'utilities/token-util';
import BiTreeSelect from 'view/components/bi-tree-select/bi-tree-select';
import { TreeSelectItem } from 'view/components/bi-tree-select/tree-select-item';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import BiLabelContentItem from 'view/shared/components/bi-label-content/bi-label-content-item';
import './create-group.scss';

const mapStateToProps = (state: AppState) => {
	const groups = state.groupsReducer.adminGroups.find(g => g.id === getEmailFromToken());
	let groupTreeSelect: TreeSelectItem[] = [];

	if (groups) {
		groupTreeSelect = mapTreeItemsToTreeSelectItems(groups.data);
	}

	return {
		groupTreeSelect: groupTreeSelect,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	createChildGroup: async (createGroupDto: ICreateGroupsDto) => (await createChildGroup(createGroupDto))(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface State {
	selectedGroupId: number;
	newGroupName: string;
	isSavingData: boolean;
}

class CreateGroup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedGroupId: 0,
			newGroupName: '',
			isSavingData: false,
		};
	}

	public render() {
		if (!this.props.groupTreeSelect.length) {
			return null;
		}

		return (
			<div id="create-group" className="margin-bottom-20px">
				<div className="flex-direction-column">
					<div className="margin-bottom-5px">
						<h5 className="text-bold">{localized('CreateGroup')}</h5>
					</div>
					<BiLabelContent containerClassName="flex-fill-width">
						{this.getHeaderLabels()}
						{this.getGroupRows()}
					</BiLabelContent>
				</div>
			</div>
		);
	}

	private setNewGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newGroupName: e.target.value });
	};

	private getGroupRows = () => {
		let parentGroupTree = (
			<BiTreeSelect
				onCheckChanged={this.groupsChanged}
				nodes={this.props.groupTreeSelect}
				selectedItems={
					this.state.selectedGroupId && this.state.selectedGroupId !== 0 ? [this.state.selectedGroupId] : []
				}
				mustHaveSelection={true}
			/>
		);

		let childGroupName = (
			<div className="flex-space-between-row content-container-groups ">
				<InputText
					placeholder="Group name"
					className="input-box-bramidan"
					onBlur={this.newGroupNameOnBlur}
					value={this.state.newGroupName}
					onChange={this.setNewGroupName}
				/>
				<Button className="p-button-custom p-button-secondary" label={localized('Save')} onClick={this.save} />
				<div></div>
			</div>
		);

		return (
			<BiLabelContentItem
				containerClassName="margin-bottom-10px"
				defaultClassNames="bi-label-content-item-lg"
				label={parentGroupTree}
				content={childGroupName}
			/>
		);
	};

	private newGroupNameOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
		this.setState({ newGroupName: event.currentTarget.value });
	};

	private groupsChanged = async (groupIds: number[]) => {
		let groupId = groupIds && groupIds.length ? groupIds[0] : 0;
		this.setState({ selectedGroupId: groupId });
	};

	private save = async () => {
		if (this.state.isSavingData || !this.validate()) {
			return;
		}
		this.setState({ isSavingData: true });
		try {
			await this.props.createChildGroup({
				groupName: this.state.newGroupName,
				parentGroupId: this.state.selectedGroupId,
			});
			notificationService.showSuccessMessage(localized('Saved'));
			this.setState({
				newGroupName: '',
				selectedGroupId: 0,
			});
		} finally {
			this.setState({ isSavingData: false });
		}
	};

	private validate = () => {
		if (!this.state.newGroupName || !this.state.selectedGroupId) {
			notificationService.showWarningMessage(localized('InputFieldsNotFilledOut'));
			return false;
		}

		return true;
	};

	private getHeaderLabels = () => {
		let parentGroupTree = (
			<div className="content-container-container">
				<label>{localized('ParentGroup')}</label>
			</div>
		);

		let content = (
			<div className="flex-space-between-row content-container-groups">
				<label>{localized('NameOfNewChildGroup')}</label>
				<label className="p-button-custom p-button-secondary" style={{ opacity: 0 }}>
					{localized('Save')}
				</label>
			</div>
		);

		return (
			<BiLabelContentItem
				defaultClassNames="bi-label-content-item-lg"
				label={parentGroupTree}
				content={content}
				showBoxShadow={false}
			/>
		);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
