import React from "react";
import { IBalesProducedTileDto } from "api/api";
import { localized } from "state/i18n";
import { OneNumberTileTemplate } from "./templates/one-number-tile-template";

interface PropsFromParent {
	tile: IBalesProducedTileDto;
}

type Props = PropsFromParent;

export class BalesProducedTile extends React.PureComponent<Props> {
	public render() {
        const balesProduced = this.props.tile.balesProduced?.amount ?? 0;

		return (
			<OneNumberTileTemplate
                number={balesProduced}
                subtitle={localized("BalesProducedSubtitle")}
            />
		);
	}
}