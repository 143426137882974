import { FileResponse, ICreateUnitDto, IUnitsDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('machines');

export const ClearMachines = actionCreator(constants.CLEAR_MACHINES);
export const UpdateMachinesOwnerGroupNameAction = actionCreator<{ MachineIds: number[]; groupName: string }>(
	constants.UPDATE_MACHINES_OWNER_GROUP_NAME
);
export const UpdateMachinesOwnerGroupIdAction = actionCreator.async<
	{ MachineIds: number[]; destinationGroupId: number },
	FileResponse | null
>(constants.UPDATE_MACHINES_OWNER_GROUP_ID);
export const GetMachinesAction = actionCreator.async<{ callCounter: number; skipInput: number }, IUnitsDto[]>(
	constants.GET_MACHINES
);
export const GetMachineCountAction = actionCreator.async<{ callCounter: number }, number>(constants.GET_MACHINE_COUNT);
export const DeleteMachineAction = actionCreator.async<{ machineId: number }, void>(constants.DELETE_MACHINE);
export const SetSkipStateAction = actionCreator<{ skip: number }>(constants.SET_SKIP_STATE);
export const SetLoadingStateAction = actionCreator<{ loadingState: boolean }>(constants.SET_LOADING_STATE);
export const SetMachinesAction = actionCreator<{ machines: IUnitsDto[]; skip: number }>(constants.SET_MACHINES);
export const RenewTableKeyAction = actionCreator<{ tableKey: string }>(constants.RENEW_TABLE_KEY);
export const CreateMachineAction = actionCreator.async<ICreateUnitDto, ICreateUnitDto>(constants.CREATE_MACHINE);
