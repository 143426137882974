import React, { CSSProperties } from 'react';
import './white-card.scss';

export type SpacingBetweenTitlesAndContentInEm = 0 | '0-5' | 1 | 2 | 3 | 4 | 5;

export interface AvailableElementsForStyling {
	classNameTitle?: string;
	classNameSubtitle?: string;
	classNameContainer?: string;
	styleTitle?: CSSProperties;
	styleSubtitle?: CSSProperties;
	styleContainer?: CSSProperties;
}

interface AppProps extends AvailableElementsForStyling {
	title?: string;
	subtitle?: string;
	icon?: JSX.Element;
	spaceBetweenTitlesAndContentInEm?: SpacingBetweenTitlesAndContentInEm;
	topRightElement?: JSX.Element;
	disablePadding?: boolean;
	contentBelowHeaders?: React.ReactNode;
}

type Props = AppProps;

type State = {
	defaultClassNames: {
		title: string;
		subtitle: string;
		container: string;
	};
};

export default class WhiteCard extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			defaultClassNames: {
				title: 'text-bold margin-bottom-0',
				subtitle: 'text-bold',
				container: `${this.props.disablePadding ? '' : 'card-container-padded'} card-width bg-color-white`,
			},
		};
	}
	public render() {
		const {
			title,
			subtitle,
			icon,
			spaceBetweenTitlesAndContentInEm: spacingTitles,
			classNameTitle,
			classNameSubtitle,
			classNameContainer,
			styleTitle,
			styleSubtitle,
			styleContainer,
			contentBelowHeaders: contentBelowSubtitle,
		} = this.props;

		const titleAndSubtitle = (
			<div className="flex-fill-width flex-center-column flex-wrap">
				<div className="flex-1 flex-direction-column">
					{title && (
						<h3
							className={`${classNameTitle || ''} ${this.state.defaultClassNames.title}`}
							style={styleTitle}
						>
							{title}
						</h3>
					)}
					{subtitle && (
						<span
							className={`${classNameSubtitle || ''} ${this.state.defaultClassNames.subtitle}`}
							style={styleSubtitle}
						>
							{subtitle}
						</span>
					)}
				</div>
				<div className="text-right">{this.props.topRightElement}</div>
			</div>
		);

		const titleAndSubtitleContainer =
			title || subtitle ? (
				<div
					className={`margin-bottom-${spacingTitles || 1}em ${
						this.props.disablePadding ? 'subtitle-title-padded' : ''
					}`}
				>
					<div className="flex-direction-row flex-space-between-row">
						{titleAndSubtitle}
						{icon && <div className="margin-left-30px">{icon}</div>}
					</div>
					{contentBelowSubtitle}
				</div>
			) : null;

		return (
			<div
				className={`${classNameContainer || ''} ${this.state.defaultClassNames.container}`}
				style={styleContainer}
			>
				{titleAndSubtitleContainer}
				{this.props.children}
			</div>
		);
	}
}
