import { IBaleStorageDto, IUnitDetailsDto } from "api/api";
import { IdDataTuple } from "utilities/types-util";

// Used to avoid passing undefined to baleStorageBuilder-function for unknown parameters
interface BaleStorageBuilderParams {
    storageSize?: number;
    balesOnSite?: number;
    automaticReset?: boolean;
    unitIds?: number[];
}

export const baleStorageBuilder = (
    machineData: IUnitDetailsDto,
    params: BaleStorageBuilderParams): IBaleStorageDto => {

    // DeviceIds from param (new setting)
    let unitIds: number[] | undefined = params.unitIds;

    // If non deviceIds take previous deviceIds
    if (unitIds === undefined || unitIds.length === 0)
        unitIds = machineData.baleStorageDevices;

    // If non unitIds (meaning this is a first time setup) take own Id in list
    if (unitIds === undefined || unitIds.length === 0)
        unitIds = [machineData.id];

    return {
        baleStorageId: machineData.baleStorageId ?? 0,
        storageSize: params.storageSize ?? machineData.baleStorageSize ?? 0,
        balesOnSite: params.balesOnSite ?? machineData.balesOnSite ?? 0,
        calculateAutomatically: params.automaticReset ?? machineData.automaticBaleCountReset,
        unitIds,
    }
}


export const hasValidBaleStorage = (input: IdDataTuple<IUnitDetailsDto, string>) => {
    // If baleStorageId or automaticBaleCountReset are null/undefined => invalid
    if (!input.data.baleStorageId) return false;
    if (input.data.automaticBaleCountReset === null || input.data.automaticBaleCountReset === undefined) return false;

    return true;
}