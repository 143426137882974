import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { ServiceVideoState } from './types';

export const initialState: ServiceVideoState = {
	mappedVideos: [],
	isLoading: false,
	videos: [],
};

export function serviceVideoReducer(state: ServiceVideoState = initialState, action: Action): ServiceVideoState {
	if (isType(action, actions.getServiceVideosAction.started)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (isType(action, actions.getServiceVideosAction.done)) {
		return {
			...state,
			isLoading: false,
			mappedVideos: [
				...state.mappedVideos,
				{ name: action.payload.params, serviceVideos: action.payload.result },
			],
		};
	}

	if (isType(action, actions.createServiceVideoAction.started)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (isType(action, actions.createServiceVideoAction.done)) {
		return {
			...state,
			isLoading: false,
			videos: [...state.videos, action.payload.result],
		};
	}

	if (isType(action, actions.getAllServiceVideosAction.started)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (isType(action, actions.getAllServiceVideosAction.done)) {
		return {
			...state,
			isLoading: false,
			videos: action.payload.result,
		};
	}

	if (isType(action, actions.deleteServiceVideosAction.started)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (isType(action, actions.deleteServiceVideosAction.done)) {
		return {
			...state,
			isLoading: false,
			videos: state.videos.filter(v => v.id !== action.payload.params.id),
		};
	}
	return state;
}
