import { IServiceVideoDto } from 'api/api';
import { SelectItem } from 'primereact/components/selectitem/SelectItem';
import { InputText } from 'primereact/inputtext';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createServiceVideoOperation } from 'state/ducks/service-video/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { ConfirmDialog } from 'view/shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';
import BiMultiselect, { OnChangeEvent } from '../../../../components/bi-multiselect/bi-multiselect';

interface Props {
	showDialog: boolean;
	onHide: () => void;
}

export const UploadServiceVideoDialog: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();

	const machineTypeOptions = useSelector(
		(state: AppState) => state.filterSearchReducer.filterOptions.machineTypeOptions
	);
	const machineGroupOptions = useSelector(
		(state: AppState) => state.filterSearchReducer.filterOptions.machineGroupOptions
	);

	const [selectedGroupOptions, setSelectedGroupOptions] = useState<SelectItem[]>([]);
	const [selectedMachineTypeOptions, setSelectedMachineTypeOptions] = useState<SelectItem[]>([]);
	const [videoId, setVideoId] = useState('');
	const [title, setTitle] = useState('');
	const [showSpinner, setShowSpinner] = useState(false);

	const disableSave = (): boolean => {
		if (selectedMachineTypeOptions.length === 0) return true;
		if (isNullOrWhitespace(title)) return true;
		if (isNullOrWhitespace(videoId)) return true;

		return false;
	};

	const onSave = async () => {
		setShowSpinner(true);
		const serviceVideo: IServiceVideoDto = {
			id: 0,
			title,
			vimeoVideoId: videoId,
			machineTypes: selectedMachineTypeOptions
				.map(mto => mto['key'])
				.filter((mt: string | undefined): mt is string => !!mt),
		};
		(await createServiceVideoOperation(serviceVideo))(dispatch);

		setShowSpinner(false);
		props.onHide();
	};
	const filterUnitModels = (mt: SelectItem) =>
		selectedGroupOptions && selectedGroupOptions.length > 0 ? selectedGroupOptions.includes(mt.value.value) : true;

	const onVideoIdChange = (e: React.FormEvent<HTMLInputElement>) => {
		setVideoId(e.currentTarget.value);
	};

	const onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
		setTitle(e.currentTarget.value);
	};

	const handleClickSelectedGroup = (e: OnChangeEvent) => {
		setSelectedGroupOptions(e.value);
		setSelectedMachineTypeOptions(selectedMachineTypeOptions.filter(mt => e.value.includes(mt.value)));
	};

	const handleClickMachineType = (e: OnChangeEvent) => setSelectedMachineTypeOptions(e.value);

	return (
		<ConfirmDialog
			header={localized('UploadServiceTechnicianVideo')}
			onConfirm={onSave}
			onCancel={props.onHide}
			confirmMessage={localized('Save')}
			confirmColor="org-primary"
			visible={props.showDialog}
			onHide={props.onHide}
			showConfirmSpinner={showSpinner}
			disabled={disableSave()}
			className="service-video-dialog"
		>
			<h5 className="margin-vertical-20px">{localized('SetVideoUnitsByFilter')}</h5>
			<div className="flex-direction-row margin-bottom-50">
				<BiMultiselect
					placeholder={localized('unitType')}
					options={machineGroupOptions}
					selectedItems={selectedGroupOptions}
					onChange={handleClickSelectedGroup}
					filter={true}
				/>
				<BiMultiselect
					placeholder={localized('unitModel')}
					options={machineTypeOptions.filter(filterUnitModels)}
					selectedItems={selectedMachineTypeOptions}
					onChange={handleClickMachineType}
					filter={true}
				/>
			</div>

			<h5>{localized('Title')}</h5>
			<InputText className="input-box-bramidan" value={title} onChange={onTitleChange} />

			<h5 className="margin-top-30px">{localized('SetVideoId')}</h5>
			<InputText className="input-box-bramidan margin-bottom-30px" value={videoId} onChange={onVideoIdChange} />
		</ConfirmDialog>
	);
});
