import { IGroupsDto } from 'api/api';
import { TreeSelectItem } from 'view/components/bi-tree-select/tree-select-item';

// Creator: philipstanislaus (2019)
// Source: https://github.com/philipstanislaus/performant-array-to-tree
// Modified to include items with parents not included in the array as root elements.

export interface Item {
	id?: number;
	parentId?: string | null;
	[key: string]: any;
}

export interface TreeItem {
	id?: number;
	parentId?: string | null;
	[key: string]: Item | any;
	children: TreeItem[] | undefined;
}

export interface Config {
	id: string;
	parentId: string;
	dataField: string | null;
}

const defaultConfig: Config = {
	id: 'id',
	parentId: 'parentId',
	dataField: 'data',
};

/**
 * Unflattens an array to a tree with runtime O(n).
 * If non-root parents are to be included, then the runtime becomes O(n^2).
 * @param items A flat array.
 * @param config Optional configurations with the following props:
 *
 * `id`: key of the id field of the item. Default: "id"
 *
 * `parentId`: key of the parent's id field of the item. Default: "parentId"
 *
 * `dataField`: key which will contain all properties/data of the origina items. Set to null if you don't want a container. Default: "data"
 * @param includeNonRootParents Include items with parents not included in the array as root elements. Default is 'true'.
 * @link Original code: https://github.com/philipstanislaus/performant-array-to-tree
 */

function arrayToTreeModified(
	items: Item[],
	config: Partial<Config> = {},
	includeNonRootParents: boolean = true
): TreeItem[] {
	const conf: Config = { ...defaultConfig, ...config };

	// the resulting unflattened tree
	const rootItems: TreeItem[] = [];

	// stores all already processed items with ther ids as key so we can easily look them up
	const lookup: { [id: string]: TreeItem } = {};

	// idea of this loop:
	// whenever an item has a parent, but the parent is not yet in the lookup object, we store a preliminary parent
	// in the lookup object and fill it with the data of the parent later
	// if an item has no parentId, add it as a root element to rootItems
	for (const item of items) {
		const itemId = item[conf.id];
		const parentId = item[conf.parentId];

		// look whether item already exists in the lookup table
		if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
			// item is not yet there, so add a preliminary item (its data will be added later)
			lookup[itemId] = { children: undefined };
		}

		// add the current item's data to the item in the lookup table
		if (conf.dataField) {
			lookup[itemId][conf.dataField] = item;
		} else {
			lookup[itemId] = { ...item, children: lookup[itemId].children };
		}

		const treeItem = lookup[itemId];

		if (parentId === null) {
			// is a root item
			rootItems.push(treeItem);
		} else {
			// has a parent

			// look whether the parent already exists in the lookup table
			if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
				// parent is not yet there, so add a preliminary parent (its data will be added later)
				lookup[parentId] = { children: undefined };
			}

			// add the current item to the parent
			const obj = lookup[parentId];
			obj.children === undefined ? (obj.children = [treeItem]) : obj.children.push(treeItem);
		}
	}

	if (includeNonRootParents) {
		const lookupValues = Object.values(lookup);
		// The remaining items either have a parent not in the list or are preliminary parents.
		// Filter away the preliminary parents and any items which already appear as children of other items.
		const lookupFiltered: TreeItem[] = [];
		lookupValues.forEach(lookupItem => {
			if (!lookupItem.id) {
				return;
			}

			const doesItemExistAsChildInSomeOtherItem = lookupValues.some(lookupItemSibling => {
				// Enforce siblings only by ignoring the current lookup item and preliminary parents (no 'id' prop)
				if (lookupItemSibling.id === lookupItem.id || !lookupItemSibling.id) {
					return false;
				}

				const childIndex = lookupItemSibling.children?.findIndex(child => child.id === lookupItem.id);
				return childIndex !== undefined && childIndex >= 0;
			});

			if (!doesItemExistAsChildInSomeOtherItem) {
				lookupFiltered.push(lookupItem);
			}
		});

		lookupFiltered.forEach(el => {
			// Ignore items already added as roots.
			if (rootItems.some(rootItem => rootItem.id === el.id)) {
				return;
			}

			rootItems.push(el);
		});
	}

	return rootItems;
}

const groupsDtoConfig = {
	dataField: null,
	id: 'id',
	parentId: 'parentGroupId',
};

export const PropNameGroupName = 'groupName';

const mapTreeItemToTreeSelectItem = (item: TreeItem): TreeSelectItem => {
	return {
		label: item[PropNameGroupName],
		value: item.id,
		children: item.children?.map(mapTreeItemToTreeSelectItem),
	};
};

export const mapTreeItemsToTreeSelectItems = (items: IGroupsDto[]): TreeSelectItem[] => {
	const groupsBreadcrumb = arrayToTreeModified(items, groupsDtoConfig).map(item => {
		const selectItem: TreeSelectItem = mapTreeItemToTreeSelectItem(item);
		return selectItem;
	});

	return groupsBreadcrumb;
};

type SortingOrder = 'root-to-child' | 'child-to-root';

const getParentItem = <T extends Item>(
	items: T[],
	nextParentId: number | null | undefined,
	conf: Config,
	currentId: string
): T | undefined => {
	return items.find(i => i.id === nextParentId && i[conf.id] !== currentId);
};

/**
 *
 * @param items List of items. Order not necessary.
 * @param childId Id of the starting item.
 * @param config Configurations like the name of the parent id prop. See {@link arrayToTreeModified}.
 * @param order Order of the resulting list.
 */
const generateOrderedParentTreeFromNode = <T extends Item>(
	items: T[],
	childId?: number | null,
	config: Partial<Config> = groupsDtoConfig,
	order: SortingOrder = 'root-to-child'
): T[] => {
	const parentTree: T[] = [];
	const conf: Config = { ...defaultConfig, ...config };

	let currentId = '';
	let nextParentId = childId;

	while (true) {
		const parentItem = getParentItem<T>(items, nextParentId, conf, currentId);
		if (parentItem) {
			switch (order) {
				case 'child-to-root':
					parentTree.push(parentItem);
					break;
				default:
				case 'root-to-child':
					parentTree.unshift(parentItem);
					break;
			}

			nextParentId = parentItem[conf.parentId];
			currentId = parentItem[conf.id];
			continue;
		} else {
			break;
		}
	}

	return parentTree;
};

/**
 *
 * @param items Ordered list of items of type T.
 * @param labelPropName Property name in T which should be used in the breadcrumb.
 * @param separator The separator between each item. Default is ' < '. Ex. "Jysk < Jysk Aalborg < ..."
 */
const generateBreadCrumbFromOrderedList = <T>(
	items: T[],
	labelPropName: keyof T,
	separator: string = ' > '
): string => {
	let breadCrumb = '';
	for (let i = 0; i < items.length - 1; i++) {
		const item = items[i];
		breadCrumb += item[labelPropName] + separator;
	}

	if (items.length - 1 in items) {
		breadCrumb += items[items.length - 1][labelPropName];
	}

	return breadCrumb;
};

export function generateBreadCrumbFromUnorderedList<T extends Item>(
	items: T[],
	labelPropName: keyof T,
	childId?: number | null,
	config: Partial<Config> = groupsDtoConfig,
	order: SortingOrder = 'root-to-child',
	separator: string = ' > '
) {
	const orderedTree = generateOrderedParentTreeFromNode(items, childId, config, order);
	return generateBreadCrumbFromOrderedList(orderedTree, labelPropName, separator);
}
