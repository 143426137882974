import React from 'react';

interface AppProps {
	headerLeftPart?: React.ReactNode;
	selectAllColumns?: {};
	containerClassName?: string;
}

type Props = AppProps;

export class BiLabelContent extends React.PureComponent<Props> {
	public render() {
		return (
			<div className={`${this.props.containerClassName || ''}`}>
				{this.props.headerLeftPart ||
					(this.props.selectAllColumns && (
						<div className="flex-direction-row">
							{this.props.headerLeftPart || null}
							SELECT-ALL-COLUMNS-HERE
						</div>
					))}
				<div className="flex-direction-column flex-fill-width">{this.props.children}</div>
			</div>
		);
	}
}
