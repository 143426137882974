import { NotificationHistoryClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { notificationHistoryClient } from '../api-clients';
import { GetNotificationHistoryAction, UpdatePickUpAmountAction } from './actions';

export async function getNotificationHistory(
	serialNumber: string,
	startDate: Date,
	endDate: Date
) {
	return AsyncOperationBuilder(
		GetNotificationHistoryAction,
		apiClient =>
			(apiClient as NotificationHistoryClient).getHistoryBySerialNumber(
				serialNumber,
				startDate,
				endDate
			),
		{ serialNumber, startDate, endDate },
		notificationHistoryClient
	);
}

export const updateNotificationHistory = (historyId: number, unitId: string, pickUpAmount: number) => {
	return AsyncOperationBuilder(
		UpdatePickUpAmountAction,
		apiClient => (apiClient as NotificationHistoryClient).putPickUpAmount(historyId, pickUpAmount),
		{ historyId, unitId, pickUpAmount },
		notificationHistoryClient
	);
};
