import { FloatAndDateMap, ProfileUnit, UnitDetailsDto, WeightUnit } from 'api/api';
import { ChartOptions } from 'chart.js';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { IsBrandBramidan } from 'utilities/brand-utils';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import BiDialog from 'view/shared/components/dialogs/bi-dialog/bi-dialog';
import WhiteCard from 'view/shared/components/cards/white-card';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';
import Graph, { ChartPoint, ColorStop } from 'view/shared/components/graph/graph';
import LabelValueCard, {
	addTranslationLabelValueMapToList,
	getLabelValueOrDefaultToLocalizedNoDataString,
	LabelValueForCardMap,
} from 'view/shared/components/label-value-card';
import * as Colors from 'view/styles/colors';
import { cardGeneralClassNames, LabelsDataForGraphMap } from '../constants';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	const currMachine = getMachineFromIdDataMapOrDefault(
		state.machineDetailsReducer.machineDetails,
		ownProps.machineDetailsId,
		UnitDetailsDto
	);

	return {
		weightId: currMachine.data.weightId,
		currWeight: currMachine.data.currentWeight,
		lastBaleOutput: currMachine.data.lastBaleOutput,
		weightLastUpdate: currMachine.data.weightLastUpdate,
		weightData: currMachine.data.weightAndDateData,
		lastXBales: currMachine.data.lastXBalesData,
		weightUnit:
			state.userSettingReducer.userSettings.profileUnits === ProfileUnit.Metric
				? WeightUnit.Kg
				: WeightUnit.Pound,
	};
};

interface AppProps {
	machineDetailsId: string;
}

type State = {
	zoomWeightOverTimeDialog: boolean;
	zoomLast10BalesDialog: boolean;
};

type Props = ReturnType<typeof mapStateToProps> & AppProps;

const classNameColumn = 'flex-direction-column ';
const classNameColumnTitle = 'text-bold text-center';

const weightOverTimeYAxesLabels: string[] = [localized('Unit_KiloGram')]; // default
const weightOverTimeXAxesLabels: string[] = [localized('Time')];
const lastXBalesGradientColor: ColorStop[] = [
	{ offset: 0, color: Colors.chartGrayLight },
	{ offset: 1, color: Colors.chartGrayDark },
];

const numberOfBalesInGraph = 10;
const cardClassName = `${cardGeneralClassNames.classNameContainer} flex-direction-row flex-wrap padding-32px details-weight-card`;

class WeightCard extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			zoomWeightOverTimeDialog: false,
			zoomLast10BalesDialog: false,
		};
	}

	public render() {
		if (!this.props.weightId || !IsBrandBramidan) {
			return null;
		}

		const currentWeightUnit =
			this.props.weightUnit === WeightUnit.Kg
				? localized('Unit_KiloGram')
				: localized('Pound').toLocaleLowerCase();
		weightOverTimeYAxesLabels[0] = currentWeightUnit;

		const pairValues = this.getPairValues(currentWeightUnit);
		const weightAndDateGraph = this.getChartPoints(this.props.weightData);

		const lastXBalesGraph = this.getDataAndLabelsForLastBales(this.props.lastXBales);

		const weightOverTimeOptions = this.getWeightOverTimeChartOptions(
			weightOverTimeXAxesLabels,
			weightOverTimeYAxesLabels
		);
		const lastBalesOptions = this.getLastBalesChartOptions(weightOverTimeXAxesLabels, weightOverTimeYAxesLabels);

		const dialogs = this.getZoomDialogs(
			weightAndDateGraph,
			lastXBalesGraph,
			weightOverTimeOptions,
			lastBalesOptions
		);

		return (
			<>
				<WhiteCard classNameContainer={cardClassName}>
					<div className={`${classNameColumn} margin-bottom-20px`}>
						<h3 className={classNameColumnTitle}>{localized('Weight')}</h3>
						<LabelValueCard
							labels={pairValues.labels}
							values={pairValues.valuesForLabels}
							labelClassName="padding-right-2 width-180px"
							valueClassName="padding-right-2 width-180px"
						/>
					</div>

					<div
						className={`${classNameColumn} margin-horizontal-30px margin-bottom-20px cursor-pointer`}
						onClick={this.handleWeightOverTimeClick}
					>
						<h3 className={classNameColumnTitle}>{localized('WeightOverTime')}</h3>
						<Graph
							lineColor={Colors.chartGrayDark}
							lineAreaColor={Colors.chartGreyLightTransparent}
							data={weightAndDateGraph}
							height="400"
							width="550"
							options={weightOverTimeOptions}
							fill={false}
						/>
					</div>

					{this.props.lastXBales && (
						<div
							className={`${classNameColumn} cursor-pointer`}
							onClick={this.handleZoomLast10BalesDialogClick}
						>
							<h3 className={classNameColumnTitle}>{localized('Last10Bales')}</h3>
							<Graph
								canvasId={'details-weight-over-time-default'}
								colorStops={lastXBalesGradientColor}
								type="bar"
								data={lastXBalesGraph.data}
								labels={lastXBalesGraph.labels}
								height="400"
								width="480"
								options={lastBalesOptions}
							/>
						</div>
					)}
				</WhiteCard>
				{dialogs}
			</>
		);
	}

	private getChartPoints = (data?: FloatAndDateMap[]): ChartPoint[] => {
		// Create arrays of the data and labels in order to make a graph out of them.
		let sortedData = this.sortData(data);

		let graphData: ChartPoint[] = [];

		sortedData &&
			sortedData.forEach(curr => {
				graphData.push({ x: curr.dateVal, y: curr.floatVal });
			});

		return graphData;
	};

	private getDataAndLabelsForLastBales = (data?: FloatAndDateMap[]): LabelsDataForGraphMap => {
		// Create arrays of the data and labels in order to make a graph out of them.
		let sortedData = this.sortData(data);

		let graphData: LabelsDataForGraphMap = {
			data: [],
			labels: [],
		};

		sortedData &&
			sortedData.forEach(curr => {
				graphData.data.push(curr.floatVal);
				graphData.labels.push(getDateToString(curr.dateVal, DateFormatterModes.withMinutes));
			});

		// Handle case where 10 bales have not been recorded for the weight yet
		// Makes sure that bars have same width as if there were 10 bales
		for (let i: number = graphData.data.length; i < numberOfBalesInGraph; i++) {
			graphData.data.unshift(0);
			graphData.labels.unshift('');
		}

		return graphData;
	};

	private sortData = (data?: FloatAndDateMap[]): FloatAndDateMap[] | undefined => {
		// Create arrays of the data and labels in order to make a graph out of them.
		let sortedData = data?.sort((a, b) => {
			return moment(a.dateVal).isAfter(b.dateVal) ? 1 : -1;
		});

		return sortedData;
	};

	private getPairValues(currentWeightUnit: string): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		addTranslationLabelValueMapToList(
			'Id',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.weightId),
			retMap
		);

		const currWeightLabelValue = this.props.currWeight ? `${this.props.currWeight} ${currentWeightUnit}` : '';
		const lastBaleLabelValue = this.props.lastBaleOutput ? `${this.props.lastBaleOutput} ${currentWeightUnit}` : '';

		addTranslationLabelValueMapToList('CurrentWeight', currWeightLabelValue, retMap);
		addTranslationLabelValueMapToList('LastBaleOutput', lastBaleLabelValue, retMap);

		addTranslationLabelValueMapToList(
			'updatedDateFriendly',
			getLabelValueOrDefaultToLocalizedNoDataString(
				getDateToString(this.props.weightLastUpdate, DateFormatterModes.withMinutes)
			),
			retMap
		);

		return retMap;
	}

	private handleWeightOverTimeClick = () => this.setState({ zoomWeightOverTimeDialog: true });
	private handleWeightOverTimeCancel = () => this.setState({ zoomWeightOverTimeDialog: false });

	private handleZoomLast10BalesDialogClick = () => this.setState({ zoomLast10BalesDialog: true });
	private handleZoomLast10BalesDialogCancel = () => this.setState({ zoomLast10BalesDialog: false });

	private getWeightOverTimeChartOptions = (xAxisLabels: string[], yAxisLabels: string[]): ChartOptions => {
		return {
			plugins: {
				tooltip: {
					callbacks: {
						title: tooltipItem => {
							let label = tooltipItem[0]?.label || '';
							label = getDateToString(new Date(label), DateFormatterModes.withMinutes);

							return label;
						},
					},
				},
			},
			scales: {
				x: {
					type: 'time',
					time: {
						displayFormats: {
							hour: 'YYYY-MM-DDTHH:mm:ss',
							day: 'YYYY-MM-DDTHH:mm:ss',
						},
					},
					ticks: {
						callback: (value, index, values) => {
							const date = Date.parse(value.toString());

							const ret = date ? getDateToString(new Date(date), DateFormatterModes.normal) : value;
							return ret;
						},
					},
					title: {
						display: true,
						text: xAxisLabels,
					},
				},
				y: {
					title: {
						display: true,
						text: yAxisLabels,
					},
					min: -50,
				},
			},
			elements: {
				line: {
					tension: 0,
				},
			},
		};
	};

	private getLastBalesChartOptions = (xAxisLabels: string[], yAxisLabels: string[]): ChartOptions => {
		return {
			scales: {
				x: {
					title: {
						display: true,
						text: xAxisLabels,
					},
				},
				y: {
					title: {
						display: true,
						text: yAxisLabels,
					},
					min: 0,
				},
			},
		};
	};

	private getZoomDialogs = (
		weightAndDateGraph: ChartPoint[],
		lastXBalesGraph: LabelsDataForGraphMap,
		weightOverTimeOptions: ChartOptions,
		lastBalesOptions: ChartOptions
	) => {
		return (
			<>
				<BiDialog
					header={localized('WeightOverTime')}
					dismissableMask={true}
					visible={this.state.zoomWeightOverTimeDialog}
					onHide={this.handleWeightOverTimeCancel}
					showHeader={true}
					className="width-80-percent"
				>
					<Graph
						lineColor={Colors.chartGrayDark}
						lineAreaColor={Colors.chartGreyLightTransparent}
						data={weightAndDateGraph}
						height="600px"
						options={weightOverTimeOptions}
						fill={false}
					/>
				</BiDialog>

				<BiDialog
					header={localized('Last10Bales')}
					dismissableMask={true}
					visible={this.state.zoomLast10BalesDialog}
					onHide={this.handleZoomLast10BalesDialogCancel}
					showHeader={true}
					className="width-80-percent"
				>
					<Graph
						canvasId={'details-weight-over-time-zoom'}
						colorStops={lastXBalesGradientColor}
						type="bar"
						lineAreaColor={Colors.colorGreenLight}
						data={lastXBalesGraph.data}
						labels={lastXBalesGraph.labels}
						height="600px"
						options={lastBalesOptions}
					/>
				</BiDialog>
			</>
		);
	};
}

export default connect(mapStateToProps, {})(WeightCard);
