import React, { FC } from "react"
import { DateTypes } from "api/api";
import { Button } from "primereact/button";
import './bi-select-buttons.scss'


interface Props {
    availableOptions: {label: string, dateType: DateTypes}[]
    currentOption: DateTypes
    onChange: (dateType: DateTypes, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    className?: string;
}

export const BiSelectButtons: FC<Props> = React.memo((props: Props) => {
    const buttons = props.availableOptions.map(dateTypeAndLabel => {
        return (
            <BiSelectButton
                key={dateTypeAndLabel.label}
                currentOption={props.currentOption}
                dateType={dateTypeAndLabel.dateType}
                label={dateTypeAndLabel.label}
                onChange={props.onChange}
            />

        );
    });
    
    return (
        <div className={`flex-direction-row ${props.className}`}>
            {buttons}
        </div>
    )
});

interface SelectButtonProps {
    currentOption: DateTypes
    label: string
    dateType: DateTypes
    onChange: (dateType: DateTypes, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const BiSelectButton: FC<SelectButtonProps> = React.memo((props: SelectButtonProps) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onChange(props.dateType, e);
    };

    return (
        <Button
            label={props.label}            
            onClick={onClick}
            className={
                props.currentOption === props.dateType ? 'multiselect-button active' : 'multiselect-button'
            }
        ></Button>
    );
})