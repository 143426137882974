import { UnitWeightClient } from "api/api";
import { AsyncOperationBuilder } from "utilities/api-helper";
import { unitWeightClient } from "../api-clients";
import { CreateUnitWeightAction, DeleteUnitWeightAction, GetUnitWeightAction, UpdateUnitWeightAction } from "./actions";

export function createUnitWeight(unitId: number, weighingSerialNumber: string) {
	return AsyncOperationBuilder(
		CreateUnitWeightAction,
		apiClient => (apiClient as UnitWeightClient).createUnitWeight(unitId, weighingSerialNumber),
		{ unitId, weighingSerialNumber },
		unitWeightClient
	);
}

export function getUnitWeight(unitId: string) {
	return AsyncOperationBuilder(
		GetUnitWeightAction,
		apiClient => (apiClient as UnitWeightClient).getUnitWeight(unitId),
		{ unitId },
		unitWeightClient
	);
}

export function updateUnitWeight(unitId: number, weighingSerialNumber: string) {
	return AsyncOperationBuilder(
		UpdateUnitWeightAction,
		apiClient => (apiClient as UnitWeightClient).updateUnitWeight(unitId, weighingSerialNumber),
		{ unitId, weighingSerialNumber },
		unitWeightClient
	);
}

export function deleteUnitWeight(unitId: number) {
	return AsyncOperationBuilder(
		DeleteUnitWeightAction,
		apiClient => (apiClient as UnitWeightClient).deleteUnitWeight(unitId),
		{ unitId },
		unitWeightClient
	);
}