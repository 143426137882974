import {AppState} from 'state/store';
import React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {localized} from 'state/i18n';
import {PageState, Paginator} from 'primereact/paginator';
import {BiLabelContent} from 'view/shared/components/bi-label-content/bi-label-content';
import './release-notes.scss';
import ReleaseNoteItem from './release-note-item';

const mapStateToProps = (state: AppState) => {
	return {
		releaseNotes: state.releaseNoteReducer.notes.sort((note1, note2) =>
			note1.createdDate > note2.createdDate ? -1 : 1
		),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State {
	paginatorFirst: number;
	paginatorPageCount?: number;
	paginatorCurrentPage: number;
	paginatorRows: number;
	totalRows: number;
}

class ReleaseNotes extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			paginatorFirst: 0,
			paginatorCurrentPage: 0,
			paginatorRows: 7,
			totalRows: this.props.releaseNotes.length,
		};
	}

	public render() {
		const paginatedrows: JSX.Element[] = this.getReleaseNoteRows();

		return (
			<div id="release-note-admin">
				<BiLabelContent containerClassName="flex-fill-width">
					<div className="release-note-grid padding-left-20px padding-right-20px">
						<b>{localized('Title')}</b>
						<b>{localized('Description')}</b>
					</div>

					<div>{paginatedrows}</div>

					<div className="flex-end-row">
						<Paginator
							first={this.state.paginatorFirst}
							rows={this.state.paginatorRows}
							totalRecords={this.state.totalRows}
							onPageChange={this.onPageChangeHandler}
							template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
						></Paginator>
					</div>
				</BiLabelContent>
			</div>
		);
	}

	private onPageChangeHandler = (e: PageState) => {
		this.setState({
			paginatorFirst: e.first,
			paginatorRows: e.rows,
			paginatorCurrentPage: e.page,
			paginatorPageCount: e.pageCount,
		});
	};

	private getReleaseNoteRows = (): JSX.Element[] => {
		return this.props.releaseNotes
			.map(note => <ReleaseNoteItem releaseNote={note} key={`${note.id} releasenoteitem`} />)
			.slice(this.state.paginatorFirst, this.state.paginatorFirst + this.state.paginatorRows);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
