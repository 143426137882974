import { IGroupsDto, IUserGroupPermissionsDto, RolesType } from 'api/api';
import { AppState } from 'state/store';
import { getEmailFromToken } from './token-util';

export function hasAtLeastPermissionBasicUser(role: RolesType) {
	return role <= RolesType.BasicUser;
}

export function hasAtLeastPermissionCountryAdmin(role: RolesType) {
	return role <= RolesType.CountryAdmin;
}

export function hasAtLeastPermissionAdmin(role: RolesType) {
	return role <= RolesType.Admin;
}

export function hasAtLeastPermissionSuperAdmin(role: RolesType) {
	return role === RolesType.SuperAdmin;
}

export const isAtLeastBasicUserFromState = (state: AppState, groupId: number | undefined): boolean => {
	return hasGroupAccess(state, groupId, ugp => hasAtLeastPermissionBasicUser(ugp.roleType));
};

export const isAtLeastCountryAdminFromState = (state: AppState, groupId: number | undefined): boolean => {
	return hasGroupAccess(state, groupId, ugp => hasAtLeastPermissionCountryAdmin(ugp.roleType));
};

export const isAtLeastAdminFromState = (state: AppState, groupId: number | undefined): boolean => {
	return hasGroupAccess(state, groupId, ugp => hasAtLeastPermissionAdmin(ugp.roleType));
};

export const isAtLeastSuperAdminFromState = (state: AppState): boolean => {
	return state.userPermissionReducer.UserGroupPermissions.some(ugp => hasAtLeastPermissionSuperAdmin(ugp.roleType));
};

export const hasAtLeast = (currentRole: RolesType, targetRole: RolesType): boolean => {
	if (currentRole === targetRole) return true;

	switch (currentRole) {
		case RolesType.SuperAdmin:
			return true;
		case RolesType.CountryAdmin:
			return !(targetRole <= RolesType.SuperAdmin);
		case RolesType.Admin:
			return !(targetRole <= RolesType.CountryAdmin);
		case RolesType.BasicUser:
			return !(targetRole <= RolesType.Admin);
		case RolesType.AppUser:
			return !(targetRole <= RolesType.BasicUser);
		case RolesType.NonRegisteredAppUser:
			return targetRole === RolesType.NonRegisteredAppUser;

		default:
			return false;
	}
};

const getValidGroupIds = (state: AppState, permissionCheck: (ugp: IUserGroupPermissionsDto) => boolean): number[] => {
	return state.userPermissionReducer.UserGroupPermissions.filter(permissionCheck).map(ugp => ugp.groupId);
};

const getGroupsWithUserAccess = (state: AppState): IGroupsDto[] | undefined => {
	return state.groupsReducer.groups.find(g => g.id === getEmailFromToken())?.data;
};

const hasGroupAccess = (
	state: AppState,
	groupId: number | undefined,
	permissionCheck: (ugp: IUserGroupPermissionsDto) => boolean
): boolean => {
	if (groupId === undefined) return false;

	return recursiveCheckIfSubGroup(
		getValidGroupIds(state, permissionCheck),
		getGroupsWithUserAccess(state)?.find(g => g.id === groupId),
		state.groupsReducer.groups?.flatMap(g => g.data)
	);
};

const recursiveCheckIfSubGroup = (
	userGroupPermissionsWithRequiredRole: number[],
	currentGroup: IGroupsDto | undefined,
	allGroups: IGroupsDto[]
): boolean => {
	if (currentGroup === undefined || userGroupPermissionsWithRequiredRole.length === 0) return false;

	if (userGroupPermissionsWithRequiredRole.includes(currentGroup.id)) {
		return true;
	}

	return recursiveCheckIfSubGroup(
		userGroupPermissionsWithRequiredRole,
		allGroups.find(g => g.id === currentGroup.parentGroupId),
		allGroups
	);
};


export const isServiceTechnicianFromState = (state: AppState, groupId: number | undefined): boolean => {
	return hasGroupAccess(state, groupId, ugp => ugp.isServiceTechnician) || isAtLeastSuperAdminFromState(state);
};

export const isServiceToolCodeFromState = (state: AppState, groupId: number | undefined): boolean => {
	return hasGroupAccess(state, groupId, ugp => ugp.isServiceToolCode) || isAtLeastSuperAdminFromState(state);
};

